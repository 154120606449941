import { Button, Form, Input, Modal } from "antd";
import React, { Component } from "react";
import { registerEvent } from "~/analytics";
import { required } from "~/lib/form_rules";

import * as ga from "~/contants/gaConstants";

export type ActionType = "SAVE_NEW" | "EDIT_EXISTING" | "DELETE_EXISTING";

interface Props {
  action: ActionType;
  visible: boolean;
  onCancel: () => void;
  onConfirmNew: (name: string) => Promise<void>;
  onConfirmEdit: () => Promise<void>;
  onConfirmDelete: () => Promise<void>;
}

interface State {
  isLoading: boolean;
}

export default class ConfirmTemplateModal extends Component<Props, State> {
  state: State = {
    isLoading: false,
  };

  getTitle() {
    switch (this.props.action) {
      case "SAVE_NEW":
        return "Confirm save";
      case "EDIT_EXISTING":
        return "Confirm edit";
      case "DELETE_EXISTING":
        return "Confirm delete";
    }
  }

  handleFinish = async (values: any) => {
    try {
      registerEvent(
        ga.EVENT_CATEGORY_BUTTON_CLICK,
        ga.EVENT_CLICK,
        `ConfirmTemplateModal>Submit>${this.props.action}`
      );
      this.setState({
        isLoading: true,
      });
      switch (this.props.action) {
        case "SAVE_NEW":
          return await this.props.onConfirmNew(values.name);
        case "EDIT_EXISTING":
          return await this.props.onConfirmEdit();
        case "DELETE_EXISTING":
          return await this.props.onConfirmDelete();
      }
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  renderSaveNew() {
    return (
      <div>
        <div>Please enter the following to continue.</div>
        <Form.Item
          label="Template name"
          name="name"
          rules={[required("Template name")]}
        >
          <Input />
        </Form.Item>
      </div>
    );
  }

  renderEdit() {
    return (
      <div>
        <div>Do you want to save the changes?</div>
      </div>
    );
  }

  renderDelete() {
    return (
      <div>
        <div>Do you want to delete this template?</div>
      </div>
    );
  }

  renderFormBody() {
    switch (this.props.action) {
      case "SAVE_NEW":
        return this.renderSaveNew();
      case "EDIT_EXISTING":
        return this.renderEdit();
      case "DELETE_EXISTING":
        return this.renderDelete();
    }
  }

  render() {
    return (
      <Modal
        maskClosable={false}
        title={this.getTitle()}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        footer={null}
        destroyOnClose
        centered
      >
        <Form onFinish={this.handleFinish}>
          {this.renderFormBody()}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "var(--margin-md)",
            }}
          >
            <Button
              type="default"
              disabled={this.state.isLoading}
              onClick={(e) => {
                registerEvent(
                  ga.EVENT_CATEGORY_BUTTON_CLICK,
                  ga.EVENT_CLICK,
                  ga.events.confirmTemplateModal181
                );
                this.props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.state.isLoading}
            >
              Ok
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}
