import React, { Component } from "react";
// import styles from "./styles.module.css";
import UNAUTHORIZED_LOGO from "~/assets/shield.svg";
import { Button } from "antd";
import { RouteComponentProps } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { AuthState } from "~/app/MainApp/store";
import { LoginRole } from "~/api/auth";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";

// tslint:disable-next-line: no-empty-interface
interface State {}

const mapStateToProps = (state: AuthState) => {
  return {
    activeRole: state.mainAppSlice.user.activeRole,
  };
};
const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

// tslint:disable-next-line: no-empty-interface
interface Props extends RouteComponentProps, PropsFromRedux {}

class ExpiredMembership extends Component<Props, State> {
  state: State = {};

  //   handleHomeBtn = () => {
  //     const { activeRole } = this.props;
  //     const isAdminOrSuper =
  //       activeRole === LoginRole.ADMIN || activeRole === LoginRole.SUPERuSER;
  //     if (isAdminOrSuper) {
  //       this.props.history.push("/app/dashboard");
  //     } else {
  //       this.props.history.push("/app/employee/me");
  //     }
  //   };

  render() {
    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>{titles.ExpiredMembership}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={[""]}
          actions={
            <></>
            // <Space>
            //   {activeRole === LoginRole.ADMIN ||
            //   activeRole === LoginRole.SUPERuSER ? (
            //     active ? (
            //       <Button danger onClick={this.onDelete}>
            //         Delete
            //       </Button>
            //     ) : null
            //   ) : null}
            // </Space>
          }
          // empActiveStatus={active}
        />
        <div
          style={{
            display: "flex",
            minHeight: "100%",
            width: "100%",
            // border:'1px solid red',
            // marginLeft:'4.5%',
            marginTop: "20%",
            justifyContent: "center",
          }}
        >
          <div>
            <h1>
              Please contact your Admin or Superuser to reactivate the
              membership plan
            </h1>
          </div>
        </div>
      </IMTContent>
    );
  }
}

export default ExpiredMembership;
