import React, { Component } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Select,
  Spin,
  Card,
  Radio,
} from "antd";
import {
  OnboardStoreState,
  action,
  CommunicationDetails,
} from "~/app/OnboardCompany/store";
import { connect, ConnectedProps } from "react-redux";
import { getCity } from "~/api/vendor";
import { fetchUtil } from "~/api/common";
import { FormInstance } from "antd/lib/form";
import { OTHER_TERITORY_ID } from "~/lib/constants";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (state: OnboardStoreState) => ({
  initialValues: state.onboard.communicationDetails,
  directorDetailLIst: state.onboard.directorList,
});
const mapDispatchToProps = {
  onBack: action.previousStep,
  finishStep: action.finishStep,
  advanceStep: action.advanceStep,
  modifyDirectorList: action.modifyDirectorList,
};

const { Option } = Select;

type Designations = {
  id: number;
  role_name: string;
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  doNotUseMe?: never;
}
interface State {
  cityList: any;
  rowCityList: any;
  country: any;
  list: any;
  stateId: any;
  stateName: any;
  finalState: any;
  designationArray: Array<Designations>;
  loadDetail: boolean;
  selectedDirector: any;
  selectedDirectorData: any;
  executive_list: any;
}

class StepCommunication extends Component<Props, State> {
  state: State = {
    cityList: [],
    rowCityList: [],
    country: "",
    list: [],
    stateId: 0,
    stateName: "",
    finalState: "",
    designationArray: [],
    loadDetail: false,
    selectedDirector: "",
    selectedDirectorData: {},
    executive_list: [],
  };

  _formRef = React.createRef<FormInstance<Partial<CommunicationDetails>>>();

  componentDidMount() {
    this.getState();
    this.getDesignation();
    setTimeout(() => {
      // this._cityInput.current?.input.setAttribute("autocomplete", "new-city");
      document.querySelectorAll("input").forEach((el) => {
        el.setAttribute("autocomplete", "chrome-off");
      });
    }, 1200);
    if (this.props.initialValues.selectedDirectorName) {
      this.setState(
        {
          selectedDirector: this.props.initialValues.selectedDirectorName,
        },
        () => {
          const chossenDirectorData = this.props.directorDetailLIst.find(
            (el: any) => el.name === this.state.selectedDirector
          );
          this.setState(
            {
              selectedDirectorData: chossenDirectorData,
            },
            () => {
              // this.props.modifyDirectorList(this.state.selectedDirectorData)
              const loginData = JSON.parse(localStorage.getItem("imt__user")!);
              this.setState(
                {
                  selectedDirectorData: {
                    ...this.state.selectedDirectorData,
                    email: loginData.email,
                  },
                },
                () => {
                  this.props.modifyDirectorList(
                    this.state.selectedDirectorData
                  );
                }
              );
            }
          );
        }
      );
    }
  }

  getDesignation = async () => {
    this.setState({
      loadDetail: true,
    });
    const { ok, message, json } = await fetchUtil(
      "GET",
      "/get_executive_designations"
    );
    if (!ok) {
      notification.error({ message });
    } else {
      this.setState({
        designationArray: json,
        loadDetail: false,
      });
    }
  };

  getState = async () => {
    this.setState({
      loadDetail: true,
    });
    const { ok, message, json } = await fetchUtil("GET", "/get_states");
    if (!ok) {
      notification.error({ message });
    } else {
      this.setState({
        list: json,
        loadDetail: false,
      });
    }
  };

  loadPickupLists = async (value: string) => {
    const [expenseRes] = await Promise.all([
      getCity(value as string).then(({ ok, message, data }) => ({
        ok,
        message,
        data,
      })),
    ]);

    if (!expenseRes.ok) {
      notification.error({
        message: "Failed to load city list.",
        description: expenseRes.message,
      });
    } else {
      const a: any = [];
      // let obj={};
      expenseRes.data.forEach((el: any) => {
        const objj = el.city;

        const k = {
          value: objj,
        };
        a.push(k);
      });

      this.setState({
        cityList: a,
        rowCityList: expenseRes.data,
      });
    }
  };

  handleOnValueCHange = (changedValues: any, values: any) => {
    this.setState({ cityList: [] });
    if (
      String(changedValues.company_city).length === 3 ||
      String(changedValues.company_city).length > 3
    ) {
      this.loadPickupLists(changedValues.company_city);
    }

    if (changedValues.company_city) {
      this.state.rowCityList.forEach((el: any) => {
        if (changedValues.company_city === el.city) {
          this.setState(
            {
              country: el.country,
              stateName: el.state,
            },
            () => {
              this.state.list.forEach((k: any) => {
                if (k.state_name === this.state.stateName) {
                  this.setState({
                    stateId: k.id,
                    finalState: k.state_name,
                  });
                }
              });
            }
          );
        } else {
          this.setState({
            stateId: OTHER_TERITORY_ID,
            finalState: "Other teritory",
          });
        }
      });
    }
    // else {
    // }
  };

  onFinish = (values: any) => {
    this.props.finishStep({
      step: "communication",
      data: {
        ...values,
        admin_name: values.first_name + " " + values.last_name,
        selectedDirectorName: this.state.selectedDirector,

        // place_of_supply: this.state.stateId,
      },
    });
    //   if(!this.state.selectedDirector){
    //     let loginData = JSON.parse(localStorage.getItem("imt__user")!);
    //     values.email=loginData.email;
    // this.props.modifyDirectorList(values)
    //   }

    this.props.advanceStep();
  };

  handlePrev = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.stepCommunication178
    );
    const formValues = this._formRef.current?.getFieldsValue();
    this.props.onBack({
      step: "communication",
      data: {
        ...formValues,
        selectedDirectorName: this.state.selectedDirector,
      },
    });
  };

  getNumericInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let number = "";
    const N = value.length;
    for (let i = 0; i < N; i++) {
      const charCode = value.charCodeAt(i);
      if (charCode >= "0".charCodeAt(0) && charCode <= "9".charCodeAt(0)) {
        number += value.charAt(i);
      }
    }
    if (number.length > 10) {
      return number.substr(0, 10);
    }
    return number;
  };

  handleDirector = (e: any) => {
    this.setState(
      {
        selectedDirector: e.target.value,
      },
      () => {
        const chossenDirectorData = this.props.directorDetailLIst.find(
          (el: any) => el.name === this.state.selectedDirector
        );
        this.setState(
          {
            selectedDirectorData: chossenDirectorData,
          },
          () => {
            if (this._formRef.current) {
              const values = this._formRef.current.getFieldsValue() as CommunicationDetails;
              const nameParts = this.state.selectedDirectorData.name.split(" ");
              const firstName = nameParts.length > 0 ? nameParts[0] : "";
              const lastName =
                nameParts.length > 1 ? nameParts.slice(1).join(" ") : ""; // rest of the

              values.first_name = firstName;
              values.last_name = lastName;
              values.role_in_company = 3;
              this._formRef.current.setFieldsValue({
                first_name: firstName,
                last_name: lastName,
                role_in_company: 3,
              });
            }
            const loginData = JSON.parse(localStorage.getItem("imt__user")!);
            this.setState(
              {
                selectedDirectorData: {
                  ...this.state.selectedDirectorData,
                  email: loginData.email,
                },
              },
              () => {
                this.props.modifyDirectorList(this.state.selectedDirectorData);
              }
            );
          }
        );
      }
    );
  };

  renderCardView = () => {
    return this.props.directorDetailLIst.map((it: any) => {
      return (
        <Card
          key={it.name}
          style={{
            marginTop: "10px",
            marginLeft: "10px",
            minHeight: 90,
            minWidth: 212,
            // maxWidth: 166,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "row",
              marginBottom: -30,
            }}
          >
            <div>
              <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                {it.name}
              </span>
              <br />
              <p style={{ fontSize: "16px", color: "#BABABA" }}>Director</p>
            </div>

            <div style={{ padding: "10" }}>
              <Radio.Group
                onChange={this.handleDirector}
                value={this.state.selectedDirector}
              >
                <Radio value={it.name} />
              </Radio.Group>
            </div>
          </div>
        </Card>
      );
    });
  };

  render() {
    return (
      <Spin spinning={this.state.loadDetail}>
        <Form
          initialValues={this.props.initialValues}
          onFinish={this.onFinish}
          onValuesChange={this.handleOnValueCHange}
          ref={this._formRef}
        >
          <p
            style={{
              marginTop: "-10px",
              textAlign: "left",
              marginBottom: "-4px",
              // fontWeight: 700,
              fontSize: "12px",
              color: "#828282",
              marginLeft: "10px",
            }}
          >
            {this.props.directorDetailLIst.length > 0
              ? "You can choose from existing directors to prefil admin details"
              : null}
          </p>
          <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
            {this.renderCardView()}
          </div>

          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="first_name"
                label="Admin first name"
                rules={[
                  { required: true, message: "Please enter admin first name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="last_name"
                label="Admin last name"
                rules={[
                  { required: true, message: "Please enter admin last name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="role_in_company"
                label="Role in company"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid role in company",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  // tslint:disable-next-line: jsx-no-lambda
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.designationArray.map((opt) => (
                    <Option key={opt.id} value={opt.id}>
                      {opt.role_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="admin_phone"
                label="Mobile number"
                getValueFromEvent={this.getNumericInput}
                rules={[
                  { required: true, message: "Please enter mobile number" },
                ]}
              >
                <Input type="tel" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={16}>
            <Col>
              <Button type="ghost" size="middle" onClick={this.handlePrev}>
                Back
              </Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" size="middle">
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  }
}

export default connector(StepCommunication);
