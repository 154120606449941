import React, { ReactElement } from "react";
import { Row, Col, Button, Space, Dropdown, Menu } from "antd";
/* import { action } from "~/app/LandingPageApp/store"; */
import { connect, ConnectedProps } from "react-redux";
import commonStyles from "~/component/common.module.css";
import styles from "./LandingPageLayout.module.css";
import { MenuOutlined } from "@ant-design/icons";
import IMTMediaQueries from "~/component/IMTMediaQueries";
import { registerEvent } from "~/analytics";
import * as ga from "~/contants/gaConstants";
import { action } from "~/app/LandingPageApp/store";
/* const { Option } = Select; */

const googleFormContactUs =
  "https://docs.google.com/forms/d/e/1FAIpQLSeyWDRwNfc2IqkOCGJ10qNAss3VwCVJIyrLYJ8WDms1hnuFQg/viewform?vc=0&c=0&w=1&flr=0";
const aboutUsLink = "https://www.thetaonelab.com/team.html";
const hiringForm = "https://forms.gle/6ECRAu53nJKE7EGR8";

const mapDispatchToProps = {
  showLoginModal: () => action.login.showModal(),
  showSignUpModal: () => action.signup.showModal(),
};

const scrollToView = (id: string, gaEvent?: string) => () => {
  const anchor: any = document.querySelector(id);
  anchor.scrollIntoView({ behavior: "smooth", block: "start" });
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  doNotUseMe?: never;
}

const menu = (
  <Menu>
    <Menu.Item key="0" onClick={scrollToView("#features")}>
      Features
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="1" onClick={scrollToView("#premium-services")}>
      Premium services
    </Menu.Item>
    <Menu.Divider />

    <Menu.Item key="2" onClick={scrollToView("#pricing")}>
      Pricing
    </Menu.Item>
    <Menu.Divider />
    {/* <Menu.Item key="3">Blog</Menu.Item>
    <Menu.Divider /> */}

    <Menu.Item
      key="4"
      /* onClick={() => {
        window.location.href = googleFormContactUs;
      }} */
    >
      <a target="_blank" rel="noreferrer" href={googleFormContactUs}>
        Contact
      </a>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item
      key="5"
      /* onClick={() => {
        window.location.href = hiringForm;
      }} */
    >
      <a target="_blank" rel="noreferrer" href={hiringForm}>
        We are hiring
      </a>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item
      key="6"
      /* onClick={() => {
        window.location.href = aboutUsLink;
      }} */
    >
      <a target="_blank" rel="noreferrer" href={aboutUsLink}>
        About us
      </a>
    </Menu.Item>
  </Menu>
);

function IMTLPHeader({ showLoginModal, showSignUpModal }: Props): ReactElement {
  /* const [language, setLanguage] = useState("En"); */
  return (
    <IMTMediaQueries>
      {(matches) => (
        <div id="home">
          <Row
            style={{ height: 60 /* ,backgroundColor: "#454545AA" */ }}
            align="middle"
          >
            <Col
              xs={{ span: 4, offset: 1 }}
              sm={{ span: 4, offset: 1 }}
              md={{ span: 4, offset: 2 }}
              lg={{ span: 4, offset: 2 }}
              xl={{ span: 4, offset: 2 }}
              xxl={{ span: 4, offset: 2 }}
              /* style={{ backgroundColor: "#45454589" }} */
            >
              <div
                style={{
                  height: 60,
                  alignItems: "center",
                  justifyContent: "flex-start",
                  display: "flex",
                }}
              >
                <img
                  src="/logo@2x.png"
                  style={{ width: 90, position: "relative", left: "-8px" }}
                  alt="imt-logo"
                />
                {/* <div className={styles.mb} style={{ fontSize: 10 }}>
                  {matches.xs ? " xs +" : ""}
                  {matches.sm ? " sm +" : ""}
                  {matches.md ? " md +" : ""}
                  {matches.lg ? " lg +" : ""}
                  {matches.xl ? " xl +" : ""}
                  {matches.xxl ? " xxl +" : ""}
                </div> */}
              </div>
            </Col>
            <Col
              className={styles.spaceHeaderWrap}
              xs={{ span: 18, offset: 0 }}
              sm={{ span: 18, offset: 0 }}
              md={{ span: 16, offset: 0 }}
              lg={16}
              xl={16}
              xxl={16}
              /* style={{ backgroundColor: "#AA454589" }} */
            >
              <Space
                className={styles.spaceHeader}
                /* size={matches.xxl ? 30 : 10} */
              >
                {matches.lg && (
                  <>
                    <Button
                      type="text"
                      className={styles.headerLinks}
                      onClick={scrollToView(
                        "#premium-services",
                        ga.events.imtlpHeader282
                      )}
                    >
                      Premium services
                    </Button>
                    <Button
                      className={styles.headerLinks}
                      type="text"
                      onClick={scrollToView(
                        "#pricing",
                        ga.events.imtlpHeader282
                      )}
                    >
                      Pricing
                    </Button>
                    <Button
                      className={styles.headerLinks}
                      type="text"
                      href={googleFormContactUs}
                      target="_blank"
                      onClick={() => {
                        registerEvent(
                          ga.EVENT_CATEGORY_BUTTON_CLICK,
                          ga.EVENT_CLICK,
                          ga.events.imtlpHeader282
                        );
                      }}
                    >
                      Contact
                    </Button>
                    <Button
                      type="text"
                      className={styles.headerLinks}
                      href={hiringForm}
                      target="_blank"
                      onClick={() => {
                        registerEvent(
                          ga.EVENT_CATEGORY_BUTTON_CLICK,
                          ga.EVENT_CLICK,
                          ga.events.imtlpHeader281
                        );
                      }}
                    >
                      <b className={styles.animatedText}>{"Hiring"}</b>
                    </Button>
                    <Button
                      type="text"
                      className={styles.headerLinks}
                      href={aboutUsLink}
                      target="_blank"
                      onClick={() => {
                        registerEvent(
                          ga.EVENT_CATEGORY_BUTTON_CLICK,
                          ga.EVENT_CLICK,
                          ga.events.imtlpHeader281
                        );
                      }}
                    >
                      About us
                    </Button>
                  </>
                )}
                <div
                  style={{
                    height: 60,
                    alignItems: "center",
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <Space>
                    {!matches.xs && (
                      <Button
                        type="default"
                        onClick={() => {
                          showLoginModal();
                          registerEvent(
                            ga.EVENT_CATEGORY_BUTTON_CLICK,
                            ga.EVENT_CLICK,
                            ga.events.imtlpHeader283
                          );
                        }}
                      >
                        Login
                      </Button>
                    )}
                    <div>
                      <Button
                        type="primary"
                        className={`${commonStyles["bg-processing"]} ${commonStyles["border-color-processing"]} ${styles.hoverTransparancy}`}
                        onClick={() => {
                          showSignUpModal();
                          registerEvent(
                            ga.EVENT_CATEGORY_BUTTON_CLICK,
                            ga.EVENT_CLICK,
                            ga.events.imtlpHeader284
                          );
                        }}
                      >
                        Start free trial
                      </Button>
                      <div
                        style={{
                          fontSize: 8,
                          fontWeight: 500,
                          marginBottom: -12,
                          marginTop: -1,
                          textAlign: "center",
                        }}
                      >
                        ( No credit card required )
                      </div>
                    </div>
                  </Space>
                  {!matches.lg && (
                    <Dropdown
                      overlay={menu}
                      trigger={["click"]}
                      placement="bottomRight"
                    >
                      <MenuOutlined className={styles.hamburger} />
                    </Dropdown>
                  )}
                </div>
              </Space>
            </Col>
            <Col xs={1} sm={1} md={2} span={2} />
          </Row>
          {/* <div className={styles.langSelectAltWrap}>
            <Select className={styles.dropdownSelect} defaultValue="En">
              <Option value="En">English</Option>
              <Option value="Gj">ગુજરાતી</Option>
              <Option value="Bn">বাংলা</Option>
            </Select>
          </div> */}
        </div>
      )}
    </IMTMediaQueries>
  );
}

export default connector(IMTLPHeader);
/* export default IMTLPHeader; */
