import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { mainAppSlice, doChangeCompany } from "./mainAppSlice";
import { modalSlice } from "./modalSlice";

const rootReducer = combineReducers({
  mainAppSlice: mainAppSlice.reducer,
  modalSlice: modalSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: {
    actionCreators: {
      ...modalSlice.actions,
    },
  },
});

export type AuthState = ReturnType<typeof rootReducer>;
export type StoreDispatch = typeof store.dispatch;

store.dispatch({ type: "imt/INIT" });

export const action = {
  auth: {
    ...mainAppSlice.actions,
    doChangeCompany,
  },
  modal: {
    ...modalSlice.actions,
    // checkStatusAndShowModal,
  },
};
