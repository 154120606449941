import { Select } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { getStateList } from "~/api/state";
import ReadOnlyableSelect from "~/component/field-input/ReadOnlyableSelect";

import commonStyles from "~/component/common.module.css";

interface Props {
  /**
   * Place of supply id
   */
  value?: number;
  onChange?: (placeOfSupplyId: number) => void;
  readOnly?: boolean;
  error?: boolean;
}

export default function PlaceOfSupply({
  value,
  onChange,
  readOnly,
  error,
}: Props): ReactElement {
  const [stateList, setStateList] = useState([] as any);
  const pickUpPlaceOfSupplyList = async () => {
    const { data } = await getStateList();
    setStateList(data);
  };

  useEffect(() => {
    pickUpPlaceOfSupplyList();
  }, []);
  return (
    // <>
    //   <Select value={value} onChange={onChange} showArrow showSearch>
    // {stateList.map((obj: { id: number; state_name: string }) => (
    //   <Select.Option key={obj.id} value={obj.id}>
    //     {obj.state_name}
    //   </Select.Option>
    // ))}
    //   </Select>
    // </>
    <ReadOnlyableSelect
      className={commonStyles["w-100"]}
      value={value}
      onChange={onChange}
      readonly={readOnly}
      optionFilterProp="children"
      showArrow
      showSearch
      id={"place_of_supply"}
      style={
        !error
          ? undefined
          : {
              border: "0.5px solid red",
            }
      }
    >
      {stateList.map((obj: { id: number; state_name: string }) => (
        <Select.Option key={obj.id} value={obj.id}>
          {obj.state_name}
        </Select.Option>
      ))}
    </ReadOnlyableSelect>
  );
}
