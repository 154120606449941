import React, { useEffect, useState } from "react";

export default function () {
  const [loadMsg, setLoadMsg] = useState("Loading...");
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
    setTimeout(() => {
      setLoadMsg("");
    }, 3000);
  }, []);

  return (
    <div
      class="calendly-inline-widget"
      data-url="https://calendly.com/tamalsen/15min?hide_event_type_details=1&hide_gdpr_banner=1"
      style={{ minWidth: "320px", height: "630px" }}
    >
      <span>{loadMsg}</span>
    </div>
  );
}
