import React from "react";
import styles from "./incubate.module.css";
import IIMB from "~/assets/incubate-image/IMB.png";
import IIM from "~/assets/incubate-image/IIMC.png";
const Incubate = () => {
  return (
    <div className={styles.inclubationetailsBox}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <b className={styles.theTitle}>Incubations</b>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className={styles.containerOne}
          onClick={() => {
            window.open("https://nsrcel.org/", "_blank");
          }}
        >
          <img
            src={IIMB}
            alt="NSRCEL - IIM Bangalore Incubation"
            className={styles.IIMBimage}
          />
        </div>
      </div>
    </div>
  );
};

export default Incubate;
