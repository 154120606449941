import { fetchBlobNg, fetchUtil } from "./common";

export type ProcessDropdownItem = {
  process_id: number;
  label: string;
  value: number;
  start_time: "";
};

export type ProcessId = {
  process_id: number;
};

export type EntityList = {
  customer: Array<{ customer_id: number; gstin: string }>;
  product: Array<number>;
  product_category: Array<number>;
  units: Array<number>;
  tax_ledger: Array<number>;
  consumer: Array<number>;
  expense_category: Array<number>;
  sales_voucher: Array<number>;
  purchase_voucher: Array<number>;
};

export const getProcessDropdown = async (): Promise<{
  ok: boolean;
  message: string;
  data?: Array<ProcessDropdownItem>;
}> => {
  const { ok, message, json } = await fetchUtil("GET", "/get_process_dropdown");
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};

export const getProcessStepsTable = async (payload: ProcessId) => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/get_process_steps_table",
    payload
  );
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};

export const submitTallyBackward = async (entityList: EntityList) => {
  const { ok, message, json } = await fetchUtil("POST", "/entity_sync_back", {
    entity_list: entityList,
  });

  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};

export const getTallyBackwardData = async () => {
  const items = [
    "expense",
    "invoice",
    "customers",
    "product",
    "expense category",
    "units",
    "tax rates",
    "product category",
  ];
  const params = new URLSearchParams();
  params.set("source", "tally");
  params.set("item_type", items.join(","));

  const { ok, message, json } = await fetchUtil(
    "GET",
    "/sync/dirty?" + params.toString()
  );
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};

export const getSyncConfig = async () => {
  const { ok, message, json } = await fetchUtil("GET", "/get_sync_config");
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};

export const getSyncStatus = async () => {
  return fetchUtil("GET", "/get_sync_status");
};

export const getImportCSVReportBlob = async (processId: number) => {
  return fetchBlobNg(
    "GET",
    `/get_tally_import_feedback_csv?process_id=${processId}`
  );
};
