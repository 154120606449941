import React from "react";
import { saveAs } from "file-saver";
import { Button, Space } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import styles from "~/component/JsonDownloadPreview.module.css";
type Props = {
  JSONToViewString: string;
  setLoading: any;
  name: string;
};
function ExportFunctionality({ JSONToViewString, setLoading, name }: Props) {
  return (
    <div>
      <Space>
        <Button
          className={styles.ButtonExport}
          type="primary"
          icon={<DownloadOutlined />}
          // tslint:disable-next-line: jsx-no-lambda
          onClick={async () => {
            try {
              await setLoading(true);
              const jsonData = JSON.parse(JSONToViewString);
              if (jsonData?.doc_issue) {
                jsonData.doc_issue.doc_det = jsonData?.doc_issue?.doc_det.map(
                  (item: any) => {
                    const allZeros = item.docs.every((doc: any) => {
                      return Object.values(doc).every(
                        (val) => val === 0 || val === "0"
                      );
                    });
                    if (allZeros) {
                      return { docs: [], doc_num: item.doc_num };
                    } else {
                      return item;
                    }
                  }
                );
                const allEmpty = jsonData?.doc_issue?.doc_det.every(
                  (item: any) => item.docs.length === 0
                );
                jsonData.doc_issue.doc_det = allEmpty
                  ? []
                  : jsonData?.doc_issue?.doc_det;
              }
              const file = new Blob([JSON.stringify(jsonData, null, 2)], {
                type: "application/json",
              });
              await saveAs(file, name);
            } catch (err) {
              console.error(err);
            } finally {
              setLoading(false);
            }
          }}
        >
          Export JSON
        </Button>
      </Space>
    </div>
  );
}

export default ExportFunctionality;
