import { PAN_VALIDATION_REGEX } from "~/lib/constants";
import { LoginRole } from "~/api/auth";
import { __DateAtLoad } from "./lib";

const userData = localStorage.getItem("imt__user");
const userParseData = JSON.parse(userData!);
export function panNumberVerification(panNumber: string) {
  let isValidPan = false;
  const regxPatt = new RegExp(PAN_VALIDATION_REGEX);
  isValidPan = regxPatt.test(panNumber);
  return isValidPan;
}

export function differentCompFileCheck(ocrData: any) {
  let isSameCompanyGstin = true;
  const gstinList = ocrData[0]?.raw?.GSTIN;
  const uniqueGstinArray = gstinList.filter((item: any, pos: number) => {
    return gstinList.indexOf(item) === pos;
  });
  if (uniqueGstinArray.length > 1) {
    isSameCompanyGstin = uniqueGstinArray.some((e: any) => {
      return e === userParseData.gstin;
    });
  }
  return isSameCompanyGstin;
}

export function isSameCompany(rawOcrData: any) {
  let isSameCompanyGstin = true;
  const userData = JSON.parse(localStorage.getItem("imt__user")!);
  const gstinList = rawOcrData?.raw?.GSTIN;
  const uniqueGstinArray = gstinList.filter((item: any, pos: number) => {
    return gstinList.indexOf(item) === pos;
  });
  if (uniqueGstinArray.length) {
    isSameCompanyGstin = uniqueGstinArray.some((e: any) => {
      return e === userData.gstin;
    });
  }
  return isSameCompanyGstin;
}

export function quaterFind(taxPeriod: string) {
  let quater = "";
  if (taxPeriod === "April-June") quater = "Q1";
  else if (taxPeriod === "July-September") quater = "Q2";
  else if (taxPeriod === "October-December") quater = "Q3";
  else if (taxPeriod === "January-March") quater = "Q4";
  return quater;
}

export function categoryFind(categoryType?: string) {
  switch (categoryType) {
    case "general":
      return "Category type: General";
    case "salary":
      return "Category type: Salary";
    case "property":
      return "Category type: Property";
    case "rent":
      return "Category type: Rent";
    default:
      return "";
  }
}

export function setDateWithTimeZone(date?: Date) {
  date = new Date(date!.getTime() - date!.getTimezoneOffset() * 60000);
  return date;
}

/**
 * As the backend API stripes Date & discard time, in a multi-time-zone system we can only add the offset,
 * so the date part remains the same between user's timezone & server's UTC.
 * This function substracts negative offset w.r.t UTC (effictively increasing time) to increase the date.
 * e.g.
 *  Input: 2021-10-18T18:30:00.0z
 *  Output: 2021-10-19T00:00:00.0z
 */
export function __hackAdjustDatePortionForBackendWrite(date: Date) {
  const plusDateTimeWithAdjustedDate = new Date(
    date.getTime() - __DateAtLoad.getTimezoneOffset() * 60000
  );
  return plusDateTimeWithAdjustedDate;
}

export function withWriteAccess(activeRoleId: number) {
  switch (activeRoleId) {
    case LoginRole.ADMIN:
      return true;
    case LoginRole.SUPERuSER:
      return true;
    default:
      return false;
  }
}

export function sleep(ms: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, ms));
}
