import { useHistory } from "react-router";
import { useEffect, useRef } from "react";

type UnregisterCallback = () => void;

// ref: https://github.com/remix-run/react-router/issues/7286#issue-607870832
export default function usePrompt(message: string, when: boolean) {
  const history = useHistory();
  const unblock = useRef<UnregisterCallback | null>(null);

  useEffect(() => {
    if (when) {
      unblock.current = history.block(message);
    } else {
      unblock.current = null;
    }
    return () => {
      if (unblock.current) {
        unblock.current();
      }
    };
  }, [when, history, message]);
}
