import React, { ReactElement, SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

function LeftArrow({ width, height, ...rest }: Props): ReactElement {
  width = width || 21;
  height = height || 16;
  return (
    <svg
      viewBox="0 0 21 16"
      fill="none"
      width={width}
      height={height}
      {...rest}
    >
      <path
        d="M.293 7.293a1 1 0 000 1.414l6.364 6.364a1 1 0 001.414-1.414L2.414 8l5.657-5.657A1 1 0 006.657.93L.293 7.293zM21 7H1v2h20V7z"
        fill="#000"
      />
    </svg>
  );
}

export default LeftArrow;
