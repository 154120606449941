import React, { Component } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  Spin,
} from "antd";
import {
  OnboardStoreState,
  action,
  IdentityDetails,
} from "~/app/OnboardCompany/store";
import { connect, ConnectedProps } from "react-redux";
import { FormInstance } from "antd/lib/form";
import { Required } from "~/lib/form_rules";
import { fetchUtil } from "~/api/common";
import FieldInfo from "~/component/FieldInfo";
import DatePicker from "~/component/antd-overrides/DatePicker";
import { FORMAT_DATE, __DateAtLoad } from "~/lib";
import commonStyle from "~/component/common.module.css";
import { isFuture } from "date-fns";
import { CheckCircleOutlined } from "@ant-design/icons";
import { panNumberVerification } from "~/utils";
import { PAN_VALIDATION_REGEX } from "~/lib/constants";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (state: OnboardStoreState) => ({
  initialValues: state.onboard.identityDetails,
  basicDetails: state.onboard.basicDetails,
});
const mapDispatchToProps = {
  onBack: action.previousStep,
  finishStep: action.finishStep,
  advanceStep: action.advanceStep,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  doNotUseMe?: never;
}
interface State {
  disable: boolean;
  stateList: Array<{
    id: number;
    state_name: string;
  }>;
  isLoading: boolean;
  tanVerifyLoader: boolean;
  errMsgTan: string;
  isTanVerified: boolean;
  gstinTypeList: Array<{
    id: number;
    gst_type: string;
  }>;
  place_of_supply?: number;
}

class StepIdentity extends Component<Props, State> {
  state = {
    disable: false,
    stateList: [],
    isLoading: false,
    tanVerifyLoader: false,
    errMsgTan: "",
    isTanVerified: false,
    gstinTypeList: [],
    place_of_supply: this.props.initialValues.place_of_supply,
  };
  // tslint:disable-next-line: variable-name
  _formRef = React.createRef<FormInstance<Partial<IdentityDetails>>>();
  componentDidMount() {
    if (this.props.initialValues.gst_registration_type === 3) {
      this.setState({
        disable: true,
      });
    }
    this.getGstinType();
    this.getStateList();
    if (this.props.initialValues.pan) {
      this.getPtRegistartionNo(this.props.initialValues.pan);
    }
  }
  async getGstinType() {
    const { ok, json, message } = await fetchUtil(
      "GET",
      `/get_gst_registration_types`
    );

    if (ok) {
      const data = json;
      this.setState({
        gstinTypeList: data,
      });
    } else {
      notification.error({ message });
    }
  }

  async getPtRegistartionNo(pan: string) {
    this.setState({
      isLoading: true,
    });
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/profession_tax/fetch_ptax_from_pan`,
      {
        pan_number: pan,
      }
    );
    if (ok) {
      if (this._formRef.current && json.length !== 0) {
        this._formRef.current.setFieldsValue({
          ptax_registration_no: json[0].registration_no,
        });
      }
      if (this._formRef.current && json.length === 0) {
        this._formRef.current.setFieldsValue({
          ptax_registration_no: "",
        });
      }
      this.setState({
        isLoading: false,
      });
    } else {
      notification.error({ message });
    }
  }

  async getStateList() {
    const { ok, json, message } = await fetchUtil("GET", `/get_states`);
    this.setState({
      isLoading: true,
    });
    if (ok) {
      const data = json;
      this.setState({
        stateList: data,
        isLoading: false,
      });
    } else {
      notification.error({ message });
    }
  }

  onFinish = (values: any) => {
    if (!this.state.isTanVerified && !this.props.initialValues.tan_verified) {
      // values.tan = "";
      values.tan_verified = false;
    } else {
      values.tan_verified = true;
    }
    this.props.finishStep({
      step: "identity",
      data: values,
    });
    this.props.advanceStep();
  };

  handlePrev = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.stepIdentity179
    );
    // console.log(!this.props.initialValues.tan_verified);
    const formValues = this._formRef.current?.getFieldsValue();
    if (!this.state.isTanVerified && !this.props.initialValues.tan_verified) {
      // formValues.tan = "";
      formValues.tan_verified = false;
    } else {
      formValues.tan_verified = true;
    }
    // console.log(formValues);
    this.props.onBack({
      step: "identity",
      data: formValues,
    });
  };

  handleTanVerify = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.stepIdentity180
    );
    const formValues = this._formRef.current?.getFieldsValue();
    const tanRegex = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
    if (!tanRegex.test(formValues.tan)) {
      return notification.error({
        message: "please enter a valid tan",
      });
    }
    if (formValues.tan) {
      this.setState({
        tanVerifyLoader: true,
      });
      const { ok } = await fetchUtil("POST", `/tan_verify`, {
        tan_no: formValues.tan,
        legal_name: this.props?.basicDetails?.legal_name,
        company_name: this.props?.basicDetails?.name,
      });
      if (ok) {
        this.setState({
          errMsgTan: "",
          tanVerifyLoader: false,
          isTanVerified: true,
        });
      } else {
        this.setState({
          errMsgTan: "Tan verification failed",
          tanVerifyLoader: false,
          isTanVerified: false,
        });
      }
    } else {
      notification.error({
        message: "please enter a valid tan",
      });
    }
  };

  handleOnValueCHange = async (changedValues: any, values: any) => {
    if (changedValues.gst_registration_type === 3) {
      this.setState({
        disable: true,
      });
      if (this._formRef.current) {
        // const gstinNo = changedValues.target.value?.toUpperCase();

        this._formRef.current.setFieldsValue({
          gstin: "",
        });
      }
    } else if (changedValues.gst_registration_type) {
      if (changedValues.gst_registration_type !== 3) {
        this.setState({
          disable: false,
        });
      }
    }
    if (changedValues.gstin) {
      // console.log(changedValues.gstin);
      if (String(changedValues.gstin).length === 15) {
        const stateCode = String(changedValues.gstin).substring(0, 2);

        const panNumber = String(changedValues.gstin)
          .substring(2, 12)
          .toUpperCase();

        const gstinNo = String(changedValues.gstin).toUpperCase();

        this.getPtRegistartionNo(panNumber);

        if (this._formRef.current) {
          this._formRef.current.setFieldsValue({
            pan: panNumber,
            gstin: gstinNo,
            place_of_supply: Number(stateCode),
          });
        }
      } else {
        const panNumber = String(changedValues.gstin)
          .substring(2, 12)
          .toUpperCase();

        if (this._formRef.current) {
          this._formRef.current.setFieldsValue({
            pan: panNumber,
            place_of_supply: 0,
            ptax_registration_no: "",
          });
        }
      }
    }
    if (changedValues.pan) {
      const pan = changedValues.pan.toUpperCase();
      this._formRef.current?.setFieldsValue({
        pan,
      });
      if (panNumberVerification(pan)) {
        this.getPtRegistartionNo(pan);
      }
    }
    if (changedValues.tan) {
      if (changedValues.tan.length < 10 || changedValues.tan.length > 10) {
        this.setState({
          errMsgTan: "",
        });
      }
    }
  };

  render() {
    return (
      <Spin spinning={this.state.isLoading}>
        <Form
          initialValues={this.props.initialValues}
          ref={this._formRef}
          onValuesChange={this.handleOnValueCHange}
          onFinish={this.onFinish}
        >
          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="gst_registration_type"
                label="GST registration type"
                rules={Required("GST registration type")}
              >
                <Select showSearch optionFilterProp="children">
                  {this.state.gstinTypeList.map(
                    (gstin: { id: number; gst_type: string }) => (
                      <Select.Option key={gstin.id} value={gstin.id}>
                        {gstin.gst_type}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              {this.state.disable === false ? (
                <>
                  <Form.Item
                    name="gstin"
                    label="GSTIN"
                    rules={[
                      {
                        required: true,
                        type: "string",
                        message: "Please enter valid GSTIN",
                      },
                    ]}
                  >
                    <Input
                      disabled={
                        (this.props.initialValues.gstin &&
                          this.props.initialValues.gstin?.length > 0) ||
                        false
                      }
                    />
                  </Form.Item>
                  <FieldInfo
                    // tslint:disable-next-line: jsx-no-lambda
                    text={() => {
                      for (
                        let i: number = 0;
                        i < this.state.stateList.length;
                        i++
                      ) {
                        const l: { id: number; state_name: string } = this.state
                          .stateList[i];
                        if (l.id === this.state.place_of_supply) {
                          return l.state_name;
                        }
                      }
                      return "";
                    }}
                  />
                </>
              ) : null}
            </Col>
          </Row>
          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="pan"
                label="PAN"
                rules={[
                  {
                    required: true,
                    type: "string",
                    pattern: PAN_VALIDATION_REGEX,
                    message: "Please enter valid PAN",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              {this.state.disable === false ? (
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="gstin_registration_date"
                  label="Gst registration Date"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a date",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={isFuture}
                    defaultValue={__DateAtLoad}
                    format={FORMAT_DATE}
                    className={commonStyle["w-100"]}
                  />
                </Form.Item>
              ) : null}
            </Col>
          </Row>

          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Profession tax number"
                name="ptax_registration_no"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="pf_registration_no"
                label="PF registration number"
                rules={[
                  {
                    required: false,
                    type: "string",
                    // pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                    message: "Please enter valid PF registration number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="esi_registration_no"
                label="ESI registration number"
                rules={[
                  {
                    required: false,
                    type: "string",
                    // pattern: /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/,
                    message: "Please enter valid ESI registration number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="udyog_aadhaar_no"
                label="Udyog adhaar no"
                rules={[
                  {
                    required: false,
                    type: "string",
                    // pattern: /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/,
                    message: "Please enter valid Udyog aadhaar number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "100%" }}>
                  <Form.Item
                    name="tan"
                    label="TAN"
                    rules={[
                      {
                        required: false,
                        type: "string",
                        pattern: /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/,
                        message: "Please enter valid TAN",
                      },
                    ]}
                  >
                    <Input readOnly={this.state.isTanVerified} />
                  </Form.Item>
                  {this.state.errMsgTan ? (
                    <div style={{ marginTop: "4px" }}>
                      <FieldInfo text={`${this.state.errMsgTan}`} color="red" />
                    </div>
                  ) : null}
                </div>
                {this.state.isTanVerified ||
                this.props.initialValues.tan_verified ? (
                  <span style={{ border: "2ox solid red" }}>
                    <CheckCircleOutlined
                      style={{
                        padding: "8px",
                        fontWeight: 1400,
                        fontSize: "25px",
                        color: "green",
                        marginLeft: "14px",
                        marginTop: "28px",
                      }}
                    />
                  </span>
                ) : (
                  <span>
                    <Button
                      type="dashed"
                      style={{
                        marginLeft: "5px",
                        color: "#2F80ED",
                        marginTop: "32px",
                      }}
                      onClick={this.handleTanVerify}
                      loading={this.state.tanVerifyLoader}
                    >
                      {this.state.errMsgTan ? "Retry" : "Verify"}
                    </Button>
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Row justify="end" gutter={16}>
            <Col>
              <Button type="ghost" size="middle" onClick={this.handlePrev}>
                Back
              </Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" size="middle">
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  }
}
export default connector(StepIdentity);
