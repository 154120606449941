import React, { Component } from "react";
import {
  List,
  Collapse,
  Input,
  Table,
  notification,
  Button,
  Divider,
  Select,
  Space,
  Modal,
} from "antd";
import { ColumnType } from "antd/lib/table";
import {
  PlusOutlined,
  MinusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { connect, ConnectedProps } from "react-redux";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import Currency from "~/component/Currency";
import RowAttachField from "~/fragment/attachable-field/RowAttachField";
import { LoginRole } from "~/api/auth";
import { fetchUtil } from "~/api/common";
import styles from "./BenefitPane.module.css";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";
import { DraggableModalProvider } from "ant-design-draggable-modal";

const { Panel } = Collapse;

const mapStateToProps = (
  state: AuthState,
  ownProps: { employeeID: number }
) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  employeeID: ownProps.employeeID,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  createCompanyDetails: (company_name: any) =>
    dispatch(action.auth.createCompanyDetails(company_name)),
  empDetailsSaved: (saveDetails: boolean) =>
    dispatch(action.auth.empDetailsSaved(saveDetails)),
  handleTermsModal: (agreeTerms: boolean) =>
    dispatch(action.auth.handleTermsModal(agreeTerms)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  employeeID: number;
  currentTab: string;
  isTabUpdated: boolean;
  changeTabUpdate: () => void;
  active: boolean;
}

type NewBenefits = {
  id?: number;
  benefit_id: number;
  carrier_name: string;
  policy_name: string;
  policy_number: string;
  policy_document: string;
  policy_document_name: string;
  coverage_amount: number;
  isEdited: boolean;
  is_deleted: boolean;
};

type CarrierMaster = {
  carrier_name: string;
};

interface State {
  benefitMasters: Array<any>;
  carrierMasters: Array<CarrierMaster>;
  newBenefits: Array<NewBenefits>;
  dataSourceForTable: Array<any>;
  loading: boolean;
  totalCoverage: number;
}

class BenefitPane extends Component<Props, State> {
  state: State = {
    benefitMasters: [],
    carrierMasters: [],
    newBenefits: [],
    dataSourceForTable: [
      {
        benefit_id: 0,
        carrier_name: "",
        policy_name: "",
        policy_number: "",
        policy_document: "",
        policy_document_name: "",
        coverage_amount: 0,
        isEdited: false,
        is_deleted: false,
      },
    ],
    loading: false,
    totalCoverage: 0,
  };

  static createNewLineItem(carrierName: string): NewBenefits {
    return {
      benefit_id: 0,
      carrier_name: carrierName,
      policy_name: "",
      policy_number: "",
      policy_document: "",
      policy_document_name: "",
      coverage_amount: 0,
      isEdited: false,
      is_deleted: false,
    };
  }

  componentDidMount() {
    this.getCarrierMaster();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.currentTab === "benefit" && !this.props.isTabUpdated) {
      this.getCarrierMaster();
      this.props.changeTabUpdate();
    }
    if (this.props.activeRole !== prevProps.activeRole) {
      this.getCarrierMaster();
    }
  }

  getBenefitMaster = async () => {
    const { ok, json } = await fetchUtil("GET", `/get_benefits_master_data`);
    if (ok) {
      if (json.length) {
        this.setState({ benefitMasters: json });
        this.getBenefitDetails();
      }
    }
  };

  getCarrierMaster = async () => {
    const { ok, json } = await fetchUtil(
      "GET",
      `/fetch_insurance_carrier_master`
    );
    if (ok) {
      if (json.length) {
        this.setState({ carrierMasters: json });
        this.getBenefitMaster();
      }
    }
  };

  getBenefitDetails = async () => {
    const { ok, json } = await fetchUtil("POST", `/fetch_employee_benefits`, {
      emp_id: this.props.employeeID,
    });
    if (ok) {
      if (json.length) {
        const newBenefits: Array<NewBenefits> = [];
        json.forEach((value) => {
          const {
            id,
            benefit_id,
            carrier_name,
            policy_name,
            coverage_amount,
            policy_document,
            policy_document_name,
            policy_number,
          } = value;
          newBenefits.push({
            id,
            benefit_id,
            carrier_name,
            policy_name,
            coverage_amount,
            policy_document,
            policy_document_name,
            policy_number,
            isEdited: false,
            is_deleted: false,
          });
        });
        this.setState({
          newBenefits,
          dataSourceForTable: [...newBenefits],
        });
        this.calculateTotalSum();
      } else {
        this.setState({
          dataSourceForTable: [
            BenefitPane.createNewLineItem(
              this.state.carrierMasters[0].carrier_name
            ),
          ],
        });
      }
    }
  };

  handleDocChange = (
    newFileName: string,
    documentsName?: string,
    idx?: any,
    lineItemValues?: any
  ) => {
    const { newBenefits, dataSourceForTable } = this.state;
    if (dataSourceForTable[idx].id) {
      newBenefits.forEach((value, index) => {
        if (value.id === dataSourceForTable[idx].id) {
          newBenefits[index].isEdited = true;
          newBenefits[index].policy_document = newFileName;
          newBenefits[index].policy_document_name = documentsName!;
        }
      });
    }
    dataSourceForTable[idx].policy_document = newFileName;
    dataSourceForTable[idx].policy_document_name = documentsName!;
    dataSourceForTable[idx].isEdited = true;
    this.setState({ newBenefits });
  };

  saveBenefits = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.benefitPane48
    );

    const { employeeID } = this.props;
    const { newBenefits, dataSourceForTable } = this.state;
    let error = 0;
    const onlyDelete = false;
    if (dataSourceForTable.length === 1 && newBenefits.length > 0) {
      // unuseds
    } else {
      dataSourceForTable.forEach((value) => {
        const { policy_name, policy_number, coverage_amount } = value;
        if (
          policy_name.length === 0 ||
          policy_number.length === 0 ||
          Number(coverage_amount) <= 0
        ) {
          notification.error({
            message: "Please fill the details",
          });
          error = 1;
          return;
        }
      });
      if (error) {
        return;
      }
    }

    const saveList: any = [];
    if (newBenefits.length > 0) {
      newBenefits.forEach((value) => {
        const {
          id,
          benefit_id,
          carrier_name,
          policy_name,
          policy_number,
          policy_document,
          policy_document_name,
          coverage_amount,
          is_deleted,
          isEdited,
        } = value;
        if (value.id) {
          if (isEdited) {
            saveList.push({
              id,
              emp_id: employeeID,
              benefit_id,
              carrier_name,
              policy_name,
              policy_number,
              policy_document,
              policy_document_name,
              coverage_amount,
              is_deleted,
            });
          }
        }
      });
    }
    dataSourceForTable.forEach((value) => {
      const {
        benefit_id,
        carrier_name,
        policy_name,
        policy_number,
        policy_document,
        policy_document_name,
        coverage_amount,
        isEdited,
        is_deleted,
      } = value;
      if (!value.id) {
        if (isEdited && !onlyDelete) {
          saveList.push({
            emp_id: employeeID,
            benefit_id,
            carrier_name,
            policy_name,
            policy_number,
            policy_document,
            policy_document_name,
            coverage_amount,
            is_deleted,
          });
        }
      }
    });

    const { ok, message } = await fetchUtil(
      "POST",
      "/save_health_insurance_detail",
      {
        medical_benefits: saveList,
      }
    );
    if (ok) {
      notification.success({
        message,
      });
      this.getBenefitDetails();
    } else {
      notification.error({
        message,
      });
    }
  };

  calculateTotalSum = () => {
    const { dataSourceForTable } = this.state;
    let total = 0;
    dataSourceForTable.forEach((value) => {
      if (value.id && !value.is_deleted) {
        total = total + Number(value.coverage_amount);
      } else {
        total = total + Number(value.coverage_amount);
      }
    });
    this.setState({ totalCoverage: total });
  };

  handleSectionInfo = (benefitId: number) => {
    const {
      carrierMasters,
      newBenefits,
      totalCoverage,
      dataSourceForTable,
    } = this.state;
    const { activeRole } = this.props;

    dataSourceForTable.forEach((benefit, index) => {
      benefit.benefit_id = benefitId;
    });

    const onRowAdd = () => {
      this.setState({
        dataSourceForTable: [
          ...dataSourceForTable,
          BenefitPane.createNewLineItem(carrierMasters[0].carrier_name),
        ],
      });
    };

    const onRowDelete = (idx: number) => {
      if (dataSourceForTable.length === 1 && newBenefits.length > 0) {
        const { confirm } = Modal;
        confirm({
          title: "Do you want to delete all the benefits for this employee?",
          icon: <ExclamationCircleOutlined />,
          content:
            "This action is irreversible. All the details will be permanently deleted.",
          okText: "Yes",
          onOk: async () => {
            newBenefits[idx].isEdited = true;
            newBenefits[idx].is_deleted = true;
            this.saveBenefits();
            this.setState(
              {
                newBenefits: [],
                dataSourceForTable: [
                  BenefitPane.createNewLineItem(carrierMasters[0].carrier_name),
                ],
              },
              () => {
                this.calculateTotalSum();
              }
            );
          },
          onCancel: () => {
            // unuseds
          },
        });
      } else {
        if (dataSourceForTable.length > 0) {
          if (dataSourceForTable[idx].id) {
            newBenefits.forEach((value, index) => {
              if (value.id === dataSourceForTable[idx].id) {
                value.isEdited = true;
                value.is_deleted = true;
              }
            });
            dataSourceForTable.splice(idx, 1);
          } else {
            dataSourceForTable.splice(idx, 1);
          }
          this.setState({ newBenefits, dataSourceForTable });
          this.calculateTotalSum();
        }
      }
    };

    const { active } = this.props;

    const columnsDef: Array<ColumnType<any>> = [
      {
        title: "",
        key: "add_action",
        width: "2%",
        className: styles.actionCell,
        render: (_, _record, idx) => {
          return activeRole === LoginRole.ADMIN ||
            activeRole === LoginRole.SUPERuSER ? (
            dataSourceForTable.length - 1 === idx ? (
              <Space className={styles.errorSpace}>
                <Button
                  size="small"
                  icon={
                    <PlusOutlined style={{ color: "var(--success-color)" }} />
                  }
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => onRowAdd()}
                  disabled={!active}
                />
              </Space>
            ) : null
          ) : null;
        },
      },
      {
        title: "Carrier name",
        dataIndex: "carrier_name",
        width: "20%",
        render: (_value, _rec, idx) => {
          return (
            <Select
              className={styles.select}
              dropdownMatchSelectWidth={false}
              value={dataSourceForTable[idx].carrier_name}
              disabled={
                activeRole === LoginRole.CA ||
                activeRole === LoginRole.EMPLOYEE ||
                !active
              }
              // tslint:disable-next-line: jsx-no-lambda
              onChange={(newValue) => {
                if (dataSourceForTable[idx].id) {
                  newBenefits.forEach((value, index) => {
                    if (value.id === dataSourceForTable[idx].id) {
                      newBenefits[index].isEdited = true;
                      newBenefits[index].carrier_name = newValue;
                    }
                  });
                }
                dataSourceForTable[idx].carrier_name = newValue;
                dataSourceForTable[idx].isEdited = true;
                this.setState({ newBenefits });
              }}
            >
              {carrierMasters.map((opt: any) => (
                <Select.Option
                  className={styles.option}
                  key={opt.id}
                  value={opt.carrier_name}
                >
                  {opt.carrier_name}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
      {
        title: "Policy Name",
        dataIndex: "policy_name",
        width: "20%",
        render: (_value, _rec, idx) => {
          return (
            <Input
              value={dataSourceForTable[idx].policy_name}
              readOnly={
                activeRole === LoginRole.CA ||
                activeRole === LoginRole.EMPLOYEE ||
                !active
              }
              // tslint:disable-next-line: jsx-no-lambda
              onChange={(e: any) => {
                if (dataSourceForTable[idx].id) {
                  newBenefits.forEach((value, index) => {
                    if (value.id === dataSourceForTable[idx].id) {
                      newBenefits[index].isEdited = true;
                      newBenefits[index].policy_name = e.target.value;
                    }
                  });
                }
                dataSourceForTable[idx].policy_name = e.target.value;
                dataSourceForTable[idx].isEdited = true;
                this.setState({ newBenefits, dataSourceForTable });
              }}
            />
          );
        },
      },
      {
        title: "Policy number",
        dataIndex: "description",
        width: "20%",
        render: (_value, _rec, idx) => {
          return (
            <Input
              readOnly={
                activeRole === LoginRole.CA ||
                activeRole === LoginRole.EMPLOYEE ||
                !active
              }
              value={dataSourceForTable[idx].policy_number}
              // tslint:disable-next-line: jsx-no-lambda
              onChange={(e: any) => {
                if (dataSourceForTable[idx].id) {
                  newBenefits.forEach((value, index) => {
                    if (value.id === dataSourceForTable[idx].id) {
                      newBenefits[index].isEdited = true;
                      newBenefits[index].policy_number = e.target.value;
                    }
                  });
                }
                dataSourceForTable[idx].policy_number = e.target.value;
                dataSourceForTable[idx].isEdited = true;
                this.setState({ newBenefits, dataSourceForTable });
              }}
            />
          );
        },
      },
      {
        title: "Policy Document",
        dataIndex: "file_name",
        width: "20%",
        render: (_value, _rec, idx) => {
          return active ? (
            <RowAttachField
              fieldName="document"
              hashFileName={dataSourceForTable[idx].policy_document}
              disable={
                activeRole === LoginRole.CA || activeRole === LoginRole.EMPLOYEE
              }
              activeRole={activeRole}
              isNew={false}
              documentName={dataSourceForTable[idx].policy_document_name}
              idx={idx}
              onHashFileChange={this.handleDocChange}
              readonly={false}
              label="Document"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Please upload docs!",
                },
              ]}
              selectedFy={"NA"}
              opts_fy={["NA", "NA"]}
              from="Benefit"
            />
          ) : (
            "No data found !"
          );
        },
      },
      {
        title: "Coverage amount",
        dataIndex: "amount",
        width: "20%",
        align: "right",
        render: (_value, _rec, idx) => {
          return (
            <Input
              value={dataSourceForTable[idx].coverage_amount}
              readOnly={
                activeRole === LoginRole.CA ||
                activeRole === LoginRole.EMPLOYEE ||
                !active
              }
              style={{ textAlign: "right" }}
              // tslint:disable-next-line: jsx-no-lambda
              onChange={(e: any) => {
                if (dataSourceForTable[idx].id) {
                  newBenefits.forEach((value, index) => {
                    if (value.id === dataSourceForTable[idx].id) {
                      newBenefits[index].isEdited = true;
                      newBenefits[index].coverage_amount = e.target.value;
                    }
                  });
                }
                // tslint:disable-next-line: no-shadowed-variable
                dataSourceForTable[idx].coverage_amount = e.target.value;
                dataSourceForTable[idx].isEdited = true;
                this.setState({ newBenefits });
                this.calculateTotalSum();
              }}
            />
          );
        },
      },
      {
        title: "",
        key: "delete_action",
        className: styles.actionCell,
        width: "2%",
        render: (_, record, idx) => {
          return activeRole === LoginRole.ADMIN ||
            activeRole === LoginRole.SUPERuSER ? (
            newBenefits.length === 0 &&
            dataSourceForTable.length === 1 ? null : (
              <div>
                <Button
                  size="small"
                  danger
                  title="Remove line item"
                  icon={<MinusOutlined />}
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => onRowDelete(idx)}
                />
              </div>
            )
          ) : null;
        },
      },
    ];
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              justifyContent: "right",
              marginTop: "10px",
              display: "flex",
            }}
          >
            <Table
              style={{ flexBasis: "100%" }}
              columns={columnsDef}
              dataSource={[...dataSourceForTable]}
              rowKey="row_details_id"
              loading={this.state.loading}
              // tslint:disable-next-line: jsx-no-lambda
              footer={(_data: Array<any>) => {
                return (
                  <>
                    <div className={styles.footerWrap}>
                      <div className={styles.footer}>
                        <div className={styles.footerRow}>
                          <span className={styles.footerLabel}>
                            Total Coverage
                          </span>
                          <Currency
                            className={styles.footerAmount}
                            currencySymbol={"₹"}
                          >
                            {totalCoverage}
                          </Currency>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }}
              pagination={false}
            />
          </div>
          <div>
            {activeRole === LoginRole.ADMIN ||
            activeRole === LoginRole.SUPERuSER ? (
              this.props.active ||
              this.props.activeRole === LoginRole.EMPLOYEE ? (
                <div
                  style={{
                    justifyContent: "right",
                    marginTop: "10px",
                    display: "flex",
                    marginBottom: 20,
                  }}
                >
                  <Button
                    type="primary"
                    disabled={dataSourceForTable[0].benefit_id === 0}
                    onClick={this.saveBenefits}
                  >
                    Save
                  </Button>
                </div>
              ) : null
            ) : null}
          </div>
        </div>
      </>
    );
  };

  renderCollapseItem = () => {
    const { benefitMasters } = this.state;
    return (
      <>
        {benefitMasters.map((value: any) => {
          return (
            <Panel
              key={value.id}
              style={{
                borderBottom: "1px solid var(--grey-3)",
              }}
              header={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <List.Item.Meta
                    title={<a href="#wip">{value.benefit_name}</a>}
                  />
                </div>
              }
              showArrow={false}
            >
              {this.handleSectionInfo(value.id)}
            </Panel>
          );
        })}
      </>
    );
  };

  render() {
    const { benefitMasters, newBenefits } = this.state;
    const { activeRole } = this.props;
    const activeKeys = benefitMasters.map((_, index) => index + 1);
    const collapseAble = (
      <div>
        <Collapse activeKey={activeKeys} className={styles.lvl1List} ghost>
          <Divider />
          {this.renderCollapseItem()}
        </Collapse>
      </div>
    );
    return (
      <DraggableModalProvider>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "70%",
            minHeight: "calc(100vh - 250px)",
          }}
        >
          {activeRole === LoginRole.EMPLOYEE || activeRole === LoginRole.CA ? (
            newBenefits.length ? (
              collapseAble
            ) : (
              <b>
                Any benefits & perk's provided by your company will appear here.
              </b>
            )
          ) : activeRole === LoginRole.ADMIN ||
            activeRole === LoginRole.SUPERuSER ? (
            collapseAble
          ) : null}
        </div>
      </DraggableModalProvider>
    );
  }
}

export default connector(BenefitPane);
