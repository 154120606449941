import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const TawkToWidget: React.FC = () => {
  useEffect(() => {
    // Tawk.to script with your Widget ID and Property ID
    const tawkToScript = document.createElement("script");
    tawkToScript.type = "text/javascript";
    tawkToScript.text = `
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/63f2fa9531ebfa0fe7ee2dc1/1gpmi5n3q';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
      })();
    `;
    document.head.appendChild(tawkToScript);
  }, []);

  return (
    <div>
      {/* Your component content */}
      <Helmet>{/* Any other head elements you might need */}</Helmet>
    </div>
  );
};

export default TawkToWidget;
