import React, { Component } from "react";
import {
  Modal,
  Input,
  Form,
  notification,
  Select,
  Row,
  Col,
  Button,
  Typography,
} from "antd";
import { APIState, fetchUtil } from "~/api/common";
import { parseInputNumber, FORMAT_DATE, __DateAtLoad } from "~/lib";
import DatePicker from "~/component/antd-overrides/DatePicker";
import { FormInstance } from "antd/lib/form";
import OnlyAttachField from "~/fragment/attachable-field/OnlyAttachField";
import { registerEvent } from "~/analytics";
import * as ga from "~/contants/gaConstants";
import MultiAttach from "~/fragment/attachable-field/MultiAttach";

interface Props {
  isOpen: boolean;
  onUntick: (values: any) => void;
  onClose: () => void;
  onSave: (values: CompleteCompliance) => void;
  levelTwoClickedData: any;
}
interface ChallanModes {
  id: number;
  challanMode: string;
}

interface State {
  apiState: APIState;
  documents: Array<any>;
  isEditable: boolean;
  challanModeArray: Array<ChallanModes>;
  showDDfield: boolean;
}

export type CompleteCompliance = {
  late_fine: number;
  completion_date: Date;
  comment: string;
  documents: Array<any>;
  challan_amount: number;
  challan_serial_no: string;
  bsr_code: any;
  challan_mode: string;
  chequeOr_DD_number: string;
};

const DEFAULT_FORMVALUES: CompleteCompliance = {
  late_fine: 0,
  completion_date: __DateAtLoad,
  comment: "",
  documents: [],
  challan_amount: 0,
  challan_serial_no: "",
  bsr_code: "",
  challan_mode: "Bank",
  chequeOr_DD_number: "",
};

export default class CompliancePaymentModal extends Component<Props, State> {
  state: State = {
    apiState: "idle",
    documents: [],
    isEditable: true,
    showDDfield: false,
    challanModeArray: [
      {
        id: 1,
        challanMode: "Bank",
      },
      {
        id: 2,
        challanMode: "Cheque / DD",
      },
    ],
  };

  _formRef = React.createRef<FormInstance<CompleteCompliance>>();

  componentDidMount() {
    // console.log(this.props.levelTwoClickedData);
    if (this.props.levelTwoClickedData?.payment_status === "complete") {
      this.setState({
        isEditable: false,
      });
      this.getPaymentDetail();
    } else {
      // this._formRef.current!.setFieldsValue({
      //   documents: this.state.documents,
      // });
      // // DEFAULT_FORMVALUES.late_fine=this.props.levelTwoClickedData.late_fine;
      // DEFAULT_FORMVALUES.challan_amount=this.props.levelTwoClickedData.total_amount
      if (this.props.levelTwoClickedData?.tax_type !== "Filing") {
        this._formRef?.current?.setFieldsValue({
          late_fine: this.props.levelTwoClickedData?.late_fine.toFixed(2),
          challan_amount: this.props.levelTwoClickedData?.total_amount,
        });
      }
    }
  }

  async getPaymentDetail() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_compliance_completion_details`,
      {
        log_id: this.props.levelTwoClickedData.log_id,
      }
    );

    if (ok) {
      const data = json[0];
      // console.log(json[0]);
      // const data = {
      //   company_id: 1,
      //   log_id: 391,
      //   due_date: "2021-01-20",
      //   completion_date: "2020-12-31",
      //   late_fine: 100,
      //   comment: "Test 1",
      //   document: "test",
      //   extra_data: {
      //     comment: "data.comment",
      //     paid_late_fine: 10,
      //     source: "ui-action",
      //     bsr_code: 1234,
      //     challan_amount: 20.1,
      //     challan_serial_no: "123test",
      //     challan_mode: "Bank",
      //     chequeOr_DD_number: "",
      //   },
      // };
      const modifyTdsData = {
        comment: data.extra_data.comment,
        late_fine: data.extra_data.paid_late_fine,
        completion_date: new Date(data.completed_on),
        bsr_code: data.extra_data.bsr_code,
        challan_amount: data.extra_data.challan_amount,
        challan_serial_no: data.extra_data.challan_serial_no,
        documents: data.document,
        challan_mode: data.extra_data.challan_mode,
        chequeOr_DD_number: data.extra_data.chequeOr_DD_number,
      };
      if (modifyTdsData.challan_mode === "Cheque / DD") {
        this.setState({
          showDDfield: true,
        });
      } else {
        this.setState({
          showDDfield: false,
        });
      }

      this.setState({
        documents: data.document,
      });
      // this.setState(
      //   {
      //     vendorData: data,
      //     apiState: "idle",
      //   },
      //   () => {
      //     if (this.state.vendorData?.place_of_supply_id === 99) {
      //       this.setState({
      //         requireFlag: false,
      //       });
      //     }
      //   }
      // );
      // if (this._formRef.current) {
      //   if (data.gst_registration_type_id === 3) {
      //     this.setState({ hideGstin: true });
      //   }
      // if (this.props.levelTwoClickedData.tax_type === "Payment") {
      this._formRef.current!.setFieldsValue(modifyTdsData);
      // } else {
      //   this._formRef.current!.setFieldsValue(modifyCommonData);
      // }

      // }
    } else {
      notification.error({ message });
    }
  }

  handleOk = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.compliancePaymentModal173
    );
    // await this._formRef.current?.validateFields();
    const values = (await this._formRef.current?.validateFields()) as CompleteCompliance;
    values.documents = this.state.documents;

    this.props.onSave(values);
  };

  handlePanDocChange = (newFileName: string, dutyString?: string) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.onlyAttachFieldcompliance264
    );

    // const newArray = this.state.documents.slice(); // Create a copy
    // newArray.push( {
    //   "docName":  newFileName,
    //   "hashFile": ""
    // }); // Push the object
    if (dutyString === "del") {
      const filteredAttached = this.state.documents.filter(
        (item) => item !== newFileName
      );
      this.setState(
        {
          documents: filteredAttached,
        },
        () => {
          DEFAULT_FORMVALUES.documents = this.state.documents;
          this._formRef?.current?.setFieldsValue({
            documents: this.state.documents,
          });
        }
      );
    } else {
      this.setState(
        {
          documents: [...this.state.documents, newFileName],
        },
        () => {
          DEFAULT_FORMVALUES.documents = this.state.documents;
          this._formRef?.current?.setFieldsValue({
            documents: this.state.documents,
          });
        }
      );
    }
  };

  handleFormValueChange = (
    changedValues: Partial<CompleteCompliance>,
    values: CompleteCompliance
  ) => {
    if (changedValues.challan_mode) {
      DEFAULT_FORMVALUES.challan_mode = changedValues.challan_mode;
      if (changedValues.challan_mode === "Cheque / DD") {
        this.setState({
          showDDfield: true,
        });
      } else {
        this.setState({
          showDDfield: false,
        });
      }
    }
  };
  handleUntick = async () => {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/cancel_compliance_completion`,
      {
        log_id: this.props.levelTwoClickedData.log_id,
      }
    );
  };
  render() {
    return (
      <Modal
        title="Complete compliance"
        // zIndex={1100}
        destroyOnClose={true}
        width="35%"
        // bodyStyle={{ height: "400px" }}
        centered
        visible={this.props.isOpen}
        maskClosable={false}
        onCancel={this.props.onClose}
        onOk={this.handleOk}
        cancelText="No"
        okText="Yes"
        cancelButtonProps={
          !this.state.isEditable
            ? { style: { display: "none" } }
            : { style: {} }
        }
        okButtonProps={
          !this.state.isEditable
            ? { style: { display: "none" } }
            : { style: {} }
        }
        footer={
          <div
            style={{
              display: !this.state.isEditable ? "flex" : "block",
            }}
          >
            {!this.state.isEditable ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flex: "1",
                }}
              >
                <div>
                  <Button onClick={this.props.onClose}>Cancel</Button>
                </div>
                <Typography.Link
                  onClick={() => {
                    this.props.onUntick(this.props.levelTwoClickedData.log_id);
                  }}
                  style={{
                    paddingTop: "4px",
                    textDecoration: "underline",
                    color: "#eb5757",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Mark it as incomplete
                </Typography.Link>
              </div>
            ) : (
              <div>
                <Button key="cancel" onClick={this.props.onClose}>
                  No
                </Button>
                <Button key="ok" type="primary" onClick={this.handleOk}>
                  Yes
                </Button>
              </div>
            )}
          </div>
        }
      >
        {/* <Typography.Title level={4}>
          Are you sure to complete compliance ?
        </Typography.Title> */}
        <Form
          ref={this._formRef}
          initialValues={
            DEFAULT_FORMVALUES
            // late_fine: this.props.levelTwoClickedData?.late_fine,
          }
          onValuesChange={this.handleFormValueChange}
        >
          {this.props.levelTwoClickedData.tax_type === "Payment" ||
          this.props.levelTwoClickedData.tax_type === "Payment+Filing" ? (
            <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
              <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="challan_serial_no"
                  label="Challan serial no / Reference no"
                  rules={[
                    {
                      type: "string",
                      required: !this.state.isEditable ? false : true,
                      message: "Please enter challan serial no",
                    },
                  ]}
                >
                  <Input readOnly={!this.state.isEditable} />
                </Form.Item>
              </Col>
              <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="challan_amount"
                  label="Challan amount"
                  getValueFromEvent={(evt) =>
                    parseInputNumber(evt.target.value, "")
                  }
                  rules={[
                    {
                      type: "number",
                      required: !this.state.isEditable ? false : true,
                      message: "Please enter challan amount",
                    },
                  ]}
                  // style={{ maxWidth: "133px" }}
                >
                  <Input readOnly={!this.state.isEditable} />
                </Form.Item>
              </Col>
            </Row>
          ) : null}

          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="late_fine"
                label="Penalty (Late fine)"
                getValueFromEvent={(evt) =>
                  parseInputNumber(evt.target.value, "")
                }
                rules={[
                  ({ getFieldsValue }) => ({
                    required: !this.state.isEditable ? false : true,
                    message: "Please add penalty",
                  }),
                ]}
                // rules={[required("Penalty")]}
                // style={{ maxWidth: "133px" }}
              >
                <Input readOnly={!this.state.isEditable} />
              </Form.Item>
              <Form.Item
                name="completion_date"
                label="Filing date"
                rules={[
                  {
                    // type: "string",
                    required: !this.state.isEditable ? false : true,
                    message: "Please add Filing date",
                  },
                ]}
              >
                <DatePicker
                  format={FORMAT_DATE}
                  style={{ width: "100%" }}
                  popupStyle={{ zIndex: 1200 }}
                  disabled={!this.state.isEditable}
                />
              </Form.Item>
              {this.props.levelTwoClickedData.tax_type === "Payment" ||
              this.props.levelTwoClickedData.tax_type === "Payment+Filing" ? (
                <>
                  <Form.Item
                    name="challan_mode"
                    label="Challan mode"
                    rules={[
                      {
                        // required: !this.state.isEditable ? false : true,
                        message: "Please select Challan mode",
                      },
                    ]}
                  >
                    <Select showSearch disabled={!this.state.isEditable}>
                      {this.state.challanModeArray.map((el: any) => (
                        <Select.Option key={el.id} value={el.challanMode}>
                          {el.challanMode}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {this.state.showDDfield ? (
                    <Form.Item
                      name="chequeOr_DD_number"
                      label="Cheque / DD number"
                      rules={[
                        {
                          type: "string",
                          required: !this.state.isEditable ? false : true,
                          message: "Please enter Cheque / DD number",
                        },
                      ]}
                    >
                      <Input readOnly={!this.state.isEditable} />
                    </Form.Item>
                  ) : null}
                </>
              ) : null}
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              {this.props.levelTwoClickedData.tax_type === "Payment" ||
              this.props.levelTwoClickedData.tax_type === "Payment+Filing" ? (
                <Form.Item
                  name="bsr_code"
                  label="BSR code"
                  rules={[
                    {
                      type: "string",
                      message: "Please enter BSR code",
                      required: false,
                    },
                  ]}

                  // style={{ maxWidth: "133px" }}
                >
                  <Input readOnly={!this.state.isEditable} />
                </Form.Item>
              ) : null}
              <MultiAttach
                fieldName="documents"
                docList={this.state.documents}
                onHashFileChange={this.handlePanDocChange}
                readonly={this.state.isEditable}
                label="Challan receipt"
                rules={[
                  {
                    required: false,
                    message: "Please upload challan receipt!",
                  },
                ]}
              />

              <Form.Item
                name="comment"
                label="Comment"
                rules={[
                  {
                    type: "string",
                    required: false,
                    message: "Please add comment",
                  },
                ]}
              >
                <Input.TextArea rows={5} readOnly={!this.state.isEditable} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
