import React, { PureComponent } from "react";
import { Input, Button, notification, Typography } from "antd";
import { connect, ConnectedProps } from "react-redux";
import GstIniconWithOutText from "~/component/GstIniconWithOutText";
import VanillaButton from "~/component/VanillaButton";
import VideoDrawer from "~/component/VideoDrawer";
import { generateOTP, updateGstUsername, verifyOTP } from "~/api/compliance";
import { fetchUtil } from "~/api/common";
import FieldInfo from "~/component/FieldInfo";
import { registerEvent } from "~/analytics";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import styles from "./Iconstyle.module.css";
import { AdminDetail } from "~/api/profile";
import SyncYearMonthDropdown from "~/fragment/year-month-dropdown/SyncYearMonthDropdown";
import * as ga from "~/contants/gaConstants";

interface Props {
  handleBackPress: () => void;
  closeModal?: () => void;
  // handleStartSync: () => void;
  gstin?: string;
  source: any;
}

interface State {
  gstUserName: string;
  otp?: string;
  apiMesage?: string;
  apiState?: string;
  loadingForVerify?: boolean;
  showDataFetch?: boolean;
  fetchDetail?: any;
  processStatus?: any;
  otpVerifyStage?: any;
  syncStarted?: boolean;
  loadingStage?: boolean;
  requestLoading?: boolean;
  openVideoPlayer: boolean;
  otpSentStatus: boolean;
  syncStatusList?: any;
  iconchoser: string;
  year?: any;
  verifyOtpNew: boolean;
}

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  userId: state.mainAppSlice.user.id,
  companyName: state.mainAppSlice.user.company_name,
  saveEmpDetails: state.mainAppSlice.user.save_emp_details,
  showSyncButtonTour: state.mainAppSlice.showSyncButtonTour,
  employeeName:
    state.mainAppSlice.user.first_name +
    " " +
    state.mainAppSlice.user.last_name,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  showSyncStatusModal: () => dispatch(action.modal.showSyncStatusModal()),
  hideStartSyncFromSourceModal: () =>
    dispatch(action.modal.hideStartSyncFromSourceModal()),
  updateGstUserName: (userName: string) =>
    dispatch(action.auth.updateGstUserName(userName)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
// tslint:disable-next-line
interface Props extends PropsFromRedux {}

class GstOtpFrom extends PureComponent<Props, State> {
  state: State = {
    gstUserName: "",
    otp: undefined,
    apiState: "idle",
    loadingForVerify: false,
    showDataFetch: false,
    fetchDetail: [],
    processStatus: {},
    otpVerifyStage: "",
    syncStarted: false,
    loadingStage: false,
    requestLoading: false,
    openVideoPlayer: false,
    otpSentStatus: false,
    syncStatusList: [],
    iconchoser: "",
    year: "",
    verifyOtpNew: false,
  };

  latestInterval: any = null;

  componentDidMount() {
    const userDataJSON = localStorage.getItem("imt__user") ?? "";
    const userData: AdminDetail = JSON.parse(userDataJSON);
    this.setState({
      gstUserName: userData.gst_user_name,
    });

    // setTimeout(() => {}, 1000);
    this.handlegstinFrom();
  }

  handlegstinFrom = async () => {
    this.setState({
      loadingStage: true,
    });
    try {
      const { ok, json, message } = await fetchUtil(
        "GET",
        "/reconciliation/get_otp_verification_stage"
      );
      if (ok) {
        this.setState({
          loadingStage: false,
          otpVerifyStage: json,
        });
      } else {
        notification.error({ message });
        this.setState({
          loadingStage: false,
        });
      }
    } catch (error) {
      notification.error({ message: error });
    }
  };

  handleGstInput = async (e: any) => {
    this.setState({
      gstUserName: e.target.value,
    });
    if (e) {
      this.setState({
        apiMesage: "",
      });
    }
  };

  handleOtp = async (e: any) => {
    this.setState({
      otp: e.target.value,
    });
  };
  handleChange = (month?: any, newYear?: any) => {
    this.setState({ year: newYear });
    return true;
  };
  startSyncFromVerify = async (target: HTMLButtonElement) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.gstOtpFrom236
    );
    this.startSync();
  };

  requestForOtp = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.gstOtpFrom237
    );
    if (this.state.gstUserName.length < 1) {
      this.setState({
        requestLoading: false,
        apiState: "error",
      });
      notification.error({ message: "GST username should not be empty" });
      return;
    }
    this.setState({ requestLoading: true });
    if (!this.state.gstUserName) {
      notification.error({ message: "Please provide a proper username" });
      this.setState({ requestLoading: false });
      return;
    }

    try {
      const res = await generateOTP(this.state.gstUserName);
      if (res.ok) {
        notification.success({ message: "OTP sent to your mobile number" });
        this.setState({ otpSentStatus: true });
      } else {
        if (res.message.match(/API access is not available/i)) {
          this.setState({ otpSentStatus: false });
          notification.error({
            message: "GSTIN API access is not available",
            description: (
              <div>
                <b>Please enable API access from GST portal</b>
                <br />
                <small>Error: {res.message}</small>
              </div>
            ),
          });
        } else {
          console.error(res);
          notification.error({ message: res.message });
        }
      }
      this.setState({ requestLoading: false });
    } catch (err) {
      this.setState({ requestLoading: false });
      notification.error({ message: "Something went wrong" });
    }
  };

  getStatus = async () => {
    // data pull api
    try {
      const { ok, json, message } = await fetchUtil("GET", "/get_sync_status");
      // console.log(json[0].source_name);
      if (!ok) {
        notification.error({ message });
        return { ok, message };
      } else {
        if (json.length > 0) {
          this.setState(
            {
              syncStatusList: json[0].process_steps,
              processStatus: json[0].process_status,
              iconchoser: json[0].source_name,
            },
            () => {
              // if (json[0].process_status.is_syncing === false) {
              //   if (this.props.stopLoadingSyncNowButton) {
              //     this.props.stopLoadingSyncNowButton!();
              //   }
              // }

              // this.renderStepsManagement();
              if (this.state.syncStatusList.length > 0) {
                this.state.syncStatusList.forEach((item: any) => {
                  if (
                    item.status === "Processing" ||
                    item.status === "Waiting" ||
                    item.status === "Not Started"
                  ) {
                    // processing += 1;
                    // waiting += 1;
                    // notStarted += 1;
                  }
                });
              }
            }
          );
        } else {
          this.setState({ syncStatusList: [], processStatus: null }, () => {
            // this.renderStepsManagement();
            // if (this.props.stopLoadingSyncNowButton) {
            //   this.props.stopLoadingSyncNowButton!();
            // }
          });
        }
      }
    } catch (error) {
      notification.error({ message: error });
      // if (this.props.stopLoadingSyncNowButton) {
      //   this.props.stopLoadingSyncNowButton!();
      // }
    }
  };

  startSync = async () => {
    let ud: any = {};
    const userData: any = localStorage.getItem("imt__user");
    if (userData !== null) {
      ud = JSON.parse(userData);
    }
    try {
      const { ok } = await fetchUtil("POST", "/start_sync_up", {
        first_sync: ud.first_sync,
        financial_year: this.state.year,
      });
      if (ok) {
        ud.first_sync = true;
        this.getStatus();
        localStorage.setItem("imt__user", JSON.stringify(ud));
        // If syncing started successfully
        this.props.showSyncStatusModal();
        this.props.hideStartSyncFromSourceModal();
      } else {
        notification.error({ message: "Failed to start sync" });
        // if (this.props.stopLoadingSyncNowButton) {
        //   this.props.stopLoadingSyncNowButton!();
        // }
      }
    } catch (error) {
      // notification.error({ message: error.message });
      console.error(error);
      // if (this.props.stopLoadingSyncNowButton) {
      //   this.props.stopLoadingSyncNowButton!();
      // }
    }
  };

  verifyOTP = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.gstOtpFrom238
    );
    this.setState({
      loadingForVerify: true,
      apiState: "loading",
    });
    const target = e.target;
    if (!this.state.gstUserName) {
      notification.error({ message: "GST username should not be empty" });
      this.setState({ loadingForVerify: false });
      return;
    }
    if (!this.state.otp) {
      notification.error({ message: "OTP should not be empty" });
      this.setState({ loadingForVerify: false });
      return;
    }

    try {
      const otp = parseInt(String(this.state.otp), 10);
      if (!isNaN(otp)) {
        const verifyOTPRes = await verifyOTP(
          this.state.gstUserName,
          String(this.state.otp!)
        );
        if (verifyOTPRes.ok) {
          this.setState({
            loadingForVerify: false,
            apiState: "success",
            apiMesage: verifyOTPRes.message,
            showDataFetch: true,
          });
          this.setState({
            otpVerifyStage: "Authenticated",
            verifyOtpNew: true,
          });
          this.setGstUserName(this.state.gstUserName);
          // this.startSyncFromVerify(target as HTMLButtonElement);
          notification.success({ message: "OTP verified" });
        } else {
          this.setState({
            loadingForVerify: false,
            apiState: "error",
            apiMesage: "Failed to verify OTP",
          });
          notification.error({ message: "Failed to verify OTP" });
        }
      } else {
        this.setState({
          loadingForVerify: false,
          apiState: "error",
        });
        notification.error({ message: "Please enter a valid OTP" });
      }
    } catch (err) {
      notification.error({ message: "Something went wrong" });
    }
  };

  setGstUserName = async (userName: string) => {
    const updateUsername = await updateGstUsername(userName);
    if (updateUsername.ok) {
      const userData = localStorage.getItem("imt__user");
      let updatedUserdata: any = {};
      if (userData) {
        this.props.updateGstUserName(userName);
        updatedUserdata = JSON.parse(userData);
        updatedUserdata.gst_user_name = userName;

        localStorage.setItem("imt__user", JSON.stringify(updatedUserdata));
      }
    } else {
      notification.error({ message: updateUsername.message });
    }
  };

  // handleShowIcon = (showIconFalse: boolean) => {
  //   const x = 1;
  // };

  handleBack = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.gstOtpFrom239
    );
    this.props.handleBackPress();
  };

  render() {
    return (
      <>
        <div style={{ textAlign: "center" }}>
          <GstIniconWithOutText />
          <Typography.Title
            level={4}
            style={{
              textAlign: "center",
            }}
          >
            <b className={styles.sourceheading}>GSTIN</b>
          </Typography.Title>
        </div>
        {this.state.otpVerifyStage === "Authenticated" ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "10%",
              marginBottom: "20%",
              color: "var(--success-color)",
              fontWeight: 600,
            }}
          >
            {this.state.verifyOtpNew ? (
              <div>OTP verified</div>
            ) : (
              <div>OTP already verified</div>
            )}
            <div
              style={{
                color: "#5A5A5A",
                fontWeight: 600,
                marginTop: "4%",
                marginBottom: "1%",
              }}
            >
              Select Year
            </div>
            <div>
              <SyncYearMonthDropdown
                month={1}
                year={this.state.year}
                marginLeft={"0px"}
                payrollFlag={false}
                onlyYear={true}
                onChange={this.handleChange}
              />
            </div>
            <div>
              <Button
                onClick={this.startSync}
                type="primary"
                style={{ marginTop: "20px" }}
              >
                Start sync
              </Button>
            </div>
          </div>
        ) : (
          <div>
            {this.state.otpVerifyStage === "Request OTP" ||
            this.state.otpVerifyStage === "OTP verified" ||
            this.state.otpVerifyStage === "Verify OTP" ? (
              <div>
                <VideoDrawer
                  url="https://youtu.be/amzSuvg_mPw"
                  title="Enable API access in GST portal"
                  onClose={() => this.setState({ openVideoPlayer: false })}
                  openDrawer={this.state.openVideoPlayer}
                />
                <Typography.Title
                  level={4}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Make sure that you have enabled API access in GST portal. This
                  is how you do it:{" "}
                  <VanillaButton
                    text="See video"
                    onClick={() => this.setState({ openVideoPlayer: true })}
                  />
                </Typography.Title>
                <br />
                <label style={{ marginRight: "70%" }}>GST username</label>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Input
                    onChange={this.handleGstInput}
                    value={this.state.gstUserName}
                    autoFocus
                  />
                  <Button
                    type="primary"
                    style={{ marginLeft: "20px" }}
                    loading={this.state.requestLoading}
                    onClick={this.requestForOtp}
                  >
                    Request OTP
                  </Button>
                </div>
                {this.state.otpSentStatus ? (
                  <div style={{ marginTop: "13px" }}>
                    <FieldInfo
                      color="green"
                      text="OTP has been sent to your registered email and phone number."
                      subtle
                    />
                  </div>
                ) : null}
                <div style={{ marginTop: "2em" }}>
                  {" "}
                  <label style={{ marginRight: "80%" }}>Enter OTP</label>
                  <Input
                    type="number"
                    value={this.state.otp}
                    onChange={this.handleOtp}
                  />
                </div>
                <div
                  style={{
                    height: "22px",
                    fontSize: "14px",
                    width: "100%",
                    marginTop: "var(--margin-sm)",

                    color:
                      this.state.apiState === "success"
                        ? "var(--success-color)"
                        : "var(--error-color)",
                  }}
                >
                  {this.state.apiMesage}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "2em",
                  }}
                >
                  <Button
                    style={{ border: "none" }}
                    type="ghost"
                    onClick={this.handleBack}
                  >
                    Back
                  </Button>
                  <Button
                    loading={this.state.loadingForVerify}
                    type="primary"
                    style={{ width: "120px" }}
                    onClick={this.verifyOTP}
                  >
                    Verify OTP
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  flexDirection: "column",
                  marginTop: "100px",
                  marginBottom: "100px",
                  fontWeight: 800,
                }}
              >
                <div>Checking</div>
                <div>OTP verification status ....</div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default connector(GstOtpFrom);
