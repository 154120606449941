import * as React from "react";

const OtherFeature4 = (props: any) => (
  <svg
    width={67}
    height={67}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M62.813 13.61a3.14 3.14 0 0 0-3.141-3.141H20.938v22.41a3.124 3.124 0 0 1 1.084 3.272L20.938 40.2v5.862h38.734a3.14 3.14 0 0 0 3.14-3.14V13.609Zm-13.303.306c.196-.196.462-.306.74-.307h2.094v2.094h-1.66l-1.788 1.787-1.48-1.48 2.094-2.094Zm2.834 21.678v2.093h-3.14v-2.093h3.14ZM40.828 17.797a1.047 1.047 0 0 1 1.047-1.047 8.384 8.384 0 0 1 8.375 8.375 1.047 1.047 0 0 1-1.047 1.047h-7.328a1.047 1.047 0 0 1-1.047-1.047v-7.328Zm-11.516-4.188H33.5v2.094h-4.188V13.61Zm-4.187 0h2.094v2.094h-2.094V13.61Zm0 4.188H33.5v2.094h-8.375v-2.094Zm4.188 24.078h-4.188v-2.094h4.188v2.094Zm0-4.188h-4.188v-2.093h4.188v2.093Zm4.927 3.881a1.047 1.047 0 0 1-.74.307h-2.094v-2.094h1.66l1.788-1.787 1.48 1.48-2.094 2.094Zm4.494-4.927a8.376 8.376 0 0 1 0-16.75 1.047 1.047 0 0 1 1.047 1.047v6.28h6.282a1.047 1.047 0 0 1 1.046 1.048 8.385 8.385 0 0 1-8.375 8.375Zm19.891 5.234h-9.422v-2.094h9.422v2.094Zm0-4.188h-4.188v-2.093h4.188v2.093Zm0-17.796h-4.188v-2.094h4.188v2.094Zm0-4.188h-4.188V13.61h4.188v2.094Z"
      fill="#9B51E0"
    />
    <path
      d="M37.688 28.266V22.07a6.28 6.28 0 1 0 7.24 7.241h-6.194a1.047 1.047 0 0 1-1.047-1.046ZM14.656 60.719v-5.35a9.475 9.475 0 0 1 .995-4.215l1.593-3.186c.362-.728.552-1.53.553-2.342v-.83c0-.64.084-1.279.25-1.897l1.948-7.29a1.046 1.046 0 1 0-2.021-.543l-1.203 4.506a3.15 3.15 0 0 1-3.057 2.318 3.108 3.108 0 0 1-2.643-1.52l-2.546-4.237a1.047 1.047 0 0 1-.15-.54v-8.27a5.243 5.243 0 0 0-4.188 5.13v12.069c.001.162.039.322.11.469l1.653 3.301c.217.437.33.918.331 1.405V60.72h8.375ZM42.922 18.93v5.148h5.147a6.3 6.3 0 0 0-5.147-5.147ZM14.656 29.313a1.047 1.047 0 1 0 0-2.094 1.047 1.047 0 0 0 0 2.093Z"
      fill="#9B51E0"
    />
    <path
      d="M10.469 26.172v9.132l2.394 3.99a1.033 1.033 0 0 0 1.884-.264l1.207-4.502a3.097 3.097 0 0 1 2.89-2.315V10.47h-5.235a3.141 3.141 0 0 0-3.14 3.14v12.563Zm4.187-1.047a3.14 3.14 0 1 1 0 6.28 3.14 3.14 0 0 1 0-6.28Z"
      fill="#9B51E0"
    />
  </svg>
);

export default OtherFeature4;
