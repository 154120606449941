import React from "react";

const SubHeader = (props: any) => {
  return (
    <div id="sub_header">
      <h4 style={{ fontWeight: 700 }}>{props.text}</h4>
    </div>
  );
};

export default SubHeader;
