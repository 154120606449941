import React, { PureComponent, ReactNode } from "react";
import {
  Row,
  Form,
  message as antMessage,
  Button,
  Spin,
  notification,
  Typography,
  Modal,
} from "antd";
import { Rule } from "antd/lib/form";

import { getSignedUploadURL, getSignedDownloadURL } from "~/api/common";

import styles from "./AttachFieldWithView.module.css";
import { saveAs } from "file-saver";
import Addfilelogo from "../../assets/add-file-logo.svg";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FILE_NAME_TRICK } from "~/lib";
import { SUPPORTED_EXT_LIST } from "~/lib/constants";
import texts from "~/contants/texts";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

interface Props {
  className?: string;
  label: string;
  fieldName: string;
  hashFileName?: string;
  onHashFileChange: (newHashFile: string, documentName: string) => void;
  onNewFile?: (file: File) => void;
  beforeAction?: (file: File) => Promise<boolean>;
  rules: Array<Rule>;
  readonly?: boolean;
  children?: ReactNode;
  flag?: any;
  handleAttachDelete: () => void;
  description?: string;
  askForRowDelete?: () => void;
  deleteRow?: boolean;
  lineitems?: any;
  isExpense?: boolean;
  t?: any;
}

interface State {
  previewURL?: string;
  v: boolean;
  loadIframe: boolean;
  drag: boolean;
  file: any;
  isLoading: boolean;
  isDragOver: boolean;
  documents_name: string;
  unsupportedFile: boolean;
  isDragEnter: boolean;
  deleteRow?: boolean;
  askForRowDeleteModal: boolean;
  newFile: any;
}

class AttachFieldWithView extends PureComponent<Props, State> {
  state: State = {
    previewURL: undefined,
    v: false,
    loadIframe: false,
    drag: false,
    file: "",
    isLoading: false,
    isDragOver: false,
    documents_name: "",
    unsupportedFile: false,
    isDragEnter: false,
    deleteRow: false,
    askForRowDeleteModal: false,
    newFile: null,
  };

  dropRef = React.createRef<any>();

  componentDidMount() {
    const dndDiv = this.dropRef.current!;
    dndDiv.addEventListener("dragenter", this.handleDragEnter);
    dndDiv.addEventListener("dragover", this.handleDragOver);
    dndDiv.addEventListener("drop", this.handleDrop);
    dndDiv.addEventListener("dragleave", this.handleDragLeave);
    if (this.props.t) {
      this.handleDragFileUpload(this.props.t);
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.hashFileName !== this.props.hashFileName) {
      this.handlePreview();
    }
  }

  handleDragEnter = (event: any) => {
    if (this.props.readonly) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      isDragOver: true,
      isDragEnter: true,
    });
  };

  handleDragOver = (event: any) => {
    if (this.props.readonly) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      isDragOver: true,
      isDragEnter: true,
    });
  };

  handleDrop = (event: any) => {
    if (event.dataTransfer.files.length === 0) {
      return;
    }
    if (this.props.readonly) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.info("handleDrop: ", files);
    this.setState({
      isDragOver: false,
      isDragEnter: false,
    });
    if (
      this.props.lineitems![0].description.length > 0 ||
      this.props.lineitems![0].hsn_no.length > 0 ||
      this.props.lineitems![0].amount
    ) {
      this.setState({ askForRowDeleteModal: true, newFile: files[0] });
    } else {
      if (
        !(this.props.lineitems![0].description.length > 0) ||
        !(this.props.lineitems![0].hsn_no.length > 0) ||
        !this.props.lineitems![0].amount
      ) {
        const firstFile = files[0] as File;
        const fileParts = firstFile.name.split(".");
        if (fileParts.length > 0) {
          const ext = fileParts[fileParts.length - 1].toLowerCase();
          if (SUPPORTED_EXT_LIST.includes(ext)) {
            this.handleFile(firstFile, "", false);
          } else {
            notification.warn({
              message: texts.errorUnsupportedFile,
              description: texts.warningUnsupportedFile,
              duration: 10000,
            });
          }
        }
      } else {
        const firstFile = files[0] as File;
        const fileParts = firstFile.name.split(".");
        if (fileParts.length > 0) {
          const ext = fileParts[fileParts.length - 1].toLowerCase();
          if (SUPPORTED_EXT_LIST.includes(ext)) {
            this.handleFile(firstFile, "", false);
          } else {
            notification.warn({
              message: texts.errorUnsupportedFile,
              description: texts.warningUnsupportedFile,
              duration: 10000,
            });
          }
        }
      }
    }
  };

  handleDragFileUpload = async (file: any) => {
    const { ok, message, data } = await getSignedUploadURL(file.name);
    if (!ok) {
      antMessage.warning({ message });
    } else {
      setTimeout(() => {
        if (this.props.onNewFile) {
          // if (!isFileOnly) {
          this.props.onNewFile(file);
          // }
        }
      }, 0);
      const { hashedFileName, url } = data!;
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "content-type": file.type,
        },
        body: file,
      });
      if (!res.ok) {
        antMessage.error({ message: texts.errorUpload });
      } else {
        this.props.onHashFileChange(hashedFileName, file.name);
        this.setState({
          loadIframe: false,
        });
      }
      await this.handlePreview();
    }
  };

  handleDragLeave = (event: any) => {
    this.setState({
      isDragOver: false,
      isDragEnter: false,
    });
  };

  handleAttach = (_e: React.MouseEvent) => {
    if (this.props.readonly) {
      return;
    }
    const $i = document.createElement("input") as HTMLInputElement;
    $i.type = "file";
    $i.accept = "image/png,image/jpeg,.pdf";
    $i.onchange = async (e) => {
      const file = $i.files?.length ? $i.files[0] : null;
      if (file) {
        if (
          (this.props.lineitems &&
            this.props.lineitems![0].description.length > 0) ||
          (this.props.lineitems &&
            this.props.lineitems![0].hsn_no.length > 0) ||
          (this.props.lineitems && this.props.lineitems![0].amount)
        ) {
          this.setState({ askForRowDeleteModal: true, newFile: file });
        }
        if (
          !(this.props.lineitems![0].description.length > 0) &&
          !(this.props.lineitems![0].hsn_no.length > 0) &&
          !this.props.lineitems![0].amount
        ) {
          const firstFile = file;
          const fileParts = firstFile.name.split(".");
          if (fileParts.length > 0) {
            const ext = fileParts[fileParts.length - 1].toLowerCase();
            if (SUPPORTED_EXT_LIST.includes(ext)) {
              this.handleFile(firstFile, "", false);
            } else {
              notification.warn({
                message: texts.errorUnsupportedFile,
                description: texts.warningUnsupportedFile,
                duration: 10000,
              });
            }
          }
        }
      }
    };
    $i.click();
  };

  deleteRowAndAdd = (isFileOnly: boolean) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.attachFieldWithView260
    );
    this.setState({ askForRowDeleteModal: false });
    const { newFile } = this.state;
    const firstFile = newFile;
    const fileParts = firstFile.name.split(".");
    if (fileParts.length > 0) {
      const ext = fileParts[fileParts.length - 1].toLowerCase();
      if (SUPPORTED_EXT_LIST.includes(ext)) {
        this.handleFile(firstFile, "", isFileOnly);
      } else {
        notification.warn({
          message: texts.errorUnsupportedFile,
          description: texts.warningUnsupportedFile,
          duration: 10000,
        });
      }
    }
  };

  handleFile = async (
    file: File,
    documentName: string,
    isFileOnly: boolean
  ) => {
    if (this.props.readonly) {
      return;
    }
    this.setState({
      loadIframe: true,
      isLoading: true,
      documents_name: documentName,
    });
    const { ok, message, data } = await getSignedUploadURL(file.name);
    if (!ok) {
      antMessage.warning({ message });
    } else {
      setTimeout(() => {
        if (this.props.onNewFile) {
          if (!isFileOnly) {
            this.props.onNewFile(file);
          }
        }
      }, 0);
      const { hashedFileName, url } = data!;
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "content-type": file.type,
        },
        body: file,
      });
      if (!res.ok) {
        antMessage.error({ message: texts.errorUpload });
      } else {
        this.props.onHashFileChange(hashedFileName, documentName);
        this.setState({
          loadIframe: false,
        });
      }
      await this.handlePreview();
    }
    this.setState({
      isLoading: false,
    });
  };

  handleDelete = async (_e: React.MouseEvent) => {
    if (this.props.readonly) {
      return;
    }
    this.props.onHashFileChange("", this.state.documents_name!);
  };

  getActualHashFileName = (hashFileName: string) => {
    let actualHashFileName = hashFileName;
    if (actualHashFileName.includes(FILE_NAME_TRICK)) {
      const parts = actualHashFileName.split(FILE_NAME_TRICK);
      actualHashFileName = parts[0] + parts[parts.length - 1];
      // console.log(actualHashFileName);
    }
    return actualHashFileName;
  };

  handlePreview = async () => {
    // console.log(this.props.hashFileName);
    if (this.props.hashFileName) {
      const actualHashFileName = this.getActualHashFileName(
        this.props.hashFileName
      );
      let previewURL;
      const fileParts = actualHashFileName.split(".");
      // if (fileParts.length === 0) {
      //   previewURL = "unknown_file logo";
      //   this.setState({ previewURL });
      // } else
      if (fileParts.length > 0) {
        const ext = fileParts[fileParts.length - 1].toLowerCase();
        if (SUPPORTED_EXT_LIST.includes(ext)) {
          const { ok, message, url } = await getSignedDownloadURL(
            actualHashFileName
          );
          if (!ok) {
            antMessage.error({ message });
          } else {
            previewURL = url!;
            const fileResponse = await fetch(previewURL);
            const contentType =
              fileResponse.headers.get("Content-Type")?.toLowerCase() || "";
            if (contentType.includes("application/octet-stream")) {
              const blob = await fileResponse.blob();
              const file = new Blob([blob], { type: "application/pdf" });
              const fileURL = URL.createObjectURL(file);
              previewURL = fileURL;
              // + "#toolbar=0&view=FitH&navpanes=1&scrollbar=1"
            }
            if (this.state.previewURL?.startsWith("blob:")) {
              URL.revokeObjectURL(this.state.previewURL);
            }
            const parsedUrl = new URL(previewURL);
            if (parsedUrl.pathname.endsWith(".pdf")) {
              // eslint no-param-reassign: "error"
              const preViewPdf = previewURL;
              previewURL = preViewPdf;
              // + "#toolbar=0&view=FitH&navpanes=1&scrollbar=1"
            }
            this.setState({ previewURL, unsupportedFile: false });
          }
        } else {
          this.setState({ previewURL: "", unsupportedFile: true });
        }
      } else {
        this.setState({ previewURL: "", unsupportedFile: true });
      }
    } else {
      // no hashFileName
      this.setState({ previewURL: "" });
    }
  };

  handlePreviewCancel = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.attachFieldWithView261
    );

    this.setState({
      v: false,
    });
  };

  handledown = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.attachFieldWithView262
    );
    if (this.props.hashFileName) {
      const actualHashFileName = this.getActualHashFileName(
        this.props.hashFileName
      );
      const { ok, message } = await getSignedDownloadURL(actualHashFileName);
      if (!ok) {
        antMessage.error({ message });
      } else {
        const response = await fetch(this.state.previewURL!);
        const fileName = this.props.hashFileName;
        saveAs(await response.blob(), fileName);
      }
    }
  };

  render() {
    const { className, fieldName, hashFileName, readonly, rules } = this.props;
    const { previewURL, unsupportedFile, askForRowDeleteModal } = this.state;
    const dimension = {
      height: previewURL ? "500px" : "300px",
      width: previewURL ? "700px" : "300px",
    };
    return (
      <Row
        align="bottom"
        className={className}
        id="droppable"
        style={
          this.state.isDragEnter
            ? { border: "2px dashed #ccc" }
            : { border: "0px" }
        }
      >
        <Form.Item name={fieldName} rules={rules}>
          <Spin spinning={this.state.isLoading && false}>
            <div
              ref={this.dropRef}
              onClick={this.handleAttach}
              style={{
                ...dimension,
                cursor: "pointer",
                transition: "all 500ms",
              }}
            >
              {unsupportedFile ? (
                <div
                  style={{
                    ...dimension,
                    border: "1px dotted var(--grey-2)",
                    padding: "0px var(--margin-md)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography.Title level={4}>
                    {texts.errorPreview}
                  </Typography.Title>
                </div>
              ) : (
                <embed
                  key={previewURL || (!hashFileName ? Addfilelogo : "")}
                  className={styles.embed}
                  style={{
                    border: "0px",
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                    pointerEvents: hashFileName ? "initial" : "none",
                  }}
                  src={previewURL || (!hashFileName ? Addfilelogo : "")}
                />
              )}
              <div
                style={{
                  position: "absolute",
                  marginTop: 0,
                  marginBottom: 0,
                  zIndex: 10,
                  top: 0,
                  left: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "30px",
                  pointerEvents: "none",
                }}
              />
              {readonly === true && !hashFileName ? (
                <p
                  style={{
                    textAlign: "center",
                    marginTop: 25,
                    fontSize: "20px",
                    fontWeight: 700,
                  }}
                >
                  {texts.noAttachment}
                </p>
              ) : readonly === false && !hashFileName ? (
                <p className={styles.drop_file_text}>{texts.dropFile}</p>
              ) : null}
            </div>
          </Spin>
          {!hashFileName ? null : (
            <Button
              size="small"
              type="link"
              style={{ marginTop: "5px" }}
              onClick={this.handledown}
            >
              {texts.download}
            </Button>
          )}
          {readonly || !hashFileName ? null : (
            <Button
              size="small"
              type="link"
              style={{ marginTop: "5px", color: "#EF8683" }}
              // tslint:disable-next-line: jsx-no-lambda
              onClick={() => {
                this.setState(
                  {
                    previewURL: "",
                  },
                  this.props.handleAttachDelete
                );
              }}
            >
              <p>Delete</p>
            </Button>
          )}
        </Form.Item>
        {!!previewURL ? (
          <Modal
            footer={<Button onClick={this.handlePreviewCancel}>Close</Button>}
            width={Math.floor(window.innerWidth * 0.9)}
            className={styles.previewModal}
            visible={this.state.v}
            onCancel={this.handlePreviewCancel}
            destroyOnClose
            centered
          >
            <iframe
              title="Attatchment modal"
              className={styles.previewFrame}
              src={previewURL}
            />
          </Modal>
        ) : null}
        <Modal
          destroyOnClose
          centered
          closable={false}
          title={
            <div style={{ display: "flex", flexDirection: "row" }}>
              <ExclamationCircleOutlined
                style={{ color: "#fcc41b", marginTop: 15, marginBottom: -5 }}
              />
              <p style={{ marginLeft: 10, marginTop: 10, marginBottom: -5 }}>
                {texts.warning}
              </p>
            </div>
          }
          visible={askForRowDeleteModal}
          footer={
            <div>
              <Button
                // tslint:disable-next-line: jsx-no-lambda
                onClick={() => this.setState({ askForRowDeleteModal: false })}
              >
                {texts.cancel}
              </Button>
              <Button
                style={{ backgroundColor: "#2F80ED", color: "white" }}
                // tslint:disable-next-line: jsx-no-lambda
                onClick={() => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    ga.events.attachFieldWithView263
                  );
                  this.deleteRowAndAdd(true);
                }}
              >
                {texts.onlyUpload}
              </Button>
              <Button
                style={{ backgroundColor: "#2F80ED", color: "white" }}
                // tslint:disable-next-line: jsx-no-lambda
                onClick={() => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    ga.events.attachFieldWithView263
                  );
                  this.deleteRowAndAdd(false);
                }}
              >
                {texts.overrideData}
              </Button>
            </div>
          }
        >
          <p>{texts.warningOverrideData}</p>
        </Modal>
      </Row>
    );
  }
}

export default AttachFieldWithView;
