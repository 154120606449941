import React, { useState } from "react";
import { Switch } from "antd";

interface Props {
  index: number;
  text: string;
  selectedArray: Array<boolean>;

  onToggle: (index: number, isChecked: boolean) => void;
}

const CustomSwitch = (props: Props) => {
  // const [isChecked, setIsChecked] = useState(true);

  const changeHandler = (checked: boolean) => {
    //  setIsChecked(checked);
    props.onToggle(props.index, checked);
  };

  return (
    <p>
      <Switch
        size="small"
        onChange={changeHandler}
        checked={props.selectedArray[props.index]}
      />
      <span
        style={{
          marginRight: "0.5rem",
          display: "inline-block",
          paddingLeft: "0.5rem",
          fontSize: "0.8rem",
        }}
      >
        {props.text}
      </span>
    </p>
  );
};

export default CustomSwitch;
