import React, { PureComponent } from "react";

import { Button, Menu, Typography, Dropdown, Select, notification } from "antd";
import { fetchUtil, fetchBlob } from "~/api/common";
import ZOHO_ICON from "~/assets/zoho-logo.png";
import styles from "./Iconstyle.module.css";
import TallySync from "./TallySync";
import { registerEvent } from "~/analytics";
import * as ga from "~/contants/gaConstants";
import SyncYearMonthDropdown from "~/fragment/year-month-dropdown/SyncYearMonthDropdown";
import { syncMonths, monthNameToNumber } from "~/lib/constants";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { action } from "~/app/MainApp/store";

interface Props {
  handleBackPress: () => void;
  handleStartSync: (type?: any, month?: any, year?: any) => void;
  talyStart?: boolean;
  closeModal: () => void;
  // hideStartSyncFromSourceModal: () => void;
  setTallyBackSyncSelectModal: (opts: any) => void;
  setTallyBackSyncSelectModalNew: (opts: any) => void;
  viewSyncModal?: (title: string, logo: string, style: string) => void;
}

interface State {
  showStartSyncButon: boolean | undefined;
  erroMsg: string;
  openBackwardSyncModal: boolean;
  confirmModal: boolean;
  selectedMonth: number;
  type: string;
  newMonth: string;
  newYear: string;
}

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  userId: state.mainAppSlice.user.id,
  saveEmpDetails: state.mainAppSlice.user.save_emp_details,
});

const mapDispatchToProps = {
  hideStartSyncFromSourceModal: action.modal.hideStartSyncFromSourceModal,
  showSyncStatusModal: action.modal.showSyncStatusModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
// tslint:disable-next-line
interface Props extends PropsFromRedux {}

class ZohoSyncBack extends PureComponent<Props, State> {
  state: State = {
    showStartSyncButon: undefined,
    erroMsg: "",
    openBackwardSyncModal: false,
    confirmModal: false,
    selectedMonth: -1,
    type: "Sales",
    newMonth: syncMonths[0],
    newYear: "",
  };

  // latestInterval: any = null;

  // componentDidMount() {}

  goback = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.tallyStart248
    );
    this.props.handleBackPress();
  };

  handleBackwardSyncStart = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.tallyStart249
    );

    // this.props.showSyncStatusModal();
    this.props.hideStartSyncFromSourceModal();
    this.props.setTallyBackSyncSelectModal({ isVisible: true });
    // this.setState({
    //   openBackwardSyncModal: true,
    // });
  };

  handleOpen = async () => {
    try {
      await fetchBlob("/export_zoho_xls", {
        month: monthNameToNumber(this.state.newMonth),
        fy: this.state.newYear,
      }).then((res: any) => {
        if (res.blob) {
          res.blob.then((val: any) => {
            const blob = new Blob([val], { type: "text/csv" });
            saveAs(blob, `Zoho.csv`);
          });
        } else {
          notification.info({
            message: res.message,
          });
        }
      });
    } catch (err) {
      console.error(err);
      notification.error({
        message: "Error",
      });
    }
    this.props.hideStartSyncFromSourceModal();
  };
  handleClose = () => {
    this.setState({ confirmModal: false });
  };
  handleChange = (value: any) => {
    this.setState({ type: value });
  };
  changeHandle = (month: any, year: any) => {
    this.setState({ newMonth: syncMonths[month], newYear: year });
    return true;
  };

  render() {
    return (
      <>
        <div style={{ textAlign: "center" }}>
          <>
            <div>
              <img
                alt="ZoHo logo"
                src={ZOHO_ICON}
                className={styles.ZohoIcon}
              />
              <Typography.Title
                level={4}
                style={{
                  textAlign: "center",
                }}
              >
                <b className={styles.sourceheading}>ZOHO</b>
              </Typography.Title>
            </div>

            <div className={styles.zohoStart}>
              <>
                <p style={{ color: "#5A5A5A", fontWeight: 600 }}>
                  {" "}
                  Select period
                </p>
                <SyncYearMonthDropdown
                  month={syncMonths.indexOf(this.state.newMonth)}
                  year={this.state.newYear}
                  marginLeft={"0px"}
                  payrollFlag={false}
                  onChange={this.changeHandle}
                />
                <p
                  style={{
                    color: "#5A5A5A",
                    fontWeight: 600,
                    marginTop: "10px",
                  }}
                >
                  {" "}
                  Select Instrument
                </p>
                <Select
                  suffixIcon={
                    <svg
                      width="10"
                      height="8"
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 16L19.5263 0.25H0.473721L10 16Z"
                        fill="#4F4F4F"
                      />
                    </svg>
                  }
                  style={{
                    // marginRight: "28em",
                    // marginTop: "30px",
                    width: "100px",
                    color: "black",
                    fontWeight: 700,
                  }}
                  value={this.state.type}
                  onChange={this.handleChange}
                  disabled
                >
                  <Select.Option key="Sales" value="Sales">
                    Sales
                  </Select.Option>
                  <Select.Option key="Expense" value="Expense">
                    Expense
                  </Select.Option>
                </Select>
              </>
            </div>
            <div className={styles.zohoBack}>
              <Button
                type="text"
                onClick={this.goback}
                disabled={this.props.talyStart}
              >
                Back
              </Button>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  type="primary"
                  //   disabled={this.props.talyStart}
                  onClick={this.handleOpen}
                  className={styles.sync_back}
                >
                  Sync back
                </Button>
              </div>
            </div>
          </>
        </div>
      </>
    );
  }
}

export default connector(ZohoSyncBack);
