import { fetchUtil, fetchTableExportBlob } from "./common";
import { productMasterData } from "./payment";
export type VendorItem = {
  customer_id: number;
  customer_gstin_id: number;
  customer_idx: any;
  name: string;
  company_name: string;
  billing_address: null | string;
  billing_address2: null | string;
  billing_address_city: null | string;
  billing_address_country: null | string;
  billing_address_pincode: null | string;
  billing_address_state: null | string;
  email_id: string;
  gst_registration_type_id: number | null;
  place_of_supply_id: number;
  place_of_supply: string;
  gstin: string;
  customer_currency: number;
  symbol: string;
  symbol_name: string;
  state_code: any;
  trade_name: string;
  active_customer_gstin_no: boolean;
  __init__?: any;
};

export const getVendorList = async (): Promise<{
  ok: boolean;
  message: string;
  data: Array<VendorItem>;
}> => {
  const { ok, message, json } = await fetchUtil("POST", "/get_customers", {
    page_no: 1,
    no_of_rows: 99999,
  });
  if (!ok) {
    return { ok, message, data: [] };
  } else {
    return { ok, message, data: json };
  }
};

export const getCity = async (
  value: string
): Promise<{
  ok: boolean;
  message: string;
  data: any;
}> => {
  const { ok, json, message } = await fetchUtil(
    `GET`,
    `/get_city_name?city=${value}`
  );
  if (!ok) {
    return { ok, message, data: [] };
  } else {
    return { ok, message, data: json };
  }
};

export const getCategory = async (
  value: string
): Promise<{
  ok: boolean;
  message: string;
  data: any;
}> => {
  const { ok, json, message } = await fetchUtil(
    `GET`,
    `/get_product_category?search_str=${value}`
  );
  if (!ok) {
    return { ok, message, data: [] };
  } else {
    return { ok, message, data: json };
  }
};

export const getProductList = async (
  value: string
): Promise<{
  ok: boolean;
  message: string;
  data: Array<productMasterData>;
}> => {
  const { ok, json, message } = await fetchUtil(`POST`, `/get_products`, {
    search_str: value,
    page_no: 1,
    no_of_rows: 10,
    sort_param: "",
  });
  if (!ok) {
    return { ok, message, data: [] };
  } else {
    return { ok, message, data: json };
  }
};

export const getFullVendorList = async (
  page_no: number,
  fy: string
): Promise<{
  ok: boolean;
  data?: Array<VendorItem>;
  message: string;
  schema?: Array<any>;
}> => {
  const { ok, message, json, schema } = await fetchUtil(
    "POST",
    "/get_customer_details",
    {
      page_no: 1,
      no_of_rows: 1,
      fy,
    }
  );

  if (!ok) {
    return { ok, data: [], message };
  } else {
    return { ok, data: json, schema, message };
  }
};

export const getTableExportBlob = async (
  page_no: number,
  no_of_rows: number,
  acceptType: string,
  selectedFields: string,
  fy: string
): Promise<{
  ok: boolean;
  blob: Blob | null | undefined;
  message: string;
}> => {
  const { ok, blob, message } = await fetchTableExportBlob(
    "POST",
    "/get_customer_details",
    {
      page_no,
      no_of_rows,
      fy,
    },
    acceptType,
    selectedFields
  );
  if (!ok) {
    return { ok, blob: null, message };
  } else {
    return {
      ok,
      blob,
      message,
    };
  }
};
