import React from "react";
import { useLocation } from "react-router-dom";

import analytics from "~/analytics";

export default function useGoogleAnalytics(userId?: string) {
  const location = useLocation();

  React.useEffect(() => {
    analytics.init(userId);
  }, [userId]);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    analytics.registerPageView(currentPath);
  }, [location]);
}
