import { RcFile } from "antd/lib/upload";
import React, { useCallback, useEffect, useState } from "react";
import { RouteChildrenProps } from "react-router";
import { isEqual as isEqualDates } from "date-fns";
import { getCurrency } from "~/api/expense";
import commonStyle from "~/component/common.module.css";
import DatePicker from "~/component/antd-overrides/DatePicker";
import { AuthState } from "~/app/MainApp/store";
import { addDays, subDays } from "date-fns";
import { connect, ConnectedProps } from "react-redux";
// import PayInvoiceModal from "../modal/PayInvoiceModal";
import InvoiceLineItem, {
  validateColumns,
} from "~/feature/invoice/invoice-details/invoice-line-item/InvoiceLineItem";
import { PaymentStatus, ProductItem } from "~/api/invoice";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import SendMailConfrmModal from "~/component/SendMailConfrmModal";
import AmountsAreSelect from "~/fragment/form-input/AmountsAreSelect";
import ApplyCess from "~/fragment/form-input/ApplyCess";
import {
  OTHER_TERITORY_ID,
  REGULAR_GST_REG_TYPE,
  SEZ_GST_REG_TYPE,
} from "~/lib/constants";
import PaymentDetailPageHeader from "./PaymentDetailPageHeader";
import { TaxTeritoryType, TAX_INCLUSION } from "~/lib/taxes";
import AddProductModalDialog from "~/feature/invoice/modal/AddProductModal";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  notification,
  Modal,
} from "antd";
import { formatDate, FORMAT_DATE, roundNumber } from "~/lib";
import FieldInfo from "~/component/FieldInfo";
import { fetchUtil } from "~/api/common";
import {
  Invoice,
  getInvoiceByID,
  saveInvoiceDetails,
  downloadPDFFile,
  handleEmail,
  newInvoiceDetails,
} from "~/api/invoice";
import VendorSelect from "~/fragment/form-input/VendorSelect";
import IMTContent from "~/layout/main-layout/IMTContent";
import { Required } from "~/lib/form_rules";
import PlaceOfSupply from "~/fragment/form-input/PlaceOfSupply";
import useCqdForm from "~/lib/hook/useCqdForm";
import {
  InvoiceLineItemRecord,
  DeletedInvoiceLineItemRecord,
} from "~/feature/invoice/invoice-details/invoice-line-item/InvoiceLineItemTypes";
import { getTotalGST } from "~/lib/taxCalculation";
import { PaymentDetails } from "~/feature/expense/ExpenseModal";
import { _FORCE_PRODUCT_LIST_REFRESH } from "~/component/field-input/ProductSelect";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";
import AttachableField from "~/fragment/attachable-field/AttachableField";
import { useInvoiceDocumentTypeList } from "~/lib/hook/api-hook/picklistHooks";
import ReadOnlyableSelect from "~/component/field-input/ReadOnlyableSelect";
import { registerEvent } from "~/analytics";
import styles from "./PaymentDetailsPage.module.css";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
import * as ga from "~/contants/gaConstants";

type PathParams = {
  id?: string;
  slug?: "new" | "copy";
};

type LocationState = {
  data?: Invoice;
  fileList?: Array<RcFile>;
  search?: string;
};

const DEFAULT_TAX_INCLUSION: TAX_INCLUSION = "ExclusiveOfTax";

const mapStateToProps = (state: AuthState) => ({
  place_of_supply_id: state.mainAppSlice.user.place_of_supply,
  activeRole: state.mainAppSlice.user.activeRole,
  baseCompanyGstin: state.mainAppSlice.user.gstin,
});

const connector = connect(mapStateToProps);

const getCurrencyConversionList = async (
  symbol_name: string | undefined,
  invoice_date: Date
) => {
  // if (symbol_name !== "INR") {
  const { ok, message, data } = await getCurrency(symbol_name!, invoice_date);
  if (ok) {
    return data!;
  } else {
    notification.error({ message });
  }
};

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props
  extends RouteChildrenProps<PathParams, LocationState>,
    PropsFromRedux {}

const InvoiceDetailsPage = (props: Props) => {
  const [deletedRows, setDeletedRows] = useState<
    Array<DeletedInvoiceLineItemRecord>
  >([]);

  const OPTS_TERMS = [
    { value: 0, label: "Due on receipt" },
    { value: 5, label: "Net 5" },
    { value: 10, label: "Net 10" },
    { value: 15, label: "Net 15" },
    { value: 30, label: "Net 30" },
    { value: 60, label: "Net 60" },
  ];

  const EXPORT_TYPE = [
    {
      value: "WPAY",
      label: "With pay",
    },
    {
      value: "WOPAY",
      label: "Without pay (LUT bond)",
    },
  ];
  const invoiceId = props.match?.params.id ? +props.match.params.id : null;
  const isNew = !invoiceId;
  const [normalInfo, setInfo] = useState({
    editMode: false,
    apiState: "idle",
    rippleEffect: false,
    payDlgVisible: false,
    invoiceComplianceFlag: false,
    sendMailModal: false,
    paymentDetail: {} as Partial<PaymentDetails>,
    openProductModal: false,
    invoiceType: "",
    totalInfo: {},
  });

  const { invoiceDocumentTypeList } = useInvoiceDocumentTypeList();

  /*

  data- it is the whole invoice data with extra data
  update- is a function like setstate ,it's help to update the data
  fromProps- antd form properties
  resetDirtyFlag- its help to handle prompt
  prevData- is just one step prev data of data

  */

  const { data, update, formProps, resetDirtyFlag, prevData } = useCqdForm<
    Invoice
  >();
  const isCopy = props.match?.params.slug === "copy";

  // loadInvoiceData is used for load invoice detail from api (its call when detail and copy page open)
  const loadInvoiceData = useCallback(
    async (_invoiceId: number) => {
      resetDirtyFlag();
      setInfo((oldInfo) => ({
        ...oldInfo,
        apiState: "loading",
      }));
      try {
        const { ok, data: invoiceData, message } = await getInvoiceByID(
          _invoiceId
        );

        if (!ok) {
          notification.error({
            message: "Failed to load details",
            description: message,
          });
        } else {
          update({
            ...invoiceData,
            invoice_number:
              props.match?.params.slug === "copy"
                ? undefined
                : invoiceData?.invoice_number,
            paid_flag:
              props.match?.params.slug === "copy" ? 0 : invoiceData?.paid_flag,
            extra: {
              ...invoiceData?.extra,
              apiData: {
                state_code: invoiceData!.state_code!,
              },
              vendor: {
                __init__: {
                  customer_idx: invoiceData?.customer_idx,
                  uid: Math.random(),
                },
                gst_registration_type_id:
                  invoiceData?.customer_gst_registration_type_id,
              } as any,
              hasCess: !!Number(invoiceData?.cess ?? 0),
            },
          });
        }
      } catch (error) {
        console.warn(error);
        notification.warn({ message: "Unable to load invoice!" });
      } finally {
        setInfo((oldInfo) => ({
          ...oldInfo,
          apiState: "idle",
        }));
      }
    },
    // we need to add more dependecies ,but for now eslint disable because some functionality may be broken
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update, props.match?.params.slug]
  );

  // @animesh: have a look
  // used for handle edit mode
  useEffect(() => {
    if (isNew) {
      setInfo((oldInfo) => ({
        ...oldInfo,
        editMode: true,
      }));
      update({ invoice_date: new Date() });

      setTimeout(() => {
        // updating term also updates due date if invoice date is available.
        // This update must happen after invoice_date is updated with a default value
        // SetTimeOut helps achieve that by executing this in a subsequent process tick
        update({ term: OPTS_TERMS[4].value });
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerIdx = data.extra?.vendor?.customer_idx;
  const symbolName = data.extra?.vendor?.symbol_name;
  useEffect(() => {
    if (customerIdx) {
      // we fetch updated currency convertion rate according to invoice date
      getCurrencyConversionList(symbolName, data.invoice_date).then((res) => {
        update((oldData) => {
          return {
            extra: {
              ...oldData.extra,
              convertionRate: res[0].export_rate,
            },
          };
        });
      });
    }
  }, [data.invoice_date, customerIdx, update, symbolName]);

  useEffect(() => {
    // with this effect we handle terms ,due date,invoice date
    if (Number.isFinite(data.term)) {
      const term = data.term || 0;
      const dueDate = data.due_date || new Date();
      const invoiceDate = data.invoice_date || new Date();

      const prevTerm = prevData.term;
      const prevInvoiceDate = prevData.invoice_date;
      const prevDueDate = prevData.due_date;
      // console.log(term, prevTerm);

      const dataUpdate = {} as Partial<Invoice>;
      let touched = false;

      if (term !== prevTerm) {
        // console.log("++++++++++++++++++++++", invoiceDate);
        const newDueDate = addDays(invoiceDate, term);
        dataUpdate.due_date = newDueDate;
        if (!data.invoice_date) {
          dataUpdate.invoice_date = invoiceDate;
        }
        touched = true;
      } else if (!isEqualDates(prevInvoiceDate, invoiceDate)) {
        const newDueDate = addDays(invoiceDate, term);
        dataUpdate.due_date = newDueDate;
        touched = true;
      } else if (
        prevDueDate &&
        data.term &&
        !isEqualDates(prevDueDate, dueDate)
      ) {
        const newInvoiceDate = subDays(dueDate, term);
        dataUpdate.invoice_date = newInvoiceDate;
        touched = true;
      }
      if (touched) {
        update(dataUpdate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, data.invoice_date, data.term]);

  useEffect(() => {
    // ******* with this effect ,according to state code of a vendor we manage all different type of action******
    if (
      data.state_code === OTHER_TERITORY_ID ||
      // data.extra?.vendor?.gstin === null &&
      (data.extra?.vendor?.state_code === OTHER_TERITORY_ID &&
        !(prevData.extra?.vendor as any)?.__init__)
    ) {
      update((oldData) => {
        return {
          tax_inclusion: "OutOfTax",
          extra: {
            ...oldData.extra,
            hasCess: false,
          },
        };
      });
    } else if (
      prevData.tax_inclusion === "OutOfTax" &&
      data.state_code !== OTHER_TERITORY_ID &&
      data.extra?.vendor?.state_code === OTHER_TERITORY_ID &&
      data.extra?.vendor?.gstin &&
      data.extra?.vendor?.customer_id !== data.customer_id &&
      !isNew
    ) {
      update((oldData) => {
        return {
          tax_inclusion: DEFAULT_TAX_INCLUSION,
          extra: {
            ...oldData.extra,
            hasCess: false,
          },
        };
      });
    }
    if (isNew && props.match?.params.slug !== "copy") {
      update((oldData) => {
        const taxInclusion =
          data.state_code === OTHER_TERITORY_ID ||
          data.extra?.vendor?.state_code === OTHER_TERITORY_ID ||
          (prevData.extra?.vendor?.customer_id
            ? prevData.extra?.vendor?.customer_id === data.customer_id
            : false)
            ? "OutOfTax"
            : DEFAULT_TAX_INCLUSION;
        return {
          tax_inclusion: taxInclusion,
        };
      });
    } else {
      update((oldData) => {
        const taxInclusion =
          !oldData.tax_inclusion ||
          (data.extra?.vendor?.customer_id !== data.customer_id &&
            prevData.extra?.vendor?.customer_id !== undefined &&
            data.extra?.vendor?.state_code !== OTHER_TERITORY_ID)
            ? DEFAULT_TAX_INCLUSION
            : oldData.tax_inclusion;
        return {
          tax_inclusion: taxInclusion,
        };
      });
    }
  }, [update, data.state_code]);

  /* const regTypeId =data.extra?.vendor?.gst_registration_type_id */
  useEffect(() => {
    if (data.tax_inclusion === "OutOfTax") {
      update((oldData) => ({
        extra: {
          ...oldData.extra,
          hasCess: false,
        },
      }));
    }
  }, [update, data.tax_inclusion]);

  useEffect(() => {
    /*   if (!isNew || !isCopy) {
      return;
    } */
    if (data.export_type === "WOPAY") {
      update((oldData) => {
        return {
          tax_inclusion: "OutOfTax",
          extra: {
            ...oldData.extra,
            hasCess: false,
          },
        };
      });
    } else if (data.export_type === "WPAY") {
      update((oldData) => {
        return {
          tax_inclusion: DEFAULT_TAX_INCLUSION,
          extra: {
            ...oldData.extra,
          },
        };
      });
    }

    if (data.export_type === "WOPAY") {
      update((oldData) => {
        return {
          tax_inclusion: "OutOfTax",
          extra: {
            ...oldData.extra,
            hasCess: false,
          },
        };
      });
    }
    // We are actually not dependent on data.extra , so disabling eslint deps check, unless we introduces callback style update function
    // eslint - disable - next - line react - hooks exhaustive - deps
  }, [update, data.export_type, isNew, isCopy]);

  useEffect(() => {
    // Manually parsing the query params as react-router v4 dosen't support auto parse
    //  if invoice id is present that mean we want to open invoicedetail page or copy a existing invoice
    const fromId = new URLSearchParams(props.location.search).get("from") || "";
    if (invoiceId) {
      loadInvoiceData(invoiceId);
    } else if (props.match?.params.slug === "copy" && fromId) {
      loadInvoiceData(+fromId);
    }
  }, [invoiceId, loadInvoiceData, props.location.search, props.match]);

  // handler to handle invoice doc upload
  const handlePanDocChange = (newFileName: string, documentName: string) => {
    update({
      documents: newFileName,
      document_name: documentName,
    });
  };

  // handler for open new product add modal
  const handleAddProduct = usePersistantCallbackRef((e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.invoiceDetailsPage146
    );
    setInfo((oldInfo) => ({
      ...oldInfo,
      openProductModal: true,
    }));
  });

  // handler for close new product add modal
  const closeProductModal = () => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      openProductModal: false,
    }));
  };

  // with this handler we handle payment dialog
  const handlePayDlg = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.invoiceDetailsPage147
    );
    const { paid_flag } = data;
    // paid flag 1 mean payment is already done
    if (paid_flag === 1) {
      return notification.success({ message: "Already paid" });
    } else {
      setInfo((oldValue) => ({
        ...oldValue,
        payDlgVisible: true,
      }));
    }
  };

  const handlePayDlgFromAsk = (target: HTMLButtonElement) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.invoiceDetailsPage148
    );
    const { paid_flag } = data;
    if (paid_flag === 1) {
      return notification.success({ message: "Already paid" });
    } else {
      setInfo((oldValue) => ({
        ...oldValue,
        payDlgVisible: true,
      }));
    }
  };
  // console.log(data.state_code, props.place_of_supply_id)
  const taxTeritoryType =
    data.state_code === OTHER_TERITORY_ID && data.export_type !== "WPAY"
      ? TaxTeritoryType.OTHER_TERITORY
      : data.state_code === props.place_of_supply_id
      ? TaxTeritoryType.SAME_STATE
      : TaxTeritoryType.INTRA_STATE;
  // console.log(taxTeritoryType)

  const onSave = usePersistantCallbackRef(async (e: React.MouseEvent) => {
    // apiData:- we create a api data from data to set payload accordingly backend need
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.invoiceDetailsPage149
    );
    try {
      await formProps.form.validateFields();
      const newRowData = [...data.row_data];
      newRowData.forEach(validateColumns);
      const hasRowDataError = newRowData.some(
        (it: any) => it.extra?.errors?.length > 0
      );

      // this is func used for cheack any required field is empty?
      handleRowDataChange(newRowData);
      if (hasRowDataError) {
        notification.warning({
          message: "Please fill all required fields for the line items !",
        });
        return;
      }

      const apiRequestData = {
        ...data,
        row_data: [...data.row_data, ...deletedRows],
      };

      apiRequestData.customer_idx = data.extra?.vendor?.customer_idx;
      apiRequestData.customer_id = data.extra?.vendor?.customer_id;
      apiRequestData.customer_currency = data.extra?.vendor?.symbol;
      apiRequestData.symbol_name = data.extra?.vendor?.symbol_name;
      apiRequestData.customer_gstin_id = data.extra?.vendor?.customer_gstin_id!;
      apiRequestData.customer_gstin = data.extra?.vendor?.gstin;
      apiRequestData.conversion_rate = String(data.extra?.convertionRate);
      apiRequestData.place_of_supply = data.extra?.vendor?.place_of_supply;
      apiRequestData.customer_name = data.extra?.vendor?.company_name;
      apiRequestData.customer_email = data.extra?.vendor?.email_id;
      apiRequestData.invoice_billing_address = data.extra?.vendor?.billing_address!;
      apiRequestData.customer_gstin = data.extra?.vendor?.gstin;
      apiRequestData.place_of_supply_id = data.state_code;
      apiRequestData.invoice_type = data.invoice_type;

      // gettotal- use for calculation line item's amount ,gst and cess
      const taxes = getTotalGST(
        apiRequestData.row_data.map((_it) => {
          const it = (_it as any) as InvoiceLineItemRecord;
          return {
            amount: it.amount,
            cessPercentage: it.extra?.cessRate?.cessPercentage ?? 0,
            gstPercentage: it.extra?.gstRate?.rate ?? 0,
          };
        }),
        {
          taxInclusion: data.tax_inclusion,
          taxTeritoryType,
        }
      );

      apiRequestData.amount = taxes.subTotal;
      apiRequestData.net_total = taxes.total;
      apiRequestData.cgst = taxes.cgst;
      apiRequestData.igst = taxes.igst;
      apiRequestData.sgst = taxes.sgst;
      apiRequestData.cess = taxes.cess;

      // delete extra data as backend no need this

      delete apiRequestData.extra;
      if (!apiRequestData.customer_idx) {
        notification.error({
          message: "Please select vendor",
        });
        return;
      }
      // call api for edit a existing invoice save
      if (!isNew) {
        if (apiRequestData.customer_idx) {
          const { ok, message } = await saveInvoiceDetails(
            apiRequestData as any
          );
          if (!ok) {
            if (message === "Error: Unable to update invoice compliances") {
              props.history.goBack();
              setInfo((oldInfo) => ({
                ...oldInfo,
                editMode: false,
              }));
              notification.info({ message: "Can't Edit this invoice" });
            } else {
              notification.error({ message });
            }
          } else {
            notification.success({ message });
            setInfo((oldInfo) => ({
              ...oldInfo,
              editMode: false,
            }));
            // TODO: dont goBack, set EditMode = false & load invoice details again,
            resetDirtyFlag();
            // props.history.goBack();
          }
        } else {
          notification.error({
            message: "Please select customer",
          });
        }
      } else {
        // incase of new / copy invoice we call this part
        const { ok, data, message } = await newInvoiceDetails(
          apiRequestData as any
        );
        if (!ok) {
          if (message === "Error: Unable to update invoice compliances") {
            // TODO: dont goBack, set EditMode = false & load invoice details again,
            props.history.goBack();
            setInfo((oldInfo) => ({
              ...oldInfo,
              editMode: false,
            }));
            notification.info({ message: "Can't Edit this invoice" });
          } else {
            notification.error({ message });
          }
        } else {
          setInfo((oldInfo) => ({
            ...oldInfo,
            editMode: false,
          }));
          notification.success({
            message: "Successfully created invoice",
            duration: 1,
          });
          resetDirtyFlag();

          setTimeout(() => {
            askForPayment(data.invoice_id);
          }, 2000);
        }
      }
    } catch (error) {
      console.warn("error");
    }
  });

  const closeNotification = (newInvoiceId: any) => {
    // when notification call then we redirect to new created invoce detail page
    props.history.replace(`/app/invoice/${newInvoiceId}`);
  };

  // call if we want to close the notification
  const cancelNotification = (key: any) => {
    notification.close(key);
  };

  // askForPayment - used for open a confirmation pop notification to ask ,want to  pay or not

  const askForPayment = (newInvoiceId: number) => {
    const key = `open${Date.now()}`;
    const close = closeNotification(newInvoiceId) as any;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={(e: React.MouseEvent) => {
          registerEvent(
            ga.EVENT_CATEGORY_BUTTON_CLICK,
            ga.EVENT_CLICK,
            ga.events.invoiceDetailsPage150
          );
          notification.close(key);
          const invDataLoadPromise = loadInvoiceData(newInvoiceId);
          const target = e.target;
          invDataLoadPromise.then(() => {
            handlePayDlgFromAsk(target as HTMLButtonElement);
          });
        }}
      >
        Confirm
      </Button>
    );
    notification.open({
      message: "Already received the payment ?",
      description: "",
      btn,
      key,
      onClose: close,
    });
  };

  // with this function we can handle edit mode enable and disable
  const onEdit = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.invoiceDetailsPage151
    );
    setInfo((oldInfo) => ({
      ...oldInfo,
      editMode: true,
    }));
  };

  const goToEditPage = (key: any) => {
    notification.close(key);
    props.history.push(`/app/masters/vendor/${data.customer_id}`);
  };

  // used for ,when user try to  send invoice to vendor email but there is no email exist
  // in thet case this notification alart user to add vendor email(redirect to vendor detail page) or cancel
  const showEmailNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "10px" }}
            onClick={(e: React.MouseEvent) => {
              registerEvent(
                ga.EVENT_CATEGORY_BUTTON_CLICK,
                ga.EVENT_CLICK,
                ga.events.invoiceDetailsPage152
              );
              cancelNotification(key);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={(e: React.MouseEvent) => {
              registerEvent(
                ga.EVENT_CATEGORY_BUTTON_CLICK,
                ga.EVENT_CLICK,
                ga.events.invoiceDetailsPage153
              );
              goToEditPage(key);
            }}
          >
            Edit vendor/customer
          </Button>
        </div>
      </>
    );
    notification.info({
      message: "No email address found attached to this vendor/customer",
      duration: 8,
      btn,
      key,
    });
  };

  // when we want to send invoice this func open a confirmation modal
  const handleSendInvoiceModal = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.invoiceDetailsPage154
    );
    const { customer_email } = data;
    if (customer_email === null || customer_email?.length === 0) {
      showEmailNotification();
    } else {
      setInfo((oldInfo) => ({
        ...oldInfo,
        sendMailModal: true,
      }));
    }
  };

  const handleSendMailModalCancel = () => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      sendMailModal: false,
    }));
  };

  const downloadinvoice = (e: React.MouseEvent) => {
    // for download invoice we used it
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.invoiceDetailsPage155
    );
    downloadPDFFile(data.invoice_id as any)
      .then((res: any) => {
        const blob = new Blob([res], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const printWin: any = window.open(url);
        printWin.print();
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const handleCancel = (isNew: boolean) => () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.invoiceDetailsPage156
    );
    if (isNew) {
      props.history.goBack();
    } else {
      window.location.reload();
    }
  };

  const handleDelete = (e: React.MouseEvent) => {
    // in case of delete a invoice we used it
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.invoiceDetailsPage157
    );
    const invoice = data;
    const title =
      invoice.compliance_status === "Complete" &&
      (invoice.status === PaymentStatus.DONE || invoice.paid_flag === 1) ? (
        <>
          <div>Do you really wish to delete this invoice?</div>
          <div>
            Note: Payment for this invoice is already logged and compliances are
            completed. Delete at your own risk
          </div>{" "}
        </>
      ) : invoice.status === PaymentStatus.DONE || invoice.paid_flag === 1 ? (
        <>
          <div>Do you really wish to delete this invoice?</div>
          <div>
            Note: Payment for this invoice is already logged and compliances are
            completed. Delete at your own risk
          </div>{" "}
        </>
      ) : (
        <div>
          <div>Do you wish to delete this invoice?</div>
          <div className={styles.invoice}>
            Customer Name {invoice.customer_name ?? "N/A"}, Invoice no.{" "}
            {invoice.invoice_number ?? "N/A"}, <br />
            Date {formatDate(invoice.invoice_date)}
          </div>
        </div>
      );

    Modal.confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        const { ok, message } = await fetchUtil(
          "POST",
          "/update_invoice/delete_invoice",
          {
            invoice_id: data.invoice_id,
          }
        );
        if (!ok) {
          notification.warn({ message });
        } else {
          notification.success({ message });
          resetDirtyFlag();
          props.history.push(
            `/app/invoice${props.location.state?.search ?? ""}`
          );
        }
      },
    });
  };

  const handlePayDlgVisibleChange = (newVState: boolean) => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      payDlgVisible: false,
    }));
  };

  // for adding new product we call api through this func

  const handleNewProductSubmit = async (val: ProductItem) => {
    const { ok, message } = await fetchUtil("POST", "/add_product", {
      product_description: val.description,
      product_name: val.product_name,
      hsn_sac: val.hsn_sac,
      rate: Number(val.rate),
      supply_type: val.supply_type,
      category_id: val.category_id,
      gst_rate_id: val.gst_rate_id,
      is_taxable: val.is_taxable,
      unit_id: val.unit_id,
      is_non_gst_supply: val.is_non_gst_supply,
      is_reverse_charge_applicable: val.is_reverse_charge_applicable,
    });
    if (!ok) {
      notification.warn({ message });
    } else {
      // Temporary solution
      //#region Hackity-hack #202109251137
      _FORCE_PRODUCT_LIST_REFRESH();
      //#endregion Hackity-hack

      notification.success({ message });
      closeProductModal();
    }
  };

  // after paymet is done we redirect to that new created invoice detail page
  const handleAfterPayment = () => {
    props.history.replace(`/app/invoice/${data.invoice_id}`);
  };

  // send email api
  const sendEmail = async () => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      apiState: "loading",
    }));
    try {
      const { ok, message } = await handleEmail(data.invoice_id as any);
      if (ok) {
        handleSendMailModalCancel();
        setInfo((oldInfo) => ({
          ...oldInfo,
          apiState: "idle",
        }));
        notification.success({
          message,
        });
      } else {
        showEmailNotification();
        setInfo((oldInfo) => ({
          ...oldInfo,
          apiState: "idle",
        }));
      }
    } catch (err) {
      notification.error({
        message: "Error",
      });
    }
  };

  // in this effect we want to cheack if invoice no is exceed 16 char or not
  useEffect(() => {
    if (data?.invoice_number?.length <= 16) {
      update({
        invoice_number: data.invoice_number,
      });
    } else if (data.invoice_number) {
      notification.error({
        message: "Invoice number should not exceed 16 characters",
      });
      update((oldData) => ({
        invoice_number: prevData.invoice_number,
      }));
    }
  }, [update, data.invoice_number]);

  // with this func we can search option on terms select item
  const filterTerms = (input: any, option: any) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  useEffect(() => {
    // according to customer change we take action on that
    const doWork = async () => {
      if (data.customer_id === data.extra?.vendor?.customer_id) {
        // CASE: vendor from expense_details API & dropdown's are equal
        // then
        // SUB-CASE 1: if vendor is modified take modified vendor's place_of_supply
        // SUB-CASE 2: else take place_of_supply from expense_details API
        update({
          export_type: data.export_type ? data.export_type : "",
          tax_inclusion:
            prevData.extra?.vendor?.state_code === OTHER_TERITORY_ID
              ? "OutOfTax"
              : data.tax_inclusion,
          state_code:
            prevData.extra?.vendor?.customer_idx &&
            prevData.extra?.vendor?.customer_idx !==
              data.extra?.vendor?.customer_idx
              ? data.extra?.vendor?.state_code
              : data.extra?.apiData?.state_code,
        });
      } else {
        update({
          export_type: "",
          tax_inclusion:
            prevData.extra?.vendor?.state_code === OTHER_TERITORY_ID
              ? "OutOfTax"
              : data.tax_inclusion,
          state_code: data.extra?.vendor?.state_code,
        });
      }
      const convertionList = await getCurrencyConversionList(
        data.extra?.vendor?.symbol_name,
        data.invoice_date
      );
      if (data.extra?.vendor?.symbol_name) {
        const selectedConvertionRate = convertionList[0].export_rate;
        update((oldData) => ({
          extra: {
            ...oldData.extra,
            convertionRate: selectedConvertionRate,
          },
        }));
      }
    };

    if (
      prevData.extra?.vendor !== data.extra?.vendor &&
      data.extra?.vendor?.state_code
    ) {
      doWork();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, data.extra?.vendor?.customer_idx]);

  //  this effect is hlep to find invoice_type
  const handleTotalAmt = useCallback(
    (info: any) => {
      if (
        info.total > 0 && isNew && !isCopy
          ? data.state_code
          : prevData.state_code
      ) {
        const invoiceType = getInvoiceType(
          info as any,
          data.extra?.vendor?.gst_registration_type_id,
          data.state_code
        );
        update({
          invoice_type: invoiceType,
        });
      }
    },
    [data.state_code, isNew, isCopy, prevData.state_code]
  );

  // if we delete a row item then it set into the deletedRowData and one of its property "is_active " become false
  const handleRowDataChange = (
    newRowData: Array<InvoiceLineItemRecord>,
    deletedRowData?: InvoiceLineItemRecord
  ) => {
    update({
      row_data: newRowData,
    });

    if (deletedRowData) {
      setDeletedRows((oldRows) => [
        ...oldRows,
        {
          ...deletedRowData,
          is_active: false,
        },
      ]);
    }
  };

  // disable date cond - only the current financial year is enable
  const disabledDate = (current: Date) => {
    const year = new Date().getFullYear();
    return (
      current.getTime() < new Date(`${year - 1}-04-01`).getTime() ||
      current.getTime() > new Date(`${year + 1}-04-01`).getTime()
    );
  };

  // through this func we render the upper portion of invoce detail page
  const renderInvoiceHeader = () => {
    const { editMode } = normalInfo;

    const dateView = editMode ? null : (
      <Form.Item className={commonStyle["w-100"]} label="Invoice date">
        <input
          className="ant-input"
          defaultValue={data.invoice_date ? formatDate(data.invoice_date) : ""}
          readOnly
        />
      </Form.Item>
    );
    const dateView1 = editMode ? null : (
      <Form.Item className={commonStyle["w-100"]} label="Due date">
        <input
          className="ant-input"
          defaultValue={data.due_date ? formatDate(data.due_date) : ""}
          readOnly
        />
      </Form.Item>
    );
    return (
      <Row style={{ width: "70%" }} gutter={{ md: 30, lg: 32, xl: 100 }}>
        <Helmet>
          <title>{titles.InvoiceDetailsPage}</title>
        </Helmet>
        <Col md={16} lg={14} xl={12} xxl={10}>
          <Row gutter={{ md: 8, lg: 16 }}>
            <Col sm={24} lg={12}>
              <Form.Item
                className={commonStyle["w-100"]}
                name="invoice_number"
                label="Invoice number"
                rules={[
                  {
                    required: false,
                    pattern: /^[a-zA-Z0-9-/]+$/,
                    message: "Please enter valid Invoice number",
                  },
                ]}
              >
                <Input
                  autoFocus
                  placeholder={"Unique invoice No"}
                  readOnly={!editMode}
                  // maxLength={16}
                />
              </Form.Item>
              {editMode ? (
                <div style={{ marginTop: "8px" }}>
                  <FieldInfo
                    text={
                      "If left blank, system auto generates an invoice number."
                    }
                    subtle
                  />
                </div>
              ) : null}
            </Col>
            <Col sm={24} lg={12}>
              {/* {termsView || ( */}
              <Form.Item
                className={commonStyle["w-100"]}
                name="term"
                label="Payment terms"
                rules={Required("Payment terms")}
              >
                <Select
                  disabled={!editMode}
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterTerms}
                >
                  {OPTS_TERMS.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/* )} */}
            </Col>
          </Row>
          <Row gutter={{ md: 8, lg: 16 }}>
            <Col sm={24} lg={12}>
              {dateView || (
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="invoice_date"
                  label="Invoice date"
                  rules={Required("Invoice date")}
                >
                  <DatePicker
                    format={FORMAT_DATE}
                    /* defaultValue={new Date()} */
                    disabled={!editMode}
                    disabledDate={disabledDate}
                    inputReadOnly={!editMode}
                    className={commonStyle["w-100"]}
                  />
                </Form.Item>
              )}
            </Col>
            <Col sm={24} lg={12}>
              {dateView1 || (
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="due_date"
                  label="Due date"
                  rules={Required("Due date")}
                >
                  <DatePicker
                    format={FORMAT_DATE}
                    disabled={!editMode}
                    inputReadOnly={!editMode}
                    className={commonStyle["w-100"]}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row gutter={{ md: 8, lg: 16 }}>
            <Col sm={24} lg={12}>
              <Form.Item
                className={commonStyle["w-100"]}
                name="po_number"
                label="Purchase order number"
                rules={[
                  {
                    required: false,
                    message: "Please enter purchase order number",
                  },
                ]}
              >
                <Input readOnly={!editMode} />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              <Form.Item
                className={commonStyle["w-100"]}
                name="dc_number"
                label="Delivery challan number"
                rules={[
                  {
                    required: false,
                    message: "Please enter delivery challan number",
                  },
                ]}
              >
                <Input readOnly={!editMode} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name={["extra", "vendor"]} rules={Required("Vendor")}>
            <VendorSelect
              activeRole={props.activeRole}
              readOnly={!normalInfo.editMode}
              isInvoice={true}
            />
          </Form.Item>

          <Form.Item
            label="Place of supply"
            name="state_code"
            rules={Required("Place of supply")}
          >
            <PlaceOfSupply readOnly={!normalInfo.editMode} />
          </Form.Item>
          {data.extra?.vendor &&
          data.extra?.vendor?.symbol_name &&
          data.extra?.convertionRate &&
          data.extra?.vendor?.symbol_name !== "INR" ? (
            <FieldInfo
              text={`Currency & conv. rate: 1 ${
                data.extra?.vendor?.symbol_name
              } = ${roundNumber(+data.extra?.convertionRate)} INR`}
            />
          ) : null}

          <Row
            gutter={{ md: 8, lg: 24 }}
            // style={{marginTop:"10px"}}
          >
            <Col sm={24} lg={12}>
              <Form.Item
                label="Amounts are"
                name="tax_inclusion"
                rules={Required("Amounts are")}
              >
                <AmountsAreSelect
                  readOnly={!normalInfo.editMode}
                  placeOfSupplyId={Number(data.state_code)}
                  exportType={data.export_type}
                />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              <Form.Item label="Apply cess" name={["extra", "hasCess"]}>
                <ApplyCess
                  readOnly={
                    !normalInfo.editMode ||
                    (data.state_code === OTHER_TERITORY_ID &&
                      data.export_type !== "WPAY")
                  }
                  disabled={data.tax_inclusion === "OutOfTax"}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ md: 8, lg: 16 }}>
            <Col sm={24} lg={12} />
            {/* {!disabled ? ( */}
            <Col
              sm={24}
              lg={11}
              style={{
                marginLeft: "10px",
              }}
            />

            {/* ) : null} */}
          </Row>
        </Col>

        <Col md={8}>
          <div>Attach suppliment file</div>
          <AttachableField
            fieldName="document_type_id"
            hashFileName={data.documents}
            onHashFileChange={handlePanDocChange}
            hashFileFieldName="documents"
            readonly={!editMode}
            form={formProps.form}
            label="Type of file"
            rules={[
              {
                type: "number",
                message: "Please select document type",
              },
              ({ getFieldValue }) => {
                return {
                  validator: async () => {
                    const docTypeId = getFieldValue("document_type_id");
                    const hasAttatched = !!getFieldValue("documents");
                    if (docTypeId && !hasAttatched) {
                      /*eslint no-throw-literal: "error"*/
                      throw new Error("Please attach document");
                    }
                  },
                };
              },
            ]}
          >
            <ReadOnlyableSelect allowClear readonly={!editMode}>
              {invoiceDocumentTypeList.map((it) => (
                <Select.Option key={it.id} value={it.id}>
                  {it.document_type_name}
                </Select.Option>
              ))}
            </ReadOnlyableSelect>
          </AttachableField>
          {data.state_code === OTHER_TERITORY_ID ||
          data.extra?.vendor?.gst_registration_type_id === SEZ_GST_REG_TYPE ? (
            <div className={commonStyle["w-70"]}>
              <Form.Item
                name="export_type"
                label="Export type"
                rules={Required("Export type")}
              >
                <Select
                  disabled={!editMode}
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterTerms}
                >
                  {EXPORT_TYPE.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <FieldInfo
                text="When sales are made with LUT bond to a SEZ Unit or Outside India, the tax liability disappears from the transaction"
                tip
              />
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    );
  };

  const { sendMailModal, payDlgVisible } = normalInfo;

  const currencyInfo = data.extra?.vendor
    ? {
        name: data.extra.vendor.symbol_name,
        symbol: data.extra.vendor.symbol,
        conversionRate: data.extra.convertionRate || 1,
      }
    : undefined;

  const isEditing = !!normalInfo.editMode;
  const isLoading = normalInfo.apiState !== "idle";
  return (
    <>
      <IMTContent fullwidth withoutMargin={true}>
        {/* {renderHeader()} */}
        <PaymentDetailPageHeader
          isNew={isNew}
          paidFlag={data.paid_flag}
          invoiceId={invoiceId!}
          activeRole={props.activeRole}
          isEditing={normalInfo.editMode}
          invoiceComplianceFlag={normalInfo.invoiceComplianceFlag}
          rippleEffect={normalInfo.rippleEffect}
          handlePaymentDialog={handlePayDlg}
          apiState={normalInfo.apiState}
          slug={props.match?.params.slug!}
          onCancel={handleCancel(isNew)}
          onDelete={handleDelete}
          onSave={onSave}
          onEdit={onEdit}
          onSendEmail={handleSendInvoiceModal}
          onDownloadInvoice={downloadinvoice}
          complianceStatus={data.compliance_status!}
          arryOfTag={[
            { tagName: `${data.amended ? "Amended" : ""}`, color: "#F07C2E" },
            {
              tagName: `${data.invoice_type ? data.invoice_type : ""}`,
              color: "#ebc334",
            },
          ]}
        />
        <div className={styles.commonContent}>
          <Form style={{ width: "100%" }} {...formProps}>
            {renderInvoiceHeader()}

            {/* antd line item component  */}
            <InvoiceLineItem
              hasCess={data.extra?.hasCess}
              rowData={data.row_data}
              onRowDataChange={handleRowDataChange}
              readOnly={!isEditing}
              isLoading={isLoading}
              tax_inclusion={data.tax_inclusion}
              taxTeritoryType={taxTeritoryType}
              currency={currencyInfo}
              onAddProduct={handleAddProduct}
              isCopy={props.match?.params.slug === "copy"}
              totalPayAmount={data.paid_amount}
              getTotal={handleTotalAmt}
              tdsInfo={{
                tdsRate: data.tds_rate,
                tdsAmount: data.tds_amount,
              }}
              sameState={data.state_code === props.place_of_supply_id}
              exportType={data.export_type}
            />
          </Form>
        </div>
        {/* <div>
          <h3>Data</h3>
          <code>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </code>
        </div> */}
      </IMTContent>
      {/* render send mail modal */}
      {sendMailModal && (
        <SendMailConfrmModal
          apiState={normalInfo.apiState}
          visible={sendMailModal}
          onVisibleChange={handleSendMailModalCancel}
          sendEmail={sendEmail}
          details={data}
        />
      )}

      {/* payemnt of invoice modal  */}
      {/* {payDlgVisible === true ? (
        <PayInvoiceModal
          visible={payDlgVisible}
          onVisibleChange={handlePayDlgVisibleChange}
          selectInvoiceDetail={data}
          fetchData={handleAfterPayment}
          placeOfSupply={Number(data.state_code)}
        />
      ) : null} */}
      {/* add product modal */}
      {normalInfo.openProductModal ? (
        <AddProductModalDialog
          onProductSubmit={handleNewProductSubmit}
          visible={normalInfo.openProductModal}
          closeProductModal={closeProductModal}
          // loadProduct={this.state.loadProduct}
          loadProduct={false}
        />
      ) : null}
    </>
  );
};

const getInvoiceType = (totalInfo: any, regTypeId: any, stateCode: any) => {
  const userData = localStorage.getItem("imt__user");
  const userParseData = JSON.parse(userData!);
  if (regTypeId === REGULAR_GST_REG_TYPE) {
    return "B2B";
  } else {
    if (stateCode === OTHER_TERITORY_ID || regTypeId === SEZ_GST_REG_TYPE) {
      return "EXP";
    } else if (userParseData.place_of_supply === stateCode) {
      return "B2CS";
    } else {
      if (totalInfo.total > 250000) {
        return "B2CL";
      } else {
        return "B2CS";
      }
    }
  }
};

export default connector(InvoiceDetailsPage);
