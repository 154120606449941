import React, { Component } from "react";
import {
  Modal,
  Input,
  Radio,
  notification,
  AutoComplete,
  Button,
  Row,
  Col,
  Form,
  Select,
} from "antd";
import { FormInstance } from "antd/lib/form";
import FieldWithAction from "~/component/FieldWithAction";
import { APIState, fetchUtil } from "~/api/common";
import { getCity } from "~/api/vendor";
import FieldInfo from "~/component/FieldInfo";
import TextArea from "antd/lib/input/TextArea";
import { noop } from "~/lib";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import "ant-design-draggable-modal/dist/index.css";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

interface Props {
  visible: boolean;
  __init__?: Partial<State>;
  onVisibleChange: (visible: boolean) => void;
  handlenew?: () => void;
  onAddVendor: (name?: any, withGst?: boolean, gstinNo?: any) => void;
}

export interface State {
  company_name: string;
  radioValue: number;
  gstin: string;
  apiState: APIState;
  detailFromGstin: any;
  cityList: any;
  rowCityList: any;
  country: any;
  stateName: any;
  name: any;
  city: any;
  state: string;
  pinCode: any;
  email: string;
  countryNameGstin: any;
  addresslineOne: any;
  fetchLoad: boolean;
  cityName: any;
  pan: any;
  activeFromGstin: boolean;
  textAddress: string;
  pincode: string;
  currencyList: Array<{
    id: number;
    currency_name: string;
    symbol_name: string;
  }>;
  currency_id: number | undefined;
  gstTypeList: Array<{
    id: number;
    type: string;
  }>;
  gstTypeId: number;
  gstTypeName: string;
  tradeName: string;
}

const INITIAL_STATE: State = {
  company_name: "",
  radioValue: 2,
  gstin: "",
  apiState: "idle",
  detailFromGstin: [],
  cityList: [],
  rowCityList: [],
  country: "",
  stateName: "",
  name: "",
  city: "",
  state: "",
  pinCode: "",
  countryNameGstin: "",
  addresslineOne: "",
  fetchLoad: false,
  cityName: "",
  pan: "",
  activeFromGstin: false,
  textAddress: "",
  pincode: "",
  email: "",
  currencyList: [],
  currency_id: undefined,
  gstTypeList: [],
  gstTypeId: 3,
  gstTypeName: "",
  tradeName: "",
};

export default class BasicVendorModal extends Component<Props, State> {
  state: State = INITIAL_STATE;

  componentDidMount() {
    if (this.props.__init__?.gstin) {
      this.setState({
        gstin: this.props.__init__?.gstin,
      });
    }
    this.getCurrencyList();
    this.getGstinType();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.__init__?.gstin &&
      this.props.__init__?.gstin !== prevProps.__init__?.gstin
    ) {
      this.setState({
        gstin: this.props.__init__?.gstin,
      });
    }
    if (this.props.visible !== prevProps.visible && this.props.visible) {
      this.getCurrencyList();
      this.getGstinType();
    }
  }

  handleValueChange = (e: any) => {
    if (e?.length >= 3) {
      this.loadCityList(e);
    } else {
      this.setState({
        stateName: "",
        country: "",
      });
    }
  };

  handleAddress = (event: any) => {
    this.setState({
      textAddress: event?.target.value,
    });
  };

  handlePincode = (event: any) => {
    this.setState({
      pincode: event?.target.value,
    });
  };

  handleEmail = (event: any) => {
    this.setState({
      email: event?.target.value,
    });
  };

  async getCurrencyList() {
    const { ok, json, message } = await fetchUtil("GET", `/get_currency`);

    if (ok) {
      const data = json;
      this.setState({
        currencyList: data,
      });
    } else {
      notification.error({ message });
    }
  }

  async getGstinType() {
    const { ok, json, message } = await fetchUtil(
      "GET",
      `/get_gst_registration_types`
    );

    if (ok) {
      const data = json
        .map((v) => ({ id: v.id, type: v.gst_type }))
        .filter((v) => [3, 4, 5].indexOf(v.id) > -1); // Only unregistered and related types
      this.setState({
        gstTypeList: data,
      });
    } else {
      notification.error({ message });
    }
  }

  handleCancel = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.basicVendorModal14
    );

    this.setState(INITIAL_STATE);
    this.props.onVisibleChange(false);
  };

  handleName = (event: any) => {
    this.setState({
      company_name: event?.target.value,
    });
  };

  handleGstIn = (event: any) => {
    this.setState(
      {
        gstin: event?.target.value.trim().toUpperCase(),
      },
      () => {
        if (this.state.gstin.length < 15) {
          this.setState({
            name: "",
            company_name: "",
            stateName: "",
            addresslineOne: "",
            city: "",
            countryNameGstin: "",
            pan: "",
            // gstin: "",
          });
        }
      }
    );
  };

  fetchGstinfo = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.basicVendorModal15
    );

    const GSTIN_REGEX = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}[Z]{1}[A-Z0-9]{1}$/;

    if (!GSTIN_REGEX.test(this.state.gstin)) {
      notification.error({ message: "Please enter a valid GSTIN no !" });
      return;
    }

    this.setState({
      fetchLoad: true,
    });
    if (this.state.radioValue === 2) {
      const gstinUrl = "/get_details_from_gstin";
      const { ok, json } = await fetchUtil("POST", `${gstinUrl}`, {
        gstin: this.state.gstin,
      });
      if (ok) {
        const data = json;
        this.setState({
          detailFromGstin: data,
          name: data[0].legalName,
          city: data[0].city.name,
          state: data[0].state.name,
          pinCode: data[0].pinCode,
          countryNameGstin: data[0].country.name,
          addresslineOne: data[0].addressLine,
          pan: data[0].pan,
          activeFromGstin: data[0].active,
          tradeName: data[0].tradeName,
          gstTypeId: data[0].regType.id,
          gstTypeName: data[0].regType.name,
          fetchLoad: false,
        });
      } else {
        this.setState({ apiState: "error", fetchLoad: false });

        notification.error({
          message: "Some error occured, Try again later",
        });
      }
    }
  };

  handleOk = async () => {
    if (this.state.radioValue === 1) {
      if (!this.state.company_name) {
        notification.error({ message: "Company name is required" });
        return;
      }
      if (!this.state.textAddress) {
        notification.error({ message: "Address is required" });
        return;
      }
      // if (!emaill_reg.test(this.state.email)) {
      //   notification.error({ message: "Please enter a valid email !" });
      //   return;
      // }
      const apiUrl = "/save_customer_details";
      const { ok, json, message } = await fetchUtil("POST", `${apiUrl}`, {
        customer_id: null,
        customer_name: this.state.company_name,
        company_name: this.state.company_name,
        mobile_no: null,
        phone_no: null,
        other_no: null,
        pan_number: null,
        website: null,
        place_of_supply_id: null,
        shipping_address: this.state.textAddress,
        shipping_address2: null,
        shipping_address_city: this.state.cityName,
        shipping_address_state: this.state.stateName,
        shipping_address_country: this.state.country,
        shipping_address_pincode: this.state.pincode,
        // email: this.state.email,
        billing_address: this.state.textAddress,
        company_type: null,
        contact_person_name: null,
        currency_id: this.state.currency_id,
        email_id:
          this.state.email.length > 0 ? [{ email_id: this.state.email }] : null,
        gst_registration_type_id: this.state.gstTypeId,
        billing_address2: null,
        billing_address_city: this.state.cityName,
        billing_address_country: this.state.country,
        billing_address_pincode: this.state.pincode,
        billing_address_state: this.state.stateName,
        tan_number: null,
        is_active: true,
        customer_details: [
          {
            gstin: null,
            state_id: null,
            billing_address: this.state.textAddress,
            billing_address2: null,
            billing_address_city: this.state.cityName,
            billing_address_country: this.state.country,
            billing_address_pincode: this.state.pincode,
            billing_address_state: this.state.stateName,
            trade_name: this.state.company_name,
          },
        ],
        contact_person_number: null,
        source_metadata: {
          type_id: 2,
          uid: "",
          extra_source_json: {},
        },
        trade_name: this.state.company_name,
      });
      if (ok) {
        notification.success({
          message,
        });
        this.setState({ apiState: "success" });
        this.handleCancel();
        this.props.onAddVendor(
          json[0].customer_data?.[0].customer_idx,
          false,
          null
        );
      } else {
        this.setState({ apiState: "error" });
        notification.error({
          message,
        });
      }
    } else if (this.state.radioValue === 2) {
      const pos = this.state.gstin.substring(0, 2);
      const apiUrl = "/save_customer_details";
      const { ok, json, message } = await fetchUtil("POST", `${apiUrl}`, {
        customer_id: null,
        customer_name: this.state.name,
        company_name: this.state.name,
        mobile_no: null,
        phone_no: null,
        other_no: null,
        website: null,
        place_of_supply_id: Number(pos),
        pan_number: this.state.pan,
        shipping_address: this.state.addresslineOne,
        shipping_address2: null,
        shipping_address_city: this.state.city,
        shipping_address_state: this.state.state,
        shipping_address_country: this.state.countryNameGstin,
        shipping_address_pincode: this.state.pinCode,
        billing_address: this.state.addresslineOne,
        company_type: null,
        contact_person_name: null,
        currency_id: 1,
        email_id: null,
        gst_registration_type_id: this.state.gstTypeId,
        billing_address2: null,
        billing_address_city: this.state.city,
        billing_address_country: this.state.countryNameGstin,
        billing_address_pincode: this.state.pinCode,
        billing_address_state: this.state.state,
        gstin: this.state.gstin,
        tan_number: null,
        is_active: true,
        contact_person_number: null,
        customer_details: [
          {
            gstin: this.state.gstin,
            state_id: null,
            billing_address: this.state.addresslineOne,
            billing_address2: null,
            billing_address_city: this.state.city,
            billing_address_country: this.state.countryNameGstin,
            billing_address_pincode: this.state.pinCode,
            billing_address_state: this.state.state,
            trade_name: this.state.tradeName,
            active_customer_gstin_no: this.state.activeFromGstin,
          },
        ],
        source_metadata: {
          type_id: 2,
          uid: "",
          extra_source_json: {},
        },
        trade_name: this.state.tradeName,
      });
      if (ok) {
        notification.success({
          message,
        });
        this.setState({ apiState: "success" });
        this.props.onAddVendor(
          json[0].customer_data?.[0].customer_idx,
          true,
          this.state.gstin
        );
        this.handleCancel();
      } else {
        this.setState({ apiState: "error" });
        notification.error({
          message,
        });
      }
    }
  };

  onRadioChange = (e: any) => {
    this.setState(
      {
        radioValue: e.target.value,
      },
      () => {
        this.setState({
          // company_name: "",
          // gstin: "",
          // country: "",
          // stateName: "",
          // name: "",
          // city: "",
          // state: "",
          // pinCode: "",
          // countryNameGstin: "",
          // addresslineOne: "",
          fetchLoad: false,
          // cityName: "",
          activeFromGstin: false,
        });
      }
    );
  };
  _formRef = React.createRef<FormInstance<any>>();

  loadCityList = async (value: string) => {
    const [cityListRes] = await Promise.all([
      getCity(value as string).then(({ ok, message, data }) => ({
        ok,
        message,
        data,
      })),
    ]);

    if (!cityListRes.ok) {
      notification.error({
        message: "Failed to load city list",
        description: cityListRes.message,
      });
    } else {
      this.setState({
        cityList: cityListRes.data,
        rowCityList: cityListRes.data,
      });
    }
  };

  handleCitySelect = (_value: string, opt: any) => {
    const id = Number(opt.key);
    // tslint:disable-next-line: triple-equals | Double equal is intentional, please don't correct it
    const cityData = this.state.rowCityList.find(
      (it: any) => it.id === String(id)
    )!;
    this.setState({
      country: cityData?.country,
      stateName: cityData?.state,
      cityName: cityData?.city,
    });
    this._formRef?.current?.setFieldsValue({ city: cityData?.city });
  };

  handlecurrency = (value: number) => {
    this.setState({
      currency_id: value,
    });
  };

  handleGstTypeChange = (value: number) => {
    this.setState({
      gstTypeId: value,
    });
  };

  render() {
    const { visible } = this.props;
    const { radioValue } = this.state;

    return (
      <>
        <DraggableModalProvider>
          <DraggableModal
            title="Add vendor/customer"
            visible={visible}
            maskClosable={false}
            initialHeight={this.state.radioValue === 2 ? 320 : 610}
            initialWidth={this.state.radioValue === 2 ? 450 : 524}
            footer={
              <>
                <Button onClick={this.handleCancel} type="ghost">
                  Cancel
                </Button>
                <Button
                  disabled={
                    this.state.radioValue === 2
                      ? !this.state.name
                      : !this.state.stateName ||
                        !this.state.company_name ||
                        !this.state.currency_id
                      ? true
                      : false
                  }
                  onClick={
                    this.state.radioValue === 2
                      ? this.state.name
                        ? this.handleOk
                        : noop
                      : this.handleOk
                  }
                  type="primary"
                >
                  OK
                </Button>
              </>
            }
            onCancel={this.handleCancel}
            destroyOnClose={true}
            // centered
          >
            <Radio.Group
              onChange={this.onRadioChange}
              value={this.state.radioValue}
            >
              <Radio value={2}>With GSTIN</Radio>
              <Radio value={1}>With name (GST unregistered)</Radio>
            </Radio.Group>
            {radioValue === 1 ? (
              <>
                <FieldWithAction
                  label="Company name / Person name"
                  actionLabel=""
                  onAction={noop}
                  required
                >
                  <Input
                    value={this.state.company_name}
                    onChange={this.handleName}
                    autoFocus
                  />
                </FieldWithAction>
                <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
                  <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <FieldWithAction
                      label="Address line"
                      actionLabel=""
                      onAction={noop}
                      required
                    >
                      <TextArea
                        rows={1}
                        value={this.state.textAddress}
                        onChange={this.handleAddress}
                      />
                    </FieldWithAction>
                    <FieldWithAction
                      label="Pincode"
                      actionLabel=""
                      onAction={noop}
                      // required
                    >
                      <Input
                        value={this.state.pincode}
                        onChange={this.handlePincode}
                      />
                    </FieldWithAction>
                  </Col>
                  <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Form ref={this._formRef}>
                      <Form.Item name="city" label="City" required>
                        <AutoComplete
                          style={{ width: "100%" }}
                          id="chrome-off"
                          onSelect={this.handleCitySelect}
                          onChange={this.handleValueChange}
                        >
                          {this.state.cityList.map((v: any, idx: number) => {
                            return (
                              <AutoComplete.Option key={v.id} value={`${v.id}`}>
                                <div>
                                  <span>{v.city}</span>{" "}
                                  <span
                                    style={{
                                      fontSize: 10,
                                      color: "var(--primary-color)",
                                    }}
                                  >
                                    {v.state}, {v.country}
                                  </span>
                                </div>
                              </AutoComplete.Option>
                            );
                          })}
                        </AutoComplete>
                      </Form.Item>
                      <FieldInfo
                        text="Type at lease 3 letters to see suggestions"
                        subtle
                        tip
                      />
                    </Form>
                    <FieldWithAction
                      label="State"
                      actionLabel=""
                      onAction={noop}
                      required
                    >
                      <Input value={this.state.stateName} readOnly />
                    </FieldWithAction>
                    {this.state.stateName ? (
                      <FieldInfo text={`${this.state.country}`} />
                    ) : null}
                  </Col>
                </Row>
                <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
                  <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <FieldWithAction
                      label="Currency"
                      actionLabel=""
                      onAction={noop}
                      required
                    >
                      <Select
                        id="chrome-off"
                        showSearch
                        optionFilterProp="children"
                        onChange={this.handlecurrency}
                        // disabled={this.state.is_disable}
                      >
                        {this.state.currencyList.map(
                          (currency: {
                            id: number;
                            currency_name: string;
                            symbol_name: string;
                          }) => (
                            <Select.Option
                              key={currency.id}
                              value={currency.id}
                            >
                              {`${currency.currency_name} (${currency.symbol_name})`}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </FieldWithAction>
                  </Col>
                  <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <FieldWithAction
                      label="GST registration type"
                      actionLabel=""
                      onAction={noop}
                      required
                    >
                      <Select
                        id="chrome-off"
                        showSearch
                        optionFilterProp="children"
                        onChange={this.handleGstTypeChange}
                        value={this.state.gstTypeId}
                        // disabled={this.state.is_disable}
                      >
                        {this.state.gstTypeList.map(
                          (gstType: { id: number; type: string }) => (
                            <Select.Option key={gstType.id} value={gstType.id}>
                              {gstType.type}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </FieldWithAction>
                    <FieldInfo
                      text="Choose 'GST unregistered' for Indian businesses not having a GSTIN yet or the business is located outside India. Choose 'SEZ' if the business operates from a special economic zone."
                      subtle
                      tip
                    />
                  </Col>
                </Row>
                <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
                  <Col
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{ marginTop: "-60px" }}
                  >
                    <FieldWithAction
                      label="Email"
                      actionLabel=""
                      onAction={noop}
                      // required
                    >
                      <Input
                        value={this.state.email}
                        onChange={this.handleEmail}
                      />
                    </FieldWithAction>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <FieldWithAction
                  label="GSTIN"
                  actionLabel=""
                  onAction={noop}
                  required
                >
                  <Input
                    autoFocus
                    value={this.state.gstin}
                    onChange={this.handleGstIn}
                  />
                </FieldWithAction>
                {this.state.name ? (
                  <FieldInfo
                    text={` ${this.state.name},
                ${this.state.addresslineOne},
               ${this.state.city},
              ${this.state.state},
               ${this.state.countryNameGstin} | ${this.state.gstTypeName} |
              PAN: ${this.state.pan} ${
                      !this.state.activeFromGstin ? `| INACTIVE` : ""
                    }`}
                  />
                ) : null}
                <Button
                  type="primary"
                  loading={this.state.fetchLoad}
                  onClick={this.fetchGstinfo}
                >
                  Fetch details
                </Button>
              </>
            )}
          </DraggableModal>
        </DraggableModalProvider>
      </>
    );
  }
}
