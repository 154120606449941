import React, { useMemo, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { Dropdown, Menu, Space, Button, DatePicker } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import {
  format,
  endOfMonth,
  getMonth,
  subMonths,
  getYear,
  isBefore,
  isFuture,
  addYears,
  getDate,
  isAfter,
} from "date-fns";
import { subYears, addMonths } from "date-fns/esm";
import { CaretLeftFilled, CaretRightFilled } from "@ant-design/icons";
import styles from "./YearMonthDropdown.module.css";
import { months } from "~/lib/constants";
import { LoginRole } from "~/api/auth";

interface Props {
  marginLeft?: string;
  payrollFlag: boolean;
  month: number;
  year: number;
  disabledDate?: (selectedDate: Date, iDate: Date) => boolean;
  onChange?: (month: number, year: number) => boolean;
  activeRole?: any;
}

const MONTH_FORMAT = "MMMM";
const YEAR_FORMAT = "yyyy";

function defaultDisabledDate(_selectedDate: Date, iDate: Date) {
  return isFuture(iDate);
}

// this is for month dropdown
const MonthDropDown = ({
  offset,
  selectedDate,
  onMonthSelect,
  isDisabled,
  monthSelect,
  payrollFlag,
  yearw,
  RoleNow,
}: {
  offset: Date;
  selectedDate: Date;
  onMonthSelect: (info: MenuInfo) => void;
  isDisabled: (month: Date, year: Date) => boolean;
  monthSelect: any;
  payrollFlag: boolean;
  yearw: any;
  RoleNow?: any;
}) => {
  // payrollflag is used for when it render from payroll page
  const thisMonth = getMonth(offset);
  const monthItems = useMemo(() => {
    const items = [];

    for (let i = 0; i < 12; i++) {
      const a = format(subMonths(offset, i), MONTH_FORMAT);
      const fyYearSet = yearw.split("-");
      const xdate =
        months.indexOf(a) > 2
          ? new Date(fyYearSet[0], months.indexOf(a), 1)
          : new Date(fyYearSet[1], months.indexOf(a), 1);
      const currentMonth25th = new Date();
      currentMonth25th.setDate(25);
      const currentMonth1st = new Date();
      currentMonth1st.setDate(1);
      currentMonth1st.setHours(0, 0, 0, 0);
      const today = new Date();
      const iMonth = a;
      // in case from payroll after 25th of current month we can process the salary of current month
      items.push(
        <Menu.Item
          key={iMonth + ""}
          disabled={
            payrollFlag
              ? !(
                  isBefore(xdate, today) &&
                  (isBefore(xdate, currentMonth1st) ||
                    isAfter(new Date(), currentMonth25th))
                ) || iMonth === months[monthSelect]
              : iMonth === months[monthSelect]
          }
        >
          {iMonth}
        </Menu.Item>
      );
    }
    items.push(
      <Menu.Item key={"All"} disabled={monthSelect === 12}>
        {"All"}
      </Menu.Item>
    );
    return items;
  }, [thisMonth, selectedDate]);

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  return (
    <Dropdown.Button
      className={styles.monthBtn}
      overlay={<Menu onClick={onMonthSelect}>{monthItems}</Menu>}
      trigger={["click"]}
    >
      <span>
        {" "}
        {(getDate(selectedDate) === 29 && getMonth(selectedDate) === 1) ||
        (payrollFlag && !query.has("month") && RoleNow === LoginRole.EMPLOYEE)
          ? "All"
          : format(selectedDate, MONTH_FORMAT)}
      </span>
    </Dropdown.Button>
  );
};

// this is for FY year drpdown
// here option in dropdown is current FY and previous two FY
const YearDropDown = ({
  offset,
  selectedDate,
  onYearSelect,
  payrollFlag,
  yearw,
}: {
  offset: Date;
  selectedDate: any;
  onYearSelect: (info: MenuInfo) => void;
  payrollFlag: boolean;
  yearw: any;
}) => {
  const d = new Date();
  const x = Number(d.getDate());
  const y = Number(d.getMonth());
  const yearItems = useMemo(() => {
    const items = [];
    for (let i = 1; i < 2; i++) {
      const fy_year_current =
        format(addYears(offset, i - 5), YEAR_FORMAT) +
        "-" +
        format(addYears(offset, i - 4), YEAR_FORMAT);
      items.push(
        <Menu.Item key={fy_year_current}>{fy_year_current}</Menu.Item>
      );
    }
    for (let i = 1; i < 2; i++) {
      const fy_year_current =
        format(addYears(offset, i - 4), YEAR_FORMAT) +
        "-" +
        format(addYears(offset, i - 3), YEAR_FORMAT);
      items.push(
        <Menu.Item key={fy_year_current}>{fy_year_current}</Menu.Item>
      );
    }
    for (let i = 0; i < 2; i++) {
      const fy_year =
        format(subYears(offset, i + 1), YEAR_FORMAT) +
        "-" +
        format(subYears(offset, i), YEAR_FORMAT);
      items.push(
        <Menu.Item key={fy_year} disabled={fy_year === yearw}>
          {fy_year}
        </Menu.Item>
      );
    }
    for (let i = 1; i < 2; i++) {
      const fy_year_current =
        format(addYears(offset, i - 1), YEAR_FORMAT) +
        "-" +
        format(addYears(offset, i), YEAR_FORMAT);
      items.push(
        <Menu.Item
          key={fy_year_current}
          disabled={
            payrollFlag
              ? y === 3 && x >= 25
                ? false
                : y > 3
                ? false
                : true
              : fy_year_current === yearw
          }
        >
          {fy_year_current}
        </Menu.Item>
      );
    }
    // }

    return items;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedDate, offset]);

  return (
    <Dropdown.Button
      overlay={<Menu onClick={onYearSelect}>{yearItems}</Menu>}
      trigger={["click"]}
    >
      <span className="drop-width">{"FY" + " " + yearw}</span>
    </Dropdown.Button>
  );
};

function resetPage(query: URLSearchParams) {
  if (query.has("page")) {
    query.set("page", "1");
  }
}

const YearMonthDropdown = ({
  marginLeft,
  month,
  year,
  onChange,
  disabledDate,
  payrollFlag,

  activeRole,
}: Props) => {
  const isDisabled = disabledDate || defaultDisabledDate;

  const history = useHistory();
  const location = useLocation();
  const today = new Date();
  const endOfThisMonth = endOfMonth(today);

  const query = new URLSearchParams(location.search);
  const selectedMonth: any =
    query.has("month") && query.get("month")
      ? months.indexOf(query.get("month") + "")
      : today.getDate() < 25
      ? subMonths(endOfThisMonth, 1).getMonth()
      : endOfThisMonth.getMonth();
  let y: any = "";
  // y => current  selected FY
  if (query.has("year") && query.get("year")) {
    y = (query.get("year") + "").split("-");
  }
  const selectedYear: any = year;
  const selectedDate =
    selectedMonth <= 11
      ? new Date(
          +y[0] ? +y[0] : 2024,
          selectedMonth !== -1 ? selectedMonth : month,
          1
        )
      : new Date(`2024,02,29`);

  const current = new Date();
  const CurrentYear = new Date().getFullYear();
  const eligbleDate =
    current.getTime() <= new Date(`${CurrentYear}-04-01`).getTime() &&
    current.getTime() > new Date(`${CurrentYear - 1}-04-01`).getTime();

  // eligible Data set a date according to financial yewar(which is apr 1 to mar 31)
  const currentFy = eligbleDate
    ? `${getYear(subYears(current, 1))}-${getYear(current)}`
    : `${getYear(current)}-${getYear(addYears(current, 1))}`;
  const beforeMarchFy = `${getYear(subYears(current, 1))}-${getYear(current)}`;
  const currentFySplit = currentFy.split("-");
  const lastFy = `${+currentFySplit[0] - 2}-${+currentFySplit[1] - 2}`;
  const last2Fy = `${+currentFySplit[0] - 4}-${+currentFySplit[1] - 4}`;
  const currntNextTwoFy = `${+currentFySplit[0] + 2}-${+currentFySplit[1] + 2}`;
  const currntNextOneFy = `${+currentFySplit[0] + 1}-${+currentFySplit[1] + 1}`;
  if (onChange && (month !== selectedMonth || year !== selectedYear)) {
    onChange(selectedMonth, selectedYear);
  }

  // this func used when user click on prev button
  function handlePrev() {
    const yearSplit = year.toString().split("-");
    const nextDate = subMonths(
      new Date(Number(yearSplit[0]), selectedMonth, 1),
      1
    );
    const monthNo = nextDate.getMonth();
    const monthName = months.find((el) => months.indexOf(el) === monthNo);

    const nextQuery = new URLSearchParams(location.search);
    if (monthName === "March" && last2Fy !== `${year}`) {
      nextQuery.set("month", monthName + "");
      nextQuery.set("year", `${+yearSplit[0] - 1}-${+yearSplit[1] - 1}` + "");
    } else if (month === 12 && lastFy !== `${year}`) {
      nextQuery.set("year", `${+yearSplit[0] - 1}-${+yearSplit[1] - 1}` + "");
      nextQuery.set("month", "All" + "");
    } else {
      nextQuery.set("month", monthName + "");
    }
    resetPage(nextQuery);
    history.push("?" + nextQuery);
  }

  // this func used when user click on next button
  function handleNext() {
    const c = year.toString().split("-");
    // c store two year of selected financial year based on selected month
    // ["2021","2022"]
    // 2/9/2022==> 1/10/2022==>1/11/22==>1/12/22==>1/1/2023
    const nextDate = addMonths(new Date(Number(c[0]), selectedMonth, 1), 1);
    const monthNo = nextDate.getMonth(); // 10
    const cc = months.find((el) => months.indexOf(el) === monthNo);
    // cc => selected next month when user click on next button
    const nextQuery = new URLSearchParams(location.search);
    // nextQuery = search param
    if (cc === "April" && currentFy !== `${year}`) {
      nextQuery.set("month", cc + "");
      nextQuery.set("year", `${+c[0] + 1}-${+c[1] + 1}` + "");
    } else if (month === 12 && currntNextOneFy !== `${year}`) {
      // if year is 12 then it will take "All" option
      nextQuery.set("year", `${+c[0] + 1}-${+c[1] + 1}` + "");
      nextQuery.set("month", "All" + "");
    } else {
      nextQuery.set("month", cc + "");
    }
    resetPage(nextQuery);
    history.push("?" + nextQuery);
  }

  function handleMonthSelect(info: MenuInfo) {
    const selMonth = info.key;
    const nextQuery = new URLSearchParams(location.search);
    nextQuery.set("month", selMonth + "");
    resetPage(nextQuery);
    history.push("?" + nextQuery);
  }

  function handleYearSelect(info: MenuInfo) {
    const nextQuery = new URLSearchParams(location.search);
    const selYear = info.key;
    nextQuery.set("year", selYear + "");
    resetPage(nextQuery);
    history.push("?" + nextQuery);
  }

  return (
    <Space
      size={4}
      style={{
        width: "100%",
        marginBottom: "var(--margin-xss)",
        alignItems: "center",
      }}
    >
      <Button
        type="primary"
        style={{ height: "30px", width: "30px" }}
        icon={<CaretLeftFilled color="white" />}
        onClick={handlePrev}
        disabled={
          (selectedYear === getYear(subYears(today, 2)) &&
            (selectedMonth === getMonth(today) ||
              (selectedMonth === getMonth(subMonths(today, 1)) &&
                today.getDate() < 25))) ||
          (month === 12 && lastFy === `${year}`)
        }
      />

      <YearDropDown
        offset={endOfThisMonth}
        selectedDate={selectedDate}
        onYearSelect={handleYearSelect}
        payrollFlag={payrollFlag}
        yearw={year}
      />

      <MonthDropDown
        offset={endOfThisMonth}
        selectedDate={selectedDate}
        onMonthSelect={handleMonthSelect}
        isDisabled={isDisabled}
        payrollFlag={payrollFlag}
        monthSelect={month}
        yearw={year}
        RoleNow={activeRole}
      />
      {payrollFlag === true ? (
        <Button
          type="primary"
          style={{ height: "30px", width: "30px" }}
          icon={<CaretRightFilled color="white" />}
          onClick={handleNext}
          disabled={
            (selectedYear === currentFy &&
              selectedMonth === getMonth(subMonths(today, 1)) &&
              today.getDate() < 25) ||
            (month === 12 && currentFy === `${year}`) ||
            (payrollFlag &&
              (today.getMonth() === month + 1
                ? today.getDate() <= 25
                  ? true
                  : false
                : false) &&
              (month > 3 ? currentFy : beforeMarchFy) === `${year}`) ||
            (payrollFlag &&
              today.getMonth() === month &&
              currentFy === `${year}`)
          }
        />
      ) : (
        <Button
          type="primary"
          style={{ height: "30px", width: "30px" }}
          icon={<CaretRightFilled color="white" />}
          onClick={handleNext}
          disabled={
            (selectedYear === currntNextTwoFy && selectedMonth === 11) ||
            (month === 12 && currntNextOneFy === `${year}`) ||
            (payrollFlag &&
              (today.getMonth() === month + 1
                ? today.getDate() <= 25
                  ? true
                  : false
                : false) &&
              currentFy === `${year}`) ||
            (payrollFlag &&
              today.getMonth() === month &&
              currentFy === `${year}`)
          }
        />
      )}
    </Space>
  );
};

export default YearMonthDropdown;
