import React, { Component, SyntheticEvent, useState } from "react";
import { Button, Table, Tag, notification, Row, Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/lib/table/interface";
import { RouteChildrenProps } from "react-router";
import {
  APIEmployee,
  getEmployeeList,
  getTableExportBlob,
} from "~/api/employee";
import { fetchUtil } from "~/api/common";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import OnboardEmployeeModal from "./modal/OnboardEmployeeModal";
import styles from "./EmployeePage.module.css";
import { formatDate } from "~/lib";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { LoginRole } from "~/api/auth";
import SummaryTags from "~/component/SummaryTags";
import { registerEvent } from "~/analytics";
import FilterComponent from "~/component/FilterComponent";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
import ExportButton from "~/fragment/table-export/ExportButton";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  employeeId: state.mainAppSlice.user.id,
  employeeName:
    state.mainAppSlice.user.first_name +
    " " +
    state.mainAppSlice.user.last_name,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouteChildrenProps, PropsFromRedux {
  activeStatus: boolean;
  history: any;
}

interface State {
  activePage: number;
  noOfRows: number;
  totalItemCount: number;
  employeeData: {
    rowData: Array<APIEmployee>;
    apiState: "idle" | "loading" | "error";
  };
  onboardModalVisible: boolean;
  designationList: Array<{
    id: number;
    category: string;
  }>;
  reportsTo: Array<{
    id: number;
    category: string;
  }>;
  rolesList: Array<{
    id: number;
    role_name: string;
  }>;
  employeeSummaryData: Partial<{
    employee_count: string;
    male_employee_count: string;
    interns: string;
    avg_salary: string;
    last_recruitment: string;
  }>;
  employeeSaveLoader: boolean;
  filters: Array<any>;
}

const Image = (props: any) => {
  const [url, setUrl] = useState(props.profilePicUrl);
  const [dimen, setDimen] = useState({ height: 0, width: 0 });
  const onImgLoad = (event: any) => {
    setDimen({
      height: event.target.offsetHeight,
      width: event.target.offsetWidth,
    });
  };
  return url ? (
    <div className={styles.profileImageContainer}>
      <img
        alt="pp"
        src={url}
        onLoad={onImgLoad}
        style={
          dimen.height > dimen.width
            ? {
                width: "100%",
              }
            : {
                height: "100%",
              }
        }
        // tslint:disable-next-line: jsx-no-lambda
        onError={() => {
          setUrl(null);
        }}
      />
    </div>
  ) : (
    <div className={styles.profileImageContainer}>
      {props.empName.substr(0, 1)}
    </div>
  );
};
class EmployeePage extends Component<Props, State> {
  state: State = {
    activePage: 1,
    noOfRows: 12,
    totalItemCount: 0,
    employeeData: {
      rowData: [],
      apiState: "loading",
    },
    onboardModalVisible: false,
    designationList: [],
    reportsTo: [],
    rolesList: [],
    employeeSummaryData: {},
    employeeSaveLoader: false,
    filters: [
      { title: "Active", checked: true },
      { title: "Deactivated", checked: false },
    ],
  };

  getColumnDef = () => {
    const columnDef: Array<ColumnType<APIEmployee>> = [
      {
        title: "",
        render: (_value, row) => (
          <Image empName={row.emp_name} profilePicUrl={row.profile_pic_url} />
        ),
      },
      {
        title: "Employee id",
        dataIndex: "company_emp_id",
      },
      {
        title: "Name",
        key: "emp_name",
        render: (_value, row) => <span>{row.emp_name}</span>,
      },
      {
        title: "Designation",
        dataIndex: "designation",
        width:"19%",
      },
      {
        title: "Reports to",
        dataIndex: "reports_to_name",
        width:"13%",

        render: (_value, row) => (
          <span>
            {row.reports_to_name?.length > 1 ? row.reports_to_name : "----"}
          </span>
        ),
      },
      {
        title: "Joining date",
        dataIndex: "joining_date",
        render: (date) => <span>{date ? formatDate(date) : null}</span>,
      },

      {
        title: "Engagement type",
        dataIndex: "engagement_type",
        render: (data) => <Tag>{data}</Tag>,
      },
    ];

    const filter = this.state.filters.find((e) => e.title === "Deactivated");

    if (filter.checked) {
      columnDef.splice(6, 0, {
        title: "Leaving date",
        dataIndex: "leaving_date",
        render: (date) => {
          return <span>{date ? formatDate(date) : null}</span>;
        },
      });
    }

    return columnDef;
  };

  static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State
  ): Partial<State> | null {
    const query = new URLSearchParams(nextProps.location.search);
    if (query.has("page")) {
      const pageNo = +query.get("page")!;
      if (Number.isFinite(pageNo) && prevState.activePage !== pageNo) {
        return {
          activePage: pageNo,
        };
      }
    }
    return null;
  }

  componentDidMount() {
    if (this.props.activeRole === LoginRole.EMPLOYEE) {
      this.props.history.push(
        `/app/employee/${this.props.employeeId}/${this.props.employeeName}`
      );
    }
    this.loadData();
    this.getDesignationList();
    this.getReportsTo();

    if (
      this.props.activeRole === LoginRole.ADMIN ||
      this.props.activeRole === LoginRole.SUPERuSER
    ) {
      this.getRoleList();
    }
    if (
      this.props.activeRole === LoginRole.ADMIN ||
      this.props.activeRole === LoginRole.CA ||
      this.props.activeRole === LoginRole.SUPERuSER
    ) {
      this.getEmployeeSummary();
    }
    if (this.props.history.location.state !== undefined) {
      if (this.props.history.location.state.activeStatus) {
        this.setState({
          filters: [
            { title: "Active", checked: true },
            { title: "Deactivated", checked: false },
          ],
        });
      } else {
        this.setState({
          filters: [
            { title: "Active", checked: false },
            { title: "Deactivated", checked: true },
          ],
        });
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevState.activePage !== this.state.activePage) {
      this.loadData();
    }
  }

  handleFilterChange = (updatedFilterData: any) => {
    this.setState(
      {
        filters: updatedFilterData,
      },

      () => {
        const query = new URLSearchParams(this.props.location.search);
        query.set("page", 1 + "");
        this.props.history.push("?" + query);
        this.loadData();
        this.getEmployeeSummary();
      }
    );
  };

  async getRoleList() {
    const { ok, json, message } = await fetchUtil("GET", `/get_roles`);
    if (ok) {
      const data = json;
      this.setState({
        rolesList: data,
      });
    } else {
      notification.error({ message });
    }
  }
  async getDesignationList() {
    const { ok, json, message } = await fetchUtil(
      "GET",
      `/get_designation_category`
    );
    if (ok) {
      const data = json;
      this.setState({
        designationList: data,
      });
    } else {
      notification.error({ message });
    }
  }

  async getReportsTo() {
    const { ok, json, message } = await fetchUtil("GET", `/get_reports_to`);
    if (ok) {
      const data = json;
      this.setState({
        reportsTo: data,
      });
    } else {
      notification.error({ message });
    }
  }

  async getEmployeeSummary() {
    const selectedFilter = this.state.filters.find((it: any) =>
      it.title === "Active" || it.checked ? true : false
    );
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_employee_summary`,
      {
        is_active: selectedFilter!.checked ? true : false,
      }
    );
    if (ok) {
      const data = json[0];
      this.setState({
        employeeSummaryData: data,
      });
    } else {
      notification.error({ message });
    }
  }
  loadData = async () => {
    try {
      const selectedFilter = this.state.filters.find((it: any) =>
        it.title === "Active" || it.checked ? true : false
      );
      const { employees, totalArrayLength } = await getEmployeeList(
        this.state.activePage,
        this.state.noOfRows,
        selectedFilter!.checked
      );
      this.setState({
        employeeData: {
          rowData: employees,
          apiState: "idle",
        },
        totalItemCount: totalArrayLength,
      });
    } catch (err) {
      console.warn(err);
      this.setState({
        employeeData: {
          ...this.state.employeeData,
          apiState: "error",
        },
      });
    }
  };

  handleFetchData = () => {
    return fetchUtil("POST", "/get_employee_list", {
      page_no: 1,
      no_of_rows: 1,
      is_active: this.getSelectedFilter(),
    });
  };

  handleDownload = async (acceptType: string, selectedFields: string) => {
    return getTableExportBlob(
      1,
      9999999,
      this.getSelectedFilter(),
      acceptType,
      selectedFields
    );
  };

  getSelectedFilter = () => {
    const activeFilter = this.state.filters.find((e) => e.checked === true);
    if (activeFilter.title === "Active") {
      return true;
    } else {
      return false;
    }
    // const selectedFilter = this.state.filters.find((it: any) =>
    //   it.title === "Active" || it.checked ? true : false
    // );
  };

  handleRowEvent = (record: APIEmployee, _rowIndex?: number) => {
    return {
      onClick: (_event: SyntheticEvent) => {
        const selectedFilter = this.state.filters.find((it: any) =>
          it.title === "Active" || it.checked ? true : false
        );
        this.props.history.push(
          `/app/employee/${record.emp_id}/${record.emp_name}`,
          {
            isActive: selectedFilter!.checked ? true : false,
          }
        );
      },
    };
  };

  handleAddNew = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.employeePage67
    );
    this.setState({
      onboardModalVisible: true,
    });
  };

  handleOnboardVisibleChange = (requestedVisibility: boolean) => {
    this.setState({
      onboardModalVisible: requestedVisibility,
    });
  };

  empSaveHandeler = (value: boolean) => {
    this.setState({ employeeSaveLoader: value });
    this.loadData();
    this.getEmployeeSummary();
  };

  handlePageChange = (pageNumber: number) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", pageNumber + "");
    this.props.history.push("?" + query);
  };

  handleMenuClick = (e: any) => {
    if (e.key === "1") {
      this.props.history.push("/app/employee/terms-and-conditions");
    }
    if (e.key === "2") {
      this.props.history.push("/app/employee/salary-structure-template");
    }
  };

  render() {
    const summaryData = this.state.employeeSummaryData;
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="1">Employement Terms</Menu.Item>
        <Menu.Item key="2">Salary Structure Template</Menu.Item>
      </Menu>
    );
    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>{titles.employeePage}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Employee"]}
          style={{ paddingLeft: "0" }}
          actions={
            this.props.activeRole === LoginRole.ADMIN ||
            this.props.activeRole === LoginRole.SUPERuSER ? (
              <>
                <Button
                  style={{
                    marginRight: "1em",
                  }}
                  type="primary"
                  onClick={this.handleAddNew}
                >
                  + Add new
                </Button>
                <Dropdown overlay={menu}>
                  <Button
                    type="primary"
                    style={{ background: "green", border: "green" }}
                  >
                    More <DownOutlined />
                  </Button>
                </Dropdown>
              </>
            ) : null
          }
        />

        <FilterComponent
          filterOptions={this.state.filters}
          onFilterChange={this.handleFilterChange}
        />
        <Row className={styles.summaryDiv}>
          {this.props.activeRole === LoginRole.ADMIN ||
          this.props.activeRole === LoginRole.CA ||
          this.props.activeRole === LoginRole.SUPERuSER ? (
            <SummaryTags
              nameValuePairs={[
                { name: "Count", value: summaryData.employee_count },
                { name: "Intern", value: summaryData.interns },
                { name: "Male", value: summaryData.male_employee_count },
                { name: "Average salary", value: summaryData.avg_salary },
                {
                  name: "Last recruitment on",
                  value: summaryData.last_recruitment,
                },
              ]}
            />
          ) : (
            ""
          )}
        </Row>
        <Table
          rowKey="emp_id"
          style={{ maxWidth: "93%", cursor: "pointer" }}
          columns={this.getColumnDef()}
          dataSource={this.state.employeeData.rowData}
          onRow={this.handleRowEvent}
          loading={this.state.employeeData.apiState === "loading"}
          size="large"
          pagination={{
            size: "small",
            current: this.state.activePage,
            pageSize: this.state.noOfRows,
            showSizeChanger: false,
            onChange: this.handlePageChange,
            total: this.state.totalItemCount,
            position: ["topRight"],
            style: { margin: "0px" },
          }}
        />
        {this.props.activeRole !== LoginRole.EMPLOYEE &&
          this.state.employeeData.rowData.length > 0 && (
            <ExportButton
              onDownload={this.handleDownload}
              onFetchData={this.handleFetchData}
            />
          )}
        <OnboardEmployeeModal
          visible={this.state.onboardModalVisible}
          onVisibleChange={this.handleOnboardVisibleChange}
          designationList={this.state.designationList}
          reportsTo={this.state.reportsTo}
          rolesList={this.state.rolesList}
          employeeSaveLoader={this.state.employeeSaveLoader}
          empSaveHandeler={this.empSaveHandeler}
          history={this.props.history}
        />
      </IMTContent>
    );
  }
}

export default connector(EmployeePage);
