import React, { ReactNode, useState } from "react";
import {
  Row,
  Form,
  Input,
  message as antMessage,
  Button,
  Popconfirm,
} from "antd";
import { Rule } from "antd/lib/form";
import cx from "classnames";

import { FilePdfOutlined } from "@ant-design/icons";

import { getSignedUploadURL, getSignedDownloadURL } from "~/api/common";

import commonStyle from "~/component/common.module.css";
import styles from "./AttachableField.module.css";
import Modal from "antd/lib/modal/Modal";
import { NamePath } from "rc-field-form/lib/interface";
import { FormInstance } from "antd/lib/form/Form";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

interface Props {
  className?: string;
  label: string;
  fieldName: string;
  hashFileName?: string;
  onHashFileChange: (newHashFile: string, documentsName: string) => void;
  hashFileFieldName?: NamePath;
  form?: FormInstance<any>;
  rules: Array<Rule>;
  readonly?: boolean;
  children?: ReactNode;
}

interface State {
  previewURL?: string;
}

function AttachableField(props: Props) {
  const [state, setState] = useState<State>({ previewURL: undefined });
  const handleAttach = (_e: React.MouseEvent) => {
    if (props.readonly) {
      return;
    }
    const $i = document.createElement("input") as HTMLInputElement;
    $i.type = "file";
    $i.onchange = async (e) => {
      const file = $i.files?.length ? $i.files[0] : null;
      if (file) {
        const { ok, message, data } = await getSignedUploadURL(file.name);
        if (!ok) {
          antMessage.warning({ message });
        } else {
          const { hashedFileName, url } = data!;
          // TODO: show progress
          const res = await fetch(url, {
            method: "PUT",
            headers: {
              "content-type": file.type,
            },
            body: file,
          });
          if (!res.ok) {
            antMessage.error({ message: "Failed to upload file" });
          } else {
            props.onHashFileChange(hashedFileName, file.name);
            if (props.hashFileFieldName && props.form) {
              props.form.setFields([
                {
                  name: props.hashFileFieldName,
                  errors: [],
                  touched: true,
                  validating: true,
                  value: hashedFileName,
                },
              ]);
              props.form.validateFields().catch(() => {
                // do nothing, just trigger the validation
              });
            }
          }
        }
      }
    };
    $i.click();
  };

  const handleDelete = async (_e?: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      props.fieldName === "aadhar_number"
        ? ga.events.AttachFieldAadharDelete
        : props.fieldName === "pan_number"
        ? ga.events.AttachFieldPanDelete
        : props.fieldName === "physical_disability"
        ? ga.events.AttachFieldPhysicalDisabilityDelete
        : props.fieldName === "document_type_id"
        ? ga.events.AttachFieldFileDelete
        : ""
    );
    props.onHashFileChange("", "");
  };

  const handlePreview = async (_e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      props.fieldName === "aadhar_number"
        ? ga.events.AttachFieldAadharPreview
        : props.fieldName === "pan_number"
        ? ga.events.AttachFieldPanPreview
        : props.fieldName === "physical_disability"
        ? ga.events.AttachFieldPhysicalDisabilityPreview
        : props.fieldName === "document_type_id"
        ? ga.events.AttachFieldFilePreview
        : ""
    );
    const { ok, message, url } = await getSignedDownloadURL(
      props.hashFileName!
    );
    if (!ok) {
      antMessage.error({ message });
    } else {
      setState({
        previewURL: url,
      });
    }
  };

  const handlePreviewCancel = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      props.fieldName === "aadhar_number"
        ? ga.events.AttachFieldAadharPreviewCancel
        : props.fieldName === "pan_number"
        ? ga.events.AttachFieldPanPreviewCancel
        : props.fieldName === "physical_disability"
        ? ga.events.AttachFieldPhysicalDisabilityPreviewCancel
        : props.fieldName === "document_type_id"
        ? ga.events.AttachFieldFilePreviewCancel
        : ""
    );
    setState({
      previewURL: undefined,
    });
  };

  const renderAttachFile = () => {
    return props.hashFileName ? null : (
      <b className={styles.attachLabel} onClick={handleAttach}>
        + Attach file
      </b>
    );
  };

  return (
    <Row align="bottom" className={props.className}>
      <Form.Item
        className={cx(styles.attatchableField, props.className)}
        name={props.fieldName}
        label={
          <Row justify="space-between">
            <span>{props.label}</span>
            {!props.readonly ? renderAttachFile() : null}
          </Row>
        }
        labelCol={{
          span: 24,
        }}
        rules={props.rules}
      >
        {props.children ? props.children : <Input readOnly={props.readonly} />}
      </Form.Item>
      <FilePdfOutlined
        className={props.hashFileName ? styles.fileIconBox : commonStyle.hidden}
        role="button"
        onClick={handlePreview}
      />
      {props.hashFileName && !props.readonly ? (
        <Popconfirm
          title="Are you sure delete this attachment?"
          onConfirm={handleDelete}
        >
          <b className={styles.deleteBtn} role="button">
            Delete
          </b>
        </Popconfirm>
      ) : null}
      {!!state.previewURL ? (
        <Modal
          title="Preview"
          footer={<Button onClick={handlePreviewCancel}>Close</Button>}
          className={styles.previewModal}
          visible={true}
          onCancel={handlePreviewCancel}
          centered
          destroyOnClose
        >
          <iframe
            title="Attachment preview"
            className={styles.previewFrame}
            src={state.previewURL}
          />
        </Modal>
      ) : null}
    </Row>
  );
}

export default AttachableField;
