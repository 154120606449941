import { RcFile } from "antd/lib/upload";
import React, { useCallback, useEffect, useState } from "react";
import { RouteChildrenProps } from "react-router";
import { isEqual as isEqualDates } from "date-fns";
import { getCurrency, getPaymentDetais } from "~/api/expense";
import commonStyle from "~/component/common.module.css";
import DatePicker from "~/component/antd-overrides/DatePicker";
import { AuthState } from "~/app/MainApp/store";
import { addDays, subDays } from "date-fns";
import { connect, ConnectedProps } from "react-redux";
import PayInvoiceModal from "../modal/PayInvoiceModal";
import CreditNoteLineItem, {
  validateColumns,
} from "~/feature/invoice/creditnote/creditnote-line-item/CreditNoteLineItem";
import {
  CreditNote,
  getCrediNoteByID,
  newCreditNoteDetails,
  PaymentStatus,
  ProductItem,
  saveCreditNoteDetails,
} from "~/api/invoice";
import { ExclamationCircleOutlined, UnlockFilled } from "@ant-design/icons";
import SendMailConfrmModal from "~/component/SendMailConfrmModal";
import AmountsAreSelect from "~/fragment/form-input/AmountsAreSelect";
import ApplyCess from "~/fragment/form-input/ApplyCess";
import { OTHER_TERITORY_ID } from "~/lib/constants";
import CreditNotePageHeader from "./CreditNotePageHeader";
import { TaxTeritoryType, TAX_INCLUSION } from "~/lib/taxes";
import AddProductModalDialog from "~/feature/invoice/modal/AddProductModal";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  notification,
  Modal,
} from "antd";
import { formatDate, FORMAT_DATE, roundNumber } from "~/lib";
import FieldInfo from "~/component/FieldInfo";
import { fetchUtil } from "~/api/common";
import {
  Invoice,
  getInvoiceByID,
  saveInvoiceDetails,
  downloadPDFFile,
  handleEmail,
  newInvoiceDetails,
} from "~/api/invoice";
import VendorSelect from "~/fragment/form-input/VendorSelect";
import IMTContent from "~/layout/main-layout/IMTContent";
import { Required } from "~/lib/form_rules";
import PlaceOfSupply from "~/fragment/form-input/PlaceOfSupply";

import useCqdForm from "~/lib/hook/useCqdForm";

import { getTotalGST } from "~/lib/taxCalculation";
import { PaymentDetails } from "~/feature/expense/ExpenseModal";
import { _FORCE_PRODUCT_LIST_REFRESH } from "~/component/field-input/ProductSelect";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";
import AttachableField from "~/fragment/attachable-field/AttachableField";
import { useInvoiceDocumentTypeList } from "~/lib/hook/api-hook/picklistHooks";
import ReadOnlyableSelect from "~/component/field-input/ReadOnlyableSelect";
import { registerEvent } from "~/analytics";

import styles from "~/feature/invoice/invoice-details/InvoiceDetailsPage.module.css";
import {
  CreditNoteLineItemRecord,
  DeletedCreditNoteLineItemRecord,
} from "./creditnote-line-item/CreditNoteLineItemTypes";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";

import * as ga from "~/contants/gaConstants";

const { TextArea } = Input;

type PathParams = {
  id?: string;
  crId?: string;
  slug?: "new" | "copy";
};

type LocationState = {
  data?: Invoice;
  fileList?: Array<RcFile>;
  search?: any;
};

const DEFAULT_TAX_INCLUSION: TAX_INCLUSION = "ExclusiveOfTax";

const mapStateToProps = (state: AuthState) => ({
  place_of_supply_id: state.mainAppSlice.user.place_of_supply,
  activeRole: state.mainAppSlice.user.activeRole,
  baseCompanyGstin: state.mainAppSlice.user.gstin,
});

const connector = connect(mapStateToProps);

const getCurrencyConversionList = async (
  symbol_name: string | undefined,
  invoice_date: Date
) => {
  const { ok, message, data } = await getCurrency(symbol_name!, invoice_date);
  if (ok) {
    return data!;
  } else {
    notification.error({ message });
  }
};

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props
  extends RouteChildrenProps<PathParams, LocationState>,
    PropsFromRedux {}

const CreditNotePage = (props: Props) => {
  const [deletedRows, setDeletedRows] = useState<
    Array<DeletedCreditNoteLineItemRecord>
  >([]);

  const OPTS_TERMS = [
    { value: 0, label: "Due on receipt" },
    { value: 10, label: "Net 10" },
    { value: 15, label: "Net 15" },
    { value: 30, label: "Net 30" },
    { value: 60, label: "Net 60" },
  ];
  const invoiceId = props.match?.params.id ? +props.match.params.id : null;
  const crId = props.match?.params.crId ? props.match?.params.crId : null;

  const [normalInfo, setInfo] = useState({
    isNew: true,
    editMode: true,
    apiState: "idle",
    rippleEffect: false,
    payDlgVisible: false,
    invoiceComplianceFlag: false,
    sendMailModal: false,
    paymentDetail: {} as Partial<PaymentDetails>,
    openProductModal: false,
  });

  const [invoiceData, setInvoiceData] = useState<Invoice>();

  const { isNew } = normalInfo;

  const { invoiceDocumentTypeList } = useInvoiceDocumentTypeList();

  const { data, update, formProps, resetDirtyFlag, prevData } = useCqdForm<
    CreditNote
  >();
  const loadInvoiceData = useCallback(
    async (_invoiceId: number) => {
      resetDirtyFlag();
      setInfo((oldInfo) => ({
        ...oldInfo,
        apiState: "loading",
      }));
      try {
        const { ok, data: invoiceData, message } = await getInvoiceByID(
          _invoiceId
        );

        if (!ok) {
          notification.error({
            message: "Failed to load details",
            description: message,
          });
        } else {
          setInvoiceData({
            ...(invoiceData as Invoice),
            invoice_number: invoiceData?.invoice_number || "",
            paid_flag: invoiceData ? invoiceData?.paid_flag : 0,
            tax_inclusion: invoiceData?.tax_inclusion!,
            extra: {
              ...invoiceData?.extra,
              apiData: {
                state_code: invoiceData!.state_code!,
              },
              vendor: {
                __init__: {
                  customer_idx: invoiceData?.customer_idx,
                  uid: Math.random(),
                },
              } as any,
              hasCess: !!Number(invoiceData?.cess ?? 0),
            },
          });
        }
      } catch (error) {
        console.warn(error);
        notification.warn({ message: "Unable to load invoice!" });
      } finally {
        setInfo((oldInfo) => ({
          ...oldInfo,
          apiState: "idle",
        }));
      }
    },
    // we need to add more dependecies ,but for now eslint disable because some functionality may be broken
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update, props.match?.params.slug]
  );
  const loadCreditNote = useCallback(async (_creditNoteId: number) => {
    resetDirtyFlag();
    setInfo((oldInfo) => ({
      ...oldInfo,
      apiState: "loading",
    }));
    try {
      const { ok, data: creditNotedata, message } = await getCrediNoteByID(
        _creditNoteId
      );

      if (!ok) {
        notification.error({
          message: "Failed to load credit note details",
          description: message,
        });
      } else {
        if (creditNotedata) {
          setInfo((normalInfo) => ({
            ...normalInfo,
            isNew: false,
            editMode: false,
          }));
          const formattedData = {
            ...creditNotedata,
            credit_note_row_data: creditNotedata.credit_note_items,
          };
          // Deleting the formatted key
          delete (formattedData as any).credit_note_items;
          update({
            ...formattedData,
          });
          if (!invoiceId) {
            setInvoiceData({
              ...(creditNotedata as any),
              invoice_number: creditNotedata?.invoice_number || "",
              paid_flag: creditNotedata ? creditNotedata?.paid_flag : 0,
              tax_inclusion: creditNotedata?.tax_inclusion!,
              state_code: creditNotedata?.place_of_supply,
              extra: {
                ...creditNotedata?.extra,
                apiData: {
                  state_code: creditNotedata!.place_of_supply,
                },
                vendor: {
                  __init__: {
                    customer_idx: creditNotedata?.customer_idx,
                    uid: Math.random(),
                  },
                } as any,
                hasCess: !!Number(creditNotedata?.cess ?? 0),
              },
            });
          }
        } else {
          setInfo((normalInfo) => ({
            ...normalInfo,
            isNew: true,
            editMode: true,
          }));
        }
      }
    } catch (error) {
      console.warn(error);
      notification.warn({ message: "Unable to load credit note!" });
    } finally {
      if (!invoiceId) {
        setInfo((oldInfo) => ({
          ...oldInfo,
          apiState: "idle",
        }));
      }
    }
  }, []);

  const handlePanDocChange = (newFileName: string, documentName: string) => {
    update({
      documents: newFileName,
      document_name: documentName,
    });
  };

  const handleAddProduct = usePersistantCallbackRef((e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.creditNotePage128
    );
    setInfo((oldInfo) => ({
      ...oldInfo,
      openProductModal: true,
    }));
  });

  const closeProductModal = () => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      openProductModal: false,
    }));
  };

  useEffect(() => {
    if (data.extra?.vendor?.customer_idx) {
      getCurrencyConversionList(
        data.extra.vendor.symbol_name,
        data.invoice_date
      ).then((res) => {
        update((oldData) => {
          return {
            extra: {
              ...oldData.extra,
              convertionRate: res[0].export_rate,
            },
          };
        });
      });
    }
  }, [data.invoice_date, update]);

  useEffect(() => {
    if (Number.isFinite(data.term)) {
      const term = data.term || 0;
      const dueDate = data.due_date || new Date();
      const invoiceDate = data.invoice_date || new Date();

      const prevTerm = prevData.term;
      const prevInvoiceDate = prevData.invoice_date;
      const prevDueDate = prevData.due_date;

      const dataUpdate = {} as Partial<Invoice>;
      let touched = false;

      if (term !== prevTerm) {
        const newDueDate = addDays(invoiceDate, term);
        dataUpdate.due_date = newDueDate;
        if (!data.invoice_date) {
          dataUpdate.invoice_date = invoiceDate;
        }
        touched = true;
      } else if (!isEqualDates(prevInvoiceDate, invoiceDate)) {
        const newDueDate = addDays(invoiceDate, term);
        dataUpdate.due_date = newDueDate;
        touched = true;
      } else if (
        prevDueDate &&
        data.term &&
        !isEqualDates(prevDueDate, dueDate)
      ) {
        const newInvoiceDate = subDays(dueDate, term);
        dataUpdate.invoice_date = newInvoiceDate;
        touched = true;
      }
      if (touched) {
        update(dataUpdate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, data.due_date, data.invoice_date, data.term]);

  useEffect(() => {
    if (
      data.state_code === OTHER_TERITORY_ID ||
      (data.extra?.vendor?.state_code === OTHER_TERITORY_ID &&
        !(prevData.extra?.vendor as any)?.__init__)
    ) {
      update((oldData) => {
        return {
          tax_inclusion: "OutOfTax",
          extra: {
            ...oldData.extra,
            hasCess: false,
          },
        };
      });
    } else if (
      prevData.tax_inclusion === "OutOfTax" &&
      data.state_code !== OTHER_TERITORY_ID &&
      data.extra?.vendor?.state_code === OTHER_TERITORY_ID &&
      data.extra?.vendor?.gstin &&
      data.extra?.vendor?.customer_id !== data.customer_id &&
      !isNew
    ) {
      update((oldData) => {
        return {
          tax_inclusion: DEFAULT_TAX_INCLUSION,
          extra: {
            ...oldData.extra,
            hasCess: false,
          },
        };
      });
    }

    if (!isNew && props.match?.params.slug === "copy") {
      update((oldData) => {
        const taxInclusion =
          data.state_code === OTHER_TERITORY_ID ||
          data.extra?.vendor?.state_code === OTHER_TERITORY_ID ||
          (prevData.extra?.vendor?.customer_id
            ? prevData.extra?.vendor?.customer_id === data.customer_id
            : false)
            ? "OutOfTax"
            : DEFAULT_TAX_INCLUSION;
        return {
          tax_inclusion: taxInclusion,
        };
      });
    } else {
      update((oldData) => {
        const taxInclusion =
          !oldData.tax_inclusion ||
          (data.extra?.vendor?.customer_id !== data.customer_id &&
            prevData.extra?.vendor?.customer_id !== undefined &&
            data.extra?.vendor?.state_code !== OTHER_TERITORY_ID)
            ? DEFAULT_TAX_INCLUSION
            : oldData.tax_inclusion;
        return {
          tax_inclusion: taxInclusion,
        };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, data.state_code]);

  useEffect(() => {
    if (data.tax_inclusion === "OutOfTax") {
      update((oldData) => ({
        extra: {
          ...oldData.extra,
          hasCess: false,
        },
      }));
    }
    // We are actually not dependent on data.extra , so disabling eslint deps check, unless we introduces callback style update function
    // eslint - disable - next - line react - hooks exhaustive - deps
  }, [update, data.tax_inclusion]);

  useEffect(() => {
    // Manually parsing the query params as react-router v4 dosen't support auto parse
    const fromId = new URLSearchParams(props.location.search).get("from") || "";
    setInfo((prevInfo) => ({
      ...prevInfo,
      isNew: crId ? false : true,
    }));
    if (invoiceId) {
      if (crId) {
        loadCreditNote(+crId).then(() => {
          loadInvoiceData(invoiceId);
        });
      } else {
        loadInvoiceData(invoiceId);
      }
    } else {
      if (crId) {
        loadCreditNote(+crId);
      }
    }
  }, [invoiceId, loadInvoiceData, props.location.search, props.match]);

  useEffect(() => {
    // If new creditnote then using invoice details as credit note and changing the key names
    if (normalInfo.isNew) {
      const initialCrediNoteData = {
        ...(invoiceData as Invoice),
        credit_note_row_data: invoiceData?.row_data.map((rowItem) => {
          const newItem = {
            ...rowItem,
            invoice_item_id: rowItem.row_id,
          };
          delete (newItem as any).row_id;
          delete newItem.product_unit_id;
          return newItem;
        }),
        credit_note_number: "",
        credit_note_date: "",
        credit_note_user_comment: "",
      };
      // if(invoiceData?.row_data) {
      delete (initialCrediNoteData as any).row_data;
      // }

      update({
        ...initialCrediNoteData,
      });
    } else {
      // If viewing / editing creditnote
      update({
        invoice_number: invoiceData?.invoice_number,
        paid_flag: invoiceData?.paid_flag,
        term: invoiceData?.term,
        invoice_date: invoiceData?.invoice_date,
        due_date: invoiceData?.due_date,
        state_code: invoiceData?.state_code,
        extra: {
          ...invoiceData?.extra,
          apiData: {
            state_code: invoiceData!.state_code!
              ? invoiceData!.state_code!
              : Number(invoiceData!.place_of_supply!),
          },
          vendor: {
            __init__: {
              customer_idx: invoiceData?.customer_idx,
              uid: Math.random(),
            },
          } as any,
          hasCess: !!Number(invoiceData?.cess ?? 0),
        },
      });
    }
  }, [invoiceData]);

  const handlePayDlg = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.creditNotePage129
    );
    const { paid_flag } = data;
    if (paid_flag === 1) {
      return notification.success({ message: "Already paid" });
    } else {
      setInfo((oldValue) => ({
        ...oldValue,
        payDlgVisible: true,
      }));
    }
  };

  const handlePayDlgFromAsk = (target: HTMLButtonElement) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.creditNotePage130
    );
    const { paid_flag } = data;
    if (paid_flag === 1) {
      return notification.success({ message: "Already paid" });
    } else {
      setInfo((oldValue) => ({
        ...oldValue,
        payDlgVisible: true,
      }));
    }
  };
  const taxTeritoryType =
    data.state_code === OTHER_TERITORY_ID
      ? TaxTeritoryType.OTHER_TERITORY
      : data.state_code === props.place_of_supply_id
      ? TaxTeritoryType.SAME_STATE
      : TaxTeritoryType.INTRA_STATE;

  const onSave = usePersistantCallbackRef(async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.creditNotePage131
    );
    try {
      await formProps.form.validateFields();
      const newRowData = [...data.credit_note_row_data, ...deletedRows];
      newRowData.forEach(validateColumns);
      const hasRowDataError = newRowData.some(
        (it: any) => it.extra?.errors?.length > 0
      );
      handleRowDataChange(newRowData);
      if (hasRowDataError) {
        notification.warning({
          message: "Please fill all required fields for the line items !",
        });
        return;
      }
      if (!data.invoice_id) {
        data.credit_note_row_data.map(
          (rowData) => (rowData.invoice_item_id = null)
        );
      }
      const apiData = {
        ...data,
        credit_note_row_data: [...data.credit_note_row_data, ...deletedRows],
      };

      apiData.customer_idx = data.extra?.vendor?.customer_idx;
      apiData.customer_id = data.extra?.vendor?.customer_id;
      apiData.customer_currency = data.extra?.vendor?.symbol;
      apiData.symbol_name = data.extra?.vendor?.symbol_name;
      apiData.customer_gstin_id = data.extra?.vendor?.customer_gstin_id!;
      apiData.customer_gstin = data.extra?.vendor?.gstin;
      apiData.conversion_rate = data.extra?.convertionRate
        ? String(data.extra?.convertionRate)
        : data.conversion_rate;
      apiData.place_of_supply = data.extra?.vendor?.place_of_supply;
      apiData.customer_name = data.extra?.vendor?.company_name;
      apiData.customer_email = data.extra?.vendor?.email_id;
      apiData.invoice_billing_address = data.extra?.vendor?.billing_address!;
      apiData.customer_gstin = data.extra?.vendor?.gstin;
      apiData.place_of_supply_id = data.state_code;
      apiData.invoice_id = data.invoice_id ? data.invoice_id : null;
      apiData.tax_inclusion = data.tax_inclusion;
      apiData.tcs_cgst_rate = data.tcs_cgst_rate ? data.tcs_cgst_rate : null;
      apiData.tcs_cgst_amt = data.tcs_cgst_amt ? data.tcs_cgst_amt : null;
      apiData.tcs_sgst_rate = data.tcs_sgst_rate ? data.tcs_sgst_rate : null;
      apiData.tcs_sgst_amt = data.tcs_sgst_amt ? data.tcs_sgst_amt : null;
      apiData.tcs_igst_rate = data.tcs_igst_rate ? data.tcs_igst_rate : null;
      apiData.tcs_igst_amt = data.tcs_igst_amt ? data.tcs_igst_amt : null;

      const taxes = getTotalGST(
        apiData.credit_note_row_data.map((_it) => {
          const it = (_it as any) as CreditNoteLineItemRecord;
          return {
            amount: it.amount,
            cessPercentage: it.extra?.cessRate?.cessPercentage ?? 0,
            gstPercentage: it.extra?.gstRate?.rate ?? 0,
          };
        }),
        {
          taxInclusion: data.tax_inclusion ? data.tax_inclusion : "OutOfTax",
          taxTeritoryType,
        }
      );

      apiData.amount = taxes.subTotal;
      apiData.net_total = taxes.total;
      apiData.cgst = taxes.cgst;
      apiData.igst = taxes.igst;
      apiData.sgst = taxes.sgst;
      apiData.cess = taxes.cess;
      delete apiData.extra;

      if (!isNew) {
        // if (apiData.customer_idx) {
        const { ok, message } = await saveCreditNoteDetails(apiData as any);
        if (!ok) {
          if (message === "Error: Unable to update invoice compliances") {
            props.history.goBack();
            setInfo((oldInfo) => ({
              ...oldInfo,
              editMode: false,
            }));
            notification.info({ message: "Can't Edit this credit note!" });
          } else {
            notification.error({ message });
          }
        } else {
          notification.success({ message });
          setInfo((oldInfo) => ({
            ...oldInfo,
            editMode: false,
          }));
          resetDirtyFlag();
          props.history.goBack();
        }
        // } else {
        //   notification.error({
        //     message: "Vendor not found. Please assign a vendor to the invoice.",
        //   });
        // }
      } else {
        const { ok, data, message } = await newCreditNoteDetails(
          apiData as any
        );
        if (!ok) {
          if (message === "Error: Unable to update invoice compliances") {
            props.history.goBack();
            setInfo((oldInfo) => ({
              ...oldInfo,
              editMode: false,
            }));
            notification.info({ message: "Can't save new credit note" });
          } else {
            notification.error({ message });
          }
        } else {
          setInfo((oldInfo) => ({
            ...oldInfo,
            editMode: false,
          }));
          notification.success({
            message: "Successfully saved new credit note",
            duration: 1,
          });
          resetDirtyFlag();
          resetDirtyFlag();
          props.history.goBack();
        }
      }
    } catch (error) {
      console.warn("error");
    }
  });

  const closeNotification = (newInvoiceId: any) => {
    props.history.replace(`/app/invoice/${newInvoiceId}`);
  };

  const askForPayment = (newInvoiceId: number) => {
    const key = `open${Date.now()}`;
    const close = closeNotification(newInvoiceId) as any;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={(e: React.MouseEvent) => {
          registerEvent(
            ga.EVENT_CATEGORY_BUTTON_CLICK,
            ga.EVENT_CLICK,
            ga.events.creditNotePage132
          );
          notification.close(key);
          const invDataLoadPromise = loadInvoiceData(newInvoiceId);
          const target = e.target;
          invDataLoadPromise.then(() => {
            handlePayDlgFromAsk(target as HTMLButtonElement);
          });
        }}
      >
        Confirm
      </Button>
    );
    notification.open({
      message: "Already received the payment ?",
      description: "",
      btn,
      key,
      onClose: close,
    });
  };

  const onEdit = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.creditNotePage133
    );
    setInfo((oldInfo) => ({
      ...oldInfo,
      editMode: true,
    }));
  };

  const cancelNotification = (key: any) => {
    notification.close(key);
  };

  const goToEditPage = (key: any) => {
    notification.close(key);
    props.history.push(`/app/masters/vendor/${data.customer_id}`);
  };
  const showEmailNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "10px" }}
            onClick={(e: React.MouseEvent) => {
              registerEvent(
                ga.EVENT_CATEGORY_BUTTON_CLICK,
                ga.EVENT_CLICK,
                ga.events.creditNotePage134
              );
              cancelNotification(key);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={(e: React.MouseEvent) => {
              registerEvent(
                ga.EVENT_CATEGORY_BUTTON_CLICK,
                ga.EVENT_CLICK,
                ga.events.creditNotePage133
              );
              goToEditPage(key);
            }}
          >
            Edit vendor/customer
          </Button>
        </div>
      </>
    );
    notification.info({
      message: "No email address found attached to this vendor/customer",
      duration: 8,
      btn,
      key,
    });
  };

  const handleSendInvoiceModal = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.creditNotePage135
    );
    const { customer_email } = data;
    if (customer_email === null || customer_email?.length === 0) {
      showEmailNotification();
    } else {
      setInfo((oldInfo) => ({
        ...oldInfo,
        sendMailModal: true,
      }));
    }
  };

  const handleSendMailModalCancel = () => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      sendMailModal: false,
    }));
  };

  const downloadinvoice = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.creditNotePage136
    );
    downloadPDFFile(data.invoice_id as any)
      .then((res: any) => {
        const blob = new Blob([res], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const printWin: any = window.open(url);
        printWin.print();
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const handleCancel = (isNew: boolean) => () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.creditNotePage134
    );
    if (isNew) {
      props.history.goBack();
    } else {
      window.location.reload();
    }
  };

  const handleDelete = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.creditNotePage117
    );
    const invoice = data;
    const title =
      invoice.compliance_status === "Complete" &&
      (invoice.status === PaymentStatus.DONE || invoice.paid_flag === 1) ? (
        <>
          <div>Do you really wish to delete this invoice?</div>
          <div>
            Note: Payment for this invoice is already logged and compliances are
            completed. Delete at your own risk
          </div>{" "}
        </>
      ) : invoice.status === PaymentStatus.DONE || invoice.paid_flag === 1 ? (
        <>
          <div>Do you really wish to delete this invoice?</div>
          <div>
            Note: Payment for this invoice is already logged and compliances are
            completed. Delete at your own risk
          </div>{" "}
        </>
      ) : (
        `Do you wish to delete this credit note?`
      );
    Modal.confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        const { ok, message } = await fetchUtil("POST", "/delete_credit_note", {
          credit_note_id: data.credit_note_id,
        });
        if (!ok) {
          notification.warn({ message });
        } else {
          notification.success({ message });
          resetDirtyFlag();
          // window.location.pathname=`app/invoice/creditNote` ;
          props.history.push(
            `/app/invoice/creditNote/${props.location.state?.search ?? ""}`
          );
        }
      },
    });
  };

  const handlePayDlgVisibleChange = (newVState: boolean) => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      payDlgVisible: false,
    }));
  };

  const handleNewProductSubmit = async (val: ProductItem) => {
    const { ok, message } = await fetchUtil("POST", "/add_product", {
      product_description: val.description,
      product_name: val.product_name,
      hsn_sac: Number(val.hsn_sac),
      rate: Number(val.rate),
    });
    if (!ok) {
      notification.warn({ message });
    } else {
      // Temporary solution
      //#region Hackity-hack #202109251137
      _FORCE_PRODUCT_LIST_REFRESH();
      //#endregion Hackity-hack

      notification.success({ message });
      closeProductModal();
    }
  };

  const handleAfterPayment = () => {
    props.history.replace(`/app/invoice/${data.invoice_id}`);
  };

  const sendEmail = async () => {
    try {
      const { ok, message } = await handleEmail(data.invoice_id as any);
      if (ok) {
        handleSendMailModalCancel();

        notification.success({
          message,
        });
      } else {
        showEmailNotification();
      }
    } catch (err) {
      notification.error({
        message: "Error",
      });
    }
  };

  const filterTerms = (input: any, option: any) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  useEffect(() => {
    const doWork = async () => {
      if (data.customer_id === data.extra?.vendor?.customer_id) {
        // CASE: vendor from expense_details API & dropdown's are equal
        // then
        // SUB-CASE 1: if vendor is modified take modified vendor's place_of_supply
        // SUB-CASE 2: else take place_of_supply from expense_details API
        update({
          place_of_supply:
            prevData.extra?.vendor?.customer_idx &&
            prevData.extra?.vendor?.customer_idx !==
              data.extra?.vendor?.customer_idx
              ? data.extra?.vendor?.state_code
              : data.extra?.apiData?.state_code,
        });
      } else {
        update({
          state_code: data.extra?.vendor?.state_code,
        });
      }

      const convertionList = await getCurrencyConversionList(
        data.extra?.vendor?.symbol_name,
        data.invoice_date
      );
      if (data.extra?.vendor?.symbol_name) {
        const selectedConvertionRate = convertionList[0].export_rate;
        update((oldData) => ({
          extra: {
            ...oldData.extra,
            convertionRate: selectedConvertionRate,
          },
        }));
      }
    };

    if (
      prevData.extra?.vendor !== data.extra?.vendor &&
      data.extra?.vendor?.state_code
    ) {
      doWork();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, data.extra?.vendor?.customer_idx]);

  const handleRowDataChange = (
    newRowData: Array<CreditNoteLineItemRecord>,
    deletedRowData?: CreditNoteLineItemRecord
  ) => {
    update({
      credit_note_row_data: newRowData,
    });

    if (deletedRowData) {
      setDeletedRows((oldRows) => [
        ...oldRows,
        {
          ...deletedRowData,
          is_active: false,
        },
      ]);
    }
  };

  const disabledDate = (current: Date) => {
    const year = new Date().getFullYear();
    return (
      current.getTime() < new Date(`${year - 1}-04-01`).getTime() ||
      current.getTime() > new Date(`${year + 1}-04-01`).getTime()
    );
  };

  const checkGreaterThanInvoice = (
    id: number,
    value: number,
    propertyPath: string
  ) => {
    let isGreater = false;
    invoiceData?.row_data.map((item) => {
      if (propertyPath === "quantity") {
        if (item.row_id === id && item.quantity! < value) {
          isGreater = true;
        }
      }
      if (propertyPath === "rate") {
        if (item.row_id === id && item.rate! < value) {
          isGreater = true;
        }
      }
    });
    return isGreater;
  };

  const renderInvoiceHeader = () => {
    const { editMode } = normalInfo;
    const dateView = editMode ? null : (
      <Form.Item
        className={commonStyle["w-100"]}
        label="Credit note date"
        required
      >
        <input
          className="ant-input"
          defaultValue={
            data.credit_note_date
              ? formatDate(new Date(data.credit_note_date))
              : ""
          }
          readOnly
        />
      </Form.Item>
    );
    return (
      <Row style={{ width: "70%" }} gutter={{ md: 30, lg: 32, xl: 100 }}>
        <Helmet>
          <title>{titles.CreditNotePage}</title>
        </Helmet>
        <Col md={16} lg={14} xl={12} xxl={10}>
          <Row gutter={{ md: 8, lg: 16 }}>
            <Col sm={24} lg={12}>
              <Form.Item
                className={commonStyle["w-100"]}
                name="invoice_number"
                label="Invoice number"
                rules={[
                  { required: false, message: "Please enter Invoice number" },
                ]}
              >
                <Input
                  autoFocus
                  placeholder={"Unique invoice No"}
                  readOnly={true}
                />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              <Form.Item
                className={commonStyle["w-100"]}
                name="term"
                label="Terms"
                rules={
                  invoiceId
                    ? Required("Terms")
                    : [
                        {
                          required: false,
                          // message: "Please enter delivery challan number",
                        },
                      ]
                }
              >
                <Select
                  disabled={true}
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterTerms}
                >
                  {OPTS_TERMS.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ md: 8, lg: 16 }}>
            <Col sm={24} lg={12}>
              <Form.Item className={commonStyle["w-100"]} label="Invoice date">
                <input
                  className="ant-input"
                  defaultValue={
                    data.invoice_date ? formatDate(data.invoice_date) : ""
                  }
                  readOnly
                />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              <Form.Item className={commonStyle["w-100"]} label="Due date">
                <input
                  className="ant-input"
                  defaultValue={data.due_date ? formatDate(data.due_date) : ""}
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ md: 8, lg: 16 }}>
            <Col sm={24} lg={12}>
              <Form.Item
                className={commonStyle["w-100"]}
                name="po_number"
                label="Purchase order number"
                rules={[
                  {
                    required: false,
                    message: "Please enter purchase order number",
                  },
                ]}
              >
                <Input readOnly={true} />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              <Form.Item
                className={commonStyle["w-100"]}
                name="dc_number"
                label="Delivery challan number"
                rules={[
                  {
                    required: false,
                    message: "Please enter delivery challan number",
                  },
                ]}
              >
                <Input readOnly={true} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name={["extra", "vendor"]} rules={Required("Vendor")}>
            <VendorSelect
              activeRole={props.activeRole}
              readOnly={!normalInfo.editMode}
              isInvoice={true}
            />
          </Form.Item>
          <Form.Item
            label="Place of supply"
            name="state_code"
            rules={
              invoiceId
                ? Required("Place of supply")
                : [
                    {
                      required: false,
                      // message: "Please enter delivery challan number",
                    },
                  ]
            }
          >
            <PlaceOfSupply readOnly={true} />
          </Form.Item>
          {data.extra?.vendor &&
          data.extra?.vendor?.symbol_name &&
          data.extra?.convertionRate &&
          data.extra?.vendor?.symbol_name !== "INR" ? (
            <FieldInfo
              text={`Currency & conv. rate: 1 ${
                data.extra?.vendor?.symbol_name
              } = ${roundNumber(+data.extra?.convertionRate)} INR`}
            />
          ) : null}
          <Row gutter={{ md: 8, lg: 24 }}>
            <Col sm={24} lg={12}>
              <Form.Item
                label="Amounts are"
                name="tax_inclusion"
                rules={
                  invoiceId
                    ? Required("Amounts are")
                    : [
                        {
                          required: false,
                          // message: "Please enter delivery challan number",
                        },
                      ]
                }
              >
                <AmountsAreSelect
                  readOnly={!normalInfo.editMode}
                  placeOfSupplyId={Number(data.state_code)}
                />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              <Form.Item
                label="Apply cess"
                // rules={Required("Apply cess")}
                name={["extra", "hasCess"]}
              >
                <ApplyCess
                  readOnly={!normalInfo.editMode}
                  disabled={data.tax_inclusion === "OutOfTax"}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ md: 8, lg: 16 }}>
            <Col sm={24} lg={12} />
            <Col
              sm={24}
              lg={11}
              style={{
                marginLeft: "10px",
              }}
            />
          </Row>
        </Col>

        {/* Credit note related fields */}
        <Col md={12}>
          <Row gutter={{ md: 8, lg: 16 }}>
            <Col sm={24} lg={12}>
              <Form.Item
                className={commonStyle["w-100"]}
                name="credit_note_number"
                label="Credit note number"
                rules={[
                  {
                    required: true,
                    message: "Please enter Credit note number",
                  },
                ]}
                required={true}
              >
                <Input
                  placeholder={"Unique credit note number"}
                  readOnly={!editMode}
                />
              </Form.Item>
            </Col>
            <Col sm={24} lg={12}>
              {dateView || (
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="credit_note_date"
                  label="Credit note date"
                  rules={Required("Credit note date")}
                  required={true}
                >
                  <DatePicker
                    format={FORMAT_DATE}
                    defaultValue={new Date()}
                    disabled={!editMode}
                    disabledDate={disabledDate}
                    inputReadOnly={!editMode}
                    className={commonStyle["w-100"]}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          <Form.Item
            name="credit_note_user_comment"
            label="Comment"
            rules={[
              {
                type: "string",
                required: false,
                message: "Please enter comment!",
              },
            ]}
            required={false}
          >
            <TextArea required={editMode} readOnly={!editMode} rows={4} />
          </Form.Item>
        </Col>
      </Row>
    );
  };
  const handlePaymentRefresh = async () => {
    const paymentDetails = await getPaymentDetais(invoiceId!);
    const paidAmount = paymentDetails.payment_history.reduce(
      (sum: any, it: any) =>
        it.payment_status === "Cancelled" ? sum : sum + it.paid_amount,
      0
    );

    const { net_total, tds_amount } = data;
    const remaining = roundNumber(
      net_total ? +net_total : 0 - paidAmount - (tds_amount ? +tds_amount : 0)
    );

    update({
      paymentStatus:
        remaining === 0
          ? "Completed"
          : paidAmount > 0
          ? "Partially Paid"
          : paymentDetails.payment_history.length > 0
          ? "Pending"
          : remaining < 0
          ? "Over paid"
          : "Pending",
    });
    if (props.match?.params.id) {
      loadInvoiceData(+props.match?.params.id);
    }
  };
  const { sendMailModal, payDlgVisible } = normalInfo;

  const currencyInfo = data.extra?.vendor
    ? {
        name: data.extra.vendor.symbol_name,
        symbol: data.extra.vendor.symbol,
        conversionRate: data.extra.convertionRate || 1,
      }
    : undefined;

  const isEditing = !!normalInfo.editMode;
  const isLoading = normalInfo.apiState !== "idle";
  const paymentHisData = {
    netTotal: data.net_total,
    tdsAmont: data.tds_amount,
    paymentDetails: data.extra?.paymentDetails,
    invoiceId,
    conversionRate: data.extra?.convertionRate,
    onRefresh: handlePaymentRefresh,
  };
  return (
    <>
      <IMTContent fullwidth withoutMargin={true}>
        <CreditNotePageHeader
          isNew={isNew}
          paidFlag={data.paid_flag}
          invoiceId={invoiceId!}
          creditNoteId={crId ? crId : null}
          activeRole={props.activeRole}
          isEditing={normalInfo.editMode}
          invoiceComplianceFlag={normalInfo.invoiceComplianceFlag}
          handlePaymentDialog={handlePayDlg}
          apiState={normalInfo.apiState}
          slug={props.match?.params.slug!}
          onCancel={handleCancel(isNew)}
          onDelete={handleDelete}
          onSave={onSave}
          onEdit={onEdit}
          onSendEmail={handleSendInvoiceModal}
          onDownloadInvoice={downloadinvoice}
          complianceStatus={data.compliance_status!}
        />
        <div className={styles.commonContent}>
          <Form style={{ width: "100%" }} {...formProps}>
            {renderInvoiceHeader()}
            <CreditNoteLineItem
              sameState={data.state_code === props.place_of_supply_id}
              hasCess={data.extra?.hasCess}
              rowData={data.credit_note_row_data}
              onRowDataChange={handleRowDataChange}
              onCheckGreater={checkGreaterThanInvoice}
              readOnly={!isEditing}
              isLoading={isLoading}
              tax_inclusion={data.tax_inclusion}
              taxTeritoryType={taxTeritoryType}
              currency={currencyInfo}
              onAddProduct={handleAddProduct}
              isCopy={props.match?.params.slug === "copy"}
              totalPayAmount={data.paid_amount}
              tdsInfo={{
                tdsRate: data.tds_rate,
                tdsAmount: data.tds_amount,
              }}
              paymentHistoryData={paymentHisData}
            />
          </Form>
        </div>
        {/* <div>
          <h3>Data</h3>
          <code>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </code>
        </div> */}
      </IMTContent>
      {normalInfo.openProductModal ? (
        <AddProductModalDialog
          onProductSubmit={handleNewProductSubmit}
          visible={normalInfo.openProductModal}
          closeProductModal={closeProductModal}
          // loadProduct={this.state.loadProduct}
          loadProduct={false}
        />
      ) : null}
    </>
  );
};

export default connector(CreditNotePage);
