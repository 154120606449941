import { baseURL, fetchUtil } from "./common";

export enum LoginRole {
  ADMIN = 1,
  EMPLOYEE = 2,
  CA = 3,
  DELIGATE = 4,
  SUPERuSER = 5,
}
export enum AdminApprovalStatus {
  PENDING = 0,
  APPROVE = 1,
  REJECT = -1,
}
export enum EmployeeApprovalStatus {
  APPLIED = 0,
  APPROVE = 1,
  REJECT = -1,
}
export type LoginRequest = {
  email?: string;
  password: any;
  email_id?: string;
  google_login?: boolean;
  google_secret_key?: string;
};

export type companyChangeRequest = {
  company_id: any;
  role_id: any;
};

export type CompanyDetails = {
  id: number;
  name: string;
  roles: Array<LoginRole>;
};

export type AuthUser = {
  id: number;
  email: string;
  activeRole?: LoginRole;
  role_ids: Array<LoginRole>;
  company_emp_id?: number;
  designation_id?: number;
  place_of_supply: number;
  joining_date?: Date;
  leaving_date?: Date;
  save_emp_details: boolean;
  save_salary_details: boolean;
  onboarding_status: boolean;
  authorised_capital?: any;
  paid_up_capital?: any;
  directors?: any;
  pending_charges?: any;
  company_status?: any;
  logo?: any;
  company_name?: any;
  address?: any;
  first_sync?: boolean;
  session_kv?: any;
  company_details?: Array<CompanyDetails>;
  company_id?: any;
  gstin?: any;
  first_name?: string;
  last_name?: string;
  terms_conditions_agree?: boolean;
  /* membership_plan?: boolean; */
  plan_id: string;
  plan_name: string;
  added_clients: number;
  client_no: number;
  fe_payment_status: boolean;
  plan_purchased: boolean;
  grace_period: boolean;
  already_cancelled: boolean;
  primary_associated_company_id: any;
};

export type LoginResponse = {
  token: string;
  user: AuthUser;
};
export type comResponse = {
  token: string;
  user: AuthUser;
};

export const login = async (payload: LoginRequest): Promise<LoginResponse> => {
  const res = await fetch(baseURL + "/login", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
    mode: "cors",
  });
  let ok = res.ok;
  let json: any = {};
  try {
    const text = await res.text();
    json = JSON.parse(text);
  } catch (err) {
    ok = false;
    json = (err as any).message;
  }
  if (ok) {
    const {
      token,
      user: {
        id,
        email,
        role_ids,
        joining_date,
        leaving_date,
        save_emp_details,
        save_salary_details,
        designation_id,
        place_of_supply,
        onboarding_status,
        authorised_capital,
        paid_up_capital,
        directors,
        pending_charges,
        company_status,
        logo,
        company_name,
        address,
        first_sync,
        session_kv,
        company_details,
        company_id,
        gstin,
        first_name,
        last_name,
        terms_conditions_agree,
        // subscription related fields
        plan_id,
        plan_name,
        added_clients,
        client_no,
        /* membership_plan, */
        fe_payment_status,
        plan_purchased,
        grace_period,
        already_cancelled,
        // subscription related fields ends
        primary_associated_company_id,
      },
    } = json;
    return {
      token,
      user: {
        id,
        email,
        role_ids,
        joining_date: joining_date ? new Date(joining_date) : undefined,
        leaving_date: leaving_date ? new Date(leaving_date) : undefined,
        save_emp_details,
        save_salary_details,
        designation_id,
        place_of_supply,
        onboarding_status,
        authorised_capital,
        paid_up_capital,
        directors,
        pending_charges,
        company_status,
        logo,
        company_name,
        address,
        first_sync,
        session_kv,
        company_details,
        company_id,
        gstin,
        first_name,
        last_name,
        terms_conditions_agree,

        plan_id,
        plan_name,
        added_clients,
        client_no,
        /* membership_plan, */
        fe_payment_status,
        plan_purchased: plan_purchased || (grace_period && !already_cancelled),
        grace_period,
        already_cancelled,
        primary_associated_company_id,
      },
    };
  } else {
    throw json.message;
  }
};

export const changeCompany = (payload: companyChangeRequest) => {
  return fetchUtil("POST", "/access_change", {
    company_id: payload.company_id,
    role_id: payload.role_id ? payload.role_id : null,
  }).then(({ ok, json, message }) => {
    if (ok) {
      return {
        ok,
        json,
        message,
      };
    }
  });
};

export const forgotPassword = (email: string) => {
  return fetchUtil("POST", "/forgot_password", {
    email,
  }).then(({ ok, json, message }) => {
    return { ok, message };
  });
};

export const changePassword = (
  // email: string,
  currentPassword: string,
  newPassword: string
) => {
  return fetchUtil("POST", "/change_password", {
    current_password: currentPassword,
    new_password: newPassword,
  }).then(({ ok, json, message }) => {
    return { ok, message };
  });
};
export type SignupRequest = {
  admin_email?: string;
  password: any;
  email_id?: any;
  google_login?: boolean;
  google_secret_key?: any;
};

export type SignupResponse = {
  ok: boolean;
  message: string;
  data?: any;
  userMessage: string;
};

export const signUpCompany = async (
  payload: SignupRequest
): Promise<SignupResponse> => {
  const res = await fetch(baseURL + "/onboard_company", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const ok = res.ok;
  let json = { message: "", success: false };
  let userMessage = "";
  let data = {};

  if (!ok) {
    try {
      json = await res.json();
    } catch (err) {
      json.message = (err as any).message;
    } finally {
      userMessage = (json.message || "").includes("Internal server error.")
        ? "Something unexpected happned!"
        : json.message;
      json.success = json.success || false;
    }
  } else {
    json = await res.json();
    userMessage = "Verification email sent!";
    data = json;
  }
  return { ok: json.success, message: json.message, data, userMessage };
};
