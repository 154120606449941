import React from "react";
import { Space, Button, Skeleton } from "antd";
import { LoginRole } from "~/api/auth";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import PaymentPending from "~/component/PaymentPending";
import { registerEvent } from "~/analytics";
import * as ga from "~/contants/gaConstants";
// import PaymentPending from "~/component/PaymentPending";
// import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";

interface Props {
  isNew: boolean;
  paidFlag: number;
  invoiceId?: number;
  debitNoteId: null | number;
  activeRole: LoginRole;
  isEditing: boolean;
  paymentStatus?: string;
  handlePaymentDialog: (e: React.MouseEvent) => void;
  apiState: string;
  slug: string;
  onCancel: (e: React.MouseEvent) => void;
  onDelete: (e: React.MouseEvent) => void;
  onSave: (e: React.MouseEvent) => void;
  onEdit: (e: React.MouseEvent) => void;
  invoiceComplianceFlag: boolean;
  onSendEmail: (e: React.MouseEvent) => void;
  onDownloadInvoice: (e: React.MouseEvent) => void;
  complianceStatus: string;
}
const DebitNotePageHeader = (props: Props) => {
  return (
    <IMTPageHeader
      breadcumTexts={[
        "Invoice",
        props.isNew
          ? `New debit note`
          : `View debit note (${props.debitNoteId})`,
      ]}

      actions={
        !props.isNew && props.apiState === "loading" ? (
          <Space align="center">
            <Skeleton.Button />
            <Skeleton.Button />
            <Skeleton.Button />
            <Skeleton.Button />
          </Space>
        ) : (
          <Space align="center">
            {/* {props.activeRole === LoginRole.ADMIN ||
          (props.activeRole === LoginRole.SUPERuSER && !props.isNew) ? (
            props.isEditing ? null : (
              <PaymentPending
                width={35}
                height={35}
                showRedDot={false}
                disabled={props.isEditing}
                onClick={(e: React.MouseEvent) =>
                  props.handlePaymentDialog(e)
                }
                status={props.paidFlag === 1 ? "DONE" : "PENDING"}
                style={{ display: "flex" }}
              />
            )
          ) : null} */}
            {props.invoiceComplianceFlag === true ? null : props.activeRole ===
              3 ? null : (
              <Button
                type="primary"
                style={{
                  marginRight: "0.5em",
                }}
                disabled={props.paidFlag === 1}
                // loading={apiState === "loading" && (!editMode || isNew)}
                onClick={props.isEditing ? props.onSave : props.onEdit}
              >
                {props.isEditing || props.isNew ? "Save" : "Edit"}
              </Button>
            )}

            {/* {props.activeRole === LoginRole.ADMIN ||
          props.activeRole === LoginRole.SUPERuSER ? (
            props.isNew ? null : props.isEditing ? null : (
              <Button
                type="primary"
                style={{
                  marginRight: "0.5em",
                }}
                onClick={props.onSendEmail}
              >
                Send invoice
              </Button>
            )
          ) : null} */}
            {/* {props.activeRole === 1 ||
          props.activeRole === 3 ||
          props.activeRole === LoginRole.SUPERuSER ? (
            props.isNew ? null : props.isEditing ? null : (
              <Button
                type="primary"
                style={{
                  marginRight: "0.5em",
                }}
                onClick={props.onDownloadInvoice}
              >
                Download
              </Button>
            )
          ) : null} */}
            {props.isEditing && props.activeRole !== LoginRole.CA ? (
              <Button
                type="dashed"
                onClick={(e: any) => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    `DebitNotePage>Cancel`
                  );
                  props.onCancel(e);
                }}
                // onClick={props.onCancel}
              >
                Cancel
              </Button>
            ) : null}

            {props.isNew ? null : props.activeRole ===
              3 ? null : props.isEditing ? null : (
              <Button
                danger
                onClick={(e: any) => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    `DebitNotePage>Cancel`
                  );
                  props.onDelete(e);
                }}
              >
                Delete
              </Button>
            )}
          </Space>
        )
      }
    />
  );
};

export default DebitNotePageHeader;
