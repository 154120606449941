import React, { PureComponent } from "react";
import { Modal, Input, Form, Button, Typography } from "antd";
import { fetchUtil } from "~/api/common";
import { registerEvent } from "~/analytics";
import { FormInstance } from "antd/lib/form";

import * as ga from "~/contants/gaConstants";

interface Props {
  visible: boolean;
  onGSTDataReceived(gstData: any): void;
  onCancel(): void;
}

interface State {
  gstin: string;
  errorMessage: string;
  loading: boolean;
}

export default class FetchWithGSTINModal extends PureComponent<Props, State> {
  state: State = {
    gstin: "",
    errorMessage: "",
    loading: false,
  };

  _formRef = React.createRef<FormInstance<any>>();

  componentDidUpdate() {
    //   this.resetDefaults();
  }

  handleGSTIN = (changedValues: any) => {
    // const gstin = changedValues.target.value?.toUpperCase();
    if (changedValues.gstin) {
      this.setState({ gstin: changedValues?.gstin.toUpperCase() });
      this._formRef.current?.setFieldsValue({
        gstin: changedValues?.gstin.toUpperCase(),
      });
    }
  };

  handleCancel = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.fetchWithGstinModal174
    );
    this.props.onCancel();
  };

  handleFetch = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.fetchWithGstinModal175
    );
    this.setState({ loading: true, errorMessage: "" });
    if (!this.state.gstin) {
      this.setState({ errorMessage: "Please enter a valid GSTIN" });
      this.setState({ loading: false });
      return;
    } else {
      const { ok, json } = await fetchUtil("POST", "/get_details_from_gstin", {
        gstin: this.state.gstin,
      });
      if (ok) {
        if (json[0]?.active) this.props.onGSTDataReceived(json[0]);
        else {
          this.setState({
            errorMessage:
              // "This establishment is not active. Current status: " +
              //   json[0]?.activeStr || "unknown",
              "Some error occured, Try again later ",
          });
        }
      } else {
        this.setState({ errorMessage: "Some error occured, Try again later " });
      }
    }

    this.setState({ loading: false });
  };

  render() {
    return (
      <Modal
        title="Fetch data using GSTIN"
        width="40%"
        destroyOnClose={true}
        // className="modal"
        // bodyStyle={{ height: '360px' }}
        visible={this.props.visible}
        footer={
          <>
            <Button color="red" onClick={this.handleCancel}>
              Cancel
            </Button>
          </>
        }
        onCancel={this.handleCancel}
        maskClosable={false}
      >
        <div>
          <div style={{ position: "absolute", right: 0, top: 0 }}>
            <img
              style={{ height: 270 }}
              src={require("~/assets/pattern-1.png")}
              alt="pattern"
            />
          </div>
          <Typography.Title level={4} style={{ width: "80%" }}>
            Hey, here is a shortcut for you. Enter your 15 letter GSTIN to
            auto-fill the form.
            <br />
          </Typography.Title>
          <Form
            ref={this._formRef}
            onValuesChange={this.handleGSTIN}
            style={{ width: "80%" }}
          >
            <Form.Item
              name="gstin"
              label="GSTIN"
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Please enter valid GSTIN",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            loading={this.state.loading}
            onClick={this.handleFetch}
          >
            Fetch details
          </Button>
          <Typography.Title level={4} style={{ width: "80%", color: "red" }}>
            {this.state.errorMessage}
          </Typography.Title>
        </div>
      </Modal>
    );
  }
}
