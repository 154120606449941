import React from "react";

interface FieldInfoProps {
  text: string | number | undefined | any;
  subtle?: boolean;
  color?: string;
  tip?: boolean;
  attatchmentType?: "form-field" | "table-cell";
  className?: string;
  style?: React.CSSProperties;
}
export default function FieldInfo({
  attatchmentType = "form-field",
  ...props
}: FieldInfoProps) {
  return (
    <div
      className={props.className}
      style={{
        fontSize: 10,
        marginTop: attatchmentType === "form-field" ? -12 : 2,
        color: props.color ? props.color : "var(--primary-color)",
        fontWeight: 600,
        marginLeft: 5,
        opacity: props.subtle ? 0.9 : 1,
        ...props.style,
      }}
    >
      {props.tip && (
        <>
          <span
            style={{
              padding: "2px",
              backgroundColor: "var(--processing-color)",
              color: "white",
              fontSize: "8px",
              lineHeight: "12px",
              borderRadius: "2px",
            }}
          >
            Tip
          </span>
          <span>{"  "}</span>
        </>
      )}

      {typeof props.text === "function" ? props.text() : props.text}
    </div>
  );
}
