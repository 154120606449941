import React from "react";
import { Modal, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface Props {
  isOpen: boolean;
  title: string;
  logo: string;
  style: string;
  onClose: () => void;
}
const SynchronizingModal = (props: Props) => {
  return (
    <Modal
      title="Synchronizing"
      width="30%"
      style={{ height: "700px" }}
      destroyOnClose={true}
      className="modal-headless"
      visible={props.isOpen}
      maskClosable={false}
      footer={false}
      onCancel={props.onClose}
    >
      <div style={{ textAlign: "center" }}>
        <img src={props.logo} alt="" className={props.style} />
        <div
          style={{
            marginTop: 85,
            fontSize: 18,
            fontWeight: 700,
            marginBottom: 85,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <LoadingOutlined />
          <Typography.Title
            level={4}
            style={{
              textAlign: "center",
              marginLeft: 5,
            }}
          >
            <b>{props.title}</b>
          </Typography.Title>
        </div>
      </div>
    </Modal>
  );
};

export default SynchronizingModal;
