import { Select } from "antd";
import React, { ReactElement } from "react";
import ReadOnlyableSelect from "~/component/field-input/ReadOnlyableSelect";
import { OPTS_AMOUNTS } from "~/config";
import { OTHER_TERITORY_ID } from "~/lib/constants";

interface Props {
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  placeOfSupplyId?: number;
  vendorUnregFlag?: boolean;
  exportType?: string;
}

export default function AmountsAreSelect({
  className,
  value,
  onChange,
  readOnly,
  placeOfSupplyId,
  vendorUnregFlag,
  exportType,
}: Props): ReactElement {
  return (
    <ReadOnlyableSelect
      className={className}
      value={value}
      onChange={onChange}
      readonly={
        (placeOfSupplyId === OTHER_TERITORY_ID &&
          (exportType ? exportType === "WOPAY" : true)) ||
        readOnly
      }
      showArrow
      showSearch
      id={"tax_inclusion"}
      disabled={vendorUnregFlag}
    >
      {OPTS_AMOUNTS.map((obj: any) => (
        <Select.Option key={obj.value} value={obj.value}>
          {obj.label}
        </Select.Option>
      ))}
    </ReadOnlyableSelect>
  );
}
