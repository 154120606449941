import { Input, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import * as React from "react";
import { StockGroup } from "./tally-dirty-entity-type";

interface IProps {
  stockGroupList: Array<StockGroup>;
  selectedIds: Array<number>;
  onSelectionChange: (idList: Array<number>) => void;
}

export default function StockGroupSelect({
  stockGroupList,
  // selectedIds,
  onSelectionChange,
}: IProps) {
  const [searchValue, setSearchValue] = React.useState("");
  const columnDef = React.useMemo(() => {
    const definition: Array<ColumnType<StockGroup>> = [
      {
        title: "Name",
        dataIndex: "product_category",
        filteredValue: [searchValue],
        onFilter: (value, record) => {
          return String(record.id)
            .toLowerCase()
            .includes(String(searchValue).toLowerCase());
        },
      },
      // {
      //   title: "GSTIN",
      //   dataIndex: "gstin",
      //   render: (_, record) => {
      //     return <>{record.gstin ? record.gstin : "N/A"}</>;
      //   },
      // },
      // {
      //   title: "State",
      //   dataIndex: "billing_address_state",
      // },
      // {
      //   title: "Country",
      //   dataIndex: "billing_address_country",
      // },
      // {
      //   title: "Currency",
      //   // dataIndex: "symbol_name",
      //   dataIndex: "symbol_name",
      // },
    ];

    return definition;
  }, [searchValue]);

  const rowSelection = React.useMemo(() => {
    const selection: TableRowSelection<StockGroup> = {
      onChange(_selectedRowKeys, selectedRows) {
        onSelectionChange(selectedRows.map((it) => it.id));
      },
      // selectedRowKeys: selectedIds
    };
    return selection;
  }, [onSelectionChange]);

  return (
    <>
      <Input
        placeholder="Search Here"
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setSearchValue(e.target.value);
        }}
        style={{ maxWidth: "40%" }}
        allowClear
      />
      <Table
        rowKey="id"
        style={{ maxWidth: "80%", cursor: "pointer" }}
        columns={columnDef}
        rowSelection={rowSelection}
        dataSource={stockGroupList}
        size="large"
      />
    </>
  );
}
