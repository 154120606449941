import cx from "classnames";
import React from "react";
import { Form, Row, Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import styles from "./FieldWithAction.module.css";

export interface Props extends FormItemProps {
  actionLabel: string;
  onAction: (e: React.MouseEvent) => void;
  onAction2?: (e: React.MouseEvent) => void;
  readonly?: boolean;
  actionLabel2?: any;
}

const FieldWithAction = ({
  actionLabel,
  onAction,
  onAction2,
  label,
  readonly,
  children,
  className,
  actionLabel2,
  ...formProps
}: Props) => {
  return (
    <Form.Item
      className={cx(styles.actionField, className)}
      label={
        <Row justify="space-between">
          <span>{label}</span>
          <span>
            {!readonly ? (
              <>
                <b className={styles.actionLabel} onClick={onAction}>
                  {actionLabel}
                </b>

                {actionLabel2 ? (
                  <b className={styles.actionLabel} onClick={onAction2}>
                    / {actionLabel2}
                  </b>
                ) : null}
              </>
            ) : null}
          </span>
        </Row>
      }
      labelCol={{
        span: 24,
      }}
      {...formProps}
    >
      {children ? children : <Input readOnly={readonly} />}
    </Form.Item>
  );
};

export default FieldWithAction;
