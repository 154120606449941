import { Input, Table, Tooltip } from "antd";
import { ColumnType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import * as React from "react";
import { formatDate, roundNumber } from "~/lib";
import { SalesVoucher } from "./tally-dirty-entity-type";

import classes from "./SalesVoucherSelect.module.less";
import FieldInfo from "~/component/FieldInfo";
import Currency from "~/component/Currency";

interface IProps {
  salesVoucherList: Array<SalesVoucher>;
  selectedIds: Array<number>;
  onSelectionChange: (idList: Array<number>) => void;
}

export default function SalesVoucherSelect({
  salesVoucherList,
  // selectedIds,
  onSelectionChange,
}: IProps) {
  const [searchValue, setSearchValue] = React.useState("");
  const columnDef = React.useMemo(() => {
    const definition: Array<ColumnType<SalesVoucher>> = [
      {
        title: "Invoice no/id",
        key: "inv_id",
        render(_, record, index) {
          return (
            <span>
              <Tooltip title={record.invoice_number}>
                <p className={classes.tableDataEllipsis}>
                  {record.invoice_number}
                </p>
              </Tooltip>
              <div className={classes.fieldInfoWrap}>
                <FieldInfo
                  className={classes.fieldInfoStyle}
                  color="#F07C2E"
                  text={record.invoice_id}
                  // text={(record as any).amended ? "Amended" : ""}
                />
              </div>
            </span>
          );
        },
        filteredValue: [searchValue],
        onFilter: (value, record) => {
          return (
            String(record.invoice_number)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.customer_name)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.invoice_date)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.balance_due)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase())
          );
        },
      },
      {
        title: "Customer name",
        key: "cust_name",
        // dataIndex: "id",
        render(_, record, _index) {
          return record.customer_name ? record.customer_name : <>N/A</>;
        },
      },
      {
        title: "Invoice date",
        // key: "inv_date",
        dataIndex: "invoice_date",
        render: (invoiceDate, record: any) => {
          if (invoiceDate && !record._formatedCrtTime) {
            record._formatedCrtTime = formatDate(new Date(invoiceDate));
          }
          return <span>{invoiceDate ? record._formatedCrtTime : "-"}</span>;
        },
      },
      {
        title: "Invoice amount",
        dataIndex: "balance_due",
        render(value, record, index) {
          return (
            <div>
              <Currency currencySymbol={record.customer_currency}>
                {roundNumber(+value)}
              </Currency>
              {record.customer_name ? (
                <span>
                  {record.symbol_name === "INR" ? null : (
                    <span>
                      (
                      <Currency>
                        {roundNumber(
                          +record.balance_due * +record.conversion_rate
                        )}
                      </Currency>
                      )
                    </span>
                  )}
                </span>
              ) : null}
            </div>
          );
        },
      },
    ];

    return definition;
  }, [searchValue]);

  const rowSelection = React.useMemo(() => {
    const selection: TableRowSelection<SalesVoucher> = {
      onChange(_selectedRowKeys, selectedRows) {
        onSelectionChange(selectedRows.map((it) => it.invoice_id));
      },
      // selectedRowKeys: selectedIds
    };
    return selection;
  }, [onSelectionChange]);

  return (
    <>
      <Input
        placeholder="Search Here"
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setSearchValue(e.target.value);
        }}
        style={{ maxWidth: "40%" }}
        allowClear
      />
      <Table
        rowKey="invoice_id"
        style={{ maxWidth: "80%", cursor: "pointer" }}
        columns={columnDef}
        rowSelection={rowSelection}
        dataSource={salesVoucherList}
        size="large"
      />
    </>
  );
}
