import React from "react";
import JSONViewerText from "../jsonViewerText";
import styles from "~/component/JsonDownloadPreview.module.css";
type Props = {
  objectToView: any;
  currentLayer: any;
  previousLayers: any;
  setDataOfALayer: any;
};
function ObjectViewer({
  objectToView,
  currentLayer,
  previousLayers,
  setDataOfALayer,
}: Props) {
  return (
    <tbody className={styles.tbody1}>
      {Object.keys(objectToView).map((objectKey) => {
        return (
          <tr key={objectKey} className={styles.trow1}>
            <td className={styles.trowD1}>{objectKey} :</td>
            <td className={styles.trowD1}>
              <JSONViewerText
                JSONToView={objectToView[objectKey]}
                currentLayer={[currentLayer, objectKey]
                  .filter((a) => a)
                  .join(",")}
                setDataOfALayer={setDataOfALayer}
              />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
}

export default ObjectViewer;
