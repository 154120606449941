import React from "react";
import Lottie from "lottie-react-web";
import { Typography, Button, Modal } from "antd";
import OnboardingComplete from "~/assets/lottie-animation/OnboardingComplete.json";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "~/app/MainApp/store";
import { action } from "~/app/MainApp/store";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

const FirstTimeLoginSuccessModal = () => {
  const { firstTimeLoginModal, isSyncing } = useSelector(
    (state: AuthState) => state.modalSlice
  );
  const { isVisible } = firstTimeLoginModal;
  const dispatch = useDispatch();

  const { modal, auth } = action;

  const handleCloseModal = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.firstTimeLoginSuccessModal44
    );
    // Setting a variable in local storage to mark that initially (Onboarding success modal) import modal was shown.
    const userData = localStorage.getItem("imt__user");
    let updatedUserdata: any = {};
    if (userData) {
      updatedUserdata = JSON.parse(userData);
      updatedUserdata.first_sync = true;
      localStorage.setItem("imt__user", JSON.stringify(updatedUserdata));
    }
    // Dispatching an action to hide the modal
    dispatch(modal.hideFirstTimeLoginModal());
    // dispatch(auth.controlAppTour(true));
  };

  const handleSyncNow = () => {
    dispatch(modal.hideFirstTimeLoginModal());
    // dispatch(modal.checkStatusAndShowModal());
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.synchronize244
    );
    if (isSyncing) {
      dispatch(action.modal.showSyncStatusModal());
    } else {
      dispatch(action.modal.showSourceSelectionModal());
    }
  };

  return (
    <>
      <Modal
        title="Fetch data using GSTIN"
        width="30%"
        style={{ height: "700px" }}
        destroyOnClose={true}
        className="modal-headless"
        maskClosable={false}
        footer={false}
        visible={isVisible}
        onCancel={handleCloseModal}
      >
        <div>
          <div style={{ textAlign: "center" }}>
            <Lottie
              options={{
                animationData: OnboardingComplete,
              }}
            />
            <Typography.Title level={3} style={{ color: "#9b51e0" }}>
              You are all set !!
              <br />
            </Typography.Title>
            <Typography.Title level={4} style={{ color: "var(--grey-2)" }}>
              As a next step, do you want to sync your data from other platforms
              ?
              <br />
            </Typography.Title>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "2em",
            }}
          >
            <Button
              onClick={handleCloseModal}
              style={{ border: "none" }}
              type="ghost"
            >
              Later
            </Button>
            <Button
              onClick={handleSyncNow}
              type="primary"
              style={{ width: "120px" }}
            >
              Yes, Run now
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FirstTimeLoginSuccessModal;
