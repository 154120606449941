import { roundNumber } from ".";
import { TaxTeritoryType, TAX_INCLUSION } from "./taxes";

export type TaxApplicability = {
  taxTeritoryType: TaxTeritoryType;
  taxInclusion: TAX_INCLUSION;
};

export type TaxInfo = TaxApplicability & {
  gstPercentage: number;
  cessPercentage: number;
};

export type LineItemAmount = {
  amount: number;
};

export type LineItemWtTax = {
  amount: number;
  gstPercentage: number;
  cessPercentage: number;
};

export type GSTResult = {
  subTotal: number;
  total: number;
  cgst: number;
  sgst: number;
  igst: number;
  cess: number;
};

/**
 * Calculates taxes without rounding them
 * @private
 */
const _getGST = (
  amount: number,
  { gstPercentage, cessPercentage, taxTeritoryType, taxInclusion }: TaxInfo
): GSTResult => {
  if (
    taxInclusion === "OutOfTax" ||
    taxTeritoryType === TaxTeritoryType.OTHER_TERITORY
  ) {
    return {
      subTotal: amount,
      total: amount,
      cgst: 0,
      sgst: 0,
      igst: 0,
      cess: 0,
    };
  } else if (taxInclusion === "ExclusiveOfTax") {
    const cess = (amount * cessPercentage) / 100;
    const gst = (amount * gstPercentage) / 100;
    let cgst = 0;
    let sgst = 0;
    let igst = 0;
    if (taxTeritoryType === TaxTeritoryType.INTRA_STATE) {
      igst = gst;
    } else {
      cgst = sgst = gst / 2;
    }
    const subTotal = amount;
    const total = subTotal + gst + cess;
    return {
      total,
      subTotal,
      cgst,
      sgst,
      igst,
      cess,
    };
  } else if (taxInclusion === "InclusiveOfTax") {
    const total = amount;
    const subTotal = (amount * 100) / (100 + gstPercentage + cessPercentage);
    const gst = subTotal * (gstPercentage / 100);
    const cess = subTotal * (cessPercentage / 100);

    let cgst = 0;
    let sgst = 0;
    let igst = 0;
    if (taxTeritoryType === TaxTeritoryType.INTRA_STATE) {
      igst = gst;
    } else {
      cgst = sgst = gst / 2;
    }
    return {
      total,
      subTotal,
      cgst,
      sgst,
      igst,
      cess,
    };
  }
  throw new Error(
    `Invalid taxInclusion & taxTeritoryType given. 
          taxInclusion = ${taxInclusion}, taxTeritoryType = ${taxTeritoryType}`
  );
};

export const getGST = (amount: number, taxInfo: TaxInfo): GSTResult => {
  const result = _getGST(amount, taxInfo);
  result.subTotal = roundNumber(+result.subTotal);
  result.total = roundNumber(+result.total);
  result.cgst = roundNumber(+result.cgst);
  result.sgst = roundNumber(+result.sgst);
  result.igst = roundNumber(+result.igst);
  result.cess = roundNumber(+result.cess);
  return result;
};

export const getTotalGSTForCommonTax = <T extends LineItemAmount>(
  lineItems: Array<T>,
  taxInfo: TaxInfo
) => {
  const result = lineItems.reduce(
    (accu: GSTResult, it: T) => {
      const result = getGST(it.amount, taxInfo);
      accu.subTotal += result.subTotal;
      accu.total += result.total;
      accu.cgst += result.cgst;
      accu.sgst += result.sgst;
      accu.igst += result.igst;
      accu.cess += result.cess;
      return accu;
    },
    {
      subTotal: 0,
      total: 0,
      cgst: 0,
      sgst: 0,
      igst: 0,
      cess: 0,
    }
  );

  result.subTotal = roundNumber(+result.subTotal);
  result.total = roundNumber(+result.total);
  result.cgst = roundNumber(+result.cgst);
  result.sgst = roundNumber(+result.sgst);
  result.igst = roundNumber(+result.igst);
  result.cess = roundNumber(+result.cess);
};

export const getTotalGST = <T extends LineItemWtTax>(
  lineItems: Array<T>,
  taxApplicability: TaxApplicability
) => {
  const result = lineItems.reduce(
    (accu: GSTResult, it: T) => {
      const result = getGST(it.amount, {
        cessPercentage: it.cessPercentage,
        gstPercentage: it.gstPercentage,
        taxInclusion: taxApplicability.taxInclusion,
        taxTeritoryType: taxApplicability.taxTeritoryType,
      });
      accu.subTotal += result.subTotal;
      accu.total += result.total;
      accu.cgst += result.cgst;
      accu.sgst += result.sgst;
      accu.igst += result.igst;
      accu.cess += result.cess;
      return accu;
    },
    {
      subTotal: 0,
      total: 0,
      cgst: 0,
      sgst: 0,
      igst: 0,
      cess: 0,
    }
  );
  result.subTotal = roundNumber(+result.subTotal);
  result.total = roundNumber(+result.total);
  result.cgst = roundNumber(+result.cgst);
  result.sgst = roundNumber(+result.sgst);
  result.igst = roundNumber(+result.igst);
  result.cess = roundNumber(+result.cess);
  return result;
};
