import { fetchUtil } from "./common";

export type CompliancePaymentStatus = "complete" | "pending";

export interface ComplianceInfo {
  compliances?: Array<CompliancesArr>;
  compliances_id: Array<any>;
  custom: boolean;
  status: any;
  type_description: any;

  total_amount?: any;
  due_date?: any;

  children?: Array<any>;
}

export interface CompliancesArr {
  amount: number;
  ca_comment: any;
  due_date: any;
  id: any;
  late_fine: any;
  postpone_due_date: any;
  status: any;
  status_id: any;
  type_desccription: any;
  type_id: any;
}

export interface ComplianceChildren {
  children: Array<ComplianceDetails>;
}

export interface ComplianceDetails {
  compliances: Array<CompliancesArr>;
}

export const getComplianceData = (type: string) => {
  return fetchUtil("POST", "/fetch_compliances", {
    type,
  }).then(({ json }) => {
    const complianceData = json as Array<any>;
    return complianceData;
  });
};

export const updateComplianceData = (
  complianceArray: Array<any>,
  comment: string
) => {
  return fetchUtil("POST", "/update_compliances", {
    compliance_array: complianceArray,
    comment,
    lateFine: null,
  }).then(({ ok, message }) => {
    return { ok, message };
  });
};

export const updateComplianceData1 = (
  complianceArray: Array<any>,
  comment: string,
  lateFine: string
) => {
  return fetchUtil("POST", "/update_compliances", {
    compliance_array: complianceArray,
    comment,
    lateFine,
  }).then(({ ok, message }) => {
    return { ok, message };
  });
};

export const addCustomCompliance = (
  complianceName: string,
  interval: number,
  day: number
) => {
  const d = new Date();
  const m = d.getMonth() + 1;
  return fetchUtil("POST", "/add_custom_compliance", {
    compliance_name: complianceName,
    interval,
    day,
    month: m,
  }).then(({ ok, message }) => {
    return { ok, message };
  });
};

export const addCustomCompliance1 = (
  complianceName: string,
  interval: number,
  day: number,
  month: string
) => {
  return fetchUtil("POST", "/add_custom_compliance", {
    compliance_name: complianceName,
    interval,
    day,
    month,
  }).then(({ ok, message }) => {
    return { ok, message };
  });
};

export const generateOTP = (userName: string) => {
  return fetchUtil("POST", "/reconciliation/request_otp", {
    userName,
  }).then((result) => {
    return result;
  });
};

export const verifyOTP = (userName: string, otp: string) => {
  return fetchUtil("POST", "/reconciliation/verify_otp", {
    userName,
    otp,
  }).then((result) => {
    return result;
  });
};

export const reconciliation = (year: number, month: number) => {
  return fetchUtil("POST", "/reconciliation", {
    year,
    month,
  }).then((result) => {
    return result;
  });
};

export const reconciliationOutput = (
  gstr3FinancialYear: string,
  gstr1FinancialYear: string,
  gstr1TaxPeriod: string,
  taxPeriod: string
) => {
  return fetchUtil("POST", "/reconciliation/invoice", {
    gstr3FinancialYear,
    gstr1FinancialYear,
    gstr1TaxPeriod,
    taxPeriod,
  }).then((result) => {
    return result;
  });
};

export const getExpTime = () => {
  return fetchUtil("POST", "/reconciliation/get_session_expire", {}).then(
    (result) => {
      return result;
    }
  );
};

export const checkGstr1FillingStatus = (
  financialYear: string,
  taxPeriod: string
) => {
  return fetchUtil("POST", "/reconciliation/check_gstr1_filling_status", {
    financialYear,
    taxPeriod,
  }).then((result) => {
    return result;
  });
};

export const moveToNextTaxPeriod = (
  logDetailsId: number,
  instrumentType?: string,
  instrumentId?: number
) => {
  return fetchUtil("POST", "/move_to_next_tax_period", {
    logDetailsId,
    instrument_type: instrumentType || null,
    instrument_id: instrumentId || null,
  }).then((result) => {
    return result;
  });
};

export const markAsIneligible = (logDetailsId: number) => {
  return fetchUtil("POST", "/make_log_ineligible_for_itc", {
    logDetailsId,
  }).then((result) => {
    return result;
  });
};

export const markAsEligible = (logDetailsId: number) => {
  return fetchUtil("POST", "/make_log_eligible_for_itc", {
    logDetailsId,
  }).then((result) => {
    return result;
  });
};

export const updateGstUsername = (updatedGstUsername: string) => {
  return fetchUtil("POST", "/update_gst_username", {
    gst_user_name: updatedGstUsername,
  }).then((result) => {
    return result;
  });
};
