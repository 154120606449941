import React, { Component } from "react";
import {
  Button,
  Table,
  notification,
  Row,
  Tooltip,
  // Space,
  // Menu,
  // Dropdown,
  Modal,
  Input,
  Col,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons"; // , MoreOutlined
import { ColumnType } from "antd/lib/table/interface";
import { RouteChildrenProps } from "react-router";
import { formatDate, parseNumber } from "~/lib";

import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux"; //  AnyIfEmpty,
import Currency from "~/component/Currency";
import { fetchUtil } from "~/api/common";
// import PaymentPending from "~/component/PaymentPending";
// import ReconcileFileUpload from "~/component/ReconcileUpload";
import { getVendorList } from "~/api/vendor";
import { debounce } from "lodash";
import styles from "./InvoicePage.module.css";
import {
  subMonths,
  getMonth,
  // isPast,
  getYear,
  addYears,
  subYears,
} from "date-fns";
import SummaryTags from "~/component/SummaryTags";
import PayInvoiceModal from "./modal/PayInvoiceModal";
import { LoginRole } from "~/api/auth";
import YearMonthDropdown from "~/fragment/year-month-dropdown/YearMonthDropdown";
import {
  InvoiceAPIData,
  downloadPDFFile,
  getInvoiceList,
  getTableExportBlob,
} from "~/api/invoice";
import { registerEvent } from "~/analytics";
import FieldInfo from "~/component/FieldInfo";
import { months } from "~/lib/constants";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  sortOrderInfo: state.mainAppSlice.user.sort_order,
  sorterData: state.mainAppSlice.user.sorter,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  setSortOrder: (sortOrderInfo: any) =>
    dispatch(action.auth.setSortOrder(sortOrderInfo)),
  setSorter: (sorter: any) => dispatch(action.auth.setSorter(sorter)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouteChildrenProps, PropsFromRedux {}

interface State {
  rowData: Array<InvoiceAPIData>;
  pagination: {
    pageSize: number;
    pageNo: number;
    pageCount: number;
  };
  apiState: "idle" | "loading" | "error";
  monthSelect: number;
  yearSelect: any;
  invoiceData: any;
  payInvoiceModal: boolean;
  selectInvoiceDetail?: any;
  activePage: number;
  noOfRows: number;
  totalItemCount: number;
  vendorPickList: any;
  sortedInfo: any;
  filteredInfo: any;
  sortOrder: string;
  searchItem: string;
  highlightedText: string;
}
class CreditNotePage extends Component<Props, {}> {
  state: State = {
    rowData: [],
    pagination: {
      pageSize: 10,
      pageNo: 1,
      pageCount: 1,
    },
    apiState: "idle",
    yearSelect: "",
    monthSelect:
      new Date().getDate() < 25
        ? getMonth(subMonths(new Date(), 1))
        : getMonth(new Date()),
    invoiceData: [],
    payInvoiceModal: false,
    selectInvoiceDetail: undefined,
    activePage: 1,
    noOfRows: 12,
    totalItemCount: 0,
    vendorPickList: [],
    sortedInfo: {},
    filteredInfo: {},
    sortOrder: "",
    searchItem: "",
    highlightedText: "",
  };

  static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State
  ): Partial<State> | null {
    const query = new URLSearchParams(nextProps.location.search);
    let currentPage = 1;
    const today = new Date();
    const isBefore25th = today.getDate() < 25;
    const selectedDate = isBefore25th ? subMonths(today, 1) : today;
    const current = new Date();
    const yearCurrent = new Date().getFullYear();
    const eligible =
      current.getTime() <= new Date(`${yearCurrent}-04-25`).getTime() &&
      current.getTime() > new Date(`${yearCurrent - 1}-04-25`).getTime();
    let year: any = eligible
      ? `${getYear(subYears(current, 1))}-${getYear(current)}`
      : `${getYear(current)}-${getYear(addYears(current, 1))}`;
    // let year = selectedDate.getFullYear();
    let month = getMonth(selectedDate);

    if (query.has("year")) {
      const _yearStr = query.get("year") + "";
      if (/\d{4}/.test(_yearStr)) {
        year = _yearStr;
      }
    }
    if (query.has("month")) {
      const _monthNum = +months.indexOf(query.get("month") + "");
      if (Number.isFinite(_monthNum)) {
        month = _monthNum;
      }
    }
    if (query.has("page")) {
      const pageNo = +query.get("page")!;
      if (Number.isFinite(pageNo)) {
        currentPage = pageNo;
      }
    }
    const newState: Partial<State> = {
      yearSelect: year,
      monthSelect: month,
      activePage: currentPage,
      sortOrder: query.get("sort_order") || "",
    };
    if (
      prevState.yearSelect !== newState.yearSelect ||
      prevState.monthSelect !== newState.monthSelect ||
      prevState.activePage !== newState.activePage ||
      prevState.sortOrder !== newState.sortOrder
    ) {
      return newState;
    }
    return null;
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    if (this.props.sorterData) {
      this.setState({
        sortedInfo: this.props.sorterData,
      });
    }
    if (query.has("search_item") && query.get("search_item")) {
      const searchItem = query.get("search_item");
      if (searchItem) {
        this.setState({ searchItem, highlightedText: searchItem }, () => {
          this.loadData();
          this.getInvoiceSummary();
          this.loadPickupLists();
        });
      }
    } else {
      this.loadData();
      this.getInvoiceSummary();
      this.loadPickupLists();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>) {
    if (
      _prevState.monthSelect !== this.state.monthSelect ||
      _prevState.yearSelect !== this.state.yearSelect ||
      _prevState.activePage !== this.state.activePage
    ) {
      this.loadData();
      if (
        this.props.activeRole === LoginRole.ADMIN ||
        this.props.activeRole === LoginRole.CA ||
        this.props.activeRole === LoginRole.SUPERuSER
      ) {
        this.getInvoiceSummary();
      }
    }
    if (_prevState.rowData !== this.state.rowData) {
      if (this.state.rowData.length) {
        this.placeButton();
      }
    }
  }

  placeButton = () => {
    const element = document.getElementsByClassName(
      "ant-pagination-item-link"
    )[0];
    if (element) {
      const { left, top } = element.getBoundingClientRect();
      const btn = document.getElementById("exportBtn");
      if (btn) {
        btn.style.position = "absolute";
        btn.style.left = left - 30 + "px";
        btn.style.top = top + "px";
        btn.style.margin = "0";
        btn.style.display = "inline";
      }
    }
  };

  loadPickupLists = async () => {
    const [vendorRes] = await Promise.all([
      getVendorList().then(({ ok, message, data }) => ({
        ok,
        message,
        data,
      })),
    ]);

    if (!vendorRes.ok) {
      notification.error({
        message: "Failed to load Vendors",
        description: vendorRes.message,
      });
    } else {
      this.setState({
        vendorPickList: vendorRes.data,
      });
    }
  };

  loadData = async () => {
    this.setState({
      apiState: "loading",
    });
    const { ok, json } = await fetchUtil(
      "POST",
      "/get_credit_notes_list", // message,
      {
        page_no: this.state.activePage,
        no_of_rows: this.state.noOfRows,
        // year: this.state.yearSelect + "",
        // month: this.state.monthSelect + 1 + "",
        fy: this.state.yearSelect + "",
        month:
          this.state.monthSelect + 1 + "" === "13"
            ? "ALL"
            : this.state.monthSelect + 1 + "",
        time_zone_offset: 330,
        sort_param: this.props.sortOrderInfo || "CNDATE_ASC",
        search_text: this.state.searchItem,
        // credit_note_id: 1,

        // "month": 1,
        // "no_of_rows": 1,
        // "page_no": 1,
        // "sort_param": "CNDATE_DESC",
        // "time_zone_offset": 330,
        // "year": 2022
      }
    );
    if (!ok) {
      // notification.error({ message });
      // this.setState({
      //   apiState: "error",
      // });
    } else {
      // console.log(json)
      json.forEach((row) => {
        for (const key in row) {
          if (key.includes("date") || key.includes("_time")) {
            row[key] = new Date(row[key]);
          }
        }
      });
      const data = json as Array<InvoiceAPIData>;
      this.setState({
        rowData: data,
        apiState: "idle",
        totalItemCount: data.length > 0 ? data[0].total_rows : 0,
      });
      this.getInvoiceSummary();
    }
  };

  sortedData = async (order: any) => {
    this.setState({
      apiState: "loading",
    });
    const { ok, message, json } = await fetchUtil(
      "POST",
      "/get_credit_notes_list",
      {
        page_no: this.state.activePage,
        no_of_rows: this.state.noOfRows,
        // year: this.state.yearSelect + "",
        // month: this.state.monthSelect + 1 + "",
        fy: this.state.yearSelect + "",
        month:
          this.state.monthSelect + 1 + "" === "13"
            ? "ALL"
            : this.state.monthSelect + 1 + "",
        time_zone_offset: 330,
        sort_param: order,
        search_text: this.state.searchItem,
      }
    );
    if (!ok) {
      notification.error({ message });
      this.setState({
        apiState: "error",
      });
    } else {
      json.forEach((row) => {
        for (const key in row) {
          if (key.includes("date") || key.includes("_time")) {
            row[key] = new Date(row[key]);
          }
        }
      });
      const data = json as Array<InvoiceAPIData>;

      this.setState({
        rowData: data,
        apiState: "idle",
        totalItemCount: data.length > 0 ? data[0].total_rows : 0,
      });
    }
  };
  debouncedGetCreditNoteSummary = debounce(this.loadData, 500);
  handlePageChange = (pageNumber: number) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", pageNumber + "");
    this.props.history.push("?" + query);
  };
  handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchItem = e.target.value;
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", 1 + "");
    query.set("search_item", searchItem);
    this.props.history.push("?" + query);
    this.setState(
      { searchItem: e.target.value, highlightedText: e.target.value },
      () => {
        // this.debouncedGetVendorList();
        this.debouncedGetCreditNoteSummary();
      }
    );
  };
  highlightText(text: string): JSX.Element {
    const { highlightedText } = this.state;
    if (text) {
      const parts = text.split(new RegExp(`(${highlightedText})`, "gi"));
      return (
        <>
          {parts.map((part: any, index: any) =>
            part.toLowerCase() === highlightedText.toLowerCase() ? (
              <span key={index} className={styles["highlighted-text"]}>
                {part}
              </span>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </>
      );
    } else {
      return <></>;
    }
  }
  handleTableChange = (_pagination: any, filters: any, sorter: any) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", _pagination.current + "");
    let sortOrder = "";
    // console.log(sorter);

    if (sorter.columnKey === "credit_note_number") {
      if (sorter.order === "descend") {
        sortOrder = "ID_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "ID_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "customer_name") {
      if (sorter.order === "descend") {
        sortOrder = "CUST_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "CUST_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "credit_note_date") {
      if (sorter.order === "descend") {
        sortOrder = "CNDATE_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "CNDATE_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "invoice_id") {
      if (sorter.order === "descend") {
        sortOrder = "ID_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "ID_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "net_total") {
      if (sorter.order === "descend") {
        sortOrder = "AMT_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "AMT_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    }
    if (!sorter.order) {
      sortOrder = "";
      this.props.setSortOrder(sortOrder);
    }

    const prev = query.get("sort_order");
    if (prev === sortOrder) return;

    query.set("sort_order", sortOrder);

    this.props.history.push("?" + query);
    this.props.setSorter(sorter);
    this.setState(
      {
        filteredInfo: filters,
        sortedInfo: sorter ? sorter : this.props.sorterData,
      },
      () => this.loadData()
    );
  };

  handleOnboardVisibleChange = (requestedVisibility: boolean) => {
    this.setState({
      payInvoiceModal: requestedVisibility,
    });
  };

  async getInvoiceSummary() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_credit_note_summary`,
      {
        // month: this.state.monthSelect + 1 + "",
        // year: this.state.yearSelect + "",
        fy: this.state.yearSelect + "",
        month:
          this.state.monthSelect + 1 + "" === "13"
            ? "ALL"
            : this.state.monthSelect + 1 + "",
        search_text: this.state.searchItem,
      }
    );

    if (ok) {
      const data = json[0];
      if (data) {
        data.credit_note_amount = parseNumber(data.credit_note_amount);
      }
      this.setState({
        invoiceData: data,
      });
    } else {
      notification.error({ message });
    }
  }

  handleRowEvent = (record: InvoiceAPIData, _rowIndex?: number) => {
    return {
      onClick: (_event: React.MouseEvent) => {
        registerEvent(
          ga.EVENT_CATEGORY_BUTTON_CLICK,
          ga.EVENT_CLICK,
          ga.events.creditNotePage113
        );

        if (record.invoice_id) {
          this.props.history.push(
            `/app/invoice/${record.invoice_id}/creditNote/${record.credit_note_id}`,
            {
              search: this.props.location.search,
            }
          );
        } else {
          // notification.error({
          //   message: "Can not open this invoice",
          // });
          this.props.history.push(
            `/app/invoice/creditNote/${record.credit_note_id}`,
            {
              search: this.props.location.search,
            }
          );
        }
        // this.props.history.push(`/app/invoice/${record.invoice_id}`);
      },
    };
  };

  handleAddNew = (_e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.creditNotePage114
    );
    this.props.history.push("/app/invoice/creditNote/new");
  };

  handleDownload = (e: React.MouseEvent<HTMLSpanElement>) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.creditNotePage115
    );
    e.stopPropagation();
    const _invoice_id = e.currentTarget.dataset.id;
    if (_invoice_id) {
      const invoice_id = +_invoice_id;
      downloadPDFFile(invoice_id)
        .then((res: any) => {
          const blob = new Blob([res], { type: "application/pdf" });

          saveAs(blob, `Invoice ${invoice_id}.pdf`);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  handleCopy = (e: React.MouseEvent<HTMLSpanElement>) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.creditNotePage116
    );
    e.stopPropagation();
    const _invoice_id = e.currentTarget.dataset.id;
    if (_invoice_id) {
      const invoice_id = +_invoice_id;
      const invoice = this.state.rowData.find(
        (it) => it.invoice_id === invoice_id
      );
      this.props.history.push("/app/invoice/copy?from=" + _invoice_id, invoice);
    }
  };

  handleDelete = (
    e: React.MouseEvent<HTMLSpanElement>,
    record: InvoiceAPIData
  ) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.creditNotePage117
    );
    e.stopPropagation();
    const _invoice_id = e.currentTarget.dataset.id;

    if (_invoice_id) {
      Modal.confirm({
        title:
          record.compliance_status === "Complete" && record.paid_flag === 1 ? (
            <>
              <div>Do you really wish to delete this invoice?</div>
              <div>
                Note: Payment for this invoice is already logged and compliances
                are completed. Delete at your own risk
              </div>{" "}
            </>
          ) : record.paid_flag === 1 ? (
            <>
              <div>Do you really wish to delete this invoice?</div>
              <div>
                Note: Payment for this invoice is already logged and compliances
                are completed. Delete at your own risk
              </div>{" "}
            </>
          ) : (
            <>
              <div>Do you wish to delete this invoice?</div>
            </>
          ),
        icon: <ExclamationCircleOutlined />,
        onOk: async () => {
          const { ok, message } = await fetchUtil(
            "POST",
            "/update_invoice/delete_invoice",
            {
              invoice_id: _invoice_id,
            }
          );
          if (!ok) {
            notification.warn({ message });
          } else {
            notification.success({ message });
            this.loadData();
            this.getInvoiceSummary();
          }
        },
      });
    }
  };

  refreshAfterReconcile = (isSubmit: boolean) => {
    if (isSubmit) {
      this.loadData();
      this.getInvoiceSummary();
      this.loadPickupLists();
      if (this.props.sorterData) {
        this.setState({
          sortedInfo: this.props.sorterData,
        });
      }
    }
  };

  handleFetchData = () => {
    return getInvoiceList(
      1,
      330,
      1,
      this.state.monthSelect + 1 + "",
      this.state.yearSelect + "",
      this.props.sortOrderInfo || "DATE_DESC"
    );
  };

  handleExportDataDownload = async (
    acceptType: string,
    selectedFields: string
  ) => {
    return getTableExportBlob(
      1,
      330,
      999999,
      this.state.monthSelect + 1 + "",
      this.state.yearSelect + "",
      this.props.sortOrderInfo || "DATE_DESC",
      acceptType,
      selectedFields
    );
  };

  render() {
    const summaryData = this.state.invoiceData;
    let { sortedInfo } = this.state;
    const { monthSelect, yearSelect } = this.state;
    sortedInfo = sortedInfo || {};
    const columnDef: Array<ColumnType<InvoiceAPIData>> = [
      {
        title: "Credit note no",
        dataIndex: "credit_note_number",
        key: "credit_note_number",
        align: "left",
        sorter: false,
        sortOrder:
          sortedInfo.columnKey === "credit_note_number" && sortedInfo.order,
        defaultSortOrder: "descend",
        ellipsis: true,
        render: (credit_note_number, record) => {
          return (
            <span>
              {credit_note_number && (
                <p className={styles.tableDataEllipsis}>
                  {this.highlightText(String(credit_note_number))}
                </p>
              )}
            </span>
          );
        },
      },
      {
        title: "Customer name",
        dataIndex: "customer_name",
        key: "customer_name",
        align: "left",
        sorter: false,
        sortOrder: sortedInfo.columnKey === "customer_name" && sortedInfo.order,
        ellipsis: true,
        render: (customer_name, record) => {
          return (
            <span>
              {customer_name && (
                <Tooltip title={customer_name}>
                  <p className={styles.tableDataEllipsis}>
                    {this.highlightText(customer_name)}
                  </p>
                </Tooltip>
              )}
              <div
                style={{
                  marginTop: "10px",
                  marginLeft: "-5px",
                }}
              >
                <FieldInfo
                  style={{
                    maxWidth: "98px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  text={
                    record.customer_gstin
                      ? record.customer_gstin
                      : "GST unregistered"
                  }
                />
              </div>
            </span>
          );
        },
      },

      {
        title: "Credit note date",
        dataIndex: "credit_note_date",
        key: "credit_note_date",
        sorter: true,
        sortOrder:
          sortedInfo.columnKey === "credit_note_date" && sortedInfo.order,
        render(credit_note_date) {
          return formatDate(credit_note_date);
        },
      },

      {
        title: "Invoice no.",
        dataIndex: "invoice_id",
        key: "invoice_id",
        align: "left",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "invoice_id" && sortedInfo.order,
        defaultSortOrder: "descend",
        width: "18%",
        render: (invoice_id, record) => {
          return invoice_id ? invoice_id : "--";
        },
      },

      {
        title: "Credit note amount",
        dataIndex: "net_total",
        key: "net_total",
        sorter: false,
        sortOrder: sortedInfo.columnKey === "net_total" && sortedInfo.order,
        align: "right",
        render: (net_total, record) => {
          return (
            <span>
              <Currency currencySymbol={record.customer_currency}>
                {net_total}
              </Currency>
              {record.symbol_name !== "INR" ? (
                <span>
                  {" "}
                  (<Currency>{+net_total * +record.conversion_rate}</Currency>)
                </span>
              ) : null}
            </span>
          );
        },
      },
    ];
    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>{titles.CreditNotePage}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Sales - Credit note"]}
          style={{ paddingLeft: "0" }}
          actions={
            <>
              {this.props.activeRole === 1 ||
              this.props.activeRole === LoginRole.SUPERuSER ? (
                <>
                  {" "}
                  <Button type="primary" onClick={this.handleAddNew}>
                    + Add new
                  </Button>
                </>
              ) : (
                ""
              )}
            </>
          }
        />

        <Row>
          <Col>
            <Input
              placeholder="Search in customer name / Credit note no"
              onChange={this.handleInputChange}
              value={this.state.searchItem}
              style={{
                minWidth: "300px",
                height: "32px",
                marginBottom: "5px",
                marginRight: "20px",
              }}
              allowClear
            />
          </Col>
          <Col style={{ marginLeft: "10px" }}>
            <YearMonthDropdown
              month={this.state.monthSelect}
              year={this.state.yearSelect}
              marginLeft={"0px"}
              payrollFlag={false}
            />
          </Col>
        </Row>

        <Row className={styles.summaryDiv}>
          {this.state.invoiceData ? (
            <SummaryTags
              nameValuePairs={[
                { name: "Count", value: summaryData.credit_note_count },
                {
                  name: "Total credit note amount",
                  value: summaryData.credit_note_amount,
                  isCurrency: true,
                },
              ]}
            />
          ) : null}
        </Row>

        <Table
          rowKey="credit_note_number"
          className={styles.invoiceTable}
          columns={columnDef}
          dataSource={this.state.rowData}
          loading={this.state.apiState === "loading"}
          onRow={this.handleRowEvent}
          onChange={this.handleTableChange}
          size="large"
          pagination={{
            size: "small",
            current: this.state.activePage,
            pageSize: this.state.noOfRows,
            showSizeChanger: false,
            onChange: this.handlePageChange,
            total: this.state.totalItemCount,
            position: ["topRight"],
            style: { margin: "0px" },
          }}
        />

        {this.state.selectInvoiceDetail && (
          <PayInvoiceModal
            visible={this.state.payInvoiceModal}
            onVisibleChange={this.handleOnboardVisibleChange}
            selectInvoiceDetail={this.state.selectInvoiceDetail}
            fetchData={this.loadData}
            vendorPickList={this.state.vendorPickList}
          />
        )}
      </IMTContent>
    );
  }
}

export default connector(CreditNotePage);
