import { useCallback, useEffect, useRef, useState } from "react";
import { APIState } from "~/api/common";
import usePersistantCallbackRef from "./usePersistantCallbackRef";

const __cacheSingleton = new Map<string, unknown>();
const __fetchQueue = new Map<string, Promise<unknown>>();
const __updators: Map<string, Array<(data: unknown) => void>> = new Map();

(window as any).$$ = {
  __cacheSingleton,
  __fetchQueue,
  __updators,
};

export default function useFetch<T>(
  cacheKey: string,
  fetchFn: () => Promise<T>,
  defaultValue: T,
  disableInitialFetch: boolean = false
) {
  const initialValueRef = useRef({
    defaultValue,
    isMounted: false,
    disableInitialFetch,
  });
  const [result, setResult] = useState({
    data: defaultValue,
    apiState: "loading" as APIState,
    errorMsg: "",
    error: null as Error | null,
  });

  const _fetchAndUpdateState = useCallback(async () => {
    setResult({
      data: initialValueRef.current.defaultValue,
      apiState: "loading",
      errorMsg: "",
      error: null,
    });
    try {
      const data = await fetchFn();
      // console.log(cacheKey, "updating data");
      __cacheSingleton.set(cacheKey, data);
      // setResult({
      //   data,
      //   apiState: "idle",
      //   error: null,
      //   errorMsg: "",
      // });
      __updators.get(cacheKey)!.forEach((fn) => fn(data));
      return data;
    } catch (error) {
      setResult({
        data: initialValueRef.current.defaultValue,
        apiState: "error",
        errorMsg: (error as any).message,
        error: error as any,
      });
      return null;
    }
  }, [cacheKey, fetchFn]);

  const reFetch = useCallback(
    async (force?: boolean) => {
      let sameRequestQueue: Promise<T | null>;
      if (force) {
        __fetchQueue.delete(cacheKey);
      }
      if (__fetchQueue.has(cacheKey)) {
        sameRequestQueue = __fetchQueue
          .get(cacheKey)!
          .then(() => {
            // console.log(
            //   "cacheKey = %s, Reusing cached value in sameRequestQueue chain",
            //   cacheKey
            // );
            const data = __cacheSingleton.get(cacheKey) as T;
            setResult({
              data,
              apiState: "idle",
              error: null,
              errorMsg: "",
            });
            return data;
          })
          .catch(() => {
            return _fetchAndUpdateState();
          });
      } else {
        sameRequestQueue = _fetchAndUpdateState();
      }
      __fetchQueue.set(cacheKey, sameRequestQueue);
      return sameRequestQueue;
    },
    [cacheKey, _fetchAndUpdateState]
  );

  const triggerFetch = usePersistantCallbackRef(async (force?: boolean) => {
    if (force) {
      return reFetch(force);
    }
    const cachedValue = __cacheSingleton.get(cacheKey) as T | undefined;
    if (cachedValue) {
      setResult({
        data: cachedValue,
        apiState: "idle",
        error: null,
        errorMsg: "",
      });
    } else {
      // console.log(
      //   "cacheKey = %s, __fetchQueue?: %o",
      //   cacheKey,
      //   __fetchQueue.has(cacheKey)
      // );
      return reFetch(force);
    }
  });

  useEffect(() => {
    // skip, first time if disableInitialFetch is set
    if (initialValueRef.current.isMounted) {
      triggerFetch();
    }

    // only trigger fetch on when cacheKey changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheKey]);

  const handleSync = useCallback((data: T) => {
    // console.log("handleSync... new data", data);
    setResult({
      data,
      apiState: "idle",
      error: null,
      errorMsg: "",
    });
  }, []);

  useEffect(() => {
    // if (!__updators.has(cacheKey)) {
    //   __updators.set(cacheKey, []);
    // }
    __updators.set(cacheKey, [
      ...(__updators.get(cacheKey) ?? []),
      handleSync as any,
    ]);
    return () => {
      const updators = __updators.get(cacheKey);
      if (updators) {
        __updators.set(
          cacheKey,
          updators.filter((it) => it !== handleSync)
        );
      }
    };
  }, [cacheKey, handleSync]);

  useEffect(() => {
    initialValueRef.current.isMounted = true;
    if (!initialValueRef.current.disableInitialFetch) {
      triggerFetch();
    }
    return () => {
      // the object(ref) stored in .current is not changing
      // eslint-disable-next-line react-hooks/exhaustive-deps
      initialValueRef.current.isMounted = false;
    };
  }, [triggerFetch]);

  return {
    ...result,
    forceFetch: reFetch,
  };
}
