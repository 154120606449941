import { fetchUtil } from "./common";
interface BankDetails {
  bank_name: string;
  account_number: string;
  ifsc_code: string;
  swift_code: string | null;
  is_primary: boolean;
  bank_id: number;
  branch_name: string;
  bank_type_id: number;
}
export type AdminDetail = {
  udyam_regd_no?: string;
  company_name?: string;
  company_address_line1?: string;
  // company_address_line2?: string;
  company_city?: string;
  company_state?: number;
  company_state_name?: string;
  company_pincode?: number;
  company_email?: string;
  gst_registration_type?: number;
  gstin?: string | undefined;
  pan?: string;
  cin?: string;
  company_logo?: string;
  industry?: number;
  place_of_supply?: number;
  admin_name?: string;
  admin_phone?: string;
  company_website?: string;
  tan?: string;
  currency?: number;
  company_country?: string;
  bank_id?: number;
  bank_type_id?: number;
  bank_name?: string;
  account_number?: string;
  bank_mobile?: string;
  legal_name?: string;
  name?: string;
  gst_user_name?: any;

  // bank_other_number?: string;
  // branch_name?: string;
  // bank_website?: string;
  // branch_code?: string;
  // branch_address?: string;
  // bank_city?: string;
  // bank_state?: string;
  // bank_pincode?: number;
  ifsc_code?: string;
  swift_code?: string;
  admin_id?: number;
  admin_email?: string;
  crt_time?: Date;
  update_time?: Date;
  pf_registration_no?: string;
  ptax_registration_no?: string;
  esi_registration_no?: string;
  first_name?: string;
  last_name?: string;
  role_in_company?: number; // designation dp
  authorised_capital?: string;
  paid_up_capital?: string;
  pending_charges?: number;
  directors?: number;
  company_status?: string;
  logo?: any;
  address?: any;
  establishment_type: number;
  gstin_registration_date: Date;
  tan_verified?: boolean;
  bank_details: Array<BankDetails>;
};

export type STATEList = {
  id?: number;
  state_name?: string;
};

export type BankList = {
  account_number: string | undefined;
  bank_name: string | undefined;
  id: number | undefined;
};

export const getStates = async (): Promise<{
  ok: boolean;
  message: string;
  data?: Array<STATEList>;
}> => {
  const { ok, message, json } = await fetchUtil("GET", "/get_states");
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};

export const getBank = async (): Promise<{
  ok: boolean;
  message: string;
  data?: Array<BankList>;
}> => {
  const { ok, message, json } = await fetchUtil("GET", "/get_bank_accounts");
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};
