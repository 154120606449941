import React, { PureComponent } from "react";
import {
  Checkbox,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  notification,
  Drawer,
  Button,
} from "antd";
import { FormInstance } from "antd/lib/form";
import { isFuture, isBefore, isSameDay } from "date-fns";
import DatePicker from "~/component/antd-overrides/DatePicker";
import FieldInfo from "~/component/FieldInfo";
import {
  FORMAT_DATE,
  formatNumber,
  roundNumber,
  parseInputNumber,
  formatDateHourMinSec,
  formatYear,
  getCommaSeparated,
} from "~/lib";
import { payConfirm, PaymentStatus, PaymentMode } from "~/api/expense";
import { Expense } from "~/feature/expense/ExpenseModal";
import { APIState, getBank, BankList, fetchUtil } from "~/api/common";
import { required } from "~/lib/form_rules";
import commonStyle from "~/component/common.module.css";
import styles from "./PaymentDialog.module.css";
import { registerEvent } from "~/analytics";
import { getTxnAmount } from "~/api/expense";
import * as ga from "~/contants/gaConstants";
import VendorTransactions from "~/feature/vendor/VendorTransactions";
import { History } from "history";
import { months } from "~/lib/constants";
const { TextArea } = Input;
const { Option } = Select;

interface Props {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  expense: Expense;
  fetchData: () => void;
  placeOfSupply?: number;
  vendorPickList?: any;
  handleRipple: () => void;
  history?: History;
  selectedYear?: string;
  selectedMonth?: number;
}

type PaymentCaptureData = {
  invoice_id: number;
  transaction_id?: any;
  payment_date: any;
  paid_amount: number;
  status: PaymentStatus;
  tds_rate?: number;
  tds_amount?: number;
  bank_id?: number;
  notes?: string;
  pay_amount: number;
  conversion_rate: string;
  payment_mode: PaymentMode;
  cess_amount: number | string;
  extra?: {
    // extra field
    alreadyPaid: number;
    appliedTDS: boolean;
    subTotal?: number | null | string;
    netTotal?: number | string;
    gstAmount: number;
  };
};

interface State {
  panNumber: string;
  paymentData?: PaymentCaptureData;
  apiState: APIState;
  bankList: Array<BankList>;
  expenseAmount: number;
  showVendorTransactions: boolean;
  isReceivingBankEmpty: boolean;
  showConfirmationModal: boolean;
  selectedBank: string;
}

const OPTIONS_PAYMENT_MODE = [
  { value: 1, label: "Cash" },
  { value: 2, label: "Other(Bank)" },
];

export default class PaymentDialog extends PureComponent<Props, State> {
  state: State = {
    panNumber: "",
    expenseAmount: 0,
    paymentData: undefined,
    apiState: "loading",
    bankList: [],
    showVendorTransactions: false,
    isReceivingBankEmpty: false,
    showConfirmationModal: false,
    selectedBank: "",
  };

  _formRef = React.createRef<FormInstance<PaymentCaptureData>>();

  componentDidMount() {
    Promise.all([this.loadbanks()]).then(() => {
      this.setState({
        apiState: "idle",
      });
    });
    if (this.props.expense.customer_id) {
      this.getVendorDetails();
      this.fetchTxnAmount(
        this.props.expense.customer_id,
        formatDateHourMinSec(this.props.expense.receipt_date)
      );
    }
  }
  getVendorDetails = async () => {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_customer_details`,
      {
        customer_id: this.props.expense.customer_id,
        no_of_rows: 1,
        page_no: 1,
      }
    );

    if (ok) {
      const data = json[0];
      if (data) {
        this.setState({
          panNumber: data?.pan_number,
        });
      }
    } else {
      notification.error({ message });
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.expense !== this.props.expense) {
      this.updateFormUsingProps();
    }
  }
  fetchTxnAmount = async (id: any, date: any) => {
    const { ok, message, data } = await getTxnAmount(id, date);
    if (!ok) {
      notification.warn({
        message: "Failed to load summary",
        description: message,
      });
    } else {
      this.setState({
        expenseAmount: data?.expense[0]?.total_amount,
      });
    }
  };
  loadbanks = async () => {
    const response = await getBank();
    if (!response.ok) {
      notification.error({
        message: "Failed to load Bank List",
        description: response.message,
      });
      this.updateFormUsingProps();
    } else {
      const bankList = response.data as Array<BankList>;
      const isReceivingBankEmpty = bankList.length === 0;
      this.setState(
        {
          bankList: response.data!,
          isReceivingBankEmpty,
          selectedBank: `${bankList[0].bank_name} (${bankList[0].account_number})`,
        },
        () => {
          this.updateFormUsingProps();
        }
      );
    }
  };
  showVendorsDrawer = (record: any) => (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      `Expense Page > Show  transactions`
    );
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showVendorTransactions: true });
    if (this.props.selectedMonth) {
      this.props.history!.push(
        `/app/expense/transactions/${record}?month=${
          months[this.props.selectedMonth]
        }`,
        {
          month: this.props.selectedMonth,
        }
      );
    } else {
      {
        this.props.history!.push(
          `/app/expense/${this.props.expense.id}/transactions/${record}`
        );
      }
    }
  };
  onVendorDrawerClose = () => {
    const searchItem = this.props.history!.location.state as any;
    if (searchItem) {
      this.props.history!.push(
        `/app/expense?month=${months[searchItem.month]}`
      );
    } else {
      this.props.history!.push(`/app/expense/${this.props.expense.id}`);
    }
    this.setState({
      showVendorTransactions: false,
    });
  };
  updateFormUsingProps = async () => {
    const details = this.props.expense;
    const { igst, sgst, cgst } = details;

    let alreadyPaid = 0;
    if (this.props.expense.extra?.paymentDetails) {
      for (const entry of this.props.expense.extra?.paymentDetails
        .payment_history) {
        if (entry.payment_status !== "Cancelled") {
          alreadyPaid += entry.paid_amount;
        }
      }
    }

    // tslint:disable-next-line: variable-name
    const paid_amount: number = roundNumber(
      +details.net_total * +details.conversion_rate! -
        alreadyPaid -
        (details.tds_amount
          ? +details.tds_amount * details.conversion_rate!
          : 0)
    );

    const paymentData: PaymentCaptureData = {
      invoice_id: details.payment_receipts_id!,
      transaction_id: undefined,
      payment_date: undefined,
      paid_amount: roundNumber(Number(paid_amount)),
      status: details.status as PaymentStatus,
      tds_rate: details.tds_rate,
      tds_amount: details.tds_amount ? +details.tds_amount : 0,
      bank_id: this.state.bankList[0] ? this.state.bankList[0].id : undefined,
      notes: details.comment,
      pay_amount: roundNumber(
        +details.net_total - (details.tds_amount ? +details.tds_amount : 0)
      ),
      conversion_rate: "1.000000",
      payment_mode: PaymentMode.OTHER,
      cess_amount: details.cess_amount,
      extra: {
        alreadyPaid,
        appliedTDS: details.tds_rate,
        subTotal: details.subTotal
          ? details.subTotal
          : +details.net_total -
            ((igst ? +igst : 0) + (sgst ? +sgst : 0) + (cgst ? +cgst : 0)) -
            +details.cess_amount,
        netTotal: details.net_total,
        gstAmount: (igst ? +igst : 0) + (sgst ? +sgst : 0) + (cgst ? +cgst : 0),
      },
    };
    this.setState(
      {
        paymentData,
      },
      () => {
        this._formRef.current?.setFieldsValue(this.state.paymentData!);
      }
    );
  };

  onValuesChange = (
    changedValues: Partial<PaymentCaptureData>,
    values: PaymentCaptureData
  ) => {
    const details = this.props.expense;
    const { igst, sgst, cgst } = details;
    let shouldUpdateFormData = false;
    if (changedValues.tds_rate) {
      values.tds_amount = roundNumber(
        (+values.extra!.subTotal!
          ? +values.extra!.subTotal!
          : +details.net_total -
            ((igst ? +igst : 0) + (sgst ? +sgst : 0) + (cgst ? +cgst : 0)) -
            +details.cess_amount) *
          (changedValues.tds_rate / 100)
      );
      // console.log(values.tds_amount);
      values.pay_amount = roundNumber(
        (+values.extra!.subTotal!
          ? +values.extra!.subTotal!
          : +details.net_total -
            ((igst ? +igst : 0) + (sgst ? +sgst : 0) + (cgst ? +cgst : 0)) -
            +details.cess_amount) +
          (values.extra?.gstAmount || 0) +
          +this.props.expense.cess_amount -
          values.tds_amount
      );
      // console.log(values.pay_amount);
      // console.log(this.props.expense.conversion_rate);
      values.paid_amount = roundNumber(
        (values.pay_amount - (values.extra?.alreadyPaid || 0)) *
          Number(this.props.expense.conversion_rate)
      );
      shouldUpdateFormData = true;
    }

    if (changedValues.payment_mode === 1) {
      this._formRef.current?.validateFields();
      values.transaction_id = "";
      shouldUpdateFormData = true;
      values.bank_id = undefined;
    }
    if (changedValues.extra?.appliedTDS !== undefined) {
      if (!changedValues.extra?.appliedTDS) {
        values.tds_amount = 0;
        values.pay_amount =
          (+values.extra!.subTotal!
            ? +values.extra!.subTotal!
            : +details.net_total -
              ((igst ? +igst : 0) + (sgst ? +sgst : 0) + (cgst ? +cgst : 0)) -
              +details.cess_amount) +
          (values.extra?.gstAmount || 0) +
          +values.cess_amount;
        values.paid_amount = roundNumber(
          (values.pay_amount - (values.extra?.alreadyPaid || 0)) *
            details.conversion_rate!
        );
        values.tds_rate = 0;
        shouldUpdateFormData = true;
      } else if (values.tds_rate) {
        values.tds_amount =
          (+values.extra!.subTotal!
            ? +values.extra!.subTotal!
            : +details.net_total -
              ((igst ? +igst : 0) + (sgst ? +sgst : 0) + (cgst ? +cgst : 0)) -
              +details.cess_amount) *
          (values.tds_rate / 100);
        values.pay_amount =
          (+values.extra!.subTotal!
            ? +values.extra!.subTotal!
            : +details.net_total -
              ((igst ? +igst : 0) + (sgst ? +sgst : 0) + (cgst ? +cgst : 0)) -
              +details.cess_amount) +
          (values.extra?.gstAmount || 0) +
          +this.props.expense.cess_amount -
          values.tds_amount;
        values.paid_amount = roundNumber(
          (values.pay_amount - (values.extra?.alreadyPaid || 0)) *
            Number(this.props.expense.conversion_rate)
        );
        shouldUpdateFormData = true;
      }
    }
    this.setState(
      {
        paymentData: values,
      },
      () => {
        if (shouldUpdateFormData) {
          this._formRef?.current?.setFieldsValue(this.state.paymentData!);
        }
      }
    );
  };

  handleOk = async (_e: React.MouseEvent<HTMLElement>) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.paymentDialog97
    );

    this.setState({
      apiState: "loading",
    });
    try {
      await this._formRef.current?.validateFields();
      const data = {
        bank_id: this.state.paymentData!.bank_id!,
        notes: this.state.paymentData!.notes!,
        paid_amount: +(this.state.paymentData!.paid_amount || 0),
        pay_amount:
          Number(this.state.paymentData!.pay_amount.toFixed(2)) *
          this.props.expense?.conversion_rate!,
        payment_date: this.state.paymentData!.payment_date,
        payment_mode: this.state.paymentData!.payment_mode,
        status: this.state.paymentData!.status,
        tds_amount: this.state.paymentData!.tds_amount!,
        tds_rate: this.state.paymentData!.tds_rate!,
        transaction_id: this.state.paymentData!.transaction_id,
        expense_id: this.props.expense.id,
      };
      const { ok, message } = await payConfirm(data);
      this.props.fetchData();
      this.setState({
        apiState: "idle",
      });
      this.props.onVisibleChange(false);
      if (!ok) {
        notification.error({ message });
      } else {
        notification.success({ message });
        this.props.handleRipple();
      }
    } catch (err) {
      console.warn(err);
      this.setState({
        apiState: "idle",
      });
    }
  };
  handleReceivingBankDropdownVisibleChange = (open: boolean) => {
    const { isReceivingBankEmpty } = this.state;

    if (open && isReceivingBankEmpty) {
      // Show the confirmation modal since the bank list is empty
      this.setState({ showConfirmationModal: true });
    }
  };
  handleConfirm = async () => {
    // Close the confirmation modal
    this.setState({ showConfirmationModal: false });
    this.props.history?.push("/app/profile?tab=official");
  };
  handleCancelConfirmation = () => {
    // Close the confirmation modal
    this._formRef.current?.setFieldsValue({ payment_mode: 1 });
    // Reset the loading state if needed
    this.setState((prevState: any) => ({
      paymentData: {
        ...prevState.paymentData,
        payment_mode: PaymentMode.CASH, // Set the payment mode to "Cash" (1) when Cancel is clicked
      },
      apiState: "idle", // Reset the loading state if needed
      showConfirmationModal: false,
    }));
    this.setState({ apiState: "idle" });
  };
  handleCancel = (_e: React.MouseEvent<HTMLElement>) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.paymentDialog98
    );

    this.props.onVisibleChange(false);
  };

  numberConverter = (str: string) => {
    return str ? +str : 0;
  };

  handleNumberInputEvt = (evt: React.ChangeEvent<HTMLInputElement>) => {
    return parseInputNumber(evt.target.value, "");
  };

  handleBankNameChange = (val: number) => {
    //  console.log(val)
    const getSelectedBank = this.state.bankList.find((data) => data.id === val);
    // console.log(getSelectedBank)
    this.setState({
      selectedBank: `${getSelectedBank?.bank_name} (${getSelectedBank?.account_number})`,
    });
  };

  render() {
    const { visible, expense, selectedYear } = this.props;
    const { panNumber, expenseAmount } = this.state;
    const alreadyPaid = this.state.paymentData?.extra?.alreadyPaid || 0;
    const isPartiallyPaid = alreadyPaid > 0;
    const conversionRate = this.props.expense.conversion_rate;

    const viewConvertionDetails =
      this.props.expense.customer_id && this.props.expense.symbol_name !== "INR"
        ? `= paid amount: ${
            this.props.expense.customer_currency
          } ${formatNumber(Number(this.state.paymentData?.pay_amount))} (1 ${
            this.props.expense.symbol_name
          } =
          ${(1 * Number(this.props.expense?.conversion_rate)).toFixed(2)} INR)`
        : "";

    return (
      <>
        <Modal
          title="Record payment"
          visible={visible}
          confirmLoading={this.state.apiState === "loading"}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          centered
          destroyOnClose
          maskClosable={false}
        >
          <Form
            ref={this._formRef}
            initialValues={this.state.paymentData}
            onValuesChange={this.onValuesChange}
          >
            <Row gutter={{ xs: 40, sm: 100, md: 100, lg: 10, xl: 100 }}>
              <Col span={12}>
                <Form.Item name={["extra", "netTotal"]} label="Bill amount">
                  <Input readOnly />
                </Form.Item>
                <FieldInfo
                  text={`= Taxable: ${formatNumber(
                    this.state.paymentData?.extra?.subTotal
                      ? +this.state.paymentData?.extra?.subTotal
                      : 0
                  )} + GST: ${formatNumber(
                    this.state.paymentData?.extra?.gstAmount || 0
                  )}+ Cess: ${formatNumber(
                    +this.state.paymentData?.cess_amount!
                  )}`}
                />
                {expenseAmount ? (
                  <Form.Item>
                    <div
                      style={{
                        borderRadius: "5px",
                        color: "#828282!important",
                        fontSize: "12px!important",
                        /* border: 1px solid; */
                        /* padding: 4px; */
                        whiteSpace: "normal",
                        fontWeight: 600,
                        marginRight: "0px",
                        marginTop: "10px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                      className="ant-tag ant-tag-warning"
                    >
                      {`${getCommaSeparated(
                        +expenseAmount,
                        expense.customer_currency
                      )}`}{" "}
                      ( Total transaction amount in FY {selectedYear} with{" "}
                      {expense.customer_name
                        ? expense.customer_name
                        : expense.extra?.vendor?.trade_name}{" "}
                      (PAN {panNumber ? panNumber : `"N/A"`}) )<br />
                      <a
                        href="#"
                        style={{
                          color: "var(--antd-wave-shadow-color)",
                        }}
                        onClick={this.showVendorsDrawer(
                          expense.customer_id
                            ? expense.customer_id
                            : expense.extra?.vendor?.customer_id
                        )}
                      >
                        See all transactions
                      </a>
                    </div>
                  </Form.Item>
                ) : null}
                <Form.Item
                  name={["extra", "appliedTDS"]}
                  className={commonStyle["w-100"]}
                  valuePropName="checked"
                  style={{ marginTop: 10 }}
                >
                  <Checkbox disabled={isPartiallyPaid}>
                    <b>Apply TDS</b>
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="tds_rate"
                  label="TDS rate"
                  getValueFromEvent={this.numberConverter}
                  style={{ marginTop: -15 }}
                >
                  {isPartiallyPaid && this.state.paymentData?.tds_rate ? (
                    <Input readOnly />
                  ) : (
                    <Select
                      showSearch
                      disabled={!this.state.paymentData?.extra?.appliedTDS}
                    >
                      <Option value={1}>1.0% TDS</Option>
                      <Option value={2}>2.0% TDS</Option>
                      <Option value={5}>5.0% TDS</Option>
                      <Option value={7.5}>7.5% TDS</Option>
                      <Option value={10}>10.0% TDS</Option>
                      <Option value={15}>15.0% TDS</Option>
                      <Option value={20}>20.0% TDS</Option>
                      <Option value={30}>30.0% TDS</Option>
                      <Option value={40}>40.0% TDS</Option>
                      <Option value={50}>50.0% TDS</Option>
                    </Select>
                  )}
                </Form.Item>
                {this.state.paymentData?.extra?.appliedTDS &&
                this.state.paymentData?.tds_amount ? (
                  <FieldInfo
                    text={`= ${formatNumber(
                      this.state.paymentData?.tds_amount
                    )}`}
                  />
                ) : null}
                <Form.Item name="pay_amount" label="Total payable">
                  <Input readOnly />
                </Form.Item>
                <FieldInfo
                  text={`= Taxable: ${formatNumber(
                    this.state.paymentData?.extra?.subTotal
                      ? +this.state.paymentData?.extra?.subTotal
                      : 0
                  )} - TDS: ${formatNumber(
                    this.state.paymentData?.tds_amount || 0
                  )} + GST: ${formatNumber(
                    this.state.paymentData?.extra?.gstAmount || 0
                  )} + Cess: ${formatNumber(
                    +this.state.paymentData?.cess_amount!
                  )}`}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  name="payment_date"
                  label="Payment date"
                  rules={[required("Payment date")]}
                >
                  <DatePicker
                    // tslint:disable-next-line: jsx-no-lambda
                    disabledDate={(date) =>
                      isFuture(date) ||
                      (this.props.expense?.receipt_date &&
                        isBefore(date, this.props.expense.receipt_date) &&
                        !isSameDay(date, this.props.expense.receipt_date))
                    }
                    className={commonStyle["w-100"]}
                    format={FORMAT_DATE}
                  />
                </Form.Item>
                <Form.Item
                  name="transaction_id"
                  label="Transaction number"
                  rules={[
                    ({ getFieldsValue }) => {
                      const rule = {
                        required:
                          getFieldsValue(["payment_mode"]).payment_mode ===
                          PaymentMode.OTHER,
                        message: "Transaction number is required",
                      };
                      return rule;
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    disabled={
                      this.state.paymentData?.payment_mode !== PaymentMode.OTHER
                    }
                  />
                </Form.Item>
                <Form.Item
                  className={styles.fieldWithInfo}
                  name="paid_amount"
                  label="Amount paid on actual (INR)"
                  getValueFromEvent={this.handleNumberInputEvt}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_rule, value) {
                        const fvAlreadyPaid = getFieldValue([
                          "extra",
                          "alreadyPaid",
                        ]);
                        const payable = getFieldValue("pay_amount");

                        const remaning =
                          payable * +conversionRate! - fvAlreadyPaid;

                        if (value > roundNumber(remaning)) {
                          return Promise.reject(
                            `Cannot be greater than ${formatNumber(remaning)}`
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>
                <FieldInfo
                  text={viewConvertionDetails}
                  // text={`= paid amount: ${
                  //   this.props.expense.customer_currency
                  // } ${formatNumber(
                  //   Number(this.state.paymentData?.pay_amount)
                  // )} (1 ${this.props.expense.symbol_name} =
                  // ${(1 / Number(this.props.expense?.conversion_rate)).toFixed(
                  //   2
                  // )} INR)`}
                />
                <br />
                {this.state.paymentData?.extra?.alreadyPaid ? (
                  <FieldInfo
                    text={`Paid ${formatNumber(
                      this.state.paymentData.extra.alreadyPaid
                    )}`}
                  />
                ) : null}
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="payment_mode"
                  label="Payment mode"
                >
                  <Select showSearch>
                    {OPTIONS_PAYMENT_MODE.map((opt) => (
                      <Option key={opt.value} value={opt.value}>
                        {opt.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="bank_id"
                  label="Deducting bank"
                  rules={[
                    ({ getFieldsValue }) => ({
                      required:
                        getFieldsValue(["payment_mode"]).payment_mode ===
                        PaymentMode.OTHER,
                      message: "Please select a bank",
                    }),
                  ]}
                >
                  <Select
                    showSearch
                    disabled={
                      this.state.paymentData?.payment_mode !== PaymentMode.OTHER
                    }
                    onChange={(e: number) => this.handleBankNameChange(e)}
                    onDropdownVisibleChange={
                      this.handleReceivingBankDropdownVisibleChange
                    }
                  >
                    {this.state.bankList.map((bank, indx) => {
                      const lastDigits = bank?.account_number?.slice(
                        bank?.account_number.length - 4
                      );
                      return (
                        <>
                          <Select.Option key={indx} value={bank.id!}>
                            {bank.bank_name} ({lastDigits})
                          </Select.Option>
                        </>
                      );
                    })}
                  </Select>
                </Form.Item>
                <FieldInfo text={this.state.selectedBank} />
                <Form.Item name="notes" label="Comments">
                  <TextArea rows={1} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          visible={this.state.showConfirmationModal}
          title="Add bank details"
          onCancel={this.handleCancelConfirmation}
          footer={[
            <Button key="cancel" onClick={this.handleCancelConfirmation}>
              No
            </Button>,
            <Button key="confirm" type="primary" onClick={this.handleConfirm}>
              Yes
            </Button>,
          ]}
          style={{ top: "30%" }}
        >
          Adding bank details is necessary for payment purposes. Please click
          Yes to proceed.
        </Modal>
        {this.state.showVendorTransactions ? (
          <Drawer
            width={"55%"}
            placement="right"
            closable={false}
            onClose={this.onVendorDrawerClose}
            visible={this.state.showVendorTransactions}
            destroyOnClose={true}
          >
            <VendorTransactions
              customerid={expense.customer_id ? expense.customer_id : 0}
              customerName={
                expense.customer_name
                  ? expense.customer_name
                  : expense.extra?.vendor?.trade_name
              }
              vendorPageFy={
                this.props.selectedYear
                  ? this.props.selectedYear
                  : formatYear(expense.receipt_date)
              }
            />
          </Drawer>
        ) : (
          <></>
        )}
      </>
    );
  }
}
