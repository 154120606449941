import React, { PureComponent, createRef } from "react";
import { Modal, Form, Input, Select, notification, Button } from "antd";
import { fetchUtil } from "~/api/common";
import { FormInstance } from "antd/lib/form";
import { LoginRole } from "~/api/auth";
import commonStyle from "~/component/common.module.css";
import styles from "../EmployeePage.module.css";
import { connect, ConnectedProps } from "react-redux";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import { History } from "history";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";
import FieldWithAction from "~/component/FieldWithAction";

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  userDetails: state.mainAppSlice.user,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  setUserRole: (role_ids: any) => dispatch(action.auth.setUserRole(role_ids)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  empSaveHandeler: (value: boolean) => void;
  designationList: Array<{
    id: number;
    category: string;
  }>;
  reportsTo: Array<{
    id: number;
    category: string;
  }>;
  rolesList: Array<{
    id: number;
    role_name: string;
  }>;
  employeeSaveLoader: boolean;
  history?: History;
}
interface State {
  loadOk: boolean;
  newvisible: boolean;
  designationList: Array<any>;
  reportsTo: Array<any>;
}

class OnboardEmployeeModal extends PureComponent<Props, State> {
  state: State = {
    loadOk: false,
    designationList: [],
    reportsTo: [],
    newvisible: false,
  };

  async getDesignationList(name = "") {
    const { ok, json, message } = await fetchUtil(
      "GET",
      `/get_designation_category`
    );
    if (ok) {
      const data = json;
      this.setState({
        designationList: data,
      });
      if (name) {
        const option = data.find((it) => it.category === name);
        if (option) {
          this.formRef.current!.setFieldsValue({
            designation: option.id,
          });
        }
      }
    } else {
      notification.error({ message });
    }
  }

  async getReportsTo() {
    const { ok, json, message } = await fetchUtil("GET", `/get_reports_to`);
    if (ok) {
      const data = json;
      this.setState({
        reportsTo: data,
      });
    } else {
      notification.error({ message });
    }
  }

  componentDidMount() {
    this.getDesignationList();
    this.getReportsTo();
  }
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.newvisible !== this.state.newvisible) {
      this.addNewFormRef.current?.resetFields();
    }
  }

  formRef = createRef<FormInstance>();
  addNewFormRef = createRef<FormInstance>();

  logout = (key: string) => {
    notification.close(key);
    localStorage.clear();
    localStorage.clear();
    this.props.history!.push("/");
  };
  handleOk = (e: React.MouseEvent<HTMLElement>) => {
    if (!this.formRef.current) {
      return;
    }

    this.formRef.current
      .validateFields()
      .then(async (value) => {
        this.setState({
          loadOk: true,
        });
        const { ok, message, json } = await fetchUtil("POST", "/signup", {
          ...value,
          role_id: LoginRole.EMPLOYEE,
        });
        if (ok) {
          this.props.empSaveHandeler(false);

          this.setState(
            {
              loadOk: false,
            },
            () => {
              if (value.email === this.props.userDetails.email) {
                const key = `open${Date.now()}`;
                const btn = (
                  <Button
                    type="primary"
                    size="small"
                    onClick={(e: React.MouseEvent) => {
                      registerEvent(
                        ga.EVENT_CATEGORY_BUTTON_CLICK,
                        ga.EVENT_CLICK,
                        ga.events.onboardEmployeeModal71
                      );
                      this.logout(key);
                    }}
                  >
                    Log out
                  </Button>
                );
                notification.info({
                  message:
                    "You have deleted yourself. Please signout and login again to see the changes",
                  duration: 8,
                  btn,
                  key,
                });
              } else {
                notification.success({
                  message,
                });
              }
            }
          );
          const roles = json[0];
          this.props.setUserRole(roles as any);
        } else {
          this.props.empSaveHandeler(false);
          notification.error({
            message,
          });
          this.setState({
            loadOk: false,
          });
        }

        this.props.onVisibleChange(false);
      })
      .catch((reason) => {
        console.warn("Form error", reason);
      });
  };

  handleCancel = (_e: React.MouseEvent<HTMLElement>) => {
    this.props.onVisibleChange(false);
  };

  handleAddNewOk = (e: React.MouseEvent<HTMLElement>) => {
    if (!this.addNewFormRef.current) {
      return;
    }

    this.addNewFormRef.current
      .validateFields()
      .then(async (data) => {
        // console.log("handleAddNewOk > validateFields > data", data);
        this.setState({
          loadOk: true,
        });

        // this.setState({
        //   designationList: [
        //     ...this.state.designationList,
        //     { id: Math.random(), category: data.designation },
        //   ],
        // });

        const { ok, message, json } = await fetchUtil(
          "POST",
          "/add_designation",
          {
            name: data.designation,
          }
        );
        this.getDesignationList(data.designation);
        if (ok) {
          this.setState({
            loadOk: false,
            newvisible: false,
          });
        } else {
          notification.error({
            message,
          });
          this.setState({
            loadOk: false,
          });
        }

        this.setState({
          newvisible: false,
        });
      })
      .catch((reason) => {
        console.warn("Form error", reason);
      });
  };

  handleNewCancel = (_e: React.MouseEvent<HTMLElement>) => {
    this.setState({
      newvisible: false,
    });
  };

  handleNewSave = (data: any) => {
    // console.log("handleNewSave", data);
  };

  handleDesignation = () => {
    this.setState({
      newvisible: true,
      // designationList: [...this.state.designationList, {id:655645, category:"new"}]
    });
  };

  render() {
    const { visible } = this.props;
    const { newvisible } = this.state;

    return (
      <Modal
        title="Onboard employee"
        visible={visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className={styles.modalWidth}
        confirmLoading={this.state.loadOk}
        destroyOnClose
        maskClosable={false}
        centered
      >
        <Form ref={this.formRef}>
          <Form.Item
            className={commonStyle["w-100"]}
            name="first_name"
            label="First name"
            rules={[
              {
                type: "string",
                required: true,
                pattern: /^[a-zA-Z0-9]{2,}.*$/,
                message: "Please enter valid First name!",
              },
            ]}
          >
            <Input autoFocus placeholder={"e.g. John"} />
          </Form.Item>
          <Form.Item
            className={commonStyle["w-100"]}
            name="last_name"
            label="Last name"
            rules={[
              {
                type: "string",
                required: true,
                pattern: /^[a-zA-Z0-9]{2,}.*$/,
                message: "Please enter valid Last name!",
              },
            ]}
          >
            <Input placeholder={"e.g. Doe"} />
          </Form.Item>
          <Form.Item
            className={commonStyle["w-100"]}
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter valid Email id!",
              },
            ]}
          >
            <Input type="email" placeholder={"e.g. youremail@domain.com"} />
          </Form.Item>

          <FieldWithAction
            actionLabel="+ Add new"
            onAction={this.handleDesignation}
            className={commonStyle["w-100"]}
            label="Designation"
            name="designation"
            rules={[
              {
                required: true,
                // type: "number",
                message: "Please enter valid Designation!",
              },
            ]}
          >
            <Select id="chrome-off" showSearch optionFilterProp="children">
              {this.state.designationList.map((desig) => (
                <Select.Option key={desig.id} value={desig.id}>
                  {desig.category}
                </Select.Option>
              ))}
            </Select>
          </FieldWithAction>

          <Form.Item
            className={commonStyle["w-100"]}
            name="reports_to"
            label="Reports to"
            rules={[
              {
                // type: "string",
                required: false,
                // message: "Please select Reports to!",
              },
            ]}
          >
            <Select id="chrome-off" showSearch optionFilterProp="children">
              {this.state.reportsTo.map((desig) => (
                <Select.Option
                  key={desig.user_mapping_id}
                  value={desig.user_mapping_id}
                >
                  {desig.first_name + " " + desig.last_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Modal
            title="Add new row on dropdown"
            visible={newvisible}
            onOk={this.handleAddNewOk}
            onCancel={this.handleNewCancel}
            confirmLoading={this.state.loadOk}
          >
            <Form ref={this.addNewFormRef}>
              <Form.Item
                label="Add new designation"
                name="designation"
                rules={[
                  {
                    required: true,
                    type: "string",
                    pattern: /^[A-Za-z0-9 ]+$/,
                  },
                ]}
              >
                {/* <input
                    name="dropdown-row"
                    className="ant-input"
                  /> */}
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </Form>
      </Modal>
    );
  }
}

export default connector(OnboardEmployeeModal);
