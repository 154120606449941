import React, { ReactElement } from "react";
import { Switch } from "antd";

interface Props {
  value?: boolean;
  onChange?: (value: boolean) => void;
  readOnly?: boolean;
  disabled?: boolean;
}

export default function ApplyCess({
  value,
  onChange,
  readOnly,
  disabled,
}: Props): ReactElement {
  const handleChange = (checked: boolean) => {
    onChange?.(checked);
  };
  return (
    <Switch
      defaultChecked={false}
      onChange={handleChange}
      checked={value}
      disabled={readOnly || disabled}
    />
  );
}
