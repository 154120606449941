import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Layout, Row, Col, Typography } from "antd";
import { OnboardStoreState } from "~/app/OnboardCompany/store";
import IllustrationGirlInfluencer from "./IllustrationGirlInfluencer";
import OnboardSteps from "~/feature/onboarding/OnboardSteps";

import styles from "./OnboardingLayout.module.css";
import useGoogleAnalytics from "~/lib/hook/useGoogleAnalytics";

const mapStateToProps = (state: OnboardStoreState) => {
  const userId = state.onboard?.user?.id;
  const firstName = state.onboard?.user?.first_name;
  const lastName = state.onboard?.user?.last_name;
  return {
    userId,
    firstName,
    lastName,
    id: `${userId}+${firstName}+${lastName}`,
  };
};
const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  history: any;
}

const OnboardingLayout = (props: Props) => {
  useGoogleAnalytics(props.id);
  return (
    <Layout>
      <Row style={{ height: "60px" }}>
        <Col span={6}>
          <div
            style={{
              height: 60,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <img src="/logo@1x.png" width={70} alt="imt-logo" />
          </div>
        </Col>
        <Col span={6} />
        <Col span={6} />
        <Col span={6} />
      </Row>
      <Row className={styles.contentRow} gutter={{ md: 64, lg: 100 }}>
        <Col
          span={11}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            // marginTop: "74px",
            textAlign: "center",
            padding: 30,
          }}
        >
          <Typography.Title level={2} style={{ width: "54%" }}>
            Simplify your business operations and compliances
          </Typography.Title>
          <div className={styles.illustrationWrap}>
            <IllustrationGirlInfluencer className={styles.illustration} />
          </div>
        </Col>
        <Col span={12}>
          <OnboardSteps
          // history={this.props.history}
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default connector(OnboardingLayout);
