import React, { useEffect } from "react";
import { Switch, Route } from "react-router";
import MainLayout from "~/layout/main-layout/MainLayout";
import AppRootPage from "~/feature/app-root/AppRootPage";
import ProfilePage from "~/feature/Profile/ProfilePage";

import ExpensePage from "~/feature/expense/ExpensePage";
import ExpenseDetailsPage from "~/feature/expense/expense-detail/ExpenseDetailsPage";
import ExpenseBulkCreatePage from "~/feature/expense/bulk-create/ExpenseBulkCreatePage";

import CompliancePageNew from "~/feature/new-compliance/CompliancePage";
import ReimbursementPage from "~/feature/reimbursement/ReimbursementPage";
import ReimbursementDetailsPage from "~/feature/reimbursement/ReimbursementDetailsPage";
import WIPPage from "~/component/WIPPage";
import NotFoundPage from "~/component/NotFoundPage";
import EmployeePage from "~/feature/employee/EmployeePage";
import EmployeeDetailsPage from "~/feature/employee/EmployeeDetailsPage";
import TermsAndConditionsPage from "~/feature/employee/TermsAndConditions";
import SalaryTemplate from "~/feature/payroll/SalaryTemplate";
import VendorPage from "~/feature/vendor/VendorPage";
import PaymentPage from "~/feature/payment/PaymentPage";
import ProductMaster from "~/feature/productMaster/ProductMaster";
import ExpenseCategory from "~/feature/expenseCategory/ExpenseCategory";
import PaymentDetailsPage from "~/feature/payment/PaymentDetailsPage";
import VendorDetailsPage from "~/feature/vendor/VendorDetailsPage";
import InvoicePage from "~/feature/invoice/InvoicePage";
import CreditNotePage from "~/feature/invoice/CreditNotePage";
import DebitNotePage from "~/feature/invoice/DebitNotePage";
import PurcheserDebitPage from "~/feature/expense/PurcheserDebitPage";
import SalerDebitPage from "~/feature/expense/SalerDebitPage";
import InvoiceDetailsPage from "~/feature/invoice/invoice-details/InvoiceDetailsPage";
import SettingsRole from "~/feature/settings-role/SettingsRole";
import SettingsUsers from "~/feature/settings-users/SettingsUsers";
import SettingsMembership from "~/feature/settings-membership/SettingsMembership";
import Synchronize from "~/feature/synchronize/Synchronize";
import Unauthorized from "~/feature/unauthorized";
import ExpiredMembership from "~/feature/membership/ExpiredMembership";
import Tour from "reactour";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import loadable from "@loadable/component";
import FullpageSpinner from "~/component/FullpageSpinner";

import usePrevProps from "~/lib/hook/usePrevProps";
import useGoogleAnalytics from "~/lib/hook/useGoogleAnalytics";
import FirstTimeLoginSuccessModal from "~/feature/dashboard/FirstTimeLoginSuccessModal";
import SourceSelectionModal from "~/feature/synchronize/SourceSelectionModal";
import CreditNoteDetailsPage from "~/feature/invoice/creditnote/CreditNotePage";
import DebitNoteDetailsPage from "~/feature/invoice/debitnote/DebitNotePage";
import PurchesDebitDetail from "~/feature/expense/purchesDebitNote/PurchesDebitDetail";
import SalerDebitNoteDetail from "~/feature/expense/salerDebitNote/SalerDebitNoteDetail";
import MembershipPlan from "~/feature/membership/MembershipPlan";
import SyncRowDetails from "~/feature/synchronize/SyncRowDetails";
import SyncSocketData from "~/component/SyncSocketData";

const LazyDashboardPage = loadable(
  () => import("~/feature/dashboard/DashboardPage"),
  {
    fallback: <FullpageSpinner />,
  }
);
const LazyPayrollBulkSalaryPage = loadable(
  () => import("~/feature/payroll/PayrollBulkSalaryPage"),
  {
    fallback: <FullpageSpinner />,
  }
);
const LazyPayrollPage = loadable(
  () => import("~/feature/payroll/PayrollPage"),
  {
    fallback: <FullpageSpinner />,
  }
);

const LazyPayrollSingleSalaryPage = loadable(
  () => import("~/feature/payroll/PayrollSingleSalaryPage"),
  { fallback: <FullpageSpinner /> }
);
const LazyPayrollSalaryDetailsPage = loadable(
  () => import("~/feature/payroll/PayrollDetail"),
  { fallback: <FullpageSpinner /> }
);

const mapStateToProps = (state: AuthState) => {
  const userId = state.mainAppSlice.user.id;
  let firstName = state.mainAppSlice.user.first_name;
  let lastName = state.mainAppSlice.user.last_name;
  if (firstName) {
    const userDataString = localStorage.getItem("imt__user");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      firstName = userData.first_name;
      lastName = userData.last_name;
    }
  }
  return {
    activeRole: state.mainAppSlice.user.activeRole,
    id: `${userId}+${firstName}+${lastName}`,
    userId,
    firstName,
    lastName,
    saveEmpDetails: state.mainAppSlice.user.save_emp_details,
    steps: state.mainAppSlice.steps,
    showSyncButtonTour: state.mainAppSlice.showSyncButtonTour,
    currentStepKey:
      state.mainAppSlice.steps[state.mainAppSlice.currentTourStep]?.key,
    connectSocket: state.mainAppSlice.connectSocket,
  };
};
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  createCompanyDetails: (company_name: any) =>
    dispatch(action.auth.createCompanyDetails(company_name)),
  updateStore: () => dispatch(action.auth.updateStore()),
  controlAppTour: (showSyncButtonTour: boolean) =>
    dispatch(action.auth.controlAppTour(showSyncButtonTour)),
  setCurrentStep: (currentStep: number) =>
    dispatch(action.auth.setTourStep(currentStep)),
  showFirstTimeLoginModal: () =>
    dispatch(action.modal.showFirstTimeLoginModal()),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
// it's required for later
interface Props extends PropsFromRedux, RouteComponentProps {}

const MainApp = (props: Props) => {
  useGoogleAnalytics(props.id);
  const prevProps = usePrevProps(props);

  useEffect(() => {
    if (prevProps.showSyncButtonTour !== props.showSyncButtonTour) {
      props.controlAppTour(props.showSyncButtonTour);
    }
  }, [prevProps, props]);

  // useEffect(() => {
  //   const userData = localStorage.getItem("imt__user");
  //   const userParseData = JSON.parse(userData!);
  //   SyncSocketData(userParseData.company_id);
  // }, []);

  const closeTour = () => {
    props.controlAppTour(false);
    props.showFirstTimeLoginModal();
  };
  const userData = localStorage.getItem("imt__user");
  const userParseData = JSON.parse(userData!);
  return (
    <div id="main-app">
      <MainLayout>
        <Switch>
          {/* <Redirect from="/app" to="/app/dashboard" exact /> */}
          <Route path="/app" component={AppRootPage} exact />
          <Route path="/app/profile:tab?" component={ProfilePage} />
          <Route path="/app/settings-role" component={SettingsRole} />
          <Route path="/app/settings-users" component={SettingsUsers} />
          <Route path="/app/settings-users" component={SettingsUsers} />

          <Route path="/app/dashboard" component={LazyDashboardPage} />
          <Route path="/app/employee" exact component={EmployeePage} />
          <Route
            path="/app/employee/:slug(me)"
            component={EmployeeDetailsPage}
            exact
          />
          <Route
            path="/app/employee/:id([0-9]+)/:emp_name"
            component={EmployeeDetailsPage}
            exact
          />
          <Route
            path="/app/employee/terms-and-conditions"
            component={TermsAndConditionsPage}
            exact
          />
          <Route
            path="/app/employee/salary-structure-template"
            component={SalaryTemplate}
            exact
          />
          <Route path="/app/expense" exact component={ExpensePage} />
          <Route
            path="/app/expense/transactions/:customerId(\d+)"
            exact
            component={ExpensePage}
          />
          <Route
            path="/app/expense/:id([0-9]+)/transactions/:customerId(\d+)"
            exact
            component={ExpenseDetailsPage}
          />
          <Route
            path="/app/expense/:id([0-9]+)"
            exact
            component={ExpenseDetailsPage}
          />
          <Route path="/app/expense/new" exact component={ExpenseDetailsPage} />

          <Route
            path="/app/reimbursement"
            exact
            component={ReimbursementPage}
          />
          <Route
            path="/app/reimbursement/:id([0-9]+)"
            exact
            component={ReimbursementDetailsPage}
          />

          <Route
            path="/app/reimbursement/new"
            exact
            component={ReimbursementDetailsPage}
          />
          <Route path="/app/payroll" component={LazyPayrollPage} />
          <Route
            path="/app/single-salary/:empId?"
            component={LazyPayrollSingleSalaryPage}
          />
          <Route
            path="/app/salary-detail/:empId/:month?/:year?"
            component={LazyPayrollSalaryDetailsPage}
          />
          <Route
            path="/app/bulk-salary"
            component={LazyPayrollBulkSalaryPage}
          />
          <Route
            path="/app/compliance-new"
            exact
            component={CompliancePageNew}
          />
          <Route
            path="/app/compliance-new/:fyYear/:activeList?/:compianceName?/:month?"
            exact
            component={CompliancePageNew}
          />
          <Route
            path="/app/invoice/creditNote"
            exact
            component={CreditNotePage}
          />
          <Route path="/app/payment" exact component={PaymentPage} />
          <Route path="/app/payment/new" exact component={PaymentDetailsPage} />

          <Route
            path="/app/masters/productMaster"
            exact
            component={ProductMaster}
          />

          <Route
            path="/app/masters/expenseCategory"
            exact
            component={ExpenseCategory}
          />

          <Route path="/app/masters/vendor" exact component={VendorPage} />
          <Route
            path="/app/masters/vendor/transactions/:customerId(\d+)"
            exact
            component={VendorPage}
          />
          <Route
            path="/app/masters/vendor/:id([0-9]+)"
            component={VendorDetailsPage}
          />
          <Route
            path="/app/masters/vendor/new"
            exact
            component={VendorDetailsPage}
          />
          <Route path="/app/invoice" exact component={InvoicePage} />
          <Route
            path="/app/invoice/creditNote"
            exact
            component={CreditNotePage}
          />
          <Route
            path="/app/settings-membership"
            exact
            component={SettingsMembership}
          />
          <Route path="/app/membership-plan" exact component={MembershipPlan} />

          <Route
            path="/app/invoice/debitNote"
            exact
            component={DebitNotePage}
          />
          <Route
            path="/app/expense/purchasedebitNote"
            exact
            component={PurcheserDebitPage}
          />
          <Route
            path="/app/expense/sellerdebitNote"
            exact
            component={SalerDebitPage}
          />
          <Route
            path="/app/invoice/:id([0-9]+)"
            exact
            component={InvoiceDetailsPage}
          />
          <Route
            path="/app/invoice/:slug(new|copy)"
            exact
            component={InvoiceDetailsPage}
          />
          <Route
            path="/app/invoice/:id([0-9]+)/creditnote/:crId([0-9]+)"
            exact
            component={CreditNoteDetailsPage}
          />
          <Route
            path="/app/invoice/creditnote/:crId([0-9]+)"
            exact
            component={CreditNoteDetailsPage}
          />
          <Route
            path="/app/invoice/debitnote/:drId([0-9]+)"
            exact
            component={DebitNoteDetailsPage}
          />
          <Route
            path="/app/invoice/:id([0-9]+)/creditnote/:slug(new)"
            exact
            component={CreditNoteDetailsPage}
          />
          <Route
            path="/app/invoice/creditNote/:slug(new)"
            exact
            component={CreditNoteDetailsPage}
          />
          <Route
            path="/app/invoice/debitNote/:slug(new)"
            exact
            component={DebitNoteDetailsPage}
          />
          <Route
            path="/app/invoice/:id([0-9]+)/debitnote/:drId([0-9]+)"
            exact
            component={DebitNoteDetailsPage}
          />
          <Route
            path="/app/invoice/:id([0-9]+)/debitnote/:slug(new)"
            exact
            component={DebitNoteDetailsPage}
          />

          <Route
            path="/app/expense/:slug(new|copy)"
            exact
            component={ExpenseDetailsPage}
          />
          <Route
            path="/app/expense/bulk-create"
            exact
            component={ExpenseBulkCreatePage}
          />
          <Route
            path="/app/expense/:id([0-9]+)/purchasedebitNote/:drId([0-9]+)"
            exact
            component={PurchesDebitDetail}
          />
          <Route
            path="/app/expense/:id([0-9]+)/purchasedebitNote/:slug(new)"
            exact
            component={PurchesDebitDetail}
          />
          <Route
            path="/app/expense/purchasedebitNote/:drId([0-9]+)"
            exact
            component={PurchesDebitDetail}
          />
          <Route
            path="/app/expense/purchasedebitNote/:slug(new)"
            exact
            component={PurchesDebitDetail}
          />
          <Route
            path="/app/expense/sellerdebitNote/:slug(new)"
            exact
            component={SalerDebitNoteDetail}
          />

          <Route
            path="/app/expense/:id([0-9]+)/sellerdebitNote/:sdrId([0-9]+)"
            exact
            component={SalerDebitNoteDetail}
          />
          <Route
            path="/app/expense/sellerdebitNote/:sdrId([0-9]+)"
            exact
            component={SalerDebitNoteDetail}
          />
          <Route
            path="/app/expense/:id([0-9]+)/sellerdebitNote/:slug(new)"
            exact
            component={SalerDebitNoteDetail}
          />
          <Route
            path="/app/synchronize/:isSync/:month?/:year?"
            exact
            component={Synchronize}
          />
          <Route
            path="/app/synchronize/:isSync/:processId([0-9]+)/:isOpenDraw"
            component={SyncRowDetails}
          />

          <Route path="/app/unauthorized" component={Unauthorized} />
          <Route path="/app/expiredMembership" component={ExpiredMembership} />
          <Route path="/app/settings" component={WIPPage} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </MainLayout>
      <Tour
        onRequestClose={closeTour}
        closeWithMask={false}
        maskClassName="mask"
        steps={props.steps}
        isOpen={props.showSyncButtonTour}
        getCurrentStep={props.setCurrentStep}
        updateDelay={600}
        update={
          ["profile", "invoice", "expense", "masters"].includes(
            props.currentStepKey
          )
            ? Math.random().toString()
            : ""
        }
        showNavigationNumber={false}
        showNavigation={true}
        showButtons={true}
        rounded={15}
        showNumber={false}
        maskSpace={4}
        showCloseButton={false}
        children={
          <div
            onClick={closeTour}
            style={{
              position: "absolute",
              right: "10px",
              top: "8px",
              cursor: "pointer",
              border: "1px solid",
              padding: "0px 5px",
              borderRadius: "6px",
              color: "var(--reactour-accent)",
              fontSize: "10px",
            }}
          >
            {props.currentStepKey === "settings" ? "Close" : "Skip"}
          </div>
        }
      />
      <FirstTimeLoginSuccessModal />
      <SourceSelectionModal />
      {props.connectSocket === true ? (
        <SyncSocketData companyid={userParseData?.company_id || null} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default connector(MainApp);
