import React from "react";
import socketIo from "socket.io-client";
import { useDispatch } from "react-redux";
import { action } from "~/app/MainApp/store";

const SyncSocketData = (props: any) => {
  const EndPoint: any = process.env.REACT_APP_WS_ENDPOINT;
  // const EndPoint = `ws://localhost:8001`;
  // const EndPoint = `wss://imt-dev.thetaone.review`
  const socket = socketIo(EndPoint, { transports: ["websocket"] });
  const dispatch = useDispatch();
  const { modal, auth } = action;

  // const [ioData, setIoData] = useState({});

  socket.on("connect", () => {
    // console.log("socket connected");
    dispatch(auth.handleReconnectSocket());
  });

  // console.log(
  //   "Adding socket.io listener for:",
  //   `company/${props.companyid}/etl`
  // );
  socket.on(`company/${props.companyid}/etl`, (data: any) => {
    // console.log(data, "++++++++++++++++++++++++++++");

    dispatch(modal.setSocketData(data));

    // setIoData({ data });
    // hi(data)
  });
  //   socket.on("error", (err: any) => {
  //   console.log( "err",err);
  // });
  // socket.on("disconect", (reason: any) => {
  //   console.log("reason",reason);
  // });
  // socket.on("progress", (data: any) => {
  //   console.log("progress", data);
  // });
  // socket.disconnect()

  return <></>;
};

export default SyncSocketData;
