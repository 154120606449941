import React, { PureComponent } from "react";
import { Button, notification, Typography, Select } from "antd";
import GenericLogo from "~/assets/Logo/genericLogo.png";
import styles from "./Iconstyle.module.css";
import { registerEvent } from "~/analytics";
import OnlyAttachField from "~/fragment/attachable-field/OnlyAttachField";
import { fetchUtil } from "~/api/common";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { action } from "~/app/MainApp/store";
import commonStyle from "~/component/common.module.css";
import { getSources } from "~/api/common";
import MyntraLogo from "~/assets/Logo/myntra.png";
import FirstCryLogo from "~/assets/Logo/FirstCry.png";
import NaykaaLogo from "~/assets/Logo/Naykaa.png";
import UdaanLogo from "~/assets/Logo/Udaan.png";
import TataCliqLogo from "~/assets/Logo/TataCliq.png";
import BulbulLogo from "~/assets/Logo/Bulbul.png";
import AjioLogo from "~/assets/Logo/Ajio.png";
import PharmeasyLogo from "~/assets/Logo/Pharmeasy.png";
import SnapdealLogo from "~/assets/Logo/Snapdeal.png";
import ShopcluesLogo from "~/assets/Logo//Shopclues.png";
import BigBasketLogo from "~/assets/Logo/BigBasket.png";
import IndustrybuyingLogo from "~/assets/Logo/Industrybuyinglogo.jpg";
import MoglixLogo from "~/assets/Logo/MoglixLogo.webp";

const LITE_SAMPLE_S3_LINK =
  "https://public-sample-2.s3.ap-south-1.amazonaws.com/Cheqd-generic-format-lite.xlsx";
const DETAILED_SAMPLE_S3_LINK =
  "https://public-sample-2.s3.ap-south-1.amazonaws.com/Cheqd-generic-format-detailed.xlsx";

interface Props {
  handleBackPress?: () => void;
  erroMsg?: string;
  talyStart?: boolean;
  closeModal: () => void;
  viewSyncModal?: (title: string, logo: string, style: string) => void;
  source: any;
}

interface State {
  showTallyStart: boolean;
  openVideoPlayer: boolean;
  tallyConnectorDownloadLink: string;
  dataFile: string;
  genericSources: any;
  genericValue: any;
}

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  userId: state.mainAppSlice.user.id,
  saveEmpDetails: state.mainAppSlice.user.save_emp_details,
});

const mapDispatchToProps = {
  hideStartSyncFromSourceModal: action.modal.hideStartSyncFromSourceModal,
  showSyncStatusModal: action.modal.showSyncStatusModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
// tslint:disable-next-line
interface Props extends PropsFromRedux {}

class CleartaxXlsSync extends PureComponent<Props, State> {
  state: State = {
    showTallyStart: false,
    openVideoPlayer: false,
    tallyConnectorDownloadLink: "",
    dataFile: "",
    genericSources: [],
    genericValue: null,
  };

  // latestInterval: any = null;

  async componentDidMount() {
    // this.tallyAuth()
    this.getGenericSources();
  }

  getGenericSources = async () => {
    // debugger
    const response = await getSources();
    if (!response.ok) {
      notification.error({
        message: "Failed to load Generic Sources",
        description: response.message,
      });
    } else {
      this.setState({
        genericSources: response.data,
      });
    }
    const genericOption = this.state.genericSources.find((el: any) => {
      return el.name === "GenericXls";
    });
    if (genericOption) {
      this.setState({
        genericValue: genericOption.id,
      });
    }
  };

  startSync = async () => {
    try {
      if (!this.state.genericValue) {
        notification.error({ message: "Please select a generic source" });
        return;
      }
      if (!this.state.dataFile) {
        notification.error({ message: "Please attach a document" });
        return;
      }

      const selectedGenericName = this.state.genericSources.find(
        (el: any, idx: number) => el.id === this.state.genericValue
      );

      const { ok } = await fetchUtil("POST", "/xls_start_sync", {
        filename: this.state.dataFile,
        type: "XLS",
        format:
          this.state.genericValue === 9
            ? "GenericXls"
            : selectedGenericName.name,
      });
      if (ok) {
        // If import started successfully
        this.props.showSyncStatusModal();
        this.props.hideStartSyncFromSourceModal();
      } else {
        notification.error({ message: "Failed to start sync" });
      }
    } catch (err) {
      notification.error({ message: "Failed to start sync" });
    }
  };

  goback = (e: React.MouseEvent) => {
    registerEvent("Button click", "click", (e.target as any).innerHTML);
    this.props.handleBackPress!();
  };

  handleDataFileChange = (newFileName: string) => {
    this.setState({ dataFile: newFileName });
  };

  handleGenericDropdownChange = (value: number) => {
    this.setState({
      genericValue: value,
    });
  };

  render() {
    const { Option } = Select;
    const logo = [
      {
        title: "Generic",
        src: GenericLogo,
      },
      {
        title: "TataCliq",
        src: TataCliqLogo,
      },
      {
        title: "Myntra",
        src: MyntraLogo,
      },
      {
        title: "Udaan",
        src: UdaanLogo,
      },
      {
        title: "Ajio",
        src: AjioLogo,
      },
      {
        title: "FirstCry",
        src: FirstCryLogo,
      },
      {
        title: "Bulbul",
        src: BulbulLogo,
      },
      {
        title: "Nykaa",
        src: NaykaaLogo,
      },
      {
        title: "Pharmeasy",
        src: PharmeasyLogo,
      },
      {
        title: "Snapdeal",
        src: SnapdealLogo,
      },
      {
        title: "Shopclues",
        src: ShopcluesLogo,
      },
      {
        title: "BigBasket",
        src: BigBasketLogo,
      },
      {
        title: "Industrybuying",
        src: IndustrybuyingLogo,
      },
      {
        title: "Moglix",
        src: MoglixLogo,
      },
    ];
    return (
      <>
        <div style={{ textAlign: "center", height: 340 }}>
          <>
            <img src={GenericLogo} alt="" className={styles.talyIcon} />

            <Typography.Title
              level={4}
              style={{
                textAlign: "center",
                marginBottom: "0.8rem",
              }}
            >
              <b className={styles.sourceheading}>{this.props.source.label}</b>
            </Typography.Title>
            <hr />
            <Typography.Title level={4} style={{ fontSize: 10 }}>
              {" "}
              Download sample:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={LITE_SAMPLE_S3_LINK}
              >
                Lite format
              </a>{" "}
              |{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={DETAILED_SAMPLE_S3_LINK}
              >
                Detailed format
              </a>
            </Typography.Title>
            <hr />
            <Select
              size="small"
              className={commonStyle["w-50"]}
              value={this.state.genericValue}
              showSearch
              optionFilterProp="children"
              placeholder="Select Source"
              suffixIcon={
                <svg
                  width="10"
                  height="8"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 16L19.5263 0.25H0.473721L10 16Z"
                    fill="#4F4F4F"
                  />
                </svg>
              }
              onSelect={this.handleGenericDropdownChange}
            >
              {this.state.genericSources.map((el: any, idx: any) => {
                return (
                  <>
                    <Option key={el.id} value={el.id}>
                      <img
                        src={logo[idx]?.src}
                        className={styles.logo}
                        alt=""
                      />
                      {/* {el.name} */}
                      {logo[idx]?.title}
                    </Option>
                  </>
                );
              })}
            </Select>
            <div>
              <OnlyAttachField
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                centered={true}
                fieldName="data_file"
                hashFileName={this.state.dataFile}
                onHashFileChange={this.handleDataFileChange}
                label="Upload data file (Max size: 50 MB)"
                readonly={true}
                className={styles.attach}
                rules={[
                  {
                    type: "string",
                    required: false,
                    message: "Please upload a valid data file",
                  },
                ]}
              />
            </div>

            <div className={styles.buttondiv}>
              <Button
                onClick={this.startSync}
                style={{ width: "156px" }}
                type="primary"
              >
                Start sync
              </Button>
            </div>
            <div className={styles.Tallyback}>
              <Button
                type="text"
                onClick={this.goback}
                disabled={this.props.talyStart}
              >
                Back
              </Button>
            </div>
          </>
        </div>
      </>
    );
  }
}

export default connector(CleartaxXlsSync);
