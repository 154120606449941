import React, { Component } from "react";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import styles from "./SettingsMembership.module.css";

import { notification, Row, Spin, Table, Typography } from "antd";
import { getExistingPlanFeature, getPlanFeature } from "~/api/license";
import Currency from "~/component/Currency";
import Illustration2 from "~/layout/landing-page/Illustration2";
import Illustration3 from "~/layout/landing-page/Illustration3";
/* import { formatDate } from "~/lib"; */
import { DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import { ColumnType } from "antd/lib/table";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import { connect, ConnectedProps } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { fetchBlob, fetchUtil } from "~/api/common";
import { AuthState, StoreDispatch } from "~/app/MainApp/store";
import { titles } from "~/contants/titles";
import { RenderPlanSingle } from "../membership/PlanSingle";
import moment from "moment";

interface State {
  activeLicense: boolean;
  activeTerm: string;
  isAnnual: boolean;
  isMonthly: boolean;
  planList: any;
  myPlan: any; // extra data coming from API
  isLoading: boolean;
  alreadyCancelled: boolean;
}

const mapStateToProps = (state: AuthState) => ({
  userData: state.mainAppSlice.user,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux, RouteChildrenProps {}

class SettingsMembership extends Component<Props, State> {
  state: State = {
    activeLicense: false,
    activeTerm: "ANNUAL",
    isAnnual: false,
    isMonthly: false,
    planList: [],
    myPlan: {},
    isLoading: true,
    alreadyCancelled: false,
  };

  loadPlan = async () => {
    const allPlanResponse: any = await getPlanFeature();
    const myPlanResponse: any = await getExistingPlanFeature();

    if (!allPlanResponse.ok || !myPlanResponse.ok) {
      console.warn(allPlanResponse.message, myPlanResponse.message);
      notification.warning({
        message: `Failed to load data`,
      });
    } else {
      const planList = allPlanResponse.json;
      const myPlan = myPlanResponse.json[0];

      myPlan.purchase_date = new Date(myPlan?.purchase_date);
      myPlan.expiry_date = new Date(myPlan.expiry_date);

      planList.forEach((plan: any) => {
        plan.licenses.forEach((license: any) => {
          license.term = license.period === "30 days" ? "MONTHLY" : "ANNUAL";
        });
      });

      const activePlan =
        planList.find((it: any) => it.name === myPlan.plan_name) || {};
      const activeLicense =
        activePlan?.licenses?.find(
          (it: any) => it.plan_id === myPlan.plan_id
        ) || {};

      activePlan.is_active_plan = true;
      activeLicense.is_active_plan = true;

      const activeTerm = activeLicense.term;
      const alreadyCancelled = myPlan?.already_cancelled;

      this.setState({
        activeTerm,
        planList,
        myPlan,
        isLoading: false,
        alreadyCancelled,
      });
    }
  };
  componentDidMount() {
    this.loadPlan();
  }

  cancelSubscription = async (subscriptionId: any) => {
    confirm({
      title: "Alert! cancelling subscription!",
      icon: <ExclamationCircleOutlined />,
      content:
        "After cancellation, you will still be able to use your account for the current billing period. Post that, you will no longer be able to access your account.",
      onOk: async () => {
        const { ok /* ,message,json */ } = await fetchUtil(
          "POST",
          "/cancel_subscription",
          { subscription_id: subscriptionId }
        );
        if (ok) {
          notification.success({
            message: "Subscription cancelled successfully.",
          });
          await this.loadPlan();
          return;
          /* this.setState({ alreadyCancelled: true });
          this.setState({myPlan: {...this.state.myPlan,payment_history:[]}})*/
        } else {
          notification.error({
            message: "Error while cancelling Subscription.",
            duration: 2,
          });
        }
      },
      onCancel: () => null,
    });
  };

  downloadInvoice(invoiceId: number | null) {
    fetchBlob("/download_invoice", {
      invoice_id: invoiceId,
    })
      .then(({ ok, message, blob }) => {
        if (ok) return blob;
        else return null;
      })
      .then((res) => {
        if (res) {
          const blobObj = new Blob([res], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blobObj);
          const printWin: any = window.open(url);
          printWin.print();
        } else {
          notification.error({
            message: "Invoice download error.",
          });
        }
      });
  }
  render() {
    const myPlan = this.state.myPlan;
    const paymentHistory = myPlan?.payment_history || [];

    const columns: Array<ColumnType<any>> = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        align: "center",

        ellipsis: true,
        render: (data) => ({
          children: data ?? "--",
        }),
      },
      {
        title: "Payment method",
        dataIndex: "method",
        key: "method",
        align: "center",

        render: (data) => ({
          children: data ?? "--",
        }),
      },
      {
        title: "Subscription id",
        dataIndex: "subscription_id",
        key: "subscription_id",
        align: "center",
        width: 200,
        ellipsis: false,
        render: (record) => {
          return {
            children: record || "--",
          };
        },
      },
      {
        title: "Payment id",
        dataIndex: "payment_id",
        key: "payment_id",
        // width: 220,
        // width: "20%",
        align: "center",
        ellipsis: true,
        render: (data) => ({
          children: data ?? "--",
        }),
      },
      {
        title: <div>Amount</div>,
        dataIndex: "discounted_amount",
        key: "discounted_amount",
        // width: "25%",
        align: "right",

        ellipsis: true,
        render: (record) => ({
          children: <Currency currencySymbol={"₹"}>{record}</Currency>,
        }),
      },
      {
        title: "Payment date",
        /* dataIndex: ["created_at","valid_till"], */
        key: "created_at",
        align: "center",
        render: (date, record) => {
          let payDate = "(upcoming)";
          const dt = new Date(record.created_at || record.valid_till);
          if (record.created_at || record.valid_till) {
            payDate = format(dt, "dd/MM/yyyy");
            if (moment(dt).isAfter(new Date())) {
              payDate = payDate + " (upcoming)";
            }
          }

          return {
            children: payDate,
          };
        },
      },
      {
        title: "Invoice",
        align: "center",
        render: (v, record) =>
          record.invoice_id ? (
            <DownloadOutlined
              onClick={this.downloadInvoice.bind(this, record.invoice_id)}
            />
          ) : (
            "--"
          ),
      },
    ];

    // membership_plan = true, plan_purchased = false, fe_payment_status
    const {
      plan_purchased,
      grace_period,
      already_cancelled /* , fe_payment_status */,
    } = myPlan;
    const { plan_purchased: planPurchasedInfoCache } = this.props.userData;

    const planPurchasedInfoApi =
      plan_purchased || (grace_period && !already_cancelled);
    let currentPlanStatus = "";
    let currentPlanClassName = "";
    let isPendingActivation = false;
    if (
      planPurchasedInfoCache &&
      planPurchasedInfoApi /*  && fe_payment_status */
    ) {
      currentPlanStatus = "Active plan";
      currentPlanClassName = styles.activatBtn;
    } /*  else if (membership_plan && plan_purchased && !fe_payment_status) {
      isPendingActivation = true;
    } */ else if (
      planPurchasedInfoCache &&
      !planPurchasedInfoApi /*  && fe_payment_status */
    ) {
      isPendingActivation = true;
    } else if (!planPurchasedInfoCache && planPurchasedInfoApi) {
      isPendingActivation = true;
    }

    if (isPendingActivation) {
      currentPlanStatus = "Pending confirmation";
      currentPlanClassName = styles.pendingBtn;
    }

    return (
      <IMTContent fullwidth withoutMargin={true}>
        <Helmet>
          <title>{titles.SettingsMembership}</title>
        </Helmet>
        <IMTPageHeader
          className={styles.wrapPlanExisting}
          breadcumTexts={["Membership"]}
        />
        <Spin spinning={this.state.isLoading}>
          <div className={styles.membership}>
            <div className={`${styles.wrap} ${styles.wrapPlan}`}>
              <div className={styles.plan}>
                Your&nbsp;
                <span className={styles.activated}>active </span> &nbsp;Plan
              </div>
              <div className={styles.switchDis}>
                <div className={styles.switchDisWrap}>
                  <input
                    id="toggle-on"
                    className={`${styles.toggle} ${styles.toggleleft} `}
                    name="toggle"
                    value="false"
                    type="radio"
                    checked={this.state.activeTerm === "ANNUAL"}
                    disabled
                  />
                  <label
                    htmlFor="toggle-on"
                    className={`${styles.btn} ${styles.btnleft} ${styles.toggleactive}`}
                  >
                    Annual plan
                  </label>
                  <input
                    id="toggle-off"
                    className={`${styles.toggle} ${styles.toggleright} `}
                    name="toggle"
                    value="true"
                    type="radio"
                    checked={this.state.activeTerm === "MONTHLY"}
                    disabled
                  />
                  <label
                    htmlFor="toggle-off"
                    className={`${styles.btn} ${styles.btnright}  ${styles.toggleactive}`}
                  >
                    Monthly plan
                  </label>
                </div>
              </div>
              <div className={`site-card-wrapper ${styles.wrapCard}`}>
                <Row className={styles.planCardWrapper} gutter={46}>
                  {this.state.planList.map((plan: any, index: number) => {
                    if (!plan.is_active_plan) {
                      // Let's show only active plan
                      return false;
                    }
                    const license = plan.licenses.find(
                      (it: any) => it.term === this.state.activeTerm
                    );
                    // const isSecond = index === 1;
                    return (
                      <RenderPlanSingle
                        key={`${Math.random()}`}
                        plan={plan}
                        license={license}
                        currentPlanClassName={currentPlanClassName}
                        currentPlanStatus={currentPlanStatus}
                        isPendingActivation={isPendingActivation}
                        myPlan={myPlan}
                        cancelSubscription={this.cancelSubscription}
                        activeTerm={this.state.activeTerm}
                        alreadyCancelled={this.state.alreadyCancelled}
                        purchaseMode={false}
                        isPurchaseEnabled={!!plan?.is_active_plan}
                      />
                    );
                  })}
                </Row>
              </div>

              <div className={styles.tableWrap}>
                <Typography.Title level={4} style={{ margin: 15 }}>
                  Payment history
                </Typography.Title>
                {paymentHistory?.length > 0 ? (
                  <Table
                    data-tour="my-first-expense-step"
                    rowKey="id"
                    className={styles.table}
                    columns={columns}
                    dataSource={paymentHistory}
                    // onChange={this.handleTableChange}
                    // onRow={this.getRowProps}
                    loading={this.state.isLoading}
                    // size={matches.xl ? "large" : "small"}
                    pagination={false}
                  />
                ) : (
                  <Typography.Text>
                    No payment history available yet.
                  </Typography.Text>
                )}
              </div>
              <div className={styles.wrapIllustration}>
                <div className={styles.girlIllustration}>
                  <Illustration3 />
                </div>
                <div className={styles.illustration2}>
                  <Illustration2 />
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </IMTContent>
    );
  }
}

export default connector(SettingsMembership);
