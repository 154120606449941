export const EVENT_CATEGORY_BUTTON_CLICK = "Button click";
export const EVENT_CATEGORY_PURCHASE = "purchase";

//
export const EVENT_CLICK = "click";
export const EVENT_PLAN_CHECKOUT = "checkout";
export const EVENT_PURCHASE = "purchase";

//
export const events = {
  loginModalHideForgot: "Login modal>Hide forgot password modal",
  loginModal1: "Login modal>Show forgot password modal",
  loginModal2: "Login modal>Forgot password",
  loginModal3: "Login modal>Forgot email",
  signupModal4: "Signup modal>Ok",
  signupModal5: "Signup modal>Cancel",
  addCategory6: "Add catagory>Cancel",
  addCategory7: "Add catagory>Save",
  addCategory8: "Add catagory>Edit",
  addCategory9: "Add catagory>Delete",
  addClientDrawer10: "AddClientDrawer>Save",
  addClientDrawer11: "Add client drawer>GSTIN button",
  addClientDrawer12: "Add client drawer>Exit modal",
  addClientDrawer13: "AddClientDrawer>Fetch details",
  basicVendorModal14: "Besic vendor modal>cancel",
  basicVendorModal15: "Basic vendor modal>Fetch GST info",
  basicVendorModalReim16: "Vendor modal>Cancel",
  basicVendorModalReim17: "Vendor modal>Ok button",
  basicVendorModalReim18: "Basic vendor modal>GST type change",
  companyListModal19: "Switch company list>Switch company",
  companyListModal20: "Company list modal>Open add client",
  conflictModal21: "Conflict modal>Cancel",
  conflictModal22: "Conflict modal>Laod conflict",
  conflictModal23: "Conflict modal>Resolve conflict",
  dynamicFormModal24: "Dynamic form modal>Cancel",
  dynamicFormModal25: "Dynamic form modal>Submit",
  firstNameModal26: "First name modal>Cancel",
  firstNameModal27: "First name modal>Save",
  otpForm28: "Generate OTP",
  otpForm29: "Verify OTP",
  otpForm30: "Verify OTP>Cancel",
  adminPaneDrawer31: "AdminPaneDrawer> Save executive detail",
  adminPaneDrawer32: "AdminPaneDrawer> Delete executive detail",
  adminPaneDrawer33: "AdminPaneDrawer>Confirm",
  adminDetailsPane34: "Admin details pane>Confirm Click",
  companyDetailsPane35: "CompanyDetailsPane>Tan verify",

  dashboardPage36: "Open cash flow modal",
  dashboardPage37: "Add new expense",
  dashboardPage38: "Add new invoice",
  dashboardPage39: "Back to expense",
  dashboardPage40: "Back to invoice",
  dashboardPage41: "Back to compliance",
  dashboardPage42: "Back to reimbursement",
  dashboardPage43: "Toggle company details",
  dashboardPage44: "dashboardPage>expenseCard>Export",
  firstTimeLoginSuccessModal44: "First time login success modal>Close modal",
  initialCashModal45: "Cash Modal>Ok",
  initialCashModal46: "Cash Modal>Cancel",
  bankDetailsPane47: "Bank details pane>Finish",
  benefitPane48: "Benefit pane>Save",
  identityDetailsPane49: "Identity details pane>Finish",
  investDetailPane50: "InvestDetailPane>Save invest details",
  personalDetailsPane51: "Personal details pane>Finish",
  salaryDetailsPane52: "Salary details pane>Save",
  salaryDetailsPane53: "Salary details pane>Cancel",
  salaryDetailsPane54: "Salary details pane>Edit",
  salaryDetailsPane55: "Salary details pane>Modal save",
  salaryDetailsPane56: "Salary details pane>Revise salary btn",
  salaryDetailsPane57: "Salary details pane>Template change",
  salaryEmployee58: "Salary employee>Cancel btn",
  salaryEmployee59: "Salary employee>Edit btn",
  salaryEmployee60: "Salary employee>Save btn",
  salaryEmployee61: "Salary employee>Revise salary btn",
  salaryEmployee62: "Salary employee>Template change",
  termsAndCond63: "TermsAndConditons>Download",
  employeeDetailsPage64: "Employee details>Delete",
  employeeDetailsPage65: "Employee details>Log out",
  employeeDetailsPage66: "Employee details page>Cancel",
  employeePage67: "EmployeePage>Add new",
  termsAndConditions68: "Termspage>Publish terms",
  termsAndConditions69: "Termspage>Restore terms",
  termsAndConditions70: "Termspage>Verify load terms",
  onboardEmployeeModal71: "OnboardEmployeeModal>Log out",

  expensePage72: "ExpensePage>Add new",
  expensePageAddBlk: "ExpensePage>Add bulk",
  expensePage73: "ExpensePage>Get row",
  expensePage74: "ExpensePage>Email capture",
  expensePage75: "ExpensePage>Payment btn",
  expensePage76: "ExpensePage>Copy",
  expensePage77: "ExpensePage>Delete",

  purcheserDebitPage78: "purchasedebitNote>Add new",
  purcheserDebitPage79: "purchasedebitNote> Get details",
  purcheserDebitPage80: "purchasedebitNote> Email Capture",
  purcheserDebitPage81: "purchasedebitNote> Payment btn",
  purcheserDebitPage82: "purchasedebitNote> Copy",
  purcheserDebitPage83: "purchasedebitNote> Delete",
  salerDebitPage84: "sellerdebitNote> Add new",
  salerDebitPage85: "sellerdebitNote> Cancel",
  salerDebitPage86: "sellerdebitNote> Row details",
  salerDebitPage87: "sellerdebitNote> Email capture",
  salerDebitPage88: "sellerdebitNote>Payment btn",
  salerDebitPage89: "sellerdebitNote> Copy",
  salerDebitPage90: "sellerdebitNote> Delete",
  expenseDetailsPage91: "ExpenseDetailsPage> Edit",
  expenseDetailsPage92: "ExpenseDetailsPage> Pay Dlg",
  expenseDetailsPage93: "ExpenseDetailsPage>Tds pay dlg from ask",
  expenseDetailsPage94: "ExpenseDetailsPage>Ask for payment",
  expenseDetailsPage95: "ExpenseDetailsPage>Ask for Tds payment",
  expenseLineItemFooter96: "ExpenseLineItemFooter>History click",
  paymentDialog97: "Payment dialog>Ok",
  paymentDialog98: "Payment dialog>Cancel",
  paymentHistoryDialog99: "PaymentHistoryDialog>Cancel payment",
  purchesDebitDetail100: "PurchesDebitDetail>Edit",
  purchesDebitDetail101: "PurchesDebitDetail>Pay Dlg",
  purchesDebitDetail102: "PurchesDebitDetail> Tds pay dlg from ask",
  purchesDebitDetail103: "PurchesDebitDetail>Ask for payment",
  purchesDebitDetail104: "PurchesDebitDetail>Ask for tds payment",
  purchesDebitDetail105: "Purchase debit details>Edit action",
  purchesLineItemFooter106: "PurchesLineItemFooter>History click",
  salerDebitNoteDetail107: "SalerDebitNoteDetail>Edit",
  salerDebitNoteDetail108: "SalerDebitNoteDetail>Pay dlg",
  salerDebitNoteDetail109: "SalerDebitNoteDetail>Tds pay dlg from ask",
  salerDebitNoteDetail110: "SalerDebitNoteDetail>Ask for payment",
  salerDebitNoteDetail111: "SalerDebitNoteDetail>Ask for tds payment",
  salerDebitNoteDetail112: "Supplier debit note details>Edit action",
  creditNotePage113: "CreditNotePage>Row event",
  creditNotePage114: "CreditNotePage>Add new",
  creditNotePage115: "CreditNotePage>Download",
  creditNotePage116: "CreditNotePage>Copy",
  creditNotePage117: "CreditNotePage>Delete",
  debitNotePage118: "DebitNotePage>Row details",
  debitNotePage119: "DebitNotePage>Add new",
  debitNotePage120: "DebitNotePage>Download",
  debitNotePage121: "DebitNotePage>Copy",
  debitNotePage122: "DebitNotePage>Delete",
  invoicePage123: "InvoicePage>Row details",
  invoicePage124: "InvoicePage> Add new",
  invoicePage125: "InvoicePage>Download",
  invoicePage126: "InvoicePage> Copy",
  invoicePage127: "InvoicePage> Delete",
  creditNotePage128: "CreditNotePage>Add product",
  creditNotePage129: "CreditNotePage>Pay Dlg",
  creditNotePage130: "CreditNotePage>Pay dlg from ask",
  creditNotePage131: "CreditNotePage>Save",
  creditNotePage132: "CreditNotePage>Ask for payment",
  creditNotePage133: "CreditNotePage>Edit",
  creditNotePage134: "CreditNotePage>Cancel",
  creditNotePage135: "CreditNotePage>Invoice modal",
  creditNotePage136: "CreditNotePage>Download invoice",
  debitNotePage137: "DebitNotePage>Add product",
  debitNotePage138: "DebitNotePage>Pay dlg",
  debitNotePage139: "DebitNotePage>Pay dlg from ask",
  debitNotePage140: "DebitNotePage>Save",
  debitNotePage141: "DebitNotePage>Ask for payment",
  debitNotePage142: "DebitNotePage>Edit",
  debitNotePage143: "DebitNotePage>Cancel",
  debitNotePage144: "DebitNotePage>Invoice Modal",
  debitNotePage145: "DebitNotePage>Download invoice",
  invoiceDetailsPage146: "InvoiceDetailsPage>Add product",
  invoiceDetailsPage147: "InvoiceDetailsPage>Pay dlg",
  invoiceDetailsPage148: "InvoiceDetailsPage>Pay dlg from ask",
  invoiceDetailsPage149: "InvoiceDetailsPage>Save",
  invoiceDetailsPage150: "InvoiceDetailsPage>Ask for payment",
  invoiceDetailsPage151: "InvoiceDetailsPage>Edit",
  invoiceDetailsPage152: "InvoiceDetailsPage>Email notification",
  invoiceDetailsPage153: "InvoiceDetailsPage>Edit vendor/customer",
  invoiceDetailsPage154: "InvoiceDetailsPage>Invoice modal",
  invoiceDetailsPage155: "InvoiceDetailsPage>Download invoice",
  invoiceDetailsPage156: "Cancel",
  invoiceDetailsPage157: "InvoiceDetailsPage>Delete",
  membershipPlan158: "Membership plan>Logout",
  membershipPlan159: "Membership plan>Checkout",
  currentTabPane160: "Compliance>CurrentTabPane>Open drawer",
  complianceDrawer161: "Compliance drawer>Reconcile",
  complianceDrawer162: "ComplianceDrawer>Entity",
  complianceDrawer163: "ComplianceDrawer>Pay modal",
  complianceDrawer164: "ComplianceDrawer>Conflict modal",
  complianceDrawer165: "ComplianceDrawer>RPU file download",
  complianceDrawer166: "ComplianceDrawer>GSTR3b download",
  complianceDrawer167: "Compliance drawer>Open verify OTP modal",
  complianceDrawer168: "Compliance drawer>Close verify OTP modal",
  complianceDrawer169: "Compliance drawer>Export",
  complianceDrawer170: "ComplianceDrawer>Generate OTP",
  complianceDrawer171: "ComplianceDrawer>Verify OTP",
  complianceDrawer172: "ComplianceDrawer>Download Rpu error",
  compliancePaymentModal173: "Compliance payment>Ok",
  fetchWithGstinModal174: "FetchWithGSTINModal>Cncel",
  fetchWithGstinModal175: "FetchWithGSTINModal>Fetch",
  stepBank176: "StepBank>Prev",
  stepBasic177: "StepBasic> GSTIN btn",
  stepCommunication178: "StepCommunication>Prev btn",
  stepIdentity179: "StepIdentity>Prev btn",
  stepIdentity180: "StepIdentity>Tan verify btn",
  confirmTemplateModal181: "ConfirmTemplateModal>Cancel",
  payrollBulkSalaryPage182: "PayrollBulkSalaryPage>Cancel",
  payrollBulkSalaryPage183: "PayrollBulkSalaryPage> Btn click",
  payrollBulkSalaryPage184: "PayrollBulkSalaryPage> Yes btn",
  payrollDetail185: "PayrollSalaryDetailsPage>Edit",
  payrollDetail186: "PayrollSalaryDetailsPage>Delete",
  payrollDetail187: "PayrollSalaryDetailsPage>Download salary slip",
  payrollDetail188: "PayrollSalaryDetailsPage>Send email",
  payrollPage189: "PayrollPage>Export salary",
  payrollPage190: "PayrollPage>Row click",
  payrollPage191: "PayrollPage>Single Ok",
  payrollPage192: "PayrollPage>Single cancel",
  payrollPage193: "PayrollPage>Bulk ok",
  payrollPage194: "PayrollPage>Bulk cancel",
  payrollPage195: "PayrollPage>Export salary modal",
  payrollPage196: "PayrollPage>Cancel export salary",
  payrollSingleSalaryPage197: "PayrollSingleSalaryPage>Cancel edit",
  payrollSingleSalaryPage198: "PayrollSingleSalaryPage>Process salary",
  salaryTemplate199: "Salary template>Cancel btn",
  salaryTemplate200: "Salary template>Edit",
  salaryTemplate201: "Salary template> Cfm new",
  salaryTemplate202: "Salary template> Cfm Edit",
  salaryTemplate203: "Salary template>CFM Delete",
  salaryTemplate204: "Salary template>Save",
  salaryTemplate205: "Salary template>Delete",
  salaryTemplate206: "Salary template>Add row",
  salaryTemplate207: "Salary template>Remove row",
  approveModal208: "Reimbursement approve modal>Cancel",
  approveModal209: "Reimbursement approve modal>Form submit",
  reimbursementDetailsPage210: "Reimbursement details page>Confirm",
  reimbursementDetailsPage211: "Reimbursement details page>Cancel Click",
  reimbursementDetailsPage212: "Reimbursement details page>Save and new",
  reimbursementDetailsPage213: "Reimbursement details page>Save",
  reimbursementDetailsPage214: "Reimbursement details page>Reject click",
  reimbursementDetailsPage215: "Reimbursement details page>Approve click",
  reimbursementDetailsPage216: "Reimbursement details page>Edit",
  reimbursementDetailsPage217: "Reimbursement details page>Delete",
  reimbursementDetailsPage218: "Reimbursement details page>Cancel",
  reimbursementLineItem219: "ReimbursementLineItem>Add row",
  reimbursementPage220: "ReimbursementPage>Approve",
  reimbursementPage221: "ReimbursementPage>Reject",
  reimbursementPage222: "Reimbursement page>Approve Click",
  reimbursementPage223: "Reimbursement page>Reject Click",
  reimbursementPage224: "Reimbursement page>Form sumbit",
  reimbursementPage225: "ReimbursementPage>Cancel",
  reimbursementPage226: "ReimbursementPage>Add new",
  reimbursementPage227: "Reimbursement page>Download Click",
  reimbursementPage228: "ReimbursementPage>Row click",
  settingsUsers229: "SettingsUsers>Edit",
  settingsUsers230: "SettingsUsers>Add new user",
  amazonCsvSync231: "Amazon CSV sync> Start sync",
  amazonCsvSync232: "AmazonCsvSync>Back click",
  amazonStockTransferCsvSync233: "AmazonStockTransferCsvSync>Back click",
  flipkartXlsxSync234: "Flipkart Xlsx Csv sync> Start sync",
  flipkartXlsxSync235: "FlipkartXlsxCsv>Back click",
  gstOtpFrom236: "GstOtpFrom>Start sync from verify",
  gstOtpFrom237: "GstOtpFrom>Request for otp",
  gstOtpFrom238: "GstOtpFrom>Verify OTP",
  gstOtpFrom239: "GstOtpFrom> Back click",
  meeshoXlsxSync240: "Meesho Xlsx sync> Start sync",
  meeshoXlsxSync241: "MeeshoXlsxSync>Back click",
  syncBackwardTallyModal242: "handleStartBackwardTally> Start btn",
  synchronize243: "Synchronize> Sync now dropdown ",
  synchronize244: "Synchronize> Synce now button ",
  synchronize245: "Synchronize>Refresh btn",
  synchronize246: "Synchronize>Download CSV report",
  tallyStart247: "TallyStart>Tally start sync",
  tallyStart248: "TallyStart>Back click",
  tallyStart249: "TallyStart>Backward Sync start",
  tallySync250: "TallySync>Tally start call",
  tallySync251: "TallySync>Back click",
  homepageBtn: "Back from unauthorized to dashboard page",
  vendorDetailsPage253: "VendorDetailsPage>Confirm",
  vendorDetailsPage254: "VendorDetailsPage>Edit",
  vendorDetailsPage255: "VendorDetailsPage>Delete",
  vendorDetailsPage256: "VendorDetailsPage>Save",
  vendorDetailsPage257: "VendorDetailsPage>Cancel",
  vendorPage258: "Vendor Page > Add new",
  vendorTransactions259: "VendorTransactions>Export csv",

  onlyAttachFieldcompliance264: "Compliance payment modal > Upload file",
  onlyAttachFieldAmazomCsvSync: "AmazomCsvSync> Upload file",
  onlyAttachFieldAmazomStockTransCsvSync:
    "AmazomStockTransCsvSync> Upload file",
  onlyAttachFieldFlipkartCsvSync: "FlipkartCsvSync> Upload file",
  onlyAttachFieldMeeshoXlsxCsvSync: "MeeshoXlsxCsvSync> Upload file",

  onlyAttachField265: "Attached file delete",
  onlyAttachField266: "Attached file preview",
  onlyAttachField267: "Attached file Preview cancel",

  attachFieldWithView260: "Ocr > Handle prev cancel",
  attachFieldWithView261: "Ocr > Preview cancel",
  attachFieldWithView262: "Ocr > Download",
  attachFieldWithView263: "Ocr > Delete row and add",

  rowAttachField268: "Employee page> File Attach",
  rowAttachField269: "Employee page>File Delete",
  rowAttachField270: "Employee page>File Preview",
  rowAttachField271: "Employee page>File Preview cancel",

  AttachFieldAadharDelete: "IdentityDetailsPane> Aadhar file delete",
  AttachFieldPanDelete: "IdentityDetailsPane> Pan file delete",
  AttachFieldPhysicalDisabilityDelete:
    "IdentityDetailsPane> Physical Disability file delete",
  AttachFieldFileDelete: "InvoiceDetails page > File delete",

  AttachFieldAadharPreview: "IdentityDetailsPane> Aadhar file preview",
  AttachFieldPanPreview: "IdentityDetailsPane> Pan filepPreview",
  AttachFieldPhysicalDisabilityPreview:
    "IdentityDetailsPane> Physical Disability file preview",
  AttachFieldFilePreview: "InvoiceDetails page > File preview",

  AttachFieldAadharPreviewCancel:
    "IdentityDetailsPane> Aadhar file preview cancel",
  AttachFieldPanPreviewCancel: "IdentityDetailsPane> Pan filepPreview cancel",
  AttachFieldPhysicalDisabilityPreviewCancel:
    "IdentityDetailsPane> Physical Disability file preview cancel",
  AttachFieldFilePreviewCancel: "InvoiceDetails page > File preview cancel",

  exportButton272: "Export btn>Show modal",
  exportButton273: "Export btn>Ok",
  exportButton274: "Export btn>Cancel",
  simpleUploadButton275: "Upload",
  simpleUploadButton276: "Download btn",
  finalBlock277: "FinalBlock>Get started",
  heroBlock278: "HeroBlock>Watch video",
  heroBlock279: "HeroBlock>Start free trial",
  imtlpHeader280: "IMTLPHeader>Features",
  imtlpHeader281: "IMTLPHeader>Pricing",
  imtlpHeader282: "IMTLPHeader>Contact",
  imtlpHeader283: "IMTLPHeader>Login",
  imtlpHeader284: "IMTLPHeader>Sign up",
  landingLayout285: "Landing layout>Notification btn on Click",
  pricingBlock286: "IMTLPHeader>SignUp modal",
  testimonial287: "IMTLPHeader>Start free trial",
  imtContent288: "Switch modal>Switch role",
  imtContent289: "Switch modal>Switch company",
  imtContent290: "Switch modal>Company list",
  imtContent291: "IMT content>Company list view switch",
  imtPageHeader292: "IMT page header>Click back button",
  mainLayout293: "Company change click",
  mainLayout294: "Main Layout>Company popup",
  mainLayout295: "Company list>Switch role",
  mainLayout296: "MainLayout>Menu click",
  mainLayout297: "MainLayout>Inoice click",
  mainLayout298: "MainLayout>Membership click",
  mainLayout299: "MainLayout>Expense click",
  mainLayout300: "MainLayout>Logout click",
  mainLayout301: "MainLayout>Credit note click",
  mainLayout302: "MainLayout>Debit note click",
  mainLayout303: "MainLayout>Supplier credit note click",
  mainLayout304: "MainLayout>Supplier debit note click",
  mainLayout305: "MainLayout>Profile click",
  mainLayout306: "MainLayout>Role user click",
  mainLayout307: "MainLayout>Setting user click",
  mainLayout308: "MainLayout>Setting membership click",
  mainLayout309: "MainLayout>Add client click",
  mainLayout310: "MainLayout>Logout",
  mainLayout311: "Current password change",
  mainLayout312: "New password",
  mainLayout313: "Confirm password",
  mainLayout314: "Email change",
  mainLayout315: "Company list",
  changePasswordModal316: "Change password modal>Ok",
  changePasswordModal317: "Change password modal>Cancel",
  sendMailConfrmModal318: "Send mail confirm modal>Cancel",
  sendMailConfrmModal319: "Send mail confirm modal>Confirm",
  Reconcile320: "Reconcile file upload>Action",
  Reconcile321: "Reconcile>Submit reconcile data",
  Reconcile322: "Reconcile>Cancel",
  Reconcile323: "Reconcile>Accept",
  termsModalView324: "Employee-TermsAndConditons>Agree",
  termsModalView325: "Employee-TermsAndConditons>Download TermsAndConditons",
  termsModalView326: "Employee-TermsAndConditons>Action",
  termsModalView327: "Overridden request",
};
