import React, { Component } from "react";
import {
  Spin,
  notification,
  Select,
  Button,
  Table,
  Tooltip,
  Space,
  Input,
  Divider,
} from "antd";
import { ColumnType } from "antd/lib/table";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import styles from "./InvestDetailPane.module.css";
import { fetchUtil } from "~/api/common";
import { parseInputNumber } from "~/lib";
import RowAttachField from "~/fragment/attachable-field/RowAttachField";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import Currency from "~/component/Currency";
import DynamicFormModal from "~/component/DynamicFormModal";
import { registerEvent } from "~/analytics";
import { LoginRole } from "~/api/auth";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (
  state: AuthState,
  ownProps: { employeeID: number }
) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  employeeID: ownProps.employeeID,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  createCompanyDetails: (company_name: any) =>
    dispatch(action.auth.createCompanyDetails(company_name)),
  empDetailsSaved: (saveDetails: boolean) =>
    dispatch(action.auth.empDetailsSaved(saveDetails)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux {
  employeeID: number;
  isTabUpdated: boolean;
  changeTabUpdate: () => void;
  currentTab: string;
  active: boolean;
}

type rowData = {
  investment_type_id: string;
  description: string;
  amount: number;
  file_name: string;
};

type InvestmentTypes = {
  investment_type_id: number;
  investment_type: string;
};

interface State {
  opts_fy: Array<any>;
  selectedFy: any;
  tableLIneItem: any;
  deletedItems: any;
  document: string;
  document_name: string;
  total: number;
  disableEdit: boolean;
  storeIncomingData?: any;
  investmentTypes?: Array<InvestmentTypes>;
  loadSave: boolean;
  loadDetail: boolean;
  fyData: Array<any>;
  PreRequisitesVisible: boolean;
  a: any;
  selectedPreREQdata: any;
  forCheackArray: any;
  showPreReq: boolean;
  selectedDependency: any;
  rowId: any;
  selectedGroup: any;
  listData: any;
  validPreReq: boolean;
  validPrereqRow: any;
  validPreReqGroup: any;
}

class InvestDetailPane extends Component<Props, State> {
  state: State = {
    selectedPreREQdata: [],
    a: 0,
    opts_fy: [],
    // fix me - this should not be hard coded
    selectedFy: "",
    tableLIneItem:
      this.props.activeRole === 3
        ? []
        : [
            {
              investment_type_id: "",
              description: "",
              amount: "",
              file_name: "",
            },
          ],
    deletedItems: [],
    document: "",
    document_name: "",
    total: 0,
    disableEdit: false,
    storeIncomingData: [],
    loadSave: false,
    loadDetail: false,
    fyData: ["2020-2021", "2021-2022"],
    PreRequisitesVisible: false,
    forCheackArray: [],
    showPreReq: false,
    selectedDependency: null,
    rowId: null,
    selectedGroup: null,
    listData: [
      {
        name: "A. Total Investment",
        value: 0,
        key: "A",
      },
      {
        name: "B. Total eligible exemption",
        value: 0,
        key: "B",
      },
      {
        name: "C. Standard deduction",
        value: 0,
        key: "C",
      },
      {
        name: "D. Estimated income from salary",
        value: 0,
        key: "D",
      },
      {
        name: "E. Salary received so far",
        value: 0,
        key: "E",
      },
      {
        name: "F. Taxable amount (D-B-C)",
        value: 0,
        key: "F",
      },
      {
        name: "G. Estimated Tax (old regime)",
        value: 0,
        key: "G",
      },
      {
        name: "H. Rebate under Section 87A",
        value: 0,
        key: "H",
      },
      {
        name: "I. Tax deducted so far",
        value: 0,
        key: "I",
      },
      {
        name: "J. Estimated Monthly Deduction(in 0 months)",
        value: 0,
        key: "J",
        isVisible: true,
      },
    ],
    validPreReq: true,
    validPrereqRow: 0,
    validPreReqGroup: 0,
  };

  static createNewLineItem(isNew = false): any {
    return {
      description: "",
      amount: "",
      file_name: "",
      subsection_id: "",
      isNew,
      pre_req: [],
    };
  }

  componentDidMount() {
    // according to fy we fetch invest data 
    this.getFy().then(() => {
      this.getInvestmentDetails();
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.currentTab === "invest_detail" && !this.props.isTabUpdated) {
      this.getFy().then(() => {
        this.getInvestmentDetails();
      });
      this.props.changeTabUpdate();
    }
    if (this.props.activeRole !== prevProps.activeRole) {
      this.getFy().then(() => {
        this.getInvestmentDetails();
      });
    }
  }

  async getInvestmentDetails() {
    this.setState({
      loadDetail: true,
    });
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_employee_investment_details`,
      {
        emp_id: this.props.employeeID,
        finanical_year: this.state.selectedFy,
      }
    );

    if (ok) {
      const { listData } = this.state;
      const { total } = json[1];
      if (Object.keys(total).length) {
        listData[0].value = total.A;
        listData[1].value = total.B;
        listData[2].value = total.C;
        listData[3].value = total.D;
        listData[4].value = total.E;
        listData[5].value = total.F;
        listData[6].value = total.G;
        listData[7].value = total.H;
        listData[8].value = total.I;
        listData[9].value = total.J.tds_deductible;
        listData[9].name = `J. Estimated Monthly Deduction(in ${total.J.months} months)`;
      } else {
        listData[0].value = 0;
        listData[1].value = 0;
        listData[2].value = 0;
        listData[3].value = 0;
        listData[4].value = 0;
        listData[5].value = 0;
        listData[6].value = 0;
        listData[7].value = 0;
        listData[8].value = 0;
        listData[9].value = 0;
        listData[9].name = `J. Estimated Monthly Deduction(in ${0} months)`;
      }

      this.setState(
        {
          storeIncomingData: json[0].employee_investment,
          loadDetail: false,
          forCheackArray: JSON.parse(JSON.stringify(json[0])),
        },
        () => {
          const employee_invest_data: any = [];
          employee_invest_data.push(InvestDetailPane.createNewLineItem());
          json[0].employee_investment.forEach((it: any) => {
            if (
              !it.employee_investment_data &&
              this.props.activeRole !== LoginRole.CA &&
              this.state.opts_fy[1] === this.state.selectedFy
            ) {
              it.employee_investment_data = employee_invest_data;
            }
          });

          this.setState({
            loadDetail: false,
          });
        }
      );
      this.setState({
        loadDetail: false,
      });
    } else {
      notification.error({ message });
      this.setState({
        loadDetail: false,
      });
    }
  }

  async getFy() {
    this.setState({
      loadDetail: true,
    });
    const { ok, json, message } = await fetchUtil(
      "GET",
      `/get_employee_investment_fy_data`
    );
    if (ok) {
      this.setState({
        opts_fy: json,
        loadDetail: false,
        selectedFy: json[1],
      });
    } else {
      notification.error({ message });
      this.setState({
        loadDetail: false,
      });
    }
  }

  handleFy = (value: any) => {
    this.setState(
      {
        selectedFy: value,
      },
      () => {
        this.getInvestmentDetails();
      }
    );
  };

  prereqValidation = (
    isvalid: boolean,
    validPrereqRow: any,
    validPreReqGroup: any
  ) =>
    this.setState({ validPreReq: isvalid, validPrereqRow, validPreReqGroup });


// this section is used for render every block of section & 
// each section have a heading ,description,table and a save button
// ** once row is created and save then it will not be deletable
// only attach doc field can be added
  handleSectionInfo = () => {
    let saveLoading = false;
    return this.state.storeIncomingData
      ?   this.state.storeIncomingData.map((el: any) => {
          const saveDynamicFrom = (data: any, idx: any, selectedGroup: any) => {
            // tslint:disable-next-line: no-shadowed-variable
            selectedGroup.employee_investment_data.forEach((el: any) => {
              if (selectedGroup.employee_investment_data.indexOf(el) === idx) {
                el.pre_req = data;
                if (!el.hasOwnProperty("isNew")) {
                  el.isNew = true;
                }
              }
            });
          };

          const handlePreRequisites = (
            rowIndex: number,
            pre_req: any,
            pre_req_data: any,
            groupData: any
          ) => {
            this.setState({
              PreRequisitesVisible: true,
              selectedPreREQdata: pre_req,
              selectedDependency: pre_req_data,
              rowId: rowIndex,
              selectedGroup: groupData,
            });
          };

          const onRowAdd = async () => {
            return (
              this.setState({
                a: 1,
              }),
              this.setState({}),
              (el.employee_investment_data = [
                ...el.employee_investment_data,
                InvestDetailPane.createNewLineItem(true),
              ]),
              this.setState({})
            );
          };
          const onRowDelete = (rowIndex: number) => {
            if (el.employee_investment_data.length <= 1) {
              el.employee_investment_data = [
                InvestDetailPane.createNewLineItem(),
              ];
              let sum = 0;
              el.employee_investment_data.map((item: any) => {
                return (sum += Number(item.amount));
              });
              el.group_gross_amount = sum;
            } else {
              el.employee_investment_data = [
                ...el.employee_investment_data.slice(0, rowIndex),
                ...el.employee_investment_data.slice(rowIndex + 1),
              ];
              let sum = 0;
              el.employee_investment_data.map((item: any) => {
                return (sum += Number(item.amount));
              });
              el.group_gross_amount = sum;
            }

            this.setState({});
          };

          const saveInvestDetails = async (
            e: React.MouseEvent,
            subsection_info: any
          ) => {
            registerEvent(
              ga.EVENT_CATEGORY_BUTTON_CLICK,
              ga.EVENT_CLICK,
              ga.events.investDetailPane50
            );
            let validate = false;
            let preReqCheack = false;
            el.employee_investment_data.forEach((val: any) => {
              if (!val.subsection_id) {
                validate = true;
              }
              if (val.amount.length === 0) {
                validate = true;
              }

              const selectedSubSection = subsection_info.find(
                (it: any) => it.subsection_id === val.subsection_id
              );

              if (
                selectedSubSection?.dynamic_form_data !== null &&
                val.pre_req.length === 0
              ) {
                preReqCheack = true;
              }
            });

            if (validate) {
              notification.error({
                message: "Please fill all required fields for the line items !",
              });
              return;
            }
            if (preReqCheack) {
              notification.error({
                message: "Please add pre-requisite",
              });
              return;
            }

            const changedData: any = [];

            el.employee_investment_data.forEach((val: any) => {
              if (val.hasOwnProperty("isNew")) {
                delete val.isNew;
                changedData.push(
                  Object.assign(val, {
                    financial_year: this.state.selectedFy,
                  })
                );
              }
            });

            this.setState({
              loadSave: true,
            });

            saveLoading = true;

            const { ok, message } = await fetchUtil(
              "POST",
              "/save_employee_investment_details",
              {
                investment_details: changedData,
              }
            );
            if (ok) {
              notification.success({
                message,
              });
              this.setState({
                loadSave: false,
              });
              saveLoading = false;
              this.getInvestmentDetails();
            } else {
              notification.error({
                message,
              });
              this.setState({
                loadSave: false,
              });
              saveLoading = false;
            }
          };
          const { active } = this.props;
          const columnsDef: Array<ColumnType<any>> = [
            {
              title: "",
              key: "add_action",
              width: "2%",
              className: styles.actionCell,
              render: (_, _record, idx) => {
                if (idx + 1 === el.employee_investment_data.length) {
                  return this.props.activeRole !== 3 &&
                    this.state.selectedFy === this.state.opts_fy[1] ? (
                    <Space className={styles.errorSpace}>
                      <Button
                        size="small"
                        disabled={!active}
                        icon={
                          <PlusOutlined
                            style={{ color: "var(--success-color)" }}
                          />
                        }
                        // tslint:disable-next-line: jsx-no-lambda
                        onClick={() => onRowAdd()}
                      />
                    </Space>
                  ) : null;
                } else {
                  return null;
                }
              },
            },
            {
              title: "Investment type",
              dataIndex: "subsection_id",
              width: "25%",
              render: (value, _rec, idx) => {
                const label =
                  el.subsection_info &&
                  el.subsection_info!.find(
                    (it: any) => it.subsection_id === Number(value)
                  )?.subsection_name;
                const isNew =
                  el.employee_investment_data[idx].id ||
                  this.props.activeRole === 3
                    ? true
                    : false;

                return isNew ? (
                  <Tooltip title={label}>
                    <Input defaultValue={label} readOnly />
                  </Tooltip>
                ) : (
                  <Select
                    className={styles.select}
                    dropdownMatchSelectWidth={false}
                    value={value}
                    disabled={!active}
                    // autoFocus
                    // tslint:disable-next-line: jsx-no-lambda
                    onChange={(newValue) =>
                      this.handleCellChange(
                        "subsection_id",
                        idx,
                        {
                          target: { value: newValue },
                        } as any,
                        el
                      )
                    }
                  >
                    {el.subsection_info!.map((opt: any) => (
                      <Select.Option
                        className={styles.option}
                        key={opt.subsection_id}
                        value={opt.subsection_id}
                      >
                        {opt.subsection_name}
                      </Select.Option>
                    ))}
                  </Select>
                );
              },
            },
            {
              title: "Description",
              dataIndex: "description",
              width: "25%",
              render: (value, _rec, idx) => {
                const isNew = el.employee_investment_data[idx].id
                  ? true
                  : false;
                return (
                  <Input.TextArea
                    rows={1}
                    readOnly={
                      this.props.activeRole === 3 || !active
                        ? true
                        : isNew
                        ? true
                        : false
                    }
                    value={value}
                    // tslint:disable-next-line: jsx-no-lambda
                    onChange={(e: any) => {
                      this.handleCellChange("description", idx, e, el);
                    }}
                  />
                );
              },
            },
            {
              title: "Documents",
              dataIndex: "file_name",
              width: "25%",
              render: (value, _rec, idx) => {

                // isnew cheack for new created row or not
                const isNew =
                  el.employee_investment_data[idx].id &&
                  !el.employee_investment_data[idx].isNew
                    ? true
                    : false;
                return active ? (
                  <RowAttachField
                    fieldName="document"
                    hashFileName={el.employee_investment_data[idx].file_name}
                    disable={
                      this.props.activeRole === 3
                        ? true
                        : el.employee_investment_data
                        ? el.employee_investment_data[idx].file_name
                          ? true
                          : false
                        : false
                    }
                    activeRole={this.props.activeRole}
                    isNew={isNew}
                    documentName={
                      el.employee_investment_data
                        ? el.employee_investment_data[idx].document_name
                        : ""
                    }
                    idx={idx}
                    lineItemValues={el}
                    onHashFileChange={this.handlePanDocChange}
                    readonly={false}
                    label="Document"
                    rules={[
                      {
                        type: "string",
                        required: false,
                        message: "Please upload docs!",
                      },
                    ]}
                    selectedFy={this.state.selectedFy}
                    opts_fy={this.state.opts_fy}
                  />
                ) : (
                  "No data found !"
                );
              },
            },
            {
              title: "Amount",
              dataIndex: "amount",
              width: "25%",
              align: "right",
              render: (value, _rec, idx) => {
                const isNew = el.employee_investment_data[idx].id
                  ? true
                  : false;
                return (
                  <Input
                    value={value}
                    readOnly={
                      this.props.activeRole === 3 || !active
                        ? true
                        : isNew
                        ? true
                        : false
                    }
                    style={{ textAlign: "right" }}
                    // tslint:disable-next-line: jsx-no-lambda
                    onChange={(e: any) => {
                      this.handleCellChange("amount", idx, e, el);
                    }}
                  />
                );
              },
            },

            {
              title: "",
              key: "delete_action",
              className: styles.actionCell,
              width: "2%",
              render: (_, _record, idx) => {
                const isNew = el.employee_investment_data[idx].id
                  ? true
                  : false;
                return this.props.activeRole === LoginRole.CA ? null : !isNew &&
                  this.state.selectedFy === this.state.opts_fy[1] ? (
                  <div>
                    <Button
                      size="small"
                      danger
                      title="Remove line item"
                      disabled={!active}
                      icon={<MinusOutlined />}
                      // tslint:disable-next-line: jsx-no-lambda
                      onClick={() => onRowDelete(idx)}
                    />
                  </div>
                ) : null;
              },
            },
            {
              title: "",
              className: styles.actionCell1,
              dataIndex: "pre_req",
              align: "center",
              width: "2%",

              render: (value, _record, idx) => {
                const selectedPreReq = el.subsection_info.find(
                  (it: any) => it.subsection_id === _record.subsection_id
                );
                const {
                  validPreReq,
                  validPreReqGroup,
                  validPrereqRow,
                } = this.state;
                return selectedPreReq ? (
                  selectedPreReq.dynamic_form_data?.length ? (
                    <Tooltip
                      title={`${selectedPreReq.dynamic_form_data.length} Prerequisites - Click to add`}
                    >
                      <Button
                        size="small"
                        type="primary"
                        title=""
                        style={{
                          minHeight: 24,
                          minWidth: 24,
                          marginLeft: "30px",
                          backgroundColor:
                            !validPreReq &&
                            el.grouping_id === validPreReqGroup &&
                            validPrereqRow === idx
                              ? "red"
                              : "",
                          borderColor:
                            !validPreReq &&
                            el.grouping_id === validPreReqGroup &&
                            validPrereqRow === idx
                              ? "red"
                              : "",
                        }}
                        // tslint:disable-next-line: jsx-no-lambda
                        onClick={() =>
                          handlePreRequisites(
                            idx,
                            selectedPreReq.dynamic_form_data,
                            _record.pre_req,
                            el
                          )
                        }
                      >
                        {selectedPreReq.dynamic_form_data.length}
                      </Button>
                    </Tooltip>
                  ) : null
                ) : null;
              },
            },
          ];
          return (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <div>
                    <p
                      style={{
                        color: "#2F80ED",
                        fontSize: "18PX",
                        fontWeight: 500,
                      }}
                    >
                      {el.grouping_id}. {el.grouping_name}
                    </p>
                  </div>
                  <div>
                    <p style={{ color: "#B2B2B2" }}>{el.description}</p>
                  </div>

                  <div
                    style={{
                      justifyContent: "right",
                      marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    <Table
                      style={{ flexBasis: "100%" }}
                      columns={columnsDef}
                      dataSource={el.employee_investment_data}
                      rowKey="row_details_id"
                      // tslint:disable-next-line: jsx-no-lambda
                      footer={(_data: Array<any>) => {
                        return this.props.activeRole === 3 ? null : this.state
                            .opts_fy[1] === this.state.selectedFy ? (
                          <>
                            <div className={styles.footerWrap}>
                              <div className={styles.footer}>
                                <div className={styles.footerRow}>
                                  <span className={styles.footerLabel}>
                                    Subtotal of Investment
                                  </span>
                                  <Currency
                                    className={styles.footerAmount}
                                    currencySymbol={"₹"}
                                  >
                                    {el.group_gross_amount}
                                  </Currency>
                                </div>
                                <div className={styles.footerRow}>
                                  <span className={styles.footerLabel}>
                                    Eligible exemption
                                    <br />
                                    <p style={{ whiteSpace: "nowrap" }}>
                                      {" "}
                                      (Will be calculated after saving)
                                    </p>
                                  </span>
                                  <Currency
                                    className={styles.footerAmount}
                                    currencySymbol={"₹"}
                                  >
                                    {el.group_eligible_amount}
                                  </Currency>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null;
                      }}
                      pagination={false}
                    />
                  </div>
                </div>

                <div>
                  {this.props.activeRole !== LoginRole.CA &&
                  this.state.selectedFy === this.state.opts_fy[1] ? (
                    <div
                      style={{
                        justifyContent: "right",
                        marginTop: "10px",
                        display: "flex",
                        marginBottom: 20,
                      }}
                    >
                      {this.props.active ||
                      this.props.activeRole === LoginRole.EMPLOYEE ? (
                        <Button
                          type="primary"
                          loading={saveLoading}
                          // tslint:disable-next-line: jsx-no-lambda
                          onClick={(e: React.MouseEvent) =>
                            saveInvestDetails(e, el.subsection_info)
                          }
                        >
                          Save
                        </Button>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>

              {this.state.PreRequisitesVisible ? (
                // this component is used for pre req from populate with dynamically from api data
                <DynamicFormModal
                  visible={this.state.PreRequisitesVisible}
                  onVisibleChange={this.onPreRequisitesVisibleChange}
                  selectedPreREQdata={this.state.selectedPreREQdata}
                  selectedDependency={this.state.selectedDependency}
                  saveDynamicFrom={saveDynamicFrom}
                  rowId={this.state.rowId}
                  selectedGroup={this.state.selectedGroup}
                  validationCheck={this.prereqValidation}
                />
              ) : null}
            </>
          );
        })
      : null;
  };

  // this is used for any cell change in table data
  handleCellChange = (
    key: string,
    rowIndex: number,
    event: React.ChangeEvent<HTMLInputElement>,
    lineItemValues: any
  ) => {
    const updatedLineItem = {
      ...lineItemValues.employee_investment_data[rowIndex],
    };

    updatedLineItem.isNew = true;
    updatedLineItem.emp_id = this.props.employeeID;

    if (key === "subsection_id") {
      updatedLineItem.subsection_id = Number(event.target.value);
    }

    if (key === "description") {
      updatedLineItem.description = event.target.value;
    }

    if (key === "amount") {
      updatedLineItem.amount = parseInputNumber(
        event.target.value,
        updatedLineItem[key]
      );
    }

    lineItemValues.employee_investment_data = [
      ...lineItemValues.employee_investment_data.slice(0, rowIndex),
      updatedLineItem,
      ...lineItemValues.employee_investment_data.slice(rowIndex + 1),
    ];
    let sum = 0;
    lineItemValues.employee_investment_data.map((item: any) => {
      return (sum += Number(item.amount));
    });
    lineItemValues.group_gross_amount = sum;

    this.setState({});
  };

  // on row add
  onRowAdd = (e: React.MouseEvent) => {
    const { tableLIneItem } = this.state;
    this.setState({
      tableLIneItem: [...tableLIneItem, InvestDetailPane.createNewLineItem()],
    });
  };

  // on row delete
  onRowDelete = (rowIndex: number) => {
    let { tableLIneItem } = this.state;
    const { deletedItems } = this.state;
    const deletedItem = tableLIneItem[rowIndex];
    if (tableLIneItem.length <= 1) {
      tableLIneItem = [InvestDetailPane.createNewLineItem()];
    } else {
      tableLIneItem = [
        ...tableLIneItem.slice(0, rowIndex),
        ...tableLIneItem.slice(rowIndex + 1),
      ];
    }
    deletedItem.is_active = false;
    if (typeof deletedItem.row_details_id === "number") {
      deletedItems.push(deletedItem);
    }
    this.setState(
      {
        tableLIneItem,
        deletedItems,
      }
    );
  };

  // handle pre_req modal visible
  handlePreRequisites = (rowIndex: number, pre_req: any, pre_req_data: any) => {
    this.setState({
      PreRequisitesVisible: true,
      selectedPreREQdata: pre_req,
      selectedDependency: pre_req_data,
    });
  };

  onPreRequisitesVisibleChange = (visible: boolean) => {
    this.setState({
      PreRequisitesVisible: visible,
    });
  };


  // handler for  doc change 
  handlePanDocChange = (
    newFileName?: string,
    documentsName?: string,
    idx?: any,
    lineItemValues?: any
  ) => {
    const updatedLineItem = {
      ...lineItemValues.employee_investment_data[idx],
    };
    updatedLineItem.file_name = newFileName;
    updatedLineItem.document_name = documentsName;
    updatedLineItem.isNew = true;
    updatedLineItem.emp_id = this.props.employeeID;
    this.setState({});
    lineItemValues.employee_investment_data = [
      ...lineItemValues.employee_investment_data.slice(0, idx),
      updatedLineItem,
      ...lineItemValues.employee_investment_data.slice(idx + 1),
    ];
    this.setState({
      a: 89,
    });
  };

  render() {
    return (
      <>
        <Spin spinning={this.state.loadDetail}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              width: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "67%",
                minHeight: window.innerHeight - 250 + "px",
                marginRight: "20px",
              }}
            >
              <div
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Select
                  suffixIcon={
                    <svg
                      width="10"
                      height="8"
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 16L19.5263 0.25H0.473721L10 16Z"
                        fill="#4F4F4F"
                      />
                    </svg>
                  }
                  style={{
                    width: "170px",
                    color: "black",
                    fontWeight: 700,
                  }}
                  value={this.state.selectedFy}
                  onChange={this.handleFy}
                >
                  {this.state.opts_fy.map((opt) => (
                    <Select.Option key={opt} value={opt}>
                      {`FY ${opt}`}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              {this.state.storeIncomingData ? this.handleSectionInfo() : null}
            </div>
            <div
              style={{
                flexDirection: "row",
                height: "100",
                width: "30%",
                marginTop: "5px",
                position: "fixed",
                right: "20px",

                top: "8",
              }}
            >
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingRight: "2px",
                    paddingLeft: "20px",
                    flexDirection: "column",
                    marginTop: 37,
                  }}
                >
                  {this.state.listData.map((val: any) => {
                    if (
                      // this.state.selectedFy !== this.state.opts_fy[1] &&
                      val.key === "J" &&
                      !val.isVisible
                    )
                      return null;
                    return (
                      <>
                        <span
                          style={{
                            fontSize: "14px",
                            color: val.key === "I" ? "#9E65DE" : "#3483EA",
                            fontWeight: 500,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <span>{val.name}</span>
                          <span>
                            <Currency
                              style={{
                                color: val.key === "I" ? "#9E65DE" : "#3483EA",
                              }}
                              currencySymbol={"₹"}
                            >
                              {val.value}
                            </Currency>
                          </span>
                        </span>
                        <Divider
                          type="horizontal"
                          style={{ marginTop: 10, marginBottom: 10 }}
                        />
                      </>
                    );
                  })}
                </div>
              </>
            </div>
          </div>
        </Spin>
      </>
    );
  }
}

export default connector(InvestDetailPane);
