import React, { useEffect, useState, useCallback } from "react";
import { Prompt, RouteChildrenProps } from "react-router";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Radio,
  notification,
  Spin,
  Modal,
  Tag,
} from "antd";
import DatePicker from "~/component/antd-overrides/DatePicker";
import { PickupItem } from "~/fragment/pickup-field/pickupSlice";
import { isAfter } from "date-fns";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import ReimbursementLineItem from "./ReimbursementLineItem";
import {
  Reimbursement,
  ReimbursementLineItems,
} from "~/feature/reimbursement/ReimbursementModel";
import { format } from "date-fns";
import { APIState, fetchUtil } from "~/api/common";
import {
  getReimburseByID,
  saveReimbursementDetails,
  editReimbursementDetails,
} from "~/api/reimburse";
import {
  useCESSRateList,
  useGSTRateList,
  useCustomerList,
  useUnitList,
} from "~/lib/hook/api-hook/picklistHooks";
import CategorySelect from "~/fragment/form-input/CategorySelect";
import VendorSelect from "~/fragment/form-input/VendorSelect";
import AmountsAreSelect from "~/fragment/form-input/AmountsAreSelect";
import ApplyCess from "~/fragment/form-input/ApplyCess";
import OcrSelect, { OCRData } from "~/fragment/form-input/OcrSelect";
import ApproveModal from "./Modal/ApproveModal";
import { LoginRole } from "~/api/auth";
import { FORMAT_DATE, formatDate, parseInputNumber } from "~/lib";
import { isSameCompany } from "~/utils";
import commonStyle from "~/component/common.module.css";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { getLineItemTotal, TaxTeritoryType } from "~/lib/taxes";
import { Required } from "~/lib/form_rules";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useCqdForm from "~/lib/hook/useCqdForm";
import { LineItemError } from "~/fragment/line-items/LineItemTypes";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
import { registerEvent } from "~/analytics";
import styles from "./ReimbursementPage.module.css";

import * as ga from "~/contants/gaConstants";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";

type PathParams = {
  id?: string;
  slug?: "new" | "copy";
};

const { TextArea } = Input;

/**
 * Get "place_of_supply_id" and "activeRole" from the store by calling mapStateToProps
 */
const mapStateToProps = (state: AuthState) => ({
  place_of_supply_id: state.mainAppSlice.user.place_of_supply,
  activeRole: state.mainAppSlice.user.activeRole,
  baseCompanyGstin: state.mainAppSlice.user.gstin,
});

/**
 * Connect to store
 */
const connector = connect(mapStateToProps);
const { confirm } = Modal;
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props
  extends PropsFromRedux,
    RouteChildrenProps<PathParams, { fileList: Array<File> }> {}

type VendorPickList = PickupItem & {
  place_of_supply_id: number;
  symbol_name: string;
  symbol: string;
  email_id: string;
  billing_address: string;
  billing_address2: string;
  billing_address_city: string;
  billing_address_country: string;
  billing_address_pincode: string;
  billing_address_state: string;
  company_name: string;
  customer_currency: number;
  customer_id: number;
  customer_idx: any;
  gst_registration_type_id: number;
  gstin: string;
  name: string;
  place_of_supply: string;
  state_code: number;
  customer_gstin_id: any;
};

interface GeneralState {
  id: number;
  isEditing: boolean;
  isDirty: boolean;
  apiState: APIState;
  flagCustomer: number | undefined;
  interState: boolean;
  openApproveModal: boolean;
  modalTitle: string;
  isDataLoading: boolean;
  isOtherTeritory: boolean;
  hideTaxes: boolean;
  isGstUnregistered: boolean;
}

const ReimbursementDetailsPage = (props: Props) => {
  /**
   *  Initial states
   */

  // here we maintain two types of reimbursement for company and for self
  // incase  for company we calculate tax(cgst,sgst,igst) according to amounts are field and also apply cess
  const [isWarningModalCanelled, setIsWarningModalCancelled] = useState<
    boolean
  >(false);
  const [isLineItemChanged, setIsLineItemChanged] = useState<boolean>(false);
  const [lineItemErrors, setLineItemErrors] = useState<Array<Array<string>>>(
    []
  );

  const [deletedItems, setDeletedItems] = useState<
    Array<ReimbursementLineItems>
  >([]);

  const { gstRateList } = useGSTRateList();
  const { cessRateList } = useCESSRateList();
  const { unitList } = useUnitList();
  const { customerList } = useCustomerList();
  const [isDescriptionDirty, setDescriptionDirty] = useState(false);

  // This is the general state
  const [generalState, setGeneralState] = useState<GeneralState>({
    id: -1,
    isDirty: false,
    isEditing: true,
    apiState: "idle",
    flagCustomer: 0,
    interState: false,
    openApproveModal: false,
    modalTitle: "",
    isDataLoading: false,
    isOtherTeritory: false,
    hideTaxes: false,
    isGstUnregistered: false,
  });

  // Reimbursement state: This state is responsible for all view data
  const { prevData, data, update, formProps } = useCqdForm<
    Partial<Reimbursement>
  >();

  /**
   * Detecting the type of reimbursement i.e. new of esisting
   */

  const reimbursementId = props.match?.params.id ? +props.match.params.id : -1;
  const isNewReimbursement = reimbursementId === -1;

  /**
   * Initial variables
   */

  /**
   * Helpers
   */
  const setInterStateFlag = () => {
    setGeneralState({
      ...generalState,
      interState:
        data.extra?.vendor?.state_code !== props.place_of_supply_id || false,
      isDataLoading: generalState.isEditing
        ? false
        : generalState.isDataLoading,
      isOtherTeritory:
        data.extra?.vendor?.billing_address_country?.toLowerCase() === "india"
          ? false
          : true,
      isGstUnregistered: data.extra?.vendor?.gstin ? false : true,
    });
  };

  const checkOtherTerritory = (country: string | null | undefined) => {
    if (country) {
      return country.toLowerCase() !== "india";
    }
    return false;
  };

  // this is used to update reimbursement data

  const loadReimburseData = useCallback(
    async (_expenseId: number) => {
      setGeneralState({
        ...generalState,
        isDataLoading: true,
        apiState: "loading",
      });
      try {
        const { ok, data: responseData, message } = await getReimburseByID(
          reimbursementId
        );
        const res = responseData as unknown;
        const reimburseData = res as Reimbursement;
        if (!ok) {
          notification.error({
            message: "Failed to load details",
            description: message,
          });
        } else {
          setIsWarningModalCancelled(true);
          setDescriptionDirty(true);
          update({
            ...reimburseData,
            extra: {
              vendor: {
                __init__: {
                  customer_idx: reimburseData?.customer_idx,
                  uid: Math.random(),
                },
              } as any,
              hasCess: !!Number(reimburseData?.cess_amount ?? 0),
            },
          });
        }
      } catch (error) {
        console.warn(error);
        notification.warn({ message: "Unable to load expense!" });
      } finally {
        setInterStateFlag();
        setGeneralState({
          ...generalState,
          isDataLoading: false,
          isEditing: false,
          apiState: "idle",
        });
        setIsWarningModalCancelled(false);
      }
    },
    [update, props.match?.params.slug === "copy"]
  );

  const createNewLineItem = (): ReimbursementLineItems => {
    return {
      row_id: Math.random(),
      row_docs_id: Math.random(),
      is_active: true,
      description: "",
      hsn_no: "",
      amount: 0,
      igst: 0,
      cgst: 0,
      sgst: 0,
      total_amount: 0,
      invoice_no: "",
      documents: "",
      quantity: 0,
      rate: 0,
      unit_id: null,
      date: new Date(),
      cess_rate_id: 19,
      gst_rate_id:
        data.company_type === 1 || generalState.isGstUnregistered ? 1 : 9,
    };
  };

  // calculate tax info here

  const calculate = () => {
    const _lineItems = data.row_data as Array<ReimbursementLineItems>;

    let { tax_inclusion } = data;
    const lineItems = _lineItems.map((li) => ({ ...li }));
    tax_inclusion = tax_inclusion || "OutOfTax";
    const placeOfSupplyId = customerList.find(
      (it: any) => it.customer_idx === data.customer_idx
    )?.state_code;

    if (tax_inclusion === "OutOfTax") {
      update({
        extra: {
          ...data.extra,
          hasCess: false,
        },
      });
    }

    const taxTeritoryType = generalState.interState
      ? TaxTeritoryType.INTRA_STATE
      : TaxTeritoryType.SAME_STATE;

    const {
      igstSum,
      cgstSum,
      sgstSum,
      subTotalVal,
      netTotal,
      cessAmount,
      linetemsVal: lineItemsVal,
    } = getLineItemTotal(
      tax_inclusion,
      lineItems,
      taxTeritoryType,
      gstRateList,
      cessRateList,
      data.company_type === 2
    );
    update({
      row_data: lineItemsVal,
      cess_amount: cessAmount,
      igst: igstSum,
      cgst: cgstSum,
      sgst: sgstSum,
      amount: subTotalVal,
      net_total: netTotal,
    });
  };

  // showing cnfirm popup
  const showConfirm = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.reimbursementDetailsPage210
    );

    const companyType = data.company_type;
    confirm({
      title:
        "Switching  will delete all your row data.Do you still want to continue?",
      icon: <ExclamationCircleOutlined />,

      onOk: () => {
        if (data.row_data) {
          // Marking all existing line items as not active
          const updatedLineItems = [...data.row_data];
          updatedLineItems.forEach((lineItem) => {
            lineItem.is_active = false;
          });
          // Adding them into deletedItems
          const _deletedItems = [...deletedItems];
          setDeletedItems(_deletedItems.concat(updatedLineItems));

          update({
            row_data: [createNewLineItem()],
            tax_inclusion:
              companyType === 1 || generalState.isGstUnregistered
                ? "OutOfTax"
                : "ExclusiveOfTax",
            amount: 0,
            net_total: 0,
            cess_amount: 0.0,
          });
          setIsLineItemChanged(!isLineItemChanged);
        }
      },

      onCancel: () => {
        registerEvent(
          ga.EVENT_CATEGORY_BUTTON_CLICK,
          ga.EVENT_CLICK,
          ga.events.reimbursementDetailsPage211
        );

        update({
          company_type: data.company_type === 1 ? 2 : 1,
        });
        setIsWarningModalCancelled(true);
      },
    });
  };

  const validateReimbursementData = (
    reimbursementData: Partial<Reimbursement>
  ): boolean => {
    const today = new Date();
    const cMonthday = today.getDate();

    if (reimbursementData.receipt_date) {
      const receiptDate = format(reimbursementData.receipt_date!, "yyyy/MM/dd");
      if (
        // isBefore(new Date(receiptDate), startDate) ||
        isAfter(new Date(receiptDate), today)
      ) {
        notification.error({
          message: `You can not create reimbursement prior to ${format(
            new Date(receiptDate),
            "dd/MM/yyyy"
          )}. Please create at a later date`,
        });
        return false;
      }
    }
    if (
      reimbursementData.company_type === 2 &&
      !reimbursementData.customer_idx
    ) {
      notification.error({ message: "Please select vendor" });
      return false;
    }
    if (!reimbursementData.description) {
      if (!reimbursementData.documents) {
        notification.error({ message: "Please enter purpose" });
        return false;
      }
    }

    if (!reimbursementData.documents) {
      if (reimbursementData.company_type === 2) {
        notification.error({ message: "Please attach a document" });
        return false;
      } else if (reimbursementData.company_type === 1) {
        if (
          reimbursementData.net_total &&
          reimbursementData.net_total >= 1000
        ) {
          notification.error({ message: "Please attach a document" });
          return false;
        }
      }
    }

    if (reimbursementData.company_type === 1) {
      reimbursementData.tax_inclusion = "OutOfTax";
      reimbursementData.row_data!.forEach((el) => {
        el.gst_rate_id = 1;
        el.cess_rate_id = 19;
      });
    }
    return true;
  };

  const validateLineItems = (lineItems: ReimbursementLineItems) => {
    const errors: Array<LineItemError> = [];
    const error = [];
    lineItems.description = lineItems.description.trim();

    if (!lineItems.amount) {
      errors.push({
        dataIndex: "quantity",
        message: "Please enter quantity",
      });
    }

    if (!lineItems.description) {
      errors.push({
        dataIndex: "description",
        message: "Please enter description",
      });
    }
    if (!lineItems.extra) {
      lineItems.extra = { errors };
    }
    lineItems.extra.errors = errors;
    return lineItems;
  };

  const structureData = (reimburseData: Partial<Reimbursement>) => {
    reimburseData.customer_idx = data.extra?.vendor?.customer_idx;
    reimburseData.customer_id = data.extra?.vendor?.customer_id;
    reimburseData.customer_gstin_id = data.extra?.vendor?.customer_gstin_id!;
    reimburseData.cess_amount = data.cess_amount;
    reimburseData.row_data = reimburseData.row_data?.concat(deletedItems);
    if (reimburseData.company_type === 1) {
      reimburseData.tax_inclusion = "OutOfTax";
      reimburseData.row_data?.forEach((item) => {
        item.gst_rate_id = 1;
      });
    }
    reimburseData.reimbursement_date = new Date();
    if (reimburseData.row_data) {
      reimburseData.row_data = [...reimburseData.row_data, ...deletedItems];
    }
    return reimburseData;
  };

  /**
   * Side Effects
   */

  // Initial effect
  useEffect(() => {
    setLineItemErrors(reimbursementId === -1 ? [[]] : []);
    setGeneralState({
      ...generalState,
      id: reimbursementId,
      apiState: reimbursementId === -1 ? "idle" : "loading",
      isEditing: isNewReimbursement,
    });
    update({
      company_type: 2,
      igst: 0,
      cgst: 0,
      sgst: 0,
      amount: 0,
      net_total: 0,
      tax_inclusion: "ExclusiveOfTax",
      row_data: [createNewLineItem()],
      extra: {
        hasCess: false,
      },
    });
  }, []);

  // Loading saved data
  useEffect(() => {
    if (reimbursementId !== -1) {
      loadReimburseData(reimbursementId);
    }
  }, [reimbursementId, props.match, props.location.search, loadReimburseData]);

  // After changing any line item
  useEffect(() => {
    if (data.row_data) {
      calculate();
    }
  }, [isLineItemChanged]);

  // After changing applyCess
  useEffect(() => {
    if (data.extra?.hasCess && data.row_data) {
      const updatedLineItems = [...data.row_data];
      updatedLineItems.forEach((it) => {
        it.cess_rate_id = isNewReimbursement ? 19 : it.cess_rate_id;
      });
      update({
        row_data: updatedLineItems,
        cess_amount: data.extra?.hasCess ? data.cess_amount : "0.00",
      });
      setIsLineItemChanged(!isLineItemChanged);
    } else if (!data.extra?.hasCess && data.row_data) {
      const updatedLineItems = [...data.row_data];
      updatedLineItems.forEach((it) => {
        it.cess_rate_id = 19;
      });
      update({
        row_data: updatedLineItems,
        cess_amount: "0.00",
      });
      setIsLineItemChanged(!isLineItemChanged);
    }
  }, [data.extra?.hasCess]);

  // After changing tax_inclusion
  useEffect(() => {
    if (data.row_data && gstRateList && !generalState.isDataLoading) {
      const taxInclusion = data.tax_inclusion;
      const updatedLineItems = [...data.row_data];

      let hideTaxes = generalState.hideTaxes;

      if (taxInclusion === "OutOfTax") {
        updatedLineItems.forEach((lineItem) => {
          lineItem.cess_rate_id = 19;
          lineItem.gst_rate_id = gstRateList[0].id;
        });
        /*
           Whenever the user selects outOfTax then to hide IGST SGST and CGST fields
         */
        hideTaxes = true;
      } else if (taxInclusion === "ExclusiveOfTax") {
        updatedLineItems.forEach((lineItem) => {
          lineItem.cess_rate_id = lineItem.cess_rate_id || 19;
          lineItem.gst_rate_id =
            lineItem.gst_rate_id !== 1 ? lineItem.gst_rate_id : 9;
        });

        hideTaxes = false;
      } else if (taxInclusion === "InclusiveOfTax") {
        updatedLineItems.forEach((lineItem) => {
          lineItem.cess_rate_id = lineItem.cess_rate_id || 19;
          lineItem.gst_rate_id =
            lineItem.gst_rate_id === 1 ? 9 : lineItem.gst_rate_id;
        });
        hideTaxes = false;
      }
      setGeneralState({
        ...generalState,
        hideTaxes,
      });
      update({
        row_data: updatedLineItems,
      });
      setIsLineItemChanged(!isLineItemChanged);
    }
  }, [data.tax_inclusion]);

  // Toggling between `for self` and `for compnay` i.e. toggling between company_type s
  useEffect(() => {
    if (isWarningModalCanelled) {
      setIsWarningModalCancelled(false);
    } else if (data.amount) {
      showConfirm();
    }
  }, [data.company_type]);

  useEffect(() => {
    setIsLineItemChanged(!isLineItemChanged);
  }, [generalState.interState]);

  // After changing vendor
  useEffect(() => {
    if (data.extra?.vendor?.customer_idx && data.row_data) {
      // If other territoty vendor or gst unregistered vendor
      if (
        checkOtherTerritory(data.extra?.vendor?.billing_address_country) ||
        data.extra.vendor.gstin === null
      ) {
        const updatedLineItems = [...data.row_data];
        updatedLineItems.forEach((item) => {
          item.gst_rate_id = 1;
        });
        update({
          tax_inclusion: "OutOfTax",
          row_data: updatedLineItems,
        });
        setIsLineItemChanged(!isLineItemChanged);
      } else {
        const updatedLineItems = [...data.row_data];
        let taxInclusion = data.tax_inclusion;
        // If the previous vendor was other trritory or gst unregistered
        if (prevData.extra?.vendor?.customer_idx) {
          const prevVendor = prevData.extra.vendor;
          updatedLineItems.forEach((item) => {
            item.gst_rate_id = prevVendor.gstin ? item.gst_rate_id : 9;
          });
          taxInclusion = prevVendor.gstin
            ? data.tax_inclusion
            : "ExclusiveOfTax";
        }
        update({
          tax_inclusion: taxInclusion,
          row_data: updatedLineItems,
        });
        setIsLineItemChanged(!isLineItemChanged);
        setGeneralState({
          ...generalState,
          interState: false,
        });
      }
    }
    if (data.extra && data.extra.vendor) {
      setInterStateFlag();
    }
  }, [data.extra?.vendor?.customer_idx]);

  /**
   * Event handlers
   */
  const handleRowDataChange = usePersistantCallbackRef(
    (newRowData: Array<ReimbursementLineItems>) => {
      update({
        row_data: newRowData,
      });
    }
  );
  const onSaveandNew = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.reimbursementDetailsPage212
    );

    try {
      await formProps.form.validateFields();
      const newRowData = [...data.row_data!];
      newRowData.forEach(validateLineItems);
      const hasRowDataError = newRowData.some(
        (it: any) => it.extra?.errors.length > 0
      );
      handleRowDataChange(newRowData);
      if (hasRowDataError) {
        notification.warning({
          message: "Please fill all required fields for the line items !",
        });
        return;
      }
      setGeneralState({
        ...generalState,
        apiState: "loading",
        isDataLoading: true,
      });
      const reimburseData = { ...data };
      const { id } = reimburseData;
      const reimburse = structureData({ ...data });
      const { ok, message } =
        id === -1 || !id
          ? await saveReimbursementDetails(reimburse as Reimbursement)
          : await editReimbursementDetails(id, reimburse as Reimbursement);
      if (!ok) {
        notification.error({ message });
        setGeneralState((oldGeneralState) => ({
          ...oldGeneralState,
          apiState: "idle",
          isDataLoading: false,
        }));
      } else {
        setGeneralState({
          ...generalState,
          isDirty: false,
        });
        window.location.reload();
        window.history.replaceState(null, "");
        if (id === -1) {
          notification.success({
            message: "Successfully created reimbursement",
          });
        } else {
          notification.success({
            message: "Reimbursement updated successfully",
          });
        }
      }
      setGeneralState((oldGeneralState) => ({
        ...oldGeneralState,
        apiState: ok ? "success" : "error",
        isDataLoading: false,
      }));
    } catch (error) {
      console.warn("Form validation failed", error);
    }
  };
  const handleSave = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.reimbursementDetailsPage213
    );

    try {
      await formProps.form.validateFields();
      const newRowData = [...data.row_data!];
      newRowData.forEach(validateLineItems);
      const hasRowDataError = newRowData.some(
        (it: any) => it.extra?.errors.length > 0
      );
      handleRowDataChange(newRowData);
      if (hasRowDataError) {
        notification.warning({
          message: "Please fill all required fields for the line items !",
        });
        return;
      }
      const reimburs = structureData({ ...data });
      await formProps.form.validateFields();
      if (!validateReimbursementData(reimburs)) {
        return;
      }
      setGeneralState({
        ...generalState,
        apiState: "loading",
        isDataLoading: true,
        isDirty: true,
      });
      // const success = false;
      const reimburseData = { ...data };
      const { id } = reimburseData;
      const reimburse = structureData({ ...data });
      reimburse.row_data?.forEach((row: any) => {
        if (row.row_id < 1) {
          row.row_id = 0;
        }
        if (row.row_docs_id < 1) {
          row.row_docs_id = 0;
        }
      });
      const { ok, message } =
        id === -1 || !id
          ? await saveReimbursementDetails(reimburse as Reimbursement)
          : await editReimbursementDetails(id, reimburse as Reimbursement);

      if (!ok) {
        notification.error({ message });
        setGeneralState((oldGeneralState) => ({
          ...oldGeneralState,
          apiState: "idle",
          isDataLoading: false,
        }));
      } else {
        setGeneralState({
          ...generalState,
          isDirty: false,
          isDataLoading: false,
        });
        id === -1 || !id
          ? notification.success({
              message: "Successfully created reimbursement",
            })
          : notification.success({
              message: "Reimbursement updated successfully",
            });
        props.history.push(`/app/reimbursement`);
      }
      setGeneralState((oldGeneralState) => ({
        ...oldGeneralState,
        apiState: ok ? "success" : "error",
        isDataLoading: false,
      }));
    } catch (error) {
      console.warn("Form validation failed", error);
    }
  };
  const RejectClick = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.reimbursementDetailsPage214
    );

    setGeneralState({
      ...generalState,
      openApproveModal: true,
      modalTitle: "Rejection",
    });
  };

  // when approve is clicked
  const ApproveClick = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.reimbursementDetailsPage215
    );

    update({
      paid_amount: data.total_amount,
    });
    setGeneralState({
      ...generalState,
      openApproveModal: true,
      modalTitle: "Approve",
    });
  };

  const onEdit = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.reimbursementDetailsPage216
    );

    setGeneralState({
      ...generalState,
      isEditing: true,
    });
  };
  //  when delete reim data
  const handleDelete = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.reimbursementDetailsPage217
    );

    const reimbursement = data;

    Modal.confirm({
      title: (
        <>
          <div>Do you want to delete the Reimbursement?</div>

          <div className={commonStyle.deleteCfmDlgDetail}>
            Customer Name {reimbursement?.extra?.vendor?.company_name ?? "N/A"},
            Invoice no. {reimbursement.invoice_no ?? "N/A"}
            ,<br /> Date{" "}
            {reimbursement.receipt_date
              ? formatDate(reimbursement.receipt_date)
              : "N/A"}
          </div>
        </>
      ),
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        const { ok, message } = await fetchUtil(
          "POST",
          "/update_receipt/delete_receipt",
          {
            receipt_id: data.receipt_id,
          }
        );
        if (!ok) {
          notification.warn({ message });
        } else {
          notification.success({ message });
          props.history.goBack();
        }
      },
    });
  };

  // reim row data  cell change
  const handleCellChange = usePersistantCallbackRef(
    (
      key: string,
      rowIndex: number,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (key === "description" && event.target.classList) {
        setDescriptionDirty(true);
      }
      setGeneralState({
        ...generalState,
        isDirty: true,
      });
      let lineItems = data.row_data as Array<ReimbursementLineItems>;
      const value =
        key === "quantity" || key === "rate"
          ? parseInputNumber(event.target.value, lineItems[rowIndex][key])
          : event.target.value;
      lineItems = [
        ...lineItems.slice(0, rowIndex),
        {
          ...lineItems[rowIndex],
          [key]: value,
        },
        ...lineItems.slice(rowIndex + 1),
      ];
      update({
        row_data: lineItems,
      });
      setIsLineItemChanged(!isLineItemChanged);
    }
  );

  // Update line-item description on form description change
  useEffect(() => {
    if (data.description && !isDescriptionDirty) {
      handleCellChange("description", 0, {
        target: { value: data.description },
      } as any);
    }
  }, [data.description]);

  const handleNewRow = (e: React.MouseEvent) => {
    if (data.row_data) {
      update({
        row_data: [...data.row_data, createNewLineItem()],
      });
      setLineItemErrors([...lineItemErrors, []]);
    }
  };
  // handle delete row data
  const handleRowDelete = (rowIndex: number) => {
    if (data.row_data) {
      const deletedItem = data.row_data[rowIndex];
      let updatedLineItems = [...data.row_data];
      // let updatedLineItemErrors = [[]];
      const _deletedItems = [...deletedItems];
      if (data.row_data.length < 2) {
        updatedLineItems = [createNewLineItem()];
        // updatedLineItemErrors = [[]];
      } else if (data.row_data.length > 1) {
        updatedLineItems = [
          ...data.row_data.slice(0, rowIndex),
          ...data.row_data.slice(rowIndex + 1),
        ];
      }
      deletedItem.is_active = false;
      if (typeof deletedItem.row_id === "number") {
        _deletedItems.push(deletedItem);
      }
      // Updating states
      update({
        row_data: updatedLineItems,
      });
      // setLineItemErrors(updatedLineItemErrors);
      setDeletedItems(_deletedItems);
      setIsLineItemChanged(!isLineItemChanged);
    }
  };
  const disabledDaterow = (current: Date) => {
    const today = new Date();
    return isAfter(current, today);
  };
  const handleNewVendor = () => {
    return;
  };
  const handleVisibilityChange = (visibility: boolean) => {
    setGeneralState({
      ...generalState,
      openApproveModal: visibility,
    });
    return;
  };

  const handleOcrData = (ocrData: OCRData, rawOcrData: any) => {
    if (data.company_type === 2) {
      if (!isSameCompany(rawOcrData)) {
        notification.warning({
          message:
            "You are adding an invoice that seems to have been raised to a different company but yours.",
          duration: 0,
        });
      }
    }

    const _deletedItems = data.row_data?.map((item) => {
      item.is_active = false;
      return item;
    });
    if (_deletedItems) {
      setDeletedItems([...deletedItems, ..._deletedItems]);
    }
    const rate = gstRateList.find((el) => el.rate === ocrData.taxRate);
    update({
      receipt_date: ocrData.receipt_date,
      invoice_no: ocrData.invoice_no,
      category_id: ocrData.category_id,
      row_data: [
        {
          ...createNewLineItem(),
          row_details_id: Math.random(),
          quantity: ocrData.amount ? 1 : 0,
          rate: ocrData.amount,
          sgst: ocrData.sgst,
          cgst: ocrData.cgst,
          igst: ocrData.igst,
          hsn_no: ocrData.hsn,
          gst_rate_id: rate?.id ? rate.id : 1,
          extra: {
            errors: [],
          },
        } as any, // Any is to just work around
      ],
      extra: {
        ...data.extra,
        vendor: {
          __init__: {
            gstin: ocrData.gstin,
            uid: Math.random(),
          },
        } as any,
      },
    });
    setInterStateFlag();
    setIsLineItemChanged(!isLineItemChanged);
  };
  const handleOCRLoading = (isLoading: boolean) => {
    setGeneralState({
      ...generalState,
      isDataLoading: isLoading,
    });
  };

  // handle ocr data
  const handleOcrDelete = () => {
    const _deletedItems = data.row_data?.map((item) => {
      item.is_active = false;
      return item;
    });
    if (_deletedItems) {
      setDeletedItems([...deletedItems, ..._deletedItems]);
    }
    update({
      documents: "",
      description: "",
      document_name: "",
      receipt_date: undefined,
      reimbursement_date: data.reimbursement_date,
      invoice_no: "",
      customer_idx: undefined,
      category_id: undefined,
      row_data: [createNewLineItem()],
      extra: {
        vendor: undefined,
        hasCess: false,
      },
    });
    setIsLineItemChanged(!isLineItemChanged);
    return;
  };

  /**
   * Utility functions
   */

  const renderReimburseHeader = () => {
    const isOtherTeritory = generalState.isOtherTeritory;
    const { isEditing } = generalState;
    const disabled = !isEditing;
    const dateView = generalState.isEditing ? null : (
      <Form.Item className={commonStyle["w-100"]} label="Date">
        <input
          className="ant-input"
          defaultValue={data.receipt_date ? formatDate(data.receipt_date) : ""}
          readOnly
        />
      </Form.Item>
    );

    // basis of company_type some fields are hidden
    return (
      <>
        <Row>
          <Col style={{ width: "18%" }}>
            <Form.Item className={commonStyle["w-100"]} name="company_type">
              <Radio.Group disabled={disabled}>
                <Radio value={1}>For self</Radio>
                <Radio value={2} disabled={gstRateList.length === 0}>
                  For company
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col style={{ width: "82%" }}>
            {!isNewReimbursement ? (
              <Tag
                style={{ marginLeft: "50px" }}
                color={
                  data.status === 1
                    ? "#A9F3C3"
                    : data.status === -1
                    ? "#F0A5CD"
                    : "#45A5F2"
                }
              >
                <span style={{ color: "black" }}>
                  {data.status === 1
                    ? "Approved"
                    : data.status === -1
                    ? "Rejected"
                    : "Pending"}
                </span>
              </Tag>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row style={{ width: "100%" }} gutter={{ md: 8, lg: 8, xl: 16 }}>
          <Col style={{ width: "80%" }}>
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              gutter={{ md: 30, lg: 32, xl: 100 }}
            >
              <Col md={12} lg={12} xl={10} xxl={10}>
                <Row gutter={{ md: 8, lg: 16 }}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      className={commonStyle["w-100"]}
                      name="invoice_no"
                      label="Invoice no"
                      rules={
                        data.company_type !== 1
                          ? Required("Invoice no")
                          : undefined
                      }

                      // required={generalState.isEditing}
                    >
                      <Input autoFocus readOnly={!generalState.isEditing} />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    {dateView || (
                      <Form.Item
                        className={commonStyle["w-100"]}
                        name="receipt_date"
                        label="Date"
                        rules={Required("Date")}
                        required={generalState.isEditing}
                      >
                        <DatePicker
                          disabledDate={disabledDaterow}
                          format={FORMAT_DATE}
                          className={commonStyle["w-100"]}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Row gutter={{ md: 8, lg: 16 }}>
                  {!isNewReimbursement ? (
                    <>
                      <Col sm={24} lg={12}>
                        <Form.Item
                          className={commonStyle["w-100"]}
                          name="id"
                          label="Receipt id"
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col sm={24} lg={12}>
                        <Form.Item
                          className={commonStyle["w-100"]}
                          label="Apply Date"
                        >
                          <input
                            className="ant-input"
                            defaultValue={
                              data.reimbursement_date
                                ? formatDate(data.reimbursement_date as Date)
                                : ""
                            }
                            readOnly
                          />
                        </Form.Item>
                        {/* )} */}
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
                <Form.Item
                  name="description"
                  label="Narration"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter purpose!",
                    },
                  ]}
                  required={generalState.isEditing}
                >
                  <TextArea
                    required={generalState.isEditing}
                    readOnly={disabled}
                    rows={2}
                  />
                </Form.Item>
                {/* {categoryView || ( */}
                <>
                  <Form.Item name="category_id" rules={Required("Category")}>
                    <CategorySelect
                      required={generalState.isEditing}
                      readOnly={!generalState.isEditing}
                    />
                  </Form.Item>
                </>
                {/* {vendorView || ( */}
                <>
                  <Form.Item
                    name={["extra", "vendor"]}
                    rules={
                      data.company_type !== 1 ? Required("Vendor") : undefined
                    }
                  >
                    <VendorSelect
                      activeRole={props.activeRole}
                      required={
                        data.company_type === 2 && generalState.isEditing
                      }
                      readOnly={!generalState.isEditing}
                      sendVendorDetail={() => {
                        return;
                      }}
                      showGstUnregistered={
                        data.extra?.vendor?.customer_idx ? true : false
                      }
                    />
                  </Form.Item>
                </>
                <Row gutter={{ md: 8, lg: 16 }}>
                  {data.company_type === 2 ? (
                    <>
                      <Col sm={24} lg={12}>
                        <Form.Item
                          name="tax_inclusion"
                          label="Amounts are"
                          rules={[
                            {
                              required: true,
                              message: "Please select amounts are!",
                            },
                          ]}
                          required={generalState.isEditing}
                        >
                          <AmountsAreSelect
                            readOnly={
                              !generalState.isEditing || isOtherTeritory
                            }
                            vendorUnregFlag={
                              data.extra?.vendor?.gstin !== "" ? false : true
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        sm={24}
                        lg={11}
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        <Form.Item
                          label="Apply cess"
                          name={["extra", "hasCess"]}
                        >
                          <ApplyCess
                            readOnly={!generalState.isEditing}
                            disabled={data.tax_inclusion === "OutOfTax"}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
              <Col md={10} lg={10} xl={10} xxl={10} style={{ width: "70%" }}>
                <OcrSelect
                  value={data}
                  onFileUpload={update}
                  onOcrData={handleOcrData}
                  onLoading={handleOCRLoading}
                  readOnly={!generalState.isEditing}
                  onOcrDelete={handleOcrDelete}
                  DragFile={props.location?.state?.fileList!}
                />
              </Col>
              <Col md={2} lg={2} xl={2} xxl={2} />
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  /**
   *  Utility variables
   */

  const { isEditing, isDataLoading } = generalState;
  const { status } = data;

  /**
   * Subtotal,total etc calulate
   */
  const footerValues = {
    cess: data.cess_amount ? +data.cess_amount : 0,
    igst: data.igst,
    cgst: data.cgst,
    sgst: data.sgst,
    amount: data.amount ? +data.amount : 0,
    net_total: data.net_total ? +data.net_total : 0,
  };

  /**
   * If isEditing true the disable false
   * Else isEditing false disable true
   */
  const disabled = !generalState.isEditing;
  const activeRole = props.activeRole;

  return (
    <IMTContent fullwidth withoutMargin={true}>
      <Helmet>
        <title>{titles.ReimbursementDetailsPage}</title>
      </Helmet>
      <IMTPageHeader
        breadcumTexts={[
          "Reimbursement",
          isNewReimbursement
            ? "New"
            : `Reimbursement details (${reimbursementId})`,
        ]}
        actions={
          <Space align="center">
            {isNewReimbursement ? (
              <Button
                type="primary"
                htmlType="submit"
                disabled={isDataLoading}
                style={{
                  marginRight: "0.5em",
                }}
                onClick={onSaveandNew}
              >
                Save and New
              </Button>
            ) : (
              ""
            )}
            {isNewReimbursement ? (
              <Button
                type="primary"
                disabled={isDataLoading}
                style={{
                  marginRight: "0.5em",
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            ) : (
              ""
            )}
            {(props.activeRole === LoginRole.ADMIN ||
              props.activeRole === LoginRole.SUPERuSER) &&
            data.status === 0 ? (
              <>
                <Button
                  disabled={isDataLoading}
                  style={{
                    marginRight: "0.5em",
                    backgroundColor: "#A9F3C3",
                  }}
                  onClick={ApproveClick}
                >
                  Approve
                </Button>
                <Button
                  disabled={isDataLoading}
                  style={{
                    marginRight: "0.5em",
                    backgroundColor: "#F0A5CD",
                  }}
                  onClick={RejectClick}
                >
                  Reject
                </Button>
              </>
            ) : (
              ""
            )}
            {/* If  activeRole is not "Employee" then nothin show*/}
            {/* If  activeRole is "Employee" and if not IsNew(edit) then show*/}
            {props.activeRole !== 2 ? null : isNewReimbursement ? null : (
              <Button
                type="primary"
                disabled={status !== 0}
                style={{
                  marginRight: "0.5em",
                  minWidth: "80px",
                }}
                loading={isDataLoading}
                onClick={isEditing ? handleSave : onEdit}
              >
                {generalState.isEditing
                  ? "Save"
                  : isDataLoading
                  ? "loading"
                  : "Edit"}
              </Button>
            )}
            {/* If  isEditing=true (edit reimbursement)*/}
            {isEditing && props.activeRole !== LoginRole.ADMIN ? (
              <Button
                type="dashed"
                onClick={() => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    ga.events.reimbursementDetailsPage218
                  );

                  if (isNewReimbursement) {
                    props.history.goBack();
                  } else {
                    window.location.reload();
                  }
                }}
                // disabled={savebuttonDisable || saveNewDisable}
              >
                Cancel
              </Button>
            ) : null}

            {props.activeRole !==
            2 ? null : isNewReimbursement ? null : generalState.isEditing ? null : (
              <Button danger onClick={handleDelete} disabled={status !== 0}>
                Delete
              </Button>
            )}
          </Space>
        }
      />
      <div className={styles.commonContent}>
        <Spin spinning={isDataLoading} delay={500}>
          {/* Total page form */}
          <Form {...formProps}>
            <Prompt
              message="You have unsaved details. Do you still want to leave this page?"
              when={generalState.isDirty}
            />
            {renderReimburseHeader()}
            <ReimbursementLineItem
              lineItems={data.row_data as Array<ReimbursementLineItems>}
              place_of_supply_id={props.place_of_supply_id}
              errors={lineItemErrors}
              footerValues={footerValues}
              onCellChange={handleCellChange}
              onRowAdd={handleNewRow}
              onRowDelete={handleRowDelete}
              disabledDaterow={disabledDaterow}
              disabled={disabled}
              customerList={customerList}
              showGst={data.company_type === 2}
              flagCustomer={generalState.flagCustomer}
              flagCompany={data.company_type === 2}
              handleNewVendor={handleNewVendor}
              isEditing={isEditing}
              activeRole={activeRole}
              intraState={generalState.interState}
              outOfTax={data.tax_inclusion === "OutOfTax"}
              OPTS_CESS={cessRateList}
              gstPickList={gstRateList}
              applyCess={data.extra?.hasCess || false}
              unitList={unitList}
            />
          </Form>
        </Spin>
      </div>
      {/* <div>
        <h3>General State</h3>
        <code>
          <pre>{JSON.stringify(generalState, null, 2)}</pre>
        </code>
      </div>
      <div>
        <h3>Data</h3>
        <code>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </code>
      </div> */}
      <ApproveModal
        reimbursement={data}
        modalTitle={generalState.modalTitle}
        visible={generalState.openApproveModal}
        onVisibleChange={handleVisibilityChange}
        loadReimburseData={loadReimburseData}
      />
    </IMTContent>
  );
};

export default connector(ReimbursementDetailsPage);
