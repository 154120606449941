import React from "react";
import { TaxTeritoryType, TAX_INCLUSION } from "~/lib/taxes";
import { CurrencyInfo } from "~/fragment/line-items/LineItemTypes";
/**
 * Extra Renderers for eXPENSE lINE itEMS
 * See common line item renderes in src/fragment/line-items/litTableRenderers.tsx
 */

import InvoiceLineItemFooter from "./InvoiceLineItemFooter";

import { InvoiceLineItemRecord } from "./InvoiceLineItemTypes";

/////////////////////////////////
//////// EXTRA COLUMN RENDERERS ///////
/////////////////////////////////

/////////////////////////////////
//////// FOOTER RENDERERS SPECIFIC TO EXPENSE ///////
/////////////////////////////////

// export const invLitMakeFooterRenderer = () => {
//   return (data: Array<InvoiceLineItemRecord>) => (
//     <InvoiceLineItemFooter rowData={data} />
//   );
// };
interface HistoryData {
  netTotal: string | number;
  tdsAmont: string | number;
  paymentDetails: any;
  invoiceId: number | null;
  conversionRate: number | undefined;
  onRefresh: () => void;
}
export const invLitMakeFooterRenderer = (
  getTotal: (data: any) => void,
  currency?: CurrencyInfo,
  taxInclusion?: TAX_INCLUSION,
  taxTeritoryType?: TaxTeritoryType,
  hasCess?: boolean,
  isCopy?: boolean,
  totalPayAmount?: number,
  tdsInfo?: {
    tdsRate?: number;
    tdsAmount?: number;
  },
  exportType?: any,
  paymentHistoryData?: HistoryData
) => {
  return (data: Array<InvoiceLineItemRecord>) => (
    <InvoiceLineItemFooter
      rowData={data}
      taxInclusion={taxInclusion}
      taxTeritoryType={taxTeritoryType}
      currency={currency}
      paymentHistoryData={paymentHistoryData}
      hasCess={hasCess}
      isCopy={isCopy!}
      totalPayAmount={totalPayAmount}
      tdsInfo={tdsInfo}
      getTotal={getTotal}
      exportType={exportType}
    />
  );
};
