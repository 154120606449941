import React, { Component } from "react";
import { notification, Checkbox, Spin, Button, Tooltip } from "antd";
import { connect, ConnectedProps } from "react-redux";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import { LoginRole } from "~/api/auth";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import TermsModalView from "~/component/TermsModalView";
import { fetchUtil, getSignedDownloadURL } from "~/api/common";
import NonIdealState from "~/component/NonIdealState";
import { HourglassOutlined } from "@ant-design/icons";
import styles from "../EmployeePage.module.css";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (
  state: AuthState,
  ownProps: { employeeID: number }
) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  employeeID: ownProps.employeeID,
  termsAndCondAgree: state.mainAppSlice.user.terms_conditions_agree,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  createCompanyDetails: (company_name: any) =>
    dispatch(action.auth.createCompanyDetails(company_name)),
  empDetailsSaved: (saveDetails: boolean) =>
    dispatch(action.auth.empDetailsSaved(saveDetails)),
  handleTermsModal: (agreeTerms: boolean) =>
    dispatch(action.auth.handleTermsModal(agreeTerms)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  employeeID: number;
  isTabUpdated: boolean;
  changeTabUpdate: () => void;
  currentTab: string;
  active: boolean;
}

interface State {
  content: any;
  agreeTerms: boolean;
  checkForButton: boolean;
  loadData: boolean;
  loadUpdateTerm: boolean;
  loadSaveContent: boolean;
  cheackLength: number;
  editorState: any;
}

class TermsAndCond extends Component<Props, State> {
  state: State = {
    editorState: EditorState.createEmpty(),
    content: null,
    agreeTerms: false,
    checkForButton: false,
    loadData: false,
    loadUpdateTerm: false,
    loadSaveContent: false,
    cheackLength: 0,
  };

  componentDidMount() {
    this.getContent();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.currentTab === "terms" && !this.props.isTabUpdated) {
      this.getContent();
      this.props.changeTabUpdate();
    }
    if (prevProps.employeeID !== this.props.employeeID) {
      this.getContent();
    }
  }

  async getContent() {
    this.setState({
      loadData: true,
    });
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_employee_terms_conditions`,
      {
        emp_id: this.props.employeeID,
      }
    );
    if (ok) {
      if (json.length > 0) {
        this.props.handleTermsModal(
          json[0].terms_conditions_agree ? true : false
        );
        this.setState(
          {
            agreeTerms: json[0].terms_conditions_agree,
            content:
              json[0].last_published_terms_conditions === null
                ? "<p>Write something here .... </p>"
                : json[0].last_published_terms_conditions,
            cheackLength: json[0].last_published_terms_conditions
              ? json[0].last_published_terms_conditions?.length
              : 0,
          },
          () => {
            const blocksFromHtml = htmlToDraft(this.state.content);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap
            );
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
              editorState,
              loadData: false,
            });
          }
        );
      } else {
        this.setState({
          loadData: false,
        });
      }
    } else {
      if (json?.length !== 0) {
        notification.error({ message });
      }
      this.setState({
        loadData: false,
      });
      this.setState({
        loadData: false,
      });
    }
  }

  onCheckChange = (e: any) => {
    this.setState({
      agreeTerms: e.target.checked,
    });
  };

  downloadTermsAndConditons = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.termsAndCond63
    );

    const { employeeID } = this.props;
    const { ok, json } = await fetchUtil(
      "POST",
      "/download_signed_terms_conditions",
      { emp_id: employeeID }
    );
    if (ok) {
      // tslint:disable-next-line: no-shadowed-variable
      const { ok, url } = await getSignedDownloadURL(json + "");
      if (ok) {
        window.open(url, "PRINT");
      }
    }
  };

  render() {
    const { employeeID, termsAndCondAgree, activeRole } = this.props;
    const { agreeTerms, editorState, cheackLength } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          minHeight: window.innerHeight - 250 + "px",
        }}
      >
        <Spin spinning={this.state.loadData} />
        {activeRole === LoginRole.EMPLOYEE && cheackLength !== 0 ? (
          <TermsModalView employeeID={employeeID} visible={termsAndCondAgree} />
        ) : null}
        {cheackLength ? (
          <>
            {activeRole === LoginRole.EMPLOYEE ? (
              <div className={styles.editor}>
                <Editor
                  toolbarHidden={true}
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName_admin"
                  readOnly={true}
                />
              </div>
            ) : null}
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Checkbox
                  checked={agreeTerms}
                  disabled={true}
                  style={{ marginRight: 5 }}
                />
                <b>
                  {agreeTerms
                    ? `Terms & Conditions accepted`
                    : `Terms & Conditions not accepted`}
                </b>
              </div>
              {agreeTerms && this.props.active ? (
                <Button
                  type="primary"
                  onClick={this.downloadTermsAndConditons}
                  style={{ marginTop: 10, width: 200 }}
                >
                  <Tooltip title="Download signed Terms and Conditions">
                    Download Signed PDF
                  </Tooltip>
                </Button>
              ) : null}
            </>
          </>
        ) : (
          <NonIdealState
            icon={<HourglassOutlined />}
            title="No terms and conditions available. "
          />
        )}
      </div>
    );
  }
}

export default connector(TermsAndCond);
