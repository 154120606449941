import React, { Component, createRef } from "react";
import { Upload, Modal, message as antMessage } from "antd";
import {
  UploadFile,
  UploadChangeParam,
  RcFile,
  RcCustomRequestOptions,
} from "antd/lib/upload/interface";
import {
  UploadProgressEvent,
  UploadRequestError,
} from "rc-upload/lib/interface";
import { nanoid } from "@reduxjs/toolkit";

import styles from "./ProfilePicUpload.module.css";
import { getSignedDownloadURL, getSignedUploadURL } from "~/api/common";

import DEFAULT_DP_HREF from "~/assets/default_dp.jpeg";
import DEFAULT_COMPANY_LOGO_HREF from "~/assets/default-company-logo.png";

function getError(
  option: { method: "PUT"; action: string },
  xhr: XMLHttpRequest
) {
  const msg = `cannot ${option.method} ${option.action} ${xhr.status}'`;
  const err = new Error(msg) as UploadRequestError;
  err.status = xhr.status;
  err.method = option.method;
  err.url = option.action;
  return err;
}

function getBody(xhr: XMLHttpRequest) {
  const text = xhr.responseText || xhr.response;
  if (!text) {
    return text;
  }

  try {
    return JSON.parse(text);
  } catch (e) {
    return text;
  }
}

interface Props {
  hashedFileName?: string;
  onChange: (newHashedFileName: string) => void;
  square?: boolean;
  is_disable: boolean;
  companyLogo: boolean;
}
interface State {
  previewVisible: boolean;
  fileList: [UploadFile<any>];
}

export default class ProfilePicUpload extends Component<Props, State> {
  static defaultProps = {
    square: false,
    companyLogo: false,
  };

  state: State = {
    previewVisible: false,
    fileList: [
      {
        uid: nanoid(),
        status: "uploading",
        percent: 10,
        name: "that-cool-dp.png",
        size: 1024,
        type: "image/jpeg",
        url: this.props.companyLogo
          ? DEFAULT_COMPANY_LOGO_HREF
          : DEFAULT_DP_HREF,
      },
    ],
  };

  uploadRef = createRef<any>();
  imgRef = createRef<HTMLImageElement>();
  componentDidMount() {
    if (this.props.hashedFileName) {
      this.tryUpdateFileEntryWithSignedURL(this.props.hashedFileName);
    } else {
      this.updateFileEntryWhenNoHashedFileName();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>) {
    if (prevProps.hashedFileName !== this.props.hashedFileName) {
      if (this.props.hashedFileName) {
        this.tryUpdateFileEntryWithSignedURL(this.props.hashedFileName);
      } else {
        this.updateFileEntryWhenNoHashedFileName();
      }
    }
  }

  updateFileEntryWhenNoHashedFileName = () => {
    const fileEntry: UploadFile<any> = {
      ...this.state.fileList[0],
      status: "done",
      percent: 100,
    };
    this.setState({
      fileList: [fileEntry],
    });
  };

  tryUpdateFileEntryWithSignedURL = async (newFileName: string) => {
    if (this.state.fileList[0].name !== newFileName) {
      const { ok, url } = await getSignedDownloadURL(newFileName);
      if (!ok) {
        antMessage.error("Failed to retrive file from server");
      } else {
        // test url for 200 OK
        const fileEntry: UploadFile<any> = {
          ...this.state.fileList[0],
          status: "done",
          percent: 100,
          name: newFileName,
          url,
        };
        // try {
        //   const tRes = await fetch(url!, { method: "GET", mode: "no-cors" });
        //   if (tRes.status === 404) {
        //     fileEntry.status = "removed";
        //   } else if (!ok) {
        //     fileEntry.status = "error";
        //     fileEntry.url = DEFAULT_DP_HREF;
        //   }
        // } catch (err) {
        //   console.warn("Failed to test profile pic url" + url, err);
        //   fileEntry.status = "error";
        //   fileEntry.url = DEFAULT_DP_HREF;
        // }
        if (this.imgRef.current) {
          this.imgRef.current.onload = () => {
            if (this.imgRef.current) {
              if (
                this.imgRef.current.naturalWidth /
                  this.imgRef.current.naturalHeight >
                1
              ) {
                // Image is horizontally wider, use object-fit: contain;
                this.imgRef.current.style.objectFit = "contain";
              } else {
                // Image is vertically taller, use object-fit: cover;
                this.imgRef.current.style.objectFit = "cover";
              }
            }
          };
        }
        fetch(url!, { method: "GET", mode: "cors" })
          .then((_tRes) => {
            // if (tRes.status === 404) {
            //   fileEntry.status = "error";
            //   fileEntry.url = DEFAULT_DP_HREF;
            // } else
            if (!ok) {
              fileEntry.status = "error";
              fileEntry.url = DEFAULT_DP_HREF;
            }
          })
          .catch((err) => {
            fileEntry.status = "error";
            fileEntry.url = DEFAULT_DP_HREF;
          })
          .finally(() => {
            this.setState({
              fileList: [fileEntry],
            });
          });
      }
    }
  };

  beforeUpload(file: any) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      antMessage.error("You can only upload JPG/PNG file!");
    }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //   antMessage.error('Image must smaller than 2MB!');
    // }
    return isJpgOrPng;
  }

  handlePreview = () => {
    this.setState({
      previewVisible: true,
    });
  };

  handleDownload = () => {
    const url = this.state.fileList[0].url + "";
    // setTimeout(() => {
    // const $a = document.createElement("a");
    // $a.href = url;
    // $a.download = "profile-pic" + url.substr(url.lastIndexOf("."));
    // $a.target = "_blank";
    // $a.click();
    saveAs(url, this.props.hashedFileName);
    // }, 0);
  };

  handlePreviewClose = () => {
    this.setState({
      previewVisible: false,
    });
  };

  handleUploadReq = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (this.uploadRef.current) {
      (window as any).$$uploadRef = this.uploadRef.current;
      try {
        this.uploadRef.current.upload.uploader.onClick();
      } catch (err) {
        console.warn(err);
      }
    }
  };

  handleUploadStateChange = ({ fileList }: UploadChangeParam) => {
    // replace the first one with new file
    const myFileList: [UploadFile<any>] = [fileList[fileList.length - 1]];
    if (myFileList[0].status === "error" && fileList.length > 1) {
      this.setState({
        fileList: [fileList[0]],
      });
      return;
    }
    this.setState({
      fileList: myFileList,
    });
  };

  handleAction = async (file: RcFile): Promise<string> => {
    const fileName = file.name;
    const { ok, message, data } = await getSignedUploadURL(fileName);
    if (!ok) {
      antMessage.error(message);
      throw Error(message);
    } else {
      const { url, hashedFileName } = data!;
      setTimeout(() => {
        this.props.onChange(hashedFileName);
      }, 1500);
      return url;
    }
  };

  overriddenRequest = (option: RcCustomRequestOptions) => {
    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = function progress(e: any) {
      if (e.total > 0) {
        e.percent = (e.loaded / e.total) * 100;
      }
      option.onProgress(e as UploadProgressEvent, option.file);
    };
    xhr.onerror = function error(_e: ProgressEvent) {
      const e = (_e as any) as ErrorEvent;
      option.onError(e.error, xhr.response, option.file);
    };

    xhr.onload = function onload() {
      // allow success when 2xx status
      // see https://github.com/react-component/upload/issues/34
      if (xhr.status < 200 || xhr.status >= 300) {
        return option.onError(
          getError({ ...option, method: "PUT" }, xhr),
          getBody(xhr)
        );
      }

      return option.onSuccess(getBody(xhr), option.file);
    };

    xhr.open("PUT", option.action);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.setRequestHeader("Content-Type", option.file.type);
    xhr.send(option.file);
  };

  render() {
    return (
      <div
        className={this.props.square ? styles.squareBase : styles.circularBase}
      >
        <Upload
          ref={this.uploadRef}
          // listType="picture"
          // showUploadList={{
          //   showDownloadIcon: false,
          //   showRemoveIcon: false,
          //   showPreviewIcon: false
          // }}
          openFileDialogOnClick={false}
          beforeUpload={this.beforeUpload}
          // onPreview={this.handlePreview}
          onDownload={this.handleDownload}
          // fileList={this.state.fileList}
          action={this.handleAction}
          showUploadList={false}
          customRequest={this.overriddenRequest}
          method="PUT"
          accept="image/*"
          onChange={this.handleUploadStateChange}
        >
          <img
            ref={this.imgRef}
            src={this.state.fileList[0].url}
            alt=""
            className={styles.profilePhoto}
          />
        </Upload>
        <Modal
          style={{ width: "25%" }}
          visible={this.state.previewVisible}
          onCancel={this.handlePreviewClose}
          footer={null}
          centered
        >
          <img
            ref={this.imgRef}
            style={{ width: "100%" }}
            className={styles.profilePhoto}
            src={this.state.fileList[0].url}
            alt="uploaded-pic"
          />
        </Modal>
        {!this.props.is_disable ? (
          <a
            className={styles.uploadAction}
            href="#upload-new"
            onClick={this.handleUploadReq}
          >
            Upload new
          </a>
        ) : null}
      </div>
    );
  }
}
