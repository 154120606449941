/**
 * FIXME: @sourav_roy Remove all console.log and pass var "ok" properly so we dont silently ignore error in ~/api
 */
import { fetchBlob, fetchUtil } from "./common";
import pick from "lodash/pick";
type StaticComponent = {
  name: string;
  amt: string;
};

type Deduction = {
  name: string;
  amt: string;
};

type Addition = {
  name: string;
  amt: string;
};

interface SalarySlip {
  static: {
    basic: StaticComponent;
    da: StaticComponent;
    hra: StaticComponent;
    conveyance: StaticComponent;
    medical_allowance: StaticComponent;
    special_allowance: StaticComponent;
    travel_allowance: StaticComponent;
    meal_coupon: StaticComponent;
  };
  deductions: {
    pf: Deduction;
    other_recurring_deduction: Deduction;
    pt: Deduction;
    esi_contribution: Deduction;
    tds: Deduction;
    fine: Deduction;
    deduction_others: Deduction;
  };
  additions: {
    performance_bonus: Addition;
    other_earnings: Addition;
    onsite: Addition;
  };
}

interface NetSalary {
  name: string;
  amt: string;
}

interface EmployeeData {
  salary_id: number;
  employee_id: number;
  company_employee_id: string;
  employeeName: string;
  designation_id: number;
  designation: string;
  email_id: string;
  present_days: number;
  total_days: number;
  month: string;
  year: string;
  template_id: number;
}

interface EmployeeSalary {
  total_rows: number;
  employeeData: EmployeeData;
  salary_slip: SalarySlip;
  net_salary: NetSalary;
}

//
export type APIPayroll = {
  company_employee_id: string;
  employeeName: string;
  designation: string;
  month: string;
  year: string;
  amt: string;
  employee_id: number;
  salary_id: number;
};

export type ProcessSalaryPayload = {
  basic?: number;
  conveyance?: number;
  da?: number;
  emp_id: number;
  fine: number;
  hra?: number;
  meal_coupon?: number;
  medical_allowance?: number;
  month: string;
  net_salary: number;
  onsite_bonus: number;
  other_deductions: number;
  other_recurring_deduction?: number;
  other_earnings: number;
  performance_bonus?: number;
  pf?: number;
  present_days: number;
  professional_tax: number;
  special_allowance?: number;
  tds: number;
  esi: number;
  total_days: number;
  travel_allowance?: number;
  year: string;
  salary_id?: number;
  template_id: number;
};

// type APIRawType = APIEmployee & {
//   total_rows: number;
// };

export type EmployeeResponse = {
  pageSize: number;
  pageNo: number;
  // pageCount: number;
  salaryList: Array<APIPayroll>;
};

export const getSalaryListEmployee = (
  pageNo: number,
  pageSize: number,
  month: string,
  fy: string,
  roleId: number,
  empId: number
) => {
  return fetchUtil("POST", "/get_salary_slip_details", {
    page_no: pageNo,
    no_of_rows: pageSize,
    fy,
    month,
    role_id: roleId,
    emp_id: empId,
  }).then(({ ok, json }) => {
    const salaryData = json as Array<EmployeeSalary>;
    const response: any = {
      pageNo,
      pageSize,
      pageCount:
        salaryData.length > 0
          ? Math.ceil(salaryData[0].total_rows / pageSize)
          : 0,
      totalArrayLength: salaryData.length > 0 ? salaryData[0].total_rows : 0,
      salaryList: [],
    };
    for (const rawSalary of salaryData) {
      const total_static: number =
        Number(rawSalary?.salary_slip?.static?.basic?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.conveyance?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.da?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.hra?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.meal_coupon?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.medical_allowance?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.special_allowance?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.travel_allowance?.amt || 0);
      const total_addition: number =
        Number(rawSalary?.salary_slip?.additions?.onsite?.amt || 0) +
        Number(rawSalary?.salary_slip?.additions?.other_earnings?.amt || 0) +
        Number(rawSalary?.salary_slip?.additions?.performance_bonus?.amt || 0);
      const earning: number = total_addition + total_static;
      const {
        pt,
        tds,
        pf,
        esi_contribution,
      } = rawSalary?.salary_slip?.deductions;
      const obj = pick(rawSalary.employeeData, [
        "company_employee_id",
        "employeeName",
        "designation",
        "month",
        "year",
        "employee_id",
        "salary_id",
      ]);
      const obj2 = pick(rawSalary.net_salary, ["amt"]);
      const merged = {
        ...obj,
        ...obj2,
        earning,
        pt: pt?.amt,
        tds: tds?.amt,
        pf: pf?.amt,
        esi_contribution: esi_contribution.amt,
      };
      response.salaryList.push(merged);
    }
    return response;
  });
};

export const getSalaryList = (
  pageNo: number,
  pageSize: number,
  month: string,
  fy: string,
  roleId?: number
) => {
  return fetchUtil("POST", "/get_salary_slip_details", {
    page_no: pageNo,
    no_of_rows: pageSize,
    fy,
    month: Number(month) < 9 ? `0${month}` : `${month}`,
    role_id: roleId,
  }).then(({ ok, json }) => {
    const salaryData = json as Array<EmployeeSalary>;
    const response: any = {
      pageNo,
      pageSize,
      pageCount:
        salaryData.length > 0
          ? Math.ceil(salaryData[0].total_rows / pageSize)
          : 0,
      totalArrayLength: salaryData.length > 0 ? salaryData[0].total_rows : 0,
      salaryList: [],
    };
    for (const rawSalary of salaryData) {
      const total_static: number =
        Number(rawSalary?.salary_slip?.static?.basic?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.conveyance?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.da?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.hra?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.meal_coupon?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.medical_allowance?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.special_allowance?.amt || 0) +
        Number(rawSalary?.salary_slip?.static?.travel_allowance?.amt || 0);
      const total_addition: number =
        Number(rawSalary?.salary_slip?.additions?.onsite?.amt || 0) +
        Number(rawSalary?.salary_slip?.additions?.other_earnings?.amt || 0) +
        Number(rawSalary?.salary_slip?.additions?.performance_bonus?.amt || 0);
      const earning: number = total_addition + total_static;
      const {
        pt,
        tds,
        pf,
        esi_contribution,
      } = rawSalary?.salary_slip?.deductions;
      const obj = pick(rawSalary.employeeData, [
        "company_employee_id",
        "employeeName",
        "designation",
        "month",
        "year",
        "employee_id",
        "salary_id",
      ]);
      const obj2 = pick(rawSalary.net_salary, ["amt"]);
      const merged = {
        ...obj,
        ...obj2,
        earning,
        pt: pt?.amt,
        tds: tds?.amt,
        pf: pf?.amt,
        esi_contribution: esi_contribution.amt,
      };
      response.salaryList.push(merged);
    }
    return response;
  });
};

export const getEmployeeSalary = (
  pageNo: number,
  pageSize: number,
  month: string,
  fy: string,
  empId?: number,
  salaryId?: number
) => {
  return fetchUtil("POST", "/get_salary_slip_details", {
    fy,
    month,
    emp_id: empId,
    salary_id: salaryId,
  }).then(({ ok, json }) => {
    const salaryData = json as Array<any>;
    const response: any = {
      salaryData,
    };
    return response;
  });
};

export const getEmployeeSalaryDetails = (salaryId?: number) => {
  return fetchUtil("POST", "/get_salary_slip_details", {
    salary_id: salaryId,
  }).then(({ ok, json }) => {
    const salaryData = json as Array<any>;
    const response: any = {
      salaryData,
    };
    return response;
  });
};

export const fetchSalaryLogs = (
  month: string,
  year: string,
  empId: number,
  templateId: number
) => {
  return fetchUtil("POST", "/fetch_salary_slip_log", {
    year,
    month: Number(month) < 9 ? `0${month}` : `${month}`,
    emp_id: empId,
    template_id: templateId,
  }).then(({ ok, json }) => {
    const salaryData = json as Array<any>;
    const response: any = {
      salaryData,
    };
    return response;
  });
};

export const getBulkSalaryList = (month: string, year: string) => {
  return fetchUtil("POST", "/fetch_bulk_salary_list", {
    month: Number(month) < 9 ? `0${month}` : `${month}`,
    year,
  }).then(({ ok, json }) => {
    const bulkSalaryData = json as Array<any>;
    const response: any = {
      bulksalaryList: bulkSalaryData,
    };
    return response;
  });
};

export const getEmployeesSal = (month: string, year: string) => {
  return fetchUtil("POST", "/get_employee_list_for_salary", {
    // salary: true,
    month: Number(month) < 9 ? `0${month}` : `${month}`,
    year,
  }).then(({ ok, json }) => {
    const employeeList = json as Array<any>;
    const response: any = {
      employeeList,
    };
    return response;
  });
};

export const checkSalarySlip = (
  month: string,
  year: string,
  emp_id: number
) => {
  return fetchUtil("POST", "/check_salary_slip_exists", {
    emp_id,
    month: Number(month) < 9 ? `0${month}` : `${month}`,
    year,
  }).then(({ ok, json, message }) => {
    return { message };
  });
};

export const downloadSalarySlip = (
  month: string,
  year: string,
  empId: number
) => {
  return fetchBlob("/download_salary_slip", {
    empId,
    month: Number(month) < 9 ? `0${month}` : `${month}`,
    year,
  }).then(({ ok, message, blob }) => {
    return blob;
  });
};

export const handleEmail = (month: string, year: string, empId: number) => {
  return fetchUtil("POST", "/email_salary_slip", {
    emp_id: empId,
    month,
    year,
  }).then(({ ok, json, message }) => {
    return { ok, message };
  });
};

export const processEmployeeSalary = (payload: ProcessSalaryPayload) => {
  return fetchUtil("POST", "/log_salary_slip", payload).then(
    ({ ok, json, message }) => {
      return { ok, message };
    }
  );
};

export const editSalary = (payload: ProcessSalaryPayload) => {
  return fetchUtil("POST", "/edit_salary", payload).then(
    ({ ok, json, message }) => {
      return { ok, message };
    }
  );
};

export const processBulkEmployeeSalary = (
  bulkSalaryData: Array<any>,
  month: any,
  year: any
) => {
  return fetchUtil("POST", "/save_bulk_salary", {
    bulk_salary_data: bulkSalaryData,
    month: Number(month) < 9 ? `0${month}` : `${month}`,
    year,
  }).then(({ ok, json, message }) => {
    return { ok, message };
  });
};
