import React, { CSSProperties } from "react";
import Currency from "~/component/Currency";

import classes from "./LineItem.module.css";

interface LIFooterRowAmountProps {
  label: string;
  labelColor?: string;
  value: number;
  valueColor?: string;
  color?: string;

  rightNode?: React.ReactNode;

  conversionRate: number;
  currencySymbol?: string;
}

export const LIFooterAmountRow = (props: LIFooterRowAmountProps) => {
  const labelStyle: CSSProperties | undefined =
    props.labelColor || props.color
      ? {
          color: props.color || props.labelColor,
        }
      : undefined;
  const currencyStyle: CSSProperties | undefined =
    props.valueColor || props.color
      ? { color: props.color || props.valueColor }
      : undefined;

  return (
    <div className={classes.footerRow}>
      <span className={classes.footerLabel} style={labelStyle}>
        {props.label}
      </span>
      <Currency
        style={currencyStyle}
        className={classes.footerAmount}
        value={props.value}
        currencySymbol={props.currencySymbol}
      />
      {props.currencySymbol !== "₹" && props.currencySymbol && (
        <span style={{ marginLeft: "4px" }}>
          {" "}
          (
          <Currency
            style={currencyStyle}
            className={classes.footerAmount}
            value={props.value * props.conversionRate}
          />
          )
        </span>
      )}
      {props.rightNode}
    </div>
  );
};

export type LIFooterProps = {
  children: React.ReactNode;
};

const LIFooter = ({ children }: LIFooterProps) => {
  return (
    <div className={classes.footerWrap}>
      <div className={classes.footer}>{children}</div>
    </div>
  );
};

export default LIFooter;
