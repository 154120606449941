import React, { Component } from "react";
import { Button, Modal, Table, notification, Popconfirm } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/lib/table/interface";
import { format } from "date-fns";
import { cancelPayment, cancelPaymentInvoice } from "~/api/expense";
import {
  PaymentDetails,
  PaymentHistoryEntry,
} from "~/feature/expense/ExpenseModal";
import { FORMAT_DATE } from "~/lib";
import Currency from "~/component/Currency";
import styles from "./PaymentHistoryDialog.module.css";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

interface Props {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  paymentDetails: PaymentDetails;
  onRefresh: () => void;
  net_amount: number | string;
  tds_amount: number | string;
  conversionRate1: any;
  expenseId?: any;
  totalPayAmount?: number;
  invoiceId?: any;
}

interface State {
  columnDef: Array<ColumnType<PaymentHistoryEntry>>;
}

export default class PaymentHistoryDialog extends Component<Props, State> {
  state: State = {
    columnDef: [],
  };

  componentDidMount() {
    this.setState({
      columnDef: [
        {
          title: "Transaction id",
          dataIndex: "transaction_id",
          align: "left",
          ellipsis: true,
        },
        {
          title: "Date",
          dataIndex: "payment_date",
          align: "center",
          render: (date) => (date ? format(date, FORMAT_DATE) : null),
        },
        {
          title: "Payment Mode",
          dataIndex: "payment_mode",
          width: 140,
          align: "center",
          render: (mode) => (mode === 1 ? "Cash" : "Bank"),
        },
        {
          title: "Paid Amount",
          dataIndex: "paid_amount",
          align: "right",
          width: 128,
          render: (amount) => <Currency value={amount} />,
        },
        {
          title: "Notes",
          dataIndex: "payment_notes",
        },
        {
          title: "Status",
          dataIndex: "payment_status",
        },
        {
          dataIndex: "payment_receipt_id",
          render: (_, entry) => (
            <div className={styles.float_right}>
              <Popconfirm
                title="Are you sure to cancel this payment?"
                disabled={entry.payment_status === "Cancelled"}
                okText="Yes"
                cancelText="No"
                onConfirm={this.handlePayCancel.bind(null, entry)}
              >
                <Button
                  shape="circle"
                  size="small"
                  type="ghost"
                  icon={<CloseOutlined />}
                  disabled={entry.payment_status === "Cancelled"}
                  danger
                />
              </Popconfirm>
            </div>
          ),
        },
      ],
    });
  }

  handlePayCancel = async (entry: PaymentHistoryEntry) => {
    try {
      registerEvent(
        ga.EVENT_CATEGORY_BUTTON_CLICK,
        ga.EVENT_CLICK,
        ga.events.paymentHistoryDialog99
      );

      const { ok, message } = this.props.expenseId
        ? await cancelPayment(entry.payment_receipt_id, this.props.expenseId)
        : await cancelPaymentInvoice(
            entry.payment_receipt_id,
            this.props.invoiceId
          );
      if (ok) {
        this.props.onRefresh();
        notification.success({
          message: `Successfully cancelled payment ${
            entry.transaction_id?.length > 0 ? `(${entry.transaction_id})` : ""
          }`,
        });
      } else {
        notification.warn({
          message: `Failed to cancel payment  ${
            entry.transaction_id?.length > 0 ? `(${entry.transaction_id})` : ""
          }`,
          description: message,
        });
      }
    } catch (err) {
      notification.warn({
        message: `Failed to cancel payment  ${
          entry.transaction_id?.length > 0 ? `(${entry.transaction_id})` : ""
        }`,
        description: err.message || "Network error!",
      });
      console.warn("Failed to cancel ", entry.payment_receipt_id, err);
    }
  };

  handleCancel = () => {
    this.props.onVisibleChange(false);
  };

  render() {
    const { visible, paymentDetails, totalPayAmount } = this.props;
    const net_amount_ininr = +totalPayAmount!;
    const paidSum = paymentDetails?.payment_history?.reduce((sum, entry) => {
      if (entry.payment_status === "Cancelled") {
        return sum;
      } else {
        return sum + entry.paid_amount;
      }
    }, 0);
    return (
      <Modal
        visible={visible}
        className={styles.modal}
        onCancel={this.handleCancel}
        okButtonProps={{ hidden: true }}
        cancelText="Close"
        title="Payment History"
        maskClosable={false}
      >
        <Table
          className={styles.table}
          size="large"
          rowKey="payment_receipt_id"
          columns={this.state.columnDef}
          dataSource={paymentDetails?.payment_history}
          pagination={false}
          // tslint:disable-next-line: jsx-no-lambda
          footer={() => (
            <div className={styles.footerWrap}>
              <div className={styles.footer}>
                <div className={styles.footerRow}>
                  <span className={styles.footerLabel}>Amount Paid</span>
                  <Currency className={styles.footerAmount}>{paidSum}</Currency>
                </div>
                <div className={styles.footerRow}>
                  <span className={styles.footerLabel}>Payable</span>
                  <Currency className={styles.footerAmount}>
                    {net_amount_ininr}
                  </Currency>
                </div>
                <div className={styles.footerRow}>
                  <span className={styles.footerLabel}>Pending</span>
                  <Currency className={styles.footerAmount}>
                    {net_amount_ininr - paidSum}
                  </Currency>
                </div>
              </div>
            </div>
          )}
        />
      </Modal>
    );
  }
}
