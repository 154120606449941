import React from "react";
import { TAX_INCLUSION, TaxTeritoryType } from "~/lib/taxes";
import { CurrencyInfo } from "~/fragment/line-items/LineItemTypes";
/**
 * Extra Renderers for eXPENSE lINE itEMS
 * See common line item renderes in src/fragment/line-items/litTableRenderers.tsx
 */

import ExpenseLineItemFooter from "./ExpenseLineItemFooter";

import { ExpenseLineItemRecord } from "./ExpenseLineItemTypes";

/////////////////////////////////
//////// EXTRA COLUMN RENDERERS ///////
/////////////////////////////////

/////////////////////////////////
//////// FOOTER RENDERERS SPECIFIC TO EXPENSE ///////
/////////////////////////////////
interface HistoryData {
  netTotal: string | number;
  tdsAmont: string | number;
  paymentDetails: any;
  expenseId: number | null;
  conversionRate: number | undefined;
  onRefresh: () => void;
}
export const elitMakeFooterRenderer = ({
  currency,
  hasCess,
  taxInclusion,
  taxTeritoryType,
  paymentHistoryData,
  tdsInfo,
  totalPayAmount,
  isCopy,
  netTotal,
}: {
  currency?: CurrencyInfo;
  hasCess?: boolean;
  taxInclusion?: TAX_INCLUSION;
  taxTeritoryType?: TaxTeritoryType;
  paymentHistoryData?: HistoryData;
  tdsInfo: {
    tdsRate?: number;
    tdsAmount?: number;
  };
  totalPayAmount: number;
  isCopy: boolean;
  netTotal: any;
}) => {
  return (data: Array<ExpenseLineItemRecord>) => (
    <ExpenseLineItemFooter
      rowData={data}
      hasCess={hasCess}
      taxInclusion={taxInclusion}
      taxTeritoryType={taxTeritoryType}
      currency={currency}
      paymentHistoryData={paymentHistoryData}
      tdsInfo={tdsInfo}
      totalPayAmount={totalPayAmount}
      isCopy={isCopy}
      netTotal={netTotal}
    />
  );
};
