import React, { Component, createRef } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  notification,
  Spin,
  Switch,
  Modal,
} from "antd";
import { connect, ConnectedProps } from "react-redux";
import { Prompt } from "react-router";
import { FormInstance } from "antd/lib/form";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import DatePicker from "~/component/antd-overrides/DatePicker";
import { APIState, fetchUtil } from "~/api/common";
import commonStyle from "~/component/common.module.css";
import styles from "./PersonalDetailsPane.module.css";
import { getEmployeeDetails, EmployeeDetails } from "~/api/employee";
import ProfilePicUpload from "~/fragment/upload/ProfilePicUpload";
import { LoginRole } from "~/api/auth";
import { __DateAtLoad } from "~/lib";
import { Required } from "~/lib/form_rules";
import { formatDate } from "~/lib";
import { isValid, isFuture, isBefore, isAfter } from "date-fns";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";
import FieldWithAction from "~/component/FieldWithAction";

const mapStateToProps = (
  state: AuthState,
  ownProps: { employeeID: number }
) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  employeeID: ownProps.employeeID,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  createCompanyDetails: (company_name: any) =>
    dispatch(action.auth.createCompanyDetails(company_name)),
  empDetailsSaved: (saveDetails: boolean) =>
    dispatch(action.auth.empDetailsSaved(saveDetails)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const { TextArea } = Input;
interface Props extends PropsFromRedux {
  employeeID: number;
  isTabUpdated: boolean;
  changeTabUpdate: () => void;
  currentTab: string;
  active: boolean;
}

interface State {
  personalFormData: EmployeeDetails;
  designationList: Array<{
    id: number;
    category: string;
  }>;
  engagementTypeList: Array<{
    id: number;
    type_name: string;
  }>;
  reportsTo: Array<{
    id: number;
    category: string;
  }>;
  is_disable: boolean;
  isDirty: boolean;
  apiState: APIState;
  dateOfBirth: any;
  joiningDate: any;
  leavingDate: any;

  value?: boolean;
  edit_access_personal: boolean;
  edit_access_identity: boolean;
  edit_access_bank: boolean;

  newvisible: boolean;
  loadOk: boolean;
}

class PersonalDetailsPane extends Component<Props, State> {
  state: State = {
    personalFormData: {
      emp_id: 0,
      company_emp_id: "0",
      email: "",
      is_active: false,
      crt_time: new Date(),
      update_time: new Date(),
    },
    designationList: [],
    engagementTypeList: [],
    reportsTo: [],
    is_disable: true,
    isDirty: false,
    apiState: "loading",
    dateOfBirth: "",
    joiningDate: "",
    leavingDate: "",
    edit_access_personal: false,
    edit_access_identity: false,
    edit_access_bank: false,

    newvisible: false,
    loadOk: false,
  };

  _formRef = React.createRef<FormInstance<any>>();
  addNewFormRef = createRef<FormInstance>();

  componentDidMount() {
    this.getDesignationList();
    this.getEngagementTypeList();
    this.getReportsTo();
    this.getEmployeeEditAccess();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: State) {
    if (this.props.currentTab === "personal" && !this.props.isTabUpdated) {
      this.getEngagementTypeList();
      this.getReportsTo();
      this.getDesignationList();
      this.props.changeTabUpdate();
      this.getEmployeeEditAccess();
    }
    if (prevProps.employeeID !== this.props.employeeID) {
      this.getEngagementTypeList();
      this.getReportsTo();
      this.getDesignationList();
      this.getEmployeeEditAccess();
    }
    if (prevState.newvisible !== this.state.newvisible) {
      this.addNewFormRef.current?.resetFields();
    }
  }

  async getDesignationList(name = "") {
    const { ok, json, message } = await fetchUtil(
      "GET",
      `/get_designation_category`
    );
    if (ok) {
      const data = json;
      this.setState({
        designationList: data,
      });
      if (name) {
        const option = data.find((it) => it.category === name);
        if (option) {
          this._formRef.current!.setFieldsValue({
            designation: option.id,
          });
        }
      }
    } else {
      notification.error({ message });
    }
  }

  async getEngagementTypeList() {
    const { ok, json, message } = await fetchUtil(
      "GET",
      `/get_engagement_types`
    );
    if (ok) {
      const data = json;
      this.setState({
        engagementTypeList: data,
      });
    } else {
      notification.error({ message });
    }
  }

  async getReportsTo() {
    const { ok, json, message } = await fetchUtil("GET", `/get_reports_to`);
    if (ok) {
      const data = json;
      this.setState({
        reportsTo: data,
      });
    } else {
      notification.error({ message });
    }
  }

  async getEmployeeEditAccess() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_employee_edit_access`,
      {
        emp_id: this.props.employeeID,
      }
    );

    if (ok) {
      const data = json[0];
      this.setState(data, () => {
        this.getPersonalDetails();
      });
    } else {
      notification.error({ message: "Failed to fetch" });
    }
  }

  async getPersonalDetails() {
    const { ok, data } = await getEmployeeDetails(this.props.employeeID);

    if (ok) {
      const edit_access_personal = this.state.edit_access_personal;
      const isEnabled =
        this.props.activeRole === LoginRole.ADMIN ||
        this.props.activeRole === LoginRole.SUPERuSER;

      this.setState({ is_disable: !(isEnabled || edit_access_personal) });

      this.setState({
        personalFormData: data!,
        apiState: "idle",
        dateOfBirth: data?.dob,
        joiningDate: data?.joining_date,
        leavingDate: data?.leaving_date,
      });
      if (this._formRef.current) {
        this._formRef.current.setFieldsValue(data);
      }
    } else {
      notification.error({ message: "Failed to fetch" });
    }
  }

  handleProfilePicHashedName = (newHashName: string) => {
    this.setState({
      personalFormData: {
        ...this.state.personalFormData,
        profile_pic_url: newHashName,
      },
    });
  };

  handleValueChange = (
    _changedValues: Partial<EmployeeDetails>,
    _values: EmployeeDetails
  ) => {
    if (!this.state.isDirty) {
      this.setState({ isDirty: true });
    }
    if (_values.dob) {
      this.setState({
        dateOfBirth: _values.dob,
        joiningDate: _values.joining_date,
        leavingDate: _values.leaving_date,
      });
    }
  };

  onFinish = async (values: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.personalDetailsPane51
    );

    this.setState({
      apiState: "loading",
    });
    const result = isAfter(
      new Date(this.state.dateOfBirth),
      new Date(this.state.joiningDate)
    );
    if (result === true) {
      this.setState({
        apiState: "idle",
      });
      notification.error({
        message: "Joining Date shoud be less then from Date Of Birth",
      });
      return;
    }

    const { ok, message } = await fetchUtil("POST", "/save_employee_details", {
      emp_id: this.props.employeeID,
      ...values,
      profile_pic_url: this.state.personalFormData.profile_pic_url,
    });
    if (ok) {
      this.props.empDetailsSaved(true);
      notification.success({
        message,
      });
      this.setState({ is_disable: true, isDirty: false, apiState: "success" });
    } else {
      notification.error({
        message,
      });
      this.setState({ apiState: "error" });
    }
    this.getEmployeeEditAccess();
  };

  handleEditAccess = async (value: boolean) => {
    this.setState({
      edit_access_personal: value,
    });
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/employee_edit_access`,
      {
        emp_id: this.props.employeeID,
        edit_access_personal: value,
        edit_access_identity: this.state.edit_access_identity,
        edit_access_bank: this.state.edit_access_bank,
      }
    );
    if (ok) {
      // const data = json[0];
      this.setState({
        // employeeSummaryData: data,
      });
    } else {
      notification.error({ message });
    }
  };

  handleAddNewOk = (e: React.MouseEvent<HTMLElement>) => {
    if (!this.addNewFormRef.current) {
      return;
    }

    this.addNewFormRef.current
      .validateFields()
      .then(async (data) => {
        this.setState({
          loadOk: true,
        });

        const { ok, message, json } = await fetchUtil(
          "POST",
          "/add_designation",
          {
            name: data.designation,
          }
        );
        this.getDesignationList(data.designation);
        if (ok) {
          this.setState({
            loadOk: false,
            newvisible: false,
          });
        } else {
          notification.error({
            message,
          });
          this.setState({
            loadOk: false,
          });
        }

        this.setState({
          newvisible: false,
        });
      })
      .catch((reason) => {
        console.warn("Form error", reason);
      });
  };

  handleNewCancel = (_e: React.MouseEvent<HTMLElement>) => {
    this.setState({
      newvisible: false,
    });
  };

  handleDesignation = () => {
    this.setState({
      newvisible: true,
    });
  };

  render() {
    const { personalFormData, is_disable, newvisible } = this.state;
    const { active } = this.props;
    const isEnabled =
      this.props.activeRole === LoginRole.ADMIN ||
      this.props.activeRole === LoginRole.SUPERuSER;

    const designationView = !is_disable ? null : (
      <Form.Item label="Designation">
        <input
          className="ant-input"
          defaultValue={
            this.state.designationList.find(
              (it) => it.id === personalFormData.designation
            )?.category
          }
          readOnly={true}
        />
      </Form.Item>
    );
  
    const reportsTo: any = this.state.reportsTo.find(
      (it: any) => it.user_mapping_id === (personalFormData as any).reportsTo
    );
    const reportsView = !is_disable ? null : (
      <Form.Item label="Reports To" name="reports_to_name">
        <input className="ant-input" readOnly={true} />
      </Form.Item>
    );

    const engagementTypeView = !is_disable ? null : (
      <Form.Item label="Engagement type">
        <input
          className="ant-input"
          defaultValue={
            this.state.engagementTypeList.find(
              (it) => it.id === personalFormData.engagement_type_id
            )?.type_name
          }
          readOnly={true}
        />
      </Form.Item>
    );

    const genderList = [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
    ];
    const genderView = !this.state.is_disable ? null : (
      <Form.Item label="Gender">
        <input
          className="ant-input"
          defaultValue={
            genderList.find(
              (it) => it.value === this.state.personalFormData.gender
            )?.label
          }
          readOnly={true}
        />
      </Form.Item>
    );
    const joiningDateView = !this.state.is_disable ? null : (
      <Form.Item className={commonStyle["w-100"]} label="Joining date">
        <input
          className="ant-input"
          defaultValue={
            this.state.personalFormData.joining_date
              ? formatDate(this.state.personalFormData.joining_date)
              : ""
          }
          readOnly={true}
        />
      </Form.Item>
    );
    const leavingDateView = !this.state.is_disable ? null : (
      <Form.Item className={commonStyle["w-100"]} label="Leaving date">
        <input
          className="ant-input"
          defaultValue={
            this.state.personalFormData.leaving_date
              ? formatDate(this.state.personalFormData.leaving_date)
              : ""
          }
          readOnly={true}
        />
      </Form.Item>
    );

    const dateOfBirthView = !this.state.is_disable ? null : (
      <Form.Item className={commonStyle["w-100"]} label="Date of birth">
        <input
          className="ant-input"
          defaultValue={
            isValid(this.state.personalFormData.dob)
              ? formatDate(this.state.personalFormData.dob!)
              : ""
          }
          readOnly={true}
        />
      </Form.Item>
    );

    return (
      <Spin spinning={this.state.apiState === "loading"}>
        <Form
          initialValues={this.state.personalFormData}
          onValuesChange={this.handleValueChange}
          onFinish={this.onFinish}
          ref={this._formRef}
        >
          <Prompt
            message="You have unsaved details. Do you still want to leave this page?"
            when={this.state.isDirty}
          />

          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <ProfilePicUpload
              hashedFileName={this.state.personalFormData.profile_pic_url}
              onChange={this.handleProfilePicHashedName}
              is_disable={this.state.is_disable}
              square={false}
            />

            {this.props.active &&
            (this.props.activeRole === LoginRole.ADMIN ||
              this.props.activeRole === LoginRole.SUPERuSER) ? (
              <Form.Item
                style={{ height: "0" }}
                label={
                  <>
                    <span style={{ marginRight: "10px" }}>
                      Edit access to employee
                    </span>
                    <Switch
                      style={{ display: "block" }}
                      checked={this.state.edit_access_personal}
                      onChange={this.handleEditAccess}
                      // checked={value}
                      // disabled={readOnly || disabled}
                    />
                  </>
                }
              />
            ) : null}
          </Row>

          <Row gutter={{ md: 32, lg: 64, xl: 100 }}>
            <Col md={8} lg={8} xl={8} xxl={6}>
              <Row gutter={{ md: 8, lg: 16 }}>
                <Col flex={1} sm={24} lg={12}>
                  <Form.Item
                    className={commonStyle["w-100"]}
                    name="first_name"
                    label="First name"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        pattern: /^[a-zA-Z0-9]{2,}.*$/,
                        message: "Please enter valid First name!",
                      },
                    ]}
                  >
                    <Input
                      autoFocus
                      readOnly={this.state.is_disable || !active}
                    />
                  </Form.Item>
                </Col>
                <Col flex={1} sm={24} lg={12}>
                  <Form.Item
                    className={commonStyle["w-100"]}
                    name="last_name"
                    label="Last name"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        pattern: /^[a-zA-Z0-9]{2,}.*$/,
                        message: "Please enter valid Last name!",
                      },
                    ]}
                  >
                    <Input readOnly={this.state.is_disable || !active} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                className={commonStyle["w-100"]}
                name="email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please enter valid Email!",
                  },
                ]}
              >
                <Input
                  type="email"
                  readOnly={this.state.is_disable || !active}
                />
              </Form.Item>

             {isEnabled ? 
              designationView || (
                <FieldWithAction
                  actionLabel="+ Add new"
                  onAction={this.handleDesignation}
                  className={commonStyle["w-100"]}
                  label="Designation"
                  name="designation"
                  rules={[
                    {
                      required: true,
                      // type: "number",
                      message: "Please enter valid Designation!",
                    },
                  ]}
                >
                  <Select
                    id="chrome-off"
                    showSearch
                    optionFilterProp="children"
                  >
                    {this.state.designationList.map((desig) => (
                      <Select.Option key={desig.id} value={desig.id}>
                        {desig.category}
                      </Select.Option>
                    ))}
                  </Select>
                </FieldWithAction>
              ) :
               designationView || (
                <Form.Item
                  className={commonStyle["w-100"]}
                  label="Designation"
                  name="designation"
                  rules={[
                    {
                      required: true,
                      // type: "number",
                      message: "Please enter valid Designation!",
                    },
                  ]}
                >
                  <Select
                    id="chrome-off"
                    showSearch
                    optionFilterProp="children"
                  >
                    {this.state.designationList.map((desig) => (
                      <Select.Option key={desig.id} value={desig.id}>
                        {desig.category}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )
            }
              <Modal
                title="Add new row on dropdown"
                visible={newvisible}
                onOk={this.handleAddNewOk}
                onCancel={this.handleNewCancel}
                confirmLoading={this.state.loadOk}
              >
                <Form ref={this.addNewFormRef}>
                  <Form.Item
                    label="Add new designation"
                    name="designation"
                    rules={[
                      {
                        required: true,
                        type: "string",
                        pattern: /^[A-Za-z0-9 ]+$/,
                        message: "Please add valid designation",
                      },
                    ]}
                  >
                    {/* <input
                    name="dropdown-row"
                    className="ant-input"
                  /> */}
                    <Input />
                  </Form.Item>
                </Form>
              </Modal>

              <Form.Item
                className={commonStyle["w-100"]}
                name="address"
                label="Address"
                rules={[
                  {
                    type: "string",
                    required: true,
                    // pattern: /^[a-zA-Z0-9]{2,}.*$/,
                    message: "Please enter valid Address!",
                  },
                ]}
              >
                <TextArea
                  rows={5}
                  readOnly={this.state.is_disable || !active}
                />
              </Form.Item>
            </Col>
            <div className={styles.divider} role="separator" />
            <Col md={6} lg={6} xl={5} xxl={4}>
              <Form.Item
                name="mobile_no"
                label="Mobile no"
                rules={[
                  {
                    type: "string",
                    required: true,
                    pattern: /^[1-9]{1}[0-9 ]{9}$/,
                    message: "Please enter valid Mobile no!",
                  },
                ]}
              >
                <Input type="tel" readOnly={this.state.is_disable || !active} />
              </Form.Item>
              {joiningDateView || (
                <Form.Item name="joining_date" label="Joining date">
                  <DatePicker
                    className={commonStyle["w-100"]}
                    format="DD-MMM, YYYY"
                    // tslint:disable-next-line: jsx-no-lambda
                    disabledDate={(date) =>
                      isFuture(date) ||
                      (this.state.dateOfBirth &&
                        isBefore(date, this.state.dateOfBirth))
                    }
                    disabled={this.state.is_disable || !active}
                  />
                </Form.Item>
              )}
              {!active
                ? leavingDateView || (
                    <Form.Item name="leaving_date" label="Leaving date">
                      <DatePicker
                        className={commonStyle["w-100"]}
                        format="DD-MMM, YYYY"
                        // tslint:disable-next-line: jsx-no-lambda
                        disabledDate={(date) =>
                          isFuture(date) ||
                          (this.state.dateOfBirth &&
                            isBefore(date, this.state.dateOfBirth))
                        }
                        disabled={this.state.is_disable || !active}
                      />
                    </Form.Item>
                  )
                : null}

              {dateOfBirthView || (
                <Form.Item
                  name="dob"
                  label="Date of birth"
                  rules={[
                    {
                      required: true,
                      type: "date",
                      message: "Please select your date of birth!",
                    },
                  ]}
                >
                  <DatePicker
                    className={commonStyle["w-100"]}
                    format="DD-MMM, YYYY"
                    disabledDate={isFuture}
                    disabled={this.state.is_disable || !active}
                    defaultPickerValue={__DateAtLoad || undefined}
                  />
                </Form.Item>
              )}

              {genderView || (
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter valid Gender!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    disabled={this.state.is_disable || !active}
                  >
                    <Select.Option value="male">Male</Select.Option>
                    <Select.Option value="female">Female</Select.Option>
                  </Select>
                </Form.Item>
              )}
            </Col>
            <div className={styles.divider} role="separator" />
            <Col md={6} lg={6} xl={5} xxl={4}>
              <Form.Item
                name="father_name"
                label="Father/Guardian name"
                rules={[
                  {
                    type: "string",
                    required: true,
                    // pattern: /^[a-zA-Z0-9]{2,}.*$/,
                    message: "Please enter valid Father/Guardian name!",
                  },
                ]}
              >
                <Input
                  type="text"
                  readOnly={this.state.is_disable || !active}
                />
              </Form.Item>
              <Form.Item
                className={commonStyle["w-100"]}
                name="emergency_no"
                label="Emergency contact no"
                rules={[
                  {
                    type: "string",
                    required: true,
                    pattern: /^[1-9]{1}[0-9 ]{9}$/,
                    message: "Please enter valid Emergency contact No!",
                  },
                ]}
              >
                <Input type="tel" readOnly={this.state.is_disable || !active} />
              </Form.Item>

              {engagementTypeView || (
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="engagement_type_id"
                  label="Engagement type"
                  rules={[
                    {
                      required: true,
                      message: "Please enter valid Engagement type!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    disabled={this.state.is_disable || !active}
                  >
                    {this.state.engagementTypeList.map((engage) => (
                      <Select.Option key={engage.id} value={engage.id}>
                        {engage.type_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              {reportsView || (
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="reports_to"
                  label="Reports to"
                  rules={[
                    {
                      required: false,
                      // message: "Please enter valid Reports to!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    disabled={this.state.is_disable || !active}
                  >
                    {this.state.reportsTo.map((emp: any) => (
                      <Select.Option
                        key={emp.user_mapping_id}
                        value={emp.user_mapping_id}
                      >
                        {emp.first_name + " " + emp.last_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                className={commonStyle["w-100"]}
                name="other_info"
                label="Other info"
                rules={[
                  {
                    type: "string",
                    // required: true,
                    // pattern: /^[1-9]{1}[0-9 ]{9}$/,
                    // message: "Please enter valid Other info!",
                  },
                ]}
              >
                <Input
                  type="text"
                  readOnly={this.state.is_disable || !active}
                />
              </Form.Item>
            </Col>
          </Row>
          {this.props.activeRole === LoginRole.EMPLOYEE &&
          !this.state.personalFormData?.mobile_no ? (
            <div style={{ fontSize: "12px", color: "red" }}>
              *Note: You can only edit one time.
            </div>
          ) : null}
          {!this.state.is_disable ? (
            this.props.active ||
            this.props.activeRole === LoginRole.EMPLOYEE ? (
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.apiState === "loading"}
                disabled={!active}
              >
                Save
              </Button>
            ) : null
          ) : null}
        </Form>
      </Spin>
    );
  }
}

export default connector(PersonalDetailsPane);
