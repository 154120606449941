import React from "react";
import { IMTIconProps, IMTIconElement, __colors } from "./interface";

const Payroll: IMTIconElement = ({ highlighted, ...props }: IMTIconProps) => {
  const color = highlighted ? __colors.active : __colors.inactive;
  return (
    <svg x="0px" y="0px" viewBox="0 0 56 56" xmlSpace="preserve" {...props}>
      <path
        d="M38.9 26.2c-2.8 0-5.4.9-7.5 2.3-1.6-.8-3.5-1.2-5.3-1.2H16c-6.2 0-11.5 4.6-12.3 10.8v1.6c0 .6.4 1 1 1h20.8c.5 7 6.3 12.5 13.4 12.5 7.4 0 13.5-6 13.5-13.5s-6.1-13.5-13.5-13.5zm-9.3 3.7c-2.4 2.2-3.9 5.3-4.1 8.8h-.8l-.9-5.7 1.5-3.7h.8c1.2 0 2.4.2 3.5.6zm-7.5 1.9h-1.8l-1-2.6h3.8l-1 2.6zm-1.7 2h1.4l.8 4.8h-3.1l.9-4.8zM5.6 38.2c.7-5.1 5.1-9 10.3-9h1.2l1.5 3.7-1.1 5.7H5.6v-.4zm33.3 13c-6.3 0-11.5-5.2-11.5-11.5s5.2-11.5 11.5-11.5 11.5 5.2 11.5 11.5-5.2 11.5-11.5 11.5z"
        fill={color}
      />
      <path
        d="M44.7 33.8c.6 0 1-.4 1-1s-.4-1-1-1h-9.8c-.6 0-1 .4-1 1s.4 1 1 1h3.6c1.2 0 2.3.8 2.7 1.8h-6.3c-.6 0-1 .4-1 1s.4 1 1 1h6.4c-.4 1.1-1.5 2-2.7 2H35c-.4 0-.8.3-.9.6-.2.4-.1.8.2 1.1l7.5 7.1c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-5.7-5.3h1.1c2.4 0 4.4-1.7 4.8-4h1.4c.6 0 1-.4 1-1s-.4-1-1-1h-1.4c-.2-.7-.4-1.3-.9-1.8h2.2zM10.8 13v.1c0 8.3 3.7 13.1 10.2 13.1 7.4 0 10.1-6.8 10.2-12.7v-.1-.1-.2c0-5.6-4.6-10.2-10.2-10.2-5.5 0-10 4.3-10.2 9.7 0 .1-.1.2 0 .4zM21 24.2c-6.6 0-8-5.9-8.2-10.1.7.1 1.4.2 2.1.2 5.2 0 8.7-.8 10.6-2.4 1.2 1.5 2.5 2.1 3.7 2.3-.2 3.1-1.4 10-8.2 10zm0-19.4c4.2 0 7.7 3.2 8.2 7.3-.9-.2-1.8-.8-2.8-2.4-.2-.3-.5-.5-.9-.5s-.7.2-.9.5c-.7 1.2-3 2.6-9.8 2.6-.7 0-1.4-.1-2.1-.3.6-4 4.1-7.2 8.3-7.2z"
        fill={color}
      />
    </svg>
  );
};

export default Payroll;
