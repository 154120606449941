import React from "react";
import { IMTIconProps, IMTIconElement, __colors } from "./interface";

const payroll: IMTIconElement = ({ highlighted, ...props }: IMTIconProps) => {
  const color = highlighted ? __colors.active : __colors.inactive;
  return (
    <svg x="0px" y="0px" viewBox="0 0 56 56" xmlSpace="preserve" {...props}>
      <path
        d="M32.7 18.3h7.1c.6 0 1-.4 1-1s-.4-1-1-1h-7.1c-.6 0-1 .4-1 1s.5 1 1 1zM40 26.9h-7.2c-.6 0-1 .4-1 1s.4 1 1 1H40c.6 0 1-.4 1-1s-.5-1-1-1zM40 37.6H16c-.6 0-1 .4-1 1s.4 1 1 1h24c.6 0 1-.4 1-1s-.5-1-1-1z"
        fill={color}
      />
      <path
        d="M47.9 3.5c-.8-.3-1.7-.2-2.4.4L40 7.5c-.1 0-.1.1-.1.1-.1.1-.1.1-.2.1s-.1 0-.2-.1l-3.1-3.9s0-.1-.1-.1c-.4-.4-1-.7-1.6-.7-.6 0-1.1.2-1.6.6l-5 4s-.1 0-.1.1c.1.1 0 .1 0 .1s-.1 0-.2-.1l-.1-.1-4.9-4c-.9-.8-2.3-.8-3.2.1l-3.4 3.9c-.1.1-.1.1-.2.1s-.1 0-.2-.1l-.1-.1-5.2-3.6c-.7-.5-1.6-.7-2.4-.3-.9.3-1.4 1.2-1.4 2.1v40.5c0 .6.2 1.2.7 1.7l5.4 4.6c.9.9 2.3.9 3.1.1l5.1-4.2c.1-.1.3-.1.5.1l4.9 4c.5.5 1 .7 1.6.7.6 0 1.1-.2 1.5-.6l5.1-4.2c.1-.1.1-.1.2-.1 0 0 .1 0 .3.1l4.9 4c.9.9 2.3.9 3.1.1l5.5-4.6.1-.1c.4-.4.7-1 .7-1.6V5.5c-.1-.9-.6-1.7-1.5-2zm-.6 42.6c0 .1 0 .1-.1.2l-5.6 4.6c-.1.1-.3.1-.5-.1l-4.9-4c-.4-.4-1-.7-1.6-.7-.6 0-1.2.2-1.5.6L28 50.9c0 .1-.1.1-.3-.1l-4.9-4c-.4-.4-1-.7-1.6-.7-.6 0-1.2.2-1.5.6l-5.1 4.2c-.1.1-.3.1-.4-.1l-5.4-4.6c-.1-.1-.1-.1-.1-.2V5.6c0-.1.1-.2.2-.2H9c.1 0 .1 0 .2.1l.1.1 5.2 3.6c.4.4 1 .6 1.5.6.6 0 1.2-.2 1.7-.7l3.3-4c.1-.1.3-.1.4 0l.1.1 4.9 4c.4.4 1 .6 1.6.6.6 0 1.1-.2 1.6-.6l5-4s.1 0 .1-.1c.1-.1.2-.1.4 0L38 9s0 .1.1.1c.4.4 1 .7 1.6.7.6 0 1.1-.2 1.5-.6l5.5-3.7c.1 0 .1-.1.1-.1.1-.1.2-.1.3-.1.1 0 .2.1.2.2v40.6z"
        fill={color}
      />
      <path
        d="M15 21.4c0 .6.4 1 1 1h6.9c-.4 1.3-1.6 2.3-3 2.3H16c-.4 0-.8.3-.9.6-.2.4-.1.8.2 1.1l8.1 7.6c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-6.2-5.9H20c2.5 0 4.6-1.8 5.1-4.3h1.5c.6 0 1-.4 1-1s-.4-1-1-1H25c-.2-.8-.5-1.5-1-2.1h2.6c.6 0 1-.4 1-1s-.4-1-1-1H16c-.6 0-1 .4-1 1s.4 1 1 1h3.9c1.4 0 2.5.9 3 2.1H16c-.5 0-1 .5-1 1z"
        fill={color}
      />
    </svg>
  );
};

export default payroll;
