import React, { useEffect, useState } from "react";
import DataToShow from "./data-to-show/datatoshow";
import { Button, Tooltip } from "antd";
import styles from "~/component/JsonDownloadPreview.module.css";
const mapInput: any = {};
type Props = {
  JSONToView: any;
  loading?: boolean;
  setLoading?: any;
  currentLayer: any;
  previousLayers?: any;
  setDataOfALayer: any;
  editable?: boolean;
  extraButton?: JSX.Element;
  ind?: number;
};
export default function JsonViewer({
  JSONToView,
  loading,
  setLoading,
  currentLayer,
  previousLayers,
  setDataOfALayer,
  editable,
  extraButton,
  ind,
}: Props) {
  let lastLayer = currentLayer.split(",");
  lastLayer = lastLayer[lastLayer.length - 1];
  const [showContent, setShowContent] = useState(false);

  useEffect(
    () => {
      if (setLoading) setLoading(false);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSONToView]
  );

  if (!loading) {
    if (JSONToView === null || JSONToView === undefined) {
      return (
        <React.Fragment>
          <input
            className={styles.jsonViewInput}
            value={JSON.stringify(JSONToView, null, 2).split('"').join("")}
            // tslint:disable-next-line: jsx-no-lambda
            onChange={(e) => {
              setDataOfALayer(currentLayer, e.target.value);
            }}
          />

          {extraButton}
        </React.Fragment>
      );
    }
    if (typeof JSONToView === "boolean") {
      return (
        <React.Fragment>
          <input
            className={styles.jsonViewInput}
            value={JSON.stringify(JSONToView, null, 2).split('"').join("")}
            // tslint:disable-next-line: jsx-no-lambda
            onChange={(e) => {
              setDataOfALayer(currentLayer, e.target.value);
            }}
          />
        </React.Fragment>
      );
    }

    if (typeof JSONToView !== "object") {
      // not object && not array

      const unqKeyInput = `${currentLayer},${ind}`;
      if (!mapInput[unqKeyInput]) {
        mapInput[unqKeyInput] =
          typeof JSONToView === "number" ? "number" : "text";
      }

      return (
        <React.Fragment>
          {currentLayer === "gstin" || currentLayer === "ret_period" ? (
            <Tooltip placement="rightTop" title={`Cannot edit ${currentLayer}`}>
              <input
                className={styles.jsonViewInput}
                value={JSON.stringify(JSONToView, null, 2).split('"').join("")} // tslint:disable-next-line: jsx-no-lambda
                onChange={(e) => {
                  setDataOfALayer(currentLayer, e.target.value);
                }}
              />
            </Tooltip>
          ) : (
            <input
              type={mapInput[unqKeyInput]}
              className={styles.jsonViewInput}
              value={JSONToView} // tslint:disable-next-line: jsx-no-lambda
              onChange={(e) => {
                setDataOfALayer(
                  currentLayer,
                  mapInput[unqKeyInput] === "number"
                    ? parseFloat(e.target.value)
                    : e.target.value
                );
              }}
            />
          )}
          {extraButton}
        </React.Fragment>
      );
    }
    if (typeof JSONToView === "object") {
      return (
        <React.Fragment>
          {lastLayer ? (
            <div>
              <Button
                className={styles.showHideDataBtn}
                // tslint:disable-next-line: jsx-no-lambda
                onClick={() => {
                  setShowContent(!showContent);
                }}
              >
                {`[${showContent ? "-" : "+"}] ` +
                  (lastLayer ? lastLayer : "Retrived Data") +
                  " " +
                  (Array.isArray(JSONToView)
                    ? `[${Object.keys(JSONToView).length}]`
                    : `{${Object.keys(JSONToView).length}}`)}
              </Button>
            </div>
          ) : null}
          {extraButton}

          <div>
            <table
              className={`${styles.jsonPreviewTable} ${
                extraButton ? styles.setTable : ""
              }`}
              style={{ display: showContent || !lastLayer ? "block" : "none" }}
            >
              <DataToShow
                editable={editable}
                JSONToView={JSONToView}
                currentLayer={currentLayer}
                previousLayers={previousLayers}
                setDataOfALayer={setDataOfALayer}
              />
            </table>
          </div>
        </React.Fragment>
      );
    }
  }
  return <>{/* <img src={loader} alt="" /> */}</>;
  //   return "Loading";
}
