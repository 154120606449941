import React, { Component } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  notification,
  Spin,
  Switch,
} from "antd";
import { FormInstance } from "antd/lib/form";
import commonStyle from "~/component/common.module.css";
import { APIState, fetchUtil, getBankList } from "~/api/common";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { LoginRole } from "~/api/auth";
import { Prompt } from "react-router";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (
  state: AuthState,
  ownProps: { employeeID: number }
) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  employeeID: ownProps.employeeID,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux {
  employeeID: number;
  isTabUpdated: boolean;
  changeTabUpdate: () => void;
  currentTab: string;
  active: boolean;
}

interface State {
  bankFormData: any;
  is_disable: boolean;
  apiState: APIState;
  isDirty: boolean;
  bankList: Array<any>;

  value?: boolean;
  edit_access_personal: boolean;
  edit_access_identity: boolean;
  edit_access_bank: boolean;
}

class BankDetailsPane extends Component<Props, State> {
  state: State = {
    bankFormData: {},
    is_disable: true,
    apiState: "loading",
    isDirty: false,
    bankList: [],
    edit_access_personal: false,
    edit_access_identity: false,
    edit_access_bank: false,
  };
  _formRef = React.createRef<FormInstance<any>>();

  componentDidMount() {
    this.loadBankList();
    this.getEmployeeEditAccess();
    if (
      this.props.activeRole === LoginRole.ADMIN ||
      this.props.activeRole === LoginRole.SUPERuSER
    ) {
      this.setState({ is_disable: false });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.currentTab === "bank" && !this.props.isTabUpdated) {
      this.getEmployeeEditAccess();

      if (
        this.props.activeRole === LoginRole.ADMIN ||
        this.props.activeRole === LoginRole.SUPERuSER
      ) {
        this.setState({ is_disable: false });
      }
      this.props.changeTabUpdate();
    }
    if (prevProps.employeeID !== this.props.employeeID) {
      this.getEmployeeEditAccess();
    }
  }

  async getBankDetails() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_employee_bank_details`,
      {
        emp_id: this.props.employeeID,
      }
    );

    if (ok) {
      const data = json[0];
      const edit_access_bank = this.state.edit_access_bank;
      const isEnabled =
        this.props.activeRole === LoginRole.ADMIN ||
        this.props.activeRole === LoginRole.SUPERuSER;

      this.setState({ is_disable: !(isEnabled || edit_access_bank) });
      this.setState({
        bankFormData: data,
        apiState: "idle",
      });
      if (this._formRef.current) {
        this._formRef.current.setFieldsValue(data);
      }
    } else {
      notification.error({ message });
    }
  }

  async getEmployeeEditAccess() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_employee_edit_access`,
      {
        emp_id: this.props.employeeID,
      }
    );

    if (ok) {
      const data = json[0];
      this.setState(data, () => {
        this.getBankDetails();
      });
    } else {
      notification.error({ message: "Failed to fetch" });
    }
  }

  loadBankList = async () => {
    const response = await getBankList();
    if (!response.ok) {
      notification.error({
        message: "Failed to load Bank List",
        description: response.message,
      });
    } else {
      this.setState(
        {
          bankList: response.data!,
        },
        () => {
          // const bank = this.state.bankList.find(
          //   (el: BankNameList) => el.id === this.props.data.bank_type_id
          // );
          // this.setState({
          //   selectedBank: bank,
          // });
        }
      );
    }
  };

  handleValueChange = (_changedValues: Partial<any>, _values: any) => {
    if (!this.state.isDirty) {
      this.setState({ isDirty: true });
    }
  };
  onFinish = async (values: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.bankDetailsPane47
    );

    this.setState({
      apiState: "loading",
    });
    const { ok, message } = await fetchUtil(
      "POST",
      "/save_employee_bank_details",
      {
        emp_id: this.props.employeeID,
        ...values,
      }
    );
    if (ok) {
      notification.success({
        message,
      });
      this.setState({ is_disable: true, isDirty: false, apiState: "success" });
    } else {
      notification.error({
        message,
      });
      this.setState({ apiState: "error" });
    }
    this.getEmployeeEditAccess();
  };

  searchBank = (text: string) => {
    const { bankList } = this.state;
    if (text.length) {
      const newList = bankList.filter((val: any) => {
        return (
          val.name.substring(0, text.length).toUpperCase() ===
          text.toUpperCase()
        );
      });
      this.setState({ bankList: newList });
    } else {
      this.loadBankList();
    }
  };

  handleBankFilterOption = (input?: string, option?: any): any => {
    return input && option.children.toLowerCase().includes(input.toLowerCase());
  };

  handleEditAccess = async (value: boolean) => {
    this.setState({
      edit_access_bank: value,
    });
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/employee_edit_access`,
      {
        emp_id: this.props.employeeID,
        edit_access_personal: this.state.edit_access_personal,
        edit_access_identity: this.state.edit_access_identity,
        edit_access_bank: value,
      }
    );
    if (ok) {
      // const data = json[0];
      this.setState({
        // employeeSummaryData: data,
      });
    } else {
      notification.error({ message });
    }
  };

  render() {
    const { bankFormData, bankList } = this.state;
    const { active } = this.props;
    const bankNameView =
      !this.state.is_disable || active ? null : (
        <Form.Item label="Bank name">
          <input
            className="ant-input"
            defaultValue={
              bankList.find((it) => it.value === bankFormData.bank_name)?.label
            }
            readOnly
          />
        </Form.Item>
      );
    return (
      <Spin spinning={this.state.apiState === "loading"}>
        <Form
          initialValues={bankFormData}
          onValuesChange={this.handleValueChange}
          onFinish={this.onFinish}
          ref={this._formRef}
        >
          <Prompt
            message="You have unsaved details. Do you still want to leave this page?"
            when={this.state.isDirty}
          />
          {this.props.active &&
          (this.props.activeRole === LoginRole.ADMIN ||
            this.props.activeRole === LoginRole.SUPERuSER) ? (
            <Form.Item
              style={{
                display: "block",
                marginBottom: "0",
                textAlign: "end",
                height: "0",
              }}
              label={
                <>
                  <span style={{ marginRight: "10px" }}>
                    Edit access to employee
                  </span>
                  <Switch
                    style={{ display: "block" }}
                    onChange={this.handleEditAccess}
                    checked={this.state.edit_access_bank}
                    // defaultChecked={false}
                    // disabled={readOnly || disabled}
                  />
                </>
              }
            />
          ) : null}
          <Row gutter={{ md: 32, lg: 64, xl: 100 }}>
            <Col md={6} lg={6} xl={5} xxl={4}>
              <Form.Item
                className={commonStyle["w-100"]}
                name="ac_holder_name"
                label="Account holder name"
                rules={[
                  {
                    type: "string",
                    required: true,
                    pattern: /^[a-zA-Z0-9]{2,}.*$/,
                    message: "Please enter valid Account holder name!",
                  },
                ]}
              >
                <Input autoFocus readOnly={this.state.is_disable || !active} />
              </Form.Item>
              {bankNameView || (
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="bank_name"
                  label="Bank name"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      message: "Please enter valid Bank name!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={this.handleBankFilterOption}
                    disabled={this.state.is_disable || !active}
                  >
                    {bankList.map((bank, indx) => (
                      <Select.Option key={indx} value={bank.name}>
                        {bank.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                className={commonStyle["w-100"]}
                name="ifsc_code"
                label="IFSC code"
                rules={[
                  {
                    type: "string",
                    required: true,
                    pattern: /^[A-Z]{4}[0][A-Z0-9]{6,7}$/,
                    message: "Please enter valid IFSC code!",
                  },
                ]}
              >
                <Input readOnly={this.state.is_disable || !active} />
              </Form.Item>
              <Form.Item
                className={commonStyle["w-100"]}
                name="ac_no"
                label="Account number"
                rules={[
                  {
                    type: "string",
                    required: true,
                    pattern: /^[0-9 ]+$/,
                    message: "Please enter valid Account number!",
                  },
                ]}
              >
                <Input readOnly={this.state.is_disable || !active} />
              </Form.Item>
            </Col>
          </Row>
          {this.props.activeRole === LoginRole.EMPLOYEE &&
          !this.state.bankFormData?.ac_holder_name ? (
            <div style={{ fontSize: "12px", color: "red" }}>
              *Note: You can only edit one time.
            </div>
          ) : null}
          {!this.state.is_disable ? (
            this.props.active ||
            this.props.activeRole === LoginRole.EMPLOYEE ? (
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.apiState === "loading"}
                disabled={!active}
              >
                Save
              </Button>
            ) : null
          ) : null}
        </Form>
      </Spin>
    );
  }
}
export default connector(BankDetailsPane);
