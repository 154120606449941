import {
  configureStore,
  combineReducers,
  createSlice,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";
import { APIState, fetchUtil } from "~/api/common";
import { AuthUser } from "~/api/auth";
import { notification } from "antd";

interface UpdateCompanyRet {
  plan_id: any;
  plan_name: any;
  plan_purchased: any;
  fe_payment_status: any;
  grace_period: any;
  already_cancelled: any;
  added_clients: any;
  client_no: any;
}

export const stepPositionMap = {
  basic: 0,
  identity: 1,
  communication: 2,
  bank: 3,
};

const _positionStepMap = Object.keys(stepPositionMap).reduce((obj, _key) => {
  const key = _key as StepsType;
  obj[stepPositionMap[key]] = key;
  return obj;
}, {} as { [pos: number]: string });

const _stepsLength = Object.keys(stepPositionMap).length;

export type StepsType = keyof typeof stepPositionMap;

export type BasicDetails = {
  company_logo?: string;
  legal_name?: string;
  name: string;
  establishment_type: number;
  cin?: string;
  industry?: number;
  address?: string;
  company_city?: string;
  company_state?: string | number;
  company_state_name?: string;
  company_country?: string;
  company_pincode?: string;
  company_website?: string;
  currency?: string | number;
};

export type IdentityDetails = {
  gst_registration_type?: string | number;
  gstin?: string;
  gst_registration_state?: string | any;
  place_of_supply?: number;
  pan?: string;
  tan?: string;
  tan_verified?: boolean;
  ptax_registration_no?: string;
  pf_registration_no?: string;
  esi_registration_no?: string;
  udyog_aadhaar_no?: string;
  gstin_registration_date: Date;
};

export type CommunicationDetails = {
  first_name?: string;
  last_name?: string;
  role_in_company?: number;
  admin_phone?: string;
  selectedDirectorName?: string;
};

export type BankDetails = {
  bank_type_id?: string;
  bank_name?: string;
  account_number?: string;
  ifsc_code?: string;
  swift_code?: string;
};

export type PersistActionType = PayloadAction<{
  step: StepsType;
  data: BasicDetails | IdentityDetails | CommunicationDetails | BankDetails;
}>;

export const doSaveOnboardData = createAsyncThunk<
  string,
  any,
  {
    dispatch: OnboardDispatch;
    state: OnboardStoreState;
  }
>("imt/onboard/save", async (_, thunkAPI) => {
  const {
    basicDetails,
    identityDetails,
    communicationDetails,
    bankDetails,
    newModified,
  } = thunkAPI.getState().onboard;
  const response = await fetchUtil("POST", "/update_company", {
    ...basicDetails,
    ...identityDetails,
    ...communicationDetails,
    ...bankDetails,
    executive_list: newModified,
  });

  if (response.ok) {
    const licenseDetailsForFreeplan: UpdateCompanyRet = response.json
      ? response.json[0]
      : {};

    setTimeout(() => {
      const user = { ...thunkAPI.getState().onboard.user };
      user.onboarding_status = true;
      user.first_name = communicationDetails.first_name;
      user.last_name = communicationDetails.last_name;
      user.company_name = basicDetails.name;
      user.gstin = identityDetails.gstin;
      user.place_of_supply = Number(basicDetails.company_state);
      user.plan_id = licenseDetailsForFreeplan.plan_id;
      user.plan_name = licenseDetailsForFreeplan.plan_name;
      user.added_clients = licenseDetailsForFreeplan.added_clients;
      user.client_no = licenseDetailsForFreeplan.client_no;
      user.fe_payment_status = licenseDetailsForFreeplan.fe_payment_status;
      user.plan_purchased = licenseDetailsForFreeplan.plan_purchased;
      user.grace_period = licenseDetailsForFreeplan.grace_period;
      user.already_cancelled = licenseDetailsForFreeplan.already_cancelled;

      localStorage.setItem("imt__user", JSON.stringify(user));
      if (user.plan_purchased) {
        window.location.pathname = "/app/dashboard";
      } else {
        window.location.pathname = "/app/membership-plan";
      }
    }, 150);
    return response.message;
  } else {
    throw new Error(response.message);
  }
});

const initialState = {
  apiState: "idle" as APIState,
  apiMessage: "" as string,
  user: {} as AuthUser,
  currentStep: "basic" as StepsType,
  basicDetails: {} as BasicDetails,
  identityDetails: {} as IdentityDetails,
  communicationDetails: {} as CommunicationDetails,
  bankDetails: {} as BankDetails,
  directorList: [] as any,
  newModified: [] as any,
};

const defaultSlice = createSlice({
  name: "onboard",
  initialState,
  reducers: {
    previousStep(state, _action: PersistActionType) {
      // console.log(_action.payload.data);
      const stepPos = stepPositionMap[state.currentStep];
      const cStep = Math.max(stepPos - 1, 0);
      state.currentStep = _positionStepMap[cStep] as StepsType;
      switch (_action.payload.step) {
        case "basic":
          state.basicDetails = _action.payload.data as BasicDetails;
          break;
        case "identity":
          state.identityDetails = _action.payload.data as IdentityDetails;
          break;
        case "communication":
          state.communicationDetails = _action.payload
            .data as CommunicationDetails;
          break;
        case "bank":
          state.bankDetails = _action.payload.data as BankDetails;
          break;
      }
      localStorage.setItem(
        "imt__data_onboarding",
        JSON.stringify({
          basicDetails: state.basicDetails,
          identityDetails: state.identityDetails,
          communicationDetails: state.communicationDetails,
          bankDetails: state.bankDetails,
        })
      );
    },
    handleDirectorsList(state, _action: any) {
      state.directorList = _action.payload;
    },

    modifyDirectorList(state, _action: any) {
      const filteredPeople = state.directorList.filter(
        (item: any) => item.name !== _action.payload.name
      );
      if (_action.payload.name) {
        const findDir = state.directorList.find(
          (it: any) => it.name === _action.payload.name
        );
        const index = state.directorList.indexOf(findDir);
        filteredPeople.splice(index, 0, _action.payload);
        state.newModified = filteredPeople;
      }
    },

    finishStep(state, _action: PersistActionType) {
      switch (_action.payload.step) {
        case "basic":
          state.basicDetails = _action.payload.data as BasicDetails;
          break;
        case "identity":
          state.identityDetails = _action.payload.data as IdentityDetails;
          break;
        case "communication":
          state.communicationDetails = _action.payload
            .data as CommunicationDetails;
          break;
        case "bank":
          state.bankDetails = _action.payload.data as BankDetails;
          break;
      }
      localStorage.setItem(
        "imt__data_onboarding",
        JSON.stringify({
          basicDetails: state.basicDetails,
          identityDetails: state.identityDetails,
          communicationDetails: state.communicationDetails,
          bankDetails: state.bankDetails,
        })
      );
    },
    advanceStep(state) {
      const stepPos = stepPositionMap[state.currentStep];
      const cStep =
        stepPos + 1 >= _stepsLength ? _stepsLength - 1 : stepPos + 1;
      state.currentStep = _positionStepMap[cStep] as StepsType;
    },
  },
  extraReducers(builder) {
    builder
      .addCase("imt/INIT", (state, _action) => {
        state.user = JSON.parse(localStorage.getItem("imt__user")!);
        const dataStr = localStorage.getItem("imt__data_onboarding");
        if (dataStr) {
          const data = JSON.parse(dataStr);
          state.basicDetails = data.basicDetails;
          state.identityDetails = data.identityDetails;
          state.communicationDetails = data.communicationDetails;
          state.bankDetails = data.bankDetails;
        }
        if (!state.user && window.location.pathname === "/onboard") {
          notification.warn({ message: "Please login to continue" });
          setTimeout(() => {
            window.location.pathname = "/";
          }, 1000);
        }
      })

      .addCase(doSaveOnboardData.pending, (state, _action) => {
        state.apiState = "loading";
      })
      .addCase(doSaveOnboardData.fulfilled, (state, _action) => {
        state.apiState = "success";
        state.apiMessage = _action.payload;
      })
      .addCase(doSaveOnboardData.rejected, (state, _action) => {
        state.apiState = "error";
        state.apiMessage = _action.error.message + "";
      });
  },
});

const rootReducer = combineReducers({
  onboard: defaultSlice.reducer,
});

export type OnboardStoreState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,

  devTools: {
    name: "OnboardApp",
  },
});

export type OnboardDispatch = typeof store.dispatch;

store.dispatch({ type: "imt/INIT" });

export const action = {
  ...defaultSlice.actions,
};
