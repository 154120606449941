import { Input, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import * as React from "react";
import SummaryTags, { NameValuePair } from "~/component/SummaryTags";
import { formatDate } from "~/lib";
import { PurchaseLedger } from "./tally-dirty-entity-type";

interface IProps {
  purchaseLedgerList: Array<PurchaseLedger>;
  selectedIds: Array<number>;
  onSelectionChange: (idList: Array<number>) => void;
}

export default function PurchaseLedgerSelect({
  purchaseLedgerList,
  // selectedIds,
  onSelectionChange,
}: IProps) {
  const [searchValue, setSearchValue] = React.useState("");
  const columnDef = React.useMemo(() => {
    const definition: Array<ColumnType<PurchaseLedger>> = [
      {
        title: "Ledger name",
        dataIndex: "name",
        filteredValue: [searchValue],
        onFilter: (value, record) => {
          return (
            String(record.name)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.id)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.crt_time)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase())
          );
        },
      },
      {
        title: "Category",
        dataIndex: "id",
        render(_value, record, _index) {
          const r = record as any;
          if (!r._cats) {
            const cats: Array<NameValuePair> = [];
            if (record.is_goods) {
              cats.push({ name: "Goods", value: undefined });
            }
            if (record.is_non_compliance) {
              cats.push({ name: "Non compliance", value: undefined });
            }
            if (record.is_property) {
              cats.push({ name: "Property", value: undefined });
            }
            if (record.is_rent) {
              cats.push({ name: "Rent", value: undefined });
            }
            if (record.is_salary) {
              cats.push({ name: "Salary", value: undefined });
            }
            if (
              !record.is_salary &&
              !record.is_rent &&
              !record.is_property &&
              !record.is_non_compliance &&
              !record.is_goods
            ) {
              cats.push({ name: "Service", value: undefined });
            }
            r._cats = cats;
          }
          return <SummaryTags nameValuePairs={r._cats} />;
        },
      },
      {
        title: "Create time",
        dataIndex: "crt_time",
        render: (crtTime: string, record: any) => {
          if (crtTime && !record._formatedCrtTime) {
            record._formatedCrtTime = formatDate(new Date(crtTime));
          }
          return <span>{crtTime ? record._formatedCrtTime : "-"}</span>;
        },
      },
      // {
      //   title: "State",
      //   dataIndex: "billing_address_state",
      // },
      // {
      //   title: "Country",
      //   dataIndex: "billing_address_country",
      // },
      // {
      //   title: "Currency",
      //   // dataIndex: "symbol_name",
      //   dataIndex: "symbol_name",
      // },
    ];

    return definition;
  }, [searchValue]);

  const rowSelection = React.useMemo(() => {
    const selection: TableRowSelection<PurchaseLedger> = {
      onChange(_selectedRowKeys, selectedRows) {
        onSelectionChange(selectedRows.map((it) => it.id));
      },
      // selectedRowKeys: selectedIds
    };
    return selection;
  }, [onSelectionChange]);

  return (
    <>
      <Input
        placeholder="Search Here"
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setSearchValue(e.target.value);
        }}
        style={{ maxWidth: "40%" }}
        allowClear
      />
      <Table
        rowKey="id"
        style={{ maxWidth: "80%", cursor: "pointer" }}
        columns={columnDef}
        rowSelection={rowSelection}
        dataSource={purchaseLedgerList}
        size="large"
      />
    </>
  );
}
