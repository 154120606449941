import React, { PureComponent } from "react";
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  AutoComplete,
  notification,
  Switch,
} from "antd";
import { Required } from "~/lib/form_rules";
import { FormInstance } from "antd/lib/form";
import { APIState, fetchUtil } from "~/api/common";
import { ProductItem } from "~/api/invoice";
import TextArea from "antd/lib/input/TextArea";
import { getCategory } from "~/api/vendor";
import { values } from "lodash";
import { useGSTRateList } from "~/lib/hook/api-hook/picklistHooks";
import { getGSTRates, getUnits } from "~/api/expense";
import FieldInfo from "~/component/FieldInfo";
import { ExclamationCircleOutlined } from "@ant-design/icons";

interface Props {
  visible: boolean;
  onProductSubmit: (val: ProductItem) => void;
  closeProductModal: () => void;
  loadProduct: boolean;
}

interface State {
  productData?: ProductItem;
  apiState: APIState;
  categoryList: any;
  gstRateList: any;
  typeList: any;
  unitList: any;
  categoryPath: any;
  hsnUnitMandatory: boolean;
  isDescriptionDirty: boolean;
}

export default class AddProductModalDialog extends PureComponent<Props, State> {
  state: State = {
    productData: undefined,
    apiState: "loading",
    categoryList: [],
    gstRateList: [],
    typeList: [
      {
        id: 1,
        type: "GOODS",
      },
      {
        id: 2,
        type: "SERVICES",
      },
    ],
    unitList: [],
    categoryPath: "",
    hsnUnitMandatory: false,
    isDescriptionDirty: false,
  };

  _formRef = React.createRef<FormInstance<ProductItem>>();

  componentDidMount() {
    this.setState({
      apiState: "idle",
    });
    this.loadGstRate();
    this.loadUnits();
    this.loadProdCategory();
  }

  loadProdCategory = async () => {
    await fetchUtil("GET", "/get_product_category").then(
      ({ ok, message, json }) => {
        if (!ok) {
          notification.error({ message });
          return { ok, message };
        } else {
          if (json?.length > 0) {
            this.setState({
              categoryList: json,
            });
          }
        }
      }
    );
  };

  // loadCategoryLists = async (value: string) => {
  //   const [categoryListRes] = await Promise.all([
  //     getCategory(value as string).then(({ ok, message, data }) => ({
  //       ok,
  //       message,
  //       data,
  //     })),
  //   ]);

  //   if (!categoryListRes.ok) {
  //     notification.error({
  //       message: "Failed to load city list.",
  //       description: categoryListRes.message,
  //     });
  //   } else {
  //     this.setState({
  //       categoryList: categoryListRes.data,
  //     });
  //   }
  // };

  loadGstRate = async () => {
    const [gstRes] = await Promise.all([
      getGSTRates().then(({ ok, message, data }) => ({
        ok,
        message,
        data,
      })),
    ]);

    if (!gstRes.ok) {
      notification.error({
        message: "Failed to load gst rate list.",
        description: gstRes.message,
      });
    } else {
      this.setState({
        gstRateList: gstRes.data,
      });
    }
  };

  loadUnits = async () => {
    const [unitRes] = await Promise.all([
      getUnits().then(({ ok, message, data }) => ({
        ok,
        message,
        data,
      })),
    ]);

    if (!unitRes.ok) {
      notification.error({
        message: "Failed to load gst rate list.",
        description: unitRes.message,
      });
    } else {
      this.setState({
        unitList: unitRes.data,
      });
    }
  };

  onValuesChange = (
    changedValues: Partial<ProductItem>,
    values: ProductItem
  ) => {
    if (changedValues?.category_id) {
      if (
        values.hsn_sac ||
        values.gst_rate_id ||
        values.unit_id ||
        values.rate
      ) {
        Modal.confirm({
          title: "Do you want to reset values with category defaults ?",
          icon: <ExclamationCircleOutlined />,
          // content: "Some descriptions",
          centered: true,
          onOk: () => {
            // console.log('OK');
          },
          // onCancel() {
          //   console.log('Cancel');
          // },
        });
      } else {
        this.handleCategorySelect(changedValues.category_id);
      }

      // this.handleCategorySelect(changedValues.category_id);
    }

    if (changedValues?.supply_type === "GOODS") {
      this.setState({ hsnUnitMandatory: true });
    }
    if (changedValues?.supply_type === "SERVICES") {
      this.setState({ hsnUnitMandatory: false });
    }

    if (changedValues.is_non_gst_supply) {
      // console.log("is_reverse_charge_applicable")
      this._formRef.current!.setFieldsValue({
        is_reverse_charge_applicable: false,
      });
    }
    if (changedValues.is_reverse_charge_applicable) {
      this._formRef.current!.setFieldsValue({
        is_non_gst_supply: false,
      });
    }

    if (changedValues.description) {
      this.setState({ isDescriptionDirty: true });
    }
    if (changedValues.product_name) {
      if (!this.state.isDescriptionDirty) {
        this._formRef.current!.setFieldsValue({
          description: changedValues.product_name,
        });
      }
    }
    //  else {
    //   this.resetCategoryData();
    // }
    // if (changedValues.product_name) {
    //   this.setState({
    //     productData: {
    //       ...this.state.productData,
    //       product_name:changedValues.product_name
    //     }
    //   })
    // }
    // if (changedValues.rate) {
    //   this.setState({
    //     productData: {
    //       ...this.state.productData,
    //       rate:changedValues.rate
    //     }
    //   })
    // }
    // if (changedValues.product_description) {
    //   this.setState({
    //     productData: {
    //       ...this.state.productData,
    //       product_description:changedValues.product_description
    //     }
    //   })
    // }
    // if (changedValues.hsn_sac) {
    //   this.setState({
    //     productData: {
    //       ...this.state.productData,
    //       hsn_sac:changedValues.hsn_sac
    //     }
    //   })
    // }
  };

  resetCategoryData = () => {
    this.setState({
      categoryList: [],
    });
  };

  handleCategorySelect = (categoryId: any) => {
    const id = Number(categoryId);
    // tslint:disable-next-line: triple-equals | Double equal is intentional, please don't correct it
    const categoryData = this.state.categoryList.find(
      (it: any) => it.id === categoryId
    )!;
    const formValues = this._formRef.current?.getFieldsValue();
    if (this._formRef.current) {
      this.setState({
        productData: {
          ...this.state.productData!,
          gst_rate_id: categoryData.gst_rate_id
            ? categoryData.gst_rate_id
            : formValues.gst_rate_id,
          hsn_sac: categoryData.hsn_sac
            ? categoryData.hsn_sac
            : formValues.hsn_sac,
          category: categoryData.name,
          category_id: categoryData.id,
          rate: categoryData.rate ? categoryData.rate : formValues.rate,
          unit_id: categoryData.unit_id
            ? categoryData.unit_id
            : formValues.unit_id,
        },
        categoryPath: categoryData.path,
      });

      this._formRef.current.setFieldsValue({
        gst_rate_id: categoryData.gst_rate_id
          ? categoryData.gst_rate_id
          : formValues.gst_rate_id,
        hsn_sac: categoryData.hsn_sac
          ? categoryData.hsn_sac
          : formValues.hsn_sac,
        category: categoryData.path,
        category_id: categoryData.id,
        rate: categoryData.rate ? categoryData.rate : formValues.rate,
        unit_id: categoryData.unit_id
          ? categoryData.unit_id
          : formValues.unit_id,
      });
    }
  };

  handleOk = () => {
    if (!this._formRef.current) {
      return;
    }

    this._formRef.current?.validateFields().then((value) => {
      value.category_id = this.state.productData?.category_id;
      value.is_taxable = this.state.productData?.is_taxable;
      value.is_non_gst_supply = !!value.is_non_gst_supply;
      value.is_reverse_charge_applicable = !!value.is_reverse_charge_applicable;
      this.props.onProductSubmit(value as ProductItem);
    });
  };

  handleCancel = () => {
    this.props.closeProductModal();
  };

  handleCategoryFilterOption = (input?: string, option?: any): any => {
    return (
      input && option.extra.name.toLowerCase().includes(input.toLowerCase())
    );
  };

  render() {
    const { visible } = this.props;

    return (
      <Modal
        title="Add item ( Good / Service )"
        visible={visible}
        confirmLoading={this.props.loadProduct}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        centered
        destroyOnClose
        maskClosable={false}
      >
        <Form
          ref={this._formRef}
          initialValues={this.state.productData}
          onValuesChange={this.onValuesChange}
        >
          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Name"
                required={true}
                name="product_name"
                rules={Required("Product name")}
              >
                <TextArea rows={2} placeholder="Short product name" />
              </Form.Item>
              <Form.Item
                label="Supply type (Goods or Service)"
                name="supply_type"
                rules={Required("supply type")}
              >
                <Select showSearch>
                  {this.state.typeList?.map((it: any, indx: any) => (
                    <Select.Option key={indx} value={it.type}>
                      {it.type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Selling rate"
                name="rate"
                // rules={Required("Rate")}
                rules={[{ required: false }]}
              >
                <Input type="number" placeholder="Per unit selling rate" />
              </Form.Item>
              <Form.Item
                label="HSN"
                name="hsn_sac"
                // rules={Required("HSN")}
                rules={
                  this.state.hsnUnitMandatory
                    ? Required("HSN code")
                    : [{ required: false }]
                }
              >
                <Input type={"number"} placeholder="4 - 8 digit code" />
              </Form.Item>
              <Form.Item
                label="Non-gst supply"
                name="is_non_gst_supply"
                rules={[{ required: false }]}
                valuePropName="checked"
              >
                <Switch
                // checked={value}
                // disabled={readOnly || disabled}
                />
              </Form.Item>
            </Col>

            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Description"
                name="description"
                rules={Required("Product description")}
                // rules={[{ required: false, type: "string", message: "" }]}
              >
                <TextArea rows={2} placeholder="Product description" />
              </Form.Item>
              <Form.Item
                label="Category"
                name="category_id"
                // rules={Required("Category")}
                rules={[{ required: false }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={this.handleCategoryFilterOption}
                >
                  {this.state.categoryList?.map((opt: any, indx: any) => (
                    <Select.Option key={indx} value={opt.id} extra={opt}>
                      <div>
                        <div>{opt.name}</div>
                        <div
                          style={{
                            fontSize: 10,
                            marginTop: -3,
                            marginBottom: 5,
                            color: "var(--primary-color)",
                          }}
                        >
                          {opt.path}
                        </div>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
                {/* <AutoComplete
                  id="chrome-off"
                  onSelect={this.handleCategorySelect}
                  onBlur={this.resetCategoryData}
                >
                  {this.state.categoryList.map((v: any, idx: number) => {
                    return (
                      <AutoComplete.Option key={v.id} value={`${v.id}`}>
                        <div>
                          <span>{v.path}</span>{" "}
                          <span
                            style={{
                              fontSize: 10,
                              color: "var(--primary-color)",
                            }}
                          >
                            {v.path}
                          </span>
                        </div>
                      </AutoComplete.Option>
                    );
                  })}
                </AutoComplete> */}
              </Form.Item>
              {this.state.categoryPath ? (
                <FieldInfo text={this.state.categoryPath} />
              ) : null}

              <Form.Item
                label="Gst rate"
                name="gst_rate_id"
                rules={Required("Gst rate")}
              >
                <Select showSearch>
                  {this.state.gstRateList?.map((it: any, indx: any) => (
                    <Select.Option key={indx} value={it.id}>
                      {it.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Unit"
                name="unit_id"
                // rules={Required("unit")}
                rules={
                  this.state.hsnUnitMandatory
                    ? Required("Unit code")
                    : [{ required: false }]
                }
              >
                <Select showSearch showArrow optionFilterProp="children">
                  {this.state.unitList?.map((it: any, indx: any) => (
                    <Select.Option key={indx} value={it.id}>
                      {`${it.quantity} (${it.uqc_code})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Reverse charge applicable"
                name="is_reverse_charge_applicable"
                rules={[{ required: false }]}
                valuePropName="checked"
              >
                <Switch
                // defaultChecked={false}
                // onChange={this.onChangeReverseCharge}
                // checked={value}
                // disabled={readOnly || disabled}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
