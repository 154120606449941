import React, { useState, useEffect } from "react";
import JSONViewerText from "./jsonDownloadButton/json_viewer/jsonViewerText";
import JSONViewer from "./jsonDownloadButton/json_viewer/jsonviewer";
import ExportFunctionality from "./jsonDownloadButton/exportFunctionality/exportfunctionality";
import styles from "./JsonDownloadPreview.module.css";
import { Button, Affix, Switch } from "antd";

type props = {
  jsonDataShow: any;
  name: string;
};
export default function JsonDownload({ jsonDataShow, name }: props) {
  const [loading, setLoading] = useState(false);
  const [JSONToView, setJSONToView] = useState(jsonDataShow);
  const oldData = jsonDataShow;
  const [editable, setEditable] = useState(true);
  const changeEditable = () => {
    setEditable(!editable);
  };

  useEffect(() => {
    setJSONToView(jsonDataShow);
  }, [jsonDataShow]);

  const setDataOfALayer = (
    aLayer: string,
    newValue: number | string,
    type: string
  ) => {
    /* tslint:disable:one-variable-per-declaration */

    const layersArray = aLayer ? aLayer.split(",") : "".split(",");

    const mainData = JSON.parse(JSON.stringify(JSONToView, null, 2));
    let currentVariable = mainData;
    if (aLayer === "gstin" || aLayer === "ret_period") {
      // Do noting
    } else {
      if (type === "plus") {
        layersArray.forEach((singleLayer: any, index: any, arr: any) => {
          if (newValue !== undefined && index === arr.length - 1) {
            currentVariable[singleLayer].splice(
              Number(newValue) + 1,
              0,
              currentVariable[singleLayer][newValue]
            );
          }
          if (singleLayer && singleLayer.length > 0) {
            currentVariable = currentVariable[singleLayer];
          }
        });
        setJSONToView(mainData);
      } else if (type === "minus") {
        layersArray.forEach((singleLayer: any, index: any, arr: any) => {
          if (newValue !== undefined && index === arr.length - 1) {
            currentVariable[singleLayer].splice(newValue, 1);
          }
          if (singleLayer && singleLayer.length > 0) {
            currentVariable = currentVariable[singleLayer];
          }
        });

        setJSONToView(mainData);
      } else {
        layersArray.forEach((singleLayer: any, index: any, arr: any) => {
          if (newValue !== undefined && index === arr.length - 1) {
            currentVariable[singleLayer] = newValue;
          }
          if (singleLayer && singleLayer.length > 0) {
            currentVariable = currentVariable[singleLayer];
          }
        });
        setJSONToView(mainData);
      }
    }
  };
  const restoreData = () => {
    setJSONToView(oldData);
  };

  return (
    <div className={styles.containerJson}>
      <Affix offsetTop={75}>
        <div className={styles.editJsonB}>
          <span>Edit&nbsp;</span>

          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            defaultChecked
            onChange={changeEditable}
          />
        </div>

        {oldData !== JSONToView && (
          <Button className={styles.resetButton} danger onClick={restoreData}>
            Reset
          </Button>
        )}
        <ExportFunctionality
          name={name}
          JSONToViewString={JSON.stringify(JSONToView, null, 2)}
          setLoading={setLoading}
        />
      </Affix>

      {editable ? (
        <div className={styles.jsonPreview}>
          <JSONViewer
            JSONToView={JSONToView}
            loading={loading}
            setLoading={setLoading}
            currentLayer={""}
            setDataOfALayer={setDataOfALayer}
            editable={editable}
          />
        </div>
      ) : (
        <div className={styles.jsonPreview}>
          <JSONViewerText
            JSONToView={JSONToView}
            loading={loading}
            setLoading={setLoading}
            currentLayer={""}
            setDataOfALayer={setDataOfALayer}
          />
        </div>
      )}
    </div>
  );
}
