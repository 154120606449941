import React, { Component } from "react";
import { Tag } from "antd";
import Currency from "./Currency";

export type NameValuePair = {
  name: string;
  value: string | number | undefined;
  isCurrency?: boolean;
};

interface Props {
  nameValuePairs: Array<NameValuePair>;
  marginBottom?: number;
}

export default class SummaryTags extends Component<Props, {}> {
  state = {};

  render() {
    return (
      <div>
        {this.props.nameValuePairs.map((pair, _idx) => (
          <Tag
            key={pair.name}
            style={{
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            <span
              style={{
                color: "var(--processing-color)",
                fontWeight: "bold",
              }}
            >
              {pair.name}{" "}
            </span>
            {pair.isCurrency ? (
              <Currency value={pair.value as number} />
            ) : (
              pair.value
            )}
          </Tag>
        ))}
      </div>
    );
  }
}
