import React, { PureComponent } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import {
  Checkbox,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  notification,
  Button,
} from "antd";
import { Required } from "~/lib/form_rules";
import { FormInstance } from "antd/lib/form";
import { isBefore } from "date-fns";
import DatePicker from "~/component/antd-overrides/DatePicker";
import { roundNumber } from "~/lib";
import { OTHER_TERITORY_ID } from "~/lib/constants";

import {
  payConfirm,
  PaymentStatus,
  PaymentMode,
  partialInvoice,
} from "~/api/invoice";
import { APIState, getBank, BankList } from "~/api/common";

import commonStyle from "~/component/common.module.css";
import FieldInfo from "~/component/FieldInfo";
import { FORMAT_DATE, formatNumber, parseInputNumber } from "~/lib";

const { TextArea } = Input;
const { Option } = Select;

interface Props extends RouteComponentProps {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  selectInvoiceDetail: partialInvoice;
  fetchData: () => void;
  vendorPickList?: any;
  placeOfSupply?: number;
}

type PaymentCaptureData = {
  invoice_id: number;
  invoice_number?: string;
  transaction_id?: any;
  payment_date: any;
  paid_by: number;
  paid_amount: any;
  status: PaymentStatus;
  tds_rate?: number;
  tds_amount?: number;
  bank_id?: number;
  notes?: string;
  pay_amount: number | string;
  conversion_rate: string | undefined;
  payment_mode: PaymentMode;
  extra?: {
    // extra field
    taxableAmount: number;
    appliedTDS: boolean;
    subTotal: number;
    gstAmount: number;
  };
};

interface State {
  paymentData?: PaymentCaptureData;
  apiState: APIState;
  bankList: Array<BankList>;
  isReceivingBankEmpty: boolean;
  showConfirmationModal: boolean;
  selectedBank: string;
}

const OPTIONS_PAYMENT_MODE = [
  { value: 1, label: "Cash" },
  { value: 2, label: "Other(Bank)" },
];

class PaymentDialog extends PureComponent<Props, State> {
  state: State = {
    paymentData: undefined,
    apiState: "loading",
    bankList: [],
    isReceivingBankEmpty: false,
    showConfirmationModal: false,
    selectedBank: "",
  };

  _formRef = React.createRef<FormInstance<PaymentCaptureData>>();

  componentDidMount() {
    this.updateFormUsingProps();

    Promise.all([this.loadBanks()]).then(() => {
      this.setState({
        apiState: "idle",
      });
    });

    this.setState({
      apiState: "idle",
    });
  }

  loadBanks = async () => {
    const [states] = await Promise.all([getBank()]);
    if (!states.ok) {
      notification.error({
        message: "Failed to load states",
        description: states.message,
      });
      this.updateFormUsingProps();
    } else {
      const bankList = states.data as Array<BankList>;
      const isReceivingBankEmpty = bankList.length === 0;
      this.setState(
        {
          bankList: states.data as any,
          isReceivingBankEmpty,
          selectedBank: `${bankList[0].bank_name} (${bankList[0].account_number})`,
        },
        () => {
          this.updateFormUsingProps();
        }
      );
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.selectInvoiceDetail !== this.props.selectInvoiceDetail) {
      this.updateFormUsingProps();
    }
  }

  updateFormUsingProps = async () => {
    const details = this.props.selectInvoiceDetail;
    const { igst, sgst, cgst } = details;

    const paymentData: PaymentCaptureData = {
      invoice_id: 0,
      invoice_number: details.invoice_number,
      transaction_id: undefined,
      payment_date: undefined,
      paid_by: parseInt("aabbcc", 16),
      paid_amount: roundNumber(
        (Number(details.calAmount!) + Number(details.calTax!)) *
          Number(details.conversion_rate) -
          Number(details.creditNoteAmt ? details.creditNoteAmt : 0) *
            Number(details.conversion_rate) +
          Number(details.debitNoteAmt! ? details.debitNoteAmt! : 0) *
            Number(details.conversion_rate)
      ),
      status: 0,
      tds_rate: 0,
      tds_amount: 0,
      bank_id: this.state.bankList[0] ? this.state.bankList[0].id : undefined,
      notes: "",
      pay_amount: roundNumber(
        Number(details.calAmount!) +
          Number(details.calTax!) -
          Number(details.creditNoteAmt ? details.creditNoteAmt : 0) +
          Number(details.debitNoteAmt! ? details.debitNoteAmt! : 0)
      ),
      conversion_rate: details.conversion_rate,
      payment_mode: PaymentMode.OTHER,
      extra: {
        taxableAmount: roundNumber(
          Number(details.calAmount!) + Number(details.calTax!)
        ),
        appliedTDS: false,
        subTotal: roundNumber(+details.calAmount!),
        gstAmount: roundNumber(
          (igst ? +igst : 0) + (sgst ? +sgst : 0) + (cgst ? +cgst : 0)
        ),
      },
    };
    this.setState(
      {
        paymentData,
      },
      () => {
        this._formRef.current!.setFieldsValue(this.state.paymentData!);
      }
    );
  };

  onValuesChange = (
    changedValues: Partial<PaymentCaptureData>,
    values: PaymentCaptureData
  ) => {
    const details = this.props.selectInvoiceDetail;
    let shouldUpdateFormData = false;
    if (changedValues.tds_rate) {
      values.extra!.subTotal = roundNumber(
        +this.props.selectInvoiceDetail.calAmount! -
          this.props.selectInvoiceDetail.calAmount! *
            (changedValues.tds_rate! / 100) +
          this.props.selectInvoiceDetail.calTax! -
          (this.props.selectInvoiceDetail.creditNoteAmt!
            ? this.props.selectInvoiceDetail.creditNoteAmt!
            : 0) +
          (this.props.selectInvoiceDetail.debitNoteAmt!
            ? this.props.selectInvoiceDetail.debitNoteAmt!
            : 0)
      );
      values.tds_amount = roundNumber(
        this.props.selectInvoiceDetail.calAmount! *
          (changedValues.tds_rate / 100)
      );
      values.pay_amount = roundNumber(values.extra!.subTotal);
      values.paid_amount = roundNumber(
        values.pay_amount * Number(details.conversion_rate)
      );
      shouldUpdateFormData = true;
    }
    if (changedValues.payment_mode === 1) {
      this._formRef.current?.validateFields();
      values.transaction_id = "";
      shouldUpdateFormData = true;
      values.bank_id = undefined;
    }
    if (changedValues.extra) {
      values.tds_rate = 0;
      values.tds_amount = 0;
      values.extra!.subTotal =
        +this.props.selectInvoiceDetail.calAmount! +
        this.props.selectInvoiceDetail.calTax! -
        (this.props.selectInvoiceDetail.creditNoteAmt!
          ? this.props.selectInvoiceDetail.creditNoteAmt!
          : 0) +
        (this.props.selectInvoiceDetail.debitNoteAmt!
          ? this.props.selectInvoiceDetail.debitNoteAmt!
          : 0);

      values.pay_amount = roundNumber(values.extra!.subTotal);
      values.paid_amount = roundNumber(
        values.pay_amount * Number(details.conversion_rate)
      );
      shouldUpdateFormData = true;
    }

    if (!isNaN(changedValues.paid_amount)) {
      values.paid_amount = changedValues.paid_amount;
    }

    this.setState(
      {
        paymentData: values,
      },
      () => {
        if (shouldUpdateFormData) {
          this._formRef?.current?.setFieldsValue(this.state.paymentData!);
        }
      }
    );
  };

  handleOk = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({
      apiState: "loading",
    });
    try {
      await this._formRef.current?.validateFields();
      const { ok, message } = await payConfirm(
        Number(this.state.paymentData?.bank_id),
        this.props.selectInvoiceDetail.invoice_id!,
        String(this.state.paymentData?.notes),
        Number(this.state.paymentData?.paid_amount),
        Number(this.state.paymentData?.paid_by),
        Number(this.state.paymentData?.paid_amount),
        this.state.paymentData?.payment_date,
        Number(this.state.paymentData?.payment_mode),
        1,
        Number(this.state.paymentData?.tds_amount),
        Number(this.state.paymentData?.tds_rate),
        this.state.paymentData?.transaction_id,
        this.state.paymentData?.conversion_rate!
      );
      if (!ok) {
        notification.error({ message });
      } else {
        this.setState(
          {
            apiState: "idle",
          },
          () => {
            this.props.onVisibleChange(false);
            this.props.fetchData();
          }
        );
        notification.success({ message: "Paid successfully" });
      }
    } catch (err) {
      this.setState({
        apiState: "idle",
      });
    }
  };
  handleReceivingBankDropdownVisibleChange = (open: boolean) => {
    const { paymentData, isReceivingBankEmpty } = this.state;

    if (open && isReceivingBankEmpty) {
      // Show the confirmation modal since the bank list is empty
      this.setState({ showConfirmationModal: true });
    }
  };
  handleConfirm = async () => {
    // Close the confirmation modal
    this.setState({ showConfirmationModal: false });
    this.props.history.push("/app/profile?tab=official");
  };
  handleCancelConfirmation = () => {
    // Close the confirmation modal
    this._formRef.current?.setFieldsValue({ payment_mode: 1 });
    // Reset the loading state if needed
    this.setState((prevState: any) => ({
      paymentData: {
        ...prevState.paymentData,
        payment_mode: PaymentMode.CASH, // Set the payment mode to "Cash" (1) when Cancel is clicked
      },
      apiState: "idle", // Reset the loading state if needed
      showConfirmationModal: false,
    }));
    this.setState({ apiState: "idle" });
  };

  handleNumberInputEvt = (evt: React.ChangeEvent<HTMLInputElement>) => {
    return parseInputNumber(evt.target.value, "");
  };

  handleCancel = (_e: React.MouseEvent<HTMLElement>) => {
    this.props.onVisibleChange(false);
  };

  numberConverter = (str: string) => {
    return +str;
  };

  handleBankNameChange = (val: number) => {
    //  console.log(val)
    const getSelectedBank = this.state.bankList.find((data) => data.id === val);
    // console.log(getSelectedBank)
    this.setState({
      selectedBank: `${getSelectedBank?.bank_name} (${getSelectedBank?.account_number})`,
    });
  };

  render() {
    const { visible } = this.props;
    const placeOfSupply = this.props.placeOfSupply
      ? this.props.placeOfSupply
      : this.props.vendorPickList?.find(
          (it: any) =>
            it.customer_idx === this.props.selectInvoiceDetail.customer_idx
        )?.state_code;
    const viewConvertionDetails =
      this.props.selectInvoiceDetail.customer_idx &&
      this.props.selectInvoiceDetail.symbol_name !== "INR"
        ? `= paid amount: ${
            this.props.selectInvoiceDetail.customer_currency
          } ${formatNumber(Number(this.state.paymentData?.pay_amount))} (1 ${
            this.props.selectInvoiceDetail.symbol_name
          } =
        ${(1 * Number(this.props.selectInvoiceDetail?.conversion_rate)).toFixed(
          2
        )} INR)`
        : "";
    const conversionRate = this.props.selectInvoiceDetail.conversion_rate;

    return (
      <>
        <Modal
          title="Record payment"
          visible={visible}
          // confirmLoading={this.state.apiState === "loading"}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          centered
          destroyOnClose
          maskClosable={false}
        >
          <Form
            ref={this._formRef}
            initialValues={this.state.paymentData}
            onValuesChange={this.onValuesChange}
          >
            <Row gutter={{ xs: 40, sm: 100, md: 100, lg: 10, xl: 100 }}>
              <Col span={12}>
                <Form.Item name="invoice_number" label="Invoice number">
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name={["extra", "taxableAmount"]}
                  label="Invoice amount"
                >
                  <Input readOnly />
                </Form.Item>
                <FieldInfo
                  text={`= Taxable: ${formatNumber(
                    (this.state.paymentData?.extra?.taxableAmount || 0) -
                      (this.props.selectInvoiceDetail.calTax || 0)
                  )} + GST: ${formatNumber(
                    this.props.selectInvoiceDetail.calTax || 0
                  )}`}
                />

                <Form.Item
                  name={["extra", "appliedTDS"]}
                  className={commonStyle["w-100"]}
                  valuePropName="checked"
                  style={{ marginTop: 10 }}
                >
                  <Checkbox disabled={placeOfSupply === OTHER_TERITORY_ID}>
                    <b>Apply TDS</b>
                  </Checkbox>
                </Form.Item>

                <Form.Item
                  className={commonStyle["w-100"]}
                  name="tds_rate"
                  label="TDS rate"
                  getValueFromEvent={this.numberConverter}
                  style={{ marginTop: -15 }}
                >
                  <Select
                    showSearch
                    disabled={!this.state.paymentData?.extra?.appliedTDS}
                  >
                    <Option value={1}>1.0% TDS</Option>
                    <Option value={2}>2.0% TDS</Option>
                    <Option value={5}>5.0% TDS</Option>
                    <Option value={7.5}>7.5% TDS</Option>
                    <Option value={10}>10.0% TDS</Option>
                    <Option value={15}>15.0% TDS</Option>
                    <Option value={20}>20.0% TDS</Option>
                    <Option value={30}>30.0% TDS</Option>
                    <Option value={40}>40.0% TDS</Option>
                    <Option value={50}>50.0% TDS</Option>
                  </Select>
                </Form.Item>
                {this.state.paymentData?.extra?.appliedTDS &&
                this.state.paymentData?.tds_amount ? (
                  <FieldInfo
                    text={`= ${formatNumber(
                      this.state.paymentData?.tds_amount
                    )}`}
                  />
                ) : null}

                <Form.Item name="pay_amount" label="Total receivable">
                  <Input readOnly />
                </Form.Item>
                <FieldInfo
                  text={`= Taxable: ${formatNumber(
                    (this.state.paymentData?.extra?.taxableAmount || 0) -
                      (this.props.selectInvoiceDetail.calTax || 0)
                  )} - TDS: ${formatNumber(
                    this.state.paymentData?.tds_amount || 0
                  )} + GST: ${formatNumber(
                    this.props.selectInvoiceDetail.calTax || 0
                  )} - Credit note: ${formatNumber(
                    this.props.selectInvoiceDetail.creditNoteAmt || 0
                  )} + Debit note: ${formatNumber(
                    this.props.selectInvoiceDetail.debitNoteAmt || 0
                  )}`}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  name="payment_date"
                  label="Receipt date"
                  rules={Required("Payment date")}
                >
                  <DatePicker
                    // tslint:disable-next-line: jsx-no-lambda
                    disabledDate={(date) =>
                      !!this.props.selectInvoiceDetail?.invoice_date &&
                      isBefore(
                        date,
                        this.props.selectInvoiceDetail.invoice_date
                      )
                    }
                    className={commonStyle["w-100"]}
                    format={FORMAT_DATE}
                  />
                </Form.Item>
                <Form.Item
                  name="transaction_id"
                  label="Transaction number"
                  rules={[
                    ({ getFieldsValue }) => {
                      const rule = {
                        required:
                          getFieldsValue(["payment_mode"]).payment_mode ===
                          PaymentMode.OTHER,
                        message: "Transaction number is required",
                      };
                      return rule;
                    },
                  ]}
                >
                  <Input
                    disabled={
                      this.state.paymentData?.payment_mode !== PaymentMode.OTHER
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="paid_amount"
                  label="Amount received on actual (INR)"
                  getValueFromEvent={this.handleNumberInputEvt}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_rule, value) {
                        const payable = getFieldValue("pay_amount");

                        const remaning = payable * +conversionRate!;

                        if (value > roundNumber(remaning)) {
                          return Promise.reject(
                            `Cannot be greater than ${formatNumber(remaning)}`
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>
                <FieldInfo text={viewConvertionDetails} />
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="payment_mode"
                  label="Payment mode"
                  style={{
                    marginTop: viewConvertionDetails.length > 0 ? 10 : 25,
                  }}
                >
                  <Select showSearch>
                    {OPTIONS_PAYMENT_MODE.map((opt) => (
                      <Option key={opt.value} value={opt.value}>
                        {opt.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="bank_id"
                  label="Receiving bank"
                  rules={[
                    ({ getFieldsValue }) => {
                      const rule = {
                        required:
                          getFieldsValue(["payment_mode"]).payment_mode ===
                          PaymentMode.OTHER
                            ? true
                            : false,
                        message: "Bank is required",
                      };
                      return rule;
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onChange={(e: number) => this.handleBankNameChange(e)}
                    disabled={
                      this.state.paymentData?.payment_mode !== PaymentMode.OTHER
                    }
                    onDropdownVisibleChange={
                      this.handleReceivingBankDropdownVisibleChange
                    }
                  >
                    {this.state.bankList.map((bank, indx) => {
                      const lastDigits = bank?.account_number?.slice(
                        bank?.account_number.length - 4
                      );
                      return (
                        <>
                          <Select.Option key={indx} value={bank.id!}>
                            {bank.bank_name} ({lastDigits})
                          </Select.Option>
                        </>
                      );
                    })}
                  </Select>
                </Form.Item>
                <FieldInfo text={this.state.selectedBank} />
                <Form.Item name="notes" label="Comments">
                  <TextArea rows={1} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          visible={this.state.showConfirmationModal}
          title="Add bank details"
          onCancel={this.handleCancelConfirmation}
          footer={[
            <Button key="cancel" onClick={this.handleCancelConfirmation}>
              No
            </Button>,
            <Button key="confirm" type="primary" onClick={this.handleConfirm}>
              Yes
            </Button>,
          ]}
          style={{ top: "30%" }}
        >
          Adding bank details is necessary for payment purposes. Please click
          Yes to proceed.
        </Modal>
      </>
    );
  }
}
export default withRouter(PaymentDialog);
