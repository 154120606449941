import React, { Component } from "react";
import { Modal, Typography } from "antd";
import { APIState } from "~/api/common";
import { withRouter, RouteComponentProps } from "react-router";
import Currency from "~/component/Currency";
import { DrawerProps } from "antd/lib/drawer";
import { getEmployeeSalary } from "~/api/payroll";

interface Props extends RouteComponentProps {
  isOpen: boolean;
  onClose: () => void;
  clickedRowData: any;
  year: any;
  closeDrawer: DrawerProps["onClose"];
}

interface State {
  apiState: APIState;
  typeKey: number;
  detailList: any;
  realSalId: number;
}

class ComplianceDetailsModal extends Component<Props, State> {
  state: State = {
    apiState: "idle",
    typeKey: 2,
    detailList: [],
    realSalId: 0,
  };

  /* componentDidMount() {
    this.arrangeFields();
  } */

  /*   componentDidUpdate() {
    this.arrangeFields();
  } */

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (!nextProps.clickedRowData?.details) return null;
    const detailList = Array.from(nextProps.clickedRowData.details);
    const { clickedRowData } = nextProps;
    if (clickedRowData.hasOwnProperty("taxable_amount")) {
      detailList.splice(3, 0, {
        label: "Taxable Amount",
        value: clickedRowData.taxable_amount,
        type: "currency",
      });
    }
    const key = JSON.stringify(detailList).substring(0, 40);
    detailList[detailList.length] = { label: "key", value: key, ignore: true };
    const keyFromState =
      prevState.detailList[prevState.detailList.length - 1]?.value;
    if (key !== keyFromState) return { detailList };
    else return null;
  }

  handleEntityClick = (evt: React.SyntheticEvent<HTMLAnchorElement>) => {
    // evt.stopPropagation();
    // evt.preventDefault();
    if (this.props.closeDrawer) {
      this.props.closeDrawer(evt as any);
    }
    // const pathname = evt.currentTarget.pathname;
    // this.props.history.push(pathname);
  };
  getSalaryDetails = async (month: any, year: any, salId: any) => {
    try {
      const data = await getEmployeeSalary(
        1,
        12,
        String(month),
        year,
        undefined,
        Number(salId)
      );
      // const salaryData = data.salaryData[0];
      if (data.salaryData[0]?.employeeData?.employee_id) {
        const salarySlip = data.salaryData[0].employeeData.employee_id;
        this.setState({
          realSalId: salarySlip,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  render() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const { detailList } = this.state;

    const salId = detailList.find((it: any) => it.label === "Salary Id")?.value;
    const month =
      months.indexOf(
        detailList.find((it: any) => it.label === "For Month")?.value
      ) + 1;
    const { year } = this.props;
    if (year) {
      this.getSalaryDetails(month, year, salId);
    }

    const invoiceId = detailList.find((it: any) => it.label === "Invoice Id")
      ?.value;
    const expenseId = detailList.find((it: any) => it.label === "Expense Id")
      ?.value;

    return (
      <Modal
        title="Compliance detail"
        footer={null}
        zIndex={1100}
        destroyOnClose={true}
        width="25%"
        centered
        visible={this.props.isOpen}
        onCancel={this.props.onClose}
        maskClosable={false}
      >
        <div>
          <div>
            <div>
              {detailList
                .filter((dl: any) => !dl.ignore)
                .map((it: any) => {
                  let href = "";
                  if (it.type === "entity" && it.extra) {
                    if (it.label === "Credit Note Id") {
                      if (invoiceId) {
                        href = `/app/invoice/${invoiceId}/creditnote/${it.value}`;
                      } else {
                        href = `/app/invoice/creditnote/${it.value}`;
                      }
                    } else if (it.label === "Debit Note Id") {
                      if (invoiceId) {
                        href = `/app/invoice/${invoiceId}/debitNote/${it.value}`;
                      } else {
                        href = `/app/invoice/debitNote/${it.value}`;
                      }
                    } else if (it.label === "Purchase Debit Note Id") {
                      if (expenseId) {
                        href = `/app/expense/${expenseId}/purchasedebitNote/${it.value}`;
                      }
                    } else if (it.label === "Seller Debit Note Id") {
                      if (expenseId) {
                        href = `/app/expense/${expenseId}/sellerdebitNote/${it.value}`;
                      }
                    } else if (it.label === "Salary Id") {
                      href = `/app/salary-detail/${this.state.realSalId}/${month}/${year}`;
                    } else if (it.label === "Supplier Credit Note Id") {
                      if (expenseId) {
                        href = `/app/expense/${expenseId}/purchasedebitNote/${it.value}`;
                      }
                    } else if (it.label === "Supplier Debit Note Id") {
                      if (expenseId) {
                        href = `/app/expense/${expenseId}/sellerdebitNote/${it.value}`;
                      }
                    } else {
                      href = `/app/${it.extra.name}/${it.value}`;
                    }
                  }
                  return (
                    <div
                      key={it.label}
                      className="span-2"
                      style={{
                        borderBottom: "1px solid #f0f0f0",
                        padding: "var(--padding-xs)",
                      }}
                    >
                      <b className="b-1">{it.label}</b>
                      {it.type === "plain" ||
                      (it.type === "entity" && !href) ? (
                        <label className="b-level-1">{it.value}</label>
                      ) : it.type === "currency" ? (
                        <Currency className="b-1">{it.value}</Currency>
                      ) : it.type === "entity" && href ? (
                        <Typography.Link href={href} target="_blank">
                          {it.value}
                        </Typography.Link>
                      ) : (
                        "-"
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withRouter(ComplianceDetailsModal);
