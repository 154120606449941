import React, { PureComponent, createRef } from "react";
import { Modal, Form, Input, Select, notification } from "antd";
import { fetchUtil } from "~/api/common";
import { FormInstance } from "antd/lib/form";
import commonStyle from "~/component/common.module.css";
import styles from "../SettingsUsers.module.css";
import { connect, ConnectedProps } from "react-redux";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  userDetails: state.mainAppSlice.user,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  setUserRole: (role_ids: any) => dispatch(action.auth.setUserRole(role_ids)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  getUser: () => void;
  // empSaveHandeler: (value: boolean) => void;
  // designationList: Array<{
  //   id: number;
  //   category: string;
  // }>;
  rolesList: Array<{
    id: number;
    role_name: string;
  }>;
  // employeeSaveLoader: boolean;
}

interface State {
  loadOk: boolean;
}

class AddUserModal extends PureComponent<Props, {}> {
  state: State = {
    loadOk: false,
  };
  formRef = createRef<FormInstance>();

  handleCancel = (_e: React.MouseEvent<HTMLElement>) => {
    this.props.onVisibleChange(false);
  };

  handleOk = (e: React.MouseEvent<HTMLElement>) => {
    if (!this.formRef.current) {
      return;
    }

    // this.props.empSaveHandeler(true);
    this.formRef.current
      .validateFields()
      .then(async (value) => {
        this.setState({
          loadOk: true,
        });
        const { ok, message, json } = await fetchUtil("POST", "/assign_role", {
          ...value,
          // role_id: LoginRole.EMPLOYEE,
        });

        if (ok) {
          // this.props.empSaveHandeler(false);
          this.setState({
            loadOk: false,
          });
          const roles = json[0];
          this.props.setUserRole(roles as any);
          notification.success({
            message,
          });
        } else {
          // this.props.empSaveHandeler(false);
          notification.error({
            message,
          });
          this.setState({
            loadOk: false,
          });
        }
        // this.props.getUser();
        this.props.onVisibleChange(false);
      })
      .catch((reason) => {
        console.warn("Form error", reason);
      });
  };

  render() {
    const { visible } = this.props;
    return (
      <Modal
        title="Add New User"
        visible={visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className={styles.modalWidth}
        confirmLoading={this.state.loadOk === true}
        destroyOnClose
        maskClosable={false}
      >
        <Form ref={this.formRef}>
          <Form.Item
            className={commonStyle["w-100"]}
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter valid Email id!",
              },
            ]}
          >
            <Input
              type="email"
              placeholder={"e.g. youremail@domain.com"}
              style={{ textTransform: "lowercase" }}
              autoFocus
            />
          </Form.Item>

          <Form.Item
            className={commonStyle["w-100"]}
            name="role_id"
            label="Role"
            rules={[
              {
                required: true,
                message: "Please select Role!",
              },
            ]}
          >
            <Select showSearch optionFilterProp="children">
              {this.props.rolesList.map((role) =>
                role.id !== 2 && role.id !== 1 ? (
                  <Select.Option key={role.id} value={role.id}>
                    {role.role_name}
                  </Select.Option>
                ) : (
                  <></>
                )
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default connector(AddUserModal);
