import React from "react";
import { IMTIconProps, IMTIconElement, __colors } from "./interface";

const Dashboard: IMTIconElement = ({ highlighted, ...props }: IMTIconProps) => {
  const color = highlighted ? __colors.active : __colors.inactive;
  return (
    <svg x="0px" y="0px" viewBox="0 0 56 56" xmlSpace="preserve" {...props}>
      <path
        d="M47.8 4.7h-9.7c-.6 0-1 .4-1 1s.4 1 1 1h9.7c.5 0 1.1.2 1.5.5-.3 0-.6.1-.8.3L36.1 19.7c-1 1-2.6 1-3.5 0l-9.9-9.9c-.8-.8-2-1.3-3.2-1.3s-2.3.5-3.1 1.4L4 22.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l12.3-12.3c.9-.9 2.6-.9 3.5 0l9.9 9.9c1.8 1.8 4.6 1.8 6.4 0L49.8 8.8c.2-.2.2-.3.3-.5.1.3.2.6.2.9v9.7c0 .6.4 1 1 1s1-.4 1-1V9.2c0-2.5-2-4.5-4.5-4.5zM8.2 33.9c-2.5 0-4.5 2-4.5 4.5v11.9c0 .6.4 1 1 1h7c.6 0 1-.4 1-1V38.4c0-2.4-2-4.5-4.5-4.5zm2.5 15.4h-5V38.4c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5v10.9zM21.4 23.7c-2.5 0-4.5 2-4.5 4.5v22.2c0 .6.4 1 1 1h7c.6 0 1-.4 1-1V28.2c0-2.5-2-4.5-4.5-4.5zm2.5 25.6h-5V28.2c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5v21.1zM34.6 33.9c-2.5 0-4.5 2-4.5 4.5v11.9c0 .6.4 1 1 1h7c.6 0 1-.4 1-1V38.4c0-2.4-2-4.5-4.5-4.5zm2.5 15.4h-5V38.4c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5v10.9zM47.8 28.7c-2.5 0-4.5 2-4.5 4.5v17.2c0 .6.4 1 1 1h7c.6 0 1-.4 1-1V33.2c0-2.5-2-4.5-4.5-4.5zm2.5 20.6h-5V33.2c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5v16.1z"
        fill={color}
      />
    </svg>
  );
};

export default Dashboard;
