// tslint-disable-file jsx-no-lambda
import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Row, Col, Space, Button } from "antd";
import styles from "./LandingPageLayout.module.css";
import IMTMediaQueries from "~/component/IMTMediaQueries";
import premiumServicesIllus from "~/assets/premium-services.png";
import partnerImage1 from "~/assets/partner-images/ca-image.jpeg";
import commonStyles from "~/component/common.module.css";

const googleFormForPartner = "https://forms.gle/fbjjdUVvxHfMBJpd8";
const googleFormForHumanAssistedServices =
  "https://forms.gle/wUzodWaJf9rvuwT26";

const services = [
  {
    title: "Assisted tax filing",
    Subtitle: "Looking for a human help to take care your taxation needs ?",
    body: "",
    bullets: [
      "Compliance calendar & dashboard",
      "No more asking for financial data to your CA, all are available round the clock.",
      "GST, TDS, PTAX, PF, MCA and ESI - all major compliances are covered",
      "Transparent pricing",
    ],
  },
  {
    title: "Partner driven services",
    Subtitle:
      "Looking for a affordable yet complete auditing & advisory services ?",
    body:
      "We have partnered with experienced chartered accountants, cost & management accountants, company secratories to help you.",
    bullets: [
      "Regulatory audit",
      "Tax audit",
      "Advisory & consultancy services",
    ],
  },
  {
    title: "Adhoc services",
    Subtitle: "Let us help you various Govt. registrations & applications",
    body: "",
    bullets: [
      "Comapny incorporation",
      "GST Registration",
      "DSC ceration",
      "PAN / TAN registration",
      "FSSAI registration",
    ],
  },
];
const partners = [
  {
    image: partnerImage1,
    name: "Shibaji Bakshi",
    bio:
      "Automate compilation of sales reports from various eCommerce platforms with high precision and making GST file ready in just 10 minutes.",
  },
  {
    image: partnerImage1,
    name: "Shibaji Bakshi",
    bio:
      "Automate compilation of sales reports from various eCommerce platforms with high precision and making GST file ready in just 10 minutes.",
  },
  {
    image: partnerImage1,
    name: "Shibaji Bakshi",
    bio:
      "Automate compilation of sales reports from various eCommerce platforms with high precision and making GST file ready in just 10 minutes.",
  },
];

let timerHandle: any;
export default function PremiumServices({ className, showSignUpModal }: any) {
  const [activeSlide, setActiveSlide] = useState(0);
  const [overrideAutoSlide, setOverrideAutoSlide] = useState(false);
  const getActiveSlide = () => activeSlide;
  useEffect(() => {
    if (!overrideAutoSlide) {
      timerHandle = setTimeout(() => {
        setActiveSlide((activeSlide + 1) % services.length);
      }, 4000);
    }
  }, [activeSlide]);

  return (
    <IMTMediaQueries>
      {(matches) => (
        <Row
          className={cx(className, styles.FeaturesBlock)}
          id="premium-services"
        >
          <Col
            flex={1}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            style={{ textAlign: "center" }}
          >
            <div className={styles.HeadLineFeaturesBlock}>
              Premium <span className={styles.primaryTextColor}>services</span>
            </div>
            <Space
              align="center"
              direction="horizontal"
              size={8}
              className={styles.justifyCenter}
              style={{ flexWrap: "wrap" }}
            >
              {services.map((val, idx) => {
                return (
                  <div
                    key={val.title}
                    className={`${styles.tabHeaderRounded} ${
                      activeSlide === idx ? styles.tabHeaderRoundedActive : ""
                    }`}
                    onMouseEnter={() => {
                      if (timerHandle) clearTimeout(timerHandle);
                      setOverrideAutoSlide(true);
                    }}
                    onClick={() => {
                      setActiveSlide(idx);
                    }}
                    onMouseOut={() => {
                      setOverrideAutoSlide(false);
                      setActiveSlide(activeSlide);
                      timerHandle = setTimeout(() => {
                        setActiveSlide((activeSlide + 1) % services.length);
                      }, 4000);
                    }}
                  >
                    {val.title}
                  </div>
                );
              })}
            </Space>
            <Row className={styles.useCasesRow}>
              <Col xs={0} xl={2} xxl={3} />
              <Col
                xl={9}
                xxl={10}
                lg={9}
                xs={24}
                /* style={{backgroundColor:'green'}} */
              >
                {
                  <div className={styles.useCasesTextWrapper}>
                    <div
                      className={`${styles.useCasesSubtitle} ${styles.standartHeading}`}
                    >
                      {services[activeSlide].Subtitle}
                    </div>
                    <div className={styles.useCasesBody}>
                      {services[activeSlide].body}
                    </div>
                    <div className={styles.useCasesBulletWrapper}>
                      {services[activeSlide].bullets.map((bul) => {
                        return (
                          <div
                            key={bul}
                            className={`${styles.useCasesBulletItem} ${styles.standartBodyText}`}
                          >
                            <span className={styles.useCasesBulletSpan}>
                              {" "}
                              [+]{" "}
                            </span>{" "}
                            {bul}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }
              </Col>
              <Col lg={2} xxl={1} /* style={{backgroundColor:'yellow'}} */ />
              <Col
                xl={9}
                lg={8}
                xs={24}
                /* style={{backgroundColor:'red'}} */
              >
                <div
                  className={styles.useCasesImageWrapper}
                  /* style={{ backgroundColor: "red" }} */
                >
                  <img src={premiumServicesIllus} alt="" />
                  {/* <IllustrationPremiumService /> */}
                  {/* <div className={styles.illustrationSvg}>
                     <IllustrationPremiumService />
                    </div> */}
                </div>
              </Col>
              <Col
                lg={2}
                xs={0}
                /* style={{backgroundColor:'purple'}} */
              />
            </Row>
            <Button
              type="primary"
              size="large"
              ghost
              href={googleFormForHumanAssistedServices}
              target="_blank"
              className={`${commonStyles["text-color-processing"]} ${commonStyles["border-color-processing"]}  ${styles.hoverTransparancy} ${styles.useCasesLetsConBtn}`}
            >
              Let's connect
            </Button>
            <div
              className={`${styles.premiumServicesAreYouCA} ${styles.standartBodyText} ${commonStyles["text-color-primary"]}`}
            >
              Are you a CA / CMA / Tax professional and looking to expanding
              your business ?
            </div>
            <Button
              type="primary"
              size="large"
              ghost
              href={googleFormForPartner}
              target="_blank"
              className={`${commonStyles["text-color-processing"]} ${commonStyles["border-color-processing"]}  ${styles.hoverTransparancy}`}
            >
              Be a partner in Cheqd
            </Button>
            {/* <Row style={{ marginTop: 30 }}>
                <Col span={24}>
                  <div>
                    <div className={styles.partnerHeader}>Partners</div>
                    <div className={styles.partnerItemWrap}>
                      {partners.map((prt: any) => (
                        <Col xs={24} md={12} lg={8} xl={8} xxl={5} className={styles.partnerItemWrapCol}>
                          <div className={styles.partnerItem}>
                            <div className={styles.partnerItemImage}>
                              <img src={prt.image} />
                            </div>
                            <div className={`${styles.partnerItemName} ${styles.standartHeading}`}>
                              {prt.name}
                            </div>
                            <div className={`${styles.partnerItemBio} ${styles.standartBodyText}`}>{prt.bio}</div>
                          </div>
                        </Col>
                      ))}
                    </div>
                    <Button
                      type="primary"
                      size="large"
                      target="_blank"
                      ghost
                      style={{ marginTop: 30 }}
                      className={`${commonStyles["text-color-processing"]} ${commonStyles["border-color-processing"]}  ${styles.hoverTransparancy}`}
                      href={googleFormForPartner}
                    >
                      Partner with Cheqd
                    </Button>
                  </div>
                </Col>
              </Row> */}
          </Col>
        </Row>
      )}
    </IMTMediaQueries>
  );
}
