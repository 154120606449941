import { Rule } from "antd/lib/form";

/**
 * Common form rules
 */

export const required: (fieldName: string) => Rule = (fieldName) => ({
  required: true,
  message: `${fieldName} is required`,
});
export const Required: (fieldName: string) => Array<Rule> = (fieldName) => [
  { required: true, message: `${fieldName} is required` },
];
