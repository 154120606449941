import React, { Component } from "react";
import {
  Modal,
  Input,
  Form,
  Radio,
  Checkbox,
  Select,
  Button,
  message as antMessage,
} from "antd";
import { FormInstance } from "antd/lib/form";
import { RuleObject } from "rc-field-form/lib/interface";
import { PAN_VALIDATION_REGEX } from "~/lib/constants";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

const { Option } = Select;

interface Props {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  selectedPreREQdata?: any;
  selectedDependency?: any;
  saveDynamicFrom: (data: Array<any>, idx: any, selectedGroup: any) => void;
  rowId?: any;
  selectedGroup: any;
  validationCheck: (data: boolean, idx: any, grouping_id: any) => void;
}
class DynamicFormModal extends Component<Props> {
  state = {};

  // tslint:disable-next-line: variable-name
  _formRef = React.createRef<FormInstance<any>>();

  handleCancel = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.dynamicFormModal24
    );

    this.props.onVisibleChange(false);
  };

  handleSubmit = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.dynamicFormModal25
    );

    const { rowId, selectedGroup } = this.props;
    try {
      const values = (await this._formRef.current!.validateFields()) as any;
      const finalSelection: Array<any> = [];

      this.props.selectedPreREQdata.forEach((value: any) => {
        finalSelection.push(
          Object.assign(value, { value: values[value.name] })
        );
      });

      this.props.onVisibleChange(false);
      this.props.validationCheck(true, rowId, selectedGroup.grouping_id);
      this.props.saveDynamicFrom(finalSelection, rowId, selectedGroup);
    } catch (error) {
      antMessage.error("All values not submitted");
      console.error("All values not submitted");
      this.props.validationCheck(false, rowId, selectedGroup.grouping_id);
    }
  };

  renderForm = () => {
    const { selectedPreREQdata, selectedDependency } = this.props;
    const data = selectedPreREQdata.map((values: any, index: any) => {
      const {
        datatype,
        defaultValue,
        id,
        required,
        name,
        valueJson,
        label,
      } = values;
      switch (datatype.name) {
        case "Radio":
          return (
            <Form.Item
              name={name}
              label={label}
              key={id}
              initialValue={
                selectedDependency.length
                  ? selectedDependency[index].value
                  : defaultValue
              }
              rules={[
                {
                  required,
                  message: "Please select relevant value",
                },
              ]}
            >
              <Radio.Group disabled={selectedDependency.length}>
                {(valueJson as Array<any>).map(
                  (radioValues: any, indexRadio: any) => (
                    <Radio key={indexRadio} value={radioValues}>
                      {radioValues}
                    </Radio>
                  )
                )}
              </Radio.Group>
            </Form.Item>
          );
        case "Textbox":
          return (
            <Form.Item
              name={name}
              label={label}
              key={id}
              initialValue={
                selectedDependency.length
                  ? selectedDependency[index]
                    ? selectedDependency[index].value
                    : ""
                  : ""
              }
              rules={[
                {
                  required,
                  validator: (
                    rule: RuleObject,
                    data: any,
                    callback: (error?: string) => void
                  ) => {
                    if (!data.length) {
                      return Promise.reject(
                        name === "ownersName"
                          ? "Please enter name of the owner"
                          : name === "panNumber"
                          ? `Please enter the PAN number of the owner`
                          : name === "orgPanNumber"
                          ? "Please enter the PAN number of the relief fund / charitable trust"
                          : `Please enter ${label}`
                      );
                    } else if (
                      (name === "orgPanNumber" || name === "panNumber") &&
                      !PAN_VALIDATION_REGEX.test(data)
                    ) {
                      return Promise.reject(`Please enter valid PAN`);
                    } else if (data.length) {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input
                autoComplete="off"
                disabled={selectedDependency.length}
                placeholder={defaultValue}
              />
            </Form.Item>
          );
        case "Checkbox":
          return (
            <Form.Item
              name={name}
              label={label}
              key={id}
              initialValue={
                selectedDependency.length
                  ? selectedDependency[index].value
                  : defaultValue
              }
              rules={[
                {
                  validator: (
                    rule: RuleObject,
                    checked: any,
                    callback: (error?: string) => void
                  ) => {
                    if (checked) {
                      return Promise.resolve();
                    }
                    return Promise.reject(`Please accept the ${label}`);
                  },
                },
              ]}
              valuePropName="checked"
            >
              <Checkbox disabled={selectedDependency.length}>
                <b> {label}</b>
              </Checkbox>
            </Form.Item>
          );
        case "Dropdown":
          return (
            <Form.Item
              name={name}
              label={label}
              key={id}
              initialValue={
                selectedDependency.length
                  ? selectedDependency[index].value
                  : defaultValue
              }
              rules={[
                {
                  required,
                  message:
                    "Please enter the PAN number of the relief fund / charitable trust",
                },
              ]}
            >
              <Select
                showArrow
                disabled={selectedDependency.length}
                // tslint:disable-next-line: jsx-no-lambda
                filterOption={(input?: string, option?: any) =>
                  option.extra.label
                    .toLowerCase()
                    .includes(input?.toLowerCase()) ||
                  (option.extra.gstin
                    ? option.extra.gstin
                        .toLowerCase()
                        .includes(input?.toLowerCase())
                    : "")
                }
              >
                {(valueJson as Array<any>).map((values: any) => (
                  <Option key={values} value={values}>
                    {values}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          );
      }
      return null;
    });
    return data;
  };

  render() {
    const { visible, selectedDependency } = this.props;
    return (
      <>
        <Modal
          maskClosable={false}
          title={"Fill up pre-requisite"}
          visible={visible}
          onCancel={this.handleCancel}
          footer={
            <>
              <Button color="red" onClick={this.handleCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={selectedDependency.length}
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </>
          }
          centered
        >
          <Form ref={this._formRef}>{this.renderForm()}</Form>
        </Modal>
      </>
    );
  }
}
export default DynamicFormModal;
