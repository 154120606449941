import React, { Component } from "react";
import { Modal, Input, notification, Row, Col, Form } from "antd";
import { fetchUtil } from "~/api/common";
import { FormInstance } from "antd/lib/form";
import commonStyle from "~/component/common.module.css";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

type FormValue = {
  first_name: string;
  last_name: string;
};

interface Props {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  updateNameInStore: (formValues: FormValue) => void;
}

interface State {
  formValue: FormValue;
  loadSave: boolean;
}

export default class FirstNameModal extends Component<Props, State> {
  state: State = {
    formValue: {
      first_name: "",
      last_name: "",
    },
    loadSave: false,
  };

  _formRef = React.createRef<FormInstance<Partial<FormValue>>>();

  handleCancel = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.firstNameModal26
    );

    this.props.onVisibleChange(false);
  };

  handleValuesChange = (
    changedValues: Partial<FormValue>,
    values: FormValue
  ) => {
    if (changedValues) {
      this.setState({
        formValue: values,
      });
    }
  };

  handleSave = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.firstNameModal27
    );

    const formVal = (await this._formRef.current?.validateFields()) as FormValue;
    //  console.log(formVal)
    if (formVal) {
      this.setState({
        loadSave: true,
      });
    }
    const { ok, message } = await fetchUtil(
      "POST",
      "/update_company_name",
      formVal
    );

    if (ok) {
      notification.success({
        message,
      });
      this.setState({
        loadSave: false,
      });
      this.props.onVisibleChange(false);
      this.props.updateNameInStore(formVal);
    } else {
      notification.error({
        message,
      });
      this.setState({
        loadSave: false,
      });
    }
  };

  render() {
    const { visible } = this.props;
    return (
      <>
        <Modal
          maskClosable={false}
          title={"Update your name"}
          visible={visible}
          // onCancel={this.handleCancel}
          cancelButtonProps={{ style: { display: "none" } }}
          onOk={this.handleSave}
          confirmLoading={this.state.loadSave}
          okText="Save"
          closable={false}
          destroyOnClose
          centered
        >
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <Form
                ref={this._formRef}
                onValuesChange={this.handleValuesChange}
                initialValues={this.state.formValue}
              >
                <Row gutter={{ md: 8, lg: 16 }}>
                  <Col sm={24} lg={16}>
                    <Form.Item
                      className={commonStyle["w-100"]}
                      name="first_name"
                      label="First name"
                      rules={[
                        { required: true, message: "Please enter First Name" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className={commonStyle["w-100"]}
                      name="last_name"
                      label="Last name"
                      rules={[
                        { required: true, message: "Please enter Last Name" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        </Modal>
      </>
    );
  }
}
