import React, { useState, useEffect } from "react";
import { Modal, Button, Select, Tooltip, Table } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import CustomSwitch from "./CustomSwitch";
import useWindowSize from "~/lib/hook/useWindowSize";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

const { Option } = Select;
interface SchemaObject {
  label: string;
  key: string;
  type: string;
  extra: any;
}

interface DynamicObject {
  [key: string]: any;
}

interface Props {
  onDownload: (
    acceptType: string,
    selectedFields: string
  ) => Promise<{
    ok: boolean;
    blob?: Blob | null;
    message: string;
  }>;
  onFetchData: () => Promise<{
    ok: boolean;
    data?: Array<any> | undefined;
    message: string;
    schema?: Array<SchemaObject>;
  }>;
  selectedList?: Array<string>;
}

type filetype = "pdf" | "csv" | "xlsx";

const ExportButton = (props: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileType, setFileType] = useState<filetype>("csv");
  const [selectedFields, setSelectedFields] = useState<Array<boolean>>([]);
  const [fileData, setFileData] = useState<Array<any>>([]);
  const [schema, setSchema] = useState<Array<SchemaObject>>([]);
  const windowSize = useWindowSize();

  const showModal = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.exportButton272
    );
    setIsModalVisible(true);
  };

  const fetchData = async () => {
    const { ok, data, schema, message } = await props.onFetchData();
    if (!ok) {
      console.error(message);
    } else {
      setSchema(schema ? schema.slice(1) : []);
    }
  };

  const createDataSource = (array: Array<any>) => {
    const fields: Array<DynamicObject> = [];

    const numberOfColumns =
      array.length % 7 !== 0
        ? Math.floor(array.length / 7) + 1
        : Math.floor(array.length / 7);
    for (let index = 0; index < array.length; index += numberOfColumns) {
      // array.map((item, index) => {
      let count = 0;
      const row: DynamicObject = {
        key: index,
      };
      while (count <= numberOfColumns) {
        // console.log("index = ", index, count);
        const fieldItem = array[index + count];
        const item =
          fieldItem !== undefined ? (
            <div key={fieldItem.key}>
              <CustomSwitch
                index={index + count}
                text={fieldItem.label}
                onToggle={handleToggle}
                selectedArray={selectedFields}
              />
            </div>
          ) : undefined;
        row[`col${count}`] = item;
        count++;
      }
      fields.push(row);
      // });
      // console.log(fields);
    }
    return { fields, numberOfColumns };
  };

  const printTable = () => {
    const { fields: dataSource, numberOfColumns } = createDataSource([
      ...schema,
    ]);
    const columnDef = [];
    for (let columnNo = 0; columnNo < numberOfColumns; columnNo++) {
      columnDef.push({
        title: "column",
        dataIndex: `col${columnNo}`,
        key: `col${columnNo}`,
      });
    }

    const normalStyle = { marginTop: "1rem" };
    const compactStyle = {
      marginTop: "1rem",
      width: "28rem",
    };

    return (
      <Table
        showHeader={false}
        scroll={numberOfColumns > 2 ? { x: numberOfColumns * 180 } : { x: 200 }}
        columns={columnDef}
        pagination={false}
        dataSource={dataSource}
        style={numberOfColumns > 2 ? normalStyle : compactStyle}
      />
    );
  };

  const placeExportButton = (windowWidth?: number) => {
    setTimeout(() => {
      const element = document.getElementsByClassName(
        "ant-pagination-item-link"
      )[0];
      if (element) {
        const { left, right, top, bottom } = element.getBoundingClientRect();
        const btn = document.getElementById("exportBtn");
        if (btn) {
          btn.style.position = "absolute";
          btn.style.left = left - 30 + "px";
          btn.style.top = top + "px";
          btn.style.margin = "0";
          btn.style.display = "inline";
        }
      }
    }, 1000);
  };

  // On initial load calling the API to get data and schema
  useEffect(() => {
    fetchData();
    placeExportButton(windowSize.width);
  }, [windowSize.width]);

  // After schema is loaded
  useEffect(() => {
    setSelectedFields(Array.from({ length: schema.length }, () => true));
    handleUpdate(props.selectedList);
  }, [schema]);

  const getSelectedFieldKeys = () => {
    let selectedFieldKeys = "";
    selectedFields.map((isSelected: boolean, index: number) => {
      if (isSelected) {
        selectedFieldKeys += `${schema[index].key},`;
      }
    });
    return selectedFieldKeys.slice(0, -1);
  };

  const handleOk = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.exportButton273
    );
    const selectedFieldKeys = getSelectedFieldKeys();
    let acceptType = "text/csv";
    switch (fileType) {
      case "csv":
        acceptType = "text/csv";
        break;
      case "pdf":
        acceptType = "application/pdf";
        break;
      case "xlsx":
        acceptType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
    }
    const { ok, message, blob } = await props.onDownload(
      acceptType,
      selectedFieldKeys
    );
    if (ok && blob) {
      // const ext = blob.type.split("/")[1];
      saveAs(blob, `${Math.floor(1000 + Math.random() * 9000)}.${fileType}`);
    }
    setIsModalVisible(false);
    handleUpdate(props.selectedList);
  };
  const handleUpdate = (selectedList?: Array<string>) => {
    if (selectedList) {
      for (const item of selectedList) {
        setSelectedFields((prevState) => {
          const updatedState = [...prevState];

          updatedState[
            schema.findIndex((prev: any) => prev.label === item)
          ] = false;

          return updatedState;
        });
      }
    }
  };
  const handleCancel = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.exportButton274
    );
    setIsModalVisible(false);
    handleUpdate(props.selectedList);
  };

  const handleFileTypeChange = (option: filetype) => {
    setFileType(option);
  };

  const handleToggle = (index: number, isChecked: boolean) => {
    const updatedState = [...selectedFields];
    updatedState[index] = isChecked;
    setSelectedFields(updatedState);
  };

  return (
    <>
      <Tooltip title="Export data">
        <div style={{ display: "none" }} id="exportBtn">
          <Button
            onClick={showModal}
            type="primary"
            icon={<DownloadOutlined />}
            size="small"
          />
        </div>
      </Tooltip>

      <Modal
        title="Export data"
        okText="Download"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        <div style={{ height: "60vh" }}>
          <Select
            defaultValue="csv"
            style={{ width: 80 }}
            onChange={handleFileTypeChange}
          >
            <Option value="csv">CSV</Option>
            <Option value="pdf">PDF</Option>
            <Option value="xlsx">XLSX</Option>
          </Select>
          {printTable()}
        </div>
      </Modal>
    </>
  );
};

export default ExportButton;
