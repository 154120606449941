import React from "react";

// import classes from './ProductColumnHeader.module.css';

interface Props {
  readOnly?: boolean;
  onAddProductRequest?: () => void;
}

const ProductColumnHeader = (props: Props) => {
  /**
   * TODO: move these inline styles to ./ProductColumnHeader.module.css
   */
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        minWidth: 140,
      }}
    >
      <span>
        <span style={{ color: "#EB5757" }}>*</span> Item
      </span>
      {!props.readOnly ? (
        <span
          style={{
            color: "#9B77E8",
            fontSize: "smaller",
            cursor: "pointer",
            fontWeight: "bold",
            marginTop: "3px",
          }}
          onClick={props.onAddProductRequest}
          role="button"
        >
          + Add new
        </span>
      ) : null}
    </div>
  );
};

export default ProductColumnHeader;
