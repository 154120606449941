import React, { PureComponent } from "react";

import { Button, Menu, Typography, Dropdown, Select } from "antd";
import { fetchUtil } from "~/api/common";
import TALLY_ICON from "~/assets/Tally-Erp-9-with-GST-Logo.png";
import styles from "./Iconstyle.module.css";
import TallySync from "./TallySync";
import { registerEvent } from "~/analytics";
import * as ga from "~/contants/gaConstants";
import SyncYearMonthDropdown from "~/fragment/year-month-dropdown/SyncYearMonthDropdown";
import { syncMonths } from "~/lib/constants";
interface Props {
  handleBackPress: () => void;
  handleStartSync: (type?: any, month?: any, year?: any) => void;
  talyStart?: boolean;
  closeModal: () => void;
  hideStartSyncFromSourceModal: () => void;
  setTallyBackSyncSelectModal: (opts: any) => void;
  setTallyBackSyncSelectModalNew: (opts: any) => void;
  viewSyncModal?: (title: string, logo: string, style: string) => void;
}

interface State {
  showStartSyncButon: boolean | undefined;
  erroMsg: string;
  openBackwardSyncModal: boolean;
  confirmModal: boolean;
  selectedMonth: number;
  type: string;
  newMonth: any;
  newYear: any;
}

export default class TallyStart extends PureComponent<Props, State> {
  state: State = {
    showStartSyncButon: undefined,
    erroMsg: "",
    openBackwardSyncModal: false,
    confirmModal: false,
    selectedMonth: -1,
    type: "Full",
    newMonth: "",
    newYear: "",
  };

  // latestInterval: any = null;

  componentDidMount() {
    this.tallyAuth();
  }

  tallyAuth = async () => {
    const { ok, message } = await fetchUtil("POST", "/tally_auth_check", {});
    if (!ok) {
      // console.log(json);
      this.setState({
        erroMsg: message,
      });
      // this.props.handleStartSync();
    } else {
      this.setState({
        showStartSyncButon: true,
      });
      // this.props.handleStartSync();
    }
  };

  handleTallyStartSync = async (type?: any, month?: any, year?: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.tallyStart247
    );
    await this.props.handleStartSync(type, month, year);
  };

  goback = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.tallyStart248
    );
    this.props.handleBackPress();
  };

  handleBackwardSyncStart = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.tallyStart249
    );

    // this.props.showSyncStatusModal();
    this.props.hideStartSyncFromSourceModal();
    this.props.setTallyBackSyncSelectModal({ isVisible: true });
    // this.setState({
    //   openBackwardSyncModal: true,
    // });
  };

  handleBackwardSyncClose = () => {
    this.props.closeModal();
    // this.setState({
    //   openBackwardSyncModal: false,
    // });onClick={this.handleBackwardSyncStart}
  };
  handleOpen = () => {
    this.props.hideStartSyncFromSourceModal();
    this.props.setTallyBackSyncSelectModalNew({ isVisible: true });
  };
  handleClose = () => {
    this.setState({ confirmModal: false });
  };
  handleChange = (value: any) => {
    this.setState({ type: value });
  };
  changeHandle = (month: any, year: any) => {
    this.setState({ newMonth: syncMonths[month], newYear: year });
    return true;
  };

  render() {
    return (
      <>
        <div style={{ textAlign: "center" }}>
          {this.state.showStartSyncButon ? (
            <>
              <div>
                <img
                  alt="Tally logo"
                  src={TALLY_ICON}
                  className={styles.talyIcon}
                />
                <Typography.Title
                  level={4}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <b className={styles.sourceheading}>Tally</b>
                </Typography.Title>
              </div>

              <div className={styles.start}>
                {this.state.showStartSyncButon ? (
                  <>
                    <p style={{ color: "#5A5A5A", fontWeight: 600 }}>
                      {" "}
                      Select import period
                    </p>
                    <SyncYearMonthDropdown
                      month={syncMonths.indexOf(this.state.newMonth)}
                      year={this.state.newYear}
                      marginLeft={"0px"}
                      payrollFlag={false}
                      onChange={this.changeHandle}
                    />
                    <p style={{ color: "#5A5A5A", fontWeight: 600 }}>
                      {" "}
                      Master data import value
                    </p>
                    <Select
                      suffixIcon={
                        <svg
                          width="10"
                          height="8"
                          viewBox="0 0 20 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 16L19.5263 0.25H0.473721L10 16Z"
                            fill="#4F4F4F"
                          />
                        </svg>
                      }
                      style={{
                        // marginRight: "28em",
                        // marginTop: "30px",
                        width: "100px",
                        color: "black",
                        fontWeight: 700,
                      }}
                      value={this.state.type}
                      onChange={this.handleChange}
                      disabled
                    >
                      <Select.Option key="Full" value="Full">
                        Full
                      </Select.Option>
                      <Select.Option key="Delta" value="Delta">
                        Delta
                      </Select.Option>
                    </Select>
                    <p style={{ color: "#2f80ed", fontWeight: 600 }}>
                      Selecting "Full" will import the whole set of master data
                      which includes Ledgers, Stocks, Stock groups, employees
                      etc.
                    </p>
                  </>
                ) : null}
              </div>
              <div className={styles.back}>
                <Button
                  type="text"
                  onClick={this.goback}
                  disabled={this.props.talyStart}
                >
                  Back
                </Button>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    type="default"
                    disabled={this.props.talyStart}
                    onClick={this.handleOpen}
                    className={styles.sync_back}
                  >
                    Sync back
                  </Button>

                  <Button
                    type="primary"
                    loading={this.props.talyStart}
                    onClick={() => {
                      this.handleTallyStartSync(
                        this.state.type,
                        this.state.newMonth,
                        this.state.newYear
                      );
                    }}
                  >
                    Import
                  </Button>
                </div>
              </div>
            </>
          ) : this.state.erroMsg ? (
            <TallySync
              erroMsg={this.state.erroMsg}
              handleBackPress={this.props.handleBackPress}
              closeModal={this.props.closeModal}
            />
          ) : (
            <>
              <img
                alt="Tally logo"
                src={TALLY_ICON}
                className={styles.talyIcon}
              />
              <div className={styles.cheking}>
                Checking <br /> Connectivity with Tally.....
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
