import React, { Component } from "react";
import {
  Modal,
  Table,
  Upload,
  Button,
  Tooltip,
  notification,
  Checkbox,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { LoadingOutlined } from "@ant-design/icons";
import Currency from "~/component/Currency";
import FieldInfo from "~/component/FieldInfo";
import SubHeader from "~/component/SubHeader";
import { fetchUtil, fetchDataWithFormData } from "~/api/common";
import { formatDate } from "~/lib";
import { months } from "~/lib/constants";
import DOCUMENT_UPLOAD from "~/assets/file.png";
import styles from "./styles.module.css";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

const { Column, ColumnGroup } = Table;
const strings = {
  imageSizeError: "Image must smaller than 2MB!",
  NODATA: "No Match Found",
  uploadBankStateMent: "Upload bank statement for",
  cancel: "Cancel",
  accept: "Accept",
  subheaderRowSelection: "Please select the matching rows and accept.",
  expenses: "Expense",
  invoices: "Invoice",
  bankStatements: "Bank Statements",
  reconcileModalHeader: "Reconcile with bank statement",
  idDate: "ID/Date",
  vendorDescription: "Vendor/Description",
  amount: "Amount",
  finalAmount: "Final Amount",
  transactionRef: "Transaction Ref",
};

interface Props {
  curMonth: any;
  curYear: any;
  refreshData: (isSubmit: boolean) => void;
  document: string;
  selectedFy: string;
}

interface State {
  reconcileOriginalData: Array<any>;
  reconciliationModal: boolean;
  reconcileData: Array<any>;
  reconcileSubmitData: Array<any>;
  totalCount: number;
  uploading: boolean;
}

class ReconcileFileUpload extends Component<Props, State> {
  state: State = {
    reconcileOriginalData: [],
    reconciliationModal: false,
    reconcileData: [],
    reconcileSubmitData: [],
    totalCount: 0,
    uploading: false,
  };

  uploadReconcile = (formBody: FormData) => {
    return fetchDataWithFormData(
      "/bank_stmt_reconciliation/reconcile",
      formBody
    );
  };

  beforeUpload(file: File) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({ message: strings.imageSizeError });
    }
    return isLt2M;
  }

  handleAction = async (file: RcFile): Promise<string> => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.Reconcile320
    );
    this.setState({ uploading: true });
    const data: any = [];
    const submitData: any = [];
    const formData = new FormData();
    const { curMonth, curYear, document } = this.props;
    const start_Date = `${curYear}-${
      curMonth + 1 < 10 ? "0" + (curMonth + 1) : curMonth + 1
    }-01`;
    const daysInMonth = new Date(curYear, curMonth + 1, 0).getDate();

    const end_Date = `${curYear}-${
      curMonth + 1 < 10 ? "0" + (curMonth + 1) : curMonth + 1
    }-${daysInMonth}`;
    const splitStartDate = start_Date.split("-");
    const splitFy = this.props.selectedFy?.split("-");
    formData.append("file_name", file);
    formData.append(
      "startDate",
      splitStartDate[1] === "13" ? `${splitFy[0]}-04-01` : start_Date
    );
    formData.append(
      "endDate",
      splitStartDate[1] === "13" ? `${splitFy[1]}-03-31` : end_Date
    );
    formData.append("documentType", document);
    const { ok, message, result } = await this.uploadReconcile(formData);
    this.setState({ uploading: false });
    if (ok) {
      if (result.result.length > 0) {
        this.setState({ reconcileOriginalData: result.result });
        result.result.forEach((value: any, index: any) => {
          const { src_row, trx_row } = value;
          if (trx_row.length > 0) {
            submitData.push(src_row[0].id);
            if (src_row.length > 1) {
              const tds = src_row.map((val: any, index: number) => {
                return `${val.tds_rate}%`;
              });
              data.push({
                key: index,
                tds: `${tds}`,
                srcRow: src_row,
                trxRow: trx_row,
                srcCount: src_row.length,
                trcCount: trx_row.length,
              });
            } else if (src_row.length === 1) {
              data.push({
                key: index,
                tds: `${src_row[0].tds_rate}%`,
                srcRow: src_row,
                trxRow: trx_row,
                srcCount: src_row.length,
                trcCount: trx_row.length,
              });
            }
          }
        });
        if (data.length > 0) {
          this.setState({
            reconcileData: data,
            reconciliationModal: true,
            reconcileSubmitData: [],
            totalCount: submitData.length,
          });
        } else {
          notification.info({ message: strings.NODATA });
        }
      } else {
        notification.info({ message: strings.NODATA });
      }
    } else {
      notification.error({ message });
    }
    return "";
  };

  reconcileCheck = (e: any, row: any) => {
    const { reconcileSubmitData } = this.state;
    if (e.target.checked) {
      reconcileSubmitData.push(row.srcRow[0].id);
    } else {
      const index = reconcileSubmitData.indexOf(row.srcRow[0].id);

      if (index > -1) {
        reconcileSubmitData.splice(index, 1);
      }
    }
    this.setState({ reconcileSubmitData });
  };

  submitReconcileData = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.Reconcile321
    );
    const { reconcileSubmitData, reconcileOriginalData } = this.state;
    if (reconcileSubmitData.length > 0) {
      const finalData: any = [];
      const { document } = this.props;
      reconcileOriginalData.forEach((value) => {
        const { src_row, trx_row } = value;
        // if (src_row.length > 1) {
        if (reconcileSubmitData.indexOf(src_row[0].id) !== -1) {
          src_row.forEach((src_element: any) => {
            trx_row.forEach((trx_element: any) => {
              finalData.push({
                [document === "INVOICE"
                  ? "invoice_id"
                  : "expense_id"]: src_element.id,
                taxable_amount: src_element.converted_amount,
                final_amount: src_element.final_amt,
                tds_rate: src_element.tds_rate,
                payment_date: trx_element.date.substring(0, 10),
                transaction_id: Array.prototype.join.call(
                  trx_element.particulars,
                  "/"
                ),
                installment_amount:
                  src_element.final_amt -
                  (src_element.tds_rate * src_element.converted_amount) / 100,
              });
            });
          });
        }
      });
      const { ok, message } = await fetchUtil(
        "POST",
        document === "INVOICE"
          ? "/update_invoice/receive_invoice_payment"
          : "/update_expense/accept_expense",
        { payment_array: finalData }
      );
      if (ok) {
        this.props.refreshData(true);
        notification.success({ message });
      }
    } else {
      this.props.refreshData(false);
    }
    this.setState({
      reconciliationModal: false,
      reconcileData: [],
      reconcileSubmitData: [],
    });
  };

  render() {
    const {
      reconciliationModal,
      reconcileData,
      reconcileSubmitData,
      totalCount,
      uploading,
    } = this.state;
    const { curMonth, curYear } = this.props;
    return (
      <>
        <Upload
          accept=".csv"
          showUploadList={false}
          beforeUpload={this.beforeUpload}
          action={this.handleAction}
        >
          <Button
            // type="primary"
            title={`Upload bank statement for ${months[curMonth]}' ${curYear}`}
            className={styles.height_auto}
            // icon={
            //   uploading ? (
            //     <LoadingOutlined />
            //   ) : (
            //     <img
            //       className={styles.upload_image}
            //       src={DOCUMENT_UPLOAD}
            //       alt="reconcile_upload"
            //     />
            //   )
            // }
          >
            <div className={styles.upload_container}>
              {/* <p>
                Upload bank statement for {months[curMonth]}' {curYear}
              </p> */}
              Reconcile
            </div>
          </Button>
        </Upload>
        <Modal
          visible={reconciliationModal}
          title={strings.reconcileModalHeader}
          maskClosable={false}
          width="80%"
          footer={
            <div className={styles.reconcile_table_footer}>
              <p className={styles.reconcile_table_footer_text}>
                {reconcileSubmitData.length} out of {totalCount} selected
              </p>
              <Button
                // tslint:disable-next-line: jsx-no-lambda
                onClick={() => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    ga.events.Reconcile322
                  );

                  this.setState({
                    reconciliationModal: false,
                    reconcileData: [],
                    reconcileSubmitData: [],
                  });
                }}
              >
                {strings.cancel}
              </Button>
              <Button
                type="primary"
                disabled={reconcileSubmitData.length === 0}
                // tslint:disable-next-line: jsx-no-lambda
                onClick={() => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    ga.events.Reconcile323
                  );

                  this.submitReconcileData();
                }}
              >
                {strings.accept}
              </Button>
            </div>
          }
          // tslint:disable-next-line: jsx-no-lambda
          onCancel={() =>
            this.setState({
              reconciliationModal: false,
              reconcileData: [],
              reconcileSubmitData: [],
            })
          }
        >
          <SubHeader text={strings.subheaderRowSelection} />
          <Table
            id="reconcile_table"
            className={styles.reconcile_table}
            dataSource={reconcileData}
            scroll={{ x: 800, y: 300 }}
            pagination={false}
            // tslint:disable-next-line: jsx-no-lambda
            rowClassName={(record) => {
              return reconcileSubmitData.indexOf(record.srcRow[0].id) > -1
                ? "red"
                : "green";
            }}
          >
            <ColumnGroup
              title={
                <p
                  className={styles.reconcile_column_group_header}
                  style={{ color: "var(--processing-color)" }}
                >
                  {this.props.document === "INVOICE"
                    ? strings.invoices
                    : strings.expenses}
                </p>
              }
              align="left"
            >
              <Column
                title={strings.idDate}
                fixed="left"
                align="center"
                className={styles.header_font_size}
                // tslint:disable-next-line: jsx-no-lambda
                render={(_val, row: any) => {
                  const obj = {
                    children: (
                      <table className={styles.reconcile_sub_table}>
                        {row.srcRow.map((element: any, i: any) => (
                          <tr key={i}>
                            {i === 0 ? (
                              <td
                                rowSpan={row.srcRow.length}
                                className={styles.sub_table_checkbox}
                              >
                                <Checkbox
                                  defaultChecked={false}
                                  // tslint:disable-next-line: jsx-no-lambda
                                  onChange={(e) => this.reconcileCheck(e, row)}
                                />
                              </td>
                            ) : null}
                            <td
                              className={styles.sub_table_data_id_date}
                              style={{
                                borderBottomWidth:
                                  row.srcCount > 1 && i < row.srcCount - 1
                                    ? 1
                                    : 0,
                              }}
                            >
                              <div className={styles.id_date_container}>
                                <p className={styles.header_font_size}>
                                  {element.id}
                                </p>
                                <FieldInfo
                                  text={formatDate(
                                    new Date(element.receipt_date)
                                  )}
                                />
                              </div>
                            </td>
                            <td
                              className={styles.sub_table_data}
                              style={{
                                borderBottomWidth:
                                  row.srcCount > 1 && i < row.srcCount - 1
                                    ? 1
                                    : 0,
                              }}
                            >
                              <Tooltip title={element.name}>
                                <p className={styles.reconcile_popup_text}>
                                  {element.name}
                                </p>
                              </Tooltip>
                              <Tooltip title={element.description}>
                                <p
                                  className={`${styles.reconcile_popup_text}  ${styles.description}`}
                                >
                                  {element.description}
                                </p>
                              </Tooltip>
                            </td>
                            <td
                              className={`${styles.sub_table_data} ${styles.amount}`}
                              style={{
                                borderBottomWidth:
                                  row.srcCount > 1 && i < row.srcCount - 1
                                    ? 1
                                    : 0,
                              }}
                            >
                              <Currency currencySymbol={"₹"}>
                                {element.final_amt}
                              </Currency>
                            </td>
                          </tr>
                        ))}
                      </table>
                    ),
                    props: { colSpan: 3 },
                  };
                  return obj;
                }}
              />
              <Column
                title={strings.vendorDescription}
                align="left"
                fixed="left"
                className={styles.header_font_size}
                // tslint:disable-next-line: jsx-no-lambda
                render={(_val) => {
                  const obj = {
                    children: "",
                    props: { colSpan: 0 },
                  };
                  return obj;
                }}
              />
              <Column
                title={strings.amount}
                align="right"
                fixed="left"
                className={styles.header_font_size}
                ellipsis={true}
                // tslint:disable-next-line: jsx-no-lambda
                render={() => {
                  const obj = {
                    children: "",
                    props: { colSpan: 0 },
                  };
                  return obj;
                }}
              />
            </ColumnGroup>
            <ColumnGroup
              title={
                <p
                  className={styles.reconcile_column_group_header}
                  style={{ color: "var(--success-color)" }}
                >
                  {strings.bankStatements}
                </p>
              }
              align="left"
            >
              <Column
                title={strings.transactionRef}
                className={styles.header_font_size}
                align="left"
                // tslint:disable-next-line: jsx-no-lambda
                render={(_val, row: any) => {
                  const obj = {
                    children: (
                      <table className={styles.reconcile_sub_table}>
                        {row.trxRow.map((element: any, i: any) => (
                          <tr key={i}>
                            <td
                              className={styles.sub_table_data}
                              style={{
                                borderBottomWidth:
                                  row.trcCount > 1 && i < row.trcCount - 1
                                    ? 1
                                    : 0,
                              }}
                            >
                              <Tooltip
                                title={Array.prototype.join.call(
                                  element.particulars,
                                  "/"
                                )}
                              >
                                <p className={styles.reconcile_popup_text}>
                                  {Array.prototype.join.call(
                                    element.particulars,
                                    "/"
                                  )}
                                </p>
                              </Tooltip>
                            </td>
                            <td
                              className={styles.sub_table_data}
                              style={{
                                borderBottomWidth:
                                  row.trcCount > 1 && i < row.trcCount - 1
                                    ? 1
                                    : 0,
                              }}
                            >
                              {formatDate(new Date(element.date))}
                            </td>
                            <td
                              className={`${styles.sub_table_data} ${styles.amount}`}
                              style={{
                                borderBottomWidth:
                                  row.trcCount > 1 && i < row.trcCount - 1
                                    ? 1
                                    : 0,
                              }}
                            >
                              <Currency currencySymbol={"₹"}>
                                {element.amount}
                              </Currency>
                              <div className={styles.tds_container}>
                                <FieldInfo text={`Tds: ${row.tds}`} />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </table>
                    ),
                    props: { colSpan: 3 },
                  };
                  return obj;
                }}
              />
              <Column
                title="Date"
                align="center"
                className={styles.header_font_size}
                // tslint:disable-next-line: jsx-no-lambda
                render={(val) => {
                  const obj = { children: val, props: { colSpan: 0 } };
                  return obj;
                }}
              />
              <Column
                title={strings.finalAmount}
                align="right"
                className={styles.header_font_size}
                // tslint:disable-next-line: jsx-no-lambda
                render={(val) => {
                  const obj = {
                    children: val ? (
                      <Currency currencySymbol={"₹"}>{val}</Currency>
                    ) : null,
                    props: { colSpan: 0 },
                  };
                  return obj;
                }}
              />
            </ColumnGroup>
          </Table>
        </Modal>
      </>
    );
  }
}

export default ReconcileFileUpload;
