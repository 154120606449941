import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Space, Tooltip } from "antd";
import { LineItemError } from "./LineItemTypes";

import classes from "./LineItem.module.css";

interface Props {
  isLastRow: boolean;
  readOnly?: boolean;
  onAddNew: (e?: React.MouseEvent<HTMLElement>) => void;
  errors: Array<LineItemError>;
}

/**
 * Component used in Line Item table's Column
 */
const LICLeftButton = ({ isLastRow, errors, readOnly, onAddNew }: Props) => {
  const validationInfo =
    (errors?.length ?? 0) === 0 ? null : (
      <Tooltip
        title={
          <ul className={classes.errorList}>
            {errors.map(({ dataIndex, message }) => (
              <li key={dataIndex}>{message}</li>
            ))}
          </ul>
        }
      >
        {/* <InfoCircleOutlined className={classes.errorIcon} /> */}
      </Tooltip>
    );

  return (
    <Space size="small" align="end">
      {isLastRow && (
        <Button
          aria-label="Add lineitem"
          size="small"
          icon={<PlusOutlined style={{ color: "var(--success-color)" }} />}
          disabled={readOnly}
          onClick={onAddNew}
        />
      )}
      {validationInfo}
    </Space>
  );
};

export default LICLeftButton;
