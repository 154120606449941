import React, { PureComponent } from "react";
import { Button, Typography, notification } from "antd";
import VanillaButton from "~/component/VanillaButton";
import VideoDrawer from "~/component/VideoDrawer";
import { connect, ConnectedProps } from "react-redux";
import TALLY_ICON from "~/assets/Tally-Erp-9-with-GST-Logo.png";
import styles from "./Iconstyle.module.css";
import TallyStart from "./TallyStart";
import { registerEvent } from "~/analytics";
import { getSyncConfig } from "~/api/synchronize";
import { fetchUtil } from "~/api/common";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";

import * as ga from "~/contants/gaConstants";

interface Props {
  handleBackPress?: () => void;
  erroMsg?: string;
  closeModal: () => void;
  viewSyncModal?: (title: string, logo: string, style: string) => void;
  source?: any;
}

interface State {
  showTallyStart: boolean;
  openVideoPlayer: boolean;
  tallyConnectorDownloadLink: string;
  isTallyStarted: boolean;
  syncStatusList?: any;
  processStatus?: any;
  iconchoser: string;
}

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  userId: state.mainAppSlice.user.id,
  companyName: state.mainAppSlice.user.company_name,
  saveEmpDetails: state.mainAppSlice.user.save_emp_details,
  showSyncButtonTour: state.mainAppSlice.showSyncButtonTour,
  employeeName:
    state.mainAppSlice.user.first_name +
    " " +
    state.mainAppSlice.user.last_name,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  showSyncStatusModal: () => dispatch(action.modal.showSyncStatusModal()),
  hideStartSyncFromSourceModal: () =>
    dispatch(action.modal.hideStartSyncFromSourceModal()),
  setTallyBackSyncSelectModal: (opts: any) =>
    dispatch(action.modal.setTallyBackSyncSelectModal(opts)),
  setTallyBackSyncSelectModalNew: (opts: any) =>
    dispatch(action.modal.setTallyBackSyncSelectModalNew(opts)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
// tslint:disable-next-line
interface Props extends PropsFromRedux {}

class TallySync extends PureComponent<Props, State> {
  state: State = {
    showTallyStart: false,
    openVideoPlayer: false,
    tallyConnectorDownloadLink: "",
    isTallyStarted: false,
    syncStatusList: [],
    processStatus: {},
    iconchoser: "",
  };

  async componentDidMount() {
    await this.getTallyConnectorDownloadLink();
  }

  getTallyConnectorDownloadLink = async () => {
    const { ok, data } = await getSyncConfig();
    if (ok && data)
      this.setState({
        tallyConnectorDownloadLink: data[0].tally_connector_download_link,
      });
  };

  tallyStartCall = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.tallySync250
    );
    this.setState({
      showTallyStart: true,
    });
  };

  goback = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.tallySync251
    );
    this.props.handleBackPress!();
  };

  getTallyStatus = async () => {
    // Getting the sync status.
    try {
      const { ok, json, message } = await fetchUtil("GET", "/get_sync_status");
      if (!ok) {
        notification.error({ message });
        return { ok, message };
      } else {
        if (json.length > 0) {
          this.setState({
            syncStatusList: json[0].process_steps,
            processStatus: json[0].process_status,
            iconchoser: json[0].source_name,
          });
        } else {
          this.setState({ syncStatusList: [], processStatus: null });
        }
      }
    } catch (error) {
      notification.error({ message: (error as any).message });
      // if (this.props.stopLoadingSyncNowButton) {
      //   this.props.stopLoadingSyncNowButton!();
      // }
    }
  };

  handleBack = () => {
    this.setState({
      showTallyStart: false,
    });
  };

  startTallySync = async (
    type?: string,
    monthNew?: string,
    yearNew?: string
  ) => {
    this.setState({
      isTallyStarted: true,
    });
    let ud: any = {};
    const userData: any = localStorage.getItem("imt__user");
    if (userData !== null) {
      ud = JSON.parse(userData);
    }
    try {
      const { ok } = await fetchUtil("POST", "/tally_start_sync", {
        import_volume: type,
        month: monthNew,
        financial_year: yearNew,
      });
      if (ok) {
        ud.first_sync = true;
        this.getTallyStatus();
        localStorage.setItem("imt__user", JSON.stringify(ud));
        this.setState({
          isTallyStarted: false,
        });
        this.props.showSyncStatusModal();
        this.props.hideStartSyncFromSourceModal();
      } else {
        notification.error({ message: "Failed to start sync" });
        this.setState({
          isTallyStarted: false,
        });
      }
    } catch (error) {
      notification.error({ message: (error as any).message });
      // if (this.props.stopLoadingSyncNowButton) {
      //   this.props.stopLoadingSyncNowButton!();
      // }
    }
  };

  render() {
    return (
      <>
        <div style={{ textAlign: "center" }}>
          {!this.state.showTallyStart ? (
            <>
              <img
                src={TALLY_ICON}
                alt=""
                className={styles.talyIcon}
                // onClick={this.handleTallyIConClick}
              />
              <Typography.Title
                level={4}
                style={{
                  textAlign: "center",
                }}
              >
                <b className={styles.sourceheading}>Tally (experimental)</b>
              </Typography.Title>
              <div className={styles.textdiv}>
                {this.props.erroMsg ? (
                  <div className={styles.errordiv}>
                    {/* Error: not able to reach Tally installation
                     */}
                    {this.props.erroMsg}
                  </div>
                ) : null}
                <VideoDrawer
                  url="https://youtu.be/h02Q_0fXcBw"
                  title="Install and start tally server"
                  // tslint:disable-next-line: jsx-no-lambda
                  onClose={() => this.setState({ openVideoPlayer: false })}
                  openDrawer={this.state.openVideoPlayer}
                />
                <Typography.Title
                  level={4}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <b>Please make sure that</b>
                  <br /> Tally connector ({" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.tallyConnectorDownloadLink}
                  >
                    {"download here"}
                  </a>{" "}
                  ) is <br /> installed <b>and</b> Tally is Running
                  <br />
                  <br />
                  For setup instructions{" "}
                  <VanillaButton
                    text="See video"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => this.setState({ openVideoPlayer: true })}
                  />
                </Typography.Title>
              </div>
              <div className={styles.buttondiv}>
                <Button
                  style={{ width: "156px" }}
                  type="primary"
                  onClick={this.tallyStartCall}
                >
                  {this.props.erroMsg ? "Retry" : "Proceed"}
                </Button>
              </div>
              <div className={styles.Tallyback}>
                <Button
                  type="text"
                  onClick={this.goback}
                  disabled={this.state.isTallyStarted}
                >
                  Back
                </Button>
              </div>
            </>
          ) : (
            <TallyStart
              handleBackPress={this.handleBack}
              handleStartSync={this.startTallySync}
              talyStart={this.state.isTallyStarted}
              closeModal={this.props.closeModal}
              viewSyncModal={this.props.viewSyncModal}
              hideStartSyncFromSourceModal={
                this.props.hideStartSyncFromSourceModal
              }
              setTallyBackSyncSelectModal={
                this.props.setTallyBackSyncSelectModal
              }
              setTallyBackSyncSelectModalNew={
                this.props.setTallyBackSyncSelectModalNew
              }
            />
          )}
        </div>
      </>
    );
  }
}

export default connector(TallySync);
