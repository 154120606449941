import React, { Component } from "react";
import styles from "./styles.module.css";
import UNAUTHORIZED_LOGO from "~/assets/shield.svg";
import { Button } from "antd";
import { RouteComponentProps } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { AuthState } from "~/app/MainApp/store";
import { LoginRole } from "~/api/auth";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

// tslint:disable-next-line: no-empty-interface
interface State {}

const mapStateToProps = (state: AuthState) => {
  return {
    activeRole: state.mainAppSlice.user.activeRole,
  };
};
const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

// tslint:disable-next-line: no-empty-interface
interface Props extends RouteComponentProps, PropsFromRedux {}

class Unauthorized extends Component<Props, State> {
  state: State = {};

  handleHomeBtn = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.homepageBtn
    );
    const { activeRole } = this.props;
    const isAdminOrSuper =
      activeRole === LoginRole.ADMIN || activeRole === LoginRole.SUPERuSER;
    if (isAdminOrSuper) {
      this.props.history.push("/app/dashboard");
    } else {
      this.props.history.push("/app/employee/me");
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <Helmet>
          <title>{titles.Unauthorized}</title>
        </Helmet>
        <div className={styles.middle_container}>
          <img
            alt="Gstin logo"
            src={UNAUTHORIZED_LOGO}
            className={styles.unauthorized_image}
            // onClick={this.handleGSTIConClick}
          />
          <h1 className={styles.unauthorized_text}>
            You might not have access to this page. Try switching to other role.
          </h1>
          <Button
            style={{ background: "var(--processing-color)", color: "white" }}
            onClick={this.handleHomeBtn}
          >
            Home page
          </Button>
        </div>
      </div>
    );
  }
}

export default Unauthorized;
