import React from "react";
import { Row, Col } from "antd";
import styles from "./LandingPageLayout.module.css";
import IMTMediaQueries from "~/component/IMTMediaQueries";
import cx from "classnames";
import facebookIcon from "~/assets/social-icons/facebook.png";
import youtubIcon from "~/assets/social-icons/youtube.png";
import linkedinIcon from "~/assets/social-icons/linkedin.png";
import Incubate from "./Incubate";
const tncLink =
  "https://docs.google.com/document/d/e/2PACX-1vTqMnlIdzsKm4edgXfUTIjlIssvYCpVkQ5ov-qS8T7pIWSnL2i3vntSeDGd3Zrg1g/pub";
const privPolicyLink =
  "https://docs.google.com/document/d/e/2PACX-1vRene3LPKf1b-egsY3XUvrmhEvDxATzhlPNaygQaZqnoSGIYEzZfJzAlJSbw2OH6w/pub";
const googleFormContactUs =
  "https://docs.google.com/forms/d/e/1FAIpQLSeyWDRwNfc2IqkOCGJ10qNAss3VwCVJIyrLYJ8WDms1hnuFQg/viewform?vc=0&c=0&w=1&flr=0";
/* const aboutUsLink = "https://google.com";
const hiringForm = "https://google.com"; */

const scrollToView = (id: string, gaEvent?: string) => () => {
  const anchor: any = document.querySelector(id);
  anchor.scrollIntoView({ behavior: "smooth", block: "start" });
};

export default function Footer({ className, showSignupModal }: any) {
  return (
    <IMTMediaQueries>
      {(matches) => (
        <>
          <Incubate />
          <Row
            className={cx(className)}
            style={{ paddingTop: "30px", paddingBottom: "30px" }}
            justify="center"
            id="footer"
          >
            <Col xs={24} md={6}>
              <div className={styles.footerColumn1}>
                <img
                  src="logo-white@1x.png"
                  className={styles.footerLogo}
                  alt=""
                />
                <div>
                  Cheqd helps small businesses manage day-to-day business
                  operations & compliances centrally. Compliances are
                  auto-drafted from various data sources - e.g. accounting
                  platforms, HRMS systems, Government portals etc. Cheqd is
                  equipped with minimal accounting, HRMS and other ERP features
                  so that small businesses can run their business without
                  needing any other costly ERP or fragmented platforms.
                </div>
                <div>
                  <a
                    style={{ color: "#f6ebff" }}
                    target="_blank"
                    rel="noreferrer"
                    href={privPolicyLink}
                  >
                    Privacy policy
                  </a>{" "}
                  |{" "}
                  <a
                    style={{ color: "#f6ebff" }}
                    target="_blank"
                    rel="noreferrer"
                    href={tncLink}
                  >
                    Terms of use
                  </a>
                </div>
              </div>
            </Col>
            <Col md={2} xs={0} />
            <Col xs={24} md={4}>
              <div className={styles.footerColumn2}>
                {[
                  ["Home", "#home"],
                  ["Features", "#features"],
                  ["Premium services", "#premium-services"],
                  /* ["Partners", "#partners"], */
                  ["Pricing", "#pricing"],
                  ["Team", "https://www.thetaonelab.com/team.html", true],
                  ["Contact us", googleFormContactUs, true],
                ].map((link: Array<any>) => {
                  return (
                    <div key={link[0]}>
                      <a
                        style={{ color: "#f6ebff" }}
                        href={link[2] ? link[1] : undefined}
                        target={link[2] ? "_blank" : ""}
                        rel="noreferrer"
                        onClick={!link[2] ? scrollToView(link[1]) : () => null}
                      >
                        {link[0]}
                      </a>
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col xs={0} md={2} />
            <Col xs={24} md={3}>
              <div className={styles.footerColumn3}>
                <div>Social media</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  {[
                    [facebookIcon, "https://www.facebook.com/StayCheqd"],
                    [
                      youtubIcon,
                      "https://www.youtube.com/channel/UCaf7l5F_ci2TsPZrnpQ1jhw",
                    ],
                    [
                      linkedinIcon,
                      "https://www.linkedin.com/company/cheqdindia",
                    ],
                  ].map((icon: Array<string>) => {
                    return (
                      <a
                        key={icon[1]}
                        href={icon[1]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={icon[0]} alt="" />{" "}
                      </a>
                    );
                  })}
                </div>
              </div>
              {/* <Typography.Title level={4} className={commonStyles["text-grey-2"]}>
              Made with{" "}
              <span role="img" aria-label="heart">
                ❤️
              </span>{" "}
              by <a href="https://thetaonelab.com">Theta One</a>
            </Typography.Title> */}
            </Col>
            <Col xs={0} md={2} />
            <Col xs={24} md={5}>
              <div className={styles.footerColumn4}>
                <div>
                  Mail us at{" "}
                  <a
                    style={{ color: "#f6ebff" }}
                    href="mailTo:support@cheqd.in"
                  >
                    support@cheqd.in
                  </a>
                </div>
                {/* <div>View all pricing plans</div> */}
              </div>
            </Col>
          </Row>
          <Row
            className={cx(className)}
            justify="center"
            style={{
              height: "80px",
              paddingTop: 0,
              paddingBottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "white",
              fontSize: "13px",
              fontWeight: 600,
              backgroundColor: "var(--processing-color)",
              opacity: "80%",
            }}
          >
            <Col span={24}>
              <div style={{ color: "var(--grey-3)", fontWeight: 400 }}>
                Copyright © 2022 Cheqd. All rights reserved
              </div>
              <div>
                Made with{" "}
                <span role="img" aria-label="love">
                  ❤️
                </span>{" "}
                at{" "}
                <a
                  style={{ color: "#f6ebff" }}
                  href="https://thetaonelab.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Theta One
                </a>
              </div>
            </Col>
          </Row>
        </>
      )}
    </IMTMediaQueries>
  );
}
