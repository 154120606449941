/*global chrome*/
import React, { Component } from "react";
import { Form, Input, Row, Col, Button, Select, notification } from "antd";
import DatePicker from "~/component/antd-overrides/DatePicker";
import commonStyle from "~/component/common.module.css";
import { AdminDetail } from "~/api/profile";
import { FormInstance } from "antd/lib/form";
import { APIState, fetchUtil } from "~/api/common";
import { filterOption, FORMAT_DATE, formatDate } from "~/lib";
import { LoginRole } from "~/api/auth";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import FieldInfo from "~/component/FieldInfo";
import { CheckCircleOutlined } from "@ant-design/icons";
import { panNumberVerification } from "~/utils";
import { PAN_VALIDATION_REGEX } from "~/lib/constants";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

const { Option } = Select;
const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  apiState: APIState;
  data: AdminDetail;
  onDataChange: (newData: AdminDetail) => void;
  handlesubmit: (newData: AdminDetail) => void;
  stateList: any;
  onDirty: () => void;
  handleTanVerification?: (isTanVerify: boolean, tan_no?: string) => void;
}
interface State {
  gstinTypeList: Array<{
    id: number;
    gst_type: string;
  }>;
  hideGstin: boolean;
  errMsgTan: string;
  tanVerifyLoader: boolean;
}
class CompanyDetailsPane extends Component<Props, {}> {
  state: State = {
    gstinTypeList: [],
    hideGstin: false,
    errMsgTan: "",
    tanVerifyLoader: false,
  };

  _formRef = React.createRef<FormInstance<any>>();

  componentDidMount() {
    this.getGstinType();
    if (
      this.props.data.gst_registration_type === 3 ||
      this.props.data.gst_registration_type === 4 ||
      this.props.data.gst_registration_type === 5
    ) {
      this.setState({ hideGstin: true });
    }
  }
  componentDidUpdate(prevProps: Props, _prevState: {}) {
    if (prevProps.data !== this.props.data) {
      if (
        this.props.data.gst_registration_type === 3 ||
        this.props.data.gst_registration_type === 4 ||
        this.props.data.gst_registration_type === 5
      ) {
        this.setState({ hideGstin: true });
      }

      this._formRef.current?.setFieldsValue(this.props.data);
    }
  }

  async getPtRegistartionNo(pan: string) {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/profession_tax/fetch_ptax_from_pan`,
      {
        pan_number: pan,
      }
    );
    if (ok) {
      if (this._formRef.current && json.length !== 0) {
        this._formRef.current.setFieldsValue({
          ptax_registration_no: json[0].registration_no,
        });
      }
      if (this._formRef.current && json.length === 0) {
        this._formRef.current.setFieldsValue({
          ptax_registration_no: "",
        });
      }
    } else {
      notification.error({ message });
    }
  }

  handleFetch = async (gstinNo: any) => {
    this.setState({ loading: true, errorMessage: "" });
    if (!gstinNo) {
      this.setState({ errorMessage: "Please enter a valid GSTIN" });
      this.setState({ loading: false });
      return;
    } else {
      const { ok, json } = await fetchUtil("POST", "/get_details_from_gstin", {
        gstin: gstinNo,
      });
      if (ok) {
        if (this._formRef.current) {
          this._formRef.current.setFieldsValue({
            gstin_registration_date: new Date(json[0].regDate)
              ? new Date(json[0].regDate)
              : new Date(),
          });
        }
      } else {
        this.setState({ errorMessage: "Some error occured, Try again later " });
      }
    }

    this.setState({ loading: false });
  };

  handleTanVerify = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.companyDetailsPane35
    );
    const formValues = this._formRef.current?.getFieldsValue();
    // console.log(formValues);
    const tanRegex = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
    if (!tanRegex.test(formValues.tan)) {
      return notification.error({
        message: "please enter a valid tan",
      });
    }
    if (formValues.tan) {
      this.setState({
        tanVerifyLoader: true,
      });
      const { company_name, legal_name } = this.props?.data;
      const { ok } = await fetchUtil("POST", `/tan_verify`, {
        tan_no: formValues.tan,
        legal_name,
        company_name,
      });
      if (ok) {
        this.setState({
          errMsgTan: "",
          tanVerifyLoader: false,
        });
        this.props.handleTanVerification!(true, formValues.tan);
        // console.log(json);
      } else {
        this.props.handleTanVerification!(false, formValues.tan);

        this.setState({
          errMsgTan: "Tan verification failed !!",
          tanVerifyLoader: false,
        });
      }
    } else {
      notification.error({
        message: "please enter a valid tan",
      });
    }
  };

  async getGstinType() {
    const { ok, json, message } = await fetchUtil(
      "GET",
      `/get_gst_registration_types`
    );

    if (ok) {
      const data = json;
      this.setState({
        gstinTypeList: data,
      });
    } else {
      notification.error({ message });
    }
  }
  gstinHandleChange = (val: any) => {
    if (val === 3 || val === 4 || val === 5) {
      this.setState({ hideGstin: true });
    } else {
      this.setState({ hideGstin: false });
    }
  };
  handleOnValueChange = async (changedValues: any, _values: any) => {
    this.props.onDirty();

    if (changedValues.gstin) {
      const gstin = changedValues.gstin.toUpperCase();
      if (String(changedValues.gstin).length === 15) {
        const stateCode = String(changedValues.gstin).substring(0, 2);

        const panNumber = String(changedValues.gstin).substring(2, 12);
        this.handleFetch(changedValues.gstin);
        this.getPtRegistartionNo(panNumber);

        if (this._formRef.current) {
          this._formRef.current.setFieldsValue({
            pan: panNumber,
            gstin,
            place_of_supply: Number(stateCode),
          });
        }
      } else {
        if (this._formRef.current) {
          this._formRef.current.setFieldsValue({
            pan: "",
            gstin,
            place_of_supply: 0,
            ptax_registration_no: "",
          });
        }
      }
    }

    if (changedValues.pan) {
      if (panNumberVerification(changedValues.pan)) {
        this.getPtRegistartionNo(changedValues.pan);
      }
      if (this._formRef.current) {
        const panNumber = String(changedValues.pan).toUpperCase();

        this._formRef.current.setFieldsValue({
          pan: panNumber,
        });
      }
    }

    if (changedValues.tan) {
      if (changedValues.tan.length < 10 || changedValues.tan.length > 10) {
        this.setState({
          errMsgTan: "",
        });
      }
    }
  };

  render() {
    const loading = this.props.apiState === "loading";

    const states = this.props.stateList;

    const gstview = (
      <Form.Item label="GSTIN registration type">
        <input
          className="ant-input"
          defaultValue={
            this.state.gstinTypeList.find(
              (it: { id: number; gst_type: string }) =>
                it.id === this.props.data.gst_registration_type
            )?.gst_type
          }
          readOnly
        />
      </Form.Item>
    );
    const stateView = (
      <Form.Item label="GST registration state">
        <input
          className="ant-input"
          defaultValue={
            states.find((it: any) => it.id === this.props.data.place_of_supply)
              ?.state_name
          }
          readOnly
        />
      </Form.Item>
    );

    const gstRegDateView = (
      <Form.Item label="GST registration date">
        <input
          className="ant-input"
          defaultValue={
            this.props.data.gstin_registration_date
              ? formatDate(this.props.data.gstin_registration_date)
              : ""
          }
          readOnly
        />
      </Form.Item>
    );

    // const formValues = this._formRef.current?.getFieldsValue();
    return (
      <Form
        initialValues={this.props.data}
        onFinish={this.props.handlesubmit}
        ref={this._formRef}
        onValuesChange={this.handleOnValueChange}
      >
        <Row gutter={{ sm: 36, md: 56, lg: 64, xl: 100 }}>
          <Col md={8} lg={8} xl={8} xxl={6}>
            {this.props.activeRole === LoginRole.SUPERuSER ? (
              gstview
            ) : (
              <Form.Item
                className={commonStyle["w-100"]}
                name="gst_registration_type"
                label="GSTIN registration type"
                rules={[
                  {
                    required: true,
                    message: "Please enter GSTIN registration type!",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  disabled={this.props.data.gst_registration_type === 1}
                  onChange={this.gstinHandleChange}
                >
                  {this.state.gstinTypeList.map(
                    (gstin: { id: number; gst_type: string }) => (
                      <Select.Option key={gstin.id} value={gstin.id}>
                        {gstin.gst_type}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            )}

            {!this.state.hideGstin ? (
              <Form.Item
                name="gstin"
                label="GSTIN"
                rules={[
                  {
                    type: "string",
                    pattern: /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
                    required: true,
                    message: "Please enter valid GSTIN",
                  },
                ]}
              >
                <Input
                  readOnly={this.props.activeRole === LoginRole.SUPERuSER}
                />
              </Form.Item>
            ) : (
              ""
            )}
            {this.props.activeRole === LoginRole.SUPERuSER ? (
              gstRegDateView
            ) : (
              <Form.Item
                name="gstin_registration_date"
                label="GSTIN registration date"
                rules={[
                  {
                    required: !this.state.hideGstin ? true : false,
                    message: "Please enter gst registration date",
                  },
                ]}
              >
                {<DatePicker format={FORMAT_DATE} style={{ width: "100%" }} />}
              </Form.Item>
            )}
            {!this.state.hideGstin ? (
              this.props.activeRole === LoginRole.SUPERuSER ? (
                stateView
              ) : (
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="place_of_supply"
                  label="GST registration state"
                  rules={[
                    {
                      required: true,
                      message: "Please select GST registration state!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                  >
                    {states.map((opt: any) => (
                      <Option key={opt.id} value={opt.id}>
                        {opt.state_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )
            ) : (
              ""
            )}

            <Form.Item
              name="gst_user_name"
              label="GST username"
              rules={[
                {
                  required: false,
                  type: "string",
                  message: "Please your GST username",
                },
              ]}
            >
              <Input readOnly={this.props.activeRole === LoginRole.SUPERuSER} />
            </Form.Item>
            <Form.Item
              name="udyam_regd_no"
              label="Udyam registration number"
              rules={[
                {
                  type: "string",
                  required: false,
                  message: "Please your udyam registration number",
                },
              ]}
            >
              <Input readOnly={this.props.activeRole === LoginRole.SUPERuSER} />
            </Form.Item>
          </Col>
          <div role="separator" />
          <Col md={8} lg={8} xl={8} xxl={6}>
            <div>
              <Form.Item
                label="Profession tax number"
                name="ptax_registration_no"
              >
                <input
                  className="ant-input"
                  readOnly={this.props.activeRole === LoginRole.SUPERuSER}
                />
              </Form.Item>
              <Form.Item
                name="pf_registration_no"
                label="PF registration number"
                rules={[
                  {
                    required: false,
                    type: "string",
                    message: "Please enter valid PF registration number",
                  },
                ]}
              >
                <Input
                  readOnly={this.props.activeRole === LoginRole.SUPERuSER}
                />
              </Form.Item>

              <Form.Item
                name="pan"
                label="PAN"
                rules={[
                  {
                    required: true,
                    type: "string",
                    pattern: PAN_VALIDATION_REGEX,
                    message: "Please enter valid PAN",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  maxLength={10}
                  readOnly={this.props.activeRole === LoginRole.SUPERuSER}
                />
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "100%" }}>
                <Form.Item
                  name="tan"
                  label="TAN"
                  rules={[
                    {
                      type: "string",
                      pattern: /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/,
                      message: "Please enter valid TAN",
                    },
                  ]}
                  style={{ flexBasis: "100%" }}
                >
                  <Input
                    readOnly={this.props.activeRole === LoginRole.SUPERuSER}
                    disabled={this.props.data.tan_verified}
                  />
                </Form.Item>
                {this.state.errMsgTan ? (
                  <div style={{ marginTop: "4px" }}>
                    <FieldInfo text={`${this.state.errMsgTan}`} color="red" />
                  </div>
                ) : null}
              </div>
              {this.props.data.tan_verified ? (
                <span>
                  <CheckCircleOutlined
                    style={{
                      padding: "8px",
                      fontWeight: 1400,
                      fontSize: "25px",
                      color: "green",
                      marginLeft: "14px",
                      marginTop: "28px",
                    }}
                  />
                </span>
              ) : (
                <span>
                  <Button
                    type="dashed"
                    style={{
                      marginLeft: "5px",
                      color: "#2F80ED",
                      marginTop: "32px",
                    }}
                    disabled={this.props.activeRole === LoginRole.SUPERuSER}
                    onClick={this.handleTanVerify}
                    loading={this.state.tanVerifyLoader}
                  >
                    {this.state.errMsgTan ? "Retry" : "Verify"}
                  </Button>
                </span>
              )}
            </div>

            <Form.Item
              name="esi_registration_no"
              label="ESI registration number"
              rules={[
                {
                  required: false,
                  type: "string",
                  message: "Please enter valid ESI registration number",
                },
              ]}
            >
              <Input readOnly={this.props.activeRole === LoginRole.SUPERuSER} />
            </Form.Item>
          </Col>
        </Row>
        {this.props.activeRole === LoginRole.SUPERuSER ? null : (
          <div>
            {/* Upcoming changes ---> <Button
              style={{ marginRight: 10 }}
              loading={loading}
              type="primary"
              onClick={() => {
                window.open("https://services.gst.gov.in/services/login");
                const configs = {
                  meta: { url: "https://services.gst.gov.in/services/login" },
                  actions: [
                    {
                      selector: "#username",
                      data: "my-gst-username",
                      timeout: 1000,
                    },
                    {
                      selector: "#user_pass",
                      data: "my-pass",
                      timeout: 1100,
                    },
                  ],
                };
                setTimeout((v) => {
                  window.postMessage({ configs }, document.location.origin);
                }, 5000);
              }}
            >
              open gst login
            </Button> */}
            <Button loading={loading} type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        )}
      </Form>
    );
  }
}

export default connector(CompanyDetailsPane);
