const equalSet = (firstSet: any, secondSet: any) => {
  let counter = 0;
  // tslint:disable-next-line: no-unused-expression
  firstSet.size === secondSet.size &&
    [...firstSet].every((x) => {
      if (secondSet.has(x)) {
        counter++;
      }
      return secondSet.has(x);
    });
  return counter;
};

export function isItemObject(item: any) {
  return item && typeof item === "object" && !Array.isArray(item);
}
export function checkIfArrayItemsAreAllObjects(
  array: any
): [boolean, [string]] {
  let finalKeys: any[string] = [];
  let onlyContainsObjects = true;
  let objectsShareAtLeastTwoAttributes = true;
  // If it contains any item other than objects
  array.forEach((item: any, index: any) => {
    if (!isItemObject(item)) {
      onlyContainsObjects = false;
      return onlyContainsObjects && objectsShareAtLeastTwoAttributes;
    }
    // If all objects share at least two attributes
    if (
      index !== 0 &&
      equalSet(
        new Set(Object.keys(item)),
        new Set(Object.keys(array[index - 1]))
      ) < 3
    ) {
      objectsShareAtLeastTwoAttributes = false;
      return onlyContainsObjects && objectsShareAtLeastTwoAttributes;
    }
    finalKeys = [...Array.from(new Set([...finalKeys, ...Object.keys(item)]))];
  });

  return [onlyContainsObjects && objectsShareAtLeastTwoAttributes, finalKeys];
}
