import React, { Component } from "react";
import { Modal, notification, Button, Table } from "antd";
import { fetchUtil } from "~/api/common";
import Currency from "~/component/Currency";

import styles from "./ReconcileUpload/styles.module.css";
import FieldInfo from "./FieldInfo";
import { formatDate } from "~/lib";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

interface Props {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  levelTwoClickedData: any;
  startRecocile: () => void;
  loadData: () => void;
}
const { Column, ColumnGroup } = Table;
export interface State {
  conflictId: any;
  existData: any;
  importData: any;
  tableData: any;
  tableLoading: boolean;
  loadOverWrite: boolean;
  loadKeep: boolean;
  incomeList: any;
  currentConflictNumber: number;
  totalConflictCount: number;
  disableKeepBothOption: boolean;
}

export default class ConflictModal extends Component<Props, State> {
  state: State = {
    conflictId: undefined,
    existData: [],
    importData: [],
    tableData: [],
    tableLoading: false,
    loadOverWrite: false,
    loadKeep: false,
    incomeList: {},
    currentConflictNumber: 0,
    totalConflictCount: 0,
    disableKeepBothOption: false,
  };

  componentDidMount() {
    this.loadConflict();
  }

  handleCancel = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.conflictModal21
    );

    this.props.onVisibleChange(false);
  };

  loadConflict = async (is_skip?: boolean) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.conflictModal22
    );
    this.setState({
      tableLoading: true,
    });
    const { ok, json, total, message } = await fetchUtil(
      `GET`,
      `/reconciliation/gstr2a_conflict?mapping_log_id=${
        this.props.levelTwoClickedData.log_id
      }&only_next=true&reset=${is_skip ? false : true}`
    );
    if (ok) {
      if (json.length > 0) {
        this.setState({
          conflictId: json[0].conflictId,
        });
      }
      const tableData: any = [];
      json.forEach((el, index) => {
        const existList = el.expenseDetail;
        const incomeList = el.gst2aExpense;

        existList.receipt_date = formatDate(new Date(existList.receipt_date));
        incomeList.invoiceDate = formatDate(new Date(incomeList.invoiceDate));

        this.setState({
          incomeList,
        });
        const combineObj = {
          ...existList,
          ...incomeList,
          existList,
          incomeList,
        };

        tableData.push(combineObj);
      });

      this.setState(
        {
          tableData,
          currentConflictNumber: total.currentConflictNumber,
          totalConflictCount: total.totalConflictCount,
          disableKeepBothOption: total.disable_keep_both_option,
          tableLoading: false,
        },
        () => {
          if (tableData.length === 0) {
            notification.success({ message: "Great. All caught up!" });
            setTimeout(() => {
              this.handleCancel();
            }, 1000);
          }
        }
      );
    } else {
      notification.error({ message });
      this.setState({
        tableLoading: false,
      });
    }
  };

  resolveConflict = async (type: string) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.conflictModal23
    );
    if (type === "Overwrite") {
      this.setState({
        loadOverWrite: true,
      });
    } else {
      this.setState({
        loadKeep: true,
      });
    }
    const { ok, message } = await fetchUtil(
      "POST",
      "/reconciliation/expense_action",
      {
        conflict_id: this.state.conflictId,
        action: type === "Overwrite" ? "OVERWRITE_EXISTING" : "KEEP_BOTH",
      }
    );
    if (!ok) {
      notification.error({ message });
      if (type === "Overwrite") {
        this.setState({
          loadOverWrite: false,
        });
      } else {
        this.setState({
          loadKeep: false,
        });
      }
    } else {
      // this.props.onVisibleChange(false);
      // this.props.loadData();
      this.loadConflict(true);
      if (type === "Overwrite") {
        this.setState({
          loadOverWrite: false,
        });
      } else {
        this.setState({
          loadKeep: false,
        });
      }
    }
  };

  render() {
    const { visible } = this.props;
    const { disableKeepBothOption } = this.state;
    return (
      <>
        <Modal
          title={`Resolve conflict (${this.state.currentConflictNumber}/${this.state.totalConflictCount})`}
          visible={visible}
          // maskClosable={false}
          width={900}
          bodyStyle={{ minHeight: 300 }}
          footer={
            <>
              <Button
                onClick={() => this.resolveConflict("Overwrite")}
                style={{ background: "#F09952", color: "white" }}
                loading={this.state.loadOverWrite}
              >
                Overwrite existing
              </Button>
              <Button
                onClick={() => this.resolveConflict("keep")}
                style={{
                  background: disableKeepBothOption
                    ? "var(--disabled-bg)"
                    : "var(--success-color)",
                  color: disableKeepBothOption ? " " : "var(--white-color)",
                  width: "100px",
                }}
                loading={this.state.loadKeep}
                disabled={disableKeepBothOption}
              >
                Keep both
              </Button>
              <Button
                style={{ background: "#D6D6D6", width: "100px" }}
                onClick={() => this.loadConflict(true)}
                loading={this.state.tableLoading}
              >
                Skip
              </Button>
              {/* <Button onClick={() => this.props.startRecocile()}>
                refresh
              </Button> */}
            </>
          }
          onCancel={this.handleCancel}
          destroyOnClose
          centered
        >
          <div style={{ position: "relative", height: "100%" }}>
            {this.state.tableData.length > 0 ? (
              <div
                style={{
                  minHeight: "100%",
                  width: "0.5px",
                  backgroundColor: "#E0E0E0",
                  position: "absolute",
                  top: "30px",
                  left: "50%",
                  zIndex: 5,
                  overflow: "hidden",
                }}
              />
            ) : null}

            <Table
              className={styles.reconcile_table}
              dataSource={this.state.tableData}
              loading={this.state.tableLoading}
              pagination={false}
              size="small"
              style={{ minHeight: 250 }}
            >
              <ColumnGroup
                title={
                  <p
                    // className={styles.reconcile_column_group_header}
                    style={{ color: "var(--processing-color)" }}
                  >
                    Existing
                  </p>
                }
                align="left"
              >
                <Column
                  title="Invoice no"
                  fixed="left"
                  // align="center"
                  dataIndex="invoice_no"
                  render={(val, data: any) => {
                    const obj = {
                      children: this.state.incomeList ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {val}

                          <span style={{ marginTop: "0px" }}>
                            <FieldInfo
                              attatchmentType="table-cell"
                              text={data.receipt_date}
                            />
                          </span>
                        </div>
                      ) : null,
                    };
                    return obj;
                  }}
                />
                <Column
                  title={"Name"}
                  align="center"
                  dataIndex="customer_name"
                  render={(val, data: any) => {
                    const obj = {
                      children: this.state.incomeList ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {val}
                          <span style={{ marginTop: "0px" }}>
                            <FieldInfo
                              attatchmentType="table-cell"
                              text={data.gstin}
                            />
                          </span>
                        </div>
                      ) : null,
                    };
                    return obj;
                  }}
                />
                <Column
                  title={<div style={{ marginRight: "20px" }}>Amount</div>}
                  align="right"
                  fixed="left"
                  dataIndex="amount"
                  // tslint:disable-next-line: jsx-no-lambda
                  render={(val) => {
                    const obj = {
                      children: val ? (
                        <div
                          style={{
                            maxWidth: "90px",
                            marginRight: "16px",
                            textAlign: "right",
                            alignSelf: "center",
                            marginLeft: "24px",
                          }}
                        >
                          <Currency currencySymbol={"₹"}>{val}</Currency>
                        </div>
                      ) : null,
                    };
                    return obj;
                  }}
                />
              </ColumnGroup>

              <ColumnGroup
                title={
                  <p
                    style={{
                      color: "var(--processing-color)",
                      marginLeft: "20px",
                    }}
                  >
                    Incoming from GST portal
                  </p>
                }
                align="left"
              >
                <Column
                  title="Invoice no"
                  align="center"
                  // fixed="left"

                  dataIndex="invoiceNo"
                  // tslint:disable-next-line: jsx-no-lambda
                  render={(val, data: any) => {
                    const obj = {
                      children: val ? (
                        <p
                          style={{
                            maxWidth: "90px",
                            marginLeft: "22px",
                            textAlign: "left",
                            marginTop: "15px",
                          }}
                        >
                          {val}
                          <span style={{ marginTop: "0px" }}>
                            <FieldInfo
                              attatchmentType="table-cell"
                              text={data.invoiceDate}
                            />
                          </span>
                        </p>
                      ) : null,
                    };
                    return obj;
                  }}
                />
                <Column
                  title={"Name"}
                  align="center"
                  dataIndex="customer_name"
                  render={(val, data: any) => {
                    const obj = {
                      children: this.state.incomeList ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {val}
                          <span style={{ marginTop: "0px" }}>
                            <FieldInfo
                              attatchmentType="table-cell"
                              text={data.gstin}
                            />
                          </span>
                        </div>
                      ) : null,
                    };
                    return obj;
                  }}
                />
                <Column
                  title={"Amount"}
                  align="right"
                  fixed="left"
                  dataIndex="netTotal"
                  ellipsis={true}
                  // tslint:disable-next-line: jsx-no-lambda
                  render={(val) => {
                    const obj = {
                      children: val ? (
                        <Currency currencySymbol={"₹"}>{val}</Currency>
                      ) : null,
                    };
                    return obj;
                  }}
                />
              </ColumnGroup>
            </Table>
          </div>
        </Modal>
      </>
    );
  }
}
