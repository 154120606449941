import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { notification, Table } from "antd";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import { ColumnType } from "antd/lib/table/interface";
import { AuthState } from "~/app/MainApp/store";
import { fetchUtil } from "~/api/common";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";

interface State {
  userList: Array<{
    role: string;
    privilage: string;
    id: number;
  }>;
}

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  history: any;
}

class SettingsRole extends Component<Props, State> {
  state: State = {
    userList: [],
  };

  columnDef: Array<ColumnType<any>> = [
    {
      title: "Role",
      dataIndex: "role_name",
      render: (role_name: string) => ({
        children: (
          <>
            <b>{role_name}</b>
          </>
        ),
      }),
    },
    {
      title: "Privilege",
      dataIndex: "privilage",
    },
  ];

  componentDidMount() {
    this.getRoleList();
  }

  async getRoleList() {
    const { ok, json, message } = await fetchUtil("GET", `/get_roles`);
    if (ok) {
      const data = json;

      data.forEach((el) => {
        if (el.id === 1) {
          el.privilage =
            "Admin can see all section of portal and can edit also ";
        } else if (el.id === 2) {
          el.privilage =
            "Employee can see only his/her basic details, salary details and apply for reimbursements";
        } else if (el.id === 3) {
          el.privilage = "CA can see all the detail in read only format ";
        } else if (el.id === 4) {
          el.privilage = "Can access any user's account, given the permission.";
        } else if (el.id === 5) {
          el.privilage =
            "A super user same as an admin but can not update company details";
        }
      });

      this.setState({
        userList: data,
      });
    } else {
      notification.error({ message });
    }
  }

  render() {
    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>{titles.SettingsRole}</title>
        </Helmet>
        <IMTPageHeader breadcumTexts={["Roles"] }           style ={{paddingLeft:"0"}}
/>
        
        <Table
          id="table-emplist"
          style={{ maxWidth: "80%", cursor: "pointer" }}
          columns={this.columnDef}
          dataSource={this.state.userList}
          size="large"
          pagination={{
            size: "small",
            showSizeChanger: false,
            position: ["topRight"],
          }}
        />
      </IMTContent>
    );
  }
}

export default connector(SettingsRole);
