import React from "react";
import { IMTIconProps, IMTIconElement, __colors } from "./interface";

const SalesInvoice: IMTIconElement = ({
  highlighted,
  ...props
}: IMTIconProps) => {
  const color = highlighted ? __colors.active : __colors.inactive;
  return (
    <svg 
       width="20"
      height="17"
    viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M792 400H736C731.6 400 728 403.6 728 408V728H72V72H392C396.4 72 400 68.4 400 64V8C400 3.6 396.4 0 392 0H32C14.3 0 0 14.3 0 32V768C0 785.7 14.3 800 32 800H768C785.7 800 800 785.7 800 768V408C800 403.6 796.4 400 792 400Z" fill="black"/>
<path d="M798.995 70.6975L799.999 8.47742C800.052 3.82007 796.246 0 791.541 0H791.33L728.949 1.51758C727.892 1.56992 726.834 1.98853 726.094 2.72116L506.229 219.89C504.59 221.512 504.59 224.181 506.229 225.803L571.94 290.796C572.786 291.634 573.843 292 574.954 292C576.064 292 577.121 291.581 577.967 290.796L797.779 73.628C798.543 72.8375 798.977 71.791 798.995 70.6975ZM765.426 58.3477L574.954 246.473L551.059 222.872L741.637 34.6423L765.796 34.0667L765.426 58.3477Z" fill="black"/>
<path d="M315.718 592.164C315.718 596.948 313.97 600.996 310.474 604.308C306.978 607.436 302.93 609 298.33 609C293.178 609 289.038 607.436 285.91 604.308C282.782 600.996 281.218 596.948 281.218 592.164V432.636C281.218 427.852 282.874 423.896 286.186 420.768C289.498 417.456 293.73 415.8 298.882 415.8C303.298 415.8 307.162 417.456 310.474 420.768C313.97 423.896 315.718 427.852 315.718 432.636V592.164ZM503.781 415.8C508.197 415.8 511.785 417.272 514.545 420.216C517.305 423.16 518.685 426.84 518.685 431.256V592.164C518.685 596.948 517.029 600.996 513.717 604.308C510.589 607.436 506.633 609 501.849 609C499.457 609 496.973 608.632 494.397 607.896C492.005 606.976 490.165 605.78 488.877 604.308L380.409 466.584L387.585 462.168V593.544C387.585 597.96 386.113 601.64 383.169 604.584C380.409 607.528 376.729 609 372.129 609C367.713 609 364.125 607.528 361.365 604.584C358.605 601.64 357.225 597.96 357.225 593.544V432.636C357.225 427.852 358.789 423.896 361.917 420.768C365.229 417.456 369.277 415.8 374.061 415.8C376.637 415.8 379.305 416.352 382.065 417.456C384.825 418.376 386.849 419.848 388.137 421.872L493.293 556.008L488.601 559.32V431.256C488.601 426.84 489.981 423.16 492.741 420.216C495.501 417.272 499.181 415.8 503.781 415.8Z" fill="black"/>
</svg>

    // <svg
    //   width="80"
    //   height="30"
    //   viewBox="0 0 63 76"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <mask id="path-1-inside-1_0_1" fill="white">
    //     <rect width="63" height="76" rx="3" />
    //   </mask>
    //   <rect
    //     width="63"
    //     height="76"
    //     rx="3"
    //     fill="white"
    //     stroke="black"
    //     stroke-width="8"
    //     mask="url(#path-1-inside-1_0_1)"
    //   />
    //   <path
    //     d="M20.5066 60.804C20.5066 61.428 20.2786 61.956 19.8226 62.388C19.3666 62.796 18.8386 63 18.2386 63C17.5666 63 17.0266 62.796 16.6186 62.388C16.2106 61.956 16.0066 61.428 16.0066 60.804V39.996C16.0066 39.372 16.2226 38.856 16.6546 38.448C17.0866 38.016 17.6386 37.8 18.3106 37.8C18.8866 37.8 19.3906 38.016 19.8226 38.448C20.2786 38.856 20.5066 39.372 20.5066 39.996V60.804ZM45.0367 37.8C45.6127 37.8 46.0807 37.992 46.4407 38.376C46.8007 38.76 46.9807 39.24 46.9807 39.816V60.804C46.9807 61.428 46.7647 61.956 46.3327 62.388C45.9247 62.796 45.4087 63 44.7847 63C44.4727 63 44.1487 62.952 43.8127 62.856C43.5007 62.736 43.2607 62.58 43.0927 62.388L28.9447 44.424L29.8807 43.848V60.984C29.8807 61.56 29.6887 62.04 29.3047 62.424C28.9447 62.808 28.4647 63 27.8647 63C27.2887 63 26.8207 62.808 26.4607 62.424C26.1007 62.04 25.9207 61.56 25.9207 60.984V39.996C25.9207 39.372 26.1247 38.856 26.5327 38.448C26.9647 38.016 27.4927 37.8 28.1167 37.8C28.4527 37.8 28.8007 37.872 29.1607 38.016C29.5207 38.136 29.7847 38.328 29.9527 38.592L43.6687 56.088L43.0567 56.52V39.816C43.0567 39.24 43.2367 38.76 43.5967 38.376C43.9567 37.992 44.4367 37.8 45.0367 37.8Z"
    //     fill="black"
    //   />
    //   <line
    //     x1="8"
    //     y1="14.5"
    //     x2="46"
    //     y2="14.5"
    //     stroke="#4F4F4F"
    //     stroke-width="3"
    //   />
    //   <line
    //     x1="8"
    //     y1="22.5"
    //     x2="27"
    //     y2="22.5"
    //     stroke="#4F4F4F"
    //     stroke-width="3"
    //   />
    // </svg>
  );
};

export default SalesInvoice;
