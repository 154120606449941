import React, { useState } from "react";
import { ExpenseLineItemRecord } from "./ExpenseLineItemTypes";
import LIFooter, { LIFooterAmountRow } from "~/fragment/line-items/LIFooter";
import { TAX_INCLUSION, TaxTeritoryType } from "~/lib/taxes";
import PaymentHistoryDialog from "~/feature/expense/modal/PaymentHistoryDialog";
import classes from "./ExpenseLineItem.module.css";
import { getTotalGST } from "~/lib/taxCalculation";
import { CurrencyInfo } from "~/fragment/line-items/LineItemTypes";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

interface HistoryData {
  netTotal: string | number;
  tdsAmont: string | number;
  paymentDetails: any;
  expenseId: number | null;
  conversionRate: number | undefined;
  onRefresh: () => void;
}

interface ExpenseLineItemFooter {
  rowData: Array<ExpenseLineItemRecord>;
  hasCess?: boolean;
  currency?: CurrencyInfo;
  taxInclusion?: TAX_INCLUSION;
  taxTeritoryType?: TaxTeritoryType;
  conversionRate?: number;
  paymentHistoryData?: HistoryData;
  tdsInfo: {
    tdsRate?: number;
    tdsAmount?: number;
  };
  totalPayAmount?: number;
  isCopy: boolean;
  netTotal?: any;
}

const ExpenseLineItemFooter = (props: ExpenseLineItemFooter) => {
  const [footerInfo, setFooterInfo] = useState({
    paymentHistoryDlg: false,
  });
  const taxes = getTotalGST(
    props.rowData.map((row) => {
      return {
        amount: row.amount,
        gstPercentage: row.extra?.gstRate?.rate ?? 0,
        cessPercentage: row.extra?.cessRate?.cessPercentage ?? 0,
      };
    }),
    {
      taxInclusion: props.taxInclusion ?? "OutOfTax",
      taxTeritoryType: props.taxTeritoryType ?? TaxTeritoryType.OTHER_TERITORY,
    }
  );

  const hasNoTax = props.taxInclusion === "OutOfTax";
  const isIGST =
    !hasNoTax && props.taxTeritoryType === TaxTeritoryType.INTRA_STATE;
  const isC_S_GST = !hasNoTax && !isIGST;
  const hasCess = props.hasCess;
  const hasTDS = !!props.tdsInfo.tdsAmount;

  const conversionRate =
    props.currency !== undefined ? props.currency.conversionRate : 1;

  const symbol = props.currency?.symbol;

  const totalPaidAmount = props.paymentHistoryData?.paymentDetails
    ?.payment_history
    ? props.paymentHistoryData?.paymentDetails?.payment_history
        .map((el: any) =>
          el.payment_status !== "Cancelled" ? el.paid_amount : null
        )
        .reduce((a: any, c: any) => {
          return a + c;
        })
    : null;

  const handleHstryDlgVisibleChange = (visible: boolean) => {
    setFooterInfo({
      paymentHistoryDlg: visible,
    });
  };

  const onHistoryClick = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.expenseLineItemFooter96
    );
    setFooterInfo({
      paymentHistoryDlg: true,
    });
  };
  return (
    <>
      <LIFooter>
        <LIFooterAmountRow
          label="Subtotal"
          value={taxes.subTotal}
          conversionRate={conversionRate}
          currencySymbol={symbol}
        />
        {isIGST && (
          <LIFooterAmountRow
            label="IGST"
            value={taxes.igst}
            conversionRate={conversionRate}
            currencySymbol={symbol}
          />
        )}
        {isC_S_GST && (
          <LIFooterAmountRow
            label="CGST"
            value={taxes.cgst}
            conversionRate={conversionRate}
            currencySymbol={symbol}
          />
        )}
        {isC_S_GST && (
          <LIFooterAmountRow
            label="SGST"
            value={taxes.sgst}
            conversionRate={conversionRate}
            currencySymbol={symbol}
          />
        )}
        {hasCess && (
          <LIFooterAmountRow
            label="CESS"
            value={taxes.cess}
            conversionRate={conversionRate}
            currencySymbol={symbol}
          />
        )}
        {/* // props.rowData[0]?.amount *
      (Number(a?.extra?.cessRate?.cessPercentage) / 100) */}
        <LIFooterAmountRow
          label="Total"
          value={taxes.total}
          conversionRate={conversionRate}
          currencySymbol={symbol}
        />
        {props.totalPayAmount! > 0 && !props.isCopy ? (
          <LIFooterAmountRow
            label="Paid"
            value={
              totalPaidAmount! /
              (props.paymentHistoryData?.conversionRate!
                ? props.paymentHistoryData?.conversionRate!
                : 1)
            }
            conversionRate={conversionRate}
            currencySymbol={symbol}
            color="#4C985A"
            rightNode={
              <span
                className={classes.linkStyle}
                style={{
                  marginLeft: "20px",
                  color: "#3D6BFA",
                  cursor: "pointer",
                }}
                onClick={onHistoryClick}
              >
                History
              </span>
            }
          />
        ) : null}

        {hasTDS && !props.isCopy ? (
          <LIFooterAmountRow
            label={`TDS Withheld (${props.tdsInfo.tdsRate} %)`}
            value={
              props.tdsInfo.tdsAmount
                ? props.tdsInfo.tdsAmount *
                  // props.paymentHistoryData?.conversionRate!
                  (props.paymentHistoryData?.conversionRate!
                    ? props.paymentHistoryData?.conversionRate!
                    : 1)
                : 0
            }
            conversionRate={1}
            // currencySymbol={symbol}
            color="#828282"
          />
        ) : null}
        {(hasTDS || props.totalPayAmount! > 0) && !props.isCopy ? (
          <LIFooterAmountRow
            label={`Payable amount`}
            value={
              props.netTotal *
                //  props.paymentHistoryData?.conversionRate!
                (props.paymentHistoryData?.conversionRate!
                  ? props.paymentHistoryData?.conversionRate!
                  : 1) -
              (props.tdsInfo.tdsAmount! *
                // props.paymentHistoryData?.conversionRate! +
                (props.paymentHistoryData?.conversionRate!
                  ? props.paymentHistoryData?.conversionRate!
                  : 1) +
                totalPaidAmount!)
            }
            conversionRate={1}
            color="#828282"
          />
        ) : null}
      </LIFooter>
      <PaymentHistoryDialog
        paymentDetails={props.paymentHistoryData?.paymentDetails!}
        net_amount={props.paymentHistoryData?.netTotal!}
        tds_amount={props.paymentHistoryData?.tdsAmont! || 0}
        visible={footerInfo.paymentHistoryDlg}
        onVisibleChange={handleHstryDlgVisibleChange}
        onRefresh={props.paymentHistoryData?.onRefresh!}
        conversionRate1={
          props.paymentHistoryData?.conversionRate!
            ? props.paymentHistoryData?.conversionRate!
            : 1
          // props.paymentHistoryData?.conversionRate
        }
        expenseId={props.paymentHistoryData?.expenseId}
        totalPayAmount={props.totalPayAmount}
      />
    </>
  );
};

export default ExpenseLineItemFooter;
