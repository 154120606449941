import React, { Component } from "react";
import {
  Button,
  Table,
  notification,
  Row,
  Tooltip,
  Space,
  Menu,
  Dropdown,
  Modal,
  Input,
  Col,
} from "antd";
import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/lib/table/interface";
import { RouteChildrenProps } from "react-router";
import { formatDate, parseNumber, roundNumber } from "~/lib";

import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import Currency from "~/component/Currency";
import FieldInfo from "~/component/FieldInfo";
import { fetchUtil } from "~/api/common";
import PaymentPending from "~/component/PaymentPending";
import ReconcileFileUpload from "~/component/ReconcileUpload";
import { getVendorList } from "~/api/vendor";

import styles from "./InvoicePage.module.css";
import {
  subMonths,
  getMonth,
  isPast,
  addYears,
  getYear,
  subYears,
} from "date-fns";
import SummaryTags from "~/component/SummaryTags";
import PayInvoiceModal from "./modal/PayInvoiceModal";
import { LoginRole } from "~/api/auth";
import YearMonthDropdown from "~/fragment/year-month-dropdown/YearMonthDropdown";
import {
  InvoiceAPIData,
  downloadPDFFile,
  getInvoiceList,
  getTableExportBlob,
} from "~/api/invoice";
import { registerEvent } from "~/analytics";
import ExportButton from "~/fragment/table-export/ExportButton";
import { months } from "~/lib/constants";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
import { debounce } from "lodash";
import * as ga from "~/contants/gaConstants";

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  sortOrderInfo: state.mainAppSlice.user.sort_order,
  sorterData: state.mainAppSlice.user.sorter,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  setSortOrder: (sortOrderInfo: any) =>
    dispatch(action.auth.setSortOrder(sortOrderInfo)),
  setSorter: (sorter: any) => dispatch(action.auth.setSorter(sorter)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouteChildrenProps, PropsFromRedux {}

interface State {
  rowData: Array<InvoiceAPIData>;
  pagination: {
    pageSize: number;
    pageNo: number;
    pageCount: number;
  };
  apiState: "idle" | "loading" | "error";
  monthSelect: number;
  yearSelect: any;
  invoiceData: any;
  payInvoiceModal: boolean;
  selectInvoiceDetail?: any;
  activePage: number;
  noOfRows: number;
  totalItemCount: number;
  vendorPickList: any;
  sortedInfo: any;
  filteredInfo: any;
  sortOrder: string;
  searchItem: string;
  highlightedText: string;
}
class InvoicePage extends Component<Props, {}> {
  state: State = {
    rowData: [],
    pagination: {
      pageSize: 10,
      pageNo: 1,
      pageCount: 1,
    },
    apiState: "idle",
    yearSelect: "",
    monthSelect:
      new Date().getDate() < 25
        ? getMonth(subMonths(new Date(), 1))
        : getMonth(new Date()),
    invoiceData: [],
    payInvoiceModal: false,
    selectInvoiceDetail: undefined,
    activePage: 1,
    noOfRows: 12,
    totalItemCount: 0,
    vendorPickList: [],
    sortedInfo: {},
    filteredInfo: {},
    sortOrder: "",
    searchItem: "",
    highlightedText: "",
  };

  static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State
  ): Partial<State> | null {
    const query = new URLSearchParams(nextProps.location.search);
    let currentPage = 1;
    const today = new Date();
    const isBefore25th = today.getDate() < 25;
    const selectedDate = isBefore25th ? subMonths(today, 1) : today;
    const current = new Date();
    const yearCurrent = new Date().getFullYear();
    const eligible =
      current.getTime() <= new Date(`${yearCurrent}-04-25`).getTime() &&
      current.getTime() > new Date(`${yearCurrent - 1}-04-25`).getTime();
    let year: any = eligible
      ? `${getYear(subYears(current, 1))}-${getYear(current)}`
      : `${getYear(current)}-${getYear(addYears(current, 1))}`;

    let month = getMonth(selectedDate);

    if (query.has("year")) {
      const _yearStr = query.get("year") + "";
      if (/\d{4}/.test(_yearStr)) {
        year = _yearStr;
      }
    }
    if (query.has("month")) {
      const _monthNum = +months.indexOf(query.get("month") + "");

      // console.log(_monthNum, "--------------------");
      if (Number.isFinite(_monthNum)) {
        month = _monthNum;
      }
    }
    if (query.has("page")) {
      const pageNo = +query.get("page")!;
      if (Number.isFinite(pageNo)) {
        currentPage = pageNo;
      }
    }
    const newState: Partial<State> = {
      yearSelect: year,
      monthSelect: month,
      activePage: currentPage,
      sortOrder: query.get("sort_order") || "",
    };
    if (
      prevState.yearSelect !== newState.yearSelect ||
      prevState.monthSelect !== newState.monthSelect ||
      prevState.activePage !== newState.activePage ||
      prevState.sortOrder !== newState.sortOrder
    ) {
      return newState;
    }
    return null;
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    if (this.props.sorterData) {
      this.setState({
        sortedInfo: this.props.sorterData,
      });
    }
    if (query.has("search_item") && query.get("search_item")) {
      const searchItem = query.get("search_item");
      if (searchItem) {
        this.setState({ searchItem, highlightedText: searchItem }, () => {
          this.loadData();
          this.getInvoiceSummary();
          this.loadPickupLists();
        });
      }
    } else {
      this.loadData();
      this.getInvoiceSummary();
      this.loadPickupLists();
    }

    // this.placeExportButton();
  }

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>) {
    if (
      _prevState.monthSelect !== this.state.monthSelect ||
      _prevState.yearSelect !== this.state.yearSelect ||
      _prevState.activePage !== this.state.activePage
    ) {
      this.loadData();
      if (
        this.props.activeRole === LoginRole.ADMIN ||
        this.props.activeRole === LoginRole.CA ||
        this.props.activeRole === LoginRole.SUPERuSER
      ) {
        this.getInvoiceSummary();
      }
    }
    if (_prevState.rowData !== this.state.rowData) {
      if (this.state.rowData.length) {
        this.placeButton();
      }
    }
  }

  placeButton = () => {
    // setTimeout(() => {
    const element = document.getElementsByClassName(
      "ant-pagination-item-link"
    )[0];
    if (element) {
      const { left, top } = element.getBoundingClientRect();
      const btn = document.getElementById("exportBtn");
      if (btn) {
        btn.style.position = "absolute";
        btn.style.left = left - 30 + "px";
        btn.style.top = top + "px";
        btn.style.margin = "0";
        btn.style.display = "inline";
      }
    }
    // }, 1000);
  };

  loadPickupLists = async () => {
    const [vendorRes] = await Promise.all([
      getVendorList().then(({ ok, message, data }) => ({
        ok,
        message,
        data,
      })),
    ]);

    if (!vendorRes.ok) {
      notification.error({
        message: "Failed to load Vendors",
        description: vendorRes.message,
      });
    } else {
      this.setState({
        vendorPickList: vendorRes.data,
      });
    }
  };

  loadData = async () => {
    this.setState({
      apiState: "loading",
    });
    const { ok, message, json } = await fetchUtil("POST", "/get_invoices", {
      page_no: this.state.activePage,
      no_of_rows: this.state.noOfRows,
      fy: this.state.yearSelect + "",
      month:
        this.state.monthSelect + 1 + "" === "13"
          ? "ALL"
          : this.state.monthSelect + 1 + "",
      time_zone_offset: 330,
      sort_param: this.props.sortOrderInfo || "DATE_DESC",
      search_text: this.state.searchItem,
    });
    if (!ok) {
      notification.error({ message });
      this.setState({
        apiState: "error",
      });
    } else {
      json.forEach((row) => {
        for (const key in row) {
          // console.log(key,row)
          if (key.includes("date") || key.includes("_time")) {
            row[key] = new Date(row[key]);
          }
        }
      });
      const data = json as Array<InvoiceAPIData>;
      this.setState({
        rowData: data,
        apiState: "idle",
        totalItemCount: data.length > 0 ? data[0].total_rows : 0,
      });
      this.getInvoiceSummary();
    }
  };

  sortedData = async (order: any) => {
    this.setState({
      apiState: "loading",
    });
    const { ok, message, json } = await fetchUtil("POST", "/get_invoices", {
      page_no: this.state.activePage,
      no_of_rows: this.state.noOfRows,
      fy: this.state.yearSelect + "",
      month:
        this.state.monthSelect + 1 + "" === "13"
          ? "ALL"
          : this.state.monthSelect + 1 + "",
      time_zone_offset: 330,
      sort_param: order,
      search_text: this.state.searchItem,
    });
    if (!ok) {
      notification.error({ message });
      this.setState({
        apiState: "error",
      });
    } else {
      json.forEach((row) => {
        for (const key in row) {
          if (key.includes("date") || key.includes("_time")) {
            row[key] = new Date(row[key]);
          }
        }
      });
      const data = json as Array<InvoiceAPIData>;
      this.setState({
        rowData: data,
        apiState: "idle",
        totalItemCount: data.length > 0 ? data[0].total_rows : 0,
      });
    }
  };

  handlePageChange = (pageNumber: number) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", pageNumber + "");
    this.props.history.push("?" + query);
  };
  handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchItem = e.target.value;
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", 1 + "");
    query.set("search_item", searchItem);
    this.props.history.push("?" + query);
    this.setState(
      { searchItem: e.target.value, highlightedText: e.target.value },
      () => {
        // this.debouncedGetVendorList();
        this.debouncedGetInvoiceSummary();
      }
    );
  };
  highlightText(text: string): JSX.Element {
    const { highlightedText } = this.state;
    if (text) {
      const parts = text.split(new RegExp(`(${highlightedText})`, "gi"));
      return (
        <>
          {parts.map((part: any, index: any) =>
            part.toLowerCase() === highlightedText.toLowerCase() ? (
              <span key={index} className={styles["highlighted-text"]}>
                {part}
              </span>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </>
      );
    } else {
      return <></>;
    }
  }

  handleTableChange = (_pagination: any, filters: any, sorter: any) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", _pagination.current + "");
    let sortOrder = "";
    // console.log(sorter);

    if (sorter.columnKey === "invoice_number") {
      if (sorter.order === "descend") {
        sortOrder = "ID_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "ID_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "customer_name") {
      if (sorter.order === "descend") {
        sortOrder = "CUST_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "CUST_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "invoice_type") {
      if (sorter.order === "descend") {
        sortOrder = "CUST_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "CUST_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "invoice_date") {
      if (sorter.order === "descend") {
        sortOrder = "IDATE_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "IDATE_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "due_date") {
      if (sorter.order === "descend") {
        sortOrder = "DDATE_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "DDATE_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "balance_due") {
      if (sorter.order === "descend") {
        sortOrder = "AMT_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "AMT_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "taxableAmt") {
      if (sorter.order === "descend") {
        sortOrder = "TAMT_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "TAMT_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    }
    if (!sorter.order) {
      sortOrder = "";
      this.props.setSortOrder(sortOrder);
    }

    const prev = query.get("sort_order");
    if (prev === sortOrder) return;

    query.set("sort_order", sortOrder);

    this.props.history.push("?" + query);
    this.props.setSorter(sorter);
    this.setState(
      {
        filteredInfo: filters,
        sortedInfo: sorter ? sorter : this.props.sorterData,
      },
      () => this.loadData()
    );
  };

  handleOnboardVisibleChange = (requestedVisibility: boolean) => {
    this.setState({
      payInvoiceModal: requestedVisibility,
    });
  };

  handleCreditNote = (
    e: React.MouseEvent<HTMLSpanElement>,
    invoice: InvoiceAPIData
  ) => {
    e.stopPropagation();
    const keys = e.currentTarget.dataset.id;
    const _invoice_id = keys?.split("/")[0];
    const _crId = keys?.split("/")[1];
    if (_invoice_id) {
      const invoice_id = +_invoice_id;
      const crId = _crId ? +_crId : null;
      const invoice = this.state.rowData.find(
        (it) => it.invoice_id === invoice_id
      );
      this.props.history.push(
        crId
          ? `/app/invoice/${_invoice_id}/creditnote/${crId}`
          : `/app/invoice/${_invoice_id}/creditnote/new`,
        invoice
      );
    }
  };
  // debouncedGetVendorList = debounce(this, 300);
  debouncedGetInvoiceSummary = debounce(this.loadData, 500);
  handleDebitNote = (
    e: React.MouseEvent<HTMLSpanElement>,
    invoice: InvoiceAPIData
  ) => {
    e.stopPropagation();
    const keys = e.currentTarget.dataset.id;
    const _invoice_id = keys?.split("/")[0];
    const _drId = keys?.split("/")[1];
    if (_invoice_id) {
      const invoice_id = +_invoice_id;
      const drId = _drId ? +_drId : null;
      const invoice = this.state.rowData.find(
        (it) => it.invoice_id === invoice_id
      );
      this.props.history.push(
        drId
          ? `/app/invoice/${_invoice_id}/debitnote/${drId}`
          : `/app/invoice/${_invoice_id}/debitnote/new`,
        invoice
      );
    }
  };

  async getInvoiceSummary() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_invoice_summary`,
      {
        // month: this.state.monthSelect + 1 + "",
        // year: this.state.yearSelect + "",
        fy: this.state.yearSelect + "",
        month:
          this.state.monthSelect + 1 + "" === "13"
            ? "ALL"
            : this.state.monthSelect + 1 + "",
        search_text: this.state.searchItem,
      }
    );

    if (ok) {
      const data = json[0];
      if (data) {
        data.invoice_amount = parseNumber(data.invoice_amount);
      }
      this.setState({
        invoiceData: data,
      });
    } else {
      notification.error({ message });
    }
  }

  handleRowEvent = (record: InvoiceAPIData, _rowIndex?: number) => {
    return {
      onClick: (_event: React.MouseEvent) => {
        registerEvent(
          ga.EVENT_CATEGORY_BUTTON_CLICK,
          ga.EVENT_CLICK,
          ga.events.invoicePage123
        );
        this.props.history.push(`/app/invoice/${record.invoice_id}`, {
          search: this.props.location.search,
        });
      },
    };
  };

  handleAddNew = (_e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.invoicePage124
    );
    this.props.history.push("/app/invoice/new");
  };

  handleDownload = (info: any) => {
    const e = info.domEvent;
    e.stopPropagation();
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.invoicePage125
    );
    const _invoice_id = e.currentTarget.dataset.id;
    if (_invoice_id) {
      const invoice_id = +_invoice_id;
      downloadPDFFile(invoice_id)
        .then((res: any) => {
          const blob = new Blob([res], { type: "application/pdf" });

          saveAs(blob, `Invoice ${invoice_id}.pdf`);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  handleCopy = (info: any) => {
    const e = info.domEvent;

    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.invoicePage126
    );
    e.stopPropagation();
    const _invoice_id = e.currentTarget.dataset.id;
    if (_invoice_id) {
      const invoice_id = +_invoice_id;
      const invoice = this.state.rowData.find(
        (it) => it.invoice_id === invoice_id
      );
      this.props.history.push("/app/invoice/copy?from=" + _invoice_id, invoice);
    }
  };

  handleDelete = (info: any, record: InvoiceAPIData) => {
    const e = info.domEvent;

    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.invoicePage127
    );
    e.stopPropagation();
    const _invoice_id = e.currentTarget.dataset.id;

    if (_invoice_id) {
      Modal.confirm({
        title:
          record.compliance_status === "Complete" && record.paid_flag === 1 ? (
            <>
              <div>Do you really wish to delete this invoice?</div>
              <div>
                Note: Payment for this invoice is already logged and compliances
                are completed. Delete at your own risk
              </div>{" "}
            </>
          ) : record.paid_flag === 1 ? (
            <>
              <div>Do you really wish to delete this invoice?</div>
              <div>
                Note: Payment for this invoice is already logged and compliances
                are completed. Delete at your own risk
              </div>{" "}
            </>
          ) : (
            <>
              <div>Do you wish to delete this invoice?</div>
              <div className={styles.invoice}>
                Customer Name {record.customer_name ?? "N/A"}, Invoice no.{" "}
                {record.invoice_number ?? "N/A"}, <br />
                Date {formatDate(record.invoice_date)}
              </div>
            </>
          ),
        icon: <ExclamationCircleOutlined />,
        onOk: async () => {
          const { ok, message } = await fetchUtil(
            "POST",
            "/update_invoice/delete_invoice",
            {
              invoice_id: _invoice_id,
            }
          );
          if (!ok) {
            notification.warn({ message });
          } else {
            notification.success({ message });
            this.loadData();
            this.getInvoiceSummary();
          }
        },
      });
    }
  };

  refreshAfterReconcile = (isSubmit: boolean) => {
    if (isSubmit) {
      this.loadData();
      this.getInvoiceSummary();
      this.loadPickupLists();
      if (this.props.sorterData) {
        this.setState({
          sortedInfo: this.props.sorterData,
        });
      }
    }
  };

  handleFetchData = () => {
    return getInvoiceList(
      1,
      330,
      1,
      this.state.monthSelect + 1 + "" === "13"
        ? "ALL"
        : this.state.monthSelect + 1 + "",
      this.state.yearSelect + "",
      this.props.sortOrderInfo || "DATE_DESC"
    );
  };

  handleExportDataDownload = async (
    acceptType: string,
    selectedFields: string
  ) => {
    return getTableExportBlob(
      1,
      330,
      999999,
      this.state.monthSelect + 1 + "" === "13"
        ? "ALL"
        : this.state.monthSelect + 1 + "",
      this.state.yearSelect + "",
      this.props.sortOrderInfo || "DATE_DESC",
      acceptType,
      selectedFields
    );
  };

  getInvoiceType = (row: any) => {
    const userData = localStorage.getItem("imt__user");
    const userParseData = JSON.parse(userData!);
    const gstRegistrationTypeId = userParseData.gstin.length > 0 ? 1 : 3;
    if (gstRegistrationTypeId === 1) {
      return "B2B";
    } else {
      if (row.state_code === 99) {
        return "EXP";
      } else if (userParseData.place_of_supply === row.state_code) {
        return "B2CS";
      } else {
        if (row.invoiceValue > 250000) {
          return "B2CL";
        } else {
          return "B2CS";
        }
      }
    }
  };

  render() {
    const summaryData = this.state.invoiceData;
    let { sortedInfo } = this.state;
    const { monthSelect, yearSelect } = this.state;
    const setOfCurYear = yearSelect.split("-");
    const xdate =
      monthSelect > 2
        ? new Date(setOfCurYear[0], monthSelect, 1)
        : new Date(setOfCurYear[1], monthSelect, 1);
    const selectedYearFromFy = getYear(xdate);
    sortedInfo = sortedInfo || {};
    const columnDef: Array<ColumnType<InvoiceAPIData>> = [
      {
        title: "Invoice no.",
        dataIndex: "invoice_number",
        key: "invoice_number",
        align: "left",
        sorter: true,
        sortOrder:
          sortedInfo.columnKey === "invoice_number" && sortedInfo.order,
        defaultSortOrder: "descend",
        width: "13%",
        render: (invoice_number, record) => {
          return (
            <span>
              {invoice_number && (
                <Tooltip title={invoice_number}>
                  <p className={styles.tableDataEllipsis}>
                    {this.highlightText(String(invoice_number))}
                  </p>
                </Tooltip>
              )}
              <div
                style={{
                  marginTop: "10px",
                  marginLeft: "-5px",
                }}
              >
                <FieldInfo
                  style={{
                    maxWidth: "98px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  color="#F07C2E"
                  text={record.amended ? "Amended" : ""}
                />
              </div>
            </span>
          );
        },
      },
      {
        title: "Customer name",
        dataIndex: "customer_name",
        key: "customer_name",
        align: "left",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "customer_name" && sortedInfo.order,
        ellipsis: true,
        render: (customer_name, record) => {
          return (
            <span>
              {customer_name && (
                <Tooltip title={customer_name}>
                  <p className={styles.tableDataEllipsis}>
                    {this.highlightText(customer_name)}
                  </p>
                </Tooltip>
              )}
              <div
                style={{
                  marginTop: "10px",
                  marginLeft: "-5px",
                }}
              >
                <FieldInfo
                  style={{
                    maxWidth: "98px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  text={record.gstin}
                />
              </div>
            </span>
          );
        },
      },

      {
        title: "Invoice type",
        dataIndex: "invoice_type",
        key: "invoice_type",
        sorter: true,
        align: "center",
        width: "12%",

        sortOrder: sortedInfo.columnKey === "invoice_type" && sortedInfo.order,
        // render: (invoiceId, row) => {
        //   return this.getInvoiceType(row);
        // },
      },

      {
        title: "Invoice date",
        dataIndex: "invoice_date",
        key: "invoice_date",
        sorter: true,
        align: "right",
        width: "12%",

        sortOrder: sortedInfo.columnKey === "invoice_date" && sortedInfo.order,
        render(invoice_date) {
          return formatDate(invoice_date);
        },
      },
      {
        title: "Due date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: true,
        align: "right",
        width: "12%",
        sortOrder: sortedInfo.columnKey === "due_date" && sortedInfo.order,
        render: (due_date, record) => {
          const isPaid = record.paid_flag === 1;
          return isPast(new Date(due_date)) && !isPaid ? (
            <span className={styles.pastDate}>{formatDate(due_date)}</span>
          ) : (
            <span>{formatDate(due_date)}</span>
          );
        },
      },

      {
        title: "Invoice amount",
        dataIndex: "balance_due",
        key: "balance_due",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "balance_due" && sortedInfo.order,
        align: "right",
        render: (balance_due, record) => {
          const {
            creditNoteAmt,
            debitNoteAmt,
            tds_amount,
            paid_amount,
          } = record;
          let totalTax = 0;
          // let netTotal = 0;

          if (Number(record.cgst) > 0) {
            totalTax = Number(record.cgst) + Number(record.sgst);
          } else {
            totalTax = Number(record.igst);
          }
          const taxAmount =
            record.symbol_name !== "INR"
              ? roundNumber(totalTax * +record.conversion_rate)
              : totalTax;

          let cess = Number((record as any).cess ?? 0);
          cess =
            record.symbol_name !== "INR"
              ? roundNumber(cess * +record.conversion_rate)
              : cess;
          totalTax += cess;

          // netTotal = record.calAmount + totalTax;
          const calAmtInRupee =
            record.symbol_name !== "INR"
              ? roundNumber(record.calAmount * +record.conversion_rate)
              : record.calAmount;
          return (
            <span>
              <div>
                <span style={{ whiteSpace: "nowrap" }}>
                  <Currency currencySymbol={record.customer_currency}>
                    {roundNumber(balance_due)}
                  </Currency>
                  {record.symbol_name !== "INR" ? (
                    <span>
                      {" "}
                      (
                      <Currency>
                        {roundNumber(+balance_due * +record.conversion_rate)}
                      </Currency>
                      )
                    </span>
                  ) : null}
                </span>
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <Tooltip
                  title={`₹${calAmtInRupee} (amount) + ₹${taxAmount} (tax) + ${cess} (cess) | Payment due breakup: ₹${
                    record.symbol_name !== "INR"
                      ? roundNumber(+balance_due * +record.conversion_rate)
                      : balance_due
                  } - ₹${
                    roundNumber(creditNoteAmt * +record.conversion_rate) ?? 0
                  }(credit note) + ₹${
                    roundNumber(debitNoteAmt * +record.conversion_rate) ?? 0
                  }(debit note) - ₹${
                    tds_amount * +record.conversion_rate ?? 0
                  }(tds) - ₹${paid_amount}(paid)`}
                >
                  <span style={{ whiteSpace: "nowrap" }}>
                    <FieldInfo
                      style={{
                        maxWidth: "98px",
                        float: "right",
                        color: "var(--grey-2)",
                      }}
                      text={
                        <Currency currencySymbol={"₹"}>{`${
                          Number(
                            record.symbol_name !== "INR"
                              ? +balance_due * +record.conversion_rate
                              : balance_due
                          ) -
                          Number(creditNoteAmt * +record.conversion_rate ?? 0) +
                          Number(debitNoteAmt * +record.conversion_rate ?? 0) -
                          Number(tds_amount * +record.conversion_rate ?? 0) -
                          Number(paid_amount)
                        }`}</Currency>
                      }
                    />
                  </span>
                </Tooltip>
              </div>
            </span>
          );
        },
      },

      {
        title:
          this.props.activeRole === LoginRole.ADMIN ||
          this.props.activeRole === LoginRole.SUPERuSER
            ? "Action"
            : "",
        dataIndex: "invoice_id",
        key: "invoice_id",
        align: "center",
        render: (invoice_id, record) => {
          const isPaid = record.paid_flag === 1;
          const menu = (
            <Menu>
              {this.props.activeRole === LoginRole.ADMIN ||
              this.props.activeRole === LoginRole.SUPERuSER ? (
                <Menu.Item
                  key="0"
                  onClick={this.handleDownload}
                  data-id={invoice_id}
                >
                  <span data-id={invoice_id}>Download</span>
                </Menu.Item>
              ) : null}

              {this.props.activeRole === LoginRole.ADMIN ||
              this.props.activeRole === LoginRole.SUPERuSER ? (
                <Menu.Item
                  key="0"
                  onClick={this.handleCopy}
                  data-id={invoice_id}
                >
                  <span data-id={invoice_id}>Copy</span>
                </Menu.Item>
              ) : null}

              {this.props.activeRole === LoginRole.ADMIN ||
              this.props.activeRole === LoginRole.SUPERuSER ? (
                <Menu.Item
                  key="1"
                  onClick={(info) => this.handleDelete(info, record)}
                  data-id={invoice_id}
                >
                  <span
                  // tslint:disable-next-line: jsx-no-lambda
                  >
                    Delete
                  </span>
                </Menu.Item>
              ) : null}
              <Menu.Item key="2">
                {this.props.activeRole === LoginRole.ADMIN ||
                this.props.activeRole === LoginRole.SUPERuSER ? (
                  <span
                    onClick={(e: any) => this.handleCreditNote(e, record)}
                    data-id={`${invoice_id}/${record.crId}`}
                  >
                    Add / View credit note
                  </span>
                ) : null}
              </Menu.Item>
              <Menu.Item key="3">
                {this.props.activeRole === LoginRole.ADMIN ||
                this.props.activeRole === LoginRole.SUPERuSER ? (
                  <span
                    onClick={(e: any) => this.handleDebitNote(e, record)}
                    data-id={`${invoice_id}/${
                      record.drId ? record.drId[0] : null
                    }`}
                  >
                    Add / View debit note
                  </span>
                ) : null}
              </Menu.Item>
            </Menu>
          );
          return this.props.activeRole === LoginRole.ADMIN ||
            this.props.activeRole === LoginRole.SUPERuSER ? (
            <div className={styles.actionInvoice}>
              <Tooltip placement="right" title={"Record Payment"}>
                <PaymentPending
                  status={isPaid ? "DONE" : "PENDING"}
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (isPaid) {
                      notification.success({ message: "Already paid" });
                      return;
                    }
                    this.setState({
                      payInvoiceModal: true,
                      selectInvoiceDetail: record,
                    });
                  }}
                />
              </Tooltip>
              <Space align="center">
                <Dropdown overlay={menu} trigger={["click"]}>
                  <span
                    className="ant-dropdown-link"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={(e) => e.stopPropagation()}
                  >
                    <MoreOutlined />
                  </span>
                </Dropdown>
              </Space>
            </div>
          ) : null;
        },
      },
    ];
    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>{titles.InvoicePage}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Sales - Invoice"]}
          style={{ paddingLeft: "0" }}
          actions={
            <>
              {this.props.activeRole === 1 ||
              this.props.activeRole === LoginRole.SUPERuSER ? (
                <>
                  <ReconcileFileUpload
                    curMonth={monthSelect}
                    curYear={selectedYearFromFy}
                    refreshData={this.refreshAfterReconcile}
                    selectedFy={yearSelect}
                    document="INVOICE"
                  />
                  <Button
                    type="primary"
                    onClick={this.handleAddNew}
                    style={{ display: "inline-block", marginLeft: "10px" }}
                  >
                    + Add new
                  </Button>
                </>
              ) : (
                ""
              )}
            </>
          }
        />
        <Row>
          <Col>
            <Input
              placeholder="Search in customer name / Invoice no"
              onChange={this.handleInputChange}
              value={this.state.searchItem}
              style={{
                minWidth: "300px",
                height: "32px",
                marginBottom: "5px",
                marginRight: "20px",
              }}
              allowClear
            />
          </Col>
          <Col style={{ marginLeft: "10px" }}>
            <YearMonthDropdown
              month={this.state.monthSelect}
              year={this.state.yearSelect}
              marginLeft={"0px"}
              payrollFlag={false}
            />
          </Col>
        </Row>

        <Row className={styles.summaryDiv}>
          {this.state.invoiceData ? (
            <SummaryTags
              nameValuePairs={[
                { name: "Count", value: summaryData.invoice_count },
                {
                  name: "Total taxable",
                  value: summaryData.taxable,
                  isCurrency: true,
                },
                {
                  name: "Total amount",
                  value: summaryData.invoice_amount,
                  isCurrency: true,
                },
                { name: "Invoice due", value: summaryData.due },
              ]}
            />
          ) : null}
        </Row>

        <Table
          rowKey="invoice_id"
          className={styles.invoiceTable}
          columns={columnDef}
          dataSource={this.state.rowData}
          loading={this.state.apiState === "loading"}
          onRow={this.handleRowEvent}
          onChange={this.handleTableChange}
          size="large"
          pagination={{
            size: "small",
            current: this.state.activePage,
            pageSize: this.state.noOfRows,
            showSizeChanger: false,
            onChange: this.handlePageChange,
            total: this.state.totalItemCount,
            position: ["topRight"],
            style: { margin: "0px" },
          }}
        />
        {this.props.activeRole !== LoginRole.EMPLOYEE &&
          this.state.rowData.length > 0 && (
            <ExportButton
              onDownload={this.handleExportDataDownload}
              onFetchData={this.handleFetchData}
              selectedList={[
                "Conversion Rate",
                "GST Rate ID",
                "Amount",
                "Compliance Status",
                "Amount paid",
                "Payment Due",
                "Calculated Tax",
                "Rate",
                "State Code",
                "Paid Flag",
                "Due Date",
                "Customer GSTIN ID",
                "Balance Due",
                "Symbol Name",
                "Quantity",
                "Calculted Amount",
                "Debit Note Amount",
                "Credit Note Amount",
                "Cess",
                "Cess Rate",
                "Customer Currency",
                "Total Tax",
                "Tax Inclusion",
                "Cess Rate ID",
                "Currency ID",
                "GST Compliance ID",
                "Customer ID",
                "Cess",
                "Cess Amount",
                "Tran ref number",
                "Payment date",
                "TDS Amount",
                "TDS Rate",
                "Product ID",
                "Is Active",
                "Customer Idx",
                "Bank name",
              ]}
            />
          )}
        {this.state.selectInvoiceDetail && (
          <PayInvoiceModal
            visible={this.state.payInvoiceModal}
            onVisibleChange={this.handleOnboardVisibleChange}
            selectInvoiceDetail={this.state.selectInvoiceDetail}
            fetchData={this.loadData}
            vendorPickList={this.state.vendorPickList}
          />
        )}
      </IMTContent>
    );
  }
}

export default connector(InvoicePage);
