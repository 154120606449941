import React from "react";
import { Tooltip } from "antd";


interface TagProps {
  tagSet?: any;
}
export default function IndicatorTag(props: TagProps) {
  return (
    <>
      {props.tagSet.map((el: any, idx: any) => {
        return (
          <>
            <Tooltip title={el.title && el.title}>
              <div
                style={{
                  height: "14px",
                  width: "20px",
                  backgroundColor: el.color,
                  borderRadius: "3px",
                  textAlign: "center",
                  fontSize: "9px",
                  color: "white",
                  fontWeight: 700,

                  marginLeft:
                    idx !== props.tagSet.lastIndexOf() ? "2px" : "0px",
                }}
              >
                {el.tagName}
              </div>
            </Tooltip>
          </>
        );
      })}
    </>
  );
}
