import * as React from "react";

const OtherFeature3 = (props: any) => (
  <svg
    width={54}
    height={55}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.246 6.43v38.762h7.59a.67.67 0 0 1 .664.676V55h27.801v-9.52c-5.365-.372-9.619-4.935-9.619-10.493 0-5.557 4.254-10.12 9.62-10.492V7.192A8.585 8.585 0 0 1 41.165 0H7.56C4.078 0 1.246 2.885 1.246 6.431Zm24.478 3.656H5.977c-.875 0-.875-3.353 0-3.353h19.747c.874 0 .875 3.353 0 3.353Zm0 8.734H5.977c-.875 0-.875-3.352 0-3.352h19.747c.874 0 .875 3.352 0 3.352Zm0 8.735H5.977c-.875 0-.875-3.352 0-3.352h19.747c.874 0 .875 3.352 0 3.352Zm0 8.735H5.977c-.874 0-.874-3.352 0-3.352h19.747c.874 0 .875 3.352 0 3.352Z"
        fill="#9B51E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.63 7.192v6.432h14.124V7.192C52.754 3.227 49.586 0 45.692 0S38.63 3.227 38.63 7.192ZM8.172 46.545H2.026l6.146 7.304v-7.304ZM38.008 44.378c-5.083 0-9.22-4.213-9.22-9.39 0-5.178 4.137-9.39 9.22-9.39 5.084 0 9.22 4.212 9.22 9.39 0 5.178-4.136 9.39-9.22 9.39Z"
        fill="#9B51E0"
      />
      <path
        d="M40.684 41c-.128 0-.277-.037-.448-.112a1.518 1.518 0 0 1-.496-.432c-.149-.203-.277-.485-.384-.848-.096-.363-.144-.827-.144-1.392 0-.341-.053-.624-.16-.848a1.463 1.463 0 0 0-.432-.544 1.598 1.598 0 0 0-.608-.288 2.962 2.962 0 0 0-.688-.08h-1.648a.7.7 0 0 1-.464-.16.607.607 0 0 1-.176-.448c0-.181.059-.325.176-.432a.7.7 0 0 1 .464-.16h2c.31-.021.598-.123.864-.304.278-.192.502-.453.672-.784.182-.341.272-.73.272-1.168 0-.565-.181-1.024-.544-1.376-.352-.363-.8-.544-1.344-.544h-2a.662.662 0 0 1-.464-.176.651.651 0 0 1-.192-.48c0-.181.064-.33.192-.448a.662.662 0 0 1 .464-.176h5.952c.182 0 .336.064.464.192a.581.581 0 0 1 .192.448.632.632 0 0 1-.192.464.662.662 0 0 1-.464.176H38.78l-.864-.576c.555 0 1.051.112 1.488.336.448.224.8.533 1.056.928.267.395.4.848.4 1.36 0 .405-.085.79-.256 1.152-.17.363-.4.683-.688.96a3.094 3.094 0 0 1-.944.624l-.368-.208c.32.085.619.24.896.464.288.213.523.49.704.832.182.33.278.71.288 1.136.022.395.048.71.08.944.032.235.08.421.144.56.064.128.155.23.272.304a.647.647 0 0 1 .24.24c.054.096.08.192.08.288 0 .17-.058.31-.176.416a.64.64 0 0 1-.448.16ZM38.7 33.544a.724.724 0 0 1-.48-.16.555.555 0 0 1-.176-.432c0-.17.059-.31.176-.416a.683.683 0 0 1 .48-.176h2.672c.192 0 .347.059.464.176a.536.536 0 0 1 .176.416.555.555 0 0 1-.176.432.663.663 0 0 1-.464.16H38.7Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h54v55H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default OtherFeature3;
