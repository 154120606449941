import { Input } from "antd";
import { values } from "lodash";
import React from "react";

interface Props {
  value?: string;
  readOnly?: boolean;
  onChange?: (value: string) => void;
  rowData?: any;
}

/**
 * Component used in Line Item table's Column
 */
const LICDescription = (props: Props) => {
  const handleChange = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
    props.onChange?.(e.currentTarget.value);
  };
  return (
    <Input.TextArea
      value={props.value}
      // style={{ minWidth: 150 }}
      style={
        window.location.pathname.includes("expense") &&
        props.rowData?.extra?.errors?.length > 0 &&
        !props.value
          ? { border: "0.5px solid red", minWidth: 150 }
          : { minWidth: 150 }
      }
      onChange={handleChange}
      readOnly={props.readOnly}
      rows={(props.value?.length ?? 0) > 30 ? 3 : 1}
    />
  );
};

export default LICDescription;
