import { Form, Input, notification, Spin, Button, Divider } from "antd";
import { RcFile } from "antd/lib/upload";
import React, { useCallback, useEffect, useState } from "react";
import { RouteChildrenProps } from "react-router";
import {
  getExpenseByID,
  getCurrency,
  getPaymentDetais,
  editExpenseDetails,
  saveNewExpense,
  getPurDebitNoteByID,
  getPurDebitNoteByExpenseID,
  saveNewPurchesDebitNote,
  editPurchesDetails,
} from "~/api/expense";
import { Expense } from "~/feature/expense/ExpenseModal";
import commonStyle from "~/component/common.module.css";
import DatePicker from "~/component/antd-overrides/DatePicker";
import { Required /*required*/ } from "~/lib/form_rules";
import { isFuture } from "date-fns";
import { Col, Row, Modal } from "antd";
import { FORMAT_DATE, roundNumber, formatDate } from "~/lib";
import { ExclamationCircleOutlined } from "@ant-design/icons";
// import AttachFieldWithView from "~/fragment/attachable-field/AttachFieldWithView";
import IMTContent from "~/layout/main-layout/IMTContent";
import styles from "~/feature/expense/expense-detail/ExpenseDetailsPage.module.css";
import PurchesDebitDetailsHeader from "./PurchesDebitDetailsHeader";
import useCqdForm from "~/lib/hook/useCqdForm";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { fetchUtil } from "~/api/common";
import PaymentDialog from "../modal/PaymentDialog";
import TdsPayment from "~/component/TdsPayment";
import PurchesLineItem, {
  SKELETON_ROW,
  validateColumns,
} from "./purches-line-item/PurchesLineItem";
import CategorySelect from "~/fragment/form-input/CategorySelect";
import VendorSelect from "~/fragment/form-input/VendorSelect";
import OcrSelect, { OCRData } from "~/fragment/form-input/OcrSelect";
import PlaceOfSupply from "~/fragment/form-input/PlaceOfSupply";
import AmountsAreSelect from "~/fragment/form-input/AmountsAreSelect";
import ApplyCess from "~/fragment/form-input/ApplyCess";

import { OTHER_TERITORY_ID } from "~/lib/constants";
import {
  DeletedPurchesLineItemRecord,
  PurchesLineItemRecord,
} from "./purches-line-item/PurchesLineItemTypes";
import { TaxTeritoryType, TAX_INCLUSION } from "~/lib/taxes";
import { getTotalGST } from "~/lib/taxCalculation";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";
import { useGSTRateList } from "~/lib/hook/api-hook/picklistHooks";
import { registerEvent } from "~/analytics";
import FieldInfo from "~/component/FieldInfo";

import * as ga from "~/contants/gaConstants";

type PathParams = {
  id?: string;
  drId?: string;
  slug?: "new" | "copy";
};

type LocationState = {
  data?: Expense;
  fileList?: Array<RcFile>;
};

/**
 * CONSTANTS
 */
const DEFAULT_TAX_INCLUSION: TAX_INCLUSION = "ExclusiveOfTax";

const mapStateToProps = (state: AuthState) => ({
  place_of_supply_id: state.mainAppSlice.user.place_of_supply,
  activeRole: state.mainAppSlice.user.activeRole,
  baseCompanyGstin: state.mainAppSlice.user.gstin,
});

/**
 * Connect to store
 */

const connector = connect(mapStateToProps);

const getCurrencyConversionList = async (
  symbol_name: string | undefined,
  receipt_date: Date
) => {
  const { ok, message, data } = await getCurrency(symbol_name!, receipt_date);
  if (ok) {
    return data!;
  } else {
    notification.error({ message });
  }
};
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props
  extends RouteChildrenProps<PathParams, LocationState>,
    PropsFromRedux {}

const PurchesDebitDetail = (props: Props) => {
  const { TextArea } = Input;
  const expenseId = props.match?.params.id ? +props.match.params.id : null;
  const drId = props.match?.params.drId ? props.match?.params.drId : null;
  const setMonth = props.history.location.state as any;
  const setMonth2 = props.history.location as any;
  const [normalInfo, setInfo] = useState({
    isNew: true,
    editMode: false,
    apiState: "idle",
    rippleEffect: false,
    payDlgVisible: false,
    isFormDataLoading: false,
    tdsDlgVisible: false,
    tdsEditFlag: false,
    tdsInfo: {},
  });
  const [expenseData, setExpenseData] = useState<Expense>();
  const [deletedRows, setDeletedRows] = useState<
    Array<DeletedPurchesLineItemRecord>
  >([]);

  const { prevData, data, update, resetDirtyFlag, formProps } = useCqdForm<
    Expense
  >();
  const { isNew } = normalInfo;
  const loadExpenseData = useCallback(
    async (_expenseId: number) => {
      resetDirtyFlag();
      setInfo((oldInfo) => ({
        ...oldInfo,
        isFormDataLoading: true,
        apiState: "loading",
      }));
      try {
        const { ok, data: expenseData, message } = await getExpenseByID(
          _expenseId,
          props.match?.params.slug === "copy" ? true : false
        );
        if (!ok) {
          notification.error({
            message: "Failed to load details",
            description: message,
          });
        } else {
          setExpenseData({
            ...(expenseData as Expense),
            extra: {
              ...expenseData?.extra,
              apiData: {
                place_of_supply: expenseData!.place_of_supply!,
              },
              vendor: {
                __init__: {
                  customer_idx: expenseData?.customer_idx,
                  uid: Math.random(),
                },
              } as any,
              hasCess: !!Number(expenseData?.cess_amount ?? 0),
            },
          });
        }
      } catch (error) {
        console.warn(error);
        notification.warn({ message: "Unable to load expense!" });
      } finally {
        setInfo((oldInfo) => ({
          ...oldInfo,
          isFormDataLoading: false,
          apiState: "idle",
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update, props.match?.params.slug]
  );

  const loadCreditNote = useCallback(async (_purDebitNoteId: number) => {
    resetDirtyFlag();
    setInfo((oldInfo) => ({
      ...oldInfo,
      apiState: "loading",
    }));
    try {
      const { ok, data: purDebitNotedata, message } = await getPurDebitNoteByID(
        _purDebitNoteId
      );

      if (!ok) {
        notification.error({
          message: "Failed to load credit note details",
          description: message,
        });
      } else {
        if (purDebitNotedata) {
          setInfo((normalInfo) => ({
            ...normalInfo,
            isNew: false,
            editMode: false,
          }));
          const formattedData = {
            ...purDebitNotedata,
            pd_row_data: purDebitNotedata.pd_row_data,
            // credit_note_row_data: purDebitNotedata.credit_note_items,
          };

          // Deleting the formatted key
          // delete formattedData.pd_row_data;
          delete formattedData.tax_inclusion;

          update({
            ...formattedData,
          });
          setExpenseData({
            ...(purDebitNotedata as Expense),
            place_of_supply: purDebitNotedata!.place_of_supply_id!,
            // description: salerDebitNotedata.sd_row_data.description,
            // receipt_date: purDebitNotedata.expense_date,
            receipt_date: purDebitNotedata.expense_date,
            extra: {
              ...expenseData?.extra,
              apiData: {
                place_of_supply: purDebitNotedata!.place_of_supply_id!,
              },

              vendor: {
                __init__: {
                  customer_idx: purDebitNotedata?.customer_idx,
                  uid: Math.random(),
                },
              } as any,

              hasCess: !!Number(purDebitNotedata?.cess_amount ?? 0),
            },
          });
        } else {
          setInfo((normalInfo) => ({
            ...normalInfo,
            isNew: true,
            editMode: true,
          }));
        }
      }
    } catch (error) {
      console.warn(error);
      notification.warn({ message: "Unable to load purches debit note!" });
    } finally {
      setInfo((oldInfo) => ({
        ...oldInfo,
        isFormDataLoading: false,
        apiState: "idle",
      }));
    }
  }, []);

  const loadCreditNoteByExpenseId = useCallback(
    async (_purDebitNoteId: number) => {
      resetDirtyFlag();
      setInfo((oldInfo) => ({
        ...oldInfo,
        apiState: "loading",
      }));
      try {
        const {
          ok,
          data: purDebitNotedata,
          message,
        } = await getPurDebitNoteByExpenseID(Number(expenseId));

        if (!ok) {
          notification.error({
            message: "Failed to load credit note details",
            description: message,
          });
        } else {
          if (purDebitNotedata) {
            setInfo((normalInfo) => ({
              ...normalInfo,
              isNew: false,
              editMode: false,
            }));
            const formattedData = {
              ...purDebitNotedata,
              pd_row_data: purDebitNotedata.pd_row_data,
              // credit_note_row_data: purDebitNotedata.credit_note_items,
            };

            // Deleting the formatted key
            // delete formattedData.pd_row_data;
            delete formattedData.tax_inclusion;

            update({
              ...formattedData,
            });
            setExpenseData({
              ...(purDebitNotedata as Expense),
              place_of_supply: purDebitNotedata!.place_of_supply_id!,
              // description: salerDebitNotedata.sd_row_data.description,
              receipt_date: purDebitNotedata.expense_date,
              extra: {
                ...expenseData?.extra,
                apiData: {
                  place_of_supply: purDebitNotedata!.place_of_supply_id!,
                },

                vendor: {
                  __init__: {
                    customer_idx: purDebitNotedata?.customer_idx,
                    uid: Math.random(),
                  },
                } as any,

                hasCess: !!Number(purDebitNotedata?.cess_amount ?? 0),
              },
            });
          } else {
            setInfo((normalInfo) => ({
              ...normalInfo,
              isNew: true,
              editMode: true,
            }));
          }
        }
      } catch (error) {
        console.warn(error);
        notification.warn({ message: "Unable to load purches debit note!" });
      } finally {
        setInfo((oldInfo) => ({
          ...oldInfo,
          isFormDataLoading: false,
          apiState: "idle",
        }));
      }
    },
    []
  );

  useEffect(() => {
    // If new creditnote then using invoice details as credit note and changing the key names
    if (normalInfo.isNew) {
      delete (expenseData as any)?.documents;
      delete expenseData?.document_name;
      const initialCrediNoteData = {
        ...(expenseData as Expense),
        pd_row_data: expenseData?.row_data.map((rowItem) => {
          const newItem = {
            ...rowItem,
            // invoice_item_id: rowItem.row_id,
          };
          // delete newItem.row_details_id;
          // delete newItem.product_unit_id;
          return newItem;
        }),
        id: expenseData?.id,

        p_debit_note_number: "",
        p_debit_note_date: "",
        // credit_note_id?: number;
        user_comment: "",
      };
      // if(invoiceData?.row_data) {
      // delete initialCrediNoteData.row_data;
      // }

      update({
        ...initialCrediNoteData,
      });
    } else {
      // If viewing / editing creditnote
      update({
        tax_inclusion: expenseData?.tax_inclusion,
        place_of_supply: expenseData?.place_of_supply,
        receipt_date: expenseData?.receipt_date,
        id: expenseData?.id,
        invoice_no: expenseData?.invoice_no,
        description: expenseData?.description,
        category: expenseData?.category,
        category_id: expenseData?.category_id,
        extra: {
          ...expenseData?.extra,
          apiData: {
            place_of_supply: expenseData!.place_of_supply!,
          },
        },
        // exp: expenseData?.invoice_number,
        // documents:expenseData?.documents
        // paid_flag: invoiceData?.paid_flag,
        // term: invoiceData?.term,
        // invoice_date: invoiceData?.invoice_date,
        // due_date: invoiceData?.due_date,
        // state_code: invoiceData?.state_code,
        // extra: {
        //   ...expenseData?.extra,
        //   apiData: {
        //     place_of_supply: expenseData!.place_of_supply!,
        //   },
        // vendor: {
        //   __init__: {
        //     customer_idx: expenseData?.customer_idx,
        //     uid: Math.random(),
        //   },
        // } as any,
        //   hasCess: !!Number(expenseData?.cess_amount ?? 0),
        // },
      });

      // taxTeritoryType: taxTeritoryType ?? TaxTeritoryType.OTHER_TERITORY;
    }
  }, [expenseData]);

  useEffect(() => {
    // console.log("copy effect hit");
    // Manually parsing the query params as react-router v4 dosen't support auto parse
    // const fromId = new URLSearchParams(props.location.search).get("from") || "";
    setInfo((prevInfo) => ({
      ...prevInfo,
      isNew: drId ? false : true,
    }));
    if (expenseId) {
      if (props.match?.params.slug === "new") {
        loadExpenseData(expenseId);
      } else {
        loadCreditNoteByExpenseId(expenseId);
      }
      // console.log("load drid")
    } else {
      if (drId) {
        loadCreditNote(+drId);
      }
    }
    // else if (props.match?.params.slug === "copy" && fromId) {
    //   loadInvoiceData(+fromId);
    // }
  }, [expenseId, loadExpenseData, props.location.search, props.match]);

  useEffect(() => {
    if (isNew) {
      setInfo((oldInfo) => ({
        ...oldInfo,
        editMode: true,
      }));
    }
  }, [isNew]);

  useEffect(() => {
    if (data.extra?.vendor?.customer_idx) {
      getCurrencyConversionList(
        data.extra.vendor.symbol_name,
        data.receipt_date
      ).then((res) => {
        update((oldData) => {
          return {
            extra: {
              ...oldData.extra,
              convertionRate: res[0].import_rate,
            },
          };
        });
      });
    }
  }, [data.receipt_date, update]);

  useEffect(() => {
    if (
      data.place_of_supply === OTHER_TERITORY_ID ||
      (data.extra?.vendor?.gstin === null &&
        !(prevData.extra?.vendor as any)?.__init__)
    ) {
      update((oldData) => {
        return {
          tax_inclusion: "OutOfTax",
          extra: {
            ...oldData.extra,
            hasCess: false,
          },
        };
      });
    }
    // else if(data.place_of_supply){

    // }
    else if (
      // prevData.tax_inclusion !== "OutOfTax" &&
      data.place_of_supply !== OTHER_TERITORY_ID &&
      data.extra?.vendor?.gstin &&
      data.extra?.vendor?.customer_id !== data.customer_id &&
      !isNew
    ) {
      update((oldData) => {
        return {
          tax_inclusion: DEFAULT_TAX_INCLUSION,
          extra: {
            // ...oldData.extra,
            hasCess: false,
          },
        };
      });
    }

    if (!isNew && props.match?.params.slug === "copy") {
      update(() => {
        const taxInclusion =
          data.place_of_supply === OTHER_TERITORY_ID ||
          data.extra?.vendor?.gstin === "" ||
          data.extra?.vendor?.gstin === null ||
          (prevData.extra?.vendor?.customer_id
            ? prevData.extra?.vendor?.customer_id === data.customer_id
            : false)
            ? "OutOfTax"
            : expenseData?.tax_inclusion!;

        return {
          tax_inclusion: taxInclusion,
        };
      });
    } else if (isNew && !expenseId) {
      update(() => {
        const taxInclusion =
          data.place_of_supply === OTHER_TERITORY_ID ||
          data.extra?.vendor?.gstin === "" ||
          data.extra?.vendor?.gstin === null ||
          (prevData.extra?.vendor?.customer_id
            ? prevData.extra?.vendor?.customer_id === data.customer_id
            : false)
            ? "OutOfTax"
            : DEFAULT_TAX_INCLUSION;

        return {
          tax_inclusion: taxInclusion,
        };
      });
    } else {
      update((oldData) => {
        const taxInclusion = expenseData?.tax_inclusion!;
        return {
          tax_inclusion: taxInclusion,
        };
      });
    }

    // We are actually not dependent on data.extra , so disabling eslint deps check, unless we introduces callback style update function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, data.place_of_supply, data.extra?.vendor?.customer_idx]);

  /**
   * Side-Effect: mark hasCess=false when tax_inclusion changes to "OutOfTax"
   */
  useEffect(() => {
    // console.log(
    //   "Side-Effect: mark hasCess=false",
    //   data.tax_inclusion,
    //   prevData.extra?.hasCess,
    //   data.extra?.hasCess
    // );
    if (data.tax_inclusion === "OutOfTax") {
      // console.log("updating hasCess: false");
      update((oldData) => ({
        extra: {
          ...oldData.extra,
          hasCess: false,
        },
      }));
    }
  }, [update, data.tax_inclusion]);

  useEffect(() => {
    const doWork = async () => {
      if (data.customer_id === data.extra?.vendor?.customer_id) {
        // CASE: vendor from expense_details API & dropdown's are equal
        // then
        // SUB-CASE 1: if vendor is modified take modified vendor's place_of_supply
        // SUB-CASE 2: else take place_of_supply from expense_details API
        update({
          place_of_supply:
            prevData.extra?.vendor?.customer_idx &&
            prevData.extra?.vendor?.customer_idx !==
              data.extra?.vendor?.customer_idx
              ? data.extra?.vendor?.state_code
              : data.extra?.apiData?.place_of_supply,
        });
      } else {
        update({
          place_of_supply: data.extra?.vendor?.state_code,
        });
      }

      const convertionList = await getCurrencyConversionList(
        data.extra?.vendor?.symbol_name,
        data.receipt_date
      );
      if (data.extra?.vendor?.symbol_name) {
        const selectedConvertionRate = convertionList[0]!.import_rate;

        update((oldData) => ({
          extra: {
            ...oldData.extra,
            convertionRate:
              expenseId &&
              data.customer_idx === data.extra?.vendor?.customer_idx
                ? data.conversion_rate
                : selectedConvertionRate,
          },
          conversion_rate:
            expenseId && props.match?.params.slug !== "copy"
              ? data.conversion_rate
              : selectedConvertionRate,
        }));
      }
    };

    if (
      prevData.extra?.vendor !== data.extra?.vendor &&
      data.extra?.vendor?.state_code
    ) {
      doWork();
    }
    // We are actually not dependent on data.extra , so disabling eslint deps check, unless we introduces callback style update function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, data.extra?.vendor?.customer_id]);

  // useEffect(() => {
  //   if (expenseId && !normalInfo.tdsEditFlag) {
  //     loadExpenseData(expenseId);
  //   } else if (props.match?.params.slug === "copy") {
  //     const urlQ = new URLSearchParams(props.location.search);
  //     const fromId = +(urlQ.get("from") ? urlQ.get("from")! : "");
  //     if (Number.isFinite(fromId)) {
  //       loadExpenseData(fromId);
  //     }
  //   } else {
  //     // reset the data
  //   }
  // }, [expenseId, props.match, props.location.search, loadExpenseData]);

  const handleEdit = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.purchesDebitDetail100
    );
    setInfo((oldInfo) => ({
      ...oldInfo,
      editMode: true,
    }));
  };

  const handleCancelEdit = () => {
    if (isNew) {
      props.history.goBack();
    } else {
      window.location.reload();
    }
  };

  const handleRowDataChange = usePersistantCallbackRef(
    (
      newRowData: Array<PurchesLineItemRecord>,
      deletedRowData?: PurchesLineItemRecord
    ) => {
      update({
        pd_row_data: newRowData,
      });
      if (deletedRowData) {
        setDeletedRows((oldRows) => [
          ...oldRows,
          {
            ...deletedRowData,
            is_active: false,
          },
        ]);
      }
    }
  );

  const handlePayDlg = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.purchesDebitDetail101
    );
    const { paymentStatus } = data;

    if (paymentStatus === "Completed") {
      return notification.success({ message: "Already paid" });
    }

    setInfo((oldInfo) => ({
      ...oldInfo,
      payDlgVisible: true,
    }));
  };

  ///////////////////////////////////////////////
  ///////////////////////////////////////////////
  ///////////////////////////////////////////////
  ///////////////////////////////////////////////
  ///////////////////////////////////////////////
  ///////////////////////////////////////////////
  // We derive various values here

  const taxTeritoryType =
    data.place_of_supply === OTHER_TERITORY_ID
      ? TaxTeritoryType.OTHER_TERITORY
      : data.place_of_supply === props.place_of_supply_id
      ? TaxTeritoryType.SAME_STATE
      : TaxTeritoryType.INTRA_STATE;
  const isEditing = !!normalInfo.editMode;
  const isLoading = normalInfo.apiState !== "idle";
  const closeNotification = (newExpId: any) => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      rippleEffect: true,
    }));
    props.history.replace(
      `/app/expense/${data.id}/purchasedebitNote/${newExpId}`
    );
  };

  const handleTdsPayDlgFromAsk = (target: HTMLButtonElement) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.purchesDebitDetail102
    );

    setInfo((oldInfo) => ({
      ...oldInfo,
      tdsDlgVisible: true,
    }));
  };

  const askForPayment = (newExpId: number) => {
    const key = `open${Date.now()}`;
    const close = closeNotification(newExpId) as any;
    const btn = (
      <Button
        type="primary"
        size="small"
        // tslint:disable-next-line: jsx-no-lambda
        onClick={(e: React.MouseEvent) => {
          registerEvent(
            ga.EVENT_CATEGORY_BUTTON_CLICK,
            ga.EVENT_CLICK,
            ga.events.purchesDebitDetail103
          );
          notification.close(key);
          const expLoadPromise = loadCreditNote(newExpId);
          const target = e.target;
          expLoadPromise.then(() => {
            handleTdsPayDlgFromAsk(target as HTMLButtonElement);
          });
        }}
      >
        Apply now
      </Button>
    );
    notification.open({
      duration: 200000,
      message: `Apply TDS? (You can change it later while payment logging)`,
      description: "",
      btn,
      key,
      onClose: close,
    });
  };

  const askForTdsPayment = (newExpId: number, newData: Expense) => {
    const key = `open${Date.now()}`;
    const btn = (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "5px" }}
            // tslint:disable-next-line: jsx-no-lambda
            onClick={(e: React.MouseEvent) => {
              registerEvent(
                ga.EVENT_CATEGORY_BUTTON_CLICK,
                ga.EVENT_CLICK,
                ga.events.purchesDebitDetail104
              );
              notification.close(key);
              // const expLoadPromise = loadExpenseData(newExpId);
              const target = e.target;
              // expLoadPromise.then(() => {
              handleTdsPayDlgFromAsk(target as HTMLButtonElement);
              // });
            }}
          >
            Update TDS rate
          </Button>
          <Button
            type="ghost"
            size="small"
            onClick={() => {
              notification.close(key);
              handleEditAction(newData);
            }}
          >
            {`Keep existing rate ( ${data.tds_rate ? data.tds_rate : 0}% )`}
          </Button>
        </div>
      </>
    );
    notification.open({
      duration: 100000,
      message: "Do you wish to update the TDS rate too?",
      description: "",
      btn,
      key,
      onClose: () => {
        notification.close(key);
        handleEditAction(newData);
      },
    });
  };

  const handleSave = usePersistantCallbackRef(async () => {
    try {
      await formProps.form.validateFields();

      const newRowData = [...data.pd_row_data!, ...deletedRows];
      newRowData.forEach(validateColumns);
      const hasRowDataError = newRowData.some(
        (it: any) => it.extra?.errors.length > 0
      );
      // FIXME: Dont set deletedRows to row_data, as they will be shown in the table again.
      handleRowDataChange(newRowData);
      if (hasRowDataError) {
        notification.warning({
          message: "Please fill all required fields for the line items !",
        });
        return;
      }
      const newData: Expense = {
        ...data,
        pd_row_data: newRowData,
      };
      newData.customer_idx = data.extra?.vendor?.customer_idx;
      newData.customer_id = data.extra?.vendor?.customer_id;
      newData.expense_id = expenseId;
      newData.customer_currency = data.extra?.vendor?.symbol;
      newData.symbol_name = data.extra?.vendor?.symbol_name;
      newData.customer_gstin_id = data.extra?.vendor?.customer_gstin_id!;
      newData.conversion_rate = data.extra?.convertionRate;
      newData.place_of_supply_id = data.place_of_supply;
      // newData.total_pay_amount= +data.net_total;
      const taxes = getTotalGST(
        newData.pd_row_data!.map((row: any) => {
          return {
            amount: row.is_active ? row.amount : 0,
            gstPercentage: row.is_active ? row.extra?.gstRate?.rate ?? 0 : 0,
            cessPercentage: row.is_active
              ? row.extra?.cessRate?.cessPercentage ?? 0
              : 0,
          };
        }),
        {
          taxInclusion: newData.tax_inclusion ?? "OutOfTax",
          taxTeritoryType: taxTeritoryType ?? TaxTeritoryType.OTHER_TERITORY,
        }
      );
      newData.subTotal = taxes.subTotal;
      newData.cgst = taxes.cgst;
      newData.igst = taxes.igst;
      newData.sgst = taxes.sgst;
      newData.amount = taxes.total;
      newData.cess_amount = taxes.cess;

      delete newData.extra;
      if (isNew) {
        setInfo((oldInfo) => ({
          ...oldInfo,
          isFormDataLoading: true,
        }));
        const { ok, data, message } = await saveNewPurchesDebitNote(newData);
        if (!ok) {
          notification.error({
            message,
          });
          setInfo((oldInfo) => ({
            ...oldInfo,
            isFormDataLoading: false,
          }));
        } else {
          setInfo((oldInfo) => ({
            ...oldInfo,
            editMode: false,
            isFormDataLoading: false,
          }));
          notification.success({
            message: "Supplier credit note created successfully",
            duration: 1,
          });
          resetDirtyFlag();
          setInfo((oldInfo) => ({
            ...oldInfo,
            rippleEffect: true,
          }));
          if (expenseId) {
            props.history.replace(
              `/app/expense/${expenseId}/purchasedebitNote/${
                data!.p_debit_note_id
              }`
            );
          } else {
            props.history.goBack();
            // props.history.push(`app/expense/purcheserdebitNote`)
          }

          // setTimeout(() => {
          //   askForPayment(data!.p_debit_note_id);
          // }, 2000);
        }
      } else {
        resetDirtyFlag();
        setInfo((oldInfo) => ({
          ...oldInfo,
          tdsEditFlag: true,
          isFormDataLoading: true,
        }));
        handleEditAction(newData);
        // askForTdsPayment(expenseId!, newData);
      }
    } catch (error) {
      console.warn("Form validation failed", error);
    }
  });

  const handleEditAction = async (newData: Expense) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.purchesDebitDetail105
    );
    newData.p_debit_note_id = +drId!;
    delete newData.place_of_supply;
    try {
      setInfo((oldInfo) => ({
        ...oldInfo,
        isFormDataLoading: true,
      }));
      const { ok, message } = await editPurchesDetails(expenseId!, newData);
      if (!ok) {
        notification.error({
          message,
        });
        setInfo((oldInfo) => ({
          ...oldInfo,
          isFormDataLoading: false,
        }));
      } else {
        setInfo((oldInfo) => ({
          ...oldInfo,
          editMode: false,
          isFormDataLoading: false,
        }));
        notification.success({
          message,
        });

        loadCreditNote(+drId!);
      }
    } catch (error) {
      console.warn("Form validation failed", error);
    }
  };

  const handleEdittds = async (tdsPayDetail: any) => {
    try {
      await formProps.form.validateFields();
      const newRowData = [...data.pd_row_data!, ...deletedRows];
      newRowData.forEach(validateColumns);
      const newData: Expense = {
        ...data,
        pd_row_data: newRowData,
      };
      newData.customer_idx = data.extra?.vendor?.customer_idx;
      newData.customer_id = data.extra?.vendor?.customer_id;
      newData.customer_currency = data.extra?.vendor?.symbol;
      newData.symbol_name = data.extra?.vendor?.symbol_name;
      newData.customer_gstin_id = data.extra?.vendor?.customer_gstin_id!;
      newData.conversion_rate = data.extra?.convertionRate;
      newData.tds_amount = tdsPayDetail.tds_amount;
      newData.tds_rate = tdsPayDetail.tds_rate;
      // newData.total_pay_amount= +data.net_total;
      const taxes = getTotalGST(
        newData.pd_row_data!.map((row: any) => {
          return {
            amount: row.is_active ? row.amount : 0,
            gstPercentage: row.is_active ? row.extra?.gstRate?.rate ?? 0 : 0,
            cessPercentage: row.is_active
              ? row.extra?.cessRate?.cessPercentage ?? 0
              : 0,
          };
        }),
        {
          taxInclusion: newData.tax_inclusion ?? "OutOfTax",
          taxTeritoryType: taxTeritoryType ?? TaxTeritoryType.OTHER_TERITORY,
        }
      );
      newData.subTotal = taxes.subTotal;
      newData.cgst = taxes.cgst;
      newData.igst = taxes.igst;
      newData.sgst = taxes.sgst;
      newData.amount = taxes.total;
      newData.cess_amount = taxes.cess;

      delete newData.extra;

      const { ok, message } = await editExpenseDetails(expenseId!, newData);
      if (!ok) {
        notification.error({
          message,
        });
        setInfo((oldInfo) => ({
          ...oldInfo,
          tdsDlgVisible: false,
        }));
      } else {
        setInfo((oldInfo) => ({
          ...oldInfo,
          editMode: false,
          tdsDlgVisible: false,
        }));
        notification.success({
          message,
        });
        loadExpenseData(data.id);

        // props.history.goBack();
      }

      resetDirtyFlag();
    } catch (error) {
      console.warn("Form validation failed", error);
    }
  };

  const handleAfterPayment = () => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      apiState: "loading",
    }));
    loadExpenseData(data.id);
  };

  const handlePayDlgVisibleChange = (newVState: boolean) => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      payDlgVisible: false,
    }));
  };

  const handleTdsPayDlgVisibleChange = (newVState: boolean) => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      tdsDlgVisible: false,
    }));
    if (!normalInfo.tdsEditFlag) {
      loadExpenseData(data.id);
    } else {
      handleEdittds(data);
    }
  };

  const handleDelete = () => {
    const title =
      data.compliance_status === "Complete" &&
      data.paymentStatus === "Completed"
        ? `Do you really wish to delete this expense? 
        Note: Payment for this expense is already logged and one or more compliances are completed.`
        : data.paymentStatus === "Completed"
        ? `Do you wish to delete this expense? 
              Note: Payment for this expense is already logged`
        : `Do you wish to delete this expense?`;
    Modal.confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        const { ok, message } = await fetchUtil(
          "POST",
          "/delete_pur_debit_note",
          {
            p_debit_note_id: data.id,
          }
        );
        if (!ok) {
          notification.warn({ message });
        } else {
          notification.success({ message });
          resetDirtyFlag();
          props.history.push(
            `/app/expense/purchasedebitNote${
              setMonth?.search
                ? setMonth?.search
                : setMonth2?.search
                ? setMonth2?.search
                : ""
            }`
          );
        }
      },
    });
  };

  const handleRipple = () => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      rippleEffect: true,
    }));
  };

  const handleOCRLoading = (isLoading: boolean) => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      isFormDataLoading: isLoading,
    }));
  };

  const handleOcrDelete = () => {
    const ocrDeletedRow: any = data.pd_row_data;

    if (data.pd_row_data) {
      ocrDeletedRow.map((el: any) => (el.is_active = false));
      setDeletedRows(ocrDeletedRow);
    }
    update({
      documents: "",
      document_name: "",
      pd_row_data: [
        {
          ...SKELETON_ROW,
        } as any,
      ],
    });
  };

  const { gstRateList } = useGSTRateList();
  const handleOcrData = (ocrData: OCRData) => {
    /**
     * TODO: use a separate modal component, & use that component
     */
    setInfo((oldInfo) => ({
      ...oldInfo,
      apiState: "loading",
    }));

    const rate = gstRateList.find((el) => el.rate === ocrData.taxRate);
    update({
      // receipt_date: ocrData.receipt_date,
      // invoice_no: ocrData.invoice_no,

      // TODO: creation of new lineItem by using SKELETON_ROW is used in multiple places
      // its time to create a function which will do this & return the new row
      pd_row_data: [
        {
          ...SKELETON_ROW,
          row_details_id: Math.random(),
          quantity: ocrData.amount ? 1 : 0,
          rate: ocrData.amount,
          gst_rate_id: rate?.id ? rate.id : 1,
          hsn_no: ocrData.hsn,
          extra: {
            errors: [],
          },
        } as any,
      ],
      p_debit_note_number: ocrData.creditDebitNo ? ocrData.creditDebitNo : "",
      p_debit_note_date: ocrData.receipt_date
        ? ocrData.receipt_date
        : new Date(),
      // extra: {
      //   ...data.extra,
      //   vendor: {
      //     __init__: {
      //       gstin: ocrData.gstin,
      //       uid: Math.random(),
      //     },
      //   } as any,
      // },
    });

    setInfo((oldInfo) => ({
      ...oldInfo,
      apiState: "idle",
    }));
  };

  const handleOcrSelectedVendorDetail = (vendor: any) => {
    if (vendor.state_code === OTHER_TERITORY_ID) {
      update({
        tax_inclusion: "OutOfTax",
      });
    } else {
      update({
        tax_inclusion: "ExclusiveOfTax",
      });
    }
  };

  const disabledDate = (current: Date) => {
    const year = new Date().getFullYear();
    return (
      current.getTime() < new Date(`${year - 1}-04-01`).getTime() ||
      current.getTime() > new Date(`${year + 1}-04-01`).getTime()
    );
  };

  const renderUpperForm = () => {
    const dateView = !editMode ? null : (
      <Form.Item className={commonStyle["w-100"]} label="Date">
        <input
          className="ant-input"
          defaultValue={data.receipt_date ? formatDate(data.receipt_date) : ""}
          readOnly
        />
      </Form.Item>
    );
    const debitdateView = editMode ? null : (
      <Form.Item className={commonStyle["w-100"]} label="Debit note date">
        <input
          className="ant-input"
          defaultValue={
            data.p_debit_note_date
              ? formatDate(new Date(data.p_debit_note_date))
              : ""
          }
          readOnly
        />
      </Form.Item>
    );
    return (
      <>
        <Row style={{ width: "100%" }} gutter={{ md: 8, lg: 8, xl: 16 }}>
          <Col style={{ width: "70%" }}>
            <Row style={{ width: "100%" }} gutter={{ md: 16, lg: 16, xl: 30 }}>
              <Col md={12} lg={12} xl={10} xxl={10}>
                <Row gutter={{ md: 8, lg: 16 }}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      // className={commonStyle["w-100"]}
                      name="p_debit_note_number"
                      label="Debit note number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter supplier credit note number",
                        },
                      ]}
                    >
                      <Input
                        placeholder={"Unique supplier credit note number"}
                        readOnly={!editMode}
                        autoFocus
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    {debitdateView || (
                      <Form.Item
                        // className={commonStyle["w-100"]}
                        name="p_debit_note_date"
                        label="Debit note date"
                        rules={Required("Debit note date")}
                      >
                        <DatePicker
                          format={FORMAT_DATE}
                          defaultValue={new Date()}
                          disabled={!editMode}
                          disabledDate={isFuture}
                          inputReadOnly={!editMode}
                          className={commonStyle["w-100"]}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Form.Item
                  name="user_comment"
                  label="Comment"
                  rules={[
                    {
                      type: "string",
                      required: false,
                      message: "Please enter comment!",
                    },
                  ]}
                  required={false}
                >
                  <TextArea required={editMode} readOnly={!editMode} rows={4} />
                </Form.Item>
                <Form.Item
                  name={["extra", "vendor"]}
                  rules={Required("Vendor")}
                >
                  <VendorSelect
                    activeRole={props.activeRole}
                    readOnly={!isNew || expenseId ? true : false}
                    sendVendorDetail={handleOcrSelectedVendorDetail}
                  />
                </Form.Item>
                <Form.Item
                  label="Supplied from"
                  name="place_of_supply"
                  rules={Required("Supplied from")}
                >
                  <PlaceOfSupply
                    readOnly={!isNew || expenseId ? true : false}
                  />
                </Form.Item>
                {data.extra?.vendor &&
                data.extra?.vendor?.symbol_name &&
                data.extra?.convertionRate &&
                data.extra?.vendor?.symbol_name !== "INR" ? (
                  <FieldInfo
                    text={`Currency & conv. rate: 1 ${
                      data.extra?.vendor?.symbol_name
                    } = ${roundNumber(+data.extra?.convertionRate)} INR`}
                  />
                ) : null}
                {/* 
 commented code may be required in future
  */}
                {/* {data.extra?.convertionRate?  <div style={{display:'flex',justifyContent:'space-between',fontWeight:700,
                fontSize:'12px',color:'#2E80F0'
                }} >
                     {/* <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>Currency</div>
                    <div style={{textAlign:'center'}}>USD</div>
                  </div>
              {" "}    {`&`}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>conv. rate</div>
                    <div style={{textAlign:'center'}}>74.20</div>
                  </div> 

                  <div style={{ display: "flex",flexDirection:'row' }}>
{`Currency & conv. rate: 1 USD = 74.20`}
                  </div>
                  </div>
                  
                  :null} */}

                <Row gutter={{ md: 8, lg: 16 }}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      label="Amounts are"
                      name="tax_inclusion"
                      rules={Required("Amounts are")}
                    >
                      <AmountsAreSelect
                        readOnly={!isNew || expenseId ? true : false}
                        placeOfSupplyId={data.place_of_supply}
                        vendorUnregFlag={
                          data.extra?.vendor?.gstin !== null ? false : true
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      label="Apply cess"
                      // rules={Required("Apply cess")}
                      name={["extra", "hasCess"]}
                    >
                      <ApplyCess
                        readOnly={!isNew || expenseId ? true : false}
                        disabled={data.tax_inclusion === "OutOfTax"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider> Expense details</Divider>
                <Row gutter={{ md: 8, lg: 16 }}>
                  {/* {!isNew ? ( */}
                  <Col sm={24} lg={12}>
                    {expenseId && (
                      <Form.Item
                        className={commonStyle["w-100"]}
                        // name={"id"}
                        label="Expense id"
                      >
                        <Input
                          bordered={false}
                          value={expenseId}
                          readOnly={true}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  {/* ) : (
                    <></>
                  )} */}
                </Row>
                <Row gutter={{ md: 8, lg: 16 }}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      className={commonStyle["w-100"]}
                      name="invoice_no"
                      label="Invoice no"
                      rules={
                        !isNew && expenseId
                          ? Required("Invoice no")
                          : [
                              {
                                required: false,
                              },
                            ]
                      }
                    >
                      <Input readOnly={true} />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    {/* {dateView || (
                      <Form.Item
                        className={commonStyle["w-100"]}
                        name="receipt_date"
                        label="Date"
                        rules={Required("Date")}
                      >
                        <DatePicker
                          disabledDate={isFuture}
                          format={FORMAT_DATE}
                          className={commonStyle["w-100"]}
                        />
                      </Form.Item>
                    )} */}
                    <Form.Item className={commonStyle["w-100"]} label="Date">
                      <input
                        className="ant-input"
                        defaultValue={
                          data.receipt_date ? formatDate(data.receipt_date) : ""
                        }
                        readOnly
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="description"
                  label="Narration"
                  rules={
                    !isNew && expenseId
                      ? Required("Description")
                      : [
                          {
                            required: false,
                          },
                        ]
                  }
                >
                  <TextArea readOnly={true} rows={2} />
                </Form.Item>
                <Form.Item
                  name="category_id"
                  rules={
                    !isNew && expenseId
                      ? Required("Category")
                      : [
                          {
                            required: false,
                          },
                        ]
                  }
                >
                  <CategorySelect readOnly={true} />
                </Form.Item>
              </Col>
              {/* </Col> */}
              <Col md={2} lg={2} xl={2} xxl={2} />
              <Col
                style={{
                  width: "50%",
                }}
                md={10}
                lg={10}
                xl={10}
                xxl={10}
              >
                <OcrSelect
                  value={data}
                  onFileUpload={update}
                  onOcrData={handleOcrData}
                  onLoading={handleOCRLoading}
                  readOnly={!normalInfo.editMode}
                  onOcrDelete={handleOcrDelete}
                  DragFile={props.location?.state?.fileList!}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const handlePaymentRefresh = async () => {
    const paymentDetails = await getPaymentDetais(expenseId!);
    const paidAmount = paymentDetails.payment_history.reduce(
      (sum: any, it: any) =>
        it.payment_status === "Cancelled" ? sum : sum + it.paid_amount,
      0
    );
    // this.setState(({ expense }) => {
    //   const { net_total, tds_amount } = expense;
    //   const newExpense = { ...expense };
    //   const remaining = roundNumber(
    //     net_total ? +net_total : 0 - paidAmount - (tds_amount ? +tds_amount : 0)
    //   );
    //   if (remaining === 0) {
    //     newExpense.paymentStatus = "Completed";
    //   } else if (paidAmount > 0) {
    //     newExpense.paymentStatus = "Partially Paid";
    //   } else if (paymentDetails.payment_history.length > 0) {
    //     newExpense.paymentStatus = "Pending";
    //   } else if (remaining < 0) {
    //     newExpense.paymentStatus = "Over paid";
    //   } else {
    //     newExpense.paymentStatus = "Pending";
    //   }
    //   newExpense.extra = newExpense.extra
    //     ? { ...newExpense.extra, paymentDetails }
    //     : { paymentDetails };
    //   if (this.props.match?.params.id) {
    //     this.loadExpenseData(+this.props.match?.params.id);
    //   }
    //   return {
    //     expense: newExpense,
    //     apiState: "idle",
    //     isLoading: false,
    //   };
    // });
    const { net_total, tds_amount } = data;
    const remaining = roundNumber(
      net_total ? +net_total : 0 - paidAmount - (tds_amount ? +tds_amount : 0)
    );
    // if (remaining === 0) {
    //   newExpense.paymentStatus = "Completed";
    // } else if (paidAmount > 0) {
    //   newExpense.paymentStatus = "Partially Paid";
    // } else if (paymentDetails.payment_history.length > 0) {
    //   newExpense.paymentStatus = "Pending";
    // } else if (remaining < 0) {
    //   newExpense.paymentStatus = "Over paid";
    // } else {
    //   newExpense.paymentStatus = "Pending";
    // }

    update({
      paymentStatus:
        remaining === 0
          ? "Completed"
          : paidAmount > 0
          ? "Partially Paid"
          : paymentDetails.payment_history.length > 0
          ? "Pending"
          : remaining < 0
          ? "Over paid"
          : "Pending",
    });
    if (props.match?.params.id) {
      loadExpenseData(+props.match?.params.id);
    }
  };

  const {
    editMode,
    payDlgVisible,
    isFormDataLoading,
    tdsDlgVisible,
  } = normalInfo;

  const currencyInfo = data.extra?.vendor
    ? {
        name: data.extra.vendor.symbol_name,
        symbol: data.extra.vendor.symbol,
        conversionRate: data.extra.convertionRate || 1,
      }
    : undefined;
  const { match } = props;

  const paymentHisData = {
    netTotal: data.net_total,
    tdsAmont: data.tds_amount,
    paymentDetails: data.extra?.paymentDetails,
    expenseId,
    conversionRate: data.extra?.convertionRate,
    onRefresh: handlePaymentRefresh,
  };
  return (
    <IMTContent fullwidth withoutMargin={true}>
      <PurchesDebitDetailsHeader
        isNew={isNew}
        expenseId={expenseId!}
        purDebitNoteId={drId ? drId : null}
        activeRole={props.activeRole}
        isEditing={isEditing}
        rippleEffect={normalInfo.rippleEffect}
        paymentStatus={data.paymentStatus}
        handlePaymentDialog={handlePayDlg}
        apiState={normalInfo.apiState}
        slug={match?.params.slug!}
        onCancel={handleCancelEdit}
        onDelete={handleDelete}
        onSave={handleSave}
        onEdit={handleEdit}
      />
      <Spin spinning={isFormDataLoading}>
        <div className={styles.commonContent}>
          <Form style={{ width: "100%" }} {...formProps}>
            {renderUpperForm()}
            <PurchesLineItem
              hasCess={data.extra?.hasCess}
              rowData={data.pd_row_data}
              onRowDataChange={handleRowDataChange}
              readOnly={!isEditing}
              isLoading={isLoading}
              tax_inclusion={data.tax_inclusion}
              taxTeritoryType={taxTeritoryType}
              currency={currencyInfo}
              paymentDetails={data.extra?.paymentDetails}
              paymentHistoryData={paymentHisData}
              vendorUnregFlag={data.extra?.vendor?.gstin ? false : true}
              totalPayAmount={data.total_pay_amount}
              tdsInfo={{
                tdsRate: data.tds_rate,
                tdsAmount: data.tds_amount,
              }}
              isCopy={props.match?.params.slug === "copy"}
              sameState={data.place_of_supply === props.place_of_supply_id}
            />
          </Form>
          {/* <div>
            <h3>Data</h3>
            <code>
              <pre>{JSON.stringify(data, null, 2)}</pre>
            </code>
          </div> */}
        </div>
      </Spin>
      {payDlgVisible === true ? (
        <PaymentDialog
          expense={data}
          fetchData={handleAfterPayment}
          visible={payDlgVisible}
          onVisibleChange={handlePayDlgVisibleChange}
          //  here vendorlist required to pass as props
          placeOfSupply={data.place_of_supply}
          handleRipple={handleRipple}
        />
      ) : null}
      {tdsDlgVisible === true ? (
        <TdsPayment
          expense={data}
          fetchData={handleAfterPayment}
          visible={tdsDlgVisible}
          onVisibleChange={handleTdsPayDlgVisibleChange}
          cheackFlag={normalInfo.tdsEditFlag}
          forEditSave={handleEdittds}
          taxApplicableType={data.tax_inclusion}
          conversionRate={data.extra?.convertionRate}
          symbol={data.extra?.vendor?.symbol}
        />
      ) : null}
    </IMTContent>
    // </Spin>
  );
};

export default connector(PurchesDebitDetail);
