import { fetchUtil, fetchTableExportBlob } from "./common";
import { PickupItem } from "~/fragment/pickup-field/pickupSlice";
import { shallowParseDate } from "~/lib";
import {
  ReceiptDetails,
  ApiRowResponse,
  Reimbursement,
} from "~/feature/reimbursement/ReimbursementModel";

export const getReceiptList = async (
  role_id: number,
  page_no: number,
  time_zone_offset: number,
  no_of_rows: number,
  month: string,
  fy: string,
  sort_param: string
): Promise<{
  ok: boolean;
  data?: Array<ApiRowResponse>;
  message: string;
  schema?: Array<any>;
}> => {
  const { ok, json, message, schema } = await fetchUtil(
    "POST",
    "/get_receipts",
    {
      fy,
      month,
      page_no,
      no_of_rows,
      time_zone_offset,
      role_id,
      sort_param,
    }
  );

  if (!ok) {
    return { ok, data: [], message };
  } else {
    const data = json as Array<ApiRowResponse>;

    return { ok, message, data, schema };
  }
};

const _parseStringNumbers = (receiptData: Reimbursement): Reimbursement => {
  receiptData.amount = receiptData.amount ? +receiptData.amount : 0;
  receiptData.gst = receiptData.gst ? +receiptData.gst : 0;
  receiptData.paid_amount = receiptData.paid_amount
    ? +receiptData.paid_amount
    : 0;
  receiptData.total_amount = receiptData.total_amount
    ? +receiptData.total_amount
    : 0;
  receiptData.row_data.forEach((rd) => {
    rd.amount = rd.amount ? +rd.amount : 0;
  });
  return receiptData;
};

export const getReimburseByID = async (receipt_id: number) => {
  const time_zone_offset = new Date().getTimezoneOffset();
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/get_receipt_details",
    {
      receipt_id,
      time_zone_offset,
    }
  );

  return {
    ok: ok && json.length > 0,
    message: message || "No details found. Please check id",
    data: (json.length > 0
      ? shallowParseDate(_parseStringNumbers(json[0]))
      : (undefined as any)) as ReceiptDetails,
  };
};

export const saveReimbursementDetails = async (payload: Reimbursement) => {
  const { ok, message } = await fetchUtil(
    "POST",
    "/save_receipt_details",
    payload
  );
  return { ok, message };
};

export const editReimbursementDetails = async (
  id: number,
  payload: Reimbursement
) => {
  (payload as any).receipt_id = payload.id;
  const { ok, message } = await fetchUtil(
    "POST",
    "/edit_reimbursement",
    payload
  );
  return { ok, message };
};

export type GSTRate = {
  id: number;
  description: string;
  rate: number;
};

export type CESSRate = {
  id: number;
  label: string;
  value: number;
};

export const getGSTRates = async (): Promise<{
  ok: boolean;
  message: string;
  data?: Array<GSTRate>;
}> => {
  const { ok, message, json } = await fetchUtil("GET", "/get_gst_rates");
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};

export const getCessRates = async (): Promise<{
  ok: boolean;
  message: string;
  data?: Array<GSTRate>;
}> => {
  const { ok, message, json } = await fetchUtil("GET", "/get_cess_rates");
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};

export const getReimburseCategories = async (): Promise<{
  ok: boolean;
  message: string;
  data: Array<PickupItem>;
}> => {
  const { ok, message, json } = await fetchUtil("GET", "/get_expense_category");
  if (!ok) {
    return { ok, message, data: [] };
  } else {
    return {
      ok,
      message,
      data: json.map((it) => ({
        value: it.id,
        label: it.category,
        category_type: it.category_type,
      })),
    };
  }
};

export const getTableExportBlob = async (
  page_no: number,
  time_zone_offset: number,
  no_of_rows: number,
  month: string,
  fy: string,
  sort_param: string,
  acceptType: string,
  selectedFields: string,
  role_id: number
): Promise<{
  ok: boolean;
  blob: Blob | null | undefined;
  message: string;
}> => {
  const { ok, blob, message } = await fetchTableExportBlob(
    "POST",
    "/get_receipts",
    {
      fy,
      month,
      page_no,
      no_of_rows,
      time_zone_offset,
      sort_param,
      role_id,
    },
    acceptType,
    selectedFields
  );
  if (!ok) {
    return { ok, blob: null, message };
  } else {
    return {
      ok,
      blob,
      message,
    };
  }
};
