import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={40}
    height="40px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title />
    <g
      fill="none"
      fillRule="evenodd"
      id="\u9875\u9762-1"
      stroke="none"
      strokeWidth={1}
    >
      <g
        id="\u5BFC\u822A\u56FE\u6807"
        transform="translate(-325.000000, -80.000000)"
      >
        <g id="\u7F16\u7EC4" transform="translate(325.000000, 80.000000)">
          <polygon
            fill="#FFFFFF"
            fillOpacity={0.01}
            fillRule="nonzero"
            id="\u8DEF\u5F84"
            points="24 0 0 0 0 24 24 24"
          />
          <polygon
            id="\u8DEF\u5F84"
            points="22 7 12 2 2 7 2 17 12 22 22 17"
            stroke="#212121"
            strokeLinejoin="round"
            strokeWidth={1.5}
          />
          <line
            id="\u8DEF\u5F84"
            stroke="#212121"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            x1={2}
            x2={12}
            y1={7}
            y2={12}
          />
          <line
            id="\u8DEF\u5F84"
            stroke="#212121"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            x1={12}
            x2={12}
            y1={22}
            y2={12}
          />
          <line
            id="\u8DEF\u5F84"
            stroke="#212121"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            x1={22}
            x2={12}
            y1={7}
            y2={12}
          />
          <line
            id="\u8DEF\u5F84"
            stroke="#212121"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            x1={17}
            x2={7}
            y1={4.5}
            y2={9.5}
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SVGComponent;
