import { Input, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import * as React from "react";
import { StockItem } from "./tally-dirty-entity-type";

interface IProps {
  stockItemList: Array<StockItem>;
  selectedIds: Array<number>;
  onSelectionChange: (idList: Array<number>) => void;
}

export default function StockItemSelect({
  stockItemList,
  // selectedIds,
  onSelectionChange,
}: IProps) {
  const [searchValue, setSearchValue] = React.useState("");
  const columnDef = React.useMemo(() => {
    const definition: Array<ColumnType<StockItem>> = [
      {
        title: "Name",
        dataIndex: "product_name",
        filteredValue: [searchValue],
        onFilter: (value, record) => {
          return (
            String(record.product_name)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.hsn_sac)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.product_category)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.product_rate)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.unit)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase())
          );
        },
      },
      {
        title: "HSN/SAC",
        dataIndex: "hsn_sac",
        render: (_, record) => {
          return <>{record.hsn_sac ? record.hsn_sac : "N/A"}</>;
        },
      },
      {
        title: "Stock Group",
        dataIndex: "product_category",
      },
      {
        title: "Rate",
        dataIndex: "product_rate",
      },
      {
        title: "Unit",
        dataIndex: "unit",
      },
      {
        title: "GST rate",
        dataIndex: "gst_rate",
        render(_, record) {
          const r = record as any;
          if (!r._gstRate) {
            r._gstRate = record.gst_rate
              ? record.gst_rate.replace("GST", "")
              : "N/A";
          }
          return r._gstRate;
        },
      },
    ];

    return definition;
  }, [searchValue]);

  const rowSelection = React.useMemo(() => {
    const selection: TableRowSelection<StockItem> = {
      onChange(_selectedRowKeys, selectedRows) {
        onSelectionChange(selectedRows.map((it) => it.id));
      },
      // selectedRowKeys: selectedIds
    };
    return selection;
  }, [onSelectionChange]);

  return (
    <>
      <Input
        placeholder="Search Here"
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setSearchValue(e.target.value);
        }}
        style={{ maxWidth: "40%" }}
        allowClear
      />
      <Table
        rowKey="id"
        style={{ maxWidth: "80%", cursor: "pointer" }}
        columns={columnDef}
        rowSelection={rowSelection}
        dataSource={stockItemList}
        size="large"
      />
    </>
  );
}
