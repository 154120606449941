import React, { Component } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  notification,
  AutoComplete,
  Spin,
} from "antd";
import {
  OnboardStoreState,
  OnboardDispatch,
  action,
  BasicDetails,
} from "~/app/OnboardCompany/store";
import { connect, ConnectedProps } from "react-redux";
import { FormInstance } from "antd/lib/form";
import { fetchUtil } from "~/api/common";
import { PickupItem } from "~/fragment/pickup-field/pickupSlice";
import { filterOption } from "~/lib";
import ProfilePicUpload from "~/fragment/upload/ProfilePicUpload";
import FieldInfo from "~/component/FieldInfo";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

type CurrencyItem = {
  id: number;
  currency_name: string;
  symbol: string;
  symbol_name: string;
};

const mapStateToProps = (state: OnboardStoreState) => ({
  initialValues: state.onboard.basicDetails,
  directorDetailLIst: state.onboard.directorList,
});

const mapDispatchToProps = (dispatch: OnboardDispatch) => ({
  handleDirectorsList: (directorsList: Array<any>) =>
    dispatch(action.handleDirectorsList(directorsList)),
  finishStep: (stepData: any) => dispatch(action.finishStep(stepData)),
  advanceStep: () => dispatch(action.advanceStep()),
  // createCompanyDetails: (company_name: any) =>
  //   dispatch(action.auth.createCompanyDetails(company_name)),
  // updateStore: () => dispatch(action.auth.updateStore()),
  // controlAppTour: (showSyncButtonTour: boolean) =>
  //   dispatch(action.auth.controlAppTour(showSyncButtonTour)),
});

const { Option } = Select;

// const mapDispatchToProps = {
//   finishStep: action.finishStep,
//   advanceStep: action.advanceStep,
// };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  doNotUseMe?: never;
  openGstinModal(): void;
}
interface State {
  industryList: Array<{ id: number; name: string }>;
  establishmentTypes: Array<PickupItem>;
  cityList: Array<{ city: string; state: string; country: string }>;
  currencyList: Array<CurrencyItem>;
  companyList: any;
  rowCompanyList: any;
  cinRequired: boolean;
  loadDetail: boolean;
  logo: string;
  directorsList: any;
  cinData: any;
}

class StepBasic extends Component<Props, State> {
  state = {
    industryList: [],
    establishmentTypes: [],
    cityList: [],
    currencyList: [],
    companyList: [],
    rowCompanyList: [],
    cinRequired: true,
    loadDetail: false,
    logo: "",
    directorsList: [],
    cinData: {
      authorised_capital: "",
      paid_up_capital: "",
      directors: "",
      pending_charges: "",
      company_status: "",
    },
  };

  // tslint:disable-next-line: variable-name
  _formRef = React.createRef<FormInstance<Partial<BasicDetails>>>();

  componentDidMount() {
    this.getCurrencyList();
    this.getIndustryList();
    this.getEstablishment();
    if (this.props.initialValues.cin) {
      this.getDataFromCin(this.props.initialValues.cin);
    }

    const estNow = this.props.initialValues.establishment_type;
    const cinRequired = [1, 2, 3, 4, 8].indexOf(estNow) === -1;
    this.setState({ cinRequired });
    if (this.props.initialValues.company_logo) {
      this.handleLogoChange(this.props.initialValues.company_logo);
    }
  }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.initialValues.legal_name !== this.props.initialValues.legal_name
    ) {
      this._formRef.current?.setFieldsValue(this.props.initialValues);
      const estNow = this.props.initialValues.establishment_type;
      const cinRequired = [1, 2, 3, 4, 8].indexOf(estNow) === -1;
      this.setState({ cinRequired });
    }

    if (prevProps.initialValues !== this.props.initialValues) {
      this._formRef.current?.setFieldsValue(this.props.initialValues);
      const estNow = this.props.initialValues.establishment_type;
      const cinRequired = [1, 2, 3, 4, 8].indexOf(estNow) === -1;
      this.setState({ cinRequired });
    }

    if (prevProps.initialValues.cin !== this.props.initialValues.cin) {
      if (this.props.initialValues.cin) {
        this.getDataFromCin(this.props.initialValues.cin);
      } else {
        this.props.handleDirectorsList([]);
      }
    }
  }

  async getIndustryList() {
    const { ok, message, json } = await fetchUtil("GET", "/get_industry");
    if (!ok) {
      notification.error({ message });
    } else {
      this.setState({
        industryList: json,
      });
    }
  }

  async getEstablishment() {
    const { ok, message, json } = await fetchUtil(
      "GET",
      "/get_establishment_types"
    );
    if (!ok) {
      notification.error({ message });
    } else {
      this.setState({
        establishmentTypes: json,
      });
    }
  }

  async getCurrencyList() {
    this.setState({
      loadDetail: true,
    });
    const { ok, message, json } = await fetchUtil("GET", "/get_currency");
    if (!ok) {
      notification.error({ message });
    } else {
      this.setState(
        {
          currencyList: json,
        },
        () => {
          this._formRef.current?.setFieldsValue({
            currency: json.find((it) => it.symbol_name === "INR")?.id,
          });
        }
      );
      if (!this.props.initialValues.cin) {
        this.setState({
          loadDetail: false,
        });
      }
    }
  }

  getDataFromCin = async (cin: any) => {
    this.setState({
      loadDetail: true,
    });
    await fetchUtil("POST", "/get_company_details_from_cin", {
      cin,
    }).then(({ ok, message, json }) => {
      if (!ok) {
        this.setState({
          loadDetail: false,
        });
        notification.error({ message });
        return { ok, message };
      } else {
        if (json?.length > 0) {
          this.setState(
            {
              directorsList: json[0].directors_list,
              cinData: json[0],
            },
            () => {
              this.props.handleDirectorsList(json[0].directors_list);
            }
          );
        }
        this.setState({
          loadDetail: false,
        });
        // return { ok, message, data: json };
      }
    });
  };

  onFinish = (values: any) => {
    this.props.finishStep({
      step: "basic",
      data: {
        ...values,
        authorised_capital: this.state.cinData.authorised_capital,
        paid_up_capital: this.state.cinData.paid_up_capital,
        directors: this.state.cinData.directors,
        pending_charges: this.state.cinData.pending_charges,
        company_status: this.state.cinData.company_status,
      },
    });
    this.props.advanceStep();
  };

  loadPickupListsForCity = async (city: string) => {
    if (city) {
      this.setState({
        loadDetail: true,
      });
      const { ok, message, json } = await fetchUtil(
        "GET",
        "/get_city_name?city=" + city
      );
      if (ok) {
        this.setState({ cityList: json, loadDetail: false });
      } else {
        notification.error({ message });
      }
    }
  };

  handleOnValueChange = (changedValues: any, values: any) => {
    if (changedValues.company_city) {
      if (changedValues.company_city.length >= 4) {
        this.setState({ cityList: [] });
        this.loadPickupListsForCity(changedValues.company_city);
      }
    }
    if (changedValues.establishment_type) {
      const cinRequired =
        [1, 2, 3, 4, 8].indexOf(changedValues.establishment_type) === -1;
      this.setState({ cinRequired }, () => {
        this.props.handleDirectorsList([]);
      });
    }
    if (changedValues.company_logo) {
      this.setState({
        logo: changedValues.company_logo,
      });
    }
    if (changedValues?.cin?.length === 21) {
      this.getDataFromCin(changedValues.cin);
    }
  };

  getUppercaseVal = (newInput: string) => {
    // console.log("newInput: ",newInput)
    return newInput && newInput.toUpperCase();
  };

  handleLogoChange = (newLogo: string) => {
    if (this._formRef.current) {
      const values = this._formRef.current.getFieldsValue() as BasicDetails;
      values.company_logo = newLogo;
    }
  };

  render() {
    return (
      <Spin spinning={this.state.loadDetail}>
        <Form
          initialValues={this.props.initialValues}
          ref={this._formRef}
          onValuesChange={this.handleOnValueChange}
          onFinish={this.onFinish}
        >
          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                // required
                name="company_logo"
                label="Logo"
                rules={[
                  {
                    // required: true,
                    message: "Please upload logo!",
                  },
                ]}
              >
                <ProfilePicUpload
                  hashedFileName={
                    this.state.logo
                      ? this.state.logo
                      : this.props.initialValues.company_logo
                  }
                  onChange={this.handleLogoChange}
                  is_disable={false}
                  square
                  companyLogo
                />
              </Form.Item>
            </Col>
            <Col
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              style={{
                alignSelf: "center",
                textAlign: "right",
              }}
            >
              <Button
                type="primary"
                ghost
                onClick={(e: any) => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    ga.events.stepBasic177
                  );
                  this.props.openGstinModal();
                }}
              >
                Auto-fill using GSTIN
              </Button>
            </Col>
          </Row>
          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="name" hidden />
              <Form.Item name="company_country" hidden />
              <Form.Item name="company_state" hidden />
              <Form.Item name="legal_name" hidden />
              <Form.Item
                name="establishment_type"
                label="Establishment type"
                rules={[
                  {
                    required: true,
                    message: "Please select valid establishment type",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {this.state.establishmentTypes.map((it: any) => (
                    // console.log(it)
                    <Option key={it.id} value={it.id}>
                      {it.establishment_type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              {this.state.cinRequired ? (
                <Form.Item
                  name="cin"
                  label="CIN/LLPIN #"
                  rules={[
                    {
                      required: this.state.cinRequired,
                      message: "Please enter valid cin",
                    },
                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="name"
                label="Entity name"
                /* getValueFromEvent={this.getUppercaseVal} */
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please select a company name !",
                  },
                ]}
              >
                <input className="ant-input" />
              </Form.Item>
              {this._formRef?.current?.getFieldValue("name") !==
              this._formRef?.current?.getFieldValue("legal_name") ? (
                <FieldInfo
                  text={this._formRef?.current?.getFieldValue("legal_name")}
                />
              ) : null}
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="industry"
                label="Industry"
                rules={[
                  { required: true, message: "Please select valid industry" },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {this.state.industryList.map((it: any) => (
                    <Option key={it.id} value={it.id}>
                      {it.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  { required: true, message: "Please enter valid address" },
                ]}
              >
                <input className="ant-input" />
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="City"
                name="company_city"
                rules={[
                  { required: true, message: "Please select valid city" },
                ]}
              >
                <AutoComplete
                  // tslint:disable-next-line: jsx-no-lambda
                  onSelect={(val: any) => {
                    const obj: any = this.state.cityList.find(
                      (l: {
                        id: number;
                        city: string;
                        state: string;
                        country: string;
                        // tslint:disable-next-line: triple-equals |  | Double equal is intentional, please don't correct it
                      }) => l.id === val
                    );
                    if (this._formRef.current) {
                      // console.log(obj);
                      this._formRef.current.setFieldsValue({
                        company_state: obj.state_id,
                        company_city: obj.city,
                        company_state_name: obj.state,
                        company_country: obj.country,
                      });
                    }
                  }}
                  options={this.state.cityList.map((v: any, idx: number) => ({
                    key: v.id,
                    value: `${v.id}`,
                    label: (
                      <div>
                        <span>{v.city}</span>{" "}
                        <span
                          style={{
                            fontSize: 10,
                            color: "var(--primary-color)",
                          }}
                        >
                          {v.state}, {v.country}
                        </span>
                      </div>
                    ),
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="State"
                name="company_state_name"
                rules={[
                  { required: true, message: "Please enter valid state" },
                ]}
              >
                <input className="ant-input" readOnly />
              </Form.Item>
              <FieldInfo
                text={this._formRef?.current?.getFieldValue("company_country")}
              />
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Pincode"
                name="company_pincode"
                rules={[
                  { required: true, message: "Please enter valid pincode" },
                ]}
              >
                <input className="ant-input" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={16}>
            <Col>
              <Button type="primary" htmlType="submit" size="middle">
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  }
}

export default connector(StepBasic);
