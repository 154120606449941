import { PureComponent } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";

import { AuthState } from "~/app/MainApp/store";
import { LoginRole } from "~/api/auth";

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
});
const connector = connect(mapStateToProps);
const user = localStorage.getItem("imt__user");
const userData = JSON.parse(user!);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux, RouteComponentProps {}

class AppRootPage extends PureComponent<Props, {}> {
  state = {};

  componentDidMount() {
    if (
      this.props.activeRole === LoginRole.ADMIN ||
      this.props.activeRole === LoginRole.SUPERuSER ||
      this.props.activeRole === LoginRole.CA
    ) {
      this.props.history.push("/app/dashboard");
    } else {
      if (!userData.plan_purchased) {
        window.location.pathname = `/app/expiredMembership`;
      } else {
        this.props.history.push("/app/employee/me");
      }
    }
  }

  render() {
    return null;
  }
}

export default connector(AppRootPage);
