import React from "react";
import cx from "classnames";
import { Table, Input, Button, Tooltip, Space, Select } from "antd";
import { ColumnType } from "antd/lib/table";
import {
  PlusOutlined,
  MinusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import Currency from "~/component/Currency";
import { ReimbursementLineItems } from "~/feature/reimbursement/ReimbursementModel";
import { formatNumber } from "~/lib";
import styles from "./ReimbursementLineItem.module.css";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";
import { VendorItem } from "~/api/vendor";

type CustomerType = {
  place_of_supply_id?: number;
  symbol_name: string;
  symbol: string;
  email_id: string;
  billing_address: string;
  billing_address2: string;
  billing_address_city: string;
  billing_address_country: string;
  billing_address_pincode: string;
  billing_address_state: string;
  company_name: string;
  customer_currency: number;
  customer_id: number;
  gst_registration_type_id: number;
  gstin: string;
  name: string;
  place_of_supply: string;
  state_code: number;
};

export type CellChangeHandler = (
  key: string,
  rowIndex: number,
  event: React.ChangeEvent<HTMLInputElement>
) => void;

const renderCurrency = (
  key: string,
  onCellChange?: CellChangeHandler,
  readOnly?: boolean,
  disabled?: boolean,
  customerList?: Array<VendorItem>,
  place_of_supply_id?: number,
  intraState?: boolean
) => (value: number, _rec: ReimbursementLineItems, idx: number) => {
  return (
    <Input
      className={styles.currencyEditor}
      value={
        value && readOnly
          ? key === "cgst"
            ? formatNumber(value * 2)
            : formatNumber(value)
          : key === "cgst"
          ? value * 2
          : value
      }
      disabled={disabled}
      readOnly={readOnly}
      // bordered={key === "amount" ? true : false}
      // style={
      //   (key === "quantity" || key === "rate") && readOnly === false
      //     ? { border: "0.5px solid #D9D9D9" }
      //     :  (key === "quantity" || key === "rate") &&
      //     _rec.extra?.errors?.length! > 0 &&
      //     !value?{ border: "0.5px solid red" }:undefined
      // }

      style={
        (key === "quantity" || key === "rate") &&
        _rec.extra?.errors?.length! > 0 &&
        !value
          ? { border: "0.5px solid red" }
          : (key === "quantity" || key === "rate") && readOnly === false
          ? { border: "0.5px solid #D9D9D9" }
          : undefined
      }
      onChange={onCellChange && ((e) => onCellChange(key, idx, e))}
    />
  );
};

const InputComponent = ({ value, ...props }: any) =>
  value && value.length > 30 ? (
    <Input.TextArea rows={3} value={value} {...props} />
  ) : (
    <Input.TextArea rows={1} value={value} {...props} />
  );
const handleCategoryFilterOption = (input?: string, option?: any): any => {
  return input && option.children.toLowerCase().includes(input.toLowerCase());
};
interface Props {
  lineItems: Array<ReimbursementLineItems>;
  place_of_supply_id: number;
  errors: Array<Array<string>>;
  footerValues: {
    gstRate?: number;
    igst?: number;
    cgst?: number;
    cessRate?: any;
    cess?: number;
    sgst?: number;
    amount?: number;
    net_total?: number;
  };
  onCellChange: CellChangeHandler;
  onRowAdd?: (e: React.MouseEvent) => void;
  onRowDelete: (rowIndex: number) => void;
  disabledDaterow: (e: Date) => boolean;
  showGst: boolean;
  className?: string;
  disabled?: boolean;
  customerList: Array<VendorItem>;
  flagCustomer: number | undefined;
  flagCompany: boolean;
  handleNewVendor: () => void;
  isEditing: boolean;
  activeRole: any;
  intraState: any;
  outOfTax: boolean;
  OPTS_CESS: any;
  gstPickList: any;
  applyCess: boolean;
  unitList: any;
}

const ReimbursementLineItem = ({
  lineItems,
  place_of_supply_id,
  errors,
  footerValues,
  className,
  onCellChange,
  onRowAdd,
  onRowDelete,
  showGst,
  disabled,
  customerList,
  flagCompany,
  intraState,
  outOfTax,
  OPTS_CESS: optsCess,
  gstPickList,
  applyCess,
  unitList,
}: Props) => {
  const columnsDef: Array<ColumnType<ReimbursementLineItems>> = applyCess
    ? [
        {
          title: "",
          key: "add_action",
          width: "32px",
          className: styles.actionCell,
          render: (_, _record, idx) => {
            const validationInfo =
              !errors[idx] || errors[idx].length === 0 ? null : (
                <Tooltip
                  title={
                    <ul className={styles.errorList}>
                      {errors[idx].map((msg, idx) => (
                        <li key={idx}>{msg}</li>
                      ))}
                    </ul>
                  }
                >
                  <InfoCircleOutlined className={styles.errorIcon} />
                </Tooltip>
              );
            if (idx + 1 === lineItems.length) {
              return flagCompany === true ? (
                validationInfo
              ) : (
                <Space className={styles.errorSpace}>
                  <Button
                    size="small"
                    icon={
                      <PlusOutlined style={{ color: "var(--success-color)" }} />
                    }
                    disabled={disabled}
                    onClick={(e) => {
                      registerEvent(
                        ga.EVENT_CATEGORY_BUTTON_CLICK,
                        ga.EVENT_CLICK,
                        ga.events.reimbursementLineItem219
                      );
                      if (onRowAdd) {
                        onRowAdd(e);
                      }
                    }}
                  />
                  {validationInfo}
                </Space>
              );
            } else {
              return (
                <Space className={styles.errorSpace} align="center">
                  {validationInfo}
                </Space>
              );
            }
          },
        },
        {
          title: "Description",
          dataIndex: "description",
          // width: "220px",
          render: (value, _rec, idx) => (
            <InputComponent
              className={
                _rec.extra?.errors?.length! > 0 && !value
                  ? styles.deseditorwitherror
                  : styles.deseditor
              }
              value={value}
              readOnly={disabled}
              record={_rec}
              // style={_rec}
              required={true}
              onChange={
                onCellChange &&
                ((e: any) => onCellChange("description", idx, e))
              }
            />
          ),
        },
        {
          title: "HSN",
          dataIndex: "hsn_no",
          width: 120,
          render: (value, _rec, idx) => (
            <Input
              className={styles.deseditor}
              value={value}
              readOnly={disabled}
              required={false}
              onChange={
                onCellChange && ((e: any) => onCellChange("hsn_no", idx, e))
              }
            />
          ),
        },
        {
          title: "Qty",
          dataIndex: "quantity",
          align: "right",
          render: renderCurrency(
            "quantity",
            onCellChange,
            disabled,
            false,
            customerList,
            place_of_supply_id,
            intraState
          ),
        },
        {
          title: "Unit",
          dataIndex: "unit_id",
          render: (value, _rec, idx) => {
            if (disabled) {
              const label = unitList.find((it: any) => it.id === value)
                ?.quantity;
              return (
                <div
                  className={cx(
                    "ant-select ant-select-single ant-select-show-arrow",
                    styles.editor
                  )}
                >
                  <div className="ant-select-selector">
                    <span className="ant-select-selection-search">
                      <input
                        autoComplete="off"
                        type="search"
                        className="ant-select-selection-search-input"
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-owns="rc_select_4_list"
                        aria-autocomplete="list"
                        aria-controls="rc_select_4_list"
                        aria-activedescendant="rc_select_4_list_1"
                        readOnly
                        unselectable="on"
                        defaultValue={label}
                        style={{ opacity: 0 }}
                        aria-expanded="true"
                      />
                    </span>
                    <span className="ant-select-selection-item" title="Service">
                      {label ? label : "0 %"}
                    </span>
                  </div>
                  <span
                    className="ant-select-arrow"
                    unselectable="on"
                    aria-hidden="true"
                    style={{ userSelect: "none" }}
                  >
                    <span
                      role="img"
                      aria-label="down"
                      className="anticon anticon-down ant-select-suffix"
                    />
                  </span>
                </div>
              );
            }
            return (
              <Select
                className={styles.editor}
                value={value}
                disabled={outOfTax}
                showSearch
                showArrow
                filterOption={handleCategoryFilterOption}
                onChange={
                  onCellChange &&
                  ((newValue) =>
                    onCellChange("unit_id", idx, {
                      target: { value: newValue },
                    } as any))
                }
              >
                {unitList.map((opt: any) => (
                  <Select.Option key={opt.id} value={opt.id}>
                    {opt.quantity}
                  </Select.Option>
                ))}
              </Select>
            );
          },
        },
        {
          title: "Rate",
          dataIndex: "rate",
          align: "right",
          width: 140,
          render: renderCurrency(
            "rate",
            onCellChange,
            disabled,
            false,
            customerList,
            place_of_supply_id,
            intraState
          ),
        },

        {
          title: "Amount",
          dataIndex: "amount",
          align: "right",
          width: 120,
          render: renderCurrency(
            "amount",
            onCellChange,
            disabled,
            false,
            customerList,
            place_of_supply_id,
            intraState
          ),
        },
        {
          title: "CGST/ SGST",
          dataIndex: "cgst",
          align: "right",
          width: 120,
          key: "cgst",
          render: renderCurrency(
            "cgst",
            onCellChange,
            true,
            intraState || outOfTax,
            customerList,
            place_of_supply_id,
            intraState
          ),
        },
        // {
        //   title: "SGST",
        //   dataIndex: "sgst",
        //   align: "right",
        //   width: 120,
        //   render: renderCurrency(
        //     "sgst",
        //     onCellChange,
        //     true,
        //     intraState || outOfTax,
        //     customerList,
        //     place_of_supply_id,
        //     intraState
        //   ),
        // },
        {
          title: "IGST",
          dataIndex: "igst",
          align: "right",
          width: 120,
          key: "igst",
          render: renderCurrency(
            "igst",
            onCellChange,
            true,
            !intraState || outOfTax,
            customerList,
            place_of_supply_id,
            intraState
          ),
        },
        {
          title: "Tax rate",
          dataIndex: "gst_rate_id",
          render: (value, _rec, idx) => {
            if (disabled) {
              const label = gstPickList.find((it: any) => it.id === value)
                ?.description;
              return (
                <div
                  className={cx(
                    "ant-select ant-select-single ant-select-show-arrow",
                    styles.editor
                  )}
                >
                  <div className="ant-select-selector">
                    <span className="ant-select-selection-search">
                      <input
                        autoComplete="off"
                        type="search"
                        className="ant-select-selection-search-input"
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-owns="rc_select_4_list"
                        aria-autocomplete="list"
                        aria-controls="rc_select_4_list"
                        aria-activedescendant="rc_select_4_list_1"
                        readOnly
                        unselectable="on"
                        defaultValue={label}
                        style={{ opacity: 0 }}
                        aria-expanded="true"
                      />
                    </span>
                    <span className="ant-select-selection-item" title="Service">
                      {label ? label : "Out Of Scope"}
                    </span>
                  </div>
                  <span
                    className="ant-select-arrow"
                    unselectable="on"
                    aria-hidden="true"
                    style={{ userSelect: "none" }}
                  >
                    <span
                      role="img"
                      aria-label="down"
                      className="anticon anticon-down ant-select-suffix"
                    >
                      {/* <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="down"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg> */}
                    </span>
                  </span>
                </div>
              );
            }
            return (
              <Select
                className={styles.editor}
                value={value}
                disabled={outOfTax}
                onChange={
                  onCellChange &&
                  ((newValue) =>
                    onCellChange("gst_rate_id", idx, {
                      target: { value: newValue },
                    } as any))
                }
              >
                {gstPickList.map((opt: any) => (
                  <Select.Option key={opt.id} value={opt.id}>
                    {opt.description}
                  </Select.Option>
                ))}
              </Select>
            );
          },
        },
        {
          title: "CESS",
          dataIndex: "cess_rate_id",
          render: (value, _rec, idx) => {
            if (disabled) {
              const label = optsCess.find((it: any) => it.id === value)?.rate;
              return (
                <div
                  className={cx(
                    "ant-select ant-select-single ant-select-show-arrow",
                    styles.editor
                  )}
                >
                  <div className="ant-select-selector">
                    <span className="ant-select-selection-search">
                      <input
                        autoComplete="off"
                        type="search"
                        className="ant-select-selection-search-input"
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-owns="rc_select_4_list"
                        aria-autocomplete="list"
                        aria-controls="rc_select_4_list"
                        aria-activedescendant="rc_select_4_list_1"
                        readOnly
                        unselectable="on"
                        defaultValue={label}
                        style={{ opacity: 0 }}
                        aria-expanded="true"
                      />
                    </span>
                    <span className="ant-select-selection-item" title="Service">
                      {label ? label : "0 %"}
                    </span>
                  </div>
                  <span
                    className="ant-select-arrow"
                    unselectable="on"
                    aria-hidden="true"
                    style={{ userSelect: "none" }}
                  >
                    <span
                      role="img"
                      aria-label="down"
                      className="anticon anticon-down ant-select-suffix"
                    >
                      {/* <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="down"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg> */}
                    </span>
                  </span>
                </div>
              );
            }
            return (
              <Select
                className={styles.editor}
                value={value}
                disabled={outOfTax}
                onChange={
                  onCellChange &&
                  ((newValue) =>
                    onCellChange("cess_rate_id", idx, {
                      target: { value: newValue },
                    } as any))
                }
              >
                {/* <Option value={0}>Select Tax rate</Option> */}
                {optsCess.map((opt: any) => (
                  <Select.Option key={opt.id} value={opt.id}>
                    {opt.rate}
                  </Select.Option>
                ))}
              </Select>
            );
          },
        },
        // {
        //   title: "Attachment",
        //   dataIndex: "documents",
        //   align: "center",

        //   render: (documentUrl, _, inx) => (
        //     <SimpleUploadButton
        //       disabled={disabled}
        //       hashFileName={documentUrl}
        //       onHashFileChange={(f) =>
        //         onCellChange("documents", inx, { target: { value: f } } as any)
        //       }
        //     />
        //   ),
        // },
        {
          title: "",
          key: "delete_action",
          width: "32px",
          className: styles.actionCell,
          render: (_, _record, idx) => {
            return (
              <Button
                size="small"
                disabled={disabled}
                danger
                title="Remove line item"
                icon={<MinusOutlined />}
                onClick={() => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    `ReimbursementLineItem>Remove line item`
                  );
                  onRowDelete(idx);
                }}
              />
            );
          },
        },
      ]
    : [
        {
          title: "",
          key: "add_action",
          width: "32px",
          className: styles.actionCell,
          render: (_, _record, idx) => {
            const validationInfo =
              !errors[idx] || errors[idx].length === 0 ? null : (
                <Tooltip
                  title={
                    <ul className={styles.errorList}>
                      {errors[idx].map((msg, idx) => (
                        <li key={idx}>{msg}</li>
                      ))}
                    </ul>
                  }
                >
                  <InfoCircleOutlined className={styles.errorIcon} />
                </Tooltip>
              );
            if (idx + 1 === lineItems.length) {
              return flagCompany === true ? (
                validationInfo
              ) : (
                <Space className={styles.errorSpace}>
                  <Button
                    size="small"
                    icon={
                      <PlusOutlined style={{ color: "var(--success-color)" }} />
                    }
                    disabled={disabled}
                    onClick={(e) => {
                      registerEvent(
                        ga.EVENT_CATEGORY_BUTTON_CLICK,
                        ga.EVENT_CLICK,
                        ga.events.reimbursementLineItem219
                      );
                      if (onRowAdd) {
                        onRowAdd(e);
                      }
                    }}
                  />
                  {validationInfo}
                </Space>
              );
            } else {
              return (
                <Space className={styles.errorSpace} align="center">
                  {validationInfo}
                </Space>
              );
            }
          },
        },
        {
          title: "Description",
          dataIndex: "description",
          // width: "220px",
          render: (value, _rec, idx) => (
            <InputComponent
              // className={styles.deseditor}
              className={
                _rec.extra?.errors?.length! > 0 && !value
                  ? styles.deseditorwitherror
                  : styles.deseditor
              }
              value={value}
              readOnly={disabled}
              required={true}
              onChange={
                onCellChange &&
                ((e: any) => onCellChange("description", idx, e))
              }
            />
          ),
        },
        {
          title: "HSN",
          dataIndex: "hsn_no",
          width: 120,
          render: (value, _rec, idx) => (
            <Input
              className={styles.deseditor}
              value={value}
              readOnly={disabled}
              required={false}
              onChange={
                onCellChange && ((e: any) => onCellChange("hsn_no", idx, e))
              }
            />
          ),
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          align: "right",
          render: renderCurrency(
            "quantity",
            onCellChange,
            disabled,
            false,
            customerList,
            place_of_supply_id,
            intraState
          ),
        },
        {
          title: "Unit",
          dataIndex: "unit_id",

          render: (value, _rec, idx) => {
            if (disabled) {
              const label = unitList.find((it: any) => it.id === value)
                ?.quantity;
              return (
                <div
                  className={cx(
                    "ant-select ant-select-single ant-select-show-arrow",
                    styles.editor
                  )}
                >
                  <div className="ant-select-selector">
                    <span className="ant-select-selection-search">
                      <input
                        autoComplete="off"
                        type="search"
                        className="ant-select-selection-search-input"
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-owns="rc_select_4_list"
                        aria-autocomplete="list"
                        aria-controls="rc_select_4_list"
                        aria-activedescendant="rc_select_4_list_1"
                        readOnly
                        unselectable="on"
                        defaultValue={label}
                        style={{ opacity: 0 }}
                        aria-expanded="true"
                      />
                    </span>
                    <span className="ant-select-selection-item" title="Service">
                      {label ? label : "NONE"}
                    </span>
                  </div>
                  <span
                    className="ant-select-arrow"
                    unselectable="on"
                    aria-hidden="true"
                    style={{ userSelect: "none" }}
                  >
                    <span
                      role="img"
                      aria-label="down"
                      className="anticon anticon-down ant-select-suffix"
                    />
                  </span>
                </div>
              );
            }
            return (
              <Select
                className={styles.editor}
                value={value}
                // disabled={outOfTax}
                style={{ minWidth: 90 }}
                showSearch
                showArrow
                filterOption={handleCategoryFilterOption}
                onChange={
                  onCellChange &&
                  ((newValue) =>
                    onCellChange("unit_id", idx, {
                      target: { value: newValue },
                    } as any))
                }
              >
                {unitList.map((opt: any) => (
                  <Select.Option key={opt.id} value={opt.id}>
                    {opt.quantity}
                  </Select.Option>
                ))}
              </Select>
            );
          },
        },
        {
          title: "Rate",
          dataIndex: "rate",
          align: "right",
          width: 120,
          render: renderCurrency(
            "rate",
            onCellChange,
            disabled,
            false,
            customerList,
            place_of_supply_id,
            intraState
          ),
        },
        {
          title: "Amount",
          dataIndex: "amount",
          align: "right",
          width: 120,
          render: renderCurrency(
            "amount",
            onCellChange,
            true,
            false,
            customerList,
            place_of_supply_id,
            intraState
          ),
        },
        {
          title: "CGST/ SGST",
          dataIndex: "cgst",
          align: "right",
          width: 120,
          key: "cgst",
          render: renderCurrency(
            "cgst",
            onCellChange,
            true,
            intraState || outOfTax,
            customerList,
            place_of_supply_id,
            intraState
          ),
        },
        // {
        //   title: "SGST",
        //   dataIndex: "sgst",
        //   align: "right",
        //   width: 120,
        //   render: renderCurrency(
        //     "sgst",
        //     onCellChange,
        //     true,
        //     intraState || outOfTax,
        //     customerList,
        //     place_of_supply_id,
        //     intraState
        //   ),
        // },
        {
          title: "IGST",
          dataIndex: "igst",
          align: "right",
          width: 120,
          key: "igst",
          render: renderCurrency(
            "igst",
            onCellChange,
            true,
            !intraState || outOfTax,
            customerList,
            place_of_supply_id,
            intraState
          ),
        },
        {
          title: "Tax rate",
          dataIndex: "gst_rate_id",
          render: (value, _rec, idx) => {
            if (disabled) {
              const label = gstPickList.find((it: any) => it.id === value)
                ?.description;
              return (
                <div
                  className={cx(
                    "ant-select ant-select-single ant-select-show-arrow",
                    styles.editor
                  )}
                >
                  <div className="ant-select-selector">
                    <span className="ant-select-selection-search">
                      <input
                        autoComplete="off"
                        type="search"
                        className="ant-select-selection-search-input"
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-owns="rc_select_4_list"
                        aria-autocomplete="list"
                        aria-controls="rc_select_4_list"
                        aria-activedescendant="rc_select_4_list_1"
                        readOnly
                        unselectable="on"
                        defaultValue={label}
                        style={{ opacity: 0 }}
                        aria-expanded="true"
                      />
                    </span>
                    <span className="ant-select-selection-item" title="Service">
                      {label ? label : "Out Of Scope"}
                    </span>
                  </div>
                  <span
                    className="ant-select-arrow"
                    unselectable="on"
                    aria-hidden="true"
                    style={{ userSelect: "none" }}
                  >
                    <span
                      role="img"
                      aria-label="down"
                      className="anticon anticon-down ant-select-suffix"
                    >
                      {/* <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="down"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                  </svg> */}
                    </span>
                  </span>
                </div>
              );
            }
            return (
              <Select
                className={styles.editor}
                value={value}
                disabled={outOfTax}
                onChange={
                  onCellChange &&
                  ((newValue) =>
                    onCellChange("gst_rate_id", idx, {
                      target: { value: newValue },
                    } as any))
                }
              >
                {gstPickList.map((opt: any) => (
                  <Select.Option key={opt.id} value={opt.id}>
                    {opt.description}
                  </Select.Option>
                ))}
              </Select>
            );
          },
        },

        // {
        //   title: "Attachment",
        //   dataIndex: "documents",
        //   align: "center",

        //   render: (documentUrl, _, inx) => (
        //     <SimpleUploadButton
        //       disabled={disabled}
        //       hashFileName={documentUrl}
        //       onHashFileChange={(f) =>
        //         onCellChange("documents", inx, { target: { value: f } } as any)
        //       }
        //     />
        //   ),
        // },
        {
          title: "",
          key: "delete_action",
          width: "32px",
          className: styles.actionCell,
          render: (_, _record, idx) => {
            return (
              <Button
                size="small"
                disabled={disabled}
                danger
                title="Remove line item"
                icon={<MinusOutlined />}
                onClick={() => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    `ReimbursementLineItem>Remove line item`
                  );
                  onRowDelete(idx);
                }}
              />
            );
          },
        },
      ];
  const modifiedColumn = columnsDef.filter((col) =>
    !intraState ? col.key !== "igst" : col.key !== "cgst"
  );
  if (!showGst) {
    modifiedColumn.splice(7, 2);
  }
  return (
    <Table
      className={cx(showGst ? styles.bigTable : styles.smallTable, className)}
      columns={modifiedColumn}
      dataSource={lineItems}
      rowKey="row_id"
      footer={(_data: Array<ReimbursementLineItems>) => {
        return (
          <div className={styles.footerWrap}>
            <div className={styles.footer}>
              <div className={styles.footerRow}>
                <span className={styles.footerLabel}>Subtotal</span>
                <Currency
                  className={styles.footerAmount}
                  value={footerValues.amount}
                />
              </div>
              {showGst ? (
                <>
                  {!outOfTax && intraState ? (
                    <div className={styles.footerRow}>
                      <span className={styles.footerLabel}>
                        IGST
                        {/* ({formatNumber(footerValues.gstRate)} %) */}
                      </span>
                      <Currency
                        className={styles.footerAmount}
                        value={footerValues.igst}
                      />
                    </div>
                  ) : null}
                  {outOfTax ||
                    (!intraState && (
                      <div className={styles.footerRow}>
                        <span className={styles.footerLabel}>
                          CGST
                          {/* ({formatNumber(footerValues.gstRate! / 2)} %) */}
                        </span>
                        <Currency
                          className={styles.footerAmount}
                          value={footerValues.cgst}
                        />
                      </div>
                    ))}
                  {outOfTax ||
                    (!intraState && (
                      <div className={styles.footerRow}>
                        <span className={styles.footerLabel}>
                          SGST
                          {/* ({formatNumber(footerValues.gstRate! / 2)} %) */}
                        </span>
                        <Currency
                          className={styles.footerAmount}
                          value={footerValues.sgst}
                        />
                      </div>
                    ))}
                  {!outOfTax && applyCess && (
                    <div className={styles.footerRow}>
                      <span className={styles.footerLabel}>
                        CESS
                        {/* ({footerValues.cessRate!} %) */}
                      </span>
                      <Currency
                        className={styles.footerAmount}
                        value={footerValues.cess}
                      />
                    </div>
                  )}
                </>
              ) : null}
              <div className={styles.footerRow}>
                <span className={styles.footerLabel}>Total</span>
                <Currency
                  className={styles.footerAmount}
                  value={footerValues.net_total}
                />
              </div>
            </div>
          </div>
        );
      }}
      pagination={false}
    />
  );
};

export default ReimbursementLineItem;
