import React from "react";
import { IMTIconProps, IMTIconElement, __colors } from "./interface";

const Compliance: IMTIconElement = ({
  highlighted,
  ...props
}: IMTIconProps) => {
  const color = highlighted ? __colors.active : __colors.inactive;
  return (
    <svg x="0px" y="0px" viewBox="0 0 56 56" xmlSpace="preserve" {...props}>
      <path
        d="M45.1 9.6h-9.2V8.1c0-3.1-2.5-5.7-5.7-5.7h-4.5c-3.1 0-5.7 2.5-5.7 5.7v1.5h-9.2C8.2 9.6 6 11.8 6 14.5v34.1c0 2.7 2.2 4.9 4.9 4.9H45c2.7 0 4.9-2.2 4.9-4.9V14.5c.1-2.7-2.1-4.9-4.8-4.9zm-23-1.5c0-2 1.6-3.7 3.7-3.7h4.5c2 0 3.7 1.6 3.7 3.7v1.5H22.1V8.1zM48 48.7c0 1.6-1.3 2.9-2.9 2.9H10.9c-1.6 0-2.9-1.3-2.9-2.9V14.5c0-1.6 1.3-2.9 2.9-2.9h34c1.6 0 2.9 1.3 2.9 2.9v34.2z"
        fill={color}
      />

      <path
        d="M26.5 18.6l-8 8-3.8-3.8c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4.5 4.5c.2.2.5.3.7.3s.5-.1.7-.3l8.7-8.7c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0zM41.7 22.7H31.5c-.6 0-1 .4-1 1s.4 1 1 1h10.2c.6 0 1-.4 1-1s-.4-1-1-1zM26.5 34.5l-8 8-3.8-3.8c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4.5 4.5c.2.2.5.3.7.3s.5-.1.7-.3l8.7-8.7c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0zM41.7 38.6H31.5c-.6 0-1 .4-1 1s.4 1 1 1h10.2c.6 0 1-.4 1-1s-.4-1-1-1z"
        fill={color}
      />
    </svg>
  );
};

export default Compliance;
